import React, { useState } from 'react'
import startCase from 'lodash/startCase'
import { Button } from '@chakra-ui/react'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { Link } from '@chakra-ui/react'
import { OccupancyInspectionBulkApiResponseItem } from '@homevest/types/occupancy-inspections'

import { BaseGrid } from 'components/TailwindUIToolkit'
import SetFilter from 'components/AgGrid/filters/SetFilter'
import { formatUTCDate, formatDateWithTime } from 'lib/date-time'
import { formatProjectTitle } from 'lib/construction-projects'
import OccupancyInspectionDetailsEditSidepeek from './OccupancyInspectionDetailsEditSidepeek'
import { LINK_COLOR } from 'constants/colors'

export const OccupancyInspectionGrid = ({
  oiData,
  refetchOccupancyInspections
}: {
  oiData?: OccupancyInspectionBulkApiResponseItem[]
  refetchOccupancyInspections: () => void
}) => {
  const [isOpen, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const [selectedInspection, setSelectedInspection] =
    useState<OccupancyInspectionBulkApiResponseItem | null>(null)

  const openDetailsSidepeek = (inspection?: OccupancyInspectionBulkApiResponseItem) => {
    setSelectedInspection(inspection ?? null)
    onOpen()
  }

  return (
    <>
      {isOpen && (
        <OccupancyInspectionDetailsEditSidepeek
          inspectionId={selectedInspection?.id}
          isOpen={isOpen}
          onSubmit={refetchOccupancyInspections}
          onClose={onClose}
        />
      )}
      <div className='h-[60vh]'>
        <BaseGrid
          rowData={oiData ?? []}
          columns={[
            {
              colId: 'actions',
              headerName: '',
              width: 100,
              cellRenderer: (
                params: ICellRendererParams<OccupancyInspectionBulkApiResponseItem>
              ) => (
                <Button
                  aria-label='View/Edit'
                  variant={'outline'}
                  colorScheme='teal'
                  size='xs'
                  sx={{
                    fontSize: '10px',
                    padding: '3px 5px'
                  }}
                  onClick={() => openDetailsSidepeek(params.data)}
                >
                  View/Edit
                </Button>
              )
            },
            ...COLUMNS
          ]}
          defaultColDefOverrides={{
            wrapHeaderText: true
          }}
        />
      </div>
    </>
  )
}

export const occupancyInspectionColumns: ColDef<OccupancyInspectionBulkApiResponseItem>[] = [
  {
    colId: 'id',
    headerName: 'ID',
    field: 'id',
    hide: true
  },
  {
    colId: 'construction_project_id',
    headerName: 'Project',
    field: 'construction_project_id',
    valueGetter: (params) =>
      formatProjectTitle(
        params.data?.project.type,
        params.data?.address.display_line_1,
        params.data?.project.start_date
      ),
    cellRenderer: (params: ICellRendererParams<OccupancyInspectionBulkApiResponseItem>) => {
      const { data, value } = params
      return (
        <Link
          isExternal
          color={LINK_COLOR}
          href={`/construction/projects/${data?.construction_project_id}`}
        >
          {value}
        </Link>
      )
    },
    resizable: true
  },
  {
    colId: 'address.display_line_1',
    headerName: 'Address',
    cellRenderer: (params: ICellRendererParams<OccupancyInspectionBulkApiResponseItem>) => {
      const { data } = params
      const address = data?.address
      return (
        <Link isExternal color={LINK_COLOR} href={`/home/${data?.portfolio_home.id}`}>
          {address
            ? `${address.display_line_1}, ${address.city}, ${address.state}, ${address.zip}`
            : ''}
        </Link>
      )
    },
    resizable: true
  },
  {
    colId: 'markets.display_name',
    headerName: 'Market',
    field: 'markets.display_name',
    filter: SetFilter,
    resizable: true
  },
  {
    colId: 'status',
    headerName: 'Status',
    field: 'status',
    valueFormatter: (params) => startCase(params.value),
    filter: SetFilter,
    resizable: true
  },
  {
    colId: 'result',
    headerName: 'Result',
    field: 'result',
    valueFormatter: (params) => startCase(params.value),
    resizable: true
  },
  {
    colId: 'inspection_at',
    headerName: 'Inspection At',
    field: 'inspection_at',
    valueFormatter: (params) => {
      return params.value ? formatDateWithTime(params.value) : ''
    },
    resizable: true
  },
  {
    colId: 'certificate_effective_date',
    headerName: 'Certificate Effective Date',
    field: 'certificate_effective_date',
    valueFormatter: (params) => {
      return params.value ? formatUTCDate(params.value, 'MM/dd/yy') : ''
    },
    resizable: true
  },
  {
    colId: 'certificate_expiration_date',
    headerName: 'Certificate Expiration Date',
    field: 'certificate_expiration_date',
    valueFormatter: (params) => {
      return params.value ? formatUTCDate(params.value, 'MM/dd/yy') : ''
    },
    resizable: true
  },
  {
    colId: 'occupant_signature_deadline',
    headerName: 'Occupant Signature Deadline',
    field: 'occupant_signature_deadline',
    valueFormatter: (params) => {
      return params.value ? formatUTCDate(params.value, 'MM/dd/yy') : ''
    },
    resizable: true
  },
  {
    colId: 'municipality.name',
    headerName: 'Municipality Name',
    field: 'municipality.name',
    resizable: true
  },
  {
    colId: 'primary_municipality_contact.name',
    headerName: 'Primary Municipality Contact',
    field: 'primary_municipality_contact.name',
    valueFormatter: (params) => startCase(params.value),
    resizable: true
  },
  {
    colId: 'previous_occupancy_inspection_id',
    headerName: 'Previous Occupancy Inspection',
    field: 'previous_occupancy_inspection_id',
    valueGetter: (params) => {
      const { data: oi } = params
      if (!oi) {
        return ''
      }

      return `${oi.project.type.toUpperCase()} - ${formatUTCDate(
        oi.project.start_date?.toString()
      )} - ${startCase(oi.status)} - ${formatDateWithTime(oi.inspection_at?.toString())}`
    }
  }
]

const COLUMNS = occupancyInspectionColumns.filter((column) =>
  [
    'status',
    'result',
    'inspection_at',
    'certificate_effective_date',
    'certificate_expiration_date',
    'occupant_signature_deadline',
    'municipality.name',
    'primary_municipality_contact.name',
    'previous_occupancy_inspection_id'
  ].includes(column.colId ?? '')
)
