import React, { useEffect, useState } from 'react'
import {
  ColumnApi,
  ColumnState,
  ICellRendererParams,
  GridApi,
  GridReadyEvent
} from 'ag-grid-community'
import { AxiosRequestConfig } from 'axios'
import QueryString from 'qs'
import { Alert, Button, Spinner } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import type { Ticket, DisplayTicketsApiResponse } from '@homevest/types/tickets'

import { BaseGrid } from 'components/TailwindUIToolkit'
import axios from 'lib/axios'
import { convertDisplayTicketsToTickets } from 'lib/tickets'
import { TICKETS_GRID_COLUMN_IDS, TICKETS_GRID_COLUMNS } from './configuration'
import EnrichTicketModal from './EnrichTicketModal'

type ColumnId = (typeof TICKETS_GRID_COLUMN_IDS)[keyof typeof TICKETS_GRID_COLUMN_IDS]

const TicketsGrid: React.FC<{
  gridHeight: number
  ticketsSearch?: string
  getTicketsConfig?: AxiosRequestConfig
  orderedColumnIds?: ColumnId[]
  defaultColumnState?: ColumnState[]
}> = ({ gridHeight, ticketsSearch, getTicketsConfig, orderedColumnIds, defaultColumnState }) => {
  const [selectedTicket, setSelectedTicket] = useState<Ticket | undefined>(undefined)
  const [isEnrichTicketModalOpen, setIsEnrichTicketModalOpen] = useState(false)
  const [gridApi, setGridApi] = useState<GridApi>()
  const [columnApi, setColumnApi] = useState<ColumnApi>()
  const [savedFilterModel, setSavedFilterModel] = useState<{ [k: string]: any }>()
  const [savedColumnState, setSavedColumnState] = useState<ColumnState[]>()

  // When search value updated, set new filter on grid
  useEffect(() => {
    if (gridApi && ticketsSearch) {
      gridApi.setQuickFilter(ticketsSearch)
      gridApi.onFilterChanged()
    }
  }, [gridApi, ticketsSearch])

  const {
    data,
    error,
    isLoading,
    isFetching,
    isError,
    refetch: refetchTickets
  } = useQuery<DisplayTicketsApiResponse, Error>({
    queryKey: ['getTickets'],
    queryFn: async () => {
      const { data } = await axios.get<DisplayTicketsApiResponse>('admin/tickets', {
        ...getTicketsConfig,
        paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' })
      })
      return data
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  if (isLoading || isFetching) {
    return <Spinner />
  }

  if (isError && error) {
    return <Alert status='error'>{error.message}</Alert>
  }

  const tickets = convertDisplayTicketsToTickets(data.tickets)

  const saveStateAndRefetch = () => {
    // Before refetching data (which destroys the grid)
    // save the user's current filters & column state
    setSavedFilterModel(gridApi?.getFilterModel())
    setSavedColumnState(columnApi?.getColumnState())
    refetchTickets()
  }

  const onGridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api)
    setColumnApi(evt.columnApi)

    // If available, reset user's saved filters & column state
    if (savedFilterModel || savedColumnState) {
      evt.api.setFilterModel(savedFilterModel)
      evt.columnApi.applyColumnState({ state: savedColumnState })
    } else {
      // Default column state set upon initial page load
      evt.columnApi.applyColumnState({
        state: defaultColumnState ?? [
          {
            colId: 'startDate',
            sort: 'desc'
          }
        ]
      })
    }
  }

  const columns = [
    ...TICKETS_GRID_COLUMNS,
    {
      colId: TICKETS_GRID_COLUMN_IDS.ACTIONS,
      headerName: 'Actions',
      flex: 1,
      cellRenderer: ({ data: ticket }: ICellRendererParams<Ticket>) => {
        const needsEnrichment =
          !ticket?.type || (ticket?.type !== 'maintenance' && !ticket?.project_id)
        return (
          <Button
            size='sm'
            fontWeight='normal'
            variant={needsEnrichment ? 'solid' : 'outline'}
            colorScheme={needsEnrichment ? 'teal' : 'orange'}
            onClick={() => {
              setSelectedTicket(ticket)
              setIsEnrichTicketModalOpen(true)
            }}
          >
            {needsEnrichment ? 'Enrich' : 'Edit'}
          </Button>
        )
      }
    }
  ]

  // Allow parent to configure which columns are shown & their order
  // by displaying the columns in exactly the same order as the list of orderedColumnIds
  const sortedColumns = columns
    .filter((c) =>
      orderedColumnIds && c.colId ? orderedColumnIds.includes(c.colId as ColumnId) : true
    )
    .sort((c1, c2) => {
      if (orderedColumnIds) {
        if (!c1.colId) {
          return -1
        }
        if (!c2.colId) {
          return 1
        }
        return (
          orderedColumnIds.findIndex((c) => c === c1.colId) -
          orderedColumnIds.findIndex((c) => c === c2.colId)
        )
      }
      return 0
    })

  return (
    <>
      <div className={`h-[${gridHeight}vh] w-full rounded border`}>
        <BaseGrid
          columns={sortedColumns}
          rowData={tickets}
          onGridReady={onGridReady}
          rowAutoHeight={true}
          optionOverrides={{ pagination: true }}
        />
      </div>
      {selectedTicket && (
        <EnrichTicketModal
          ticket={selectedTicket}
          refetchTickets={saveStateAndRefetch}
          isOpen={isEnrichTicketModalOpen}
          onClose={() => {
            setSelectedTicket(undefined)
            setIsEnrichTicketModalOpen(false)
          }}
        />
      )}
    </>
  )
}

export default TicketsGrid
