// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import { CardBody } from 'components/Toolkit'
import PropTypes from 'prop-types'
import React, { ReactNode } from 'react'
import { Button, Collapse } from 'reactstrap'

const DropDownHeader: React.FC<
  React.PropsWithChildren<{
    header: ReactNode
    hasFilter: boolean
    children: ReactNode
    minimize?: React.MutableRefObject<string>
  }>
> = ({ header, hasFilter = false, children, minimize }) => {
  const [open, setOpen] = React.useState(hasFilter)
  if (minimize && open && header.props.children !== minimize?.current) {
    setOpen(false)
  }
  return (
    <div>
      <Button
        outline
        color='dark'
        style={{ marginRight: '6px', whiteSpace: 'nowrap' }}
        onClick={() => {
          setOpen(hasFilter ? true : !open)
          if (minimize) {
            minimize.current = header.props.children
          }
        }}
      >
        {header}
      </Button>
      <Collapse isOpen={open}>
        <CardBody>{children}</CardBody>
      </Collapse>
    </div>
  )
}

DropDownHeader.propTypes = {
  children: PropTypes.node.isRequired,
  hasFilter: PropTypes.bool.isRequired,
  header: PropTypes.node.isRequired
}

export default DropDownHeader
