"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROLES = exports.EXTERNAL_SOURCES = exports.TENANT_PREFIX = exports.AGREEMENT_SIGNER_STATUSES = exports.AGREEMENT_SIGNER_RESOURCE_TYPES = void 0;
exports.AGREEMENT_SIGNER_RESOURCE_TYPES = {
    ADMINS: 'admins',
    USERS: 'users'
};
exports.AGREEMENT_SIGNER_STATUSES = {
    CANCELED: 'canceled',
    PENDING_SIGNATURE: 'pending_signature',
    SIGNED: 'signed'
};
exports.TENANT_PREFIX = 'Tenant #';
exports.EXTERNAL_SOURCES = {
    HELLOSIGN: 'hellosign',
    GOOGLE_CLOUD: 'google_cloud'
};
// NOTE: We have potentially more than 3 roles in a lease document.
// These are the only roles that are required, with Tenant 2 - N optional.
exports.ROLES = {
    AGENT: 'Agent',
    COMPANY: 'Homevest',
    COSIGNER: 'Co-signer #1',
    LANDLORD: 'Landlord',
    PRIMARY_TENANT: `${exports.TENANT_PREFIX}1`
};
