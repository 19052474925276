import React, { useState } from 'react'
import startCase from 'lodash/startCase'
import {
  Button,
  HStack,
  Table,
  Tr,
  Td,
  Tbody,
  MenuButton,
  Menu,
  MenuList,
  Input,
  Text,
  Thead,
  Th,
  Stack,
  InputGroup,
  InputLeftAddon,
  FormControl,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  Center,
  Spinner,
  useToast,
  FormLabel
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { userIncomeSources } from '@homevest/utils'
import {
  BasicUserIncomeSourceDocument,
  UnfulfilledUserDocRequest
} from '@homevest/types/user-income-sources'

import axios from 'lib/axios'
import { formatMoney } from 'lib/numbers'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { QuestionMarkCircleIcon, DocumentIcon } from '@heroicons/react/24/outline'
import { DotsMenuButton } from 'components/TailwindUIToolkit/Display/DotsMenuButton'

const { DOCUMENT_TYPES_WITH_AMOUNT } = userIncomeSources

const UserIncomeSourceDetailsDocumentsTable = ({
  documents,
  unfulfilledUserDocRequests,
  onUpdate
}: {
  documents: BasicUserIncomeSourceDocument[]
  unfulfilledUserDocRequests: UnfulfilledUserDocRequest[]
  onUpdate: () => Promise<void>
}) => {
  return (
    <Table variant='striped' size='sm'>
      <Thead bgColor='gray.200'>
        <Th>Doc</Th>
        <Th isNumeric>Amount</Th>
        <Th>Status</Th>
        <Th>Note</Th>
        <Th></Th>
      </Thead>
      <Tbody>
        {documents
          .sort((x, y) => {
            if (x.type === y.type) {
              return x.id > y.id ? 1 : -1
            }

            return x.type > y.type ? 1 : -1
          })
          .map((doc) => (
            <UisDocsTableDocument doc={doc} onUpdate={onUpdate} key={doc.id} />
          ))}
        {unfulfilledUserDocRequests.map((doc) => (
          <UisDocsTableUnfulfilledDoc key={doc.id} doc={doc} />
        ))}
      </Tbody>
    </Table>
  )
}

const UisDocsTableUnfulfilledDoc = ({ doc }: { doc: UnfulfilledUserDocRequest }) => {
  return (
    <Tr>
      <Td px={0}>
        <Button
          isDisabled
          fontWeight='medium'
          color='gray.700'
          variant='link'
          size='sm'
          leftIcon={<QuestionMarkCircleIcon width={18} />}
        >
          {startCase(doc.document_type)}
        </Button>
      </Td>
      <Td></Td>
      <Td>
        <HStack>{mapContentToBadge('requested')}</HStack>
      </Td>
      <Td>{doc.instructions}</Td>
      <Td></Td>
    </Tr>
  )
}

const UisDocsTableDocument = ({
  doc,
  onUpdate
}: {
  doc: BasicUserIncomeSourceDocument
  onUpdate: () => Promise<void>
}) => {
  const [isOverridingAmount, setIsOverridingAmount] = useState(false)
  const hasAmount = DOCUMENT_TYPES_WITH_AMOUNT.includes(doc.type as any)

  const setDocumentReviewStatus = async (
    docId: string,
    status: 'valid' | 'invalid',
    notes?: string
  ) => {
    await axios
      .post(`/admin/documents/${docId}/review`, {
        status,
        review_notes: notes
      })
      .catch(console.error)

    await onUpdate()
  }

  return (
    <Tr key={doc.id}>
      <Td w='25%' px={0}>
        <Button
          variant='link'
          fontWeight='medium'
          color='gray.700'
          size='sm'
          leftIcon={<DocumentIcon width={18} />}
          textDecoration={doc.status === 'invalid' ? 'line-through' : undefined}
          onClick={() =>
            axios
              .get(`/admin/documents/${doc.document_id}/url`)
              .then(({ data }) => data.url && window.open(data.url, '_blank'))
          }
        >
          {startCase(doc.type)}
        </Button>
      </Td>
      <Td w='20%' isNumeric>
        {doc.amount !== undefined ? formatMoney(doc.amount, 2, '$') : ''}
      </Td>
      <Td w='15%'>
        <HStack>{mapContentToBadge(doc.status)}</HStack>
      </Td>
      <Td w='35%'>
        {doc.review_notes && doc.status === 'valid' && <Text>{doc.review_notes}</Text>}
      </Td>
      <Td w='5%'>
        <DotsMenuButton>
          {isOverridingAmount ? (
            <AmountOverrideMenu
              userIncomeSourceDocumentId={doc.id}
              onCancel={() => setIsOverridingAmount(false)}
              onSubmit={async () => {
                setIsOverridingAmount(false)
                await onUpdate()
              }}
            />
          ) : (
            <Stack>
              {doc.status !== 'valid' && (
                <DocumentApproveButton
                  approve={async (note) => {
                    await setDocumentReviewStatus(doc.document_id, 'valid', note)
                  }}
                />
              )}
              {doc.status !== 'invalid' && (
                <Button
                  size='xs'
                  variant='outline'
                  colorScheme='red'
                  onClick={async () => {
                    await setDocumentReviewStatus(doc.document_id, 'invalid')
                  }}
                >
                  Reject
                </Button>
              )}
              {hasAmount && (
                <Button
                  size='xs'
                  variant='outline'
                  colorScheme='yellow'
                  onClick={() => setIsOverridingAmount(true)}
                >
                  Override Amount
                </Button>
              )}
            </Stack>
          )}
        </DotsMenuButton>
      </Td>
    </Tr>
  )
}

const DocumentApproveButton = ({ approve }: { approve: (note: string) => Promise<void> }) => {
  const [approveNote, setApproveNote] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Menu>
      <MenuButton as={Button} size='xs' colorScheme='green' variant='outline'>
        Approve
      </MenuButton>
      <MenuList>
        <HStack px={2}>
          <Input
            value={approveNote}
            size='sm'
            onChange={(e) => setApproveNote(e.target.value)}
            placeholder='Note...'
          />
          <Button
            isLoading={isLoading}
            isDisabled={!approveNote}
            size='sm'
            colorScheme='green'
            onClick={async () => {
              setIsLoading(true)
              await approve(approveNote)
              setIsLoading(false)
            }}
          >
            Confirm
          </Button>
        </HStack>
      </MenuList>
    </Menu>
  )
}

const AmountOverrideMenu = ({
  userIncomeSourceDocumentId,
  onCancel,
  onSubmit
}: {
  userIncomeSourceDocumentId: string
  onCancel: () => void
  onSubmit: () => void
}) => {
  const [amount, setAmount] = useState('')

  const toast = useToast()

  const amountAsNumber = Number(amount)
  const isAmountValid = !isNaN(amountAsNumber) && amountAsNumber >= 0
  const canSubmit = isAmountValid && amount !== ''

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async () => {
      await axios.put(`/admin/user_income_source_documents/${userIncomeSourceDocumentId}`, {
        amount: amountAsNumber
      })
    }
  })

  const handleCancel = () => {
    setAmount('')
    onCancel()
  }

  const handleSubmit = async () => {
    if (!canSubmit) {
      return
    }

    await mutateAsync(undefined, {
      onSuccess: () => {
        setAmount('')
        toast({
          title: 'Amount Override Successful!',
          status: 'success',
          position: 'bottom-right'
        })
        onSubmit()
      },
      onError: (err: any) => {
        toast({
          title: 'An error has occured!',
          description:
            err?.response?.data?.message || 'An error occurred while overriding the amount!',
          status: 'error',
          position: 'bottom-right'
        })
      }
    })
  }

  if (isLoading) {
    return (
      <Center>
        <Spinner size='sm' color='teal' margin={2} />
      </Center>
    )
  }

  return (
    <Stack margin={1} spacing={3}>
      <FormControl isRequired isInvalid={!isAmountValid}>
        <FormLabel fontSize={'sm'}>New Amount</FormLabel>
        <InputGroup size={'sm'}>
          <InputLeftAddon>$</InputLeftAddon>
          <NumberInput
            value={amount ?? ''}
            onChange={(val) => setAmount(val)}
            // placeholder={'Enter new amount'}
          >
            <NumberInputField />
          </NumberInput>
        </InputGroup>
        <FormErrorMessage fontSize='sm'>Please enter a valid number!</FormErrorMessage>
      </FormControl>
      <HStack>
        <Button
          w='full'
          size='xs'
          variant='outline'
          colorScheme='yellow'
          onClick={handleSubmit}
          isDisabled={!canSubmit}
        >
          Submit
        </Button>
        <Button w='full' size='xs' variant='outline' colorScheme='red' onClick={handleCancel}>
          Cancel
        </Button>
      </HStack>
    </Stack>
  )
}

export default UserIncomeSourceDetailsDocumentsTable
