import startCase from 'lodash/startCase'
import { ColDef } from 'ag-grid-community'

import { UnresolvedLatchelTicketDeadLettersQuery } from 'graphql/generated'

export type TicketDeadLetterRow =
  UnresolvedLatchelTicketDeadLettersQuery['tickets_dead_letters'][number]

const LATCHEL_EVENT_ID: ColDef<TicketDeadLetterRow> = {
  colId: 'latchel_event_id',
  headerName: 'Latchel Event ID',
  field: 'external_id',
  flex: 1
}

const LATCHEL_JOB_ID: ColDef<TicketDeadLetterRow> = {
  colId: 'latchel_job_id',
  headerName: 'Latchel Job ID',
  valueGetter: (params) => params.data?.payload?.object?.job_id || '',
  valueFormatter: (params) => params.value || 'Not found',
  flex: 1
}

const OPERATION: ColDef<TicketDeadLetterRow> = {
  colId: 'operation_type',
  headerName: 'Operation',
  field: 'operation_type',
  valueFormatter: (params) => startCase(params.value),
  flex: 1
}

const SYNC_ATTEMPTED_AT: ColDef<TicketDeadLetterRow> = {
  colId: 'created_at',
  headerName: 'Sync Attempted At',
  field: 'created_at',
  filter: false,
  valueFormatter: (params) => new Date(params.value).toLocaleString(),
  flex: 1
}

const ERROR_MESSAGE: ColDef<TicketDeadLetterRow> = {
  colId: 'error_message',
  headerName: 'Error Message',
  field: 'error_message',
  flex: 2
}

export const BASE_COLUMNS = [
  LATCHEL_EVENT_ID,
  LATCHEL_JOB_ID,
  OPERATION,
  SYNC_ATTEMPTED_AT,
  ERROR_MESSAGE
]
