import React, { FunctionComponent, KeyboardEventHandler } from 'react'
import { Dialog } from '@headlessui/react'

type PropTypes = {
  open: boolean
  setClosed: (b: boolean) => void
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>
}

export const Modal: FunctionComponent<React.PropsWithChildren<PropTypes>> = ({
  open,
  setClosed,
  onKeyDown = () => {},
  children
}) => {
  return (
    <Dialog
      onClose={() => setClosed(true)}
      open={open}
      className='relative z-10'
      onKeyDown={onKeyDown}
    >
      <div className='fixed inset-0 z-10 flex flex-row justify-center overflow-y-auto bg-slate-500 bg-opacity-50'>
        <div className='flex h-full w-3/4 items-center p-0'>
          <Dialog.Panel className='relative flex h-fit max-h-screen w-full'>
            {children}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}
