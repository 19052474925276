import React, { FunctionComponent } from 'react'
import { Spinner } from 'reactstrap'
import { ContentSectionCard, LinkButton, TwoColumnDataList } from 'components/TailwindUIToolkit'
import { useEntityDetailsByPortfolioHomeIdQuery } from 'graphql/generated'
import { first } from 'lodash'
import Error from 'components/Toolkit/Text/Error'
import { RETOOL_APP_URL, RETOOL_WAREHOUSE_TRANSFER_APP_ID } from 'constants/urls'
import { formatNullableDateString } from 'lib/date-time'

type PropsType = {
  portfolioHomeId: string
}

const Entity: FunctionComponent<React.PropsWithChildren<PropsType>> = ({ portfolioHomeId }) => {
  const [{ data, error }] = useEntityDetailsByPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  if (error) {
    return <Error>{error.message}</Error>
  }

  if (!data) {
    return <Error>data not found</Error>
  }

  const entityData = data.portfolio_homes_by_pk?.most_recent_llc_property?.llc

  const dataList = [
    {
      label: 'entity',
      value: entityData?.name
    },
    {
      label: 'fund',
      value: first(entityData?.fund_llcs)?.fund.abbreviation
    }
  ]

  const completedTransferAt = first(
    data?.portfolio_homes_by_pk?.llc_property_transfers
  )?.completed_at

  if (completedTransferAt) {
    dataList.push({
      label: 'recent completed transfer',
      value: formatNullableDateString(completedTransferAt)
    })
  }

  return <TwoColumnDataList data={dataList} />
}

const WarehouseToolButton = (
  <LinkButton href={`${RETOOL_APP_URL}${RETOOL_WAREHOUSE_TRANSFER_APP_ID}`}>
    Warehouse Tool
  </LinkButton>
)

const EntityWrapper: FunctionComponent<React.PropsWithChildren<PropsType>> = (props) => {
  return (
    <ContentSectionCard title='Entity' padding action={WarehouseToolButton}>
      <React.Suspense fallback={<Spinner />}>
        <Entity {...props} />
      </React.Suspense>
    </ContentSectionCard>
  )
}

export default EntityWrapper
