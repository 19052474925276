import React, { FunctionComponent } from 'react'
import { useParams } from 'react-router'
import { Button } from '@chakra-ui/react'

import axios from 'lib/axios'
import { RentalApplication } from 'types/RentalApplication'

const HoldButton: FunctionComponent<
  React.PropsWithChildren<{
    isOnHold: boolean
    rentalApplication: RentalApplication
    setRentalApplication: (ra: RentalApplication) => void
  }>
> = ({ isOnHold, rentalApplication, setRentalApplication }) => {
  const { applicationId } = useParams<{ applicationId: string }>()

  const toggleHold = async () => {
    try {
      const { data } = await axios.put(`/admin/rental_applications/${applicationId}/hold`, {
        hold_application_at: isOnHold ? null : Date.now()
      })
      setRentalApplication({ ...rentalApplication, ...data })
    } catch (err) {
      throw err
    }
  }

  return (
    <Button onClick={toggleHold} size='md' colorScheme={'orange'}>
      {isOnHold ? 'Unhold Application' : 'Hold Application'}
    </Button>
  )
}

export default HoldButton
