"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OPERATIONAL_MARKET_NAMES = void 0;
const regions_1 = require("./regions");
exports.OPERATIONAL_MARKET_NAMES = [
    regions_1.REGIONS.ATLANTA,
    regions_1.REGIONS.CHARLOTTE,
    regions_1.REGIONS.HUNTSVILLE,
    regions_1.REGIONS.INDIANAPOLIS,
    regions_1.REGIONS.LOS_ANGELES,
    regions_1.REGIONS.ST_LOUIS
];
