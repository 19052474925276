import { camelCase, startCase } from 'lodash'

import firebase, { generateUploadPath } from '../../lib/firebase'

export const UPDATE_DOCUMENTS_MODAL_ATTRIBUTES = 'UPDATE_DOCUMENTS_MODAL_ATTRIBUTES'

const storageRef = firebase.storage().ref()

export const formatUserDocument = async (user, key) => {
  if (user.underwriting && user.underwriting[key]) {
    const uploadPath = generateUploadPath(user.id, key)

    const url = await storageRef.child(uploadPath).getDownloadURL()

    return {
      userName: `${user.firstName} ${user.lastName}`,
      name: startCase(key),
      url,
      userId: user.id
    }
  }
}

export const getSetKey = (doc) => {
  return `${doc.userId}-${camelCase(doc.name)}`
}

export const updateModalAttributes = (update) => ({
  update,
  type: UPDATE_DOCUMENTS_MODAL_ATTRIBUTES
})
