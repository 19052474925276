import React, { useMemo } from 'react'
import { assign, upperCase } from 'lodash'
import { AgGridReact } from 'ag-grid-react'
import { GridOptions, ColDef, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community'
import 'ag-grid-community/styles//ag-grid.css'
import 'ag-grid-community/styles//ag-theme-material.css'
import './custom-theme.css'

interface IGridProps<T> extends GridOptions<T> {
  rowData: T[]
  columns: ColDef<T>[]
  onGridReady?: (evt: GridReadyEvent<T>) => void
  optionOverrides?: Partial<GridOptions<T>>
  defaultColDefOverrides?: Partial<ColDef<T>>
  containerStyle?: React.CSSProperties
  onRowDoubleClicked?: (evt: RowDoubleClickedEvent<T>) => void
  rowAutoHeight?: boolean
}

const rowAutoHeightCellStyle = {
  wordBreak: 'break-word',
  lineHeight: '20px',
  alignContent: 'center'
}

const defaultCellStyle = {
  wordBreak: 'break-word',
  lineHeight: '20px',
  display: 'flex'
}

export const defaultColDef: ColDef = {
  minWidth: 40,
  sortable: true,
  filter: true,
  floatingFilter: false,
  wrapText: true,
  autoHeight: true,
  width: 200,
  headerValueGetter: (params) => upperCase(params.colDef.headerName)
}

export const defaultGridOptions: GridOptions = {
  domLayout: 'normal',
  sideBar: true,
  headerHeight: 44,
  rowHeight: 72,
  pagination: false,
  enableCellTextSelection: true
}

const Grid = <T extends object>({
  rowData,
  columns,
  onGridReady,
  optionOverrides,
  defaultColDefOverrides,
  onRowDoubleClicked,
  containerStyle = {},
  rowAutoHeight = false,
  ...otherGridProps
}: IGridProps<T>) => {
  const mergedOptions = useMemo(
    () => assign(defaultGridOptions, optionOverrides),
    [optionOverrides]
  )

  const mergedDefaultColDef = useMemo(
    () =>
      assign(
        defaultColDef,
        { cellStyle: rowAutoHeight ? rowAutoHeightCellStyle : defaultCellStyle },
        defaultColDefOverrides
      ),
    [defaultColDefOverrides]
  )
  const mergedGridOptions = {
    ...mergedOptions,
    defaultColDef: mergedDefaultColDef
  }

  return (
    <div className='ag-theme-material' style={{ height: '100%', ...containerStyle }}>
      <AgGridReact<T>
        gridOptions={mergedGridOptions}
        columnDefs={columns}
        rowData={rowData}
        onGridReady={(evt) => {
          onGridReady?.(evt)
        }}
        onRowDoubleClicked={onRowDoubleClicked}
        {...otherGridProps}
      />
    </div>
  )
}

export default Grid
