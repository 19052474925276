import React, { FunctionComponent, useState } from 'react'
import { Interaction } from 'types/Timeline'
import UncategorizedItem from './UncategorizedItem'
import RenderItem from './RenderItem'
import { allFields } from './DisplayedFields'
import { StyledOpenItem, StyledClosedItem } from './StyledComponents'
import { VALIDATORS } from './validators'

type TimelineItemPropTypes = {
  interaction: Interaction
}

// a react component for displaying any interaction
// event on the customer timeline

// should allow a user to click on the element to expand
// background color should change to be slightly darker when expanded

// should take timeline item and map it to the according
// component (i.e. email, sms, rentalAppEvent, etc.), then render it

const TimelineItem: FunctionComponent<React.PropsWithChildren<TimelineItemPropTypes>> = ({
  interaction
}) => {
  const [open, setOpen] = useState(false)

  const StyledItem = open ? StyledOpenItem : StyledClosedItem

  let item

  if (VALIDATORS.isSMS(interaction)) {
    item = (
      <RenderItem
        interaction={interaction}
        fields={allFields.sms}
        interactionEventType={interaction.direction}
        open={open}
      />
    )
  }
  if (VALIDATORS.isEmail(interaction)) {
    item = (
      <RenderItem
        interaction={interaction}
        fields={allFields.email}
        interactionEventType={interaction.status}
        open={open}
      />
    )
  }
  if (VALIDATORS.isVoice(interaction)) {
    item = (
      <RenderItem
        interaction={interaction}
        fields={allFields.voice}
        interactionEventType={interaction.direction}
        open={open}
      />
    )
  }
  if (VALIDATORS.isTour(interaction)) {
    item = (
      <RenderItem
        interaction={interaction}
        fields={allFields.tour}
        interactionEventType={interaction.eventType}
        open={open}
      />
    )
  }
  if (VALIDATORS.isRentalAppEvent(interaction)) {
    item = (
      <RenderItem
        interaction={interaction}
        fields={allFields.rentalAppEvent}
        interactionEventType={interaction.eventType}
        open={open}
      />
    )
  }
  if (VALIDATORS.isRentalSiteInquiry(interaction)) {
    item = (
      <RenderItem
        interaction={interaction}
        fields={allFields.rentalSiteInquiry}
        interactionEventType={interaction.source}
        open={open}
      />
    )
  }
  if (!item) {
    item = <UncategorizedItem interaction={interaction} open={open} />
  }

  return <StyledItem onClick={() => setOpen(!open)}>{item}</StyledItem>
}

export default TimelineItem
