"use strict";
exports.USER_FIELDS = Object.freeze({
    HAS_COSIGNER: 'has_cosigner', // bool
    HAS_GOOD_CREDIT_ROOMMATE: 'has_good_credit_roommate', // bool
    HAS_RECEIVED_SALES_PRODUCT_EXPLANATION: 'has_received_sales_product_explanation', // bool
    HAS_VOUCHER: 'has_voucher', // bool
    NOT_INTERESTED_REASON: 'not_interested_reason', // string
    SELF_REPORTED_CREDIT: 'self_reported_credit', // low-high, range
    VOUCHER_TYPE: 'voucher_type' // string
});
