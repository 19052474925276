import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { sentryDsn } from './config'

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.01
  })
}
