"use strict";
exports.TASK_TYPES = Object.freeze({
    AGENT: {
        APPROVAL: 'agent_approval'
    },
    DISPATCH: {
        COLLECT_CUSTOMER_AVAILABILITY: 'collect_customer_availability',
        FIND_AGENT_AVAILABILITY: 'find_agent_availability'
    },
    ISSUE: {
        ESCALATION: 'issue_escalation',
        ESCALATION_URGENT: 'issue_escalation_urgent'
    },
    PROGRAM: {
        INTRODUCTION: 'program_introduction'
    },
    TOUR: {
        CANCEL: 'cancel_tour',
        // This is for a user
        CONFIRM: 'tour_confirm',
        // This is for an agent
        CONFIRM_PENDING: 'tour_confirm_pending',
        // This is for a user
        CONFIRM_FINAL: 'tour_confirm_final',
        FOLLOWUP: 'tour_followup',
        REMATCH: 'tour_rematch',
        UPDATE: 'update_tour'
    }
});
exports.TASK_STATUSES = Object.freeze({
    AUTO_CANCELED: 'auto_canceled',
    CANCELED: 'canceled',
    FAILED: 'failed',
    IN_PROGRESS: 'in_progress',
    INCOMPLETE: 'incomplete',
    MANUALLY_CANCELED: 'manually_canceled',
    PENDING: 'pending',
    SUCCEEDED: 'succeeded'
});
exports.CLEANUP = Object.freeze({
    TASK_TYPE_INVALIDATE: 'task_type_invalidate',
    CRM_INVALIDATE: 'crm_invalidate',
    EXPIRE_PENDING: 'expire_pending'
});
