import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { formatMoney } from 'lib/numbers'
import pluralize from 'pluralize'

import { BodyText, Button, ErrorText, HeaderText, Input, Modal } from 'components/Toolkit'

import axios from 'lib/axios'
import { useQuery } from 'urql'
import { REQUEST_POLICY } from 'constants/urql'
import { UpupApplicationMlsListingsByAddressDocument } from 'graphql/generated'

import LeadGroupSearch from '../../components/LeadGroups/Search'

const CreateApplicationModal: React.FC<
  React.PropsWithChildren<{
    setShowCreateModal: Function
  }>
> = ({ setShowCreateModal }) => {
  const [searchAddress, setSearchAddress] = React.useState('')
  const [newApplicationProperty, setNewApplicationProperty] = React.useState({
    id: null
  })

  return (
    <Modal
      hasCloseButton
      close={() => {
        setShowCreateModal(false)
        setSearchAddress('')
      }}
    >
      {newApplicationProperty.id == null && (
        <div>
          <HeaderText size='h3'>Property Search</HeaderText>
          <BodyText>Find a property for the application</BodyText>
          <Input
            hasError={false}
            type='string'
            label='Property Address'
            value={searchAddress}
            onChange={(val: any) => {
              setSearchAddress(val)
            }}
          />
          <React.Suspense fallback={<div>No properties found!</div>}>
            <CreateApplicationPropertySearch
              address={searchAddress}
              setNewApplicationProperty={setNewApplicationProperty}
            />
          </React.Suspense>
        </div>
      )}

      {Boolean(newApplicationProperty.id) && (
        <div>
          <LeadGroupSearch
            selectText='Select'
            isDisabled={false}
            onSelect={async (val: any) => {
              try {
                await axios.post('/admin/rental_applications', {
                  lead_group_id: val.id,
                  mls_listing_id: newApplicationProperty!.id
                })
              } catch (e) {
                window.alert(`Error: ${(e as Error).message}`)
              }

              setNewApplicationProperty({ id: null })
              setShowCreateModal(false)
            }}
          />
        </div>
      )}
    </Modal>
  )
}

const CreateApplicationPropertySearch: React.FC<
  React.PropsWithChildren<{
    address: string
    setNewApplicationProperty: Function
  }>
> = ({ address, setNewApplicationProperty }) => {
  const [{ data, fetching, error }] = useQuery({
    query: UpupApplicationMlsListingsByAddressDocument,
    variables: { address: `%${address}%` },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    pause: !address
  })

  if (error) {
    return <ErrorText>{error}</ErrorText>
  }

  if (fetching) {
    return <BodyText>Searching for ${address}...</BodyText>
  }

  if (!data) {
    return <BodyText>No properties found</BodyText>
  }

  return (
    <div>
      <HeaderText size='h4'>
        {data.mls_listings.length} {pluralize('Property', data.mls_listings.length)} Found
      </HeaderText>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {data.mls_listings.map((listing: any) => {
          return (
            <div key={listing.id} style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <HeaderText size='h5'>{listing.display_line_1}</HeaderText>
                <BodyText>{listing.display_line_2}</BodyText>
                {get(listing, 'photos[0].url') && (
                  <img style={{ height: '64px' }} src={listing.photos[0].url} />
                )}
                <BodyText>
                  {listing.beds} Beds/ {listing.baths_weighted} Baths
                </BodyText>
                <BodyText>List Price: {formatMoney(listing.list_price, 2, '$')}</BodyText>
                <BodyText>
                  Listed Rent:{' '}
                  {formatMoney(listing?.opportunity?.current_rent[0]?.listed_rent, 2, '$')}
                </BodyText>
                <BodyText>Status: {listing?.internal_attributes[0]?.internal_status}</BodyText>
              </div>
              <div>
                <Button
                  isSecondary={false}
                  onClick={() => {
                    setNewApplicationProperty(listing)
                  }}
                  size='vvs'
                >
                  Select
                </Button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

CreateApplicationModal.propTypes = {
  setShowCreateModal: PropTypes.func.isRequired
}

CreateApplicationPropertySearch.propTypes = {
  address: PropTypes.string.isRequired,
  setNewApplicationProperty: PropTypes.func.isRequired
}

export default CreateApplicationModal
