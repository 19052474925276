import React from 'react'
import { Button, Heading, Link } from '@chakra-ui/react'

import ScopeLineItemsGrid from './components/ScopeLineItemsGrid'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { RETOOL_SCOPE_MANAGER_APP_URL } from 'constants/urls'
import { useConstructionProjectScopeQuery } from 'lib/construction-projects'

const ScopeLineItemsCard: React.FC<{
  constructionProjectId: string
}> = ({ constructionProjectId }) => {
  const queryResult = useConstructionProjectScopeQuery(constructionProjectId)
  const scope = queryResult.data
  const retoolUrl = scope
    ? `${RETOOL_SCOPE_MANAGER_APP_URL}/edit_scope?construction_scope_id=${scope.id}`
    : RETOOL_SCOPE_MANAGER_APP_URL

  return (
    <ContentSectionCard
      title={
        <Heading as='h4' size='md' my={1}>
          Scope Line Items
        </Heading>
      }
      action={
        <Button as={Link} isExternal href={retoolUrl} size='sm' variant='outline'>
          {scope ? 'Edit Scope in Retool' : 'Open Scope Manager Retool'}
        </Button>
      }
    >
      <ScopeLineItemsGrid data={queryResult} />
    </ContentSectionCard>
  )
}

export default ScopeLineItemsCard
