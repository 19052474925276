"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TICKET_CATEGORIES = exports.TICKET_EXTERNAL_SOURCES = exports.TICKET_TYPES = void 0;
exports.TICKET_TYPES = {
    TURN: 'turn',
    TURN_OI: 'turn_oi',
    DISPOSITION: 'disposition',
    DISPOSITION_INSPECTION: 'disposition_inspection',
    DISPOSITION_OI: 'disposition_oi',
    REHAB: 'rehab',
    MAINTENANCE: 'maintenance'
};
exports.TICKET_EXTERNAL_SOURCES = {
    LATCHEL: 'latchel'
};
exports.TICKET_CATEGORIES = {
    REKEY: 'rekey',
    PAINTING: 'painting',
    SECURITY_INSTALL: 'security_install',
    SECURITY_SYSTEM_REMOVAL: 'security_system_removal',
    MOVE_OUT_INSPECTION: 'move_out_inspection',
    QC_INSPECTION: 'qc_inspection',
    SNOW_REMOVAL: 'snow_removal',
    LANDSCAPING: 'landscaping',
    TREE_REMOVAL: 'tree_removal',
    ROOF: 'roof',
    CLEANING: 'cleaning',
    HANDYMAN: 'handyman',
    PLUMBING: 'plumbing',
    ELECTRICAL: 'electrical',
    TRASH_REMOVAL: 'trash_removal',
    APPLIANCES: 'appliances',
    COUNTERTOPS: 'countertops',
    HVAC: 'hvac',
    PEST_CONTROL: 'pest_control',
    OCCUPANCY_INSPECTION: 'occupancy_inspection',
    MISC: 'misc'
};
