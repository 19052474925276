interface RenderableField {
  fieldName: string
  displayName?: string
}

interface OpenClosedFieldsObject {
  open: RenderableField[]
  closed: RenderableField[]
}

// these objects hold information describing which fields each
// timeline component should display. when closed, will display only
// fields that appear in 'closed' and when open, will display
// first fields from 'closed' and then fields from 'open'

const emailFields: OpenClosedFieldsObject = {
  closed: [
    { fieldName: 'happenedAtFormatted', displayName: 'Occurred at' },
    { fieldName: 'subject' },
    { fieldName: 'direction' }
  ],
  open: [
    { fieldName: 'externalSource', displayName: 'External Source' },
    { fieldName: 'status' },
    { fieldName: 'to', displayName: 'Recipient' },
    { fieldName: 'content' }
  ]
}

const smsFields: OpenClosedFieldsObject = {
  closed: [
    { fieldName: 'happenedAtFormatted', displayName: 'Occurred at' },
    { fieldName: 'content' },
    { fieldName: 'direction' }
  ],
  open: [
    { fieldName: 'externalSource', displayName: 'External Source' },
    { fieldName: 'from', displayName: 'Sender' }
  ]
}

const voiceFields: OpenClosedFieldsObject = {
  closed: [
    { fieldName: 'happenedAtFormatted', displayName: 'Occurred at' },
    { fieldName: 'direction' }
  ],
  open: [{ fieldName: 'from' }, { fieldName: 'externalSource', displayName: 'External source' }]
}

const tourFields: OpenClosedFieldsObject = {
  closed: [
    { fieldName: 'happenedAtFormatted', displayName: 'Occurred at' },
    { fieldName: 'scheduledFor', displayName: 'Scheduled for' }
  ],
  open: [
    { fieldName: 'status' },
    { fieldName: 'tourType', displayName: 'Tour type' },
    { fieldName: 'propertyDetails', displayName: 'Property details' }
  ]
}

const rentalAppEventFields: OpenClosedFieldsObject = {
  closed: [
    { fieldName: 'happenedAtFormatted', displayName: 'Occurred at' },
    {
      fieldName: 'projectedOccupancyDate',
      displayName: 'Projected occupancy date'
    }
  ],
  open: [
    { fieldName: 'currentStatus', displayName: 'Current status' },
    { fieldName: 'cancellationReason', displayName: 'Cancellation reason' },
    { fieldName: 'propertyDetails', displayName: 'Property details' }
  ]
}

const rentalSiteInquiryFields: OpenClosedFieldsObject = {
  closed: [
    { fieldName: 'happenedAtFormatted', displayName: 'Occurred at' },
    { fieldName: 'source' }
  ],
  open: [
    { fieldName: 'cancellationReason', displayName: 'Cancellation reason' },
    { fieldName: 'inquiryComments', displayName: 'Comments' },
    { fieldName: 'propertyDetails', displayName: 'Property details' }
  ]
}

// the 'open' field array is left closed here because the uncategorized
// item uses custom logic when open rather than what is found here
export const uncategorizedItemFields: OpenClosedFieldsObject = {
  closed: [
    { fieldName: 'happenedAtFormatted', displayName: 'Occurred at' },
    { fieldName: 'interactionType', displayName: 'Interaction type' }
  ],
  open: []
}

export const allFields = Object.freeze({
  email: emailFields,
  sms: smsFields,
  voice: voiceFields,
  tour: tourFields,
  rentalAppEvent: rentalAppEventFields,
  rentalSiteInquiry: rentalSiteInquiryFields,
  uncategorizedItem: uncategorizedItemFields
})
