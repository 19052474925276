import React, { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import { ContentSectionCard, ActionButton } from 'components/TailwindUIToolkit'
import { startCase } from 'lodash'
import { XMarkIcon, StarIcon } from '@heroicons/react/24/solid'
import { Spinner } from 'reactstrap'
import { useUpupInsertNoteMutation, Note_Priorities_Enum } from 'graphql/generated'
import { Modal, ModalContent } from '@chakra-ui/react'

type NewNoteModalPropTypes = {
  resourceType: string
  resourceId: string
}

export const CreateNoteButtonWithModal: FunctionComponent<
  React.PropsWithChildren<NewNoteModalPropTypes>
> = ({ resourceType, resourceId }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [noteText, setNoteText] = useState<string | null>(null)
  const [notePinned, setNotePinned] = useState<boolean>(false)
  const [{ fetching }, executeMutation] = useUpupInsertNoteMutation()
  const admin = useSelector((state: StoreState) => state.admin)
  const { Pinned, Default } = Note_Priorities_Enum

  const createNoteButton = (
    <ActionButton
      onClick={() => {
        setModalOpen(true)
      }}
    >
      + New Note
    </ActionButton>
  )

  const exitButton = (
    <XMarkIcon onClick={() => setModalOpen(false)} className='h-4 w-4 cursor-pointer' />
  )

  return (
    <div>
      {createNoteButton}
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalContent>
          <div className='min-w-narrow m-auto h-fit'>
            <ContentSectionCard
              padding
              collapsable={false}
              title={`Attach Note to ${startCase(resourceType)}`}
              action={exitButton}
            >
              <div>
                <textarea
                  rows={4}
                  name='comment'
                  id='comment'
                  className='block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  placeholder={'write a note...'}
                  onChange={(e) => setNoteText(e.target.value)}
                />
                <div className='mt-4 flex justify-between'>
                  <ActionButton onClick={() => setNotePinned(!notePinned)}>
                    <StarIcon
                      className={`h-6 w-6 transition-colors duration-300 ${
                        notePinned ? `fill-yellow-300` : `fill-slate-300`
                      }`}
                    />
                    Pin
                  </ActionButton>
                  <div className='flex gap-4'>
                    <ActionButton onClick={() => setModalOpen(false)}>Cancel</ActionButton>
                    {fetching ? (
                      <Spinner />
                    ) : (
                      <ActionButton
                        onClick={() =>
                          executeMutation({
                            resource_id: resourceId,
                            resource_type: resourceType,
                            note: noteText,
                            created_by_admin_id: admin.id,
                            priority: notePinned ? Pinned : Default
                          }).then(() => setModalOpen(false))
                        }
                        colorScheme='secondary'
                      >
                        Create Note
                      </ActionButton>
                    )}
                  </div>
                </div>
              </div>
            </ContentSectionCard>
          </div>
        </ModalContent>
      </Modal>
    </div>
  )
}
