"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LATE_PAYMENT_REASONS = void 0;
exports.LATE_PAYMENT_REASONS = {
    PAID_MID_MONTH: 'paid_mid_month',
    LOST_JOB: 'lost_job',
    MEDICAL_ISSUES: 'medical_issues',
    DEATH_IN_FAMILY: 'death_in_family',
    DIVORCE: 'divorce',
    FAMILY_CIRCUMSTANCES_CHANGED: 'family_circumstances_changed',
    IN_COURT_PROCEEDINGS: 'in_court_proceedings',
    OTHER: 'other'
};
