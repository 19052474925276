import React, { FunctionComponent } from 'react'
import { startCase } from 'lodash'

export type ListItem = {
  label: string
  value: JSX.Element | string | null | undefined
}

type PropTypes = {
  data: ListItem[]
}

export const TwoColumnDataList: FunctionComponent<React.PropsWithChildren<PropTypes>> = ({
  data
}) => {
  return (
    <dl className='mb-0 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2'>
      {data.map((li) => (
        <div className='sm:col-span-1' key={li.label}>
          <dt className='text-sm font-medium text-gray-500'>{startCase(li.label)}</dt>
          <dd className='mt-1 text-sm text-gray-900'>{li.value || '-'}</dd>
        </div>
      ))}
    </dl>
  )
}
