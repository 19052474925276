import React, { useState, useEffect } from 'react'
import {
  Tr,
  Td,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Center,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'
import { requiredLeaseDetails } from '@homevest/utils'
import { PetWithId } from '@homevest/types/rental-applications'

import SelectDropdown from '../../Common/SelectDropdown'
import RemoveButton from '../../Common/RemoveButton'
import { isPetWeightValid } from 'lib/required-lease-details'

const { PET_TYPES, MAX_PET_WEIGHT } = requiredLeaseDetails

type EditPetsRowProps = {
  pet: PetWithId
  removePet: (petId: string) => void
  editPet: (petId: string, updatedPet: PetWithId) => void
  skipValidation: boolean
}

const EditPetsRow: React.FC<React.PropsWithChildren<EditPetsRowProps>> = ({
  pet,
  removePet,
  editPet,
  skipValidation
}) => {
  const [id, _setId] = useState(pet.id)
  const [name, setName] = useState(pet.name)
  const [type, setType] = useState(pet.type)
  const [color, setColor] = useState(pet.color)
  const [weight, setWeight] = useState<number | null>(pet.weight_in_pounds)

  useEffect(() => {
    handleEdit()
  }, [name, type, color, weight])

  const handleEdit = () => {
    editPet(id, { id, name, type, color, weight_in_pounds: weight! })
  }

  const isWeightValid = weight !== null && (isPetWeightValid(weight) || skipValidation)

  return (
    <Tr>
      <Td>
        <FormControl isRequired isInvalid={!name}>
          <Input value={name} onChange={(evt) => setName(evt.target.value)} />
          <FormErrorMessage>Name is required</FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired isInvalid={!type}>
          <SelectDropdown
            id='pet-type'
            options={Object.values(PET_TYPES)}
            value={type}
            onChange={(evt) => setType(evt.target.value)}
          />
          <FormErrorMessage>Type is required</FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired isInvalid={!color}>
          <Input value={color} onChange={(evt) => setColor(evt.target.value)} />
          <FormErrorMessage>Color is required</FormErrorMessage>
        </FormControl>
      </Td>

      <Td>
        <FormControl isRequired isInvalid={!isWeightValid}>
          <NumberInput
            value={weight ?? undefined}
            onChange={(value) => setWeight(value ? parseInt(value) : null)}
            max={2000}
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>
            Please enter a weight between 0 and {MAX_PET_WEIGHT} lbs
          </FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <Center>
          <RemoveButton onClick={() => removePet(id)} />
        </Center>
      </Td>
    </Tr>
  )
}

export default EditPetsRow
