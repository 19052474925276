import React from 'react'
import format from 'date-fns/format'
import { useFindRecentNoteByResourceIdQuery } from 'graphql/generated'
import { Box, Spinner, Text } from '@chakra-ui/react'
import {
  ContentSectionCard,
  LeftAlignedDataList,
  TwoColumnDataList
} from 'components/TailwindUIToolkit'
import { combineFirstNameLastName } from 'lib/utils'

export const LatestNoteDetailsInternal: React.FC<
  React.PropsWithChildren<{
    rentalApplicationId: string
  }>
> = ({ rentalApplicationId }) => {
  const [{ data, error }] = useFindRecentNoteByResourceIdQuery({
    variables: { resourceId: rentalApplicationId }
  })

  if (error) {
    return <Text>Error loading notes.</Text>
  }

  if (!data || data.notes.length === 0) {
    return <Text>No notes.</Text>
  }

  const note = data!.notes[0]

  return (
    <Box display={'flex'} flexDir={'column'} justifyContent={'space-around'}>
      <LeftAlignedDataList data={[{ label: 'Note', value: note.note }]} />
      <TwoColumnDataList
        data={[
          { label: 'Posted by', value: combineFirstNameLastName(note.admin) },
          { label: 'Date', value: format(new Date(note.created_at), 'PPp') }
        ]}
      />
    </Box>
  )
}

export const LatestNoteDetails: React.FC<
  React.PropsWithChildren<{ rentalApplicationId: string }>
> = ({ rentalApplicationId }) => (
  <ContentSectionCard padding collapsable={false} title={'Latest Note'}>
    <React.Suspense fallback={<Spinner />}>
      <LatestNoteDetailsInternal rentalApplicationId={rentalApplicationId} />
    </React.Suspense>
  </ContentSectionCard>
)
