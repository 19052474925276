import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Button,
  HStack,
  useToast
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'
import startCase from 'lodash/startCase'
import { rentalApplicationDecisions } from '@homevest/utils'

import axios from 'lib/axios'

const { REJECTION_REASONS } = rentalApplicationDecisions

const RejectModal = ({
  rentalApplicationId,
  isOpen,
  onClose
}: {
  rentalApplicationId: string
  isOpen: boolean
  onClose: () => void
}) => {
  const [rejectionReason, setRejectionReason] = React.useState('')

  const toast = useToast()

  const { isLoading, mutate: rejectApplication } = useMutation({
    mutationFn: async () => {
      await axios.post(`/admin/rental_application_decisions`, {
        rental_application_id: rentalApplicationId,
        is_approved: false,
        rejection_reason: rejectionReason
      })
    },
    onSuccess: () => {
      toast({
        title: 'Application Rejected',
        description: 'The application has been successfully rejected',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })
      onClose()
    },
    onError: (error: any) => {
      toast({
        title: 'Error',
        description: `Something went wrong when rejecting this application, please contact engineering: ${
          error.message ?? ''
        }`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })

      Sentry.captureException(error)
    }
  })

  const canSubmit = !!rejectionReason

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reject Application</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <Text>Please select a rejection reason for this application.</Text>
            <FormControl>
              <FormLabel>Rejection Reason</FormLabel>
              <Select
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
                placeholder=''
              >
                <option value='' disabled>
                  Select...
                </option>
                {Object.values(REJECTION_REASONS).map((value) => (
                  <option key={value} value={value}>
                    {startCase(value)}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'flex-end'}>
            <Button colorScheme='black' variant='outline' onClick={onClose} isDisabled={isLoading}>
              Cancel
            </Button>
            <Button
              colorScheme='red'
              onClick={() => rejectApplication()}
              isDisabled={!canSubmit}
              isLoading={isLoading}
            >
              Reject
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RejectModal
