import { SET_INITIAL_PATH } from './actions'

export default function reducer(
  state = {
    initialPathname: null,
    initialSearch: null
  },
  action
) {
  switch (action.type) {
    case SET_INITIAL_PATH:
      return {
        ...state,
        initialPathname: action.initialPathname,
        initialSearch: action.initialSearch
      }
    default:
      return state
  }
}
