import React, { useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Select,
  Button,
  ModalContent,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import { leadGroups } from '@homevest/utils'
import startCase from 'lodash/startCase'

const { LEAD_GROUP_USER_TYPES } = leadGroups

type CreateModalProps = {
  onClose: () => void
  onCreate: (users: Array<{ user_id: string; type: string }>) => void
  isActioning: boolean
}

const CreateModal = ({ onClose, onCreate, isActioning }: CreateModalProps) => {
  const [state, setState] = useState({ type: '', userId: '' })

  const { type, userId } = state

  const setPartialState = (partialState: Partial<typeof state>) =>
    setState((prev) => ({ ...prev, ...partialState }))

  const handleCreate = () => onCreate([{ user_id: userId, type }])

  const canCreate = !isActioning && userId && type

  return (
    <>
      <Modal size='xl' isOpen onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Lead Group</ModalHeader>
          <ModalBody>
            <div>
              <div>
                <b>User ID</b>
              </div>
              <Stack>
                <Input
                  onChange={(event) => setPartialState({ userId: event.target.value })}
                  value={userId}
                  type='text'
                />
                <Select
                  onChange={(event) => setPartialState({ type: event.target.value })}
                  value={type}
                >
                  <option value=''>Select the tenant type</option>
                  {Object.values(LEAD_GROUP_USER_TYPES).map((type: any) => (
                    <option key={type} value={type}>
                      {startCase(type)}
                    </option>
                  ))}
                </Select>
              </Stack>
            </div>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button
              colorScheme='cyan'
              variant='outline'
              isDisabled={!canCreate}
              onClick={handleCreate}
            >
              {isActioning ? 'Creating...' : 'Create'}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateModal
