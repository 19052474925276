import React, { createContext, useContext, useState } from 'react'
import { set } from 'lodash'

import {
  SourceDetails,
  SourceErrors,
  SourceDetailsContextType,
  SourceDetailsFieldName,
  SourceDetailsFieldValue,
  ValidatorFunc
} from 'lib/user-incomes/types'

const SourceDetailsContext = createContext<SourceDetailsContextType>(null)

export const useSourceDetails = () => {
  return useContext(SourceDetailsContext)
}

export const SourceDetailsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [sourceState, setSourceState] = useState<SourceDetails>({
    type: '',
    is_newly_employed: false
  })

  const [errors, setErrors] = useState<SourceErrors>({})
  const [isValid, setIsValid] = useState(false)

  const updateField = <Key extends SourceDetailsFieldName>(
    field: Key,
    value: SourceDetailsFieldValue<Key> // string | number | boolean | Partial<UploadDocumentWithAmount>
  ) => {
    setSourceState((prevState) => ({
      ...set(prevState, field, value)
    }))
  }

  const clear = () => {
    setSourceState({
      type: '',
      is_newly_employed: false
    })
  }

  const validate = (validatorFn: ValidatorFunc) => {
    const { errors: newErrors, isValid: newIsValid } = validatorFn(sourceState as any)
    setErrors(newErrors)
    setIsValid(newIsValid)

    return { errors: newErrors, isValid: newIsValid }
  }

  return (
    <SourceDetailsContext.Provider
      value={{
        data: sourceState,
        updateField,
        clear,
        errors,
        validate,
        isValid
      }}
    >
      {children}
    </SourceDetailsContext.Provider>
  )
}
