import React, { FunctionComponent } from 'react'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'

type CustomerInfoDetailPropTypes = {
  header: String
  content: JSX.Element | string
}

const CustomerPrequalDetail: FunctionComponent<
  React.PropsWithChildren<CustomerInfoDetailPropTypes>
> = ({ header, content }) => {
  return (
    <SC.DetailsContainer>
      <SC.DetailHeading>{header}</SC.DetailHeading>
      <SC.DetailText>{content}</SC.DetailText>
    </SC.DetailsContainer>
  )
}

export default CustomerPrequalDetail
