import { requiredLeaseDetails } from '@homevest/utils'
import { Pet, Occupant, Vehicle } from '@homevest/types/rental-applications'

const { MAX_OCCUPANT_AGE, MAX_PET_WEIGHT, MAX_LICENSE_PLATE_LENGTH, MIN_VEHICLE_YEAR } =
  requiredLeaseDetails

export const isPetValid = (pet: Pet): boolean => {
  const isWeightValid = isPetWeightValid(pet.weight_in_pounds)

  return Boolean(pet.name && pet.type && pet.color && isWeightValid)
}

export const isOccupantValid = (occupant: Occupant): boolean => {
  const isAgeValid = isOccupantAgeValid(occupant.age)

  return Boolean(occupant.name && isAgeValid && occupant.relation)
}

export const isVehicleValid = (vehicle: Vehicle): boolean => {
  const isYearValid = isVehicleYearValid(vehicle.year)

  const isLicensePlateValid = isVehicleLicensePlateValid(vehicle.license_plate)

  return Boolean(vehicle.make && vehicle.model && isYearValid && isLicensePlateValid)
}

export const isPetWeightValid = (weight: number | null): boolean => {
  return weight !== null && weight >= 0 && weight <= MAX_PET_WEIGHT
}

export const isOccupantAgeValid = (age: number | null): boolean => {
  return age !== null && age >= 0 && age <= MAX_OCCUPANT_AGE
}

export const isVehicleYearValid = (year: number | null): boolean => {
  // Vehicle years can be one year ahead of the current year
  return year !== null && year >= MIN_VEHICLE_YEAR && year <= new Date().getFullYear() + 1
}

export const isVehicleLicensePlateValid = (licensePlate: string): boolean => {
  return licensePlate.length <= MAX_LICENSE_PLATE_LENGTH
}
