import React, { FunctionComponent, useState } from 'react'
import { documents } from '@homevest/utils'
import { startCase } from 'lodash'
import { Menu, MenuList, MenuButton, Button, Select, Stack, Textarea } from '@chakra-ui/react'

const REQUEST_TYPES = [
  documents.DOCUMENT_TYPES.BANK_STATEMENT,
  documents.DOCUMENT_TYPES.OTHER_FINANCIAL_DOCUMENT
  // These should now be handled by the user income review panel module
  // documents.DOCUMENT_TYPES.TAX_RETURN,
  // documents.DOCUMENT_TYPES.PAY_STUB,
  // documents.DOCUMENT_TYPES.SOCIAL_SECURITY_AWARD_LETTER,
  // documents.DOCUMENT_TYPES.PENSION,
  // documents.DOCUMENT_TYPES.OFFER_LETTER,
]

type Props = {
  onOpen: () => void
  onCreate: (type: string, instructions: string) => void | Promise<void>
  onClose: () => void | Promise<void>
}

const RequestMenu: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onOpen,
  onCreate,
  onClose
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [instructions, setInstructions] = useState('')
  const [type, setType] = useState('')
  const valid = instructions && type

  const handleOpen = () => {
    onOpen()
    setIsOpen(true)
  }

  const handleClose = () => {
    setInstructions('')
    setType('')
    setIsOpen(false)
    onClose()
  }

  const handleSubmit = () => {
    if (!valid) {
      return
    }

    onCreate(type, instructions)
    handleClose()
  }

  return (
    <Menu isOpen={isOpen} onClose={handleClose}>
      <MenuButton
        as={Button}
        onClick={() => handleOpen()}
        variant='outline'
        size='sm'
        colorScheme='yellow'
      >
        Request new document
      </MenuButton>
      <MenuList>
        <Stack px={2}>
          <Textarea
            size='sm'
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
            placeholder='Note...'
          />
          <Select size='sm' value={type} onChange={(e) => setType(e.target.value)}>
            <option value='' disabled>
              Select Document Type
            </option>
            {Object.values(REQUEST_TYPES).map((type) => (
              <option key={type} value={type}>
                {startCase(type)}
              </option>
            ))}
          </Select>
          <Button size='sm' colorScheme='yellow' onClick={() => handleSubmit()} isDisabled={!valid}>
            Submit
          </Button>
        </Stack>
      </MenuList>
    </Menu>
  )
}

export default RequestMenu
