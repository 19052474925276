import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'

import COLORS from 'components/Toolkit/colors'
import Label from 'components/Toolkit/Text/Label'
import SearchIcon from 'components/Toolkit/Icons/Search'

const StyledInput = styled.input`
  background: ${COLORS.PRIMARY.COCONUT};
  border: ${(props) =>
    props.hasError
      ? `1.5px solid ${COLORS.ACCENTS.SALMON}`
      : `1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY}`};
  box-sizing: border-box;
  border-radius: 10px;
  color: ${COLORS.PRIMARY.PLUM};
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 168.68%;
  padding: 16px 22px 16px 50px;
  transition: border 200ms;
  width: 100%;

  &::placeholder {
    color: ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  }

  &:focus {
    border: ${(props) =>
      props.hasError
        ? `1.5px solid ${COLORS.ACCENTS.SALMON}`
        : `1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY}`};
  }
`

const StyledInputContainer = styled.div`
  margin: ${(props) => (props.hasLabel ? '25px 0' : '')};
`

const StyledInputWrapper = styled.div`
  position: relative;
`

const StyledSearchIconWrapper = styled.div`
  position: absolute;
  top: 13px;
  left: 19px;
`

const StyledContainer = styled.div`
  position: relative;
`

const StyledDropdown = styled.div`
  background: ${COLORS.PRIMARY.COCONUT};
  border: 1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY};
  width: 100%;
  top: 100px;
  left: 0;
  position: absolute;
  z-index: 2;
`

const StyledOption = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-size: 12px;
  padding: 16px 8px 16px 8px;
  line-height: 1em;
  background: ${(props) => (props.active ? '#f3f3f3' : '#fff')};
  display: block;
  border-bottom: none;
  font-weight: 400;
  color: ${COLORS.NEUTRALS.OYSTER};
  cursor: pointer;
`

function onSelect(setInputValue, onChange) {
  return (address) => {
    geocodeByAddress(address).then((results) => {
      setInputValue(results[0].formatted_address)
      onChange(results[0])
    })
  }
}

function AddressInput({ label = '', hasError = false, startValue = '', onChange }) {
  const [inputValue, setInputValue] = useState(
    typeof startValue === 'string' ? startValue : startValue.formatted_address
  )

  return (
    <PlacesAutocomplete
      value={inputValue}
      onChange={setInputValue}
      onSelect={onSelect(setInputValue, onChange)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <StyledContainer>
          <StyledInputContainer hasLabel={Boolean(label)}>
            {label && <Label>{label}</Label>}
            <StyledInputWrapper>
              <StyledInput hasError={hasError} {...getInputProps()} />
              <StyledSearchIconWrapper>
                <SearchIcon />
              </StyledSearchIconWrapper>
            </StyledInputWrapper>
          </StyledInputContainer>

          {suggestions.length > 0 && (
            <StyledDropdown>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                return (
                  <StyledOption
                    key={suggestion.id}
                    active={suggestion.active}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    <span>{suggestion.description}</span>
                  </StyledOption>
                )
              })}
            </StyledDropdown>
          )}
        </StyledContainer>
      )}
    </PlacesAutocomplete>
  )
}

AddressInput.propTypes = {
  hasError: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  startValue: PropTypes.any
}

export default memo(AddressInput)
