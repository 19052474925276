import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Center,
  Spinner,
  Tabs,
  TabList,
  Tab
} from '@chakra-ui/react'
import {
  Application,
  ColumnKey,
  DEFAULT_PAGE,
  DEFAULT_SORT,
  TableState
} from './applications-table-utils'
import { getTableQuery } from './applications-table-query'
import {
  Order_By,
  UpupRentalApplicationsQuery,
  useUpupRentalApplicationsCountQuery,
  useUpupRentalApplicationsQuery
} from 'graphql/generated'
import Pagination from './Pagination'
import RowHover from './RowHover'
import { getColumnConfig } from './getColumnConfig'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import { combineFirstNameLastName } from 'lib/utils'
import { rentalApplications } from '@homevest/utils'
import { startCase } from 'lodash'
import { Tag } from 'ui'

const { RENTAL_APPLICATION_STATUSES } = rentalApplications

const DEFAULT_STATE = {
  sortState: DEFAULT_SORT,
  pageState: DEFAULT_PAGE,
  tabState: 'all',
  filterState: {},
  selectState: { [ColumnKey.snooze]: ['normal'] }
}

const CUSTOMER_INFO_SUB_STATUSES = [
  'all',
  'needs_coapplicant',
  'pending_submission',
  'pending_credit_check',
  'pending_criminal_and_eviction',
  'pending_supplemental_docs'
] as const
type CustomerInfoSubStatus = (typeof CUSTOMER_INFO_SUB_STATUSES)[number]

type Props = {
  selectedTab: string
  onPeek: (application: Application) => void
  onCount: (result: { count?: number; fetching: boolean }) => void
  onSetRefetch: (refetch: () => void) => void
}

const ApplicationsTable: FunctionComponent<React.PropsWithChildren<Props>> = ({
  selectedTab,
  onPeek,
  onCount,
  onSetRefetch
}) => {
  const [tableState, setTableState] = useState<TableState>(DEFAULT_STATE)
  const [subStatus, setSubStatus] = useState<CustomerInfoSubStatus | null>(null)

  useEffect(() => {
    if (selectedTab === 'all') {
      setTableState(DEFAULT_STATE)
    } else if (selectedTab === 'escalated') {
      setTableState({
        ...DEFAULT_STATE,
        sortState: {
          column: ColumnKey.escalation_date,
          type: Order_By.Desc
        },
        tabState: selectedTab
      })
    } else {
      setTableState({
        ...DEFAULT_STATE,
        tabState: selectedTab,
        selectState: {
          ...DEFAULT_STATE.selectState,
          [ColumnKey.status]: [selectedTab]
        }
      })
    }
  }, [selectedTab])

  const tableQuery = useMemo(() => getTableQuery(tableState), [tableState])

  const [{ data, fetching, error }, refetch] = useUpupRentalApplicationsQuery({
    variables: tableQuery,
    // disable for custom loading - avoid entire table redraw
    context: useMemo(() => ({ suspense: false }), [])
  })

  const [{ data: countData, fetching: countFetching }] = useUpupRentalApplicationsCountQuery({
    variables: { where: tableQuery.where },
    context: useMemo(() => ({ suspense: false }), [])
  })

  const resultCount = countData?.rental_applications_aggregate.aggregate?.count

  const adminName = useSelector((state: StoreState) => combineFirstNameLastName(state.admin))

  useEffect(() => {
    setTableState((state) => ({
      ...state,
      filterState: {
        ...state.filterState,
        [ColumnKey.assigned_team]: adminName
      }
    }))
  }, [adminName])

  useEffect(() => {
    onCount({ count: resultCount, fetching: countFetching })
  }, [resultCount, countFetching, onCount])

  useEffect(() => {
    onSetRefetch(() => refetch({ requestPolicy: 'network-only' }))
  }, [refetch, onSetRefetch])

  const columnConfig = useMemo(
    () =>
      getColumnConfig({
        ...tableState,
        onSort: (sortState) => setTableState((state) => ({ ...state, sortState })),
        onSearch: (column, value) =>
          setTableState((state) => ({
            ...state,
            filterState: { ...state.filterState, [column]: value }
          })),
        onSelect: (column, value) =>
          setTableState((state) => ({
            ...state,
            selectState: { ...state.selectState, [column]: value }
          })),
        onPeek
      }),
    [tableState, onPeek]
  )

  if (error) {
    return <div>There was a problem 😭</div>
  }

  if (selectedTab === 'escalated' && !data?.rental_applications.length) {
    return <div>No escalated applications found</div>
  }

  const filteredData =
    data?.rental_applications.filter(subStatusFilter(selectedTab, subStatus)) ?? []

  return (
    <>
      <Flex direction='column' gap={4}>
        {selectedTab === RENTAL_APPLICATION_STATUSES.PENDING_CUSTOMER_INFORMATION && (
          <Tabs size='sm'>
            <TabList>
              {CUSTOMER_INFO_SUB_STATUSES.map((subStatusTab) => (
                <Tab gap={2} key={subStatusTab} onClick={() => setSubStatus(subStatusTab)}>
                  {startCase(subStatusTab)}{' '}
                  <Tag bg={subStatus === subStatusTab ? 'indigo.100' : undefined}>
                    {
                      data?.rental_applications.filter(subStatusFilter(selectedTab, subStatusTab))
                        .length
                    }
                  </Tag>
                </Tab>
              ))}
            </TabList>
          </Tabs>
        )}
        <TableContainer>
          <Table variant='simple' minWidth={32} borderRadius='md' size='sm' height={16}>
            <Thead bg='gray.50'>
              <Tr>
                {columnConfig.map((column) => (
                  <Th key={column.id} p={0}>
                    {column.renderHeader()}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody opacity={fetching ? 0.5 : 1} position='relative'>
              {fetching && <TableSpinner colSpan={columnConfig.length} />}
              {filteredData.map((application) => (
                <RowHover key={application.id}>
                  {(hover) =>
                    columnConfig.map((column) => (
                      <Td key={column.id} p={4} height={20}>
                        {column.renderCell(application, hover)}
                      </Td>
                    ))
                  }
                </RowHover>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        {(resultCount ?? 0) > 1 && (
          <Pagination
            totalResults={resultCount!}
            pageIndex={tableState.pageState.index}
            pageSize={tableState.pageState.size}
            onIndex={(index) =>
              setTableState((state) => ({
                ...state,
                pageState: { ...state.pageState, index }
              }))
            }
            onSize={(size) =>
              setTableState((state) => ({
                ...state,
                pageState: { ...state.pageState, size }
              }))
            }
          />
        )}
      </Flex>
    </>
  )
}

const TableSpinner: FunctionComponent<React.PropsWithChildren<{ colSpan: number }>> = ({
  colSpan
}) => (
  <Tr position='absolute' top={0} left={0} bottom={0} width='calc(100vw - var(--chakra-space-16))'>
    <Td display='block' h='full' colSpan={colSpan} p={0} bg='blackAlpha.300'>
      <Center h='full'>
        <Spinner />
      </Center>
    </Td>
  </Tr>
)

const subStatusFilter =
  (selectedStatus: string, selectedSubStatus: CustomerInfoSubStatus | null) =>
  (rentalApp: UpupRentalApplicationsQuery['rental_applications'][number]) => {
    if (selectedStatus !== RENTAL_APPLICATION_STATUSES.PENDING_CUSTOMER_INFORMATION) {
      return true
    }

    if (selectedSubStatus === 'all') {
      return true
    }

    if (selectedSubStatus === 'needs_coapplicant') {
      return rentalApp.required_coapplicant_at !== null || rentalApp.required_cosigner_at !== null
    }

    const allSubmitted = rentalApp.lead_group.lead_group_users.every(
      (u) => u.rental_application_submitted_at !== null
    )

    if (selectedSubStatus === 'pending_submission') {
      return !allSubmitted
    }

    const allCreditChecked = rentalApp.lead_group.lead_group_users.every(
      (u) =>
        u.user.credit_reports.filter((r) => !['pending', 'error', 'ignored'].includes(r.status))
          .length >= 1
    )

    if (selectedSubStatus === 'pending_credit_check') {
      return allSubmitted && !allCreditChecked
    }

    const hasCriminalAndEvictionCheck = rentalApp.lead_group.lead_group_users.every(
      (u) => u.user.criminal_reports.length >= 1 && u.user.eviction_reports.length >= 1
    )

    if (selectedSubStatus === 'pending_criminal_and_eviction') {
      return allSubmitted && allCreditChecked && !hasCriminalAndEvictionCheck
    }

    if (selectedSubStatus === 'pending_supplemental_docs') {
      // if all submitted and all credit checked and doesn't need coapplicant and all have a criminal
      // and eviction report, then the only reason they should be in pending_customer_information is
      // if they have a supplemental doc request, so we can optimistically assume there is a
      // supplemental doc request
      return allSubmitted && allCreditChecked && hasCriminalAndEvictionCheck
    }

    return true
  }

export default ApplicationsTable
