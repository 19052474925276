import React, { useState, useEffect } from 'react'
import {
  Tr,
  Td,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Center,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'
import { requiredLeaseDetails } from '@homevest/utils'
import { OccupantWithId } from '@homevest/types/rental-applications'

import SelectDropdown from '../../Common/SelectDropdown'
import RemoveButton from '../../Common/RemoveButton'
import { isOccupantAgeValid } from 'lib/required-lease-details'

const { OCCUPANT_RELATIONS, MAX_OCCUPANT_AGE } = requiredLeaseDetails

type EditOccupantsRowProps = {
  occupant: OccupantWithId
  removeOccupant: (occupantId: string) => void
  editOccupant: (occupantId: string, updatedOccupant: OccupantWithId) => void
  skipValidation: boolean
}

const EditOccupantsRow: React.FC<React.PropsWithChildren<EditOccupantsRowProps>> = ({
  occupant,
  removeOccupant,
  editOccupant,
  skipValidation
}) => {
  const [id, _setId] = useState(occupant.id)
  const [name, setName] = useState(occupant.name)
  const [age, setAge] = useState<number | null>(occupant.age)
  const [relation, setRelation] = useState(occupant.relation)

  useEffect(() => {
    handleEdit()
  }, [name, age, relation])

  const handleEdit = () => {
    editOccupant(id, { id, name, age: age!, relation })
  }

  const isAgeValid = age !== null && (isOccupantAgeValid(age) || skipValidation)

  return (
    <Tr>
      <Td>
        <FormControl isRequired isInvalid={!name}>
          <Input value={name} onChange={(evt) => setName(evt.target.value)} />
          <FormErrorMessage>Name is required</FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired isInvalid={!isAgeValid}>
          <NumberInput
            value={age ?? undefined}
            onChange={(value) => setAge(value ? parseInt(value) : null)}
            min={0}
            max={120}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>Please enter an age between 0 and {MAX_OCCUPANT_AGE}</FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired isInvalid={!relation}>
          <SelectDropdown
            id='relation'
            options={Object.values(OCCUPANT_RELATIONS)}
            value={relation}
            onChange={(evt) => setRelation(evt.target.value)}
          />
          <FormErrorMessage>Relation is required</FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <Center>
          <RemoveButton onClick={() => removeOccupant(id)} />
        </Center>
      </Td>
    </Tr>
  )
}

export default EditOccupantsRow
