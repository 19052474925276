import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import { rentals } from '@homevest/utils'
import axios from 'lib/axios'
import { GetRentalCashoutDetailsQuery } from 'graphql/generated'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BaseGrid } from 'components/TailwindUIToolkit'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { debounce, startCase } from 'lodash'
import { formatMoney, formatPercent } from 'lib/numbers'

interface CashoutFormProps {
  data: GetRentalCashoutDetailsQuery
}

interface CashoutAmounts {
  final_wallet_amount: number
  write_off_amount: number
  clawback_amount: number
  wallet_fee_amount: number
  total_investment_amount: number
  paid_out_amount: number
}
interface WalletSummary extends CashoutAmounts {
  outstanding_payments: number
  oustanding_liabilities: number
}

interface CashoutPreview {
  summary: CashoutAmounts
}

const SummarySection = ({
  summary,
  isVanilla,
  finalSharingPercentage
}: {
  summary: WalletSummary
  isVanilla: boolean
  finalSharingPercentage: number | null | undefined
}) => {
  return (
    <HStack justifyContent='space-evenly' alignItems='start'>
      {!isVanilla && (
        <Stack justifyContent='start'>
          <HStack justifyContent='space-between'>
            <div className='flex-child flex'>Total Contributions</div>
            <div className='flex-child flex'>
              {formatMoney(Number(summary.total_investment_amount), 2, '$')}
            </div>
          </HStack>
          <HStack justifyContent='space-between' paddingTop={4}>
            <div className='flex-child flex'>Total Earnings</div>
            <div className='flex-child flex'>
              {formatMoney(
                Number(summary.final_wallet_amount) - summary.total_investment_amount,
                2,
                '$'
              )}
            </div>
          </HStack>
          <HStack justifyContent='space-between' paddingTop={4}>
            <div className='flex-child flex'>Final Sharing Percentage</div>
            <div className='flex-child flex'>
              {formatPercent(finalSharingPercentage ?? null, 4)}
            </div>
          </HStack>
        </Stack>
      )}
      <Stack justifyContent='start' fontWeight='bold'>
        <HStack justifyContent='space-between'>
          <div className='flex-child flex'>
            Total {isVanilla ? 'Security Deposit' : 'Wallet Value'}
          </div>
          <div className='flex-child flex'>
            {formatMoney(Number(summary.final_wallet_amount), 2, '$')}
          </div>
        </HStack>
        <HStack justifyContent='space-between' paddingTop={4}>
          <div className='flex-child flex'>Unused Funds</div>
          <div className='flex-child flex' style={{ color: 'green' }}>
            {formatMoney(summary.outstanding_payments, 2, '$')}
          </div>
        </HStack>
        <HStack justifyContent='space-between' paddingTop={4}>
          <div className='flex-child flex'>Unpaid Charges</div>
          <div className='flex-child flex' style={{ color: 'red' }}>
            {formatMoney(summary.oustanding_liabilities, 2, '$')}
          </div>
        </HStack>
        {!isVanilla && (
          <HStack justifyContent='space-between' paddingTop={4}>
            <div className='flex-child flex'>Wallet Fee</div>
            <div className='flex-child flex' style={{ color: 'red' }}>
              {formatMoney(Number(summary.wallet_fee_amount), 2, '$')}
            </div>
          </HStack>
        )}
        <Divider />
        <HStack justifyContent='space-between' paddingTop={4}>
          <div className='flex-child flex'>Bad Debt Write-Off</div>
          <div className='flex-child flex'>
            {summary.write_off_amount && formatMoney(Number(summary.write_off_amount), 2, '$')}
          </div>
        </HStack>
        <HStack justifyContent='space-between' paddingTop={4}>
          <div className='flex-child flex'>Total Refund</div>
          <div
            className='flex-child flex'
            style={{
              color: Number(summary.paid_out_amount) > 0 ? 'green' : 'red'
            }}
          >
            {formatMoney(Number(summary.paid_out_amount), 2, '$')}
          </div>
        </HStack>
      </Stack>
    </HStack>
  )
}

const fetchCashoutPreview = async (rentalId: string, walletFee?: number) => {
  let url = `/admin/rentals/${rentalId}/preview_cashout`
  if (walletFee !== undefined) {
    url += `?wallet_fee=${walletFee}`
  }
  const res = await axios.get(url)
  const data = res.data
  return data
}

interface CashoutLedgerRow {
  amount_remaining: number
  type: 'payment' | 'credit' | 'liability'
  liability_label: string
  date: string
  resource: {
    note?: string | null
  }
}

const columns: ColDef<CashoutLedgerRow>[] = [
  {
    headerName: 'Date',
    field: 'date',
    filter: 'agDateColumnFilter',
    cellRenderer: (params: ICellRendererParams<CashoutLedgerRow>) => {
      if (params.data?.resource.note) {
        return (
          <Tooltip hasArrow shouldWrapChildren label={params.data.resource.note} placement='right'>
            <HStack>
              <PencilSquareIcon height={16} />
              <span>{params.value}</span>
            </HStack>
          </Tooltip>
        )
      }
      return params.value
    }
  },
  {
    headerName: 'Type',
    field: 'type',
    valueFormatter: (params) => startCase(params.value)
  },

  {
    headerName: 'Liability Type',
    field: 'liability_label'
  },
  {
    headerName: 'Amount',
    field: 'amount_remaining',
    cellStyle: (params) => {
      if (params.data?.type === 'liability') {
        return { color: 'red' }
      }
      if (params.data?.type === 'payment' || params.data?.type === 'credit') {
        return { color: 'green' }
      }
      return null
    },
    valueFormatter: (params) => {
      return formatMoney(Number(params.value), 2, '$')
    }
  }
]

export const CashoutForm: FC<React.PropsWithChildren<CashoutFormProps>> = ({ data }) => {
  const { rentalId } = useParams<{ rentalId: string }>()

  const [cashoutPreview, setCashoutPreview] = useState<CashoutPreview>()
  const [walletFeePercentage, setWalletFeePercentage] = useState(10)
  const [walletFeeAmount, setWalletFeeAmount] = useState<number>()
  const [skipValidation, setSkipValidation] = useState(false)
  const [shouldRetainWallet, setShouldRetainWallet] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const toast = useToast()

  const cashoutStatus = data.rentals_by_pk?.cashout?.status
  const canCashout = cashoutStatus === 'ledger_finalized'
  const isVanilla = data.rentals_by_pk?.rental_program_type === rentals.RENTAL_PROGRAM_TYPES.VANILLA
  const finalSharingPercentage = data.rentals_by_pk?.current_statement?.ending_premium_factor

  const items = useMemo(() => {
    return [
      ...(data.unallocated_credits ?? []).map((credit) => ({
        ...credit,
        type: 'credit',
        liability_label: credit.liability_type?.name || ''
      })),
      ...(data.unpaid_liabilities ?? []).map((liability) => ({
        ...liability,
        type: 'liability',
        liability_label: liability.liability_type?.name || ''
      })),
      ...(data.unallocated_payments ?? []).map((payment) => ({
        ...payment,
        type: 'payment',
        liability_label: payment.liability_type?.name || ''
      }))
    ] as CashoutLedgerRow[]
  }, [data])

  // on mount, set the wallet fee amount
  useEffect(() => {
    setLoading(true)
    fetchCashoutPreview(rentalId, walletFeeAmount).then((data) => {
      setCashoutPreview(data)
      setWalletFeeAmount(data.summary.wallet_fee_amount)
      setLoading(false)
    })
  }, [rentalId, walletFeeAmount])

  const debouncedFetchCashoutPreview = useMemo(() => {
    return debounce(async (rentalId, walletFeeAmount) => {
      setLoading(true)
      const data = await fetchCashoutPreview(rentalId, walletFeeAmount)
      setLoading(false)
      setCashoutPreview(data)
      setWalletFeePercentage(
        Number(
          ((data.summary.wallet_fee_amount / data.summary.final_wallet_amount) * 100).toFixed(2)
        )
      )
    }, 500)
  }, [])

  useEffect(() => {
    debouncedFetchCashoutPreview(rentalId, walletFeeAmount)
  }, [rentalId, walletFeeAmount, debouncedFetchCashoutPreview])

  useEffect(() => {
    if (shouldRetainWallet) {
      const walletAmount = cashoutPreview?.summary.final_wallet_amount
      const clawbackAmount = cashoutPreview?.summary.clawback_amount
      if (walletAmount && clawbackAmount) {
        setWalletFeeAmount(Number((walletAmount - clawbackAmount).toFixed(2)))
      }
    }
  }, [
    shouldRetainWallet,
    cashoutPreview?.summary.final_wallet_amount,
    cashoutPreview?.summary.clawback_amount
  ])

  useEffect(() => {
    if (data?.rentals_by_pk?.lease_end_reason === 'purchasing_home') {
      setWalletFeeAmount(0)
    }
  }, [data?.rentals_by_pk?.lease_end_reason])

  if (!cashoutPreview) {
    return <Spinner />
  }

  const handleCashoutRental = async () => {
    const toastId = toast({
      title: 'Cashout in progress',
      status: 'info',
      position: 'top',
      duration: null
    })
    try {
      await axios.post(`/admin/rentals/${rentalId}/finalize_adjustments`, {
        wallet_fee: walletFeeAmount,
        skip_validation: skipValidation ? 1 : 0
      })
      toast({
        id: toastId,
        title: 'Cashout Successful!',
        status: 'success',
        position: 'top',
        duration: 5000
      })

      history.replace(`/rent-roll/${rentalId}`)
    } catch (e: any) {
      toast({
        id: toastId,
        title: `Cashout Failed. ${e.message}`,
        status: 'error',
        position: 'top',
        duration: 5000
      })
    }
  }

  const user = data?.rentals_by_pk?.primary_rental_user[0]?.user

  const title = ['adjustments_finalized', 'paid_out'].includes(cashoutStatus)
    ? 'Finalized Cashout'
    : 'Cashout Preview'

  const cashoutButtonTooltipLabel = () => {
    if (cashoutStatus === 'ledger_finalized') {
      return 'Cashout is ready to be finalized'
    }
    if (cashoutStatus === 'adjustments_finalized') {
      return 'Cashout has already been finalized'
    }
    if (cashoutStatus === 'cashed_out') {
      return 'Cashout has already been paid out'
    }
    return 'Cashout is not ready to be finalized'
  }

  const isBeingPaidOut = Number(cashoutPreview.summary.paid_out_amount) > 0

  return (
    <Stack w='100%' margin='auto' px='auto'>
      <Heading as='h1' size='lg' my={4}>
        {title} for {user?.first_name} {user?.last_name}
      </Heading>
      <BaseGrid<CashoutLedgerRow>
        optionOverrides={{
          domLayout: 'autoHeight'
        }}
        columns={columns}
        rowData={items}
        containerStyle={{
          maxWidth: '1000px'
        }}
        onGridReady={(evt) => {
          evt.api.sizeColumnsToFit()
        }}
      />
      {!isVanilla && (
        <HStack justifyContent='center'>
          <FormLabel>Wallet Fee Amount</FormLabel>
          <FormControl maxW='200px' isDisabled={!canCashout}>
            <Tooltip label={`${walletFeePercentage}%`}>
              <NumberInput
                flexShrink={1}
                value={walletFeeAmount}
                onChange={(_, value) => {
                  setShouldRetainWallet(false)
                  setWalletFeeAmount(value)
                }}
                format={(value) => `$${Number(value)}`}
                min={0}
                max={cashoutPreview.summary.final_wallet_amount}
                step={0.01}
              >
                <NumberInputField textAlign='right' />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Tooltip>
          </FormControl>
          <FormLabel>Retain Wallet?</FormLabel>
          <FormControl maxW='200px'>
            <Checkbox
              isDisabled={!canCashout || !isBeingPaidOut || loading}
              isChecked={shouldRetainWallet || !isBeingPaidOut}
              onChange={(e) => {
                setShouldRetainWallet(e.target.checked)
              }}
            />
          </FormControl>
        </HStack>
      )}
      <Divider />
      <SummarySection
        summary={{
          ...cashoutPreview.summary,
          oustanding_liabilities: items
            .filter((item) => item.type === 'liability')
            .reduce((acc, item) => acc + item.amount_remaining, 0),
          outstanding_payments: items
            .filter((item) => item.type === 'payment' || item.type === 'credit')
            .reduce((acc, item) => acc + item.amount_remaining, 0)
        }}
        isVanilla={isVanilla}
        finalSharingPercentage={finalSharingPercentage}
      />

      <Divider />
      <HStack>
        <Tooltip
          // display='flex'
          shouldWrapChildren
          label={cashoutButtonTooltipLabel()}
        >
          <Button
            onClick={handleCashoutRental}
            colorScheme='green'
            size='lg'
            loadingText='Getting that money...'
            isLoading={loading}
            isDisabled={!canCashout}
            width='100%'
          >
            Cashout {formatMoney(Number(cashoutPreview.summary.paid_out_amount), 2, '$')} to{' '}
            {user?.first_name} {user?.last_name}
          </Button>
        </Tooltip>
        <FormControl maxW='200px'>
          <Checkbox
            // isDisabled={!canCashout}
            isChecked={skipValidation}
            onChange={(e) => {
              setSkipValidation(e.target.checked)
            }}
          >
            Skip Validation
          </Checkbox>
        </FormControl>
      </HStack>
    </Stack>
  )
}
