import React, { useContext } from 'react'
import { IconButton, Text, Tr, Td } from '@chakra-ui/react'
import { Trash as TrashIcon } from 'styled-icons/heroicons-solid'
import { startCase } from 'lodash'

import UtilityResponsibilitySelect from './UtilityResponsibilitySelect'
import { UtilityTypesContext } from '../../UtilityTypesContext'

type EditUtilityDetailsRowPropTypes = {
  utilityTypeConfig: {
    utilityTypeId: string
    defaultResponsibleParty: string
  }
  setResponsibleParty: (newResponsibleParty: string) => void
  removeUtilityType: () => void
}

const EditUtilityDetailsRow: React.FC<React.PropsWithChildren<EditUtilityDetailsRowPropTypes>> = ({
  utilityTypeConfig: { utilityTypeId, defaultResponsibleParty },
  setResponsibleParty,
  removeUtilityType
}) => {
  const { utilityTypes } = useContext(UtilityTypesContext)
  const utilityName = utilityTypes.find((type) => type.id === utilityTypeId)?.name ?? ''

  return (
    <Tr>
      <Td>
        <Text as='b'>{startCase(utilityName)}</Text>
      </Td>
      <Td>
        <UtilityResponsibilitySelect
          id={`${utilityTypeId}-utility-responsibility-select`}
          value={defaultResponsibleParty}
          setValue={setResponsibleParty}
          showLabel={false}
        />
      </Td>
      <Td>
        <IconButton
          aria-label='Delete'
          icon={<TrashIcon className='h-5 w-5' />}
          onClick={removeUtilityType}
        />
      </Td>
    </Tr>
  )
}

export default EditUtilityDetailsRow
