import React from 'react'
import { useHistory } from 'react-router-dom'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { Text, Stack, HStack, Button, Center, Flex, Spacer, Divider } from '@chakra-ui/react'

import { CsvUploadValidationError } from 'types/Utilities'
import ErrorsTable from './ErrorsTable'

type UploadResultViewProps = {
  isSuccessful: boolean
  resetForm: () => void
  errors: CsvUploadValidationError[]
}

const UploadResultView: React.FC<React.PropsWithChildren<UploadResultViewProps>> = ({
  isSuccessful,
  resetForm,
  errors
}) => {
  const history = useHistory()
  console.log('errors', errors)

  if (isSuccessful) {
    return (
      <Center>
        <Stack spacing={4}>
          <Center>
            <CheckCircleIcon color='green' className='h-10 w-10' />
          </Center>
          <Text align={'center'}> Your upload was successful!</Text>
          <HStack>
            <Button onClick={() => history.push('/utilities/chargebacks-manager')}>
              Back to Chargebacks
            </Button>
            <Button colorScheme={'teal'} onClick={resetForm}>
              Upload Another CSV
            </Button>
          </HStack>
        </Stack>
      </Center>
    )
  }

  return (
    <Center>
      <Stack spacing={4}>
        <Center>
          <XCircleIcon color='red' className='h-10 w-10' />
        </Center>
        <Text align={'center'}> Your upload could not be completed!</Text>

        <Flex>
          <Button onClick={() => history.push('/utilities/chargebacks-manager')}>
            Back to Chargebacks
          </Button>
          <Spacer />
          <Button colorScheme={'teal'} onClick={resetForm}>
            Try Again
          </Button>
        </Flex>
        <Divider />
        <ErrorsTable errors={errors} />
      </Stack>
    </Center>
  )
}

export default UploadResultView
