import React, { useMemo, useState } from 'react'
import { formatDistanceToNowStrict } from 'date-fns'
import { startCase } from 'lodash'

import { capabilities } from '@homevest/utils'
import { useSelector } from 'react-redux'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Heading,
  Link,
  Spinner,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer
} from '@chakra-ui/react'

import { Delinquencies, useUpupDelinquencyListQuery } from 'graphql/generated'

import { hasCapability } from 'lib/admin-perms'
import axios from 'lib/axios'
import uninterceptedAxios from 'lib/axios'
import { formatMoney } from 'lib/numbers'
const { DELINQUENCY_MANAGER } = capabilities.CAPABILITY_TYPES

export default function DelinquencyManager() {
  const isDelinquencyManager = useSelector((store: any) =>
    hasCapability(store.admin, DELINQUENCY_MANAGER)
  )

  const context = useMemo(
    () => ({
      suspense: false
    }),
    []
  )
  const [{ data }] = useUpupDelinquencyListQuery({
    context
  })

  if (!data) {
    return <Spinner />
  }

  if (!isDelinquencyManager) {
    return (
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>Access Denied!</AlertTitle>
        <AlertDescription>You must be a delinquency manager to use this tool.</AlertDescription>
      </Alert>
    )
  }

  return (
    <Box w='100%' p={1}>
      <Heading as='h3' size='xl' width='100%'>
        Delinquency Manager
      </Heading>
      <React.Suspense fallback={<Spinner />}>
        <DelinquencyManagerViewer delinquencies={data.delinquencies} />
      </React.Suspense>
    </Box>
  )
}

function DelinquencyManagerViewer({ delinquencies }: { delinquencies: Array<any> }) {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const activeDelinquencies: Array<Delinquencies> = delinquencies.filter(
    (d: any) => d.status === 'active'
  )

  async function handleGenerate() {
    try {
      setErrorMessage('')
      setSuccessMessage('')
      setIsLoading(true)
      await axios.post('/admin/system/cloud_enqueue', {
        queue: 'upandup-gcf-v2',
        url: 'https://delinquencies-scheduler-d6oubofcqa-uc.a.run.app'
      })
      setSuccessMessage(
        'A new CSV report is generating, please wait about 20m for it to process Rent Roll.'
      )
    } catch (err: any) {
      setErrorMessage(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleReport() {
    try {
      setErrorMessage('')
      setSuccessMessage('')
      setIsLoading(true)
      const { data } = await uninterceptedAxios.post(
        'https://delinquencies-generate-csv-d6oubofcqa-uc.a.run.app'
      )
      setSuccessMessage(
        `The CSV report can be found <a style="text-decoration: underline" href='${data.url}'>here</a>. The link is valid for 30 days.`
      )
    } catch (err: any) {
      setErrorMessage(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Stack spacing={5}>
        <Stack spacing={4} direction='row' align='center'>
          {errorMessage && (
            <Alert status='error'>
              <AlertIcon />
              <AlertTitle>Whoops!</AlertTitle>
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}

          {successMessage && (
            <Alert status='success'>
              <AlertIcon />
              <AlertTitle>Woo Hoo!</AlertTitle>
              <AlertDescription
                dangerouslySetInnerHTML={{ __html: successMessage }}
              ></AlertDescription>
            </Alert>
          )}
        </Stack>
        <Stack spacing={4} direction='row' align='center'>
          <Button colorScheme='teal' isLoading={isLoading} onClick={handleGenerate}>
            Generate New Report
          </Button>
          <Button colorScheme='teal' isLoading={isLoading} onClick={handleReport}>
            Download CSV
          </Button>
        </Stack>
        <Stack spacing={10}>
          <Heading as='h4' size='l' width='100%'>
            Active Delinquencies
          </Heading>
          <TableContainer>
            <Table variant='striped' colorScheme='teal'>
              <TableCaption>Active Delinquencies</TableCaption>
              <Thead>
                <Tr>
                  <Th>Address</Th>
                  <Th>Rental Status</Th>
                  <Th>Eviction Status</Th>
                  <Th>Balance At</Th>
                  <Th>Balance Owed</Th>
                  <Th>Balance Owed w/o Option Premiums</Th>
                  <Th>Primary Tenant</Th>
                </Tr>
              </Thead>
              <Tbody>
                {activeDelinquencies.map((d: any) => (
                  <Tr key={d.id}>
                    <Td>
                      <Link href={'/rent-roll/' + d.rental.id} isExternal>
                        {d.rental.property.real_estate_acquisition.opportunity.display_line_1}
                      </Link>
                    </Td>
                    <Td>{startCase(d.rental.status)}</Td>
                    <Td>{startCase(d.rental.evictions[0]?.status || 'None')}</Td>
                    <Td>{formatDistanceToNowStrict(new Date(d.balance_at))} ago</Td>
                    <Td>{formatMoney(d.balance_owed, 2, '$')}</Td>
                    <Td>{formatMoney(d.delinquency_balance_owed, 2, '$')}</Td>
                    <Td>
                      {d.rental.user.first_name} {d.rental.user.last_name}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </div>
  )
}
