import React, { useEffect, useState } from 'react'
import {
  Button,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Textarea,
  useToast
} from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { useMutation } from '@tanstack/react-query'
import { rentalApplications, rentals } from '@homevest/utils'

import { useRentalAppQualification } from './useRentalAppQualification'
import axios from 'lib/axios'
import { CreateRentalApplicationDecisionPayload } from '@homevest/types/rental-application-decisions'
import { RentalProgramType } from '@homevest/types/rentals'

const { DEPOSIT_MULTIPLES } = rentalApplications
const { RENTAL_PROGRAM_TYPES } = rentals

export const ApprovalModal = ({
  rentalApplicationId,
  isOpen,
  onClose
}: {
  rentalApplicationId: string
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ApproveRentalAppForm rentalApplicationId={rentalApplicationId} onClose={onClose} />
      </ModalContent>
    </Modal>
  )
}

const ApproveRentalAppForm = ({
  rentalApplicationId,
  onClose
}: {
  rentalApplicationId: string
  onClose: () => void
}) => {
  const { data, isFetching } = useRentalAppQualification(rentalApplicationId)
  const [depositMultiple, setDepositMultiple] = useState(
    data?.deposit_rent_multiple ?? (DEPOSIT_MULTIPLES.STANDARD_DEPOSIT_RENT_MULTIPLE as number)
  )
  const [notes, setNotes] = useState('')

  const toast = useToast()

  const { isLoading, mutate: approve } = useMutation({
    mutationFn: async () => {
      const payload: CreateRentalApplicationDecisionPayload = {
        rental_application_id: rentalApplicationId,
        is_approved: true,
        deposit_rent_multiple: depositMultiple,
        notes: notes
      }

      await axios.post(`/admin/rental_application_decisions`, payload)
    },
    onSuccess: () => {
      toast({
        title: 'Application Approved',
        description: 'The application has been successfully approved',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })
      onClose()
    },
    onError: (error: any) => {
      toast({
        title: 'Error',
        description: `Something went wrong when approving this application, please contact engineering: ${
          error.message ?? ''
        }`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })

      Sentry.captureException(error)
    }
  })

  useEffect(() => {
    if (data?.deposit_rent_multiple) {
      setDepositMultiple(data.deposit_rent_multiple)
    }
  }, [data])

  return (
    <>
      <ModalHeader>Ready to approve this application?</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack>
          <FormLabel>Deposit Rent Multiple</FormLabel>
          <DepositMultipleSelect
            value={depositMultiple}
            onChange={(e) => setDepositMultiple(Number(e.target.value))}
            rentalProgramType={data?.rental_program_type}
          />
          <FormLabel>
            Notes
            <Textarea value={notes} onChange={(e) => setNotes(e.target.value)} />
          </FormLabel>
        </Stack>
      </ModalBody>
      <ModalFooter gap={2} flexDir='column'>
        <Button
          colorScheme='teal'
          isLoading={isFetching || isLoading}
          isDisabled={isFetching || isLoading}
          w='full'
          onClick={() => approve()}
        >
          Approve
        </Button>
      </ModalFooter>
    </>
  )
}

const DepositMultipleSelect = ({
  value,
  onChange,
  rentalProgramType
}: {
  value: number
  onChange: React.ChangeEventHandler<HTMLSelectElement>
  rentalProgramType?: RentalProgramType
}) => {
  let options: React.ReactNode
  switch (rentalProgramType) {
    case RENTAL_PROGRAM_TYPES.VANILLA:
      options = (
        <>
          <option value={DEPOSIT_MULTIPLES.VANILLA_STANDARD_DEPOSIT_RENT_MULTIPLE}>
            Standard Deposit: {DEPOSIT_MULTIPLES.VANILLA_STANDARD_DEPOSIT_RENT_MULTIPLE}x Rent
          </option>
          <option value={DEPOSIT_MULTIPLES.VANILLA_ADDITIONAL_DEPOSIT_RENT_MULTIPLE}>
            Additional Deposit: {DEPOSIT_MULTIPLES.VANILLA_ADDITIONAL_DEPOSIT_RENT_MULTIPLE}x Rent
          </option>
        </>
      )
      break
    case RENTAL_PROGRAM_TYPES.UPANDUP:
    default:
      options = (
        <>
          <option value={DEPOSIT_MULTIPLES.STANDARD_DEPOSIT_RENT_MULTIPLE}>
            Standard Deposit: {DEPOSIT_MULTIPLES.STANDARD_DEPOSIT_RENT_MULTIPLE}x Rent
          </option>
          <option value={DEPOSIT_MULTIPLES.ADDITIONAL_DEPOSIT_RENT_MULTIPLE}>
            Additional Deposit: {DEPOSIT_MULTIPLES.ADDITIONAL_DEPOSIT_RENT_MULTIPLE}x Rent
          </option>
        </>
      )
  }

  return (
    <Select value={value} onChange={onChange}>
      {options}
    </Select>
  )
}
