import {
  RECEIVED_PROPERTIES,
  RECEIVED_PROPERTY,
  RECEIVED_PROPERTY_SELLER_AGENTS,
  RECEIVED_ALERT,
  DISMISS_ALERT,
  CLEAR_PROPERTY_SELLER_AGENT_DATA
} from './actions'

import { append } from '../../lib/map'

export default function reducer(
  state = {
    data: new Map(),
    priority: new Map(),
    sellerAgents: [],
    sellerAgentMLSIds: [],
    primaryStatusByAgentId: {},
    errorAlert: {
      message: '',
      alertColor: 'danger',
      show: false
    }
  },
  action
) {
  switch (action.type) {
    case RECEIVED_PROPERTIES: {
      return {
        ...state,
        data: action.properties
      }
    }

    case RECEIVED_PROPERTY: {
      const { property } = action

      return {
        ...state,
        data: append(state.data, property.id, property)
      }
    }

    case RECEIVED_PROPERTY_SELLER_AGENTS: {
      const { sellerAgents } = action
      const primaryStatusByAgentId = {}
      sellerAgents.forEach(({ id, is_primary_agent: isPrimaryAgent }) => {
        primaryStatusByAgentId[id] = isPrimaryAgent
      })
      const sellerAgentMLSIds = sellerAgents.map((agent) => agent.mls_id)

      return {
        ...state,
        sellerAgents,
        primaryStatusByAgentId,
        sellerAgentMLSIds
      }
    }

    case RECEIVED_ALERT: {
      const { message, alertColor } = action

      return {
        ...state,
        errorAlert: {
          message,
          alertColor,
          show: true
        }
      }
    }

    case DISMISS_ALERT: {
      return {
        ...state,
        errorAlert: {
          ...state.errorAlert,
          show: false
        }
      }
    }

    case CLEAR_PROPERTY_SELLER_AGENT_DATA: {
      return {
        ...state,
        sellerAgents: [],
        primaryStatusByAgentId: {}
      }
    }

    default:
      return state
  }
}
