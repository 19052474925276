import React, { FunctionComponent } from 'react'

type Props = {
  currentPage: number
  pageCount: number
  onFirst: () => void
  onPrev: () => void
  onNext: () => void
  onLast: () => void
}

const TablePagination: FunctionComponent<React.PropsWithChildren<Props>> = ({
  currentPage,
  pageCount,
  onFirst,
  onPrev,
  onNext,
  onLast
}) => (
  <div className='flex items-center gap-2'>
    <button className='w-12 rounded border p-1' onClick={onFirst} disabled={currentPage === 0}>
      {'<<'}
    </button>
    <button className='w-12 rounded border p-1' onClick={onPrev} disabled={currentPage === 0}>
      {'<'}
    </button>
    <button
      className='w-12 rounded border p-1'
      onClick={onNext}
      disabled={currentPage >= pageCount - 1}
    >
      {'>'}
    </button>
    <button
      className='w-12 rounded border p-1'
      onClick={onLast}
      disabled={currentPage >= pageCount - 1}
    >
      {'>>'}
    </button>
    <div className='flex items-center gap-1 pl-2'>
      <div>Page</div>
      <strong>{currentPage + 1}</strong>
      of {pageCount}
    </div>
  </div>
)

export default TablePagination
