import { Box, ButtonGroup, Flex, IconButton, Select, Text } from '@chakra-ui/react'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/20/solid'
import React, { FunctionComponent } from 'react'

type Props = {
  totalResults: number
  pageSize: number
  pageIndex: number
  onIndex: (index: number) => void
  onSize: (size: number) => void
}

const TablePagination: FunctionComponent<React.PropsWithChildren<Props>> = ({
  totalResults,
  pageSize,
  pageIndex,
  onIndex,
  onSize
}) => {
  const pageCount = Math.ceil(totalResults / pageSize)
  const pageStart = pageIndex * pageSize + 1
  const pageEnd = Math.min(pageStart + pageSize - 1, totalResults)

  return (
    <Flex direction='row' align='center' gap={2}>
      <Flex direction='row' align='center' flexGrow={1}>
        <Text fontSize='sm'>
          Showing result <b>{pageStart}</b> to <b>{pageEnd}</b> of <b>{totalResults}</b>
        </Text>
      </Flex>
      <Box>
        <Select
          size='sm'
          value={pageSize}
          onChange={({ target }) => onSize(parseInt(target.value))}
        >
          <option value={10}>10 / page</option>
          <option value={25}>25 / page</option>
          <option value={50}>50 / page</option>
        </Select>
      </Box>
      <ButtonGroup size='sm' alignItems='center' isAttached>
        <IconButton
          aria-label='First'
          mr='-px'
          disabled={pageIndex === 0}
          onClick={() => onIndex(0)}
          icon={
            <Box h={4} w={4}>
              <ChevronDoubleLeftIcon />
            </Box>
          }
        />
        <IconButton
          aria-label='Prev'
          mr='-px'
          disabled={pageIndex === 0}
          onClick={() => onIndex(Math.max(pageIndex - 1, 0))}
          icon={
            <Box h={4} w={4}>
              <ChevronLeftIcon />
            </Box>
          }
        />
        <Flex
          h={8}
          px={4}
          direction='row'
          align='center'
          borderTopWidth={1}
          borderBottomWidth={1}
          borderTopColor='gray.100'
          borderBottomColor='gray.100'
        >
          <Text fontSize='sm'>
            Page <b>{pageIndex + 1}</b> of <b>{pageCount}</b>
          </Text>
        </Flex>
        <IconButton
          aria-label='Next'
          mr='-px'
          disabled={pageIndex >= pageCount - 1}
          onClick={() => onIndex(Math.min(pageIndex + 1, pageCount - 1))}
          icon={
            <Box h={4} w={4}>
              <ChevronRightIcon />
            </Box>
          }
        />
        <IconButton
          aria-label='Last'
          disabled={pageIndex >= pageCount - 1}
          onClick={() => onIndex(pageCount - 1)}
          icon={
            <Box h={4} w={4}>
              <ChevronDoubleRightIcon />
            </Box>
          }
        />
      </ButtonGroup>
    </Flex>
  )
}

export default TablePagination
