import { standardizeAddress } from './addressStandardizationFunctions'
import { UPUP } from './helpers'
import {
  RealEstatAcquisition,
  TypedProperties,
  TypedRealEstatAcquisitions
} from './utility_chargeback.types'

export const memo_to_gl_account_id = {
  Gas: 'Utilities - Gas',
  Stormwater: 'Utilities - Stormwater',
  Water: 'Utilities - Water',
  Sewer: 'Utilities - Sewer',
  Trash: 'Utilities - Trash',
  Electricity: 'Utilities - Electric',
  'Service Fee': 'Utilities - Service Fee',
  Activation: 'Utilities - Activation/Deactivation',
  'Inadvertent Fee': 'Utilities - Inadvertent Fee/Reschedule',
  'Gas Deposit': 'Utility Deposit - Gas Deposit',
  'Sewer Base 2': 'Utilities - Sewer',
  'Storm Water Drainage': 'Utilities - Stormwater',
  'Water Deposit': 'Utility Deposit - Water Deposit',
  'Sewer Deposit': 'Utility Deposit - Sewer Deposit',
  'Electricity Deposit': 'Utility Deposit - Electric Deposit',
  'Trash Deposit': 'Utility Deposit - Trash Deposit',
  DeadDealCost: '7200-000 Abandoned Deals'
}

export function configureProperties(property_data: any) {
  const properties: TypedProperties = {}

  property_data.forEach((transfer: any) => {
    const mls_listing = transfer.property.real_estate_acquisition.mls_listing
    const streetAddr = mls_listing.display_line_1
    const correctedAddress = standardizeAddress(streetAddr)

    const [start_date, end_date] = [UPUP.date(transfer.start_date), UPUP.date(transfer.end_date)]

    if (!properties[correctedAddress]) {
      // this only needs to be calculated once
      const propertyOccupancy = transfer.property.rentals.map((rental: any) => {
        const [final_liability_date, occupancy_date] = [
          UPUP.date(rental.final_liability_date),
          UPUP.date(rental.occupancy_date)
        ]

        const rental_id = rental.id
        return {
          occupancy_date,
          final_liability_date,
          rental_id
        }
      })
      properties[correctedAddress] = {
        ...mls_listing,
        propertyOwnership: [],
        propertyOccupancy: propertyOccupancy
      }
    }

    properties[correctedAddress].propertyOwnership.push({
      owner_id: transfer.llc.id,
      start_date: start_date ?? new Date(),
      end_date: end_date ?? new Date()
    })
  })

  return properties
}

export function configureRealEsateAcquisitions(
  real_estate_acquisition_data: RealEstatAcquisition[]
): TypedRealEstatAcquisitions {
  const output: TypedRealEstatAcquisitions = {}

  real_estate_acquisition_data.forEach((property) => {
    try {
      const { llc_id, status } = property
      const { display_line_1 } = property.mls_listing

      const correctedAddress = standardizeAddress(display_line_1)

      const [canceledDate, possessionDate, utilitiesActivationDate, closingDate] = [
        property.canceled_at,
        property.possession_at,
        property.utilities_activation_date,
        property.closing_date
      ].map((date) => {
        return UPUP.date(date ?? '', { leaveNull: true })
      })

      const value = {
        display_line_1,
        llc_id,
        status,
        canceledDate,
        possessionDate,
        utilitiesActivationDate,
        closingDate
      }

      output[correctedAddress] = value
    } catch (err) {
      console.error("oops, didn't work for", property)
    }
  })

  return output
}

export function configureLlcData(llc_data: any) {
  return llc_data.reduce((idToName: any, { id, name, abbreviation }: any) => {
    idToName[id] = abbreviation ?? name
    return idToName
  }, {} as { [llc_id: string]: string })
}

export function configureLlcToFunds(funds_data: any, funds_to_llc_data: any) {
  const fundIdToName = funds_data.reduce((idToName: any, { id, name, abbreviation }: any) => {
    idToName[id] = abbreviation ?? name
    return idToName
  }, {} as { [fund_id: string]: string })

  return funds_to_llc_data.reduce((mapping: any, { fund_id, llc_id }: any) => {
    mapping[llc_id] = fundIdToName[fund_id]
    return mapping
  }, {})
}
