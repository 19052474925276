import React, { useState } from 'react'
import { Box, Spinner, Text, Link, IconButton } from '@chakra-ui/react'
import { ArrowUpTrayIcon, TrashIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { v4 as uuidv4 } from 'uuid'

import { UploadContainer } from 'components/Toolkit'

const DocumentUploadContainer = ({
  uploadPrefix,
  onUpload,
  onClear
}: {
  uploadPrefix: string
  onUpload: (data: {
    isLoading: boolean
    downloadUrl: string
    file: File
    error?: string
    uploadPath: string
  }) => void
  onClear: () => void
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const [uploadState, setUploadState] = useState<any>({})
  const [uploadId, setUploadId] = useState(uuidv4())

  const uploadPath = `/${uploadPrefix}/${uploadId}`

  const onDropzoneChange = async (data: any) => {
    if (data.isLoading) {
      setIsUploading(true)
    }
    if (data.downloadUrl) {
      onUpload(data)
      setIsUploading(false)
    }
    setUploadState(data)
  }

  if (uploadState.downloadUrl) {
    return (
      <Box
        rounded='md'
        borderWidth='1px'
        borderColor='gray.300'
        p='3'
        justifyContent='center'
        display='flex'
        flexDir='column'
        alignItems='center'
        backgroundColor={'gray.100'}
      >
        <div className='flex flex-row gap-1'>
          <Text fontWeight={'semibold'}>Upload Success</Text>
          <CheckCircleIcon className='h-6 w-6 text-green-600' />
        </div>
        <div className='flex flex-row items-center justify-center gap-2'>
          <Link color={'blue.500'} href={uploadState.downloadUrl} target='_blank' rel='noreferrer'>
            {' '}
            {uploadState?.file?.name}
          </Link>
          <IconButton
            size='sm'
            variant='ghost'
            aria-label='Remove file'
            icon={<TrashIcon className='h-4 w-4' />}
            onClick={() => {
              setUploadState({})
              setUploadId(uuidv4())
              onClear()
            }}
          />
        </div>
      </Box>
    )
  }

  return (
    <UploadContainer uploadPath={uploadPath} onChange={onDropzoneChange as any}>
      <Box
        rounded='md'
        borderWidth='1px'
        borderColor='gray.300'
        p='3'
        justifyContent='center'
        display='flex'
        flexDir='column'
        alignItems='center'
        backgroundColor={'gray.100'}
      >
        {isUploading && (
          <>
            <Spinner />
            <Text>Uploading {uploadState?.file?.name}...</Text>
          </>
        )}
        {!isUploading && (
          <>
            <ArrowUpTrayIcon className='m-2 h-6 w-6' />
            <div>Drag and drop, or choose a file.</div>
          </>
        )}
      </Box>
    </UploadContainer>
  )
}

export default DocumentUploadContainer
