import React from 'react'
import { MinusCircleIcon } from '@heroicons/react/24/outline'

import { RenewalOfferItem } from 'lib/renewals'
import { formatMoney } from 'lib/numbers'
import { HStack, Box, Text, NumberInput, NumberInputField, IconButton } from '@chakra-ui/react'

interface RenewalOfferRowProps {
  renewalOffer: RenewalOfferItem
  handlePriceChangeForTerm: (term: number, price: number) => void
  removeRenewalOfferForTerm: (term: number) => void
}

const RenewalOfferRow: React.FC<React.PropsWithChildren<RenewalOfferRowProps>> = ({
  renewalOffer,
  handlePriceChangeForTerm,
  removeRenewalOfferForTerm
}) => {
  const { term, price, isMonthToMonth } = renewalOffer

  return (
    <HStack>
      <Box width='40%'>
        <Text fontWeight='bold'>{isMonthToMonth ? 'Month to Month' : `${term} months`} </Text>
      </Box>
      <Box width='10%' />
      <Box width='50%'>
        <NumberInput
          value={price}
          format={(value: string | number) => formatMoney(Number(value), 0, '$')}
          onChange={(_valueAsString: string, valueAsNumber: number) =>
            handlePriceChangeForTerm(term, valueAsNumber)
          }
          min={0}
          max={10000}
        >
          <NumberInputField />
        </NumberInput>
      </Box>
      <IconButton
        variant='ghost'
        icon={<MinusCircleIcon className='h-6 w-6' />}
        aria-label='Remove Renewal Offer'
        colorScheme='red'
        onClick={() => removeRenewalOfferForTerm(term)}
      />
    </HStack>
  )
}

export default RenewalOfferRow
