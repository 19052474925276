import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, TextArea } from 'components/Toolkit'
import { Note_Priorities_Enum, useUpupInsertNoteMutation } from 'graphql/generated'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'

export default function LeadGroupReminders({
  disabled,
  messageTemplate,
  onSend,
  rentalApplication
}: {
  disabled?: boolean
  messageTemplate: string
  onSend: (message: string) => void
  rentalApplication: { mls_listing: { display_line_1: string }; id: string }
}) {
  const [message, setMessage] = useState('')
  const {
    mls_listing: { display_line_1: displayLine1 },
    id: rentalApplicationId
  } = rentalApplication

  const [, createNote] = useUpupInsertNoteMutation()
  const adminId = useSelector<StoreState, string>((store) => store.admin.id)

  useEffect(() => {
    setMessage(
      `I'm reaching out regarding your application for ${displayLine1}. ${messageTemplate}`
    )
  }, [displayLine1, messageTemplate])

  const canSend = message && !disabled

  const sendReminder = async () => {
    if (!canSend) {
      return
    }

    await createNote({
      note: `Reminder sent: \n\n"""\n${message}\n"""`,
      resource_id: rentalApplicationId,
      resource_type: 'rental_applications',
      created_by_admin_id: adminId,
      category: 'Reminder',
      priority: Note_Priorities_Enum.Default
    })

    onSend(message)
  }

  return (
    <div style={{ marginTop: '2rem' }}>
      <TextArea
        label='Hi {FIRST_NAME},'
        hasError={false}
        hasLabel={false}
        onChange={setMessage}
        value={message}
        disabled={disabled}
        rows={10}
        noMargin
      />
      <Button
        onClick={sendReminder}
        isSecondary={false}
        style={{ width: '100%', marginTop: '2rem' }}
        disabled={disabled || !canSend}
      >
        Send reminder
      </Button>
    </div>
  )
}

LeadGroupReminders.propTypes = {
  disabled: PropTypes.bool,
  messageTemplate: PropTypes.string,
  onSend: PropTypes.func.isRequired,
  rentalApplication: PropTypes.object.isRequired
}
