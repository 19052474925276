"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VENDOR_SCOPE_LINE_ITEM_STATUSES = exports.SCOPE_LINE_ITEM_STATUSES = exports.CONSTRUCTION_SCOPE_STATUSES = void 0;
exports.CONSTRUCTION_SCOPE_STATUSES = {
    PENDING_SUBMISSION: 'pending_submission',
    SUBMITTED: 'submitted',
    CANCELED: 'canceled'
};
exports.SCOPE_LINE_ITEM_STATUSES = {
    PENDING_ASSIGNMENT: 'pending_assignment',
    ASSIGNED: 'assigned',
    IN_PROGRESS: 'in_progress',
    COMPLETE: 'complete',
    CANCELED: 'canceled'
};
exports.VENDOR_SCOPE_LINE_ITEM_STATUSES = {
    ASSIGNED: 'assigned',
    ACCEPTED: 'accepted',
    IN_PROGRESS: 'in_progress',
    COMPLETE: 'complete',
    CANCELED: 'canceled'
};
