import React, { useEffect, useState, Suspense } from 'react'
import { get, startCase } from 'lodash'
import PropTypes from 'prop-types'
import { useHistory, useParams, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Button, Heading, Divider, Stack, Center } from '@chakra-ui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {
  documents,
  agreements,
  rentalApplications,
  agreementSigners,
  capabilities
} from '@homevest/utils'

import axios from 'lib/axios'
import { hasCapability } from 'lib/admin-perms'
import hellosign from 'lib/hellosign'
import { sortLeadGroupUsers } from 'lib/lead-groups'
import UserDocuments from 'components/Documents/user-documents'
import UserInfo from './UserInfo'
import UserBackgroundCheckInfo from './user-background-check-info'
import UserIncome from 'components/RentalApplications/UserIncome'
import UserIdentityVerificationInfo from 'components/RentalApplications/UserIdentityVerificationInfo'
import ApplicationDetails from './ApplicationDetails'
import UnderwritingDetails from 'components/RentalApplications/UnderwritingDetails'
import Review from './Review'
import Sign from './sign'
import Lease from './lease'
import RequiredLeaseDetails from 'components/RentalApplications/RequiredLeaseDetails'
import AddDocumentModal from 'components/Documents/add-document-modal'
import CancelationModal from './cancelation-modal'
import ReminderModal from './ReminderModal'
import HoldButton from './HoldButton'
import { ErrorText, ContentContainer } from 'components/Toolkit'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import Notes from 'components/Notes'
import { REQUEST_POLICY } from 'constants/urql'
import { useUpUpMlsListingByIdQuery, UpUpMlsListingByIdQuery } from 'graphql/generated'
import { SnoozeDetails } from 'components/RentalApplications/SnoozeApplication/SnoozeDetails'
import { EscalateApplicationDetails } from 'components/RentalApplications/EscalateApplication/EscalateApplicationDetails'
import { AssignApplicationDetails } from 'components/RentalApplications/AssignApplication/AssignApplicationDetails'
import { CreditCardPaymentDetails } from 'components/RentalApplications/CreditCardPaymentDetails'
import LoadingSpinner from 'components/RentalApplications/LoadingSpinner'
import { StoreState } from 'store'
import { RentalApplication, AgreementSigner, CrmField, User } from 'types/RentalApplication'

const { AGREEMENT_SIGNER, APPLICATION_REVIEWER, ENGINEER } = capabilities.CAPABILITY_TYPES

const { AGREEMENT_STATUSES } = agreements
const { DOCUMENT_STATUSES, DOCUMENT_TYPES } = documents
const { RENTAL_APPLICATION_STATUSES } = rentalApplications
const { ROLES, AGREEMENT_SIGNER_STATUSES } = agreementSigners

const PENDING_OR_VALID_DOCUMENT = [DOCUMENT_STATUSES.VALID, DOCUMENT_STATUSES.PENDING_REVIEW]

const DOC_TYPES_REQUIRING_APPROVAL = [
  DOCUMENT_TYPES.IDENTITY_DOCUMENT,
  DOCUMENT_TYPES.BANK_STATEMENT,
  DOCUMENT_TYPES.OFFER_LETTER,
  DOCUMENT_TYPES.PAY_STUB,
  DOCUMENT_TYPES.TAX_RETURN,
  DOCUMENT_TYPES.SOCIAL_SECURITY_AWARD_LETTER,
  DOCUMENT_TYPES.ARTICLES_OF_ORGANIZATION,
  DOCUMENT_TYPES.SUPPLEMENTAL_DOCUMENT,
  DOCUMENT_TYPES.OTHER_FINANCIAL_DOCUMENT
]

const BACKGROUND_CHECK_DOC_TYPES = [
  DOCUMENT_TYPES.CREDIT_REPORT,
  DOCUMENT_TYPES.EVICTION_REPORT,
  DOCUMENT_TYPES.CRIMINAL_REPORT
]

const CRM_APPLICATION_FIELDS = ['occupation', 'salary', 'landlord_reference']

async function updateField(userId: string, fieldId: string, value: any) {
  await axios.post(`/users/${userId}/fields/${fieldId}`, {
    value
  })
}

const ApplicationScreen = () => {
  const { applicationId } = useParams<{ applicationId: string }>()

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ApplicationView applicationId={applicationId} />
    </Suspense>
  )
}

const ApplicationView: React.FC<React.PropsWithChildren<{ applicationId: string }>> = ({
  applicationId
}) => {
  const admin = useSelector((state: StoreState) => state.admin)
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [errorText, setErrorText] = useState('')
  const [showCancelationModal, setShowCancelationModal] = useState(false)
  const [showReminderModal, setShowReminderModal] = useState(false)
  const [rentalApplication, setRentalApplication] = useState<RentalApplication | null>(null)
  const [firestoreUsers, setFirestoreUsers] = useState(null)
  const [crmUsers, setCrmUsers] = useState<any>(null)
  const [addDocumentUserId, setAddDocumentUserId] = useState<string | null>(null)
  const [crmFields, setCrmFields] = useState<CrmField[]>([])
  const [isSaving, setIsSaving] = useState(false)
  const [companySigned, setCompanySigned] = useState(false)
  const [landlordSigned, setLandlordSigned] = useState(false)
  const [mlsListing, setMlsListing] = useState<UpUpMlsListingByIdQuery['mls_listing'] | null>(null)

  const setApplicationData = async () => {
    setIsLoading(true)
    try {
      await getApplicationData()
    } catch (err: any) {
      setErrorText(err.message)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setApplicationData()
  }, [])

  const mlsListingId = get(rentalApplication, 'opportunity.id')

  const [{ data, fetching, error }] = useUpUpMlsListingByIdQuery({
    variables: { id: mlsListingId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    pause: !mlsListingId
  })

  useEffect(() => {
    if (!data || !data.mls_listing) {
      return
    }
    setMlsListing(data.mls_listing)
  }, [data])

  if (fetching || isLoading) {
    return <LoadingSpinner />
  }

  if (!rentalApplication) {
    return (
      <Stack marginTop={400}>
        <Center>
          <ExclamationTriangleIcon className='h-16 w-16 text-yellow-500' />
        </Center>
        <Center>
          <Heading as='h3' size='lg'>
            No Application Found!
          </Heading>
        </Center>
      </Stack>
    )
  }

  const pendingAgreement =
    rentalApplication &&
    rentalApplication.agreements.find(
      (agreement) => agreement.status === AGREEMENT_STATUSES.PENDING
    )
  const completedAgreement =
    rentalApplication &&
    rentalApplication.agreements.find(
      (agreement) => agreement.status === AGREEMENT_STATUSES.COMPLETE
    )
  const completedOrPendingAgreement =
    rentalApplication &&
    rentalApplication.agreements.find((agreement) =>
      ([AGREEMENT_STATUSES.COMPLETE, AGREEMENT_STATUSES.PENDING] as string[]).includes(
        agreement.status
      )
    )
  const landlordSigner =
    pendingAgreement &&
    pendingAgreement.agreement_signers.find(
      (signer) =>
        signer.role === ROLES.LANDLORD &&
        signer.status === AGREEMENT_SIGNER_STATUSES.PENDING_SIGNATURE
    )
  const companySigner =
    pendingAgreement &&
    pendingAgreement.agreement_signers.find(
      (signer) =>
        (signer.role === ROLES.COMPANY || signer.role === ROLES.AGENT) &&
        signer.status === AGREEMENT_SIGNER_STATUSES.PENDING_SIGNATURE
    )
  const holdable =
    rentalApplication &&
    (rentalApplication.status === RENTAL_APPLICATION_STATUSES.HOLD_FOR_NEW_LEASE ||
      rentalApplication.status === RENTAL_APPLICATION_STATUSES.PENDING_CUSTOMER_PAYMENT ||
      rentalApplication.status ===
        RENTAL_APPLICATION_STATUSES.PENDING_CUSTOMER_RENTAL_AGREEMENT_COMPLETION)

  const isEngineer = hasCapability(admin, ENGINEER)

  async function cancelApplication(cancelReason: string) {
    try {
      setIsSaving(true)
      await axios.post(`/admin/rental_applications/${rentalApplication!.id}/cancel`, {
        cancelation_reason: cancelReason
      })

      history.push('/applications')
    } catch (err: any) {
      window.alert(err.message)
    } finally {
      setIsSaving(false)
    }
  }

  async function cancelAgreement() {
    setIsSaving(true)

    await axios.post(
      `/admin/agreements/${completedOrPendingAgreement!.id}/cancel_rental_application`
    )

    await getApplicationData()

    setIsSaving(false)
  }

  async function review(reviewPayload: any) {
    setIsSaving(true)
    try {
      await axios.post(`/admin/rental_applications/${rentalApplication!.id}/review`, reviewPayload)
    } catch (err: any) {
      window.alert(err.message)
    }
    await getApplicationData()
    setIsSaving(false)
  }

  async function setDocumentReviewStatus(docId: string, status: string, notes: string) {
    setIsSaving(true)
    await axios.post(`/admin/documents/${docId}/review`, {
      status,
      review_notes: notes
    })
    await getApplicationData()
    setIsSaving(false)
  }

  async function updateDocumentReviewStatus(docId: string, notes: string) {
    setIsSaving(true)
    await axios.put(`/admin/documents/${docId}`, {
      review_notes: notes
    })
    await getApplicationData()
    setIsSaving(false)
  }

  async function getApplicationData() {
    const { data: application } = await axios.get<RentalApplication>(
      `/admin/rental_applications/${applicationId}`
    )

    // Get the user ids from the lead group since we now have "occupants" that
    // won't be agreement signers but do need to show up on te screen
    // TODO 2022-03-09 replace with rental application users once that is done
    const userIds = application.lead_group.active_lead_group_users.map((lgu) => lgu.user.id)

    const firestoreUserData = await Promise.all(
      userIds.map(async (id) => {
        const response = await axios.get(`/admin/users/${id}/firestore_data`)
        return response.data
      })
    )
    const userData = await Promise.all(
      userIds.map((id) =>
        axios.get<User>(`/admin/users/${id}`).then((resp) => {
          const userData = resp.data

          return {
            ...userData,
            email: userData.email2,
            phone: userData.phone2
          }
        })
      )
    )
    const { data: fields } = await axios.get<CrmField[]>('/fields')

    setCrmFields(fields.filter((field) => CRM_APPLICATION_FIELDS.includes(field.label)))
    setFirestoreUsers(
      firestoreUserData.reduce((acc, item) => {
        acc[item?.id] = item
        return acc
      }, {})
    )
    setCrmUsers(
      userData.reduce((acc, item) => {
        acc[item.id] = item
        return acc
      }, {} as any)
    )
    setRentalApplication(application)
  }

  async function launchLease(signer: AgreementSigner, setFinished: (isFinished: boolean) => void) {
    const signingId = signer.external_id

    const {
      data: { sign_url: signedUrl }
    } = await axios.get(`/hellosign/sign_url/${signingId}`)

    hellosign.on('sign', async () => {
      setFinished(true)
      await getApplicationData()
    })

    hellosign.open(signedUrl)
  }

  if (errorText || error) {
    return (
      <ErrorText
        style={{
          display: 'flex',
          maxWidth: '50%',
          justifyContent: 'center',
          margin: '400px auto'
        }}
      >
        Please contact engineering, the following error occurred: {errorText || error!.message}
      </ErrorText>
    )
  }

  const resourceNoteWidgetOptions = []
  const displayLine1 = rentalApplication.opportunity.display_line_1
  const sortedLeadGroupUsers = sortLeadGroupUsers(
    rentalApplication.lead_group.active_lead_group_users
  )

  const raOption = {
    id: rentalApplication.id,
    type: 'rental_applications',
    displayName: 'Application for ' + displayLine1,
    writable: true
  }
  resourceNoteWidgetOptions.push(raOption)

  let lgOptionDisplayName = 'Lead group for '

  if (rentalApplication.lead_group.active_lead_group_users.length) {
    lgOptionDisplayName += rentalApplication.lead_group.active_lead_group_users
      .map((lgu) => lgu.user.first_name)
      .join(', ')
  } else {
    lgOptionDisplayName += displayLine1
  }

  const lgOption = {
    id: rentalApplication.lead_group.id,
    type: 'lead_groups',
    displayName: lgOptionDisplayName,
    writable: true
  }
  resourceNoteWidgetOptions.push(lgOption)

  const completeAdditionalInformation = async () => {
    setIsSaving(true)

    try {
      await axios.put(
        `/admin/rental_applications/${applicationId}/additional_lease_information_completed_at`
      )
    } catch (err) {
      throw err
    }

    await getApplicationData()

    setIsSaving(false)
  }

  const updateRasmButton = (
    <Button
      size='md'
      disabled={isSaving}
      colorScheme='gray'
      onClick={async (_e: any) => {
        if (isSaving) {
          return
        }
        setIsSaving(true)
        try {
          await axios.post(`/admin/rental_applications/${rentalApplication.id}/update_rasm`)
          const { data: application } = await axios.get(
            `/admin/rental_applications/${applicationId}`
          )
          setRentalApplication(application)
        } catch (err: any) {
          window.alert(err.message)
        }
        setIsSaving(false)
      }}
    >
      Update Status
    </Button>
  )

  return (
    <>
      <Helmet>
        <title>App | {rentalApplication.opportunity.display_line_1}</title>
      </Helmet>
      <ContentContainer type='intro'>
        <div className='flex flex-wrap items-center justify-between'>
          <Heading mb={3} size='xl' as='h1' marginRight={5} flexWrap='nowrap'>
            <Link
              to={`/home/${rentalApplication.opportunity.home_id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {rentalApplication.opportunity.display_line_1}
            </Link>
          </Heading>
          {rentalApplication.status !== RENTAL_APPLICATION_STATUSES.COMPLETE && (
            <div className='flex flex-wrap gap-2'>
              {holdable && (
                <HoldButton
                  isOnHold={Boolean(rentalApplication.hold_application_at)}
                  rentalApplication={rentalApplication}
                  setRentalApplication={setRentalApplication}
                />
              )}
              {rentalApplication.status ===
                RENTAL_APPLICATION_STATUSES.PENDING_RENTAL_AGREEMENT_CREATION &&
                hasCapability(admin, APPLICATION_REVIEWER) && (
                  <a
                    href='https://upandup.tryretool.com/apps/edc7a8e4-dafd-11ec-ac4a-97455f0078b3/Leasing/Create%20Lease%20%2B%20Stepup%20(01-30-2023)'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <Button colorScheme={'teal'} size='md'>
                      Create Lease in Retool
                    </Button>
                  </a>
                )}
              {rentalApplication.status ===
                RENTAL_APPLICATION_STATUSES.PENDING_ADDITIONAL_LEASE_INFORMATION && (
                <Button
                  onClick={completeAdditionalInformation}
                  colorScheme='blue'
                  size='md'
                  isLoading={isSaving}
                  disabled={isSaving}
                >
                  Completed Additional Lease Info
                </Button>
              )}
              {rentalApplication.status !== RENTAL_APPLICATION_STATUSES.CANCELED && (
                <Button onClick={() => setShowCancelationModal(true)} size='md' colorScheme='red'>
                  Cancel Application
                </Button>
              )}
              {!!completedOrPendingAgreement && (
                <Button variant='outline' colorScheme='red' onClick={cancelAgreement} size='md'>
                  Cancel Agreement
                </Button>
              )}
              <Button colorScheme='teal' size='md' onClick={() => setShowReminderModal(true)}>
                Send Reminder
              </Button>
              {(process.env.NODE_ENV === 'development' || isEngineer) && updateRasmButton}
            </div>
          )}
        </div>
        <div style={{ marginTop: '20px' }}>
          <ApplicationDetails
            rentalApplication={{
              ...rentalApplication,
              listedRent: mlsListing?.opportunity?.current_rent[0]?.listed_rent
            }}
            setRentalApplication={setRentalApplication}
            admin={admin}
          />
        </div>
        <div style={{ backgroundColor: 'white', marginTop: '20px' }}>
          <UnderwritingDetails rentalApplication={rentalApplication} />
        </div>
        <div style={{ backgroundColor: 'white', marginTop: '20px' }}>
          <RequiredLeaseDetails
            rentalApplicationId={rentalApplication.id}
            rentalApplicationStatus={rentalApplication.status}
          />
        </div>
        <div style={{ backgroundColor: 'white', marginTop: '20px' }}>
          <EscalateApplicationDetails rentalApplicationId={rentalApplication.id} />
        </div>
        <div style={{ backgroundColor: 'white', marginTop: '20px' }}>
          <AssignApplicationDetails leadGroupId={rentalApplication.lead_group_id} />
        </div>
        <div style={{ backgroundColor: 'white', marginTop: '20px' }}>
          <SnoozeDetails rentalApplicationId={rentalApplication.id} />
        </div>
        <div style={{ backgroundColor: 'white', marginTop: '20px' }}>
          <CreditCardPaymentDetails rentalApplicationId={rentalApplication.id} />
        </div>

        <div style={{ marginTop: '20px' }}>
          {completedAgreement && <Lease completedAgreement={completedAgreement} />}
          {rentalApplication.status ===
            RENTAL_APPLICATION_STATUSES.PENDING_UP_AND_UP_RENTAL_AGREEMENT_COMPLETION &&
            ((companySigner && !companySigned) || (landlordSigner && !landlordSigned)) &&
            hasCapability(admin, AGREEMENT_SIGNER) && (
              <Sign
                waitingForCompany={companySigner && !companySigned}
                waitingForLandlord={landlordSigner && !landlordSigned}
                launchCompanyLease={() => {
                  launchLease(companySigner!, setCompanySigned)
                }}
                launchLandlordLease={() => {
                  launchLease(landlordSigner!, setLandlordSigned)
                }}
              />
            )}

          {rentalApplication.status === RENTAL_APPLICATION_STATUSES.PENDING_APPLICATION_REVIEW &&
            hasCapability(admin, APPLICATION_REVIEWER) && (
              <Review
                rentalAppId={rentalApplication.id}
                rentalStatus={rentalApplication.status}
                isSaving={isSaving}
                onNeedsCosigner={() => review({ required_cosigner_at: new Date() })}
                onNeedsCoapplicant={() => review({ required_coapplicant_at: new Date() })}
                onAccept={() =>
                  review({
                    is_review_approved: true,
                    required_cosigner_at: null,
                    required_coapplicant_at: null
                  })
                }
              />
            )}
        </div>

        {rentalApplication.lead_group.id && (
          <div style={{ marginTop: '20px' }}>
            <Notes resourceNoteWidgetOptions={resourceNoteWidgetOptions} />
          </div>
        )}

        <div>
          {sortedLeadGroupUsers.map((lgu) => {
            const userId = lgu.user.id
            const documentsPendingOrComplete = lgu.user.documents.filter(
              (doc) =>
                (
                  [...DOC_TYPES_REQUIRING_APPROVAL, ...BACKGROUND_CHECK_DOC_TYPES] as string[]
                ).includes(doc.type) && (PENDING_OR_VALID_DOCUMENT as string[]).includes(doc.status)
            )
            const agreementSigner = completedOrPendingAgreement
              ? completedOrPendingAgreement.agreement_signers.find(
                  (signer) =>
                    signer.resource_id === userId &&
                    signer.status === AGREEMENT_SIGNER_STATUSES.SIGNED
                ) || null
              : null

            return (
              <div key={userId} style={{ backgroundColor: 'white', marginTop: '20px' }}>
                <ContentSectionCard
                  collapsable={false}
                  padding={true}
                  title={startCase(lgu.type)}
                  key={userId}
                >
                  <UserInfo
                    hasSigned={Boolean(agreementSigner)}
                    leadGroupUser={lgu}
                    firestoreUser={firestoreUsers![userId]}
                    crmUser={crmUsers[userId]}
                    crmFields={crmFields}
                    updateField={updateField}
                  />
                  <Divider marginY='3' />
                  <UserBackgroundCheckInfo leadGroupUser={lgu} />
                  <Divider marginY='3' />
                  <UserIdentityVerificationInfo
                    userIdentityVerification={lgu.user.user_identity_verification}
                  />
                  <Divider marginY='3' />
                  <UserIncome userId={lgu.user.id} />
                  <Divider marginY='3' />
                  <UserDocuments
                    rentalAppId={rentalApplication.id}
                    userId={userId}
                    addDocument={() => setAddDocumentUserId(userId)}
                    isSaving={isSaving}
                    setDocumentReviewStatus={setDocumentReviewStatus}
                    updateDocumentReviewStatus={updateDocumentReviewStatus}
                    documents={documentsPendingOrComplete}
                  />
                </ContentSectionCard>
              </div>
            )
          })}
        </div>
        {addDocumentUserId && (
          <AddDocumentModal
            userId={addDocumentUserId}
            onFinish={getApplicationData}
            close={() => setAddDocumentUserId(null)}
          />
        )}
        {showCancelationModal && (
          <CancelationModal
            cancel={cancelApplication}
            close={() => setShowCancelationModal(false)}
          />
        )}
        {showReminderModal && (
          <ReminderModal
            close={() => setShowReminderModal(false)}
            rentalApplication={rentalApplication}
          />
        )}
      </ContentContainer>
    </>
  )
}

ApplicationView.propTypes = {
  applicationId: PropTypes.string.isRequired
}

export default ApplicationScreen
