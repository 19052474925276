import React, { FunctionComponent } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ChakraProvider } from '@chakra-ui/react'

import Routes from 'components/Routes'
import Navbar from 'components/Navbar'

import { theme } from 'lib/theme'
import store, { history } from './store'
import { AuthProvider, useAuthContext } from 'context/auth'
import { UrqlProvider, useUrqlContext } from 'context/urql'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Loading from 'ui/Loading'

store.dispatch({ type: 'BOOTSTRAPPED' })

const App: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { loading: authLoading } = useAuthContext()
  const { loading: urqlLoading } = useUrqlContext()

  if (authLoading || urqlLoading) {
    return <Loading />
  }

  return (
    <div>
      <Navbar />
      <React.Suspense fallback={<Loading />}>
        <Routes />
      </React.Suspense>
    </div>
  )
}

const queryClient = new QueryClient()

const WithProviders: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <AuthProvider>
    <UrqlProvider>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <ConnectedRouter history={history}>
            <ChakraProvider theme={theme}>
              <App />
            </ChakraProvider>
          </ConnectedRouter>
        </ReduxProvider>
      </QueryClientProvider>
    </UrqlProvider>
  </AuthProvider>
)

export default WithProviders
