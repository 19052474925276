import { Heading, Stack, Button, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { InfoTooltip } from 'ui/InfoTooltip'
import { Grid } from './Grid'

const tooltipText = (
  <Stack padding={2} gap={4}>
    <Text>This page allows you to manage and take action on deferred utility chargebacks. </Text>
    <Text>
      The idea is that all chargebacks are eventually either accepted or rejected, but if action on
      a chargeback needs to be pushed until later, it should be deferred and will show up here.
    </Text>
  </Stack>
)

const DeferredChargebacks = () => {
  const history = useHistory()

  return (
    <Stack padding={4}>
      <HStack justify='space-between'>
        <HStack>
          <Heading>Utilities - Deferred Chargebacks</Heading>
          <InfoTooltip label={tooltipText} />
        </HStack>
        <Button
          variant='outline'
          size='lg'
          onClick={() => history.push('/utilities/chargebacks-manager')}
        >
          Chargebacks
        </Button>
      </HStack>
      <Grid />
    </Stack>
  )
}

export default DeferredChargebacks
