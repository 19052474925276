import React from 'react'
import { Heading } from '@chakra-ui/react'
import { LATCHEL_STATUSES } from '@homevest/utils/latchel'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import TicketsGrid from 'components/Construction/Tickets/TicketsGrid'
import { TICKETS_GRID_COLUMN_IDS } from 'components/Construction/Tickets/configuration'

const TicketsCard: React.FC<{ constructionProjectId: string }> = ({ constructionProjectId }) => (
  <ContentSectionCard
    title={
      <Heading as='h4' size='md' my={1}>
        Related Tickets
      </Heading>
    }
  >
    <TicketsGrid
      gridHeight={60}
      getTicketsConfig={{
        params: {
          project_id: constructionProjectId,
          statuses: Object.values(LATCHEL_STATUSES)
        }
      }}
      orderedColumnIds={[
        TICKETS_GRID_COLUMN_IDS.TITLE,
        TICKETS_GRID_COLUMN_IDS.CATEGORY,
        TICKETS_GRID_COLUMN_IDS.STATUS,
        TICKETS_GRID_COLUMN_IDS.VENDOR,
        TICKETS_GRID_COLUMN_IDS.START_DATE,
        TICKETS_GRID_COLUMN_IDS.END_DATE,
        TICKETS_GRID_COLUMN_IDS.COST,
        TICKETS_GRID_COLUMN_IDS.ACTIONS
      ]}
    />
  </ContentSectionCard>
)

export default TicketsCard
