import {
  Rental_Applications_Bool_Exp,
  Rental_Applications_Order_By,
  Rental_Application_Manager_Escalation_Statuses_Enum,
  UpupRentalApplicationsQueryVariables
} from 'graphql/generated'
import { Comparators } from 'lib/hasura-comparators'
import { merge } from 'lodash'
import {
  ColumnKey,
  FilterState,
  SelectState,
  SortState,
  TableState
} from './applications-table-utils'

export const getTableQuery = ({
  sortState,
  filterState,
  selectState,
  pageState,
  tabState
}: TableState): UpupRentalApplicationsQueryVariables => ({
  limit: pageState.size,
  offset: pageState.index,
  order_by: getOrderByQueryPartial(sortState),
  where: merge(
    {},
    (tabState === 'escalated'
      ? {
          application_escalations: {
            escalation_status: {
              _eq: Rental_Application_Manager_Escalation_Statuses_Enum.Pending
            }
          }
        }
      : {}) as Rental_Applications_Bool_Exp,
    ...getWhereQueryPartialsForFilter(filterState),
    ...getWhereQueryPartialsForSelect(selectState)
  )
})

export const getOrderByQueryPartial = (
  sortState: SortState
): Rental_Applications_Order_By | undefined => {
  switch (sortState['column']) {
    case ColumnKey.address:
      return {
        mls_listing: {
          display_line_1: sortState.type
        }
      }
    case ColumnKey.market:
      return {
        mls_listing: {
          region: sortState.type
        }
      }
    case ColumnKey.status:
    case ColumnKey.projected_occupancy_date:
    case ColumnKey.created_at:
    case ColumnKey.priority_score:
      return { [sortState.column]: sortState.type }
    case ColumnKey.escalation_date:
      return {
        application_escalations_aggregate: {
          max: { created_at: sortState.type }
        }
      }
    default:
      return undefined
  }
}

export const getWhereQueryPartialsForFilter = (
  filterState: FilterState
): Rental_Applications_Bool_Exp[] => {
  const partials = []
  const matchAddress = filterState[ColumnKey.address]
  if ((matchAddress?.length ?? 0) > 1) {
    partials.push({
      mls_listing: {
        display_line_1: Comparators.isLike(`%${matchAddress}%`)
      }
    })
  }
  const matchName = filterState[ColumnKey.lead_group]
  if ((matchName?.length ?? 0) > 1) {
    partials.push({
      lead_group: {
        lead_group_users: {
          user_searchable: {
            name: Comparators.isLike(`%${matchName!.replace(/\s/g, '')}%`)
          }
        }
      }
    })
  }
  const matchEscalatedTo = filterState[ColumnKey.escalated_to]
  if ((matchEscalatedTo?.length ?? 0) > 1) {
    partials.push({
      application_escalations: {
        assigned_to_admin: {
          admin_searchable: {
            name: Comparators.isLike(`%${matchEscalatedTo!.replace(/\s/g, '')}%`)
          }
        }
      }
    })
  }
  const matchEscalatedBy = filterState[ColumnKey.escalated_by]
  if ((matchEscalatedBy?.length ?? 0) > 1) {
    partials.push({
      application_escalations: {
        assigned_by_admin: {
          admin_searchable: {
            name: Comparators.isLike(`%${matchEscalatedBy!.replace(/\s/g, '')}%`)
          }
        }
      }
    })
  }
  const matchAssignedTeam = filterState[ColumnKey.assigned_team]
  if ((matchAssignedTeam?.length ?? 0) > 1) {
    partials.push({
      admin_assignments: {
        admin_searchable: {
          name: Comparators.isLike(`%${matchAssignedTeam!.replace(/\s/g, '')}%`)
        }
      }
    })
  }
  const matchEscalationNote = filterState[ColumnKey.escalation_note]
  if ((matchEscalationNote?.length ?? 0) > 1) {
    partials.push({
      application_escalations: {
        reason: Comparators.isLike(`%${matchEscalationNote}%`)
      }
    })
  }
  return partials
}

export const getWhereQueryPartialsForSelect = (
  selectState: SelectState
): Rental_Applications_Bool_Exp[] => {
  const partials = []
  const matchStatus = selectState[ColumnKey.status]
  if ((matchStatus?.length ?? 0) > 0) {
    partials.push({ status: Comparators.isIn(matchStatus!) })
  }
  const matchMarket = selectState[ColumnKey.market]
  if ((matchMarket?.length ?? 0) > 0) {
    partials.push({
      mls_listing: {
        region: Comparators.isIn(matchMarket!)
      }
    })
  }

  const matchProgramType = selectState[ColumnKey.rental_program_type_applied_for]
  if ((matchProgramType?.length ?? 0) > 0) {
    partials.push({
      rental_program_type_applied_for: Comparators.isIn(matchProgramType!)
    })
  }

  const matchSnooze = selectState[ColumnKey.snooze]
  // If filtered to either snoozed or regular apps
  if ((matchSnooze?.length ?? 0) === 1) {
    partials.push({
      application_snoozes_aggregate: {
        count: {
          predicate: matchSnooze![0] === 'snoozed' ? { _gt: 0 } : { _eq: 0 },
          filter: {
            deleted_at: { _is_null: true },
            snoozed_until: { _gt: 'now()' }
          }
        }
      }
    })
  }
  return partials
}
