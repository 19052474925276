import React, { FunctionComponent } from 'react'

import { connect } from 'react-redux'
import { TypedDispatch, StoreState } from 'store'
import {
  setEditMode,
  updateUserName,
  updateUserContactDetails
} from 'state/personal-details/actions'
import { setReexecutePersonalDetails } from 'state/refresh-components/actions'

import {
  setErrorMessage,
  setErrorModalIsOpen,
  setSuccessMessage,
  setSuccessModalIsOpen
} from 'state/modals/actions'

import { Name, UserContactDetailsWithValidation } from '../types'

import { ActionButton, SC } from 'components/TailwindUIToolkit/'
import { getDisplayName } from 'lib/users'

type EditPanelComponentPropTypes = {
  inEditMode: boolean
  setEditMode: Function
  updateUserName: Function
  updateUserContactDetails: Function
  setReexecutePersonalDetails: Function
  setProfileName: Function
  phones: UserContactDetailsWithValidation[]
  emails: UserContactDetailsWithValidation[]
  userName: Name
  userId: string
  setErrorModalIsOpen: Function
  setErrorMessage: Function
  setSuccessModalIsOpen: Function
  setSuccessMessage: Function
}

const EditPanel: FunctionComponent<React.PropsWithChildren<EditPanelComponentPropTypes>> = ({
  inEditMode,
  setEditMode,
  setReexecutePersonalDetails,
  setProfileName,
  updateUserName,
  updateUserContactDetails,
  phones,
  emails,
  userName,
  userId,
  setErrorModalIsOpen,
  setErrorMessage,
  setSuccessModalIsOpen,
  setSuccessMessage
}) => {
  const handleEditClick = () => {
    setReexecutePersonalDetails(false)
    setEditMode(true)
  }

  const handleCancelClick = () => {
    setReexecutePersonalDetails(true)
    setEditMode(false)
  }

  const handleSaveClick = async () => {
    // don't allow save if any field invalid
    if (!emails.every((e) => e.isValid) || !phones.every((p) => p.isValid)) {
      return
    }

    // filtering blank entries is necessary because a user might make
    // a new email or phone but never focus in the new input field
    // pre-save focus happens on blur so would miss a never-edited field
    phones = phones.filter((number) => number.contact_info)
    emails = emails.filter((emailAddress) => emailAddress.contact_info)

    // If we have no primary contact detail of a given type, hard code that
    // to be the primary
    // This avoids phone2 errors due to a user forgetting to set the primary
    ;[phones, emails].forEach((contactType) => {
      const hasNoPrimaryContact = !contactType.find(
        (contactDetail: UserContactDetailsWithValidation) =>
          contactDetail.is_primary_for_contact_type
      )
      if (contactType.length && hasNoPrimaryContact) {
        contactType[0].is_primary_for_contact_type = true
      }
    })

    try {
      await updateUserName(userId, userName)
      await updateUserContactDetails(userId, { phones, emails })
    } catch (err: any) {
      setErrorMessage(err.message)
      setErrorModalIsOpen(true)
      return
    }

    // update name in header
    setProfileName({
      firstName: userName.first_name,
      lastName: userName.last_name,
      displayName: getDisplayName({
        firstName: userName.first_name,
        lastName: userName.last_name,
        emails,
        phones
      })
    })

    setEditMode(false)
    setReexecutePersonalDetails(false)
    // open success modal
    setSuccessMessage('Personal details sucessfully saved.')
    setSuccessModalIsOpen(true)
  }

  return (
    <SC.ButtonContainer>
      <ActionButton
        disabled={inEditMode}
        show={!inEditMode}
        type='button'
        onClick={handleEditClick}
      >
        Edit
      </ActionButton>
      <ActionButton
        disabled={!inEditMode}
        show={inEditMode}
        type='button'
        onClick={handleCancelClick}
      >
        Cancel
      </ActionButton>
      <ActionButton
        colorScheme='secondary'
        disabled={!inEditMode}
        show={inEditMode}
        type='button'
        onClick={handleSaveClick}
      >
        Save
      </ActionButton>
    </SC.ButtonContainer>
  )
}

const mapStateToProps = (state: StoreState) => ({
  inEditMode: state.personalDetails.editMode,
  userName: {
    first_name: state.personalDetails.first_name,
    last_name: state.personalDetails.last_name
  },
  phones: state.personalDetails.phones,
  emails: state.personalDetails.emails
})

const mapDispatchToProps = (dispatch: TypedDispatch) => ({
  setEditMode: (mode: boolean) => dispatch(setEditMode(mode)),
  setReexecutePersonalDetails: (mode: boolean) => dispatch(setReexecutePersonalDetails(mode)),
  updateUserName: (id: string, userName: Name) => dispatch(updateUserName(id, userName)),
  updateUserContactDetails: (id: string, contactDetails: UserContactDetailsWithValidation[]) =>
    dispatch(updateUserContactDetails(id, contactDetails)),
  setErrorModalIsOpen: (mode: boolean) => dispatch(setErrorModalIsOpen(mode)),
  setErrorMessage: (message: string) => dispatch(setErrorMessage(message)),
  setSuccessModalIsOpen: (mode: boolean) => dispatch(setSuccessModalIsOpen(mode)),
  setSuccessMessage: (message: string) => dispatch(setSuccessMessage(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditPanel)
