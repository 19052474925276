import Big from 'big.js'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  Input
} from 'reactstrap'
import get from 'lodash/get'
import styled from 'styled-components'

import { formatMoney } from 'lib/numbers'
import {
  ModalBodyContainerStyled,
  InfoHeaderStyled,
  InfoPaddedContainerStyled,
  CurrencyStyled,
  PropertyTableStyled,
  FeeInputStyled
} from './styles'
import axios from 'lib/axios'

import { Button, ErrorText } from 'components/Toolkit'

const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #302654;
`

export default class ChargeModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onChargeSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  }

  state = {
    error: '',
    rent: '',
    investment: '',
    total: '',
    disabled: false
  }

  disabled = false

  calculateTotal = (rent, investment) => Number(Big(rent).plus(investment).toFixed(2))

  handleCharge = async () => {
    if (this.disabled || this.state.disabled) return

    const { user } = this.props
    const rent = Number(this.state.rent)
    const investment = Number(this.state.investment)

    this.setState({
      disabled: true
    })
    this.disabled = true

    try {
      await axios.post('/admin/payments', {
        rent,
        option_premium_purchase: investment,
        user_id: user.id
      })
      this.props.onChargeSuccess()
    } catch (err) {
      const detailMessage = get(err, 'response.data.message')

      // eslint-disable-next-line no-undef
      window.alert(detailMessage || err.message)
    }

    this.setState({
      disabled: false
    })
    this.disabled = false
  }

  async componentDidMount() {
    const { user } = this.props
    const res = await axios.get(`/users/${user.id}`)
    const rentals = res.data.rentals

    if (!rentals) {
      this.setState({
        error: 'This user has no rentals!'
      })
      return
    }

    const activeRental = rentals.find((rental) => rental.status === 'active')
    if (!activeRental) {
      this.setState({
        error: 'This user has no active rental!'
      })
      return
    }

    this.setState({
      activeRental,
      rent: Number(activeRental.total_monthly_rent_owed),
      investment: Number(activeRental.target_monthly_option_premium / 100)
    })
  }

  render() {
    const { activeRental, error, rent, investment, total, disabled } = this.state
    const { user, isOpen, onCancel } = this.props

    const totalCharge = this.calculateTotal(rent || 0, investment || 0)
    const isValidCharge = totalCharge === Number(total) && totalCharge > 0

    if (!activeRental) {
      return (
        <StyledLoading>
          <span>Loading...</span>
        </StyledLoading>
      )
    }

    const rentInDollars = activeRental.rent / 100
    const targetMonthlyOptionPremiumInDollars = activeRental.target_monthly_option_premium / 100

    let isInvestmentWarning = false
    const warningInvestment = Big(targetMonthlyOptionPremiumInDollars).plus(
      Big(targetMonthlyOptionPremiumInDollars).times(Big(0.5))
    )
    if (investment && Big(investment).gt(warningInvestment)) {
      isInvestmentWarning = true
    }

    return (
      <>
        <Modal size='lg' isOpen={isOpen} toggle={onCancel}>
          {error && <ErrorText>{error}</ErrorText>}
          <ModalHeader>Charge User</ModalHeader>
          <ModalBody>
            <ModalBodyContainerStyled>
              <div>
                <InfoHeaderStyled>Customer Information</InfoHeaderStyled>
                <PropertyTableStyled>
                  <tbody>
                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                      <td> {`${user.name}`}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Email</b>
                      </td>
                      <td> {user.email2}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Phone</b>
                      </td>
                      <td> {user.phone2}</td>
                    </tr>
                  </tbody>
                </PropertyTableStyled>
              </div>
              <div style={{ marginTop: '20px' }}>
                <InfoHeaderStyled>
                  Payment Details For{' '}
                  {activeRental.property.real_estate_acquisition?.mls_listing?.display_line_1 ||
                    'need to backfill'}
                </InfoHeaderStyled>
                <PropertyTableStyled>
                  <tbody>
                    <tr>
                      <td>
                        <b>Rent</b>
                      </td>
                      <td> ${formatMoney(rentInDollars)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Investment</b>
                      </td>
                      <td>${formatMoney(targetMonthlyOptionPremiumInDollars)}</td>
                    </tr>
                  </tbody>
                </PropertyTableStyled>
              </div>
              <InfoPaddedContainerStyled>
                <InfoHeaderStyled>Charge Information</InfoHeaderStyled>
                <FeeInputStyled>
                  <div>Rent</div>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>$</InputGroupAddon>
                    <Input
                      disabled={disabled}
                      placeholder='Rent'
                      min={0}
                      type='number'
                      step='1'
                      value={rent}
                      onChange={(event) => this.setState({ rent: event.target.value })}
                    />
                    <InputGroupAddon addonType='append'>.00</InputGroupAddon>
                  </InputGroup>
                </FeeInputStyled>
                <FeeInputStyled>
                  <div>Investment</div>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>$</InputGroupAddon>
                    <Input
                      disabled={disabled}
                      placeholder='Investment'
                      min={0}
                      type='number'
                      step='1'
                      value={investment}
                      onChange={(event) => this.setState({ investment: event.target.value })}
                    />
                    <InputGroupAddon addonType='append'>.00</InputGroupAddon>
                  </InputGroup>
                </FeeInputStyled>
              </InfoPaddedContainerStyled>
              <InfoPaddedContainerStyled>
                <InfoHeaderStyled>Charge Overview</InfoHeaderStyled>
                <PropertyTableStyled>
                  <tbody>
                    <tr>
                      <td>
                        <b>Rent</b>
                      </td>
                      <CurrencyStyled>${formatMoney(Number(rent))}</CurrencyStyled>
                    </tr>
                    <tr>
                      <td>
                        <b>Investment</b>
                      </td>
                      <CurrencyStyled>${formatMoney(Number(investment))}</CurrencyStyled>
                    </tr>
                    {isInvestmentWarning && (
                      <tr>
                        <span role='img' aria-label='warning'>
                          ⚠️
                        </span>
                        This investment is higher than normal. Please be sure that this is correct.
                      </tr>
                    )}
                    <tr>
                      <td>
                        <b>Total</b>
                      </td>
                      <CurrencyStyled>
                        ${formatMoney(Number(rent) + Number(investment))}
                      </CurrencyStyled>
                    </tr>
                  </tbody>
                </PropertyTableStyled>
              </InfoPaddedContainerStyled>
              <InfoPaddedContainerStyled>
                <InfoHeaderStyled>Confirm Charge</InfoHeaderStyled>
                <div>Re-enter total charge amount to continue</div>
                <div>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>$</InputGroupAddon>
                    <Input
                      disabled={disabled}
                      placeholder='Total'
                      min={0}
                      type='number'
                      step='1'
                      value={total}
                      onChange={(event) =>
                        this.setState({
                          total: event.target.value
                        })
                      }
                    />
                    <InputGroupAddon addonType='append'>.00</InputGroupAddon>
                  </InputGroup>
                </div>
              </InfoPaddedContainerStyled>
            </ModalBodyContainerStyled>
          </ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              disabled={!isValidCharge || disabled}
              onClick={this.handleCharge}
            >
              Recharge
            </Button>
            <Button color='secondary' onClick={onCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}
