import React, { FunctionComponent, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { singular } from 'pluralize'
import { StoreState } from 'store'
import { v4 as uuidv4 } from 'uuid'
import { Box, Textarea, Text, Stack, Input, Select } from '@chakra-ui/react'
import { startCase } from 'lodash'
import { documents } from '@homevest/utils'
import { STATUS_MAP } from 'lib/document-manager'
import { UploadContainer } from 'components/Toolkit'
import { useUpupInsertDocumentMutation } from 'graphql/generated'

type OnUploadVariables = {
  resourceId: string
  resourceType: string
  docPath: string
  docName: string
  docStatus: string
  docType: string
  docNote?: string
}

type UploadDocumentPanelProps = {
  resourceId: string
  resourceType: string
  typeMap: { [key: string]: string }
  onUpload?: () => Promise<void>
  setClosed?: () => void
  generateName?: (type: string) => string
  fixedStatus?: keyof typeof STATUS_MAP
}

export const UploadDocumentPanel: FunctionComponent<
  React.PropsWithChildren<UploadDocumentPanelProps>
> = ({ resourceId, resourceType, typeMap, onUpload, setClosed, fixedStatus, generateName }) => {
  const [uploadId, setUploadId] = useState<string>(uuidv4())
  const [uploadState, setUploadState] = useState<any>()
  const [docName, setDocName] = useState<string>()
  const [docType, setDocType] = useState<string>()
  const [docStatus, setDocStatus] = useState<string | undefined>(fixedStatus)
  const [docNote, setDocNote] = useState<string>()
  const [processing, setProcessing] = useState(false)
  const admin = useSelector((state: StoreState) => state.admin)

  const [, createDocument] = useUpupInsertDocumentMutation()

  const docPath = useMemo(
    () => `${resourceType}/${resourceId}/documents/${docType}/${uploadId}`,
    [docType, uploadId, resourceId, resourceType]
  )

  const handleClose = () => {
    setUploadId(uuidv4())
    setUploadState(undefined)
    setDocName(undefined)
    setDocType(undefined)
    setDocStatus(undefined)
    setDocNote(undefined)
    setClosed?.()
  }

  const handleUpload = async (data: OnUploadVariables) => {
    await createDocument({
      external_id: data.docPath,
      external_source: documents.DOCUMENT_EXTERNAL_SOURCES.GOOGLE_CLOUD,
      friendly_name: data.docName,
      resource_id: data.resourceId,
      resource_type: data.resourceType,
      status: data.docStatus,
      type: data.docType,
      uploaded_by_admin_id: admin.id,
      ...(data.docNote ? { resource_notes: data.docNote } : {})
    })

    await onUpload?.()
  }

  return (
    <Stack direction='column' borderWidth='1px' rounded='md' p='4' gap='2'>
      <Text>Attach Document to {singular(startCase(resourceType))}</Text>
      <Box>
        <Text color='gray.500'>Document type</Text>
        <Select
          onChange={(event) => {
            setDocType(event.target.value)
            if (generateName) {
              setDocName(generateName(typeMap[event.target.value]))
            }
          }}
          defaultValue=''
        >
          <option value='' disabled>
            Choose...
          </option>
          {Object.keys(typeMap).map((key) => (
            <option key={key} value={key}>
              {typeMap[key]}
            </option>
          ))}
        </Select>
      </Box>
      {generateName === undefined && (
        <Box>
          <Text color='gray.500'>Document name</Text>
          <Input type='text' onChange={(event) => setDocName(event.target.value)} />
        </Box>
      )}
      {fixedStatus === undefined && (
        <Box>
          <Text color='gray.500'>Document status</Text>
          <Select
            color='gray.900'
            onChange={(event) => setDocStatus(event.target.value)}
            defaultValue=''
          >
            <option value='' disabled>
              Choose...
            </option>
            {Object.keys(STATUS_MAP).map((key) => (
              <option key={key} value={key}>
                {STATUS_MAP[key as keyof typeof STATUS_MAP]}
              </option>
            ))}
          </Select>
        </Box>
      )}
      <Box>
        <Text color='gray.500'>Document note</Text>
        <Textarea color='gray.900' onChange={(event) => setDocNote(event.target.value)} />
      </Box>
      <Box>
        {resourceId && docType && docName && docStatus && (
          <UploadContainer
            onChange={
              (async (data: any) => {
                if (data.isLoading) {
                  setProcessing(true)
                }
                if (data.downloadUrl) {
                  await handleUpload({
                    resourceId: resourceId,
                    resourceType: resourceType,
                    docPath,
                    docName,
                    docStatus,
                    docType,
                    docNote
                  })
                  setProcessing(false)
                  handleClose()
                }
                setUploadState(data)
              }) as any
            }
            uploadPath={`/${docPath}`}
          >
            <Box
              rounded='md'
              borderWidth='1px'
              borderColor='gray.300'
              p='2'
              justifyContent='center'
              display='flex'
              flexDir='column'
              alignItems='center'
            >
              {processing && `Uploading... ${uploadState?.file?.name}`}
              {!processing && (
                <>
                  <div>Drag and drop, or choose a file.</div>
                  <div>Document will upload when file is selected.</div>
                </>
              )}
            </Box>
          </UploadContainer>
        )}
      </Box>
    </Stack>
  )
}
