import React from 'react'

import { Spinner, Text } from '@chakra-ui/react'

const SubmittingScreen = () => {
  return (
    <div className='mt-10 flex flex-col items-center justify-center gap-3'>
      <Spinner color={'teal'} size={'lg'} />
      <Text fontWeight={'bold'}>Submitting...</Text>
    </div>
  )
}

export default SubmittingScreen
