import * as Sentry from '@sentry/react'

import { fetchRentalsPendingPayment } from '../rentals/actions'

export const RECEIVED_ADMIN = 'RECEIVED_ADMIN'

export const receivedAdmin = (admin) => {
  return (dispatch) => {
    dispatch({
      type: RECEIVED_ADMIN,
      admin
    })

    if (admin.id) {
      Sentry.setUser({ id: admin.id, email: admin.email })
      dispatch(fetchRentalsPendingPayment())
    }
  }
}
