import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'
import {
  Heading,
  Flex,
  Spacer,
  Divider,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Kbd,
  Stack
} from '@chakra-ui/react'
import { Helmet } from 'react-helmet'

import countBy from 'lodash/countBy'

import { useRentRollQuery } from 'graphql/generated'
import { hasCapability } from 'lib/admin-perms'
import RentRollGrid from './Grid'
import StatusCountsDisplay from 'components/StatusCounts'
import { GridApi, GridReadyEvent } from 'ag-grid-community'
import { Home } from 'styled-icons/heroicons-solid'
import { RentRollStatus, getRentRollStatus } from 'lib/rent-roll/statuses'

const { LEDGER_VIEWER } = capabilities.CAPABILITY_TYPES

function Rentals() {
  const [{ data }] = useRentRollQuery()

  const [gridApi, setGridApi] = useState<GridApi>()
  const [searchQuery, setSearchQuery] = useState<string>()
  const searchRef = useRef<HTMLInputElement>(null)
  const [statusFilter, setStatusFilter] = useState<RentRollStatus | undefined>()

  useEffect(() => {
    function handleSearchHotkey(e: KeyboardEvent) {
      if ((e.ctrlKey && e.key === 'f') || (e.metaKey && e.key === 'f')) {
        if (searchRef.current !== document.activeElement) {
          e.preventDefault()
          searchRef.current?.focus()
        }
      }
    }
    window.addEventListener('keydown', handleSearchHotkey)

    return () => {
      window.removeEventListener('keydown', handleSearchHotkey)
    }
  }, [])

  const admin = useSelector<any>((state) => state.admin)
  const adminName = `${(admin as any)?.first_name} ${(admin as any)?.last_name}`
  const history = useHistory()

  const canViewPage = hasCapability(admin as any, LEDGER_VIEWER)

  const rentRollData = data?.rent_roll || []
  useEffect(() => {
    if (gridApi && searchQuery !== undefined) {
      gridApi.setQuickFilter(searchQuery)
      gridApi.onFilterChanged()
    }
  }, [searchQuery, gridApi])

  useEffect(() => {
    gridApi?.onFilterChanged()
    gridApi?.sizeColumnsToFit()
  }, [statusFilter, gridApi])

  const onGridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api)
  }

  if (!canViewPage) {
    history.push('/review')
    return null
  }

  return (
    <>
      <Helmet>
        <title>Rent Roll</title>
      </Helmet>
      <Flex direction={'column'} margin={'0 1rem'}>
        <Flex padding={5}>
          <Heading fontSize={'3xl'}>Rent Roll</Heading>
          <Spacer />
          <Menu>
            <MenuButton as={Button} colorScheme={'teal'} marginRight={3} size={'lg'}>
              Actions ↓
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  // TODO: add more columns for export
                  gridApi?.exportDataAsCsv({
                    allColumns: true
                  })
                }}
              >
                Download Balance Report
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <Box textAlign='center'>
          <StatusCountsDisplay<RentRollStatus>
            statusCounts={
              countBy(rentRollData, getRentRollStatus) as Record<RentRollStatus, number>
            }
            order={[
              'pending_move_in',
              'standard',
              'in_evictions',
              'on_notice',
              'ready_to_be_cashed_out',
              'completed'
            ]}
            onStatusClicked={(status) => {
              setStatusFilter(status)
            }}
            icon={Home}
          />
        </Box>
        <InputGroup size='lg' my={4} w='60%' mx='auto'>
          <Input
            ref={searchRef}
            type='text'
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
            name='search'
            id='search'
            placeholder={`10 Grand St..., ${adminName || 'Michael Wong'}...`}
            pr='6rem'
          />
          <InputRightElement pointerEvents='none' width='6rem'>
            <Stack direction='row' spacing={2}>
              <Kbd textColor='darkgray'>cmd + F</Kbd>
            </Stack>
          </InputRightElement>
        </InputGroup>
        <Divider />
        <RentRollGrid
          statusFilter={statusFilter}
          onGridReady={onGridReady}
          rowData={rentRollData}
        />
      </Flex>
    </>
  )
}

export default Rentals
