import React from 'react'
import { Heading } from '@chakra-ui/react'
import { documents, userIncomeSources } from '@homevest/utils'

import { useSourceDetails } from './context'
import { useSubmitIncomeSourceMutation } from './hooks/submit'
import { validateSelfEmployedIncomeSource } from 'lib/user-incomes/validate'
import { formatSelfEmployedUserIncomeSource } from 'lib/user-incomes/format'
import { SelfEmployedSourceErrors } from 'lib/user-incomes/types'
import { BasicFormField, DocumentFormField } from './common/form'
import DocumentUploadWithAmount from './common/utils/DocumentUploadWithAmount'
import SubmitButton from './common/SubmitButton'
import SubmittingScreen from './common/SubmittingScreen'

const { DOCUMENT_TYPES } = documents
const { USER_INCOME_SOURCE_TYPES: SOURCE_TYPES } = userIncomeSources

const SelfEmployedSourceDetails = ({
  userId,
  onSubmit
}: {
  userId: string
  onSubmit: () => void
}) => {
  const context = useSourceDetails()
  const { isSubmitting, handleSubmit } = useSubmitIncomeSourceMutation(
    userId,
    formatSelfEmployedUserIncomeSource,
    validateSelfEmployedIncomeSource,
    onSubmit
  )

  if (!context) {
    return null
  }

  if (isSubmitting) {
    return <SubmittingScreen />
  }

  const { data, updateField, errors } = context

  if (data.type !== SOURCE_TYPES.SELF_EMPLOYED) {
    return null
  }

  const typedErrors = errors as SelfEmployedSourceErrors

  return (
    <>
      <Heading as='h3' size='md' mb='3'>
        Self Employed Income Details
      </Heading>
      <BasicFormField label='Business Name' fieldName='business_name' />
      <DocumentFormField
        label='Articles of Organization'
        fieldName='articles_of_organization'
        uploadPrefix={DOCUMENT_TYPES.ARTICLES_OF_ORGANIZATION}
        documentType={DOCUMENT_TYPES.ARTICLES_OF_ORGANIZATION}
      />
      <DocumentUploadWithAmount
        title='Tax Return'
        instructions="Upload the applicant's most recent tax return and enter their gross annual income from the return."
        inputLabel='Gross Income'
        setAmount={(value) => updateField('tax_return', { ...data?.tax_return, amount: value })}
        amount={data?.tax_return?.amount || ''}
        amountError={typedErrors.tax_return?.amount}
        fileError={typedErrors.tax_return?.file}
        uploadPrefix={DOCUMENT_TYPES.TAX_RETURN}
        onUpload={({ uploadPath }) =>
          updateField('tax_return', {
            ...data?.tax_return,
            upload_path: uploadPath,
            type: DOCUMENT_TYPES.TAX_RETURN
          })
        }
        onClear={() => updateField('tax_return', {})}
      />
      <SubmitButton onSubmit={handleSubmit} />
    </>
  )
}

export default SelfEmployedSourceDetails
