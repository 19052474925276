import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { UncontrolledTooltip } from 'reactstrap'
import startCase from 'lodash/startCase'
import { useHistory, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { capabilities, payments as PAYMENTS } from '@homevest/utils'

import { Button, Text, TableContainer, Table, Thead, Tbody, Th, Tr, Td } from '@chakra-ui/react'
import { formatMoney } from 'lib/numbers'
import { getLedgerItemsWithBalance } from 'lib/ledger'
import {
  getAddressForRental,
  getRentalDetailDisplayData,
  getCSVExportForRentalLedger
} from 'lib/rentals'
import { hasCapability } from 'lib/admin-perms'
import CreateRentalLiabilityModal from './CreateRentalLiabilityModal'
import IssueCreditModal from './IssueCreditModal'
import IssuePaymentRefundModal from './IssuePaymentRefundModal'
import LedgerDetailModal from './LedgerDetailModal'
import { StoreState } from 'store'
import { RefundInfoType } from '../types'
import { DeleteButton } from './LedgerRow/DeleteButton'
import { EditButton } from './LedgerRow/EditButton'
import moment from 'moment'
import { RentRollRentalSubscription } from 'graphql/generated'
import axios from 'lib/axios'
import { dates } from '@homevest/utils'
import { CSVLink } from 'react-csv'
import CashoutValidAlert from './CashoutValidAlert'
// import { FetchLiabilityPaymentsQuery } from 'graphql/generated'
// import { groupBy, min } from 'lodash'

const {
  LEDGER_LIABILITY_CREATOR,
  LEDGER_RENT_CREDIT_ISSUER,
  LEDGER_VIEWER,
  PAYMENT_REFUNDER,
  LEDGER_SUPER_EDITOR
} = capabilities.CAPABILITY_TYPES

const { PAYMENT_LIFECYCLE, PAYMENT_STATUSES } = PAYMENTS

const FAILED_STATUSES = [`${PAYMENT_STATUSES.FAILED}`, `${PAYMENT_STATUSES.NSF_FAILED}`] // this is stupid, our readonly type dictionaries are miserable

// TODO: refactor to use liabilities_payments as the source of truth for the ledger
// interface RentRollLedgerEntry {
//   date: Date
//   label: string
//   amountOwed: number
//   amountRemaining?: number
//   payments: {
//     amountPaid: number
//     date: Date
//     stripeUrl: string
//     amountRemainingAfterPayment: number
//   }[]
// }

// interface RentRollBalance {
//   liability_type_id: string
//   label: string
//   amount: number
// }

// type RentRollLedger = {
//   ledgerEntries: RentRollLedgerEntry[]
//   outstandingBalances: RentRollBalance[]
// }

// const getStripeURLFromPayment = (
//   payment: FetchLiabilityPaymentsQuery['liabilities_payments'][number]['payment']
// ) => {
//   if (payment?.stripe_charge_id) {
//     return `https://dashboard.stripe.com/payments/${payment.stripe_charge_id}`
//   } else if (payment?.stripe_payment_intent_id) {
//     return `https://dashboard.stripe.com/payments/${payment.stripe_payment_intent_id}`
//   } else {
//     return 'https://dashboard.stripe.com/payments'
//   }
// }

// const getRentRollLedger = (
//   liaibilitiesPayments: FetchLiabilityPaymentsQuery['liabilities_payments'],
//   unallocatedPayments: FetchLiabilityPaymentsQuery['unallocated_payments'],
//   unallocatedCredits: FetchLiabilityPaymentsQuery['unallocated_credits'],
//   unpaidLiabilities: FetchLiabilityPaymentsQuery['unpaid_liabilities']
// ) => {
//   const ledger: RentRollLedger = {
//     ledgerEntries: [],
//     outstandingBalances: []
//   }

//   const lpsByLiability = groupBy(
//     liaibilitiesPayments,
//     (lp) => lp.rental_liability.id
//   )

//   // iterate over the liabilities and create a ledger entry for each
//   Object.entries(lpsByLiability).forEach(([liabilityId, liabilityPayments]) => {
//     const liability = liabilityPayments[0].rental_liability
//     const ledgerEntry: RentRollLedgerEntry = {
//       date: liability.date,
//       label: liability.liability_type.name,
//       amountOwed: liability.amount,
//       payments: liabilityPayments.map((lp) => {
//         return {
//           amountPaid: lp.amount,
//           date: lp.payment_date,
//           stripeUrl: getStripeURLFromPayment(lp.payment),
//           amountRemainingAfterPayment: lp.remaining_liability_amount
//         }
//       })
//     }
//     ledgerEntry.amountRemaining = min(
//       liabilityPayments.map((lp) => lp.remaining_liability_amount)
//     )
//     ledger.ledgerEntries.push(ledgerEntry)
//   })

//   return ledger
// }

// TODO: put this in a library so we don't have the code duplicated in services/api/lib/ledger/types.ts
export interface FinalStatement {
  sum_contributions?: string
  ending_balance?: string
  date?: string
}

export interface RentalLedger {
  ledgerDataWithBalances: LedgerItemWithBalance[]
  balance: number
  investmentToDate: number
  rentPaidToDate: number
  summaries: Record<string, LedgerSummary>
  finalStatement: FinalStatement
}

export interface LedgerSummary {
  liabilityTypeId: string
  liabilityTypeName: string
  balance: number
  paidToDate: number
}

export type LedgerItemWithBalance = GenericLedgerItem & {
  balance: number
}

export type GenericLedgerItem = LedgerPayment | LedgerCredit | LedgerLiability | LedgerAdjustment

export const CREDIT = 'Credit' as const
export const LIABILITY = 'Liability' as const
export const PAYMENT = 'Payment' as const
export const ADJUSTMENT = 'Adjustment' as const

export interface LedgerPayment extends LedgerItem {
  type: typeof PAYMENT
  availableOn: string | null
  status: string
  lifecycle: string
}

export interface LedgerLiability extends LedgerItem {
  type: typeof LIABILITY
}

export interface LedgerCredit extends LedgerItem {
  type: typeof CREDIT
}

export interface LedgerAdjustment extends LedgerItem {
  type: typeof ADJUSTMENT
}

interface LedgerItem {
  id: string
  userId: string
  liabilityTypeId: string
  liabilityTypeName: string
  date: string
  type: string
  amount: number
  category: string | null
  note?: string
}

const fetchLedger = async (rentalId: string) => {
  return (
    await axios.get<RentalLedger>(
      `/admin/rentals/${rentalId}/ledger?filter_date=${dates.formatISODate(new Date())}`
    )
  ).data
}

export default function Ledger({
  rentalId,
  ledgerData
}: {
  ledgerData: NonNullable<RentRollRentalSubscription['rentals_by_pk']>
  rentalId: string
}) {
  const [isCreateRentalLiabilityModalOpen, setIsCreateRentalLiabilityModalOpen] = useState(false)
  const [isLedgerDetailModalOpen, setIsLedgerDetailModalOpen] = useState(false)
  const [ledgerDetailModalData, setLedgerDetailModalData] = useState({})
  const [isIssueCreditModalOpen, setIsIssueCreditModalOpen] = useState(false)
  const admin = useSelector((state: StoreState) => state.admin)
  const [isRefundPaymentModalOpen, setIsRefundPaymentModalOpen] = useState(false)
  const [refundInfo, setRefundInfo] = useState<RefundInfoType>()
  const [ledger, setLedger] = useState<RentalLedger>()
  const [ledgerDetailModalHeader, setLedgerDetailModalHeader] = useState('')
  const history = useHistory()

  useEffect(() => {
    fetchLedger(rentalId).then((ledger) => {
      setLedger(ledger)
    })
  }, [rentalId])

  const canViewPage = hasCapability(admin, LEDGER_VIEWER)
  const canIssueRentCredits = hasCapability(admin, LEDGER_RENT_CREDIT_ISSUER)
  const canCreateLiabilities = hasCapability(admin, LEDGER_LIABILITY_CREATOR)
  const canRefund = hasCapability(admin, PAYMENT_REFUNDER)
  const canEditLedger = hasCapability(admin, LEDGER_SUPER_EDITOR)
  const canEditEntry = (date: string) => {
    const today = new Date()
    const dateMoment = moment(date)
    return (
      canEditLedger &&
      (dateMoment.isSameOrAfter(today, 'day') ||
        (today.getMonth() === dateMoment.month() && today.getFullYear() === dateMoment.year()))
    )
  }

  const isLedgerLocked = !!ledgerData?.cashout?.ledger_finalized_at
  const isTenantCashedOut = !!ledgerData?.cashout?.cashed_out_at

  const rentals_by_pk = ledgerData
  if (!canViewPage) {
    history.push('/review')
    return null
  }

  const rental = getRentalDetailDisplayData(rentals_by_pk)
  const { ledgerDataWithBalances } = getLedgerItemsWithBalance(
    rentals_by_pk.payments,
    rentals_by_pk.rental_liabilities,
    rentals_by_pk.rental_credits,
    rentals_by_pk.cashout?.cashout_adjustments
  )

  const address = getAddressForRental(rentals_by_pk)
  const finalStatementDate = ledger?.finalStatement?.date

  return (
    <>
      {rental.cashout && ledger && <CashoutValidAlert ledger={ledger} />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Text fontSize='md' fontWeight={600}>
          Amount owed: {formatMoney(ledger?.balance, 2, '$')}
        </Text>
        <Text fontSize='md' fontWeight={600}>
          Rent paid: {formatMoney(ledger?.rentPaidToDate, 2, '$')}
        </Text>
        <Text fontSize='md' fontWeight={600}>
          Wallet Contribution: {formatMoney(ledger?.investmentToDate, 2, '$')}
        </Text>
        {ledger?.finalStatement && (
          <Text fontSize='md' fontWeight={600}>
            Wallet Value On{' '}
            {finalStatementDate ? format(new Date(finalStatementDate), 'MMM yyyy') : ''}:{' '}
            {formatMoney(Number(ledger?.finalStatement?.ending_balance), 2, '$')}
          </Text>
        )}
        {isTenantCashedOut && (
          <Text fontSize='md' fontWeight={600}>
            Cashed out for: {formatMoney(rental.cashout?.paid_out_amount, 2, '$')}
          </Text>
        )}
        {rental.investmentTransfer && (
          <Text fontSize='md' fontWeight={600}>
            Investment Transfer: {formatMoney(rental.investmentTransfer, 2, '$')}
          </Text>
        )}
        {!isLedgerLocked && (
          <>
            {canIssueRentCredits && (
              <Button
                size='sm'
                colorScheme={'teal'}
                onClick={() => setIsIssueCreditModalOpen(true)}
              >
                + Issue Credit
              </Button>
            )}
            {canCreateLiabilities && (
              <Button
                size='sm'
                colorScheme={'teal'}
                onClick={() => setIsCreateRentalLiabilityModalOpen(true)}
              >
                + Create Liability
              </Button>
            )}
          </>
        )}
        {ledger && (
          <Button size='sm' colorScheme={'teal'}>
            <CSVLink
              style={{ color: 'white', textDecoration: 'none' }}
              data={getCSVExportForRentalLedger(rentals_by_pk, ledger)}
              filename={`${address}-ledger-${new Date().getTime()}.csv`}
            >
              Download Ledger
            </CSVLink>
          </Button>
        )}
      </div>
      <TableContainer mt={2}>
        <Table size={'sm'} variant={'striped'}>
          <Thead>
            <Tr>
              <Th>Charge Date</Th>
              <Th>Available on</Th>
              <Th>Amount</Th>
              {canRefund && <Th>Refund Payment</Th>}
              <Th>Type</Th>
              <Th>Category</Th>
              <Th>Paid by</Th>
              <Th>Status</Th>
              <Th>Payment Method</Th>
              <Th>Balance</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {ledgerDataWithBalances.map(
              ({
                id,
                user,
                amount,
                availableOn,
                balance,
                date,
                paymentMethod,
                liabilityLabel,
                type,
                status,
                lifecycle,
                initiatedAt,
                note,
                refundReason,
                liabilityTypeId,
                stripeId,
                creditType
              }) => (
                <Tr
                  key={id}
                  className={[
                    FAILED_STATUSES.includes(status) ? 'alert-danger' : '',
                    type === ADJUSTMENT ? 'alert-warning' : ''
                  ].join(' ')}
                  style={{
                    fontStyle: moment(date).isAfter(moment()) ? 'italic' : ''
                  }}
                >
                  <Td>{date}</Td>
                  <Td>{availableOn}</Td>
                  <Td>{formatMoney(amount, 2, '$')}</Td>
                  {canRefund && (
                    <Td>
                      {type === PAYMENT && status === 'succeeded' && (
                        <Button
                          size='sm'
                          colorScheme={'teal'}
                          onClick={() => {
                            setRefundInfo({
                              amount,
                              address,
                              date,
                              paymentId: id
                            })
                            setIsRefundPaymentModalOpen(true)
                          }}
                        >
                          Refund Payment
                        </Button>
                      )}
                    </Td>
                  )}
                  <Td>
                    <Link
                      to={{
                        pathname: `https://dashboard.stripe.com/payments/${stripeId}`
                      }}
                      target='_blank'
                    >
                      {startCase(type)}
                    </Link>
                  </Td>
                  <Td>{startCase(liabilityLabel)}</Td>
                  <Td>
                    {user && (
                      <Link to={{ pathname: `/users/${user?.id}` }} target='_blank'>
                        {user?.first_name} {user?.last_name}
                      </Link>
                    )}
                  </Td>
                  <Td>
                    <span
                      style={{
                        textDecoration: isInitiallyCleared(status, lifecycle) ? 'underline' : 'none'
                      }}
                      id={`status-ic-${id}`}
                    >
                      {startCase(isInitiallyCleared(status, lifecycle) ? lifecycle : status)}
                    </span>
                    {isInitiallyCleared(status, lifecycle) && (
                      <UncontrolledTooltip placement='top' target={`status-ic-${id}`}>
                        <div>We have a high degree of certainty that this payment succeeded.</div>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          Status:&nbsp;<b>{startCase(status)}</b>
                        </div>
                        {initiatedAt && (
                          <div>
                            Initiated:&nbsp;
                            <b>{new Date(initiatedAt).toString()}</b>
                          </div>
                        )}
                      </UncontrolledTooltip>
                    )}
                  </Td>
                  <Td>{paymentMethod?.toUpperCase() ?? '-'}</Td>
                  <Td>{formatMoney(balance, 2, '$')}</Td>
                  <Td>
                    {(note || refundReason) && (
                      <Button
                        size='xs'
                        colorScheme={'teal'}
                        onClick={() => {
                          setIsLedgerDetailModalOpen(true)
                          setLedgerDetailModalData({
                            note,
                            refundReason
                          })
                          setLedgerDetailModalHeader(startCase(type))
                        }}
                      >
                        See More
                      </Button>
                    )}
                  </Td>
                  <Td>
                    {canEditEntry(date) && (
                      <EditButton
                        rentalId={rentalId}
                        ledgerItem={{
                          id,
                          user,
                          amount,
                          availableOn,
                          date,
                          paymentMethod,
                          liabilityLabel,
                          type,
                          status,
                          lifecycle,
                          initiatedAt,
                          note,
                          refundReason,
                          liabilityTypeId,
                          creditType
                        }}
                      />
                    )}
                  </Td>
                  <Td>
                    {canEditLedger && (
                      <DeleteButton
                        rentalId={rentalId}
                        ledgerItem={{
                          id,
                          amount,
                          type,
                          liabilityLabel,
                          date
                        }}
                      />
                    )}
                  </Td>
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <>
        {canCreateLiabilities && (
          <CreateRentalLiabilityModal
            rentalId={rentalId}
            occupancyDate={rental.occupancy_date}
            finalLiabilityDate={rental.final_liability_date}
            close={() => setIsCreateRentalLiabilityModalOpen(false)}
            isOpen={isCreateRentalLiabilityModalOpen}
          />
        )}
        {canIssueRentCredits && (
          <IssueCreditModal
            rentalId={rentalId}
            close={() => setIsIssueCreditModalOpen(false)}
            isOpen={isIssueCreditModalOpen}
          />
        )}
        {canRefund && refundInfo && (
          <IssuePaymentRefundModal
            close={() => setIsRefundPaymentModalOpen(false)}
            isOpen={isRefundPaymentModalOpen}
            refundInfo={refundInfo}
          />
        )}
        <LedgerDetailModal
          close={() => setIsLedgerDetailModalOpen(false)}
          isOpen={isLedgerDetailModalOpen}
          data={ledgerDetailModalData}
          header={ledgerDetailModalHeader}
        />
      </>
    </>
  )
}

const isInitiallyCleared = (status: string, lifecycle: string | undefined) =>
  [`${PAYMENT_STATUSES.PENDING}`, `${PAYMENT_STATUSES.PROCESSING}`].includes(status) &&
  lifecycle === PAYMENT_LIFECYCLE.INITIALLY_CLEARED
