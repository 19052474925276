import React from 'react'
import { UserIncomeDetails } from '@homevest/types/user-incomes'
import { Heading, Stack, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import { formatMoney } from 'lib/numbers'
import { userIncomes } from '@homevest/utils'

const { INCOME_DISPARITY_THRESHOLD } = userIncomes

const IncomeDisplay = ({ userIncome }: { userIncome: UserIncomeDetails }) => {
  const calculatedIncome = userIncome.user_income_sources.reduce(
    (acc, source) => acc + source.source_annual_income,
    0
  )

  const incomeDisparity = Math.abs(userIncome.annual_income - calculatedIncome)
  const incomeDisparityExceedsThreshold = incomeDisparity > INCOME_DISPARITY_THRESHOLD

  const incomeValues = [
    ['reported', userIncome.annual_income],
    ['calculated', calculatedIncome],
    ['discrepancy', incomeDisparity]
  ] as const

  return (
    <Stack>
      <Heading size='sm' as='u' fontWeight='semibold'>
        Gross Annual Income
      </Heading>
      <Table w='fit-content' variant='unstyled' size='sm'>
        <Tbody>
          {incomeValues.map(([label, value]) => (
            <Tr
              key={label}
              bgColor={
                label === 'discrepancy'
                  ? incomeDisparityExceedsThreshold
                    ? 'red.100'
                    : 'green.100'
                  : undefined
              }
              color={
                label === 'discrepancy'
                  ? incomeDisparityExceedsThreshold
                    ? 'red.700'
                    : 'green.700'
                  : undefined
              }
              textDecoration={
                label === 'discrepancy' && incomeDisparityExceedsThreshold ? 'underline' : undefined
              }
            >
              <Td fontWeight='medium' textTransform='capitalize'>
                {label}:
              </Td>
              <Td>{formatMoney(value, 2, '$')}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack>
  )
}

export default IncomeDisplay
