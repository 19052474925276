import React from 'react'
import { Switch, FormControl, FormLabel } from '@chakra-ui/react'

const SkipValidationSwitch: React.FC<
  React.PropsWithChildren<{
    skipValidation: boolean
    setSkipValidation: (skipValidation: boolean) => void
  }>
> = ({ skipValidation, setSkipValidation }) => {
  return (
    <FormControl display='flex' alignItems='center' alignContent={'center'}>
      <FormLabel htmlFor='skip-validation' mb='0'>
        Skip Validation
      </FormLabel>
      <Switch
        id='skip-validation'
        isChecked={skipValidation}
        onChange={() => setSkipValidation(!skipValidation)}
        colorScheme='teal'
        mt={2}
      />
    </FormControl>
  )
}

export default SkipValidationSwitch
