import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Button, HeaderText } from 'components/Toolkit'
import { Box } from '@chakra-ui/react'

export default function LedgerDetailModal({
  close,
  isOpen,
  data,
  header
}: {
  close: () => void
  isOpen: boolean
  data: any
  header: string
}) {
  return (
    <>
      <Modal toggle={close} isOpen={isOpen}>
        <ModalHeader toggle={close}>More Information</ModalHeader>
        <ModalBody>
          <HeaderText size='h4'>{header}</HeaderText>
          {data.note && (
            <Box mt={'5'}>
              <HeaderText size='h5'>Note</HeaderText>
              <p>{data.note}</p>
            </Box>
          )}
          {data.refundReason && (
            <Box mt={'5'}>
              <HeaderText size='h5'>Refund Reason</HeaderText>
              <p>{data.refundReason}</p>
            </Box>
          )}
          <Button
            isSecondary={false}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={close}
          >
            Close
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}
