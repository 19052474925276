import React from 'react'
import { Center, Heading, Spinner, Stack } from '@chakra-ui/react'

const LoadingSpinner: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Stack marginTop={400}>
      <Center>
        <Spinner color='teal' size='xl' thickness='4px' />
      </Center>
      <Center>
        <Heading as='h3' size='lg'>
          Loading...
        </Heading>
      </Center>
    </Stack>
  )
}

export default LoadingSpinner
