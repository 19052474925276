import React, { FunctionComponent } from 'react'
import { ContentSectionCard, LinkButton } from 'components/TailwindUIToolkit'
import Grid from '../Grids/RentalHistoryGrid'
import { Spinner } from 'reactstrap'

type WorkOrderHistoryPropTypes = {
  portfolioHomeId: string
}

const RentRollButton = <LinkButton href='/rent-roll'>Rent Roll</LinkButton>

const RentalHistory: FunctionComponent<React.PropsWithChildren<WorkOrderHistoryPropTypes>> = ({
  portfolioHomeId
}) => (
  <ContentSectionCard title='Rental History' staticSize={true} size='small' action={RentRollButton}>
    <React.Suspense fallback={<Spinner />}>
      <Grid portfolioHomeId={portfolioHomeId} />
    </React.Suspense>
  </ContentSectionCard>
)

export default RentalHistory
