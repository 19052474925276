import React from 'react'
import { userIncomeSources } from '@homevest/utils'

import PayStubUploadSection from './PayStubUpload'
import { SelectFormField } from '../common/form'

const {
  USER_INCOME_SOURCE_PAY_FREQUENCIES: PAY_FREQUENCIES,
  USER_INCOME_SOURCE_WAGE_TYPES: WAGE_TYPES
} = userIncomeSources

const StandardEmployedPayDetails = () => {
  return (
    <div className='flex flex-col gap-5'>
      <SelectFormField
        label='Wage Type'
        fieldName='wage_type'
        optionValues={Object.values(WAGE_TYPES)}
      />
      <SelectFormField
        label='Pay Frequency'
        fieldName='pay_frequency'
        optionValues={Object.values(PAY_FREQUENCIES)}
      />
      <PayStubUploadSection />
    </div>
  )
}

export default StandardEmployedPayDetails
