import React, { useState } from 'react'
import { Spinner } from '@chakra-ui/react'
import { capabilities } from '@homevest/utils'
import { useSelector } from 'react-redux'

import { StoreState } from 'store'
import { hasCapability } from 'lib/admin-perms'
import { ActionButton, ContentSectionCard } from 'components/TailwindUIToolkit'
import UtilitiesCardInner from '../Components/Utilities/UtilitiesCardInner'

const { CAPABILITY_TYPES } = capabilities

type UtilityDetailsPropTypes = {
  portfolioHomeId: string
}

const UtilityDetails: React.FC<React.PropsWithChildren<UtilityDetailsPropTypes>> = ({
  portfolioHomeId
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const admin = useSelector((state: StoreState) => state.admin)
  const canEdit = hasCapability(admin, CAPABILITY_TYPES.UTILITIES_CONFIGURATION_EDITOR)

  return (
    <ContentSectionCard
      title='Utility Details'
      size='fit-content'
      action={
        canEdit ? (
          <ActionButton colorScheme='primary' onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Cancel' : 'Edit'}
          </ActionButton>
        ) : undefined
      }
    >
      <React.Suspense fallback={<Spinner margin={5} />}>
        <UtilitiesCardInner
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          canEdit={canEdit}
          portfolioHomeId={portfolioHomeId}
        />
      </React.Suspense>
    </ContentSectionCard>
  )
}

export default UtilityDetails
