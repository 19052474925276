import React from 'react'
import { format as formatDate } from 'date-fns'
import { Heading, Text, Link, Wrap, HStack, WrapItem, Box } from '@chakra-ui/react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { verifications } from '@homevest/utils'
import { UserIdentityVerification } from '@homevest/types/user-identity-verifications'

import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'

const { VERIFICATION_STATUSES } = verifications

const UserIdentityVerificationInfo: React.FC<
  React.PropsWithChildren<{
    userIdentityVerification?: UserIdentityVerification
  }>
> = ({ userIdentityVerification }) => {
  if (!userIdentityVerification) {
    return (
      <HStack spacing={5}>
        <Text>This user has not yet attempted to verify their identity!</Text>
      </HStack>
    )
  }

  const completedAt = userIdentityVerification.completed_at

  const isFailed = userIdentityVerification.status === VERIFICATION_STATUSES.FAILED
  const isInvalidated = !!userIdentityVerification.invalidated_at
  const invalidatedAt = userIdentityVerification.invalidated_at

  return (
    <>
      <Wrap spacing={10}>
        <WrapItem>
          <HStack>
            <Text as='b'>Status:</Text>
            {mapContentToBadge(userIdentityVerification.status, { strikethrough: isInvalidated })}
            {isInvalidated && (
              <Text as='i'>(Invalidated on {formatDate(new Date(invalidatedAt!), 'M/d/yy')})</Text>
            )}
          </HStack>
        </WrapItem>
        <WrapItem>
          <Text>
            <Text as='b'>Completed At: </Text>
            {completedAt ? formatDate(new Date(completedAt), 'M/d/yy h:mm a') : '-'}{' '}
          </Text>
        </WrapItem>
        <WrapItem>
          <Link
            href={`https://dashboard.stripe.com/identity/verification-sessions/${userIdentityVerification.stripe_session_id}`}
            isExternal
          >
            <HStack>
              <Text className='underline' color='teal'>
                View in Stripe
              </Text>
              <ArrowTopRightOnSquareIcon color='teal' className='h-4 w-4' />
            </HStack>
          </Link>
        </WrapItem>
      </Wrap>
      {isFailed && (
        <Box marginY={3}>
          <Text>
            <Text as='b'>Failure Message: </Text>
            {userIdentityVerification.failure_message ?? 'Unknown failure reason'}
          </Text>
        </Box>
      )}
    </>
  )
}

const UserIdentityVerificationInfoWrapper: React.FC<
  React.PropsWithChildren<{
    userIdentityVerification?: UserIdentityVerification
  }>
> = ({ userIdentityVerification }) => {
  return (
    <>
      <Heading size='md' as='h3' marginBottom={3}>
        Identity Verification
      </Heading>
      <UserIdentityVerificationInfo userIdentityVerification={userIdentityVerification} />
    </>
  )
}

export default UserIdentityVerificationInfoWrapper
