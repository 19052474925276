export const parsePhoneNumber = (value: string | undefined) => {
  if (!value) {
    return value
  }

  return '+1' + value.replace(/\D/g, '')
}

export const formatPhoneForDisplay = (value: string | null | undefined): string => {
  if (!value) {
    return ''
  }

  const input = value.replace(/\D/g, '')
  let formattedInput = ''

  // Split the input into parts and apply the formatting
  if (input.length > 0) {
    formattedInput = `(${input.substring(0, 3)}`
  }
  if (input.length >= 4) {
    formattedInput += `) ${input.substring(3, 6)}`
  }
  if (input.length >= 7) {
    formattedInput += `-${input.substring(6, 10)}`
  }

  return formattedInput
}

export const formatPhoneFromDatabase = (value: string | null | undefined) => {
  if (!value) {
    return ''
  }

  const input = value.startsWith('+1') ? value.substring(2) : value

  return formatPhoneForDisplay(input)
}
