import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import EditRentalAgreementHistoryForm from './Form'

export default function EditRentalAgreementHistoryModal({
  close,
  isOpen,
  rentalAgreementHistory
}: {
  close: () => void
  isOpen: boolean
  rentalAgreementHistory: any
}) {
  return (
    <>
      <Modal toggle={close} isOpen={isOpen}>
        <ModalHeader toggle={close}>Edit Rental Agreement History</ModalHeader>
        <ModalBody>
          <EditRentalAgreementHistoryForm
            onSubmit={close}
            rentalAgreementHistory={rentalAgreementHistory}
          />
        </ModalBody>
      </Modal>
    </>
  )
}
