import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

import { HeaderText, Modal, Button, Input } from 'components/Toolkit'

function UpdateDocumentModal({ close, document, update }) {
  const [notes, setNotes] = useState(document.review_notes)

  return (
    <Modal close={close} hasCloseButton>
      <HeaderText style={{ marginTop: '40px', marginBottom: '20px' }} size='h4'>
        Update document
      </HeaderText>
      <Input lable='Notes' value={notes} onChange={setNotes} />
      <Button onClick={() => update(notes)} style={{ width: '100%', marginTop: '40px' }}>
        Update
      </Button>
    </Modal>
  )
}

UpdateDocumentModal.propTypes = {
  close: PropTypes.func,
  document: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired
}

export default memo(UpdateDocumentModal)
