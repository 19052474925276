import React, { FunctionComponent, useState, useEffect, KeyboardEvent } from 'react'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/20/solid'
import { Modal } from 'components/TailwindUIToolkit'

type PropTypes = {
  open: boolean
  files: string[]
  setClosed: (b: boolean) => void
  firstImage?: number
}

export const ImageCarouselModal: FunctionComponent<React.PropsWithChildren<PropTypes>> = ({
  open,
  files,
  setClosed,
  firstImage = 0
}) => {
  const [modalImgIdx, setModalImgIdx] = useState<number>(firstImage)

  useEffect(() => setModalImgIdx(firstImage), [firstImage])

  const decrementModalImgIdx = () => setModalImgIdx((files.length + modalImgIdx - 1) % files.length)

  const incrementModalImgIdx = () => setModalImgIdx((modalImgIdx + 1) % files.length)

  const handleKeyPress = (event: KeyboardEvent) => {
    if (!open) {
      return
    }
    if (event.key === 'ArrowLeft') {
      decrementModalImgIdx()
    }
    if (event.key === 'ArrowRight') {
      incrementModalImgIdx()
    }
  }

  return (
    <Modal open={open} setClosed={setClosed} onKeyDown={handleKeyPress}>
      <button
        onClick={decrementModalImgIdx}
        className='z-12 absolute left-0 h-full w-1/4 opacity-0 hover:opacity-50'
      >
        <ChevronLeftIcon />
      </button>
      <button
        onClick={incrementModalImgIdx}
        className='z-12 absolute right-0 h-full w-1/4 opacity-0 hover:opacity-50'
      >
        <ChevronRightIcon />
      </button>
      <img
        src={files[modalImgIdx]}
        alt={files[modalImgIdx]}
        className='w-full rounded-lg object-contain align-middle'
      />
    </Modal>
  )
}
