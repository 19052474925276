import React, { useState, useEffect } from 'react'
import { GridReadyEvent, GridApi } from 'ag-grid-community'

import { BaseGrid } from 'components/TailwindUIToolkit'
import { UPCOMING_COLUMNS } from '../../Grid/upcoming-configuration'
import { UpcomingRenewalRentals } from '@homevest/types/renewals'

interface RentalsToRenewTableProps {
  data: UpcomingRenewalRentals
  setSelectedRental: (rental: any) => void
}

type RentalToRenewDataRow = UpcomingRenewalRentals[number]

const RentalsToRenewTable: React.FC<React.PropsWithChildren<RentalsToRenewTableProps>> = ({
  data,
  setSelectedRental
}) => {
  const [gridApi, setGridApi] = useState<GridApi<RentalToRenewDataRow>>()

  const onGridReady = (evt: GridReadyEvent<RentalToRenewDataRow>) => {
    setGridApi(evt.api)
  }

  const gridSetup = (evt: GridReadyEvent<RentalToRenewDataRow>) => {
    setGridApi(evt.api)
    onGridReady(evt)
  }

  useEffect(() => {
    if (gridApi && data) {
      try {
        gridApi.setRowData(data)
        gridApi.sizeColumnsToFit()
      } catch {
        console.log('Ran into an error drawing the grid, you may need to refresh the page')
      }
    }
  }, [gridApi, data])

  return (
    <div className='ag-theme-material h-[50vh] border'>
      <BaseGrid<RentalToRenewDataRow>
        rowAutoHeight={true}
        columns={UPCOMING_COLUMNS}
        rowData={data}
        onGridReady={gridSetup}
        onRowClicked={(evt) => setSelectedRental(evt.data)}
        onGridSizeChanged={() => gridApi && gridApi.sizeColumnsToFit()}
      />
    </div>
  )
}

export default RentalsToRenewTable
