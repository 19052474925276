import { Table, TableContainer, Thead, Tr, Th, Tbody, Td, Heading, Text } from '@chakra-ui/react'
import React from 'react'

import { CsvUploadValidationError } from 'types/Utilities'

type ErrorsTableProps = {
  errors: CsvUploadValidationError[]
}

const ErrorsTable: React.FC<React.PropsWithChildren<ErrorsTableProps>> = ({ errors }) => {
  if (!errors.length) {
    return null
  }

  return (
    <>
      <Heading size='sm'>Errors</Heading>
      <Text as='i'> Please review the following errors before resubmitting the CSV.</Text>
      <TableContainer>
        <Table variant={'striped'}>
          <Thead>
            <Tr>
              <Th>Bill ID</Th>
              <Th>Error</Th>
            </Tr>
          </Thead>
          <Tbody>
            {errors.map((error) => (
              <Tr key={error.billExternalId}>
                <Td>{error.billExternalId}</Td>
                <Td whiteSpace={'normal'} wordBreak={'break-word'}>
                  {error.message}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ErrorsTable
