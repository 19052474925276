import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import ReactLoading from 'react-loading'

import axios from 'lib/axios'

export default class Dzone extends Component {
  static propTypes = {
    uploadPath: PropTypes.string,
    onSuccess: PropTypes.func,
    onRemove: PropTypes.func,
    header: PropTypes.string,
    onBegin: PropTypes.func
  }

  static defaultProps = {
    onBegin: () => null,
    onRemove: () => null,
    onSuccess: () => null,
    uploadToHomevestData: false
  }

  state = {
    file: null,
    loading: false,
    error: null,
    downloadUrl: null
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    if (this.state.file) {
      URL.revokeObjectURL(this.state.file.preview)
    }
  }

  handleReset = (override) => {
    this.setState(
      Object.assign(
        {
          file: null,
          loading: false,
          error: null,
          downloadUrl: null,
          alreadyUploaded: false
        },
        override
      )
    )

    if (!override.loading) {
      this.props.onRemove()
    }
  }

  handleDrop = async (acceptedFiles) => {
    this.handleReset({ loading: true })
    this.props.onBegin()

    const file = acceptedFiles[0]

    this.setState({
      file: Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    })

    if (this.props.uploadPath) {
      // Get a signed upload and download URL from the backend.
      const { data } = await axios.get(
        `documents/signed_upload_url?is_admin=true&file_name=${this.props.uploadPath}${
          this.props.uploadToHomevestData
            ? `&is_homevest_data=${this.props.uploadToHomevestData}`
            : ''
        }`
      )

      await axios.put(data.upload_url, file, {
        headers: { 'Content-Type': file.type }
      })

      const url = data.download_url

      this.setState({
        loading: false,
        downloadUrl: url
      })
      this.props.onSuccess(url, this.props.uploadPath)
    }
  }

  render() {
    if (this.state.downloadUrl) {
      return (
        <div className='dropzone-file-container'>
          <span className='material-icons check-icon'>check_circle</span>

          {this.props.header && <span className='dropzone-header'>{this.props.header}</span>}

          <div className='dropzone-file'>
            <a
              href={this.state.downloadUrl}
              target='_blank'
              rel='noreferrer'
              className='material-icons file-icon'
            >
              insert_drive_file
            </a>
            <div onClick={this.handleReset} className='dropzone-file-name'>
              <b>Remove</b>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <Dropzone {...this.props} multiple={false} onDrop={this.handleDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
              >
                <input {...getInputProps()} />

                {this.props.header && <span className='dropzone-header'>{this.props.header}</span>}

                <span className='material-icons upload-icon'>cloud_upload</span>

                {this.state.loading && (
                  <>
                    <ReactLoading
                      className='dropzone-loading'
                      type='spin'
                      color='#black'
                      height={25}
                      width={25}
                    />

                    <p>Uploading, please do not refresh.</p>
                  </>
                )}

                {!this.state.loading &&
                  (isDragActive ? (
                    <p>Drop files here...</p>
                  ) : (
                    <>
                      <p>
                        <b>Choose file</b> or drag here.
                      </p>
                      {this.state.error && (
                        <>
                          <br />
                          <p>this.state.error</p>
                        </>
                      )}
                    </>
                  ))}
              </div>
            )
          }}
        </Dropzone>
      )
    }
  }
}
