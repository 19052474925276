import PropTypes from 'prop-types'
import React, { Component } from 'react'
import GoogleMap from 'google-map-react'
import styled from 'styled-components'

import homeImage from '../../assets/home.svg'

const Circle = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 5px black;
`

const Image = styled.img`
  height: 20px;
`

export default class Map extends Component {
  static propTypes = {
    coordinates: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired
    })
  }

  render() {
    const { coordinates } = this.props
    const { latitude, longitude } = coordinates

    return (
      <div
        style={{
          height: '300px',
          width: '100%',
          marginBottom: '80px',
          marginTop: '20px'
        }}
      >
        <GoogleMap
          bootstrapURLKeys={{
            key: 'AIzaSyAllvPSGOH0_q2zk3bCS-swGeD0g1LPu98'
          }}
          options={{ fullscreenControl: false }}
          defaultCenter={{
            lat: latitude,
            lng: longitude
          }}
          defaultZoom={14}
        >
          <Circle lat={latitude} lng={longitude}>
            <Image src={homeImage} />
          </Circle>
        </GoogleMap>
      </div>
    )
  }
}
