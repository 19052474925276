import React, { FunctionComponent, useState } from 'react'
import { Spinner } from 'reactstrap'
import {
  ActionButton,
  ContentSectionCard,
  LinkButton,
  TwoColumnDataList
} from 'components/TailwindUIToolkit'
import type { DisplayDataType } from 'components/TailwindUIToolkit'
import { Sidepeek } from 'ui'
import { formatMoney, formatPercent } from 'lib/numbers'
import { formatNullableDateString } from 'lib/date-time'
import { useAcquisitionDetailsByPropertyIdQuery } from 'graphql/generated'
import Error from 'components/Toolkit/Text/Error'
import { RETOOL_BID_TRACKING_APP_ID, RETOOL_APP_URL } from 'constants/urls'
import { ButtonContainer } from 'components/TailwindUIToolkit/StyledComponents'
import { DocumentsViewAndUploadTool } from 'components/Documents/DocumentsViewAndUploadTool'
import { ACQUISITIONS_DOCUMENT_TYPE_MAP } from 'lib/document-manager'

interface AcquisitionDetailsProps {
  portfolioHomeId: string
  acquisitionId?: string
}

const AcquisitionDetails: FunctionComponent<React.PropsWithChildren<AcquisitionDetailsProps>> = ({
  portfolioHomeId
}) => {
  const [{ data, error }] = useAcquisitionDetailsByPropertyIdQuery({
    variables: { portfolioHomeId }
  })

  if (error) {
    return <Error>{error.message}</Error>
  }

  const acquisition = data?.portfolio_homes_by_pk?.real_estate_acquisition

  if (!acquisition) {
    return <Error>could not find data</Error>
  }

  const rentPriceInfo = acquisition.final_ic_opportunity_underwrite

  const dataList: DisplayDataType = [
    {
      label: 'Price',
      value: formatMoney(acquisition.final_purchase_price, 2, '$')
    },
    {
      label: 'Closing Date',
      value: formatNullableDateString(acquisition.closing_date)
    },
    {
      label: 'Underwritten Rent',
      value: formatMoney(rentPriceInfo?.target_rent, 2, '$')
    },
    {
      label: 'Underwritten Yield',
      value: formatPercent(rentPriceInfo?.target_cap_rate, 2)
    },
    {
      label: 'Initial Capex',
      value: formatMoney(rentPriceInfo?.upfront_improvements_cost, 2, '$')
    },
    {
      label: "Seller's Credits",
      value: formatMoney(acquisition.final_sellers_credits_amount, 2, '$')
    }
  ]

  return <TwoColumnDataList data={dataList} />
}

const AcquisitionDetailsWrapper: FunctionComponent<
  React.PropsWithChildren<AcquisitionDetailsProps>
> = (props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  const buttons = (
    <ButtonContainer>
      <ActionButton onClick={() => setDrawerOpen(true)}>
        Manage Documents
        <Sidepeek
          title='Acquisition Documents'
          isOpen={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <DocumentsViewAndUploadTool
            resourceId={props.acquisitionId}
            resourceType='real_estate_acquisitions'
            resourceTypeMap={ACQUISITIONS_DOCUMENT_TYPE_MAP}
          />
        </Sidepeek>
      </ActionButton>
      <LinkButton href={`${RETOOL_APP_URL}${RETOOL_BID_TRACKING_APP_ID}`}>Bid Tool</LinkButton>
    </ButtonContainer>
  )

  return (
    <ContentSectionCard title='Acquisition Details' padding action={buttons}>
      <React.Suspense fallback={<Spinner />}>
        <AcquisitionDetails {...props} />
      </React.Suspense>
    </ContentSectionCard>
  )
}

export default AcquisitionDetailsWrapper
