import { RECEIVED_ADMIN } from './actions'

export default function reducer(state = {}, action) {
  switch (action.type) {
    case RECEIVED_ADMIN:
      return {
        ...action.admin
      }
    default:
      return state
  }
}
