import React, { FunctionComponent, useState } from 'react'
import { first, startCase } from 'lodash'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import {
  ConstructionWalkthroughsFragment,
  useWalkthroughsByPortfolioHomeIdQuery
} from 'graphql/generated'
import { BaseGrid, DisplayDataType, LeftAlignedDataList } from 'components/TailwindUIToolkit'
import { formatNullableDateString } from 'lib/date-time'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import Error from 'components/Toolkit/Text/Error'
import { Sidepeek } from 'ui'

export type Walkthrough = ConstructionWalkthroughsFragment

const columns: ColDef<Walkthrough>[] = [
  {
    headerName: 'date',
    field: 'start_at',
    sort: 'desc',
    valueFormatter: (v) => formatNullableDateString(v.value),
    flex: 2
  },
  {
    headerName: 'Type',
    field: 'type',
    flex: 2,
    valueFormatter: (v) => startCase(v.value)
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: 2,
    cellStyle: { padding: 0 },
    cellRenderer: (params: ICellRendererParams) => mapContentToBadge(params.value)
  },
  {
    headerName: 'completed by',
    field: 'admin',
    valueFormatter: (v) => `${v.value?.first_name} ${v.value?.last_name}`,
    flex: 2
  }
]

interface GridProps {
  portfolioHomeId: string
}

export const Grid: FunctionComponent<React.PropsWithChildren<GridProps>> = ({
  portfolioHomeId
}) => {
  const [{ data, error }] = useWalkthroughsByPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  const [drawerTarget, setDrawerTarget] = useState<Walkthrough>()

  if (error) {
    return <Error>{error.message}</Error>
  }

  const propertyWalkthroughs = data?.portfolio_homes_by_pk?.construction_walkthroughs || []

  const scopeWalkthroughs =
    data?.portfolio_homes_by_pk?.real_estate_acquisition?.construction_projects
      .map((cp) => first(cp.construction_scopes)?.construction_walkthroughs || [])
      .flat() || []

  const rowData = [...propertyWalkthroughs, ...scopeWalkthroughs]

  return (
    <>
      <BaseGrid<Walkthrough>
        columns={columns}
        rowData={rowData}
        onRowDoubleClicked={(evt) => {
          setDrawerTarget(evt.data)
        }}
      />
      {drawerTarget && (
        <WalkthroughDrawer
          walkthrough={drawerTarget}
          isOpen={!!drawerTarget}
          onClose={() => setDrawerTarget(undefined)}
        />
      )}
    </>
  )
}

const WalkthroughDrawer: React.FC<
  React.PropsWithChildren<{
    walkthrough: Walkthrough
    isOpen: boolean
    onClose: () => void
  }>
> = ({ walkthrough, ...sidePeekProps }) => {
  const displayInfo: DisplayDataType = [
    {
      label: 'date',
      value: formatNullableDateString(walkthrough.start_at)
    },
    {
      label: 'type',
      value: startCase(walkthrough.type)
    },
    {
      label: 'status',
      value: mapContentToBadge(walkthrough.status)
    },
    {
      label: 'completed by',
      value: walkthrough.admin && `${walkthrough.admin?.first_name} ${walkthrough.admin?.last_name}`
    },
    { label: 'note', value: walkthrough.note?.note },
    {
      label: 'external source',
      value: walkthrough.external_link ? (
        <a href={walkthrough.external_link}>{walkthrough.external_source}</a>
      ) : (
        walkthrough.external_source
      )
    }
  ]

  return (
    <Sidepeek {...sidePeekProps} title='Walkthrough Details'>
      <LeftAlignedDataList data={displayInfo} />
    </Sidepeek>
  )
}
