import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Alert } from 'reactstrap'
import { dismissAlert } from '../../state/properties/actions'

export default function AlertBar() {
  const dispatch = useDispatch()
  const { message, alertColor, show } = useSelector((state) => state.properties.errorAlert)

  const onDismiss = () => dispatch(dismissAlert())

  return (
    <div className='sticky-top'>
      <Alert color={alertColor} isOpen={show} toggle={onDismiss}>
        {message}
      </Alert>
    </div>
  )
}
