export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const SET_ERROR_MODAL_IS_OPEN = 'SET_ERROR_MODAL_IS_OPEN'
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE'
export const SET_SUCCESS_MODAL_IS_OPEN = 'SET_SUCCESS_MODAL_IS_OPEN'

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  message
})

export const setErrorModalIsOpen = (isOpen) => ({
  type: SET_ERROR_MODAL_IS_OPEN,
  isOpen
})

export const setSuccessMessage = (message) => ({
  type: SET_SUCCESS_MESSAGE,
  message
})

export const setSuccessModalIsOpen = (isOpen) => ({
  type: SET_SUCCESS_MODAL_IS_OPEN,
  isOpen
})
