import React, { FunctionComponent, useState } from 'react'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import {
  PortfolioHomesRentalHistoryFragment,
  useRentalHistoryByPortfolioHomeIdQuery
} from 'graphql/generated'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { BaseGrid, DisplayDataType, LeftAlignedDataList, SC } from 'components/TailwindUIToolkit'
import Error from 'components/Toolkit/Text/Error'
import { formatNullableDateString } from 'lib/date-time'
import { first, last } from 'lodash'
import { formatMoney } from 'lib/numbers'
import { formatPhoneNumber } from 'lib/phone'
import { Sidepeek } from 'ui'
import moment from 'moment'

export type RentalDetails = PortfolioHomesRentalHistoryFragment['rentals'][number]

const columns: ColDef<RentalDetails>[] = [
  {
    headerName: 'Primary Tenant',
    colId: 'primary_tenant',
    valueGetter: (v) => {
      return v.data?.primary_rental_user.find((ru) => ru.role === 'primary')?.user
    },
    cellRenderer: (params: ICellRendererParams<RentalDetails>) => (
      <SC.StyledRouterLink to={`/rent-roll/${params.data?.id}`}>
        {params.value?.first_name} {params.value?.last_name}
      </SC.StyledRouterLink>
    ),
    flex: 2
  },
  {
    headerName: 'Status',
    colId: 'rental_status',
    valueGetter: (v) => v.data?.status,
    cellRenderer: (params: ICellRendererParams) => mapContentToBadge(params.value),
    cellStyle: { padding: 0 },
    flex: 2
  },
  {
    headerName: 'move in',
    colId: 'occupancy_date',
    valueGetter: (v) => v.data?.occupancy_date,
    sort: 'desc',
    valueFormatter: (v) => formatNullableDateString(v.value),
    flex: 2
  },
  {
    headerName: 'Rent',
    colId: 'rent',
    valueGetter: (v) => {
      const activeAgreement = v.data?.rental_agreement_histories.find((rah) =>
        moment(rah.starts_at).isBefore(moment())
      )
      return (activeAgreement ?? last(v.data?.rental_agreement_histories))?.rent
    },
    valueFormatter: (v) => formatMoney(v.value, 0, '$'),
    flex: 2
  }
]

interface GridProps {
  portfolioHomeId: string
}

const Grid: FunctionComponent<React.PropsWithChildren<GridProps>> = ({ portfolioHomeId }) => {
  const [{ data, error }] = useRentalHistoryByPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  const [drawerTarget, setDrawerTarget] = useState<RentalDetails>()

  if (error) {
    return <Error>{error.message}</Error>
  }

  const rentals = data?.portfolio_homes_by_pk?.rentals

  if (!rentals) {
    return <Error>data not found</Error>
  }

  return (
    <>
      <BaseGrid<RentalDetails>
        columns={columns}
        rowData={rentals}
        onRowDoubleClicked={(evt) => setDrawerTarget(evt.data)}
      />
      {drawerTarget && (
        <RentalDetailsDrawer
          rental={drawerTarget}
          isOpen={!!drawerTarget}
          onClose={() => setDrawerTarget(undefined)}
        />
      )}
    </>
  )
}

const RentalDetailsDrawer: React.FC<
  React.PropsWithChildren<{
    rental: RentalDetails
    isOpen: boolean
    onClose: () => void
  }>
> = ({ rental, isOpen, onClose }) => {
  const primaryRentalUser = first(rental.primary_rental_user)?.user

  const displayInfo: DisplayDataType = [
    {
      label: 'primary tenant',
      value: (
        <SC.StyledRouterLink to={`/rent-roll/${rental.id}`}>
          {primaryRentalUser?.first_name} {primaryRentalUser?.last_name}
        </SC.StyledRouterLink>
      )
    },
    {
      label: 'primary phone',
      value: formatPhoneNumber(first(primaryRentalUser?.primary_phone)?.contact_info)
    },
    {
      label: 'primary email',
      value: first(primaryRentalUser?.primary_email)?.contact_info
    },
    {
      label: 'status',
      value: mapContentToBadge(rental.status)
    },
    {
      label: 'move in date',
      value: formatNullableDateString(rental.occupancy_date)
    },
    {
      label: 'move out date',
      value: formatNullableDateString(rental.move_out_date) ?? 'TBD'
    },
    {
      label: 'rent',
      value: formatMoney(first(rental.rental_agreement_histories)?.rent, 0, '$')
    }
  ]
  return (
    <Sidepeek isOpen={isOpen} onClose={onClose} title='Rental Details'>
      <LeftAlignedDataList data={displayInfo} />
    </Sidepeek>
  )
}

export default Grid
