import React, { useState } from 'react'
import { Heading, HStack, Select, Stack, Button, Box } from '@chakra-ui/react'
import { Helmet } from 'react-helmet'
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { ColumnApi, ColumnState, GridApi, GridReadyEvent } from 'ag-grid-community'

import Grid from './Grid'
import {
  useCollectionsByMonthQuery,
  useLiabilitiesRemainingForCollectionsQuery
} from 'graphql/generated'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const currentMonth = new Date().getMonth() + 1

const currentYear = new Date().getFullYear()

const years = Array.from({ length: 5 }, (_, i) => currentYear - i)

const Collections = () => {
  const [month, setMonth] = useState(currentMonth)
  const [year, setYear] = useState(currentYear)
  const [gridApi, setGridApi] = useState<GridApi>()
  const [columnApi, setColumnApi] = useState<ColumnApi>()
  const [savedFilterModel, setSavedFilterModel] = useState<{ [k: string]: any }>()
  const [savedColumnState, setSavedColumnState] = useState<ColumnState[]>()

  const firstDayOfMonth = new Date(year, month - 1, 1).toISOString()
  const lastDayOfMonth = new Date(year, month, 0).toISOString()

  // 1st query to fetch most collection-related data
  const [{ data: collections, fetching }, refetchData] = useCollectionsByMonthQuery({
    variables: { firstDayOfMonth, lastDayOfMonth },
    requestPolicy: 'network-only'
  })

  // 2nd query to fetch remaining amount unpaid for each collection - takes a long time to run
  // Because of this, we never fetch this data besides on initial page load
  const [{ data: liabilitiesRemaining, fetching: fetchingLiabilitiesRemaining }] =
    useLiabilitiesRemainingForCollectionsQuery({
      variables: { firstDayOfMonth, lastDayOfMonth },
      requestPolicy: 'network-only'
    })

  // Join the data from the two queries together
  const liabilitiesRemainingDict = liabilitiesRemaining
    ? Object.fromEntries(liabilitiesRemaining.collections.map((lr) => [lr.id, lr]))
    : undefined
  const collectionRows = collections
    ? collections.collections
        .filter((c) => {
          // Filter out test rental in production
          if (process.env.NODE_ENV === 'production') {
            return c.rental.id !== process.env.REACT_APP_TEST_RENTAL_ID
          }
          return true
        })
        .map((c) => ({
          collection: c,
          liabilitiesRemaining: liabilitiesRemainingDict
            ? liabilitiesRemainingDict[c.id]
            : undefined
        }))
    : undefined

  const onGridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api)
    setColumnApi(evt.columnApi)

    // If available, reset user's saved filters & column state
    if (savedFilterModel || savedColumnState) {
      evt.api.setFilterModel(savedFilterModel)
      evt.columnApi.applyColumnState({ state: savedColumnState })
    } else {
      // Default column state set upon initial page load
      evt.columnApi.applyColumnState({
        state: [
          {
            colId: 'address',
            sort: 'asc'
          }
        ]
      })
    }
  }

  const handleCsvExport = () => {
    const currentTimestamp = new Date().getTime()
    gridApi?.exportDataAsCsv({
      allColumns: true,
      fileName: `collections_export_${currentTimestamp}.csv`
    })
  }

  const saveStateAndRefetch = () => {
    // Before refetching data (which destroys the grid)
    // save the user's current filters & column state
    setSavedFilterModel(gridApi?.getFilterModel())
    setSavedColumnState(columnApi?.getColumnState())
    refetchData({ requestPolicy: 'network-only' })
  }

  const isFetching = fetching || fetchingLiabilitiesRemaining || !collectionRows

  return (
    <>
      <Helmet>
        <title>Collections Manager</title>
      </Helmet>
      <Stack padding={4}>
        <Heading>Collections</Heading>
        <Box alignSelf={'flex-end'}>
          <Button
            onClick={handleCsvExport}
            leftIcon={<ArrowDownTrayIcon className='h-4 w-4' />}
            colorScheme={'teal'}
          >
            Export to CSV
          </Button>
        </Box>
        <HStack>
          <Stack flex={1}>
            <Heading size='md'>Month</Heading>
            <Select value={month} onChange={(e) => setMonth(Number(e.currentTarget.value))}>
              {months.map((m, idx) => (
                <option key={m} value={idx + 1}>
                  {m}
                </option>
              ))}
            </Select>
          </Stack>
          <Stack flex={1}>
            <Heading size='md'>Year</Heading>
            <Select value={year} onChange={(e) => setYear(Number(e.currentTarget.value))}>
              {years.map((y) => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </Select>
          </Stack>
        </HStack>
        {!isFetching && (
          <Grid data={collectionRows} refetchData={saveStateAndRefetch} onGridReady={onGridReady} />
        )}
      </Stack>
    </>
  )
}

export default Collections
