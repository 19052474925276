import React from 'react'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { formatDistanceToNowStrict } from 'date-fns'
import { Stack, Link, Image } from '@chakra-ui/react'

import { StatusBadge, CheckTypeBadge } from './Badges'
import CheckSearchLink from './CheckSearchLink'
import MuteUnmuteButton from './MuteUnmuteButton'
import { CheckType, RentalSiteChecksDataType } from 'types/RentalSiteChecker'

const ACTIONS: ColDef<RentalSiteChecksDataType> = {
  headerName: 'Actions',
  maxWidth: 150,
  suppressSizeToFit: true,
  sortable: false,
  filter: false,
  cellRenderer: (
    params: ICellRendererParams<RentalSiteChecksDataType> & {
      handleMuteAction?: (id: string) => Promise<void>
    }
  ) => {
    const handleMuteAction = params.handleMuteAction

    if (!handleMuteAction || !params.data) {
      return null
    }

    return (
      <MuteUnmuteButton
        buttonType={params.data?.muted_at ? 'unmute' : 'mute'}
        handleMuteAction={() => handleMuteAction(params.data?.id)}
      />
    )
  }
}

const ADDRESS: ColDef<RentalSiteChecksDataType> = {
  headerName: 'Address',
  field: 'opportunity.display_line_1',
  cellRenderer: (params: ICellRendererParams<RentalSiteChecksDataType>) => {
    return (
      <Link isExternal href={'/home/' + params.data?.opportunity.home_id}>
        {params.value}
      </Link>
    )
  }
}

const STATUS: ColDef<RentalSiteChecksDataType> = {
  headerName: 'Status',
  field: 'status',
  filter: false,
  valueFormatter: (params) => (params.value ? 'Found' : 'Missing'),
  cellRenderer: (params: ICellRendererParams<RentalSiteChecksDataType>) => {
    return <StatusBadge status={params.value} />
  }
}

const LAST_RUN: ColDef<RentalSiteChecksDataType> = {
  headerName: 'Last Run',
  field: 'last_run_at',
  filter: false,
  valueFormatter: (params) => formatTimestampRelativeToNow(params.data?.last_run_at)
}

const CHECK_TYPE: ColDef<RentalSiteChecksDataType> = {
  headerName: 'Check Type',
  field: 'name',
  cellRenderer: (params: ICellRendererParams<RentalSiteChecksDataType>) => {
    return <CheckTypeBadge checkType={params.value} />
  }
}

const MUTED_BY: ColDef<RentalSiteChecksDataType> = {
  headerName: 'Muted By',
  field: 'muted_by_admin.last_name',
  valueGetter: (params) =>
    `${params.data?.muted_by_admin?.first_name} ${params.data?.muted_by_admin?.last_name}`
}

const MUTED_AT: ColDef<RentalSiteChecksDataType> = {
  headerName: 'Muted At',
  field: 'muted_at',
  filter: false,
  valueFormatter: (params) => formatTimestampRelativeToNow(params.value)
}

const ERROR_TEXT: ColDef<RentalSiteChecksDataType> = {
  headerName: 'Error',
  field: 'error',
  autoHeight: true
}

const LINK: ColDef<RentalSiteChecksDataType> = {
  headerName: 'Check Search Link',
  filter: false,
  cellRenderer: (params: ICellRendererParams<RentalSiteChecksDataType>) => {
    return (
      <CheckSearchLink
        checkType={params.data?.name as CheckType}
        zipCode={params.data?.opportunity.zip!}
      />
    )
  }
}

const IMAGES: ColDef<RentalSiteChecksDataType> = {
  headerName: 'Images',
  field: 'images',
  sortable: false,
  filter: false,
  autoHeight: true,
  cellRenderer: (params: ICellRendererParams<RentalSiteChecksDataType>) => {
    return (
      <Stack spacing={3} direction='row'>
        {params.value.map((i: any) => (
          <Link isExternal href={i}>
            <Image boxSize='50px' src={i} />
          </Link>
        ))}
      </Stack>
    )
  }
}

const SUCCESSFUL_CHECKS_COLUMNS: ColDef[] = [ADDRESS, STATUS, LAST_RUN, CHECK_TYPE, LINK, IMAGES]

const MUTED_CHECKS_COLUMNS: ColDef[] = [
  ACTIONS,
  ADDRESS,
  STATUS,
  LAST_RUN,
  CHECK_TYPE,
  MUTED_BY,
  MUTED_AT,
  ERROR_TEXT,
  LINK,
  IMAGES
]

const FAILED_CHECKS_COLUMNS: ColDef[] = [
  ACTIONS,
  ADDRESS,
  STATUS,
  LAST_RUN,
  CHECK_TYPE,
  ERROR_TEXT,
  LINK,
  IMAGES
]

const formatTimestampRelativeToNow = (dateString: string): string => {
  if (!dateString) {
    return ''
  }
  return formatDistanceToNowStrict(new Date(dateString)) + ' ago'
}

type TableType = 'failed' | 'successful' | 'muted'

type AdditionalParams = {
  handleMuteAction?: (id: string) => Promise<void>
}

export const mapTableTypeToColumnSet = (
  tableType: TableType,
  additionalParams?: AdditionalParams
): ColDef[] => {
  let columns: ColDef[]
  switch (tableType) {
    case 'failed':
      columns = FAILED_CHECKS_COLUMNS
      break
    case 'successful':
      columns = SUCCESSFUL_CHECKS_COLUMNS
      break
    case 'muted':
      columns = MUTED_CHECKS_COLUMNS
      break
    default:
      throw new Error('Unrecognized table type!')
  }

  return generateColumnsWithAdditionalParams(columns, additionalParams)
}

const generateColumnsWithAdditionalParams = (
  columns: ColDef[],
  additionalParams: AdditionalParams = {}
): ColDef[] => {
  return columns.map((column) => {
    return {
      ...column,
      cellRendererParams: {
        ...column.cellRendererParams,
        ...additionalParams
      }
    }
  })
}
