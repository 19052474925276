import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import NoteWidgetOption from 'types/NoteWidgetOption'
import CreateNoteForm from './CreateNoteForm'

const CreateNoteModal: React.FC<
  React.PropsWithChildren<{
    close: () => void
    isOpen: boolean
    resourceNoteWidgetOptions: NoteWidgetOption[]
  }>
> = ({ close, isOpen, resourceNoteWidgetOptions }) => {
  const writableResourceNoteWidgetOptions: NoteWidgetOption[] = resourceNoteWidgetOptions.filter(
    (rnwo) => rnwo.writable
  )
  return (
    <>
      <Modal toggle={close} isOpen={isOpen}>
        <ModalHeader toggle={close}>Create a Note</ModalHeader>
        <ModalBody>
          <CreateNoteForm
            onSubmit={close}
            resourceNoteWidgetOptions={writableResourceNoteWidgetOptions}
            writeCategory=''
          />
        </ModalBody>
      </Modal>
    </>
  )
}

CreateNoteModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  resourceNoteWidgetOptions: PropTypes.array.isRequired
}

export default CreateNoteModal
