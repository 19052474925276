import React, { useEffect, useState } from 'react'
import get from 'lodash/get'
import { rentals } from '@homevest/utils'

import axios from 'lib/axios'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { BankAccountFragment } from 'graphql/generated'

const { RENTAL_STATUS } = rentals

interface Balance {
  available: number
  current: number
}

interface IBankAccount {
  balances: Balance
  name: string
  mask: string
  // eslint-disable-next-line
  official_name: string
}

export default function BankAccount({ rental }: { rental: BankAccountFragment }) {
  const [bankAccount, setBankAccount] = useState<IBankAccount | null>(null)

  const userId = rental.user.id
  const isPlaidInvalid = Boolean(rental.user.plaid_access_token_invalid_at)
  const isActive = rental.status === RENTAL_STATUS.ACTIVE

  useEffect(() => {
    const loadBankAccount = async (userId: string) => {
      const { data: bankAccount } = await axios.get(`/admin/users/${userId}/bank_account`)

      setBankAccount(bankAccount)
    }

    if (userId && isActive) {
      loadBankAccount(userId)
    }
  }, [userId, isActive])

  if (!isActive) {
    return null
  }

  return (
    <ContentSectionCard padding title={'Current Bank Account'}>
      {bankAccount && !isPlaidInvalid ? (
        <>
          <p>
            <b>Name: </b>
            {bankAccount.official_name || bankAccount.name}
          </p>
          <p>
            <b>Last 4: </b>
            {bankAccount.mask}
          </p>
          <p>
            <b>Balance: </b>
            {get(
              bankAccount,
              'balances.available',
              get(bankAccount, 'balances.current', 'Unable to determine balance')
            )}
          </p>
        </>
      ) : (
        <p>
          {isPlaidInvalid
            ? 'Please contact the customer to re-link their account.'
            : 'Unable to determine linked account. Has the customer linked a checking account?'}
        </p>
      )}
    </ContentSectionCard>
  )
}
