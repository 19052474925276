import React, { useState } from 'react'
import startCase from 'lodash/startCase'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Alert,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  useToast
} from '@chakra-ui/react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { useQuery } from '@tanstack/react-query'
import { GetConstructionProjectsApiResponse } from '@homevest/types/construction-projects'
import type { Ticket } from '@homevest/types/tickets'
import { TICKET_CATEGORIES, TICKET_TYPES } from '@homevest/utils/tickets'

import { LINK_COLOR } from 'constants/colors'
import axios from 'lib/axios'
import { formatUTCDate } from 'lib/date-time'
import {
  validateEnrichTicketPayload,
  formatTicketTypeForDisplay,
  EnrichedTicketData
} from 'lib/tickets'

const EnrichTicketProjectSelect: React.FC<{
  portfolioHomeId: string
  address: string | null
  enrichedTicketData: EnrichedTicketData
  setEnrichedTicketData: React.Dispatch<React.SetStateAction<EnrichedTicketData>>
}> = ({ portfolioHomeId, address, enrichedTicketData, setEnrichedTicketData }) => {
  const { data, error, isLoading, isFetching, isError } = useQuery<
    GetConstructionProjectsApiResponse,
    Error
  >({
    queryKey: ['getProjects'],
    queryFn: async () => {
      const { data } = await axios.get<GetConstructionProjectsApiResponse>(
        'admin/construction_projects',
        { params: { portfolio_home_id: portfolioHomeId } }
      )
      return data
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  if (isLoading || isFetching || !data) {
    return <Spinner />
  }

  if (isError && error) {
    return <Alert status='error'>{error.message}</Alert>
  }

  const filteredProjects = data.data
    .filter((cp) => (enrichedTicketData.type ? cp.type === enrichedTicketData.type : true))
    // Always show most recent project first
    .sort((cp1, cp2) => {
      const d1 = new Date(cp1.created_at)
      const d2 = new Date(cp2.created_at)
      return d2.valueOf() - d1.valueOf()
    })

  return (
    <Select
      className='bg-white'
      value={enrichedTicketData.project_id ?? ''}
      onChange={(e) =>
        setEnrichedTicketData({
          ...enrichedTicketData,
          project_id: e.target.value ?? null
        })
      }
    >
      <option value=''>Select a project</option>
      {filteredProjects.map((cp) => {
        let projectName = `${startCase(cp.type)} for ${address}`
        if (cp.start_date) {
          projectName += ` starting on ${formatUTCDate(cp.start_date, 'MMM do, yyyy')}`
        } else {
          projectName += ` created on ${formatUTCDate(cp.created_at, 'MMM do, yyyy')}`
        }
        return (
          <option key={cp.id} value={cp.id}>
            {projectName}
          </option>
        )
      })}
    </Select>
  )
}

const EnrichTicketDateInput: React.FC<{
  title: string
  column: string
  enrichedTicketData: EnrichedTicketData
  setEnrichedTicketData: React.Dispatch<React.SetStateAction<EnrichedTicketData>>
  errorMessage?: string
}> = ({ title, column, enrichedTicketData, setEnrichedTicketData, errorMessage }) => {
  const dateColumn = column as keyof typeof enrichedTicketData
  const dateValue = enrichedTicketData[dateColumn] as Date | null | undefined

  return (
    <FormControl isInvalid={!!errorMessage}>
      <FormLabel>{title}</FormLabel>
      <Input
        type='date'
        value={dateValue ? dateValue.toISOString().split('T')[0] : ''}
        onChange={(e) =>
          setEnrichedTicketData({
            ...enrichedTicketData,
            [dateColumn]: e.target.value ? new Date(e.target.value) : null
          })
        }
      />
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
}

const EnrichTicketDropdownInput: React.FC<{
  title: string
  column: string
  dropdownOptions: Record<string, string>
  enrichedTicketData: EnrichedTicketData
  setEnrichedTicketData: React.Dispatch<React.SetStateAction<EnrichedTicketData>>
  isRequired?: boolean
  errorMessage?: string
  valueFormatter?: (val: string) => string
}> = ({
  title,
  column,
  dropdownOptions,
  enrichedTicketData,
  setEnrichedTicketData,
  isRequired,
  errorMessage,
  valueFormatter = (x) => x
}) => {
  const dropdownColumn = column as keyof typeof enrichedTicketData
  const dropdownValue = enrichedTicketData[dropdownColumn] as string | null | undefined

  return (
    <FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
      <FormLabel>{title}</FormLabel>
      <Select
        value={dropdownValue ?? ''}
        onChange={(e) =>
          setEnrichedTicketData({
            ...enrichedTicketData,
            [dropdownColumn]: e.target.value ?? null
          })
        }
      >
        <option disabled value=''>
          Select a {startCase(dropdownColumn)}
        </option>
        {Object.values(dropdownOptions)
          .sort((a, b) => a.localeCompare(b))
          .map((val) => (
            <option key={val} value={val}>
              {valueFormatter(val)}
            </option>
          ))}
      </Select>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
}

const EnrichTicketNumberInput: React.FC<{
  title: string
  column: string
  enrichedTicketData: EnrichedTicketData
  setEnrichedTicketData: React.Dispatch<React.SetStateAction<EnrichedTicketData>>
  errorMessage?: string
}> = ({ title, column, enrichedTicketData, setEnrichedTicketData, errorMessage }) => {
  const numberColumn = column as keyof typeof enrichedTicketData
  const value = enrichedTicketData[numberColumn] as number | null | undefined

  return (
    <FormControl isInvalid={!!errorMessage}>
      <FormLabel>{title}</FormLabel>
      <InputGroup>
        <InputLeftAddon>$</InputLeftAddon>
        <Input
          type='number'
          value={value ?? ''}
          onChange={(e) =>
            setEnrichedTicketData({
              ...enrichedTicketData,
              [numberColumn]: e.target.value === '' ? null : Number(e.target.value)
            })
          }
        />
      </InputGroup>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
}

const EnrichTicketModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  ticket: Ticket
  refetchTickets: () => any
}> = ({ isOpen, onClose, ticket, refetchTickets }) => {
  const [enrichedTicketData, setEnrichedTicketData] = useState<EnrichedTicketData>({
    project_id: ticket.project_id,
    category: ticket.category,
    type: ticket.type,
    actual_start_date: ticket.actual_start_date,
    estimated_end_date: ticket.estimated_end_date,
    actual_end_date: ticket.actual_end_date,
    estimated_cost: ticket.estimated_cost,
    actual_cost: ticket.actual_cost
  })
  const [validationErrors, setValidationErrors] = useState<
    Partial<Record<keyof EnrichedTicketData, string>>
  >({})
  const toast = useToast()

  const isMaintenance = enrichedTicketData.type === TICKET_TYPES.MAINTENANCE
  const needsEnrichment = !ticket.type || (!isMaintenance && !ticket.project_id)

  return (
    <Modal
      size='xl'
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setEnrichedTicketData({})
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <div className='pb-2 text-xl underline'>{needsEnrichment ? 'Enrich' : 'Edit'} Ticket</div>
          <Link
            className='flex items-center text-base'
            color={LINK_COLOR}
            isExternal
            href={`https://app.latchel.com/admin/work-order/${ticket.external_id}`}
          >
            {ticket.title}
            <ArrowTopRightOnSquareIcon className='h-4 pl-1' />
          </Link>
          <div className='text-sm'>{ticket.display_line_1}</div>
          <div className='text-sm'>{ticket.display_line_2}</div>
          <div className='mt-2 text-xs italic'>
            Some fields such as the vendor and status should be updated in Latchel, not here.
          </div>
        </ModalHeader>
        <ModalBody>
          <Stack spacing={5}>
            <EnrichTicketDropdownInput
              title={needsEnrichment ? 'Assign ticket type' : 'Ticket Type'}
              column='type'
              dropdownOptions={TICKET_TYPES}
              enrichedTicketData={enrichedTicketData}
              setEnrichedTicketData={setEnrichedTicketData}
              isRequired={true}
              errorMessage={validationErrors.type ?? undefined}
              valueFormatter={formatTicketTypeForDisplay}
            />
            {!isMaintenance && (
              <FormControl isRequired isInvalid={!!validationErrors.project_id}>
                <FormLabel>
                  {needsEnrichment
                    ? 'Attach ticket to a construction project'
                    : 'Assigned Construction Project'}
                </FormLabel>
                <EnrichTicketProjectSelect
                  portfolioHomeId={ticket.portfolio_home_id}
                  address={ticket.display_line_1}
                  enrichedTicketData={enrichedTicketData}
                  setEnrichedTicketData={setEnrichedTicketData}
                />
                {validationErrors.project_id && (
                  <FormErrorMessage>{validationErrors.project_id}</FormErrorMessage>
                )}
                {needsEnrichment && (
                  <FormHelperText>
                    If no options are shown, you may need to create a project for this home on the{' '}
                    <Link isExternal as={ReactRouterLink} to='projects' color={LINK_COLOR}>
                      projects page
                    </Link>
                    .
                  </FormHelperText>
                )}
              </FormControl>
            )}
            <EnrichTicketDropdownInput
              title='Category'
              column='category'
              dropdownOptions={TICKET_CATEGORIES}
              enrichedTicketData={enrichedTicketData}
              setEnrichedTicketData={setEnrichedTicketData}
              isRequired={!isMaintenance}
              errorMessage={validationErrors.category ?? undefined}
              valueFormatter={startCase}
            />
            <Divider />
            <EnrichTicketDateInput
              title='Actual Start Date'
              column='actual_start_date'
              enrichedTicketData={enrichedTicketData}
              setEnrichedTicketData={setEnrichedTicketData}
              errorMessage={validationErrors.actual_start_date ?? undefined}
            />
            <EnrichTicketDateInput
              title='Estimated End Date'
              column='estimated_end_date'
              enrichedTicketData={enrichedTicketData}
              setEnrichedTicketData={setEnrichedTicketData}
              errorMessage={validationErrors.estimated_end_date ?? undefined}
            />
            <EnrichTicketDateInput
              title='Actual End Date'
              column='actual_end_date'
              enrichedTicketData={enrichedTicketData}
              setEnrichedTicketData={setEnrichedTicketData}
              errorMessage={validationErrors.actual_end_date ?? undefined}
            />
            <EnrichTicketNumberInput
              title='Estimated Cost'
              column='estimated_cost'
              enrichedTicketData={enrichedTicketData}
              setEnrichedTicketData={setEnrichedTicketData}
              errorMessage={validationErrors.estimated_cost ?? undefined}
            />
            <EnrichTicketNumberInput
              title='Actual Cost'
              column='actual_cost'
              enrichedTicketData={enrichedTicketData}
              setEnrichedTicketData={setEnrichedTicketData}
              errorMessage={validationErrors.actual_cost ?? undefined}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={needsEnrichment ? 'green' : 'teal'}
            onClick={async () => {
              try {
                const { isValid, errors } = validateEnrichTicketPayload(enrichedTicketData)
                setValidationErrors(errors)

                if (isValid) {
                  await axios.put(`admin/tickets/${ticket.id}`, enrichedTicketData)
                  toast({
                    title: 'Success',
                    description: `Updated ticket!`,
                    status: 'success',
                    duration: 2000,
                    position: 'bottom-right',
                    isClosable: true
                  })
                  onClose()
                  refetchTickets()
                } else {
                  toast({
                    title: 'Error',
                    description: 'Cannot update ticket! Please fix the errors above.',
                    status: 'error',
                    duration: 5000,
                    position: 'bottom-right',
                    isClosable: true
                  })
                }
              } catch (err: any) {
                toast({
                  title: 'Error',
                  description: `An error occured while updating the ticket: ${err.message}`,
                  status: 'error',
                  duration: 5000,
                  position: 'bottom-right',
                  isClosable: true
                })
              }
            }}
          >
            {needsEnrichment ? 'Enrich Ticket' : 'Submit Changes'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EnrichTicketModal
