import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import HeaderText from 'components/Toolkit/Text/Header'
import DownChevron from 'components/Toolkit/Icons/DownChevron'
import UpChevron from 'components/Toolkit/Icons/UpChevron'

const StyledHeaderText = styled(HeaderText)`
  cursor: pointer;
`

function Toggle({ label, children }) {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!isOpen)

  return (
    <div>
      <StyledHeaderText size='h4' onClick={toggleOpen}>
        {label}{' '}
        {isOpen ? (
          <UpChevron color={COLORS.PRIMARY.PLUM} />
        ) : (
          <DownChevron color={COLORS.PRIMARY.PLUM} />
        )}
      </StyledHeaderText>
      {isOpen && <div>{children}</div>}
    </div>
  )
}

Toggle.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string
}

export default memo(Toggle)
