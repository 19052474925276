import React, { FunctionComponent } from 'react'
import { Spinner } from 'reactstrap'
import { format as formatDate, parse as parseDate } from 'date-fns'
import { rentals } from '@homevest/utils'

import { useRentalsByUserIdQuery } from 'graphql/generated'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'

const { RENTAL_STATUS } = rentals

type RentalsPropTypes = {
  userId: string
}

const Rentals: FunctionComponent<React.PropsWithChildren<RentalsPropTypes>> = ({ userId }) => {
  const [{ data, error, fetching }] = useRentalsByUserIdQuery({
    variables: { userId }
  })

  const rentals = (data?.rental_users ?? [])
    .map((ru) => ru.rental)
    .sort((a, b) => {
      // Put active and pending rentals at the top
      if (a.status === RENTAL_STATUS.ACTIVE || a.status === RENTAL_STATUS.PENDING) {
        return -1
      }

      if (b.status === RENTAL_STATUS.ACTIVE || b.status === RENTAL_STATUS.PENDING) {
        return 1
      }

      // Otherwise sort by occupancy date
      return new Date(b.occupancy_date).getTime() - new Date(a.occupancy_date).getTime()
    })

  if (error) {
    return <SC.MessageText>Something went wrong.</SC.MessageText>
  }

  if (fetching) {
    return (
      <SC.DetailsContainer borders>
        <Spinner />
      </SC.DetailsContainer>
    )
  }

  if (!rentals.length) {
    return null
  }

  if (!rentals.length) {
    return (
      <SC.DetailsContainer borders>
        <SC.MessageText>No applications found for user</SC.MessageText>
      </SC.DetailsContainer>
    )
  }

  const parseAndFormatDate = (date?: string) => {
    if (!date) {
      return ''
    }

    return formatDate(parseDate(date, 'yyyy-MM-dd', new Date()), 'M/d/yyyy')
  }

  return (
    <ContentSectionCard title={'Rentals'} size={'large'}>
      {rentals.map((rental) => (
        <SC.DetailsContainer borders key={rental.id}>
          <SC.SubsectionTitleRouterLink to={`/rent-roll/${rental.id}`}>
            {`${rental.portfolio_home?.home.address.display_line_1}, ${rental.portfolio_home?.home.address.display_line_2}` ||
              'Address Not Found'}
          </SC.SubsectionTitleRouterLink>
          <SC.LightDetailText>
            {mapContentToBadge(rental.status)} ({parseAndFormatDate(rental.occupancy_date)} -{' '}
            {parseAndFormatDate(rental.move_out_date)})
          </SC.LightDetailText>
        </SC.DetailsContainer>
      ))}
    </ContentSectionCard>
  )
}

export default Rentals
