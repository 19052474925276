import React, { FunctionComponent } from 'react'
import { first, lowerCase } from 'lodash'
import { rentals } from '@homevest/utils'
import { Homes360HeaderFragment } from 'graphql/generated'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import Error from 'components/Toolkit/Text/Error'
import { SummarySidebar } from './SummarySidebar'
import { LockboxCode } from './Components/LockboxCode'

const { RENTAL_PROGRAM_LEASE_TYPES } = rentals
interface HomeHeaderProps {
  home: Homes360HeaderFragment
}

const HomeStatuses: FunctionComponent<React.PropsWithChildren<HomeHeaderProps>> = ({ home }) => {
  const downIncident = first(home.portfolio_home?.down_status_incidents)

  const summaryBadgeText = downIncident ? downIncident.type : home.summary_status

  return (
    <>
      <div className='my-2 flex gap-2'>
        {summaryBadgeText &&
          mapContentToBadge(summaryBadgeText, {
            hoverText: downIncident
              ? `Since ${new Date(downIncident.created_at).toLocaleDateString()}`
              : undefined
          })}
      </div>
      <div className='flex gap-2'>
        {home.occupancy_status && mapContentToBadge(home.occupancy_status, { dot: false })}
        {home.construction_status && mapContentToBadge(home.construction_status, { dot: false })}
        {home.ownership_status && mapContentToBadge(home.ownership_status, { dot: false })}
      </div>
    </>
  )
}

const HomeHeader: FunctionComponent<React.PropsWithChildren<HomeHeaderProps>> = ({ home }) => {
  const opportunity =
    home.portfolio_home?.real_estate_acquisition?.opportunity ??
    home.opportunities.find((o) => !!first(o.real_estate_acquisitions))

  if (!opportunity) {
    return <Error>could not find home details</Error>
  }

  return (
    <div className='flex flex-col justify-between gap-2'>
      <img
        className='mx-2 h-full rounded-md object-cover'
        alt='property-splash'
        src={first(opportunity.photos)?.url}
      />
      <div className='flex-start min-w-0 flex-1 flex-col'>
        <div className='font-sans text-xl font-bold capitalize leading-8 text-gray-900'>
          {home.display_line_1 && lowerCase(home.display_line_1)}
        </div>
        <div className='font-sans text-gray-700'>{home.display_line_2}</div>
        <div>
          {home?.market?.display_name &&
            mapContentToBadge(home.market?.display_name, { dot: false })}
        </div>
        <HomeStatuses home={home} />
        <div>
          {home?.portfolio_home?.rental_program_type &&
            mapContentToBadge(RENTAL_PROGRAM_LEASE_TYPES[home.portfolio_home.rental_program_type], {
              dot: false
            })}
        </div>
        {home.portfolio_home && <LockboxCode {...home.portfolio_home} />}
      </div>
      <div className='w-full flex-1 flex-col gap-2 text-sm text-gray-500'>
        <div className='flex flex-row gap-2 text-gray-900'>
          <div>{home.beds} beds </div>
          <div>&middot;</div>
          <div>{home.baths_total} baths</div>
          <div>&middot;</div>
          <div>{home.sqft} sqft </div>
        </div>
        <div>Built in {home.year_built}</div>
        <div>{home.id}</div>
      </div>
      <SummarySidebar portfolioHomeId={home.portfolio_home?.id} />
    </div>
  )
}

export default HomeHeader
