import React, { useState } from 'react'
import _, { omit } from 'lodash'
import {
  Table,
  Thead,
  Tr,
  Th,
  TableContainer,
  Button,
  Spinner,
  Center,
  VStack,
  HStack,
  Tbody,
  Tfoot,
  Text
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { requiredLeaseDetails } from '@homevest/utils'
import { OccupantWithId } from '@homevest/types/rental-applications'

import axios from 'lib/axios'
import EditOccupantsRow from './EditOccupantsRow'
import AddOccupantRow from './AddOccupantRow'
import SkipValidationSwitch from '../../Common/SkipValidationSwitch'
import { isOccupantValid } from 'lib/required-lease-details'

const { MAX_COUNTS, REQUIRED_LEASE_DETAIL_TYPES } = requiredLeaseDetails

type IndexedOccupants = Record<string, OccupantWithId>

type EditOccupantsProps = {
  rentalApplicationId: string
  currentOccupants: OccupantWithId[]
  exitEditMode: () => void
}

const EditOccupants: React.FC<React.PropsWithChildren<EditOccupantsProps>> = ({
  currentOccupants,
  rentalApplicationId,
  exitEditMode
}) => {
  const indexedOccupants = currentOccupants.reduce((acc, occupant) => {
    acc[occupant.id] = occupant
    return acc
  }, {} as IndexedOccupants)

  const [occupants, setOccupants] = useState<IndexedOccupants>(indexedOccupants)
  const [skipValidation, setSkipValidation] = useState(false)

  const canSubmit =
    skipValidation || Object.values(occupants).every((occupant) => isOccupantValid(occupant))

  const occupantCount = Object.keys(occupants).length
  const maxOccupantCount = MAX_COUNTS[REQUIRED_LEASE_DETAIL_TYPES.OCCUPANTS]
  const canAddOccupant = occupantCount < maxOccupantCount

  const queryClient = useQueryClient()

  const { isLoading, mutate: submitOccupantDetails } = useMutation({
    mutationFn: async (occupants: IndexedOccupants) => {
      await axios.post(`/admin/rental_applications/${rentalApplicationId}/required_lease_details`, {
        type: 'occupants',
        data: Object.values(occupants),
        skipValidation
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchRequiredLeaseDetails'] })
      exitEditMode()
    }
  })

  const editOccupant = (occupantId: string, updatedOccupant: OccupantWithId) => {
    setOccupants({
      ...occupants,
      [occupantId]: updatedOccupant
    })
  }

  const removeOccupant = (occupantId: string) => {
    setOccupants(omit(occupants, [occupantId]))
  }

  const addOccupant = (occupant: OccupantWithId) => {
    setOccupants({
      ...occupants,
      [occupant.id]: occupant
    })
  }

  if (isLoading) {
    return (
      <Center marginY={3}>
        <Spinner colorScheme='teal' />
      </Center>
    )
  }

  return (
    <>
      <VStack>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Age</Th>
                <Th>Relation</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.values(occupants).map((occupant) => (
                <EditOccupantsRow
                  key={occupant.id}
                  occupant={occupant}
                  removeOccupant={removeOccupant}
                  editOccupant={editOccupant}
                  skipValidation={skipValidation}
                />
              ))}
            </Tbody>
            <Tfoot>
              <AddOccupantRow
                addOccupant={addOccupant}
                canAddOccupant={occupantCount < maxOccupantCount}
                skipValidation={skipValidation}
              />
            </Tfoot>
          </Table>
        </TableContainer>
        {!canAddOccupant && (
          <Text color={'red.500'}>
            A maximum of {maxOccupantCount} occupants can be listed on the lease!
          </Text>
        )}
        <SkipValidationSwitch
          skipValidation={skipValidation}
          setSkipValidation={setSkipValidation}
        />
        <HStack width='100%' paddingX={3} spacing={3}>
          <Button
            width='100%'
            disabled={!canSubmit}
            onClick={() => submitOccupantDetails(occupants)}
            colorScheme='teal'
          >
            Save
          </Button>
          <Button width='100%' variant='outline' onClick={exitEditMode} colorScheme='red'>
            Cancel
          </Button>
        </HStack>
      </VStack>
    </>
  )
}

export default EditOccupants
