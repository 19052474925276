import React, { FunctionComponent, useState } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  Stack,
  Text
} from '@chakra-ui/react'
import { backgroundCheckRequests } from '@homevest/utils'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'

import axios from 'lib/axios'

const { REPORT_TYPES } = backgroundCheckRequests

type BackgroundChecksComponentPropTypes = {
  isAllowed: boolean
  userId: string
}

const BackgroundChecksWrapper: FunctionComponent<
  React.PropsWithChildren<BackgroundChecksComponentPropTypes>
> = (props) => (
  <ContentSectionCard title={'Background Checks'} padding>
    <BackgroundChecks {...props} />
  </ContentSectionCard>
)

const BackgroundChecks: FunctionComponent<
  React.PropsWithChildren<BackgroundChecksComponentPropTypes>
> = ({ isAllowed, userId }) => {
  const [isInvalidating, setIsInvalidating] = useState<boolean>(false)
  const [isInvalidationConfirmed, setIsInvalidationConfirmed] = useState(false)
  const [isLaunching, setIsLaunching] = useState<boolean>(false)
  const [isLaunchConfirmed, setIsLaunchConfirmed] = useState(false)
  const [isLaunchingPreexistingCredit, setIsLaunchingPreexistingCredit] = useState<boolean>(false)
  const [isLaunchingPreexistingCreditConfirmed, setIsLaunchingPreexistingCreditConfirmed] =
    useState(false)
  const [isLaunchingPreexistingCriminalEviction, setIsLaunchingPreexistingCriminalEviction] =
    useState<boolean>(false)
  const [
    isLaunchingPreexistingCriminalEvictionConfirmed,
    setIsLaunchingPreexistingCriminalEvictionConfirmed
  ] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState<string>('')

  async function doInvalidation() {
    try {
      setSuccessMessage('')
      setErrorMessage('')
      setIsInvalidating(true)
      const { data } = await axios.post('/admin/background_check_requests/scrub', {
        user_id: userId
      })
      setSuccessMessage(`Invalidation complete! ${data} request(s) were impacted.`)
    } catch (err: any) {
      setErrorMessage(err.message)
    } finally {
      setIsInvalidating(false)
      setIsInvalidationConfirmed(false)
    }
  }

  async function doLaunch() {
    try {
      setSuccessMessage('')
      setErrorMessage('')
      setIsLaunching(true)
      const { data } = await axios.post('/admin/background_check_requests', {
        user_id: userId
      })
      setSuccessMessage(
        `Launch request initiated! Please check their application to determine if it was successful. ${JSON.stringify(
          data
        )}`
      )
    } catch (err: any) {
      setErrorMessage(err.message)
    } finally {
      setIsLaunchConfirmed(false)
      setIsLaunching(false)
    }
  }

  async function doLaunchPreexistingCreditCheck() {
    try {
      setSuccessMessage('')
      setErrorMessage('')
      setIsLaunchingPreexistingCredit(true)

      await axios.post('/admin/background_check_requests/launch', {
        user_id: userId,
        types: [REPORT_TYPES.CREDIT]
      })
      setSuccessMessage(
        `Launch request initiated! Please check their application to determine if it was successful`
      )
    } catch (err: any) {
      setErrorMessage(err.message)
    } finally {
      setIsLaunchingPreexistingCreditConfirmed(false)
      setIsLaunchingPreexistingCredit(false)
    }
  }

  async function doLaunchPreexistingCriminalEvictionCheck() {
    try {
      setSuccessMessage('')
      setErrorMessage('')
      setIsLaunchingPreexistingCriminalEviction(true)

      await axios.post('/admin/background_check_requests/launch', {
        user_id: userId,
        types: [REPORT_TYPES.CRIMINAL, REPORT_TYPES.EVICTION]
      })
      setSuccessMessage(
        `Launch request initiated! Please check their application to determine if it was successful`
      )
    } catch (err: any) {
      setErrorMessage(err.message)
    } finally {
      setIsLaunchingPreexistingCriminalEvictionConfirmed(false)
      setIsLaunchingPreexistingCriminalEviction(false)
    }
  }

  return (
    <SC.SmallBoxContainer>
      {isAllowed && (
        <Stack spacing={3}>
          {errorMessage && (
            <Alert status='error'>
              <AlertIcon />
              <Box>
                <AlertTitle>Whoops!</AlertTitle>
                <AlertDescription>{errorMessage}</AlertDescription>
              </Box>
            </Alert>
          )}
          {successMessage && (
            <Alert status='success'>
              <AlertIcon />
              <Box>
                <AlertTitle>Cool!</AlertTitle>
                <AlertDescription>{successMessage}</AlertDescription>
              </Box>
            </Alert>
          )}
          <Text as='b'>TEMP - Launch Credit Check on Customer's Behalf</Text>
          <Text>
            Use this button to launch a credit check on behalf of a customer (this is a temporary
            measure to prevent scammers)
          </Text>
          <Checkbox
            isChecked={isLaunchingPreexistingCreditConfirmed}
            isDisabled={isLaunchingPreexistingCreditConfirmed}
            onChange={(e: any) => {
              setIsLaunchingPreexistingCreditConfirmed(e.target.checked)
            }}
          >
            Are you sure you want to launch?
          </Checkbox>
          <Button
            color='red'
            isDisabled={isLaunchingPreexistingCredit || !isLaunchingPreexistingCreditConfirmed}
            onClick={doLaunchPreexistingCreditCheck}
          >
            Launch 🚀!
          </Button>

          <Text as='b'>TEMP - Launch Criminal/Eviction Check on Customer's Behalf</Text>
          <Text>
            Use this button to launch criminal and eviction checks on behalf of a customer (this is
            a temporary measure to prevent scammers)
          </Text>
          <Checkbox
            isChecked={isLaunchingPreexistingCriminalEvictionConfirmed}
            isDisabled={isLaunchingPreexistingCriminalEviction}
            onChange={(e: any) => {
              setIsLaunchingPreexistingCriminalEvictionConfirmed(e.target.checked)
            }}
          >
            Are you sure you want to launch?
          </Checkbox>
          <Button
            color='red'
            isDisabled={
              isLaunchingPreexistingCriminalEviction ||
              !isLaunchingPreexistingCriminalEvictionConfirmed
            }
            onClick={doLaunchPreexistingCriminalEvictionCheck}
          >
            Launch 🚀!
          </Button>

          <hr className='my-3' />
          <Text as='b'>Invalidate Recent Background Checks</Text>
          <Text>
            You can invalidate pending and successful background checks for the last 90 days so that
            a user can resubmit.
          </Text>
          <Checkbox
            isChecked={isInvalidationConfirmed}
            isDisabled={isInvalidating}
            onChange={(e: any) => {
              setIsInvalidationConfirmed(e.target.checked)
            }}
          >
            Are you sure you want to invalidate?
          </Checkbox>
          <Button
            color='red'
            isDisabled={isInvalidating || !isInvalidationConfirmed}
            onClick={doInvalidation}
          >
            Invalidate!
          </Button>
          <hr className='my-3' />
          <Text as='b'>Launch Background Check</Text>
          <Text>
            You can start a new background check request if there are no pending requests and the
            user has stored their information securely in the vault.
          </Text>
          <Checkbox
            isChecked={isLaunchConfirmed}
            isDisabled={isLaunching}
            onChange={(e: any) => {
              setIsLaunchConfirmed(e.target.checked)
            }}
          >
            Are you sure you want to launch?
          </Checkbox>
          <Button color='red' isDisabled={isLaunching || !isLaunchConfirmed} onClick={doLaunch}>
            Launch 🚀!
          </Button>
        </Stack>
      )}
    </SC.SmallBoxContainer>
  )
}

export default BackgroundChecksWrapper
