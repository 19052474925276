import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const BASE = styled.p`
  color: ${(props) => props.color};
  font-family: Poppins;
  font-style: normal;
  margin: 7px 0;
`

const SIZES = {
  l: styled(BASE)`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: 20px;
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  `,
  r: styled(BASE)`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: 16px;
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '20px')};
  `,
  m: styled(BASE)`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: 14px;
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  `,
  s: styled(BASE)`
    font-weight: ${(props) => (props.weight ? props.weight : '400')};
    font-size: 12px;
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  `,
  xs: styled(BASE)`
    font-weight: ${(props) => (props.weight ? props.weight : '500')};
    font-size: 10px;
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  `
}

function BodyText({ size = 'r', weight = '', color = '#55545A', lineHeight = '', ...otherProps }) {
  const Elm = SIZES[size]

  return <Elm lineHeight={lineHeight} color={color} weight={weight} {...otherProps} />
}

BodyText.propTypes = {
  color: PropTypes.string,
  lineHeight: PropTypes.string,
  size: PropTypes.oneOf(['l', 'r', 'm', 's', 'xs']),
  total: PropTypes.number,
  weight: PropTypes.string
}

export default memo(BodyText)
