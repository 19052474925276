import React, { useState, useEffect, useCallback } from 'react'
import { GridApi, GridReadyEvent, RowNode } from 'ag-grid-community'
import { Button, Center, Spinner, Tabs, Tab, TabList, Stack } from '@chakra-ui/react'
import { latchel } from '@homevest/utils'

import { ContentSectionCard, BaseGrid } from 'components/TailwindUIToolkit'
import { useTicketsByActiveVendorQuery } from 'graphql/generated'
import { ALL_COLUMNS, COLUMNS_FOR_CALENDAR_EXPORT, TicketRow } from './configuration'

const { LATCHEL_STATUSES, INACTIVE_LATCHEL_STATUSES } = latchel

const TAB_NAMES = {
  ACTIVE_UPCOMING: 'Active/Upcoming',
  PAST: 'Past',
  ALL: 'All'
}

const ORDERED_TABS = [TAB_NAMES.ACTIVE_UPCOMING, TAB_NAMES.PAST, TAB_NAMES.ALL]

const TAB_NAME_TO_INDEX = ORDERED_TABS.reduce((acc, tabName, index) => {
  acc[tabName] = index
  return acc
}, {} as Record<string, number>)

const TicketsTable = ({ vendorId }: { vendorId: string }) => {
  const [gridApi, setGridApi] = useState<GridApi>()
  const [tabIndex, setTabIndex] = useState(0)

  const [{ data, fetching }] = useTicketsByActiveVendorQuery({
    variables: { vendorId: vendorId }
  })

  useEffect(() => {
    if (gridApi) {
      // Needs to be called in setTimeout to ensure
      // grid setup is complete
      setTimeout(() => gridApi.onFilterChanged(), 0)
    }
  }, [gridApi, tabIndex])

  const onGridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api)
    evt.api.onFilterChanged()
  }

  const exportCalendarAsCsv = () => {
    if (gridApi) {
      const currentTimestamp = new Date().getTime()
      gridApi.exportDataAsCsv({
        allColumns: true,
        columnKeys: COLUMNS_FOR_CALENDAR_EXPORT.map((col) => col.colId) as string[],
        fileName: `vendor_calendar_${vendorId}_${currentTimestamp}.csv`
      })
    }
  }

  const handleTabChange = (index: number) => {
    setTabIndex(index)
  }

  const isExternalFilterPresent = useCallback(
    () => tabIndex !== TAB_NAME_TO_INDEX[TAB_NAMES.ALL],
    [tabIndex]
  )

  const mapTabToFilterFn = (tabIndex: number): ((row: TicketRow) => boolean) => {
    // const today = new Date()
    const tabName = ORDERED_TABS[tabIndex]

    switch (tabName) {
      case TAB_NAMES.ACTIVE_UPCOMING:
        return (row) => {
          return !INACTIVE_LATCHEL_STATUSES.includes(row.status as any)
          // For now let's use latchel statuses for this tab since we dont have a good way
          // to manually set dates until Phase 3
          // const coalescedStartDate =
          //   row.actual_start_date ?? row.current_start_date ?? row.planned_start_date
          // if (!coalescedStartDate) {
          //   return true
          // }
          // return isAfterOrSameDay(parseDate(coalescedStartDate), today)
        }
      case TAB_NAMES.PAST:
        return (row) => {
          return row.status === LATCHEL_STATUSES.COMPLETED
          // For now let's use latchel statuses for this tab since we dont have a good way
          // to manually set dates until Phase 3
          // if (!(row.estimated_end_date || row.actual_end_date)) {
          //   return false
          // }

          // return isBeforeOrSameDay(parseDate(row.actual_end_date ?? row.estimated_end_date), today)
        }
      case TAB_NAMES.ALL:
        return (_row) => true
      default:
        return (_row) => true
    }
  }

  const doesExternalFilterPass = useCallback(
    (node: RowNode<TicketRow>) => {
      if (!node.data) {
        return false
      }

      return mapTabToFilterFn(tabIndex)(node.data)
    },
    [tabIndex]
  )

  if (fetching) {
    return (
      <Center>
        <Spinner color='teal' size='lg' />
      </Center>
    )
  }

  return (
    <ContentSectionCard
      title='Tickets'
      padding
      collapsable={false}
      size='fit-content'
      action={
        <Button colorScheme={'teal'} variant='outline' onClick={exportCalendarAsCsv}>
          Export Calendar
        </Button>
      }
    >
      <Stack spacing={5}>
        <Tabs index={tabIndex} onChange={handleTabChange} align='end' colorScheme={'teal'}>
          <TabList>
            {ORDERED_TABS.map((tabName) => (
              <Tab key={tabName}>{tabName}</Tab>
            ))}
          </TabList>
        </Tabs>

        <div className='h-[60vh] rounded border'>
          <BaseGrid
            rowData={data?.tickets || []}
            onGridReady={onGridReady}
            onGridSizeChanged={() => gridApi && gridApi.sizeColumnsToFit()}
            columns={ALL_COLUMNS}
            tooltipShowDelay={500}
            rowAutoHeight={true}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
          />
        </div>
      </Stack>
    </ContentSectionCard>
  )
}

export default TicketsTable
