import React, { FunctionComponent } from 'react'
import {
  Spinner,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text
} from '@chakra-ui/react'
import { useFindDocumentsByResourceIdsQuery } from 'graphql/generated'
import { DocumentDisplayData } from 'components/Documents/DocumentDisplayData'
import { UploadDocumentButtonWithPopout } from './UploadDocumentButtonWithPopout'

const DisplayAttachedDocuments: FunctionComponent<
  React.PropsWithChildren<{
    resourceId: string
  }>
> = ({ resourceId = () => {} }) => {
  const [{ data }] = useFindDocumentsByResourceIdsQuery({
    variables: { resourceIds: [resourceId] }
  })

  return (
    <Box borderWidth='1px' rounded='md'>
      <Text p='4'>Attached Documents</Text>
      <Accordion allowMultiple maxH='420px' overflowY='auto'>
        {data?.documents.length ? (
          data.documents.map((doc) => (
            <AccordionItem key={doc.id}>
              <AccordionButton>
                <Box flex='1' textAlign='left'>
                  {doc.friendly_name}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <DocumentDisplayData doc={doc} />
              </AccordionPanel>
            </AccordionItem>
          ))
        ) : (
          <Box p='4'>
            <Text fontSize='sm' color='gray.500'>
              No attached documents found
            </Text>
          </Box>
        )}
      </Accordion>
    </Box>
  )
}

export const DocumentsViewAndUploadTool: FunctionComponent<
  React.PropsWithChildren<{
    resourceId?: string
    resourceType: string
    resourceTypeMap: { [key: string]: string }
  }>
> = ({ resourceTypeMap, resourceId, resourceType }) => {
  if (!resourceId) {
    return <div>'No resource ID given'</div>
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <Stack direction='column' gap='4'>
        <DisplayAttachedDocuments resourceId={resourceId} />
        <UploadDocumentButtonWithPopout
          resourceId={resourceId}
          resourceType={resourceType}
          typeMap={resourceTypeMap}
        />
      </Stack>
    </React.Suspense>
  )
}
