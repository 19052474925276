import React, { useState } from 'react'
import { Button, Heading } from '@chakra-ui/react'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'

import SourceTypeForm from './SourceTypeForm'
import SourceDetailsForm from './SourceDetailsForm'
import { SourceDetailsContextProvider, useSourceDetails } from './context'

const DISPLAY_STATES = {
  CLOSED: 'closed',
  SELECT_TYPE: 'selecting_type',
  ENTERING_SOURCE_DETAILS: 'entering_source_details'
}

const AddSource = ({
  userId,
  onOpen: onOpenCallback,
  onClose,
  onSubmit: onSubmitCallback
}: {
  userId: string
  onOpen: () => void
  onClose: () => void
  onSubmit: () => void
}) => {
  const [displayState, setDisplayState] =
    useState<typeof DISPLAY_STATES[keyof typeof DISPLAY_STATES]>('closed')

  const context = useSourceDetails()
  if (!context) {
    return null
  }

  const { clear } = context

  const onAddSourceOpen = () => {
    setDisplayState(DISPLAY_STATES.SELECT_TYPE)
    onOpenCallback()
  }

  const onBack = () => {
    clear()
    setDisplayState(DISPLAY_STATES.CLOSED)
    onClose()
  }

  const onSubmit = () => {
    clear()
    setDisplayState(DISPLAY_STATES.CLOSED)
    onClose()
    onSubmitCallback()
  }

  if (displayState === DISPLAY_STATES.CLOSED) {
    return (
      <Button onClick={onAddSourceOpen} size='sm' colorScheme='teal' variant='outline'>
        + Add Source
      </Button>
    )
  }

  return (
    <>
      <div>
        <Button
          aria-label='Back'
          onClick={onBack}
          variant='ghost'
          colorScheme={'gray'}
          leftIcon={<ArrowLeftIcon color='grey' className='h-6 w-6' />}
        >
          Back to Income Sources
        </Button>
        <div className='mx-5 mt-3 flex flex-col gap-3'>
          <Heading size='lg'>Add an Income Source</Heading>
          {displayState === DISPLAY_STATES.SELECT_TYPE && (
            <SourceTypeForm
              onContinue={() => setDisplayState(DISPLAY_STATES.ENTERING_SOURCE_DETAILS)}
            />
          )}
          {displayState === DISPLAY_STATES.ENTERING_SOURCE_DETAILS && (
            <SourceDetailsForm userId={userId} onSubmit={onSubmit} />
          )}
        </div>
        <div></div>
      </div>
    </>
  )
}

const AddSourceWrapper = ({
  userId,
  onOpen,
  onClose,
  onSubmit
}: {
  userId: string
  onOpen: () => void
  onClose: () => void
  onSubmit: () => void
}) => {
  return (
    <SourceDetailsContextProvider>
      <AddSource userId={userId} onOpen={onOpen} onClose={onClose} onSubmit={onSubmit} />
    </SourceDetailsContextProvider>
  )
}

export default AddSourceWrapper
