import React, { useState } from 'react'
import {
  Input,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react'
import { INACTIVE_LATCHEL_STATUSES } from '@homevest/utils/latchel'

import BasicPageLayout from 'components/TailwindUIToolkit/Layouts/BasicPageLayout'
import TicketsGrid from 'components/Construction/Tickets/TicketsGrid'

const Tickets = () => {
  const [ticketsSearch, setTicketsSearch] = useState('')

  return (
    <BasicPageLayout tabTitle='Tickets' heading='Tickets'>
      <div className='p-4'>
        <Stack>
          <Input
            type='text'
            value={ticketsSearch}
            onChange={(e) => setTicketsSearch(e.target.value)}
            placeholder='Search by ticket title, description, address, etc...'
          />
          <React.Suspense fallback={<Spinner />}>
            <Tabs isLazy>
              <TabList>
                <Tab fontWeight='semibold'>Active - Needs Enrichment</Tab>
                <Tab fontWeight='semibold'>Active - Enriched</Tab>
                <Tab fontWeight='semibold'>Completed/Canceled</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Text mb={4}>
                    Active tickets that need to be enriched with a ticket type, related project,
                    etc.
                  </Text>
                  <TicketsGrid
                    gridHeight={80}
                    ticketsSearch={ticketsSearch}
                    getTicketsConfig={{ params: { needs_enrichment: true } }}
                  />
                </TabPanel>
                <TabPanel>
                  <Text mb={4}>
                    Active tickets that have already have an assigned type and/or project
                  </Text>
                  <TicketsGrid
                    gridHeight={80}
                    ticketsSearch={ticketsSearch}
                    getTicketsConfig={{ params: { needs_enrichment: false } }}
                  />
                </TabPanel>
                <TabPanel>
                  <Text mb={4}>All completed/canceled tickets</Text>
                  <TicketsGrid
                    gridHeight={80}
                    ticketsSearch={ticketsSearch}
                    getTicketsConfig={{ params: { statuses: INACTIVE_LATCHEL_STATUSES } }}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </React.Suspense>
        </Stack>
      </div>
    </BasicPageLayout>
  )
}

export default Tickets
