import React from 'react'
import { regions, rentals } from '@homevest/utils'
import { format, parseISO } from 'date-fns'
import { Text, Flex, Button, Box, Tooltip } from '@chakra-ui/react'
import { sortBy } from 'lodash'
import { ChevronDoubleLeftIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

import {
  Application,
  ColumnKey,
  DEFAULT_SORT,
  FilterColumnKey,
  SelectColumnKey,
  SortColumnKey,
  SortState,
  TableState,
  mapAgentTypeToLabel,
  MARKET_MAP,
  STATUS_MAP,
  RENTAL_PROGRAM_TYPE_MAP
} from './applications-table-utils'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { Link, Tag } from 'ui'
import ColumnHeader from './ColumnHeader'
import StatusTag from './StatusTag'
import { Order_By } from 'graphql/generated'
import { SnoozeApplicationButton } from './SnoozeApplication/SnoozeApplicationButton'
import { combineFirstNameLastName } from 'lib/utils'

const { RENTAL_PROGRAM_TYPES } = rentals

export type ConfigInput = TableState & {
  onSort: (state: SortState) => void
  onSearch: (column: FilterColumnKey, value: string) => void
  onSelect: (column: SelectColumnKey, value: string[]) => void
  onPeek: (value: Application) => void
}

export const getColumnConfig = ({
  sortState,
  filterState,
  selectState,
  tabState,
  onSort,
  onSearch,
  onSelect,
  onPeek
}: ConfigInput) => [
  {
    id: ColumnKey.address,
    renderHeader: () => (
      <ColumnHeader
        label='Property address'
        sorted={isSorted(sortState, ColumnKey.address)}
        searched={filterState[ColumnKey.address]}
        onSort={() => onSort(toggleSort(sortState, ColumnKey.address))}
        onSearch={(value) => onSearch(ColumnKey.address, value)}
        sortable
        searchable
      />
    ),
    renderCell: (data: Application, hover: boolean) => (
      <Flex direction='row' align='center' position='relative'>
        <Link to={`/applications/${data.id}`} isExternal showIcon useRouter pr={12}>
          {data.mls_listing.display_line_1 ?? null}
        </Link>
        {hover && (
          <Button
            position='absolute'
            right={0}
            variant='solid'
            size='sm'
            px={1}
            bg='gray.200'
            onClick={() => onPeek(data)}
            shadow='md'
          >
            <Flex direction='row' align='center'>
              <Box h={4} w={4}>
                <ChevronDoubleLeftIcon />
              </Box>
              <Text fontSize='xs' fontWeight={600} px={1}>
                Peek
              </Text>
            </Flex>
          </Button>
        )}
      </Flex>
    )
  },
  {
    id: ColumnKey.market,
    renderHeader: () => (
      <ColumnHeader
        label='Market'
        sorted={isSorted(sortState, ColumnKey.market)}
        selected={selectState[ColumnKey.market]}
        selectOptions={[
          regions.REGIONS.ST_LOUIS,
          regions.REGIONS.ATLANTA,
          regions.REGIONS.INDIANAPOLIS,
          regions.REGIONS.CHARLOTTE
        ].map((key) => ({
          id: key,
          label: MARKET_MAP[key]
        }))}
        onSort={() => onSort(toggleSort(sortState, ColumnKey.market))}
        onSelect={(value) => onSelect(ColumnKey.market, value)}
        sortable
        selectable
      />
    ),
    renderCell: (data: Application) => (
      <Tag>{MARKET_MAP[(data.mls_listing.region as keyof typeof MARKET_MAP) ?? ''] ?? null}</Tag>
    )
  },
  {
    id: ColumnKey.lead_group,
    renderHeader: () => (
      <ColumnHeader
        label='Lead group'
        searched={filterState[ColumnKey.lead_group]}
        onSearch={(value) => onSearch(ColumnKey.lead_group, value)}
        searchable
      />
    ),
    renderCell: (data: Application) => (
      <Flex direction='column' gap={1}>
        {data.lead_group.lead_group_users.map((lgu) => (
          <Link key={lgu.id} to={`/users/${lgu.user.id}`} isExternal showIcon useRouter>
            {`${lgu.user.first_name} ${lgu.user.last_name}`}
          </Link>
        ))}
      </Flex>
    )
  },
  {
    id: ColumnKey.rental_program_type_applied_for,
    renderHeader: () => (
      <ColumnHeader
        label='Program'
        selected={selectState[ColumnKey.rental_program_type_applied_for]}
        selectOptions={Object.values(RENTAL_PROGRAM_TYPES).map((programType) => ({
          id: programType,
          label: RENTAL_PROGRAM_TYPE_MAP[programType as keyof typeof RENTAL_PROGRAM_TYPE_MAP]
        }))}
        onSort={() => onSort(toggleSort(sortState, ColumnKey.rental_program_type_applied_for))}
        onSelect={(value) => onSelect(ColumnKey.rental_program_type_applied_for, value)}
        sortable
        selectable
      />
    ),
    renderCell: (data: Application) => (
      <Flex direction='column' gap={1}>
        {mapContentToBadge(data.rental_program_type_applied_for)}
      </Flex>
    )
  },
  {
    id: ColumnKey.status,
    renderHeader: () => (
      <ColumnHeader
        label='Status'
        sorted={isSorted(sortState, ColumnKey.status)}
        selected={selectState[ColumnKey.status]}
        selectOptions={Object.keys(STATUS_MAP).map((key) => ({
          id: key,
          label: STATUS_MAP[key as keyof typeof STATUS_MAP]
        }))}
        onSort={() => onSort(toggleSort(sortState, ColumnKey.status))}
        onSelect={(value) => onSelect(ColumnKey.status, value)}
        sortable
        selectable
      />
    ),
    renderCell: (data: Application) => <StatusTag status={data.status} />
  },
  {
    id: ColumnKey.metadata,
    renderHeader: () => <ColumnHeader label='Next Step' />,
    renderCell: (data: Application) => (
      <Flex w='full' justifyContent='center'>
        <Tooltip fontSize='lg' p={4} label={data.status_metadata.message}>
          <Button variant='outline' size='xs' py={5} px={2}>
            <InformationCircleIcon width={24} height={24} />
          </Button>
        </Tooltip>
      </Flex>
    )
  },
  {
    id: ColumnKey.projected_occupancy_date,
    renderHeader: () => (
      <ColumnHeader
        label='Est. move in date'
        sorted={isSorted(sortState, ColumnKey.projected_occupancy_date)}
        onSort={() => onSort(toggleSort(sortState, ColumnKey.projected_occupancy_date))}
        sortable
      />
    ),
    renderCell: (data: Application) => (
      <Text>
        {data.projected_occupancy_date
          ? format(parseISO(data.projected_occupancy_date), 'MMM dd, yyyy')
          : 'N/A'}
      </Text>
    )
  },
  {
    id: ColumnKey.created_at,
    renderHeader: () => (
      <ColumnHeader
        label='Creation date'
        sorted={isSorted(sortState, ColumnKey.created_at)}
        onSort={() => onSort(toggleSort(sortState, ColumnKey.created_at))}
        sortable
      />
    ),
    renderCell: (data: Application) => (
      <Text>{data.created_at ? format(parseISO(data.created_at), 'MMM dd, yyyy') : null}</Text>
    )
  },
  {
    id: ColumnKey.assigned_team,
    renderHeader: () => (
      <ColumnHeader
        label='Assigned team'
        searched={filterState[ColumnKey.assigned_team]}
        onSearch={(value) => onSearch(ColumnKey.assigned_team, value)}
        searchable
      />
    ),
    renderCell: (data: Application) => {
      const admins = data.lead_group.agent_lead_group_owners
      if (!admins || admins.length === 0) {
        return null
      }
      return (
        <Flex direction='column' align='stretch' gap={1}>
          {sortBy(admins, ['agent_type']).map((a) => (
            <Flex direction='row' align='center' key={a.id} gap={1}>
              <Text flex={1} fontSize='xs' color='gray.500'>
                {mapAgentTypeToLabel(a.agent_type)}
              </Text>
              <Text flex={2}>{combineFirstNameLastName(a.admin)}</Text>
            </Flex>
          ))}
        </Flex>
      )
    }
  },
  // Removing this to reduce cluttler until we're clearer on how to prioritize
  // {
  //   id: ColumnKey.priority_score,
  //   renderHeader: () => (
  //     <ColumnHeader
  //       label='Priority score'
  //       sorted={isSorted(sortState, ColumnKey.priority_score)}
  //       onSort={() => onSort(toggleSort(sortState, ColumnKey.priority_score))}
  //       sortable
  //     />
  //   ),
  //   renderCell: (data: Application) => <Text>{data.priority_score || 'TBD'}</Text>
  // },
  {
    id: ColumnKey.submitted_at,
    renderHeader: () => <ColumnHeader label='Submitted' />,
    renderCell: (data: Application) => {
      const leadGroupUsers = data.lead_group.lead_group_users

      return leadGroupUsers.map((lgu) => (
        <Text key={lgu.id}>
          <b>
            {lgu.user.first_name} {lgu.user.last_name}
          </b>
          :{' '}
          {lgu.rental_application_submitted_at
            ? format(parseISO(lgu.rental_application_submitted_at), 'M/d/yy p')
            : '-'}
        </Text>
      ))
    }
  },
  ...(tabState === 'escalated'
    ? [
        {
          id: ColumnKey.escalation_note,
          renderHeader: () => (
            <ColumnHeader
              label='Escalation note'
              searched={filterState[ColumnKey.escalation_note]}
              onSearch={(value) => onSearch(ColumnKey.escalation_note, value)}
              searchable
            />
          ),
          renderCell: (data: Application) => (
            <Text>{data.application_escalations[0]?.reason ?? null}</Text>
          )
        },
        {
          id: ColumnKey.escalated_by,
          renderHeader: () => (
            <ColumnHeader
              label='Escalated by'
              searched={filterState[ColumnKey.escalated_by]}
              onSearch={(value) => onSearch(ColumnKey.escalated_by, value)}
              searchable
            />
          ),
          renderCell: (data: Application) => {
            const admin = data.application_escalations[0]?.assigned_by_admin
            if (!admin) {
              return null
            }
            return (
              <Text>
                {admin.first_name} {admin.last_name}
              </Text>
            )
          }
        },
        {
          id: ColumnKey.escalated_to,
          renderHeader: () => (
            <ColumnHeader
              label='Escalated to'
              searched={filterState[ColumnKey.escalated_to]}
              onSearch={(value) => onSearch(ColumnKey.escalated_to, value)}
              searchable
            />
          ),
          renderCell: (data: Application) => {
            const admin = data.application_escalations[0]?.assigned_to_admin
            if (!admin) {
              return null
            }
            return (
              <Text>
                {admin.first_name} {admin.last_name}
              </Text>
            )
          }
        },
        {
          id: ColumnKey.escalation_date,
          renderHeader: () => (
            <ColumnHeader
              label='Escalation date'
              sorted={isSorted(sortState, ColumnKey.escalation_date)}
              onSort={() => onSort(toggleSort(sortState, ColumnKey.escalation_date))}
              sortable
            />
          ),
          renderCell: (data: Application) => {
            const createdAt = data.application_escalations[0]?.created_at
            if (!createdAt) {
              return null
            }
            return <Text>{createdAt ? format(parseISO(createdAt), 'MMM dd, yyyy') : null}</Text>
          }
        }
      ]
    : []),
  {
    id: ColumnKey.snooze,
    renderHeader: () => (
      <ColumnHeader
        label='Snooze'
        selected={selectState[ColumnKey.snooze]}
        selectOptions={[
          {
            id: 'snoozed',
            label: 'Show snoozed'
          },
          {
            id: 'normal',
            label: 'Hide snoozed'
          }
        ]}
        onSelect={(value) => onSelect(ColumnKey.snooze, value)}
        selectable
      />
    ),
    renderCell: (data: Application) => <SnoozeApplicationButton rentalApplicationId={data.id} />
  }
]

const isSorted = (sortState: SortState, key: SortColumnKey) =>
  sortState.column === key ? sortState.type : undefined

const toggleSort = (sortState: SortState, matcher: SortColumnKey): SortState => {
  if (sortState.column !== matcher) {
    return { column: matcher, type: Order_By.Desc }
  }
  if (sortState.column === matcher && sortState.type === Order_By.Desc) {
    return { column: matcher, type: Order_By.Asc }
  }
  return DEFAULT_SORT
}
