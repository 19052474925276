"use strict";
const md5 = require('md5');
exports.seededUuid = (value) => {
    let template = '########-####-4###-8###-############';
    const seed = md5(value);
    while (template.indexOf('#') !== -1) {
        const index = template.replace(/-/g, '').indexOf('#');
        template = template.replace(/#/, seed[index]);
    }
    return template;
};
