import React, { useContext, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Th,
  Tr,
  HStack,
  Input,
  useToast
} from '@chakra-ui/react'
import { rentals } from '@homevest/utils'
import {
  MoveInFeesFragment,
  useCreateMoveInFeeMutation,
  useUpdateMoveInFeeAmountMutation,
  useDeactivateMoveInFeeMutation,
  Upup_LiabilityTypesQuery
} from 'graphql/generated'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import LiabilityTypesDropdown from './Dropdowns/LiabilityTypesDropdown'
import { formatMoney } from 'lib/numbers'
import { Label } from 'components/Toolkit'
import {
  RefetchMoveInPaymentContext,
  RefetchMoveInPaymentContextProvider
} from './MoveInPaymentContext'

type MoveInFee = {
  id: string
  amount: number
  name: string
}

type LiabilityType = Upup_LiabilityTypesQuery['liability_types'][number]

const AddMoveInFeeModal: React.FC<{
  rentalId: string
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ rentalId, isModalOpen, setIsModalOpen }) => {
  const [selectedLiabilityType, setSelectedLiabilityType] = useState<LiabilityType | undefined>(
    undefined
  )
  const [newFeeAmount, setNewFeeAmount] = useState<number | ''>('')
  const [_, createMoveInFee] = useCreateMoveInFeeMutation()
  const { refetchMoveInPayment } = useContext(RefetchMoveInPaymentContext)
  const toast = useToast()

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedLiabilityType(undefined)
    setNewFeeAmount('')
  }

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Add a Move-In Fee</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LiabilityTypesDropdown
              disabled={false}
              onSelect={(lt) => setSelectedLiabilityType(lt)}
              value={selectedLiabilityType}
            />
            <Label>Amount</Label>
            <Input
              type='number'
              value={newFeeAmount ?? ''}
              onChange={(e) => setNewFeeAmount(e.target.value === '' ? '' : Number(e.target.value))}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme='teal'
              isDisabled={!selectedLiabilityType?.id || !newFeeAmount}
              onClick={async (e) => {
                e.stopPropagation()
                const res = await createMoveInFee({
                  rentalId,
                  liabilityTypeId: selectedLiabilityType!.id,
                  amount: newFeeAmount
                })
                if (res.error) {
                  toast({
                    title: 'Error',
                    description: res.error.message,
                    status: 'error',
                    duration: 5000,
                    position: 'bottom-right',
                    isClosable: true
                  })
                } else {
                  closeModal()
                  if (refetchMoveInPayment) {
                    await refetchMoveInPayment()
                  }
                }
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

const ConfirmDeleteMoveInFeeModal: React.FC<{
  id: string
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  isConfirmDeleteModalOpen: boolean
  setIsConfirmDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ id, setIsEditing, isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen }) => {
  const [_, deactivateMoveInFee] = useDeactivateMoveInFeeMutation()
  const { refetchMoveInPayment } = useContext(RefetchMoveInPaymentContext)
  const toast = useToast()

  return (
    <Modal isOpen={isConfirmDeleteModalOpen} onClose={() => setIsConfirmDeleteModalOpen(false)}>
      <ModalOverlay>
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Really delete this move-in fee?</ModalHeader>
          <Button
            margin={3}
            colorScheme='red'
            onClick={async () => {
              const res = await deactivateMoveInFee({ id, deactivated_at: new Date() })
              if (res.error) {
                toast({
                  title: 'Error',
                  description: res.error.message,
                  status: 'error',
                  duration: 5000,
                  position: 'bottom-right',
                  isClosable: true
                })
              } else {
                setIsConfirmDeleteModalOpen(false)
                setIsEditing(false)
                if (refetchMoveInPayment) {
                  await refetchMoveInPayment()
                }
              }
            }}
          >
            Delete
          </Button>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

const MoveInFeeActions: React.FC<{
  id: string
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  feeAmount: number | ''
  setFeeAmount: React.Dispatch<React.SetStateAction<number | ''>>
  originalFeeAmount: number
}> = ({ id, isEditing, setIsEditing, feeAmount, setFeeAmount, originalFeeAmount }) => {
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)
  const [_, updateMoveInFeeAmount] = useUpdateMoveInFeeAmountMutation()
  const { refetchMoveInPayment } = useContext(RefetchMoveInPaymentContext)
  const toast = useToast()

  if (isEditing) {
    return (
      <HStack>
        <Button
          size='sm'
          colorScheme='teal'
          onClick={async () => {
            const res = await updateMoveInFeeAmount({ id, amount: feeAmount })
            if (res.error) {
              toast({
                title: 'Error',
                description: res.error.message,
                status: 'error',
                duration: 5000,
                position: 'bottom-right',
                isClosable: true
              })
            } else {
              setIsEditing(false)
              if (refetchMoveInPayment) {
                await refetchMoveInPayment()
              }
            }
          }}
        >
          Submit
        </Button>
        <Button
          size='sm'
          onClick={() => {
            setFeeAmount(originalFeeAmount)
            setIsEditing(false)
          }}
        >
          Cancel
        </Button>
        <Button size='sm' colorScheme='red' onClick={() => setIsConfirmDeleteModalOpen(true)}>
          Delete
        </Button>
        <ConfirmDeleteMoveInFeeModal
          {...{
            id,
            setIsEditing,
            isConfirmDeleteModalOpen,
            setIsConfirmDeleteModalOpen
          }}
        />
      </HStack>
    )
  }

  return (
    <Button size='sm' colorScheme='teal' onClick={() => setIsEditing(true)}>
      Edit
    </Button>
  )
}

const MoveInFeeRow: React.FC<MoveInFee> = ({ id, amount, name }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [feeAmount, setFeeAmount] = useState<number | ''>(amount)

  return (
    <Tr>
      <Td>{name}</Td>
      <Td>
        {isEditing ? (
          <Input
            type='number'
            min={0}
            value={feeAmount ?? ''}
            onChange={(e) => setFeeAmount(e.target.value === '' ? '' : Number(e.target.value))}
          />
        ) : (
          formatMoney(feeAmount || null, 2, '$')
        )}
      </Td>
      <Td>
        <MoveInFeeActions
          {...{
            id,
            isEditing,
            setIsEditing,
            setFeeAmount,
            feeAmount,
            originalFeeAmount: amount
          }}
        />
      </Td>
    </Tr>
  )
}

const MoveInFeesPanel: React.FC<{ rental: MoveInFeesFragment }> = ({ rental }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  // Don't show this panel if rental is no longer pending
  if (rental.status !== rentals.RENTAL_STATUS.PENDING) {
    return null
  }

  const moveInFees = rental.rental_move_in_fees.map((mif) => ({
    id: mif.id,
    name: mif.liability_type.name,
    amount: mif.amount
  }))

  return (
    <RefetchMoveInPaymentContextProvider rentalId={rental.id}>
      <ContentSectionCard
        title='Move-In Fees'
        action={
          <Button
            size='sm'
            colorScheme='teal'
            onClick={(e) => {
              e.stopPropagation()
              setIsModalOpen(true)
            }}
          >
            Add Move-In Fee
          </Button>
        }
      >
        {moveInFees.length > 0 && (
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Move-In Fee</Th>
                  <Th>Amount</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {moveInFees.map((mif) => (
                  <MoveInFeeRow {...mif} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <AddMoveInFeeModal
          {...{
            rentalId: rental.id,
            isModalOpen,
            setIsModalOpen
          }}
        />
      </ContentSectionCard>
    </RefetchMoveInPaymentContextProvider>
  )
}

export default MoveInFeesPanel
