import React, { FunctionComponent, useEffect, useState } from 'react'
import { types } from '@homevest/timelines'
import { ActionButton } from 'components/TailwindUIToolkit'
import {
  FilterModal,
  FilterCheckButton,
  FilterName,
  FilterInput,
  FilterApplyButton,
  AdjustmentsIcon
} from 'components/TailwindUIToolkit/StyledComponents'

type FilterPanelProps = {
  eventTypesToDisplay: types.EventTypeString[]
  activeEventTypes: types.EventTypeString[]
  setActiveEventTypes: Function
}

const FilterPanel: FunctionComponent<
  React.PropsWithChildren<
    FilterPanelProps & { isOpen: boolean; setIsOpen: (open: boolean) => void }
  >
> = ({ eventTypesToDisplay, setActiveEventTypes, activeEventTypes, isOpen, setIsOpen }) => {
  const [checkedEventTypes, setCheckedEventTypes] = useState(activeEventTypes)

  const toggleEventType = (eventType: types.EventTypeString) => {
    if (checkedEventTypes.includes(eventType)) {
      return checkedEventTypes.filter((et) => et !== eventType)
    }

    return [...checkedEventTypes, eventType]
  }

  useEffect(() => {
    setCheckedEventTypes(activeEventTypes)
  }, [isOpen])

  return (
    <FilterModal isOpen={isOpen}>
      {eventTypesToDisplay.map((name) => (
        <FilterCheckButton key={name} onClick={() => setCheckedEventTypes(toggleEventType(name))}>
          <FilterName>{name}</FilterName>
          <FilterInput type='checkbox' checked={checkedEventTypes.includes(name)} readOnly />
        </FilterCheckButton>
      ))}
      <FilterApplyButton
        type='button'
        onClick={() => {
          setIsOpen(!isOpen)
          setActiveEventTypes(checkedEventTypes)
        }}
      >
        <FilterName>Apply</FilterName>
      </FilterApplyButton>
    </FilterModal>
  )
}

const FilterButton: FunctionComponent<React.PropsWithChildren<FilterPanelProps>> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <ActionButton show type='button' onClick={() => setIsOpen(!isOpen)}>
        <AdjustmentsIcon />
        Filter
      </ActionButton>
      <FilterPanel {...{ ...props, isOpen, setIsOpen }} />
    </div>
  )
}

export default FilterButton
