const COLORS = {
  PRIMARY: {
    PLUM: '#302654',
    JELLO: '#00CFCC',
    COCONUT: '#FFFFFF'
  },
  NEUTRALS: {
    OYSTER: '#85807A',
    BASMATI: '#D9D1C2',
    JASMINE: '#E5DED6'
  },
  LIGHT_NEUTRALS: {
    CORNFLOWER: '#F5F8FB',
    TUNA: '#BDBBB8',
    PARSNIP: '#DBD9D8',
    SQUASH: '#E8E3DB',
    GARLIC: '#F9F8F7',
    MERCURY: '#E6E4E3'
  },
  ACCENTS: {
    GREEN: '#6EBA83',
    PEPPERCORN: '#000000',
    SARDINE: '#F3F3F3',
    SALMON: '#FF6657'
  }
}

export default COLORS
