import moment from 'moment-timezone'
import { tasks } from '@homevest/utils'

import axios from './axios'
import { apiUrl } from './config'
import { ALL_MARKETS, MODAL_ERROR, MODAL_LOADING } from 'constants/dispatch'
import {
  tourToBookReceived,
  setModalState,
  tourSkipped
} from 'state/dispatch-heuristic-scheduler/actions'

const {
  TASK_TYPES: {
    DISPATCH: { COLLECT_CUSTOMER_AVAILABILITY, FIND_AGENT_AVAILABILITY }
  }
} = tasks

export const callDispatchSchedulerHeuristic = async (schedulerState, leadGroupProperties) => {
  const { selectedMarket, leadGroupPropertiesToSkip, showingtimeAvailabilityRestrictions } =
    schedulerState

  const res = await axios.post(`${apiUrl}/admin/dispatch_heuristic`, {
    market_id: selectedMarket === ALL_MARKETS ? undefined : selectedMarket,
    lead_group_properties_to_skip: Array.from(leadGroupPropertiesToSkip),
    showingtime_availability_restrictions: showingtimeAvailabilityRestrictions,
    lead_group_properties: leadGroupProperties || undefined
  })

  return res.data
}

export const getNextTour = async (dispatch, schedulerState, leadGroupProperties) => {
  dispatch(setModalState(MODAL_LOADING))
  try {
    const tourToBook = await callDispatchSchedulerHeuristic(schedulerState, leadGroupProperties)
    dispatch(tourToBookReceived(tourToBook))
  } catch (err) {
    dispatch(setModalState(MODAL_ERROR))
  }
}

export const skipTour = async (dispatch, schedulerState) => {
  dispatch(tourSkipped())

  await getNextTour(dispatch, schedulerState)
}

export const bookTour = async (tourToBook) => {
  const {
    agent,
    lead_group: leadGroup,
    suggested_tour_route: suggestedTourRoute,
    start_time: startAt,
    tour_type: tourType
  } = tourToBook

  const propertyIds = suggestedTourRoute.map(({ property_id: propertyId }) => propertyId)

  await axios.post(`${apiUrl}/tours`, {
    agent_id: agent.id,
    lead_group_id: leadGroup.lead_group_id,
    property_ids: propertyIds,
    type: tourType,
    start_at: +new Date(startAt) // to timestamp milliseconds
  })
}

export const triggerTask = async (
  isforCustomer,
  tourToBook,
  additionalNotes,
  showingtimeAvailabilityRestrictions
) => {
  const {
    lead_group: { user_id: userId, lead_group_id: leadGroupId },
    lead_group_properties_on_tour: leadGroupPropertyIds,
    suggested_tour_route: suggestedTourRoute,
    eligible_lead_group_availabilities: eligibleLeadGroupAvailabilities
  } = tourToBook

  // construct the meatdata object

  const metadata = {
    additional_notes: additionalNotes,
    lead_group_property_ids: leadGroupPropertyIds
  }

  if (isforCustomer) {
    const cleanedShowingtimeRestrictions = suggestedTourRoute.reduce(
      (acc, { property_id: propertyId }) => {
        acc[propertyId] = showingtimeAvailabilityRestrictions[propertyId]
        return acc
      },
      {}
    )
    metadata.showingtime_availability_restrictions = cleanedShowingtimeRestrictions
  } else {
    metadata.eligible_lead_group_availabilities = eligibleLeadGroupAvailabilities
  }

  const res = await axios.post(`${apiUrl}/admin/tasks`, {
    task_type: isforCustomer ? COLLECT_CUSTOMER_AVAILABILITY : FIND_AGENT_AVAILABILITY,
    subject_id: leadGroupId,
    resource_id: userId,
    is_scheduled_with_user: false,
    metadata
  })

  return res.data
}

export const getDatesFromAvailabilities = (availabilityList) => {
  const dateSet = new Set()
  availabilityList.forEach(({ start_at: startAt, end_at: endAt }) => {
    dateSet.add(moment(startAt).tz('America/New_York').format('M/D/YY'))
    dateSet.add(moment(endAt).tz('America/New_York').format('M/D/YY'))
  })

  // sort ascending
  return Array.from(dateSet).sort((d1, d2) => new Date(d1) - new Date(d2))
}

export const isUnavailabilityValid = (currentStart, currentEnd) => {
  return moment(currentStart, 'kk:mm').isBefore(moment(currentEnd, 'kk:mm'))
}

export const mergeUnavailabilities = (previousUnavailabilities, currentUnavailabilities) => {
  const mergedUnavailabilities = { ...previousUnavailabilities }
  for (const [propertyId, unavailabilities] of Object.entries(currentUnavailabilities)) {
    const cleanedUnavailabilities = []
    for (const { currentDate, currentStart, currentEnd, isValid } of unavailabilities) {
      if (!isValid) {
        return {
          hasInvalidUnavailability: true
        }
      }

      if (!(currentStart && currentEnd)) {
        continue
      }

      cleanedUnavailabilities.push({
        start_at: formatDateAndTime(currentDate, currentStart),
        end_at: formatDateAndTime(currentDate, currentEnd)
      })
    }

    if (!cleanedUnavailabilities.length) {
      continue
    }

    if (!mergedUnavailabilities[propertyId]) {
      mergedUnavailabilities[propertyId] = []
    }

    mergedUnavailabilities[propertyId].push(...cleanedUnavailabilities)
  }

  return {
    mergedUnavailabilities,
    hasInvalidUnavailability: false
  }
}

const formatDateAndTime = (date, time) => {
  return moment(`${date} ${time}`, 'M/D/YY kk:mm').tz('America/New_York', true).utc().format()
}
