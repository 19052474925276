import React, { FunctionComponent } from 'react'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { Grid } from '../Grids/ConstructionGrid'
import { Spinner } from 'reactstrap'

type WorkOrderHistoryPropTypes = {
  portfolioHomeId: string
}

const WorkOrderHistory: FunctionComponent<React.PropsWithChildren<WorkOrderHistoryPropTypes>> = ({
  portfolioHomeId
}) => (
  <ContentSectionCard title='Construction History' staticSize size='short'>
    <React.Suspense fallback={<Spinner />}>
      <Grid portfolioHomeId={portfolioHomeId} />
    </React.Suspense>
  </ContentSectionCard>
)

export default WorkOrderHistory
