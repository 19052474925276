import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ReactJson from 'react-json-view'
import moment from 'moment'

import axios from 'lib/axios'

import PropTypes from 'prop-types'

const StyledContainer = styled.div`
  padding: 20px;
  height: calc(100vh - 248px);
  width: 100%;
`

function Close({ userId }) {
  const [activities, setActivities] = useState(null)

  useEffect(() => {
    async function load() {
      const response = await axios.get(`/admin/users/${userId}/close_activity`)
      setActivities(response.data)
    }
    return load()
  }, [userId])

  if (!activities) {
    return <h2>Loading</h2>
  }

  return (
    <StyledContainer>
      {activities.map((activity, idx) => (
        <div key={idx}>
          <h4>
            {activity.object_type} {activity.action}{' '}
            {moment(activity.date_created).format('MM/DD/yyyy @ hh:mm A')}
          </h4>
          <ReactJson collapsed={true} src={activity} />
        </div>
      ))}
    </StyledContainer>
  )
}

Close.propTypes = {
  userId: PropTypes.string.isRequired
}

export default Close
