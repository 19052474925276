import React from 'react'
import { VStack, Spinner, Center } from '@chakra-ui/react'

import { useUtilityDetailsByPortfolioHomeIdQuery } from 'graphql/generated'
import EditUtilityDetails from './EditUtilityDetails'
import UtilityDetailsView from './UtilityDetailsView'
import { ActionButton } from 'components/TailwindUIToolkit'

type UtilitiesCardInnerPropTypes = {
  portfolioHomeId: string
  isEditing: boolean
  canEdit: boolean
  setIsEditing: (isEditing: boolean) => void
}

const UtilitiesCardInner: React.FC<React.PropsWithChildren<UtilitiesCardInnerPropTypes>> = ({
  portfolioHomeId,
  isEditing,
  setIsEditing,
  canEdit
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [{ data, fetching, error }, reexecuteQuery] = useUtilityDetailsByPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  const utilityDetails = data?.portfolio_homes_by_pk
  const areUtilitiesConfigured = !!utilityDetails?.portfolio_home_utilities?.length

  if (error) {
    return <div>Error: {error.message}</div>
  }

  if (isLoading || fetching) {
    return (
      <Center>
        <Spinner margin={5} />
      </Center>
    )
  }

  const exitEditMode = () => {
    setIsLoading(true)
    setIsEditing(false)
    reexecuteQuery({ requestPolicy: 'network-only' })
    setIsLoading(false)
  }

  if (isEditing) {
    return (
      <EditUtilityDetails
        utilityDetails={utilityDetails}
        exitEditMode={exitEditMode}
        portfolioHomeId={portfolioHomeId}
      />
    )
  }

  if (!areUtilitiesConfigured) {
    return (
      <VStack justifyItems={'center'} padding={2}>
        <div className='mb-3 text-center'>
          This home has not had its utility information configured yet!
        </div>
        {canEdit && (
          <ActionButton colorScheme='secondary' onClick={() => setIsEditing(true)}>
            Configure Utilities
          </ActionButton>
        )}
      </VStack>
    )
  }

  return <UtilityDetailsView utilityDetails={utilityDetails} />
}

export default UtilitiesCardInner
