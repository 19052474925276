import React, { FunctionComponent } from 'react'
import _ from 'lodash'
import { format } from 'date-fns'
import { types } from '@homevest/timelines'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'

type PropertiesPropTypes = { homes?: types.HomeDetails[] }

const PropertiesWrapper: FunctionComponent<React.PropsWithChildren<PropertiesPropTypes>> = (
  props
) => (
  <ContentSectionCard title={'Properties'} padding={!props.homes?.length} size={'large'}>
    <Properties {...props} />
  </ContentSectionCard>
)

const Properties: FunctionComponent<React.PropsWithChildren<PropertiesPropTypes>> = ({ homes }) => {
  if (!homes?.length) {
    return <SC.MessageText>No properties found for user</SC.MessageText>
  }

  if (!homes) {
    return <SC.MessageText>Loading properties for this user...</SC.MessageText>
  }

  return (
    <div>
      {homes?.map((h) => {
        const details = [
          {
            icon: <SC.StyledLocationMarker />,
            text: h.market
          }
        ]

        // decide which details to show here

        // if rent ready date is present, add it to list of displayed details
        const rentReadyOn = h.rentReadyOn

        if (rentReadyOn) {
          const rentReadyDate = new Date(rentReadyOn)
          const rentReadyIcon = <SC.StyledPropertyHome />

          if (rentReadyDate >= new Date()) {
            details.push({
              icon: rentReadyIcon,
              text: `Rent ready on ${format(rentReadyDate, 'd MMM, yyyy')}`
            })
          } else {
            details.push({ icon: rentReadyIcon, text: 'Rent ready now' })
          }
        }

        // TODO: add detail to show most recent interaction with the property

        /* TODO: add badges to display (inquired, toured, application, status) */
        // const badges = []

        return (
          <SC.PropertyBox key={h.mlsListingId}>
            <SC.SubsectionTitleRouterLink to={`/home/${h.homeId}`}>
              {`${startCase(h.address)}, ${startCase(h.city)}, ${h.state} ${h.zip}`}
            </SC.SubsectionTitleRouterLink>

            {/* TODO: add map on badges once badges are implemented */}
            {/* {badges.map((badge, i) => badge({key: i}))} */}

            {details.map((detail, i) =>
              detail.text ? (
                <SC.PropertyDetail key={i}>
                  {detail.icon}
                  {detail.text}
                </SC.PropertyDetail>
              ) : null
            )}
          </SC.PropertyBox>
        )
      })}
    </div>
  )
}

const startCase = (s: string | null) => {
  return _.startCase(_.toLower(s || ''))
}

export default PropertiesWrapper
