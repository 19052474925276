import axios from 'lib/axios'
import {
  refreshTableData,
  closeModal,
  startModalAction,
  endModalAction
} from 'state/dispatch/actions'
import { apiError } from 'lib/error-handling'

export const SET_AGENTS = 'AGENTS/SET_AGENTS'

export const setAgents = (agents) => ({
  type: SET_AGENTS,
  payload: agents
})

export const fetchAgents = () => {
  return async (dispatch) => {
    try {
      const resp = await axios.get('/agents')
      dispatch(setAgents(resp.data))
    } catch (e) {
      apiError(e)
    }
  }
}

export const createAgent = (firstName, lastName, email, phone) => {
  return async (dispatch) => {
    dispatch(startModalAction())

    try {
      await axios.post('/agents', {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone
      })

      dispatch(refreshTableData())
      dispatch(closeModal())
    } catch (e) {
      apiError(e)
    }

    dispatch(endModalAction())
  }
}
