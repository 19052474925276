"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LLC_NAMES = void 0;
exports.LLC_NAMES = {
    HOMEVEST: 'Homevest Inc.',
    CA_I: 'HV INV CA I LLC',
    MO_I: 'HV INV MO I LLC',
    MO_II: 'HV INV MO II LLC',
    MO_III: 'HV INV MO III LLC',
    F2_GA: 'Up&Up Fund II GA LLC',
    F2_MO: 'Up&Up Fund II MO LLC',
    F2_IN: 'Up&Up Fund II IN LLC',
    F2_POOL2: 'Up&Up Fund II Pool 2, LLC',
    GA_I: 'HV INV GA I LLC',
    IN_II: 'HV INV IN II LLC',
    MO_IV: 'HV INV MO IV LLC',
    NC_LLC: 'HV Bobcat NC I, LLC',
    HREP_III_GA: 'HREP III GA, LLC',
    HREP_III_NC: 'HREP III NC, LLC',
    HREP_III_AL: 'HREP III AL, LLC',
    HREP_IV_SANDALWOOD: 'HREP IV Sandalwood, LLC'
};
