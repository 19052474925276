import React from 'react'
import { ColDef, ICellRendererParams } from 'ag-grid-community'

import { RentalToRenewDataRow } from '../types'
import { formatMoney } from 'lib/numbers'
import { format } from 'date-fns'

const ADDRESS: ColDef<RentalToRenewDataRow> = {
  headerName: 'Address',
  field: 'address',
  flex: 1
}

const TENANT: ColDef<RentalToRenewDataRow> = {
  headerName: 'Tenant',
  field: 'primary_tenant_name',
  flex: 1
}

const MARKET: ColDef<RentalToRenewDataRow> = {
  headerName: 'Market',
  field: 'market_name',
  flex: 1
}

const FUND: ColDef<RentalToRenewDataRow> = {
  headerName: 'Fund',
  field: 'fund',
  flex: 1
}

const CURRENT_RENT: ColDef<RentalToRenewDataRow> = {
  headerName: 'Current Rent',
  field: 'current_rent',
  flex: 1,
  valueFormatter: (params) =>
    params.value ? formatMoney(Number(params.value), 0, '$') : 'missing data'
}

const LEASE_END_DATE: ColDef<RentalToRenewDataRow> = {
  headerName: 'Lease End Date',
  field: 'lease_ends_at',
  flex: 1,
  valueFormatter: (params) => {
    try {
      return format(new Date(params.value), 'MMM d, Y')
    } catch {
      return params.value
    }
  }
}

const MOST_RECENT_NOTE: ColDef<RentalToRenewDataRow> = {
  headerName: 'Most Recent Note',
  field: 'most_recent_note',
  flex: 2,
  cellRenderer: (params: ICellRendererParams<RentalToRenewDataRow, string | undefined>) => {
    const { data, value } = params
    if (value && data?.most_recent_note_created_at) {
      const createdDate = format(new Date(data.most_recent_note_created_at), 'yyyy-MM-dd')
      return (
        <div>
          {'['}
          <span className='font-semibold'>{createdDate}</span>
          {'] '}
          {value}
        </div>
      )
    }
    return ''
  },
  autoHeight: true
}

export const UPCOMING_COLUMNS = [
  ADDRESS,
  TENANT,
  MARKET,
  FUND,
  MOST_RECENT_NOTE,
  CURRENT_RENT,
  LEASE_END_DATE
]
