import React from 'react'

import { PendingAgreementsFragment } from 'graphql/generated'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import PendingAgreementsTable from './PendingAgreementsTable'

export default function PendingAgreements({ rental }: { rental: PendingAgreementsFragment }) {
  const agreements = rental?.pending_agreements

  const hasPendingAgreements = (agreements ?? []).length > 0

  if (!hasPendingAgreements) {
    return null
  }

  return (
    <ContentSectionCard padding title={'Other Pending Agreements'}>
      <div>
        <PendingAgreementsTable agreements={rental?.pending_agreements} />
      </div>
    </ContentSectionCard>
  )
}
