import { Spinner } from '@chakra-ui/react'
import { CashoutList } from 'components/Cashouts/CashoutList'
import { useCashoutsQuery } from 'graphql/generated'
import React, { FC, useMemo } from 'react'

export const Cashouts: FC<React.PropsWithChildren<unknown>> = () => {
  // fetch cashouts with graphql query
  // render cashouts in a table
  const context = useMemo(() => ({ suspense: false }), [])
  const [{ data }] = useCashoutsQuery({
    context
  })

  console.log('cashouts')
  if (!data) return <Spinner />
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '74vh' }}>
      <CashoutList cashouts={data.cashouts} />
    </div>
  )
}
