import React from 'react'
import { userIncomeSources, documents } from '@homevest/utils'

import { useSourceDetails } from '../context'
import { CurrencyFormField, SelectFormField, DocumentFormField } from '../common/form'

const { DOCUMENT_TYPES } = documents
const {
  USER_INCOME_SOURCE_TYPES: SOURCE_TYPES,
  USER_INCOME_SOURCE_PAY_FREQUENCIES: PAY_FREQUENCIES,
  USER_INCOME_SOURCE_WAGE_TYPES: WAGE_TYPES
} = userIncomeSources

const NewlyEmployedPayDetails = () => {
  const context = useSourceDetails()

  if (!context || context.data.type !== SOURCE_TYPES.EMPLOYED || !context.data.is_newly_employed) {
    return null
  }

  const { data } = context

  return (
    <div className='flex flex-col gap-5'>
      <SelectFormField
        label='Pay Frequency'
        fieldName='pay_frequency'
        optionValues={Object.values(PAY_FREQUENCIES)}
      />
      <div className='flex flex-col gap-5 md:flex-row'>
        <SelectFormField
          label='Wage Type'
          fieldName='wage_type'
          optionValues={Object.values(WAGE_TYPES)}
        />
        <CurrencyFormField
          label={data.wage_type === WAGE_TYPES.HOURLY ? 'Hourly Rate' : 'Annual Salary'}
          fieldName='offered_rate'
        />
      </div>
      <DocumentFormField
        label='Offer Letter'
        fieldName='offer_letter'
        documentType={DOCUMENT_TYPES.OFFER_LETTER}
        uploadPrefix={DOCUMENT_TYPES.OFFER_LETTER}
      />
    </div>
  )
}

export default NewlyEmployedPayDetails
