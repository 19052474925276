import React, { FunctionComponent } from 'react'
import { ContentSectionCard, LinkButton } from 'components/TailwindUIToolkit'
import { Grid } from '../Grids/WalkthroughsGrid'
import { Spinner } from 'reactstrap'
import { RETOOL_APP_URL, RETOOL_VACANT_SERVICING_APP_ID } from 'constants/urls'

type WalkthroughPropTypes = {
  portfolioHomeId: string
}

const VacantServicingToolButton = (
  <LinkButton href={`${RETOOL_APP_URL}${RETOOL_VACANT_SERVICING_APP_ID}`}>
    Vacant Servicing Tool
  </LinkButton>
)

const Walkthroughs: FunctionComponent<React.PropsWithChildren<WalkthroughPropTypes>> = ({
  portfolioHomeId
}) => (
  <ContentSectionCard title='Walkthroughs' staticSize={true} action={VacantServicingToolButton}>
    <React.Suspense fallback={<Spinner />}>
      <Grid portfolioHomeId={portfolioHomeId} />
    </React.Suspense>
  </ContentSectionCard>
)

export default Walkthroughs
