import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import { capabilities } from '@homevest/utils'
import { useSelector } from 'react-redux'

import { BodyText, Button, HeaderText, ErrorText, SuccessText } from 'components/Toolkit'

import { hasCapability } from 'lib/admin-perms'
import axios from 'lib/axios'

const { DAGGER } = capabilities.CAPABILITY_TYPES

const StyledContent = styled.div`
  width: 500px;
  margin: 0 auto;
`

export default function HpaEngineLauncher() {
  const isDagger = useSelector((store) => hasCapability(store.admin, DAGGER))

  if (!isDagger) {
    return <ErrorText>You do not have the capability of DAGGER.</ErrorText>
  }

  return (
    <StyledContent>
      <HeaderText size='h3' style={{ textAlign: 'center' }}>
        HPA Engine
      </HeaderText>
      <React.Suspense fallback={<Spinner color='primary' />}>
        <HpaEngineLauncherViewer />
      </React.Suspense>
    </StyledContent>
  )
}

function HpaEngineLauncherViewer() {
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)

  const handleCalculate = async () => {
    try {
      setIsLoading(true)

      await axios.post('/admin/system/topic_publish', {
        topic: 'hpa-download-fhfa-data',
        // A message is required for Pub/Sub
        message: 'EOM'
      })

      setSuccessMessage(
        'HPA engine initiated. Please review the data when complete in <a target="_blank" href="https://console.cloud.google.com/bigquery?authuser=0&project=homevest-production&ws=!1m5!1m4!4m3!1shomevest-data!2sproperty_financials!3shpa_estimates">Big Query</a>.'
      )
    } catch (err) {
      setErrorMessage(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Spinner color='primary' style={{ textAlign: 'center' }} />
  }

  if (errorMessage) {
    return <ErrorText>{errorMessage}</ErrorText>
  }

  if (successMessage) {
    return (
      <div>
        <SuccessText>
          <div dangerouslySetInnerHTML={{ __html: successMessage }} />
        </SuccessText>
      </div>
    )
  }

  return (
    <div>
      <BodyText style={{ marginBottom: '20px' }}>
        This tool launches the HPA engine which will process the latest prices from the FHFA.
      </BodyText>
      <Button onClick={handleCalculate}>Calculate!</Button>
    </div>
  )
}
