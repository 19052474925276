import React from 'react'
import { get } from 'lodash'
import { Divider, Text } from '@chakra-ui/react'
import { BodyText, Label } from 'components/Toolkit'
import ReportLink from '../ReportLink'
import CriminalReportOffense from './CriminalReportOffense'

export default function CriminalReportDetails({ criminalReport }) {
  const criminalReportDocumentId = get(criminalReport, 'document.id', null)

  if (!criminalReport) {
    return (
      <Text marginTop='0.5' fontWeight={'light'}>
        No report run
      </Text>
    )
  }

  if (criminalReportDocumentId) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <ReportLink
          documentId={criminalReportDocumentId}
          reportName='Criminal Report'
          style={{ flex: 1 }}
        />
      </div>
    )
  }

  const matches = get(criminalReport, 'matches', null)

  if (!matches || !matches.length) {
    return (
      <Text marginTop='0.5' fontWeight={'light'}>
        No matches found ✅
      </Text>
    )
  }

  return matches.map((match, idx) => {
    const {
      offenses: { count: offenseCount, offense: offenses },
      subject: { category: subjectCategory, comments: subjectComments }
    } = match

    return (
      <div key={idx} className='mt-1 mb-2 flex flex-col justify-between'>
        <Text marginBottom={'1'} fontWeight={'semibold'}>
          Match
        </Text>
        <div className='mb-4 flex justify-between'>
          <div style={{ flex: 1 }}>
            <Text fontSize='xs'>Subject Category</Text>
            <Text marginTop={0}>{subjectCategory}</Text>
          </div>
          <div style={{ flex: 1 }}>
            <Text fontSize='xs'>Offense Count</Text>
            <Text marginTop={0}>{offenseCount}</Text>
          </div>
          <div>
            <Text fontSize='xs'>Comments</Text>
            <Text marginTop={0}>{subjectComments}</Text>
          </div>
        </div>
        <Text marginBottom={'1'} fontWeight={'semibold'}>
          Offenses
        </Text>
        {offenses.map((offense, idx2) => (
          <CriminalReportOffense
            key={idx2}
            offense={offense}
            hasBottomBorder={idx2 !== offenses.length - 1}
          />
        ))}
        {idx !== matches.length - 1 && <Divider marginY='3' />}
      </div>
    )
  })
}
