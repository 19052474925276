import React, { useState } from 'react'
import { Button, HStack, Box } from '@chakra-ui/react'

import UtilityTypesDropdown from './UtilityTypesDropdown'
import UtilityResponsibilitySelect from './UtilityResponsibilitySelect'

type AddUtilityTypeFormPropTypes = {
  addUtilityType: ({
    utilityTypeId,
    defaultResponsibleParty
  }: {
    utilityTypeId: string
    defaultResponsibleParty: string
  }) => void
  assignedUtilityTypes: Set<string>
}

const AddUtilityTypeForm: React.FC<React.PropsWithChildren<AddUtilityTypeFormPropTypes>> = ({
  addUtilityType,
  assignedUtilityTypes
}) => {
  const [utilityTypeId, setUtilityTypeId] = useState('')
  const [defaultResponsibleParty, setDefaultResponsibleParty] = useState('')

  const canSubmit = utilityTypeId && defaultResponsibleParty

  const handleSubmit = () => {
    if (canSubmit) {
      addUtilityType({ utilityTypeId, defaultResponsibleParty })
      setUtilityTypeId('')
      setDefaultResponsibleParty('')
    }
  }

  return (
    <HStack spacing={5}>
      <UtilityTypesDropdown
        id='add-utility-type-select'
        value={utilityTypeId}
        setValue={setUtilityTypeId}
        assignedUtilityTypes={assignedUtilityTypes}
      />
      <UtilityResponsibilitySelect
        id='add-utility-responsibility-select'
        value={defaultResponsibleParty}
        setValue={setDefaultResponsibleParty}
        showLabel
      />
      <Box width='30%' alignSelf={'flex-end'}>
        <Button
          disabled={!canSubmit}
          colorScheme='green'
          onClick={handleSubmit}
          justifySelf='flex-end'
        >
          Add +
        </Button>
      </Box>
    </HStack>
  )
}

export default AddUtilityTypeForm
