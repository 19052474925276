import React, { useState } from 'react'
import {
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Icon,
  Center,
  Checkbox,
  Box
} from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/20/solid'

import { formatMoney } from 'lib/numbers'
import { RenewalOfferItem } from 'lib/renewals'

interface AddRenewalOfferFormProps {
  addRenewalOffer: (renewalOffer: RenewalOfferItem) => void
}

const AddRenewalOfferForm: React.FC<React.PropsWithChildren<AddRenewalOfferFormProps>> = ({
  addRenewalOffer
}) => {
  const [term, setTerm] = useState<number>(0)
  const [price, setPrice] = useState<number>(0)
  const [isMonthToMonth, setIsMonthToMonth] = useState<boolean>(false)

  const handleMonthToMonthClick = () => {
    const newIsMonthToMonth = !isMonthToMonth
    if (newIsMonthToMonth) {
      setTerm(1)
    }

    setIsMonthToMonth(newIsMonthToMonth)
  }

  return (
    <FormControl>
      <HStack spacing={5}>
        <Box width='30%'>
          <Stack spacing={1}>
            <FormLabel htmlFor='term'>Term</FormLabel>

            <NumberInput
              value={term}
              min={1}
              max={24}
              isDisabled={isMonthToMonth}
              onChange={(_valueAsString: string, valueAsNumber: number) => setTerm(valueAsNumber)}
            >
              <NumberInputField id='term' />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormHelperText>Renewal term in months</FormHelperText>
          </Stack>
        </Box>
        <Box width='25%'>
          <Stack spacing={1}>
            <FormLabel htmlFor='month-to-month-checkbox' mb={4}>
              Month to Month?
            </FormLabel>
            <Center>
              <Checkbox
                id='month-to-month-checkbox'
                colorScheme='teal'
                size='lg'
                isChecked={isMonthToMonth}
                onChange={handleMonthToMonthClick}
              />
            </Center>
          </Stack>
        </Box>
        <Box width='30%'>
          <Stack spacing={1}>
            <FormLabel htmlFor='price'>Price</FormLabel>
            <NumberInput
              value={price}
              format={(value: string | number) => formatMoney(Number(value), 0, '$')}
              onChange={(_valueAsString: string, valueAsNumber: number) => setPrice(valueAsNumber)}
              min={0}
              max={10000}
            >
              <NumberInputField id='price' />
            </NumberInput>
            <FormHelperText>Monthly renewal rent</FormHelperText>
          </Stack>
        </Box>
        <Box width='15%'>
          <Center>
            <Button
              colorScheme='teal'
              rightIcon={<Icon as={PlusIcon} />}
              onClick={() => addRenewalOffer({ term, price, isMonthToMonth })}
            >
              Add
            </Button>
          </Center>
        </Box>
      </HStack>
    </FormControl>
  )
}

export default AddRenewalOfferForm
