import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useDisclosure, Button } from '@chakra-ui/react'
import { FetchBuyersInspectionsForConstructionProjectResponse } from '@homevest/types/construction-projects'

import axios from 'lib/axios'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import BuyersInspectionGrid from './components/BuyersInspections/BuyersInspectionGrid'
import BuyersInspectionSidepeek from './components/BuyersInspections/CreateBuyersInspectionSidepeek'

const BuyersInspections = ({
  constructionProjectId,
  portfolioHomeId
}: {
  constructionProjectId: string
  portfolioHomeId: string
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    data,
    isFetching,
    refetch: refetchBuyersInspections
  } = useQuery({
    queryKey: ['getBuyersInspections', constructionProjectId],
    queryFn: async () => {
      const response = await axios.get<FetchBuyersInspectionsForConstructionProjectResponse>(
        `admin/construction_projects/${constructionProjectId}/buyers_inspections`
      )
      return response.data?.data
    }
  })

  return (
    <>
      <BuyersInspectionSidepeek
        portfolioHomeId={portfolioHomeId}
        isOpen={isOpen}
        onSubmit={refetchBuyersInspections}
        onClose={onClose}
      />
      <ContentSectionCard
        title="Buyer's Inspections"
        action={
          <Button variant='outline' colorScheme='teal' onClick={onOpen}>
            Add +
          </Button>
        }
      >
        <BuyersInspectionGrid
          portfolioHomeId={portfolioHomeId}
          data={data}
          isFetching={isFetching}
          refetch={refetchBuyersInspections}
        />
      </ContentSectionCard>
    </>
  )
}

export default BuyersInspections
