import React, { Fragment, FunctionComponent } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { UpupDocumentsByResourceQuery } from 'graphql/generated'

type Document = UpupDocumentsByResourceQuery['documents'][number]

type Props = {
  document?: Document
  isOpen: boolean
  onClose: () => void
}

const NoteModal: FunctionComponent<React.PropsWithChildren<Props>> = ({
  document,
  isOpen,
  onClose
}) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as='div' className='relative z-10' onClose={onClose}>
      <div className='fixed inset-0 bg-black bg-opacity-25' />

      <div className='fixed inset-0 overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4 text-center'>
          <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all '>
            <div className='flex flex-col gap-6'>
              <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                {document?.friendly_name} Note
              </Dialog.Title>
              <div className='flex flex-col gap-2'>{document?.resource_notes}</div>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  </Transition>
)

export default NoteModal
