import React, { useState } from 'react'
import { Center, Spinner } from '@chakra-ui/react'
import { VehicleDetails, VehicleWithId } from '@homevest/types/rental-applications'

import DetailsSection from '../Common/DetailsSection'
import EditVehicles from './EditMode'
import VehiclesDisplay from './Display'

type VehiclesSectionWrapperProps = {
  vehicleDetails: VehicleDetails
  rentalApplicationId: string
  isEditable: boolean
  isFetching: boolean
}

type VehiclesSectionProps = {
  vehicles: VehicleWithId[]
  rentalApplicationId: string
  isFetching: boolean
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
}

const VehiclesSectionWrapper: React.FC<React.PropsWithChildren<VehiclesSectionWrapperProps>> = ({
  vehicleDetails,
  rentalApplicationId,
  isEditable,
  isFetching
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const { data: vehicles, submitted_at: submittedAt } = vehicleDetails

  return (
    <DetailsSection
      title='Vehicles'
      submittedAt={submittedAt}
      isEditable={isEditable}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
    >
      <VehiclesSection
        rentalApplicationId={rentalApplicationId}
        vehicles={vehicles ?? []}
        isFetching={isFetching}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </DetailsSection>
  )
}

const VehiclesSection: React.FC<React.PropsWithChildren<VehiclesSectionProps>> = ({
  rentalApplicationId,
  vehicles,
  isFetching,
  isEditing,
  setIsEditing
}) => {
  if (isFetching) {
    return (
      <Center marginY={3}>
        <Spinner colorScheme='teal' />
      </Center>
    )
  }

  if (isEditing) {
    return (
      <EditVehicles
        currentVehicles={vehicles}
        rentalApplicationId={rentalApplicationId}
        exitEditMode={() => setIsEditing(false)}
      />
    )
  }

  return <VehiclesDisplay vehicles={vehicles} />
}

export default VehiclesSectionWrapper
