import React from 'react'
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  useDisclosure,
  Center,
  Spinner,
  Stack,
  Divider,
  Heading,
  HStack
} from '@chakra-ui/react'
import { UserIncomeDetails } from '@homevest/types/user-incomes'
import { useQuery } from '@tanstack/react-query'
import axios from 'lib/axios'
import { UserIncomeByUserIdQuery } from 'graphql/generated'
import AddSource from './SidePeekContent/AddSource/index'
import IncomeDisplay from './IncomeDisplay'
import IncomeActions from './IncomeActions'
import { computeUserIncomeStatus } from 'lib/user-incomes/status'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import SourceDetails from './SidePeekContent/DisplaySource'

type SidePeekProps = {
  userId: string
  userIncome: UserIncomeByUserIdQuery['user_incomes'][number]
  onClose: () => void
}

const SidePeek: React.FC<React.PropsWithChildren<SidePeekProps>> = ({ userId, onClose }) => {
  const [isAddingSource, setIsAddingSource] = React.useState(false)
  const { isOpen, onOpen, onClose: closeSidePeek } = useDisclosure()

  const handleClose = () => {
    onClose()
    closeSidePeek()
  }

  const {
    isLoading,
    isFetching,
    isError,
    data: userIncomeDetails,
    refetch
  } = useQuery({
    queryKey: ['userIncomeDetails', userId],
    queryFn: async ({ queryKey: [_, userId] }) => {
      const { data } = await axios.get<UserIncomeDetails>(`/admin/users/${userId}/user_income`)
      return data
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  const userIncomeStatus = computeUserIncomeStatus(userIncomeDetails)

  const userIncomeExists = !!userIncomeDetails?.id

  return (
    <>
      <Button width='full' size='sm' colorScheme='teal' onClick={onOpen}>
        Review
      </Button>
      <Drawer isOpen={isOpen} onClose={handleClose} placement='right' size='xl'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontSize='3xl' display='flex' gap={4}>
            <HStack justifyContent='space-between' pr='4' w='full'>
              <HStack>
                <div>Review Income</div>
                {userIncomeExists && <div>{mapContentToBadge(userIncomeStatus)}</div>}
              </HStack>
              {userIncomeDetails?.id && (
                <IncomeActions userIncome={userIncomeDetails} onAction={refetch} />
              )}
            </HStack>
          </DrawerHeader>
          {isLoading || isFetching || isError ? (
            <Center mt='2'>
              <Spinner />
            </Center>
          ) : (
            <>
              <DrawerBody>
                {userIncomeExists && (
                  <Stack divider={<Divider />}>
                    {!isAddingSource && <IncomeDisplay userIncome={userIncomeDetails} />}
                    <AddSource
                      onOpen={() => setIsAddingSource(true)}
                      onClose={() => setIsAddingSource(false)}
                      onSubmit={refetch}
                      userId={userId}
                    />
                    {!isAddingSource && (
                      <SourceDetails
                        userId={userId}
                        userIncomeDetails={userIncomeDetails}
                        onUpdate={async () => {
                          await refetch()
                        }}
                      />
                    )}
                  </Stack>
                )}
                {!userIncomeExists && (
                  <Heading size='md' textAlign='center'>
                    No income details found
                  </Heading>
                )}
              </DrawerBody>
            </>
          )}
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default SidePeek
