import React, { FunctionComponent } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { Box, Flex, Link } from '@chakra-ui/react'
import { BrandIcon, Wordmark } from 'ui'
import NavigationTabs from './NavigationTabs'
import ProfileMenu from './ProfileMenu'

const Navbar: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  return (
    <Flex
      overflowX='auto'
      direction='row'
      align='center'
      justify='space-between'
      h={16}
      gap={4}
      borderBottomWidth={1}
      borderBottomColor='gray.200'
    >
      <Flex direction='row' align='center' justify='start' h='full'>
        <Link
          as={ReactLink}
          to='/'
          display='flex'
          flexDirection='row'
          alignItems='center'
          px={8}
          py={4}
          gap={1}
        >
          <Box color='brand.600'>
            <BrandIcon />
          </Box>
          <Wordmark />
        </Link>
        <NavigationTabs />
      </Flex>
      <ProfileMenu />
    </Flex>
  )
}

export default Navbar
