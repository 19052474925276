import React from 'react'

import ChargeLiabilitiesModal from './Form'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'

export default function ChargeModal({
  close,
  isOpen,
  rentalId
}: {
  close: () => void
  isOpen: boolean
  rentalId: string
}) {
  return (
    <>
      <Modal size='6xl' isOpen={isOpen} onClose={close}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Charge Rental</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ChargeLiabilitiesModal onSubmit={close} rentalId={rentalId} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={close}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
