import React, { FunctionComponent } from 'react'
import { useListingPhotosByPortfolioHomeIdQuery } from 'graphql/generated'
import { ContentSectionCard, ImageGridList } from 'components/TailwindUIToolkit'
import { Spinner } from 'reactstrap'

type PropTypes = { portfolioHomeId: string }

const ListingPhotos: FunctionComponent<React.PropsWithChildren<PropTypes>> = ({
  portfolioHomeId
}) => {
  const [{ data, error }] = useListingPhotosByPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  if (error) {
    return <div>{error.message}</div>
  }

  const photos = data?.portfolio_homes_by_pk?.real_estate_acquisition?.opportunity?.photos

  if (!photos) {
    return <div>no photos found</div>
  }

  const files = photos.map((p) => p.url)

  return <ImageGridList files={files} />
}

const ListingPhotosWrapper: FunctionComponent<React.PropsWithChildren<PropTypes>> = (props) => (
  <ContentSectionCard title='Photos'>
    <React.Suspense fallback={<Spinner />}>
      <ListingPhotos {...props} />
    </React.Suspense>
  </ContentSectionCard>
)

export default ListingPhotosWrapper
