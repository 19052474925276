import React, { useState, useEffect } from 'react'
import { GridReadyEvent, GridApi } from 'ag-grid-community'

import { BaseGrid } from 'components/TailwindUIToolkit'
import { REUNDERWRITE_COLUMNS } from '../../Grid/reunderwrite-configuration'
import type { Reunderwrite } from '@homevest/types/rental-reunderwrites'

interface RentalsToRenewTableProps {
  reunderwrites: Reunderwrite[]
  setSelectedReunderwrite: (rental: any) => void
}

export const ReunderwritesTable = ({
  setSelectedReunderwrite,
  reunderwrites
}: RentalsToRenewTableProps) => {
  const [gridApi, setGridApi] = useState<GridApi<Reunderwrite>>()

  const onGridReady = (evt: GridReadyEvent<Reunderwrite>) => {
    setGridApi(evt.api)
  }

  const gridSetup = (evt: GridReadyEvent<Reunderwrite>) => {
    setGridApi(evt.api)
    onGridReady(evt)
  }

  useEffect(() => {
    if (gridApi && reunderwrites) {
      try {
        gridApi.setRowData(reunderwrites)
        gridApi.sizeColumnsToFit()
      } catch {
        console.log('Ran into an error drawing the grid, you may need to refresh the page')
      }
    }
  }, [gridApi, reunderwrites])

  return (
    <div className='ag-theme-material right h-[70vh] border'>
      <BaseGrid<Reunderwrite>
        optionOverrides={{
          defaultColDef: {
            width: undefined
          },
          rowHeight: 45
        }}
        columns={REUNDERWRITE_COLUMNS}
        rowData={reunderwrites}
        onGridReady={gridSetup}
        onRowClicked={(evt) => setSelectedReunderwrite(evt.data)}
        onGridSizeChanged={() => gridApi && gridApi.sizeColumnsToFit()}
      />
    </div>
  )
}

export default ReunderwritesTable
