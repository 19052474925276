import React from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import { IconButton, Menu, MenuList, MenuButton, MenuItem, useToast } from '@chakra-ui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { subHours } from 'date-fns'

import {
  useResetUserIdentityVerificationAttemptsMutation,
  useInvalidateSuccessfulUserIdentityVerificationsMutation
} from 'graphql/generated'

const ActionsMenu: React.FC<
  React.PropsWithChildren<{
    userId: string
    copyVerificationLink: () => void
    refetch: (options: any) => void
    setIsLoading: (isLoading: boolean) => void
  }>
> = ({ userId, refetch, copyVerificationLink, setIsLoading }) => {
  const toast = useToast()
  const admin = useSelector((store: StoreState) => store.admin)
  const [{ error: hasResetVerificationAttemptsError }, resetVerificationAttempts] =
    useResetUserIdentityVerificationAttemptsMutation()

  const [{ error: hasInvalidateSuccessfulVerificationsError }, invalidateSuccessfulVerifications] =
    useInvalidateSuccessfulUserIdentityVerificationsMutation()

  const handleResetVerificationAttempts = async () => {
    setIsLoading(true)
    const oneDayAgo = subHours(new Date(), 24)
    await resetVerificationAttempts({
      userId,
      invalidatedByAdminId: admin.id,
      oneDayAgo
    })

    refetch({ requestPolicy: 'network-only' })
    setIsLoading(false)

    toast({
      title: hasResetVerificationAttemptsError
        ? 'Error resetting verification attempts'
        : 'Verification attempts reset!',
      status: hasResetVerificationAttemptsError ? 'error' : 'success',
      duration: 5000,
      isClosable: true,
      position: 'bottom-right'
    })
  }

  const handleInvalidateSuccessfulVerifications = async () => {
    setIsLoading(true)
    await invalidateSuccessfulVerifications({
      userId,
      invalidatedByAdminId: admin.id
    })

    refetch({ requestPolicy: 'network-only' })
    setIsLoading(false)

    toast({
      title: hasInvalidateSuccessfulVerificationsError
        ? 'Error invalidating successful verifications!'
        : 'Successful verifications cleared!',
      status: hasInvalidateSuccessfulVerificationsError ? 'error' : 'success',
      duration: 5000,
      isClosable: true,
      position: 'bottom-right'
    })
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label='Options'
        size='sm'
        icon={<EllipsisHorizontalIcon className='h-4 w-4' />}
      />
      <MenuList>
        <MenuItem onClick={copyVerificationLink}>Copy Verification Link</MenuItem>
        <MenuItem onClick={handleResetVerificationAttempts}>Reset Verification Attempts</MenuItem>
        <MenuItem onClick={handleInvalidateSuccessfulVerifications}>
          Invalidate Successful Verifications
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default ActionsMenu
