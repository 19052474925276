import store from 'store'

import {
  editFirstName,
  editLastName,
  addPhone,
  editPhone,
  deletePhone,
  setPrimaryPhone,
  setPhoneValidity,
  addEmail,
  editEmail,
  deleteEmail,
  setPrimaryEmail,
  setEmailValidity
} from 'state/personal-details/actions'

import { formatPhoneNumber, isValidPhoneNumber } from 'lib/phone'
import { email } from 'lib/form-validation'

const dispatch = store.dispatch

const validatePhone = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
  const inputPhone = e.currentTarget.value
  const formattedNumber = formatPhoneNumber(inputPhone) || inputPhone
  const numberIsValid = isValidPhoneNumber(formattedNumber)
  mapCategoryToProps.phone.onEdit(formattedNumber, index)
  dispatch(setPhoneValidity(numberIsValid, index))
}

const validateEmail = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
  const inputEmail = e.currentTarget.value
  const emailAddressIsValid = !!inputEmail.length && !email(inputEmail)
  dispatch(setEmailValidity(emailAddressIsValid, index))
}

export const mapCategoryToProps = {
  firstName: {
    onEdit: (firstName: string) => dispatch(editFirstName(firstName))
  },
  lastName: {
    onEdit: (lastName: string) => dispatch(editLastName(lastName))
  },
  phone: {
    onAdd: () => dispatch(addPhone()),
    onEdit: (newPhone: string, i: number) => dispatch(editPhone(newPhone, i)),
    onDelete: (i: number) => dispatch(deletePhone(i)),
    onBlur: validatePhone,
    onSetPrimary: (i: number) => dispatch(setPrimaryPhone(i))
  },
  email: {
    onAdd: () => dispatch(addEmail()),
    onEdit: (newEmail: string, i: number) => dispatch(editEmail(newEmail, i)),
    onDelete: (i: number) => dispatch(deleteEmail(i)),
    onBlur: validateEmail,
    onSetPrimary: (i: number) => dispatch(setPrimaryEmail(i))
  }
}
