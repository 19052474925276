import React from 'react'
import { TableContainer, Table, Thead, Tr, Th, Td, Tbody } from '@chakra-ui/react'
import { VehicleWithId } from '@homevest/types/rental-applications'

const VehiclesDisplay: React.FC<React.PropsWithChildren<{ vehicles: VehicleWithId[] }>> = ({
  vehicles
}) => {
  return (
    <TableContainer>
      <Table variant='striped' size='sm'>
        <Thead>
          <Tr>
            <Th>Make</Th>
            <Th>Model</Th>
            <Th>Year</Th>
            <Th>License</Th>
          </Tr>
        </Thead>
        <Tbody>
          {vehicles.map((vehicle) => (
            <Tr key={vehicle.id}>
              <Td>{vehicle.make}</Td>
              <Td>{vehicle.model}</Td>
              <Td>{vehicle.year}</Td>
              <Td>{vehicle.license_plate}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default VehiclesDisplay
