import { useSourceDetails } from '../context'
import { UploadDocumentWithAmount, SourceDetailsFieldName } from 'lib/user-incomes/types'

export const useDocuments = (fieldName: SourceDetailsFieldName, documentType: string) => {
  const context = useSourceDetails()

  if (!context) {
    return {
      documents: {},
      addDocumentAtIndex: () => {},
      updateAmountAtIndex: () => {},
      clearDocumentAtIndex: () => {},
      removeDocumentAtIndex: () => {},
      getErrorAtIndex: () => ''
    }
  }

  const { data, updateField, errors } = context

  const documents = ((data as any)[fieldName] || {}) as Record<number, UploadDocumentWithAmount>

  const addDocumentAtIndex = (index: number, document: { upload_path?: string }) => {
    updateField(fieldName, {
      ...documents,
      [index]: {
        ...documents[index],
        ...document,
        type: documentType
      }
    })
  }

  const updateAmountAtIndex = (index: number, amount: number) => {
    updateField(fieldName, {
      ...documents,
      [index]: {
        ...documents[index],
        amount
      }
    })
  }

  const removeDocumentAtIndex = (index: number) => {
    const { [index]: _, ...rest } = documents
    updateField(fieldName, rest)
  }

  const clearDocumentAtIndex = (index: number) => {
    updateField(fieldName, {
      ...documents,
      [index]: {}
    })
  }

  const getErrorAtIndex = (index: number, field: 'file' | 'amount') => {
    return (errors as any)?.[fieldName]?.[index]?.[field] as string
  }

  return {
    documents,
    addDocumentAtIndex,
    updateAmountAtIndex,
    clearDocumentAtIndex,
    removeDocumentAtIndex,
    getErrorAtIndex
  }
}
