import React, { FC, useMemo } from 'react'
import { CashoutForm } from 'components/Cashouts/CashoutForm'
import { TwoColumn } from 'components/TailwindUIToolkit'
import { DynamicWidthPageContainer } from 'components/TailwindUIToolkit/Layouts/DynamicWidthPageContainer'
import { useParams } from 'react-router-dom'
import { Sidebar } from 'components/Cashouts/Sidebar'
import { useGetRentalCashoutDetailsQuery } from 'graphql/generated'
import { Spinner } from '@chakra-ui/react'

export const CashoutDetails: FC<React.PropsWithChildren<unknown>> = () => {
  const { rentalId } = useParams<{ rentalId: string }>()
  const context = useMemo(() => ({ suspense: false }), [])
  const [{ data, fetching }] = useGetRentalCashoutDetailsQuery({
    variables: { rentalId },
    context
  })

  return (
    <DynamicWidthPageContainer
      pathToPage={[
        { name: 'Rent Roll', href: '/rent-roll' },
        {
          name: `${data?.rentals_by_pk?.portfolio_home?.home?.address?.display_line_1}`,
          href: `/rent-roll/${rentalId}`
        },
        {
          name: 'Cashout',
          href: `#`
        }
      ]}
      maxWidth='max-w-[90%]'
    >
      {data?.rentals_by_pk && !fetching ? (
        <TwoColumn
          sidebarSide='left'
          sidebarSize='md'
          sidebarContent={<Sidebar rental={data.rentals_by_pk} />}
          mainContent={<CashoutForm data={data} />}
        />
      ) : (
        <Spinner />
      )}
    </DynamicWidthPageContainer>
  )
}
