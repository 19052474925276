import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import Checkmark from 'components/Toolkit/Icons/Checkmark'

const StyledInputContainer = styled.div`
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${COLORS.NEUTRALS.OYSTER};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`

const StyledLabel = styled.label`
  padding: 9px 0;
  cursor: pointer;
`

const StyledCheckbox = styled.div`
  background: ${(props) => (props.isSelected ? COLORS.PRIMARY.PLUM : COLORS.PRIMARY.COCONUT)};
  border: 1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY};
  box-sizing: border-box;
  border-radius: 8px;
  width: 35px;
  height: 35px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) => (props.isSelected ? 'transition: all 0.4s linear' : '')}
`

function Checkbox({ label, value, onChange, ...otherProps }) {
  return (
    <StyledInputContainer {...otherProps}>
      <StyledCheckbox isSelected={value} onClick={() => onChange(!value)}>
        <Checkmark />
      </StyledCheckbox>
      <StyledLabel onClick={() => onChange(!value)}>{label}</StyledLabel>
    </StyledInputContainer>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default memo(Checkbox)
