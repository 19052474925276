import React, { FunctionComponent, useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import { startCase } from 'lodash'
import { format } from 'date-fns'
import axios from 'lib/axios'

import { Rental_Applications } from 'graphql/generated'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'

import { INTERNAL_APPLICATION_SLUG } from 'constants/urls'

type ApplicationsPropTypes = {
  userId: string
}

const ApplicationsWrapper: FunctionComponent<React.PropsWithChildren<ApplicationsPropTypes>> = (
  props
) => (
  <ContentSectionCard title={'Applications'} size={'large'}>
    <Application {...props} />
  </ContentSectionCard>
)

const Application: FunctionComponent<React.PropsWithChildren<ApplicationsPropTypes>> = ({
  userId
}) => {
  const [applications, setApplications] = useState([])
  const [fetching, setFetching] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const { data } = await axios.get(`/admin/rental_applications?user_id=${userId}`)
        setApplications(data)
      } catch (err: any) {
        setErrorMessage(err.message)
        setFetching(false)
        throw err
      }
      setFetching(false)
    }

    fetchApplications()
  }, [userId])

  if (fetching) {
    return (
      <SC.DetailsContainer borders>
        <Spinner />
      </SC.DetailsContainer>
    )
  }

  if (errorMessage) {
    return (
      <SC.DetailsContainer borders>
        <SC.ErrorMessage>{errorMessage}</SC.ErrorMessage>
      </SC.DetailsContainer>
    )
  }

  if (!applications.length) {
    return (
      <SC.DetailsContainer borders>
        <SC.MessageText>No applications found for user</SC.MessageText>
      </SC.DetailsContainer>
    )
  }
  return (
    <>
      {applications.map((a: Rental_Applications) => (
        <SC.DetailsContainer borders key={a.id}>
          <SC.SubsectionTitleRouterLink to={`/home/${a.mls_listing.firestore_id}`}>
            {`${a.mls_listing.display_line_1}, ${a.mls_listing.display_line_2}` ||
              'Address Not Found'}
          </SC.SubsectionTitleRouterLink>
          <SC.DetailText>{mapContentToBadge(a.status)}</SC.DetailText>
          <SC.LightDetailText>
            <SC.GrayCalendar />
            {startCase(a.status)} on {format(new Date(a.updated_at), 'PP')}
          </SC.LightDetailText>
          <SC.SubsectionTitleRouterLink to={`${INTERNAL_APPLICATION_SLUG}${a.id}`}>
            <SC.AppLink />
            Application link
          </SC.SubsectionTitleRouterLink>
        </SC.DetailsContainer>
      ))}
    </>
  )
}

export default ApplicationsWrapper
