import { GridApi, GridReadyEvent } from 'ag-grid-community'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { Stack, Input, Button, Box } from '@chakra-ui/react'
import { Grid } from 'components/Homes/Grid/HomesGrid'
import { useHomesGridQuery } from 'graphql/generated'
import { StatusCountBar } from 'components/Homes/StatusCountBar'
import { useHistory } from 'react-router-dom'
import { Homes360View, getColumnsForView } from 'components/Homes/Grid/ColumnDefs'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'

export type StatusFilter = {
  statusName: string
  statusType: string
}

interface HomesListProps {
  view: Homes360View
}

const AddressInput: FunctionComponent<
  React.PropsWithChildren<{
    handleChange: (input: string) => void
  }>
> = ({ handleChange }) => {
  return (
    <Stack direction='column' m={4}>
      <Input
        type='text'
        onChange={(e) => handleChange(e.currentTarget.value)}
        name='search'
        id='search'
        placeholder='10 Grand St..., Needham Hurst...'
      />
    </Stack>
  )
}

const HomesList: FunctionComponent<React.PropsWithChildren<HomesListProps>> = ({
  view: viewProp
}) => {
  const [gridApi, setGridApi] = useState<GridApi>()
  const [statusFilter, setStatusFilter] = useState<StatusFilter>()
  const history = useHistory()
  const [view, setView] = useState<Homes360View>(viewProp)
  const columns = getColumnsForView(view)

  const [addressQuery, setAddressQuery] = useState<string>()
  const [{ data, error }] = useHomesGridQuery()

  const onGridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api)
  }

  useEffect(() => {
    if (gridApi && addressQuery !== undefined) {
      gridApi.setQuickFilter(addressQuery)
      gridApi.onFilterChanged()
    }
  }, [addressQuery, gridApi])

  if (error) {
    return <div>Error: {error.message}</div>
  }
  return (
    <>
      <Helmet>
        <title>Homes 360</title>
      </Helmet>
      <div>
        <StatusCountBar homes360Data={data} onStatusClicked={setStatusFilter} />
        <Stack direction='row' w='full' justifyContent='center' paddingBottom='4'>
          {Object.values(Homes360View).map((v) => (
            <Button
              key={v}
              border={v === view ? 'solid' : undefined}
              onClick={() => {
                setView(v)
                history.replace({ pathname: 'homes', search: `?gridView=${v}` })
              }}
            >
              {mapContentToBadge(v, { dot: false })}
            </Button>
          ))}
        </Stack>
        <Stack direction='row' w='full' alignItems='center'>
          <Box flex={1}>
            <AddressInput handleChange={setAddressQuery} />
          </Box>
          <Box flex={0}>
            <Button
              type='button'
              isLoading={!gridApi}
              onClick={() => {
                gridApi?.exportDataAsCsv({
                  fileName: `${
                    statusFilter?.statusName ?? 'all'
                  }-homes360-${new Date().toLocaleDateString()}`
                })
              }}
            >
              Export to CSV
            </Button>
          </Box>
        </Stack>
        <Grid
          onGridReady={onGridReady}
          rowData={data?.homes_list || []}
          statusFilter={statusFilter}
          columns={columns}
        />
        ;
      </div>
    </>
  )
}

export default HomesList
