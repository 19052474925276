"use strict";
exports.DOC_NAMES = {
    internal: 'internal',
    zillow: 'zillow',
    zumper: 'zumper'
};
exports.RENTAL_SITE_ACCOUNT_TYPES = {
    homevest: 'homevest',
    burner: 'burner'
};
exports.RENTAL_SITES = {
    zillow: 'zillow',
    zumper: 'zumper'
};
/** Warning: to be deprecated */
exports.rentalSites = exports.DOC_NAMES;
/** Warning: to be deprecated */
exports.rentalSiteAccountTypes = exports.RENTAL_SITE_ACCOUNT_TYPES;
/** Warning: to be deprecated */
exports.docNames = exports.RENTAL_SITES;
