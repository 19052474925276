import React, { useState } from 'react'
import {
  Tr,
  Td,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'
import { requiredLeaseDetails } from '@homevest/utils'
import { VehicleWithId } from '@homevest/types/rental-applications'

import AddButton from '../../Common/AddButton'
import { isVehicleYearValid, isVehicleLicensePlateValid } from 'lib/required-lease-details'

const { MIN_VEHICLE_YEAR, MAX_LICENSE_PLATE_LENGTH } = requiredLeaseDetails
const MAX_VEHICLE_YEAR = new Date().getFullYear() + 1

const AddVehicleRow: React.FC<
  React.PropsWithChildren<{
    addVehicle: (vehicle: VehicleWithId) => void
    canAddVehicle: boolean
    skipValidation: boolean
  }>
> = ({ addVehicle, canAddVehicle, skipValidation }) => {
  const [id, setId] = useState(uuidv4())
  const [make, setMake] = useState('')
  const [model, setModel] = useState('')
  const [year, setYear] = useState<number | null>(null)
  const [licensePlate, setLicensePlate] = useState('')

  // Don't show error initially when year is null, but disable submission until this is filled out
  const isYearValid = year === null || isVehicleYearValid(year) || skipValidation
  const isLicensePlateValid = isVehicleLicensePlateValid(licensePlate) || skipValidation
  const canSubmit = Boolean(
    canAddVehicle &&
      make &&
      model &&
      year !== null &&
      isYearValid &&
      licensePlate &&
      isLicensePlateValid
  )

  const handleSubmit = () => {
    if (canSubmit) {
      addVehicle({ id, make, model, year: year!, license_plate: licensePlate })
      setId(uuidv4())
      setMake('')
      setModel('')
      setYear(null)
      setLicensePlate('')
    }
  }

  if (!canAddVehicle) {
    return null
  }

  return (
    <Tr>
      <Td>
        <FormControl isRequired>
          <FormLabel>Make</FormLabel>
          <Input
            placeholder='Nissan...'
            value={make}
            onChange={(evt) => setMake(evt.target.value)}
          />
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired>
          <FormLabel>Model</FormLabel>
          <Input
            placeholder='Ultima...'
            value={model}
            onChange={(evt) => setModel(evt.target.value)}
          />
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired isInvalid={!isYearValid}>
          <FormLabel>Year</FormLabel>
          <NumberInput
            max={MAX_VEHICLE_YEAR}
            min={MIN_VEHICLE_YEAR}
            // placeholder='Year...'
            value={year ?? ''}
            onChange={(value) => setYear(value ? parseInt(value) : null)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>
            Please enter a year between {MIN_VEHICLE_YEAR} and {MAX_VEHICLE_YEAR}
          </FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired isInvalid={!isLicensePlateValid}>
          <FormLabel>License Plate</FormLabel>
          <Input
            placeholder='ABC-XYZ...'
            value={licensePlate}
            onChange={(evt) => setLicensePlate(evt.target.value)}
          />
          <FormErrorMessage>
            License plate should be under {MAX_LICENSE_PLATE_LENGTH + 1} characters
          </FormErrorMessage>
        </FormControl>
      </Td>
      <Td valign='bottom'>
        <AddButton disabled={!canSubmit} onClick={handleSubmit} />
      </Td>
    </Tr>
  )
}

export default AddVehicleRow
