import React from 'react'
import { HomeIcon } from '@heroicons/react/20/solid'
import { Text } from '@chakra-ui/react'
import humanizeString from 'humanize-string'
import { format } from 'date-fns'
import { types } from '@homevest/timelines'
import { TimelineEventContentRenderer, TimelineEventDisplayTemplate } from '../types'

const Bold: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Text display='inline' color='inherit' fontWeight='bold'>
    {children}
  </Text>
)

const Strikethrough: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Text display='inline' color='inherit' as='s'>
    {children}
  </Text>
)

const PreviewContent: TimelineEventContentRenderer<types.HomeStatusTimelineEvent> = ({
  event,
  home
}) => {
  console.log('home: ', home)
  return (
    <Text color='gray.600' fontSize='sm'>
      {<Bold>{home?.address}</Bold> || 'home'} was assigned a new {event.homeStatusType} status:{' '}
      {event.oldStatus && (
        <>
          <Bold>
            <Strikethrough>{humanizeString(event.oldStatus)}</Strikethrough>
          </Bold>{' '}
          {'->'}
        </>
      )}{' '}
      <Bold>{humanizeString(event.newStatus)}</Bold>
    </Text>
  )
}

const ExpandedContent: TimelineEventContentRenderer<types.HomeStatusTimelineEvent> = ({
  event
}) => {
  const homeStatusType = humanizeString(event.homeStatusType)
  const previousStatus = humanizeString(event.oldStatus || '') || '-'
  const formattedEventTime = format(new Date(event.eventTimeAt), 'PP @ p')

  return (
    <Text color='gray.600' fontSize='xs'>
      <Text>
        <Bold>Status type</Bold> {homeStatusType}
      </Text>
      <Text>
        <Bold>New {homeStatusType} Status</Bold> {humanizeString(event.newStatus)}
      </Text>
      <Text>
        <Bold>Previous {homeStatusType} status</Bold> {previousStatus}
      </Text>
      <Text>
        <Bold>Occurred on</Bold> {formattedEventTime}
      </Text>
    </Text>
  )
}

export const HomeStatusEventDisplayTemplate: TimelineEventDisplayTemplate<types.HomeStatusTimelineEvent> =
  {
    icon: HomeIcon,
    iconColor: 'green.500',
    previewContent: PreviewContent,
    expandedContent: ExpandedContent
  }
