import React, { useState } from 'react'
import { startCase } from 'lodash'
import styled from 'styled-components'
import { leadGroups } from '@homevest/utils'
import { Text, IconButton, Select, useToast } from '@chakra-ui/react'
import { Check } from 'styled-icons/heroicons-solid'

import { Pencil } from './Toolkit'
import axios from '../lib/axios'
import { LeadGroupUser } from 'types/RentalApplication'

const StyledField = styled.div<{ isEditing: boolean }>`
  &:hover {
    background-color: ${(props) => (props.isEditing ? '#fff' : '#eee')};

    .pencil {
      display: ${(props) => (props.isEditing ? 'none' : 'block')};
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pencil {
    display: none;
  }
`

const { LEAD_GROUP_USER_TYPES } = leadGroups

const LEAD_GROUP_USER_TYPE_OPTIONS = Object.values(LEAD_GROUP_USER_TYPES).map((type) => ({
  label: startCase(type),
  value: type
}))

const LeadType: React.FC<React.PropsWithChildren<{ leadGroupUser: LeadGroupUser }>> = ({
  leadGroupUser
}) => {
  const [type, setType] = useState(leadGroupUser.type)
  const [originalType, setOriginalType] = useState(leadGroupUser.type)
  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const toast = useToast()

  const updateLeadGroupUserType = async () => {
    if (type === originalType || !isEditing) {
      setIsEditing(false)
      return
    }

    setIsSubmitting(true)

    try {
      await axios.post(`/admin/lead_group_users/${leadGroupUser.id}`, { type })
    } catch (err: any) {
      toast({
        title: 'Error',
        description: `An error occured while updating the lead type: ${err.message}`,
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true
      })
      return
    }

    toast({
      title: 'Lead Type Updated',
      description: `The lead type has been updated to ${startCase(type)}`,
      status: 'success',
      duration: 5000,
      position: 'bottom-right',
      isClosable: true
    })
    setIsSubmitting(false)
    setIsEditing(false)
  }

  return (
    <StyledField
      style={{
        cursor: isEditing ? '' : 'pointer'
      }}
      onClick={() => {
        if (!isEditing && !isSubmitting) {
          setOriginalType(type)
          setIsEditing(true)
        }
      }}
      isEditing={isEditing}
    >
      <div>
        <Text fontSize={'xs'}>Lead Type</Text>
        {isEditing ? (
          <div className='items-top mt-2 flex'>
            <Select value={type} onChange={(e) => setType(e.target.value)}>
              {LEAD_GROUP_USER_TYPE_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <IconButton
              aria-label='Done'
              size='md'
              verticalAlign={'bottom'}
              whiteSpace={'nowrap'}
              colorScheme='teal'
              marginLeft={2}
              onClick={updateLeadGroupUserType}
              disabled={isSubmitting}
              icon={<Check className='h-4 w-4' />}
            >
              Done
            </IconButton>
          </div>
        ) : (
          <Text>{startCase(type)}</Text>
        )}
      </div>
      <div className='pencil mr-2'>
        <Pencil />
      </div>
    </StyledField>
  )
}

export default LeadType
