import { Box, Stack, Divider, Icon, Text } from '@chakra-ui/react'
import { toPairs, startCase } from 'lodash'
import React, { useState } from 'react'

interface StatusCountDisplayProps<T extends string = string> {
  statusCounts: Record<T, number>
  order?: T[]
  selectedStatus?: T
  // TODO: rename this to be onSelectedStatusChanged
  onStatusClicked: (statusFilter?: T) => void
  icon?: React.ElementType
  getIconForStatus?: (status: T) => React.ElementType
}

const StatusCountsDisplay = <T extends string>({
  statusCounts,
  getIconForStatus,
  onStatusClicked,
  icon,
  order,
  selectedStatus
}: StatusCountDisplayProps<T>) => {
  const [activeStatusFilter, setActiveStatusFilter] = useState<T | undefined>(selectedStatus)

  const statusCountPairs = toPairs<number>(statusCounts) as [T, number][]
  const sortedStatusCounts = order
    ? statusCountPairs.sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))
    : statusCountPairs
  return (
    <Stack direction='column' align='center'>
      <Stack
        direction='row'
        spacing='0'
        borderRadius='md'
        borderWidth='2px'
        borderBottomWidth='0px'
        w='fit-content'
        flexWrap='wrap'
        divider={
          <div>
            <Divider h='full' orientation='vertical' />
          </div>
        }
      >
        {sortedStatusCounts.map(([name, number]) => {
          if (name === 'null' || name === 'none') {
            return null
          }
          return (
            <Box
              p='4'
              key={name}
              h='full'
              flexShrink={0}
              flexGrow={1}
              borderBottomWidth='1px'
              background={activeStatusFilter === name ? 'gray.100' : 'white'}
              onClick={() => {
                const newStatusFilter = name === activeStatusFilter ? undefined : name
                setActiveStatusFilter(newStatusFilter)
                onStatusClicked(newStatusFilter)
              }}
              cursor='pointer'
              transition='background 0.5s'
              _hover={{ background: 'gray.100' }}
            >
              <Text fontFamily='Inter'>{startCase(name)}</Text>
              {(getIconForStatus || icon) && (
                <Icon color='gray.400' boxSize='6' as={getIconForStatus?.(name) ?? icon} />
              )}{' '}
              {number}
            </Box>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default StatusCountsDisplay
