import React, { FunctionComponent } from 'react'
import { upperFirst, lowerCase } from 'lodash'
import { Interaction } from 'types/Timeline'
import RenderFields, { FieldsObject } from './RenderFields'
import { ItemTitle } from './StyledComponents'

type RenderItemPropTypes = {
  interaction: Interaction
  interactionEventType?: string
  fields: FieldsObject
  open: boolean
}

// a react component for displaying any interaction
// event on the customer timeline

const RenderItem: FunctionComponent<React.PropsWithChildren<RenderItemPropTypes>> = ({
  interaction,
  interactionEventType,
  fields,
  open
}) => (
  <div>
    <ItemTitle>
      {upperFirst(lowerCase(interaction.interactionType))}
      {interactionEventType ? ': ' + lowerCase(interactionEventType) : ''}
    </ItemTitle>
    <RenderFields fieldsObject={fields} interaction={interaction} open={open} />
  </div>
)

export default RenderItem
