// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React from 'react'
import {
  Admin_Capabilities_Insert_Input as AdminCapInput,
  UpupAdminsWithCapabilitiesQuery,
  UpupCapabilitiesWithAdminQuery,
  UpupOverwriteAdminCapabilitiesDocument
} from 'graphql/generated'
import Select, { OptionsType, OptionTypeBase } from 'react-select'
import { Modal, BodyText, HeaderText, Button } from 'components/Toolkit'
import { useMutation } from 'urql'
import { Spinner } from 'reactstrap'

type Props = {
  isOpen: boolean
  onClose: () => void
  cloneFromAdmin?: UpupAdminsWithCapabilitiesQuery['admins'][number]
  admins?: UpupAdminsWithCapabilitiesQuery['admins']
  capabilities?: UpupCapabilitiesWithAdminQuery['capabilities']
}

const BulkEditModal: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  cloneFromAdmin,
  admins,
  capabilities
}) => {
  const [selectedAdmins, setSelectedAdmins] = React.useState<OptionsType<OptionTypeBase>>([])
  const [selectedCapabilities, setSelectedCapabilities] = React.useState<
    OptionsType<OptionTypeBase>
  >(
    () =>
      cloneFromAdmin?.admin_capabilities.map((item) => ({
        value: item.capability.id,
        label: item.capability.name
      })) ?? []
  )

  const [{ fetching }, overwriteCapabilities] = useMutation(UpupOverwriteAdminCapabilitiesDocument)

  React.useEffect(() => {
    if (cloneFromAdmin?.id) {
      setSelectedCapabilities(
        cloneFromAdmin.admin_capabilities.map((item) => ({
          value: item.capability.id,
          label: item.capability.name
        }))
      )
    }
  }, [cloneFromAdmin?.id])

  const capabilityOptions = capabilities?.map((item) => ({
    value: item.id,
    label: `${item.name}`
  }))

  const adminOptions = admins
    ?.filter((item) => (!cloneFromAdmin ? true : item.id !== cloneFromAdmin.id))
    .map((item) => ({
      value: item.id,
      label: `${item.first_name} ${item.last_name}`
    }))

  const handleOverwrite = () => {
    const adminsMap = selectedAdmins.reduce((acc, admin) => ({ ...acc, [admin.value]: true }), {})
    const capsMap = selectedCapabilities.reduce((acc, cap) => ({ ...acc, [cap.value]: true }), {})
    const adminsList = admins?.filter((admin) => adminsMap[admin.id]) ?? []
    const capsList = capabilities?.filter((cap) => capsMap[cap.id]) ?? []

    const idsToRemove = adminsList.reduce(
      (acc, admin) => [...acc, ...admin.admin_capabilities.map((cap) => cap.id)],
      [] as string[]
    )
    const capsToAdd = capsList.reduce(
      (acc, cap) => [
        ...acc,
        ...adminsList.map((admin) => ({
          admin_id: admin.id,
          capability_id: cap.id
        }))
      ],
      [] as AdminCapInput[]
    )
    overwriteCapabilities({
      ids_to_remove: idsToRemove,
      objects_to_add: capsToAdd
    })
  }

  return isOpen ? (
    <Modal
      hasCloseButton
      close={() => {
        setSelectedAdmins([])
        setSelectedCapabilities([])
        onClose()
      }}
      width='66vw'
    >
      <HeaderText size='h3'>
        {cloneFromAdmin
          ? `Clone capabilities of ${cloneFromAdmin?.first_name} ${cloneFromAdmin?.last_name}`
          : 'Assign capabilities to multiple admins'}
      </HeaderText>
      <BodyText size='s' style={{ fontStyle: 'italic', marginBottom: '1rem' }}>
        Select which capabilities you want to give.
      </BodyText>
      <div style={{ marginBottom: '1rem' }}>
        <Select
          value={selectedCapabilities}
          defaultValue={selectedCapabilities}
          options={capabilityOptions}
          onChange={(selected) => setSelectedCapabilities(selected)}
          isClearable
          isMulti
        />
      </div>
      <BodyText size='s' style={{ fontStyle: 'italic', marginBottom: '1rem' }}>
        Select which admins will have their current capabilities removed and replaced.
      </BodyText>
      <div style={{ marginBottom: '1rem' }}>
        <Select
          defaultValue={selectedAdmins}
          options={adminOptions}
          onChange={(selected) => setSelectedAdmins(selected)}
          isClearable
          isMulti
        />
      </div>
      <Button
        disabled={selectedAdmins.length < 1 || fetching}
        size='vs'
        isSecondary={false}
        onClick={handleOverwrite}
      >
        {fetching ? <Spinner /> : 'Overwrite'}
      </Button>
    </Modal>
  ) : null
}

export default BulkEditModal
