import React, { FunctionComponent } from 'react'
import LatchelCard from '../Cards/LatchelProperty'
import WorkOrderHistoryCard from '../Cards/WorkOrderHistory'
import ConstructionHistoryCard from '../Cards/ConstructionHistory'

interface ConstructionPanelProps {
  portfolioHomeId: string
}
export const ConstructionPanel: FunctionComponent<
  React.PropsWithChildren<ConstructionPanelProps>
> = ({ portfolioHomeId }) => (
  <>
    <LatchelCard portfolioHomeId={portfolioHomeId} />
    <WorkOrderHistoryCard portfolioHomeId={portfolioHomeId} />
    <ConstructionHistoryCard portfolioHomeId={portfolioHomeId} />
  </>
)
