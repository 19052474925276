import React from 'react'
import { Heading, Text } from '@chakra-ui/react'
import { userIncomeSources } from '@homevest/utils'

import { useSourceDetails } from './context'
import { useSubmitIncomeSourceMutation } from './hooks/submit'
import { formatEmployedUserIncomeSource } from 'lib/user-incomes/format'
import StandardEmployedPayDetails from './sections/StandardEmployedPayDetails'
import NewlyEmployedPayDetails from './sections/NewlyEmployedPayDetails'
import SubmitButton from './common/SubmitButton'
import SubmittingScreen from './common/SubmittingScreen'
import { BasicFormField, EmailFormField, PhoneFormField } from './common/form'
import { validateEmployedIncomeSource } from 'lib/user-incomes/validate'

const { USER_INCOME_SOURCE_TYPES: SOURCE_TYPES } = userIncomeSources

const EmployedSourceDetails = ({ userId, onSubmit }: { userId: string; onSubmit: () => void }) => {
  const context = useSourceDetails()
  const { isSubmitting, handleSubmit } = useSubmitIncomeSourceMutation(
    userId,
    formatEmployedUserIncomeSource,
    validateEmployedIncomeSource,
    onSubmit
  )
  if (!context || context.data.type !== SOURCE_TYPES.EMPLOYED) {
    return null
  }

  if (isSubmitting) {
    return <SubmittingScreen />
  }

  const { data } = context
  const isNewlyEmployed = data.is_newly_employed

  return (
    <>
      <div>
        <Heading as='h3' size='md' mb='3'>
          Employment Income Details
        </Heading>
        <BasicFormField label='Employer Name' fieldName='company_name' />
        <div className='flex flex-col gap-3'>
          <Heading mt='8' size='sm'>
            Employment Verification Details
          </Heading>
          <Text>Enter the applicant's employment verification details.</Text>
          <div className='flex flex-col gap-5 md:flex-row'>
            <BasicFormField isRequired={false} label='Contact Name' fieldName='contact_name' />
            <BasicFormField isRequired={false} label='Contact Title' fieldName='contact_title' />
          </div>
          <div className='flex flex-col gap-5 md:flex-row'>
            <PhoneFormField isRequired={false} label='Contact Phone' fieldName='contact_phone' />
            <EmailFormField isRequired={false} label='Contact Email' fieldName='contact_email' />
          </div>
        </div>
        <div className='flex flex-col gap-3'>
          <Heading mt='8' size='sm'>
            Pay Details
          </Heading>
          <Text>Enter the applicant's pay details.</Text>
          {isNewlyEmployed ? <NewlyEmployedPayDetails /> : <StandardEmployedPayDetails />}
        </div>
      </div>
      <SubmitButton onSubmit={handleSubmit} />
    </>
  )
}

export default EmployedSourceDetails
