import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import type { OperationContext } from 'urql'
import {
  Button,
  Center,
  Checkbox,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Spinner,
  Stack,
  Text,
  FormControl,
  FormErrorMessage,
  useToast
} from '@chakra-ui/react'
import { capabilities } from '@homevest/utils'
import { StoreState } from 'store'
import SidePeek from './SidePeek'
import { useUserIncomeByUserIdQuery } from 'graphql/generated'
import { computeUserIncomeStatus } from 'lib/user-incomes/status'
import { hasCapability } from 'lib/admin-perms'
import axios from 'lib/axios'
import { formatMoney } from 'lib/numbers'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'

const { CAPABILITY_TYPES } = capabilities

type AddUserIncomeModalProps = {
  userId: string
  refetch: (opts?: Partial<OperationContext> | undefined) => void
  isAddIncomeModalOpen: boolean
  setIsAddIncomeModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AddUserIncomeModal: React.FC<React.PropsWithChildren<AddUserIncomeModalProps>> = ({
  userId,
  refetch,
  isAddIncomeModalOpen,
  setIsAddIncomeModalOpen
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [annualIncomeInput, setAnnualIncomeInput] = useState('')
  const [isNonIncomeApplicant, setIsNonIncomeApplicant] = useState(false)

  const toast = useToast()

  const parsedIncome = Number(annualIncomeInput)
  const canCreateIncome =
    !isNaN(parsedIncome) && isNonIncomeApplicant ? parsedIncome === 0 : parsedIncome > 0

  const closeModal = () => {
    setIsAddIncomeModalOpen(false)
    setAnnualIncomeInput('')
    setIsNonIncomeApplicant(false)
  }

  const createUserIncome = async () => {
    if (!canCreateIncome) {
      return
    }

    setIsLoading(true)
    try {
      await axios.post('/admin/user_incomes', {
        annual_income: parsedIncome,
        is_non_income_applicant: isNonIncomeApplicant,
        user_id: userId,
        approved_at: null
      })
    } catch (err: any) {
      toast({
        title: 'Error',
        description: err.response?.data?.message || 'An error occurred while creating income!',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })
      setIsLoading(false)
      return
    }

    await refetch({ requestPolicy: 'network-only' })
    setIsLoading(false)
    closeModal()
    toast({
      title: 'Success',
      description: 'Income successfully created!',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'bottom-right'
    })
  }

  return (
    <Modal isOpen={isAddIncomeModalOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontSize='2xl'>Add User Income</ModalHeader>
        <ModalBody w='full'>
          <HStack pb='4'>
            <Text verticalAlign='center'> Gross Annual Income</Text>
            <FormControl isRequired isInvalid={isNaN(parsedIncome)}>
              <NumberInput
                defaultValue={0}
                min={0}
                precision={2}
                value={annualIncomeInput}
                isDisabled={isNonIncomeApplicant}
                onChange={(value) => setAnnualIncomeInput(value)}
              >
                <NumberInputField />
                <FormErrorMessage>Please enter a valid number!</FormErrorMessage>
              </NumberInput>
            </FormControl>
          </HStack>
          <Checkbox
            isChecked={isNonIncomeApplicant}
            onChange={() => {
              if (!isNonIncomeApplicant) {
                setAnnualIncomeInput('0')
              }
              setIsNonIncomeApplicant((x) => !x)
            }}
          >
            Is this a non-income applicant?
          </Checkbox>
          <Button
            w='full'
            mt='2'
            mb='4'
            colorScheme='teal'
            isDisabled={!canCreateIncome}
            isLoading={isLoading}
            onClick={createUserIncome}
          >
            Submit
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const UserIncome = ({ userId }: { userId: string }) => {
  const isIncomeReviewer = useSelector((store: StoreState) =>
    hasCapability(store.admin, CAPABILITY_TYPES.INCOME_REVIEWER)
  )

  const [{ data, fetching, error }, refetch] = useUserIncomeByUserIdQuery({
    variables: { userId }
  })

  const [isAddIncomeModalOpen, setIsAddIncomeModalOpen] = useState(false)

  if (fetching) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  if (error) {
    return (
      <div>
        <Text>
          Sorry! An error has occurred while fetching the applicant&apos;s income data. Please
          contact Engineering for support.
        </Text>
        <Text>
          <Text as='span' fontWeight={'semibold'}>
            Error Message:
          </Text>{' '}
          {error.message || '-'}
        </Text>
      </div>
    )
  }

  const userIncome = data?.user_incomes?.[0]
  if (!userIncome) {
    return (
      <Stack>
        <HStack alignItems={'center'}>
          <Heading mb='3' mr='3' size='md' as='h3'>
            Income
          </Heading>
          <Button
            verticalAlign={'top'}
            size='xs'
            variant='outline'
            colorScheme='teal'
            onClick={() => setIsAddIncomeModalOpen((x) => !x)}
          >
            + Add
          </Button>
        </HStack>
        <Text>This user has not yet submitted income information!</Text>
        <AddUserIncomeModal
          {...{ userId, refetch, isAddIncomeModalOpen, setIsAddIncomeModalOpen }}
        />
      </Stack>
    )
  }

  const userIncomeStatus = computeUserIncomeStatus(userIncome)

  return (
    <Stack>
      <Heading mb='3' size='md' as='h3'>
        Income
      </Heading>
      <div className='flex flex-row justify-between gap-3'>
        <div className='flex flex-col gap-2'>
          <HStack fontSize='md'>
            <Text as='span' fontWeight={'semibold'}>
              Status:
            </Text>{' '}
            {mapContentToBadge(userIncomeStatus)}
          </HStack>
          <Text fontSize='md'>
            <Text as='span' fontWeight={'semibold'}>
              Gross Annual Income:
            </Text>{' '}
            {userIncome.is_non_income_applicant
              ? 'N/A (Non-Income Applicant)'
              : formatMoney(userIncome.annual_income, 2, '$')}
          </Text>
        </div>
        {isIncomeReviewer && (
          <div className='w-1/4'>
            <SidePeek
              userId={userId}
              userIncome={userIncome}
              onClose={() => refetch({ requestPolicy: 'network-only' })}
            />
          </div>
        )}
      </div>
    </Stack>
  )
}

const UserIncomeWrapper = ({ userId }: { userId: string }) => {
  return (
    <div className='mt-30px mb-20px flex flex-col'>
      <UserIncome userId={userId} />
    </div>
  )
}

export default UserIncomeWrapper
