import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { capitalize, get } from 'lodash'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'

import ChargeModal from 'screens/Users/ChargeModal'
import EditModal from 'screens/Users/EditModal'
import { hasCapability } from 'lib/admin-perms'
import axios from 'lib/axios'
import { webUrl, cookieOptions } from 'lib/config'
import { BodyText, Button, ErrorText, HeaderText, Label, SuccessText } from 'components/Toolkit'
import COLORS from 'components/Toolkit/colors'
import summarySrc from './summary.png'
import { combineFirstNameLastName } from 'lib/utils'
import { impersonateAndOpenWeb } from 'lib/impersonation'

const { CUSTOMER_CHARGER, IMPERSONATOR, USER_MANAGER } = capabilities.CAPABILITY_TYPES

const QUALIFICATION_STATUS_TO_COLOR = {
  qualified_self_reported: '#49C896',
  qualified_verified: '#49C896',
  unqualified_self_reported: 'red',
  unqualified_verified: 'red',
  pending_qualification: '#CCCs'
}

const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #302654;
  margin-top: 50px;
`

async function getActiveRental(userId, setActiveRental) {
  const res = await axios.get(`/users/${userId}`)
  const rentals = res.data.rentals

  const activeRental = rentals.find((rental) => rental.status === 'active')
  if (activeRental) {
    setActiveRental(activeRental)
  }
}

async function getCrmUser(userId, setCrmUser) {
  const userRes = await axios.get(`/admin/users/${userId}`)
  setCrmUser(userRes.data)
}

const getActiveRentalApplication = async (userId, setRentalApplication) => {
  const res = await axios.get(`/admin/rental_applications?user_id=${userId}&is_active=true`)
  setRentalApplication(res.data && res.data[0])
}

async function loadAll(userId, setCrmUser, setRentalApplication, setActiveRental) {
  await Promise.all([
    getActiveRental(userId, setActiveRental),
    getCrmUser(userId, setCrmUser),
    getActiveRentalApplication(userId, setRentalApplication)
  ])
}

const doDelete = async ({ userId, setErrorText, setSuccessText, setReloadDataAt }) => {
  try {
    await axios.delete(`/admin/users/${userId}`)
    setSuccessText('The user has been deleted! 👋')
    setReloadDataAt(new Date())
  } catch (err) {
    setErrorText(err.message)
  }
}

const doImpersonation = async (userId, adminId, setError) => {
  try {
    await impersonateAndOpenWeb(userId, adminId)
  } catch (err) {
    setError(err.message)
  }
}

function UserInfo({ userId, horizontal = false }) {
  const admin = useSelector((store) => store.admin)
  const isChargingAllowed = hasCapability(admin, CUSTOMER_CHARGER)
  const isImpersonationAllowed = hasCapability(admin, IMPERSONATOR)
  const isDeleteAllowed = hasCapability(admin, USER_MANAGER)

  const [activeRental, setActiveRental] = useState(null)
  const [isChargeModalOpen, setIsChargeModalOpen] = useState(false)
  const [isUserEditOpen, setIsUserEditOpen] = useState(false)
  const [crmUser, setCrmUser] = useState(null)
  const [errorText, setErrorText] = useState(null)
  const [reloadDataAt, setReloadDataAt] = useState(null)
  const [rentalApplication, setRentalApplication] = useState(null)
  const [successText, setSuccessText] = useState(null)

  const handleOpenChargeModal = () => {
    setIsChargeModalOpen(true)
  }

  const handleCloseChargeModal = () => {
    setIsChargeModalOpen(false)
  }

  const handleOpenUserEditModal = () => {
    setIsUserEditOpen(true)
  }

  const handleCloseUserEditModal = () => {
    setIsUserEditOpen(false)
  }

  useEffect(() => {
    loadAll(userId, setCrmUser, setRentalApplication, setActiveRental)
  }, [userId, reloadDataAt])

  if (!crmUser) {
    return (
      <StyledLoading>
        <span>Loading...</span>
      </StyledLoading>
    )
  }

  let fullName
  if (crmUser) {
    fullName = combineFirstNameLastName(crmUser)
  }

  return (
    <div style={{ display: horizontal ? 'flex' : '' }}>
      <div style={horizontal ? { width: '50%', paddingRight: '50px' } : {}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontSize: '14px',
            marginBottom: '20px'
          }}
        >
          <div
            style={{
              width: '10px',
              height: '10px',
              marginRight: '10px',
              borderRadius: '100%',
              backgroundColor: QUALIFICATION_STATUS_TO_COLOR[crmUser.qualification_data.status]
            }}
          />
          <span
            style={{
              color: QUALIFICATION_STATUS_TO_COLOR[crmUser.qualification_data.status]
            }}
          >
            {crmUser.qualification_data.status.split('_').map(capitalize).join(' ')}
          </span>
        </div>

        <div style={{ marginBottom: '10px' }}>
          {errorText && <ErrorText>{errorText}</ErrorText>}
          {successText && <SuccessText>{successText}</SuccessText>}
          {!crmUser.deleted_at && <HeaderText size='h4'>{fullName}</HeaderText>}
          {crmUser.deleted_at && (
            <div>
              <HeaderText size='h4' style={{ textDecoration: 'line-through' }}>
                {fullName}
              </HeaderText>
              <BodyText>
                {fullName} was deleted by {get(crmUser, 'deleted_by_admin.first_name')}{' '}
                {get(crmUser, 'deleted_by_admin.last_name', 'System')} on{' '}
                {moment(crmUser.deleted_at).format('MM/DD/yyyy @ hh:mm A')}
              </BodyText>
            </div>
          )}
          {crmUser.primary_user_id && (
            <div>
              <BodyText>
                {fullName} was merged into{' '}
                <Link
                  to={`/users/${crmUser.primary_user_id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {get(crmUser, 'primary_user.first_name')} {get(crmUser, 'primary_user.last_name')}{' '}
                </Link>
                by {get(crmUser, 'merged_by_admin.first_name')}{' '}
                {get(crmUser, 'merged_by_admin.last_name', 'System')} on{' '}
                {moment(crmUser.merged_at).format('MM/DD/yyyy @ hh:mm A')}
              </BodyText>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <div style={{ marginRight: '3px' }}>
              <Button size='vvvs' onClick={() => handleOpenUserEditModal()}>
                Edit
              </Button>
              <EditModal
                user={crmUser}
                isOpen={isUserEditOpen}
                onSuccess={async () => {
                  await loadAll(userId, setCrmUser, setRentalApplication, setActiveRental)
                  handleCloseUserEditModal()
                }}
                close={handleCloseUserEditModal}
              />
            </div>

            {isImpersonationAllowed && (
              <div style={{ marginRight: '3px' }}>
                <Button size='vvvs' onClick={() => doImpersonation(userId, admin.id, setErrorText)}>
                  Impersonate
                </Button>
              </div>
            )}

            {isChargingAllowed && crmUser && crmUser.has_payment_source && activeRental && (
              <div style={{ marginRight: '3px' }}>
                <Button size='vvvs' onClick={handleOpenChargeModal}>
                  Charge User
                </Button>
                <ChargeModal
                  user={crmUser}
                  isOpen={isChargeModalOpen}
                  onChargeSuccess={handleCloseChargeModal}
                  onCancel={handleCloseChargeModal}
                />
              </div>
            )}

            {isDeleteAllowed && !crmUser.deleted_at && (
              <div style={{ marginRight: '3px' }}>
                <Button
                  size='vvvs'
                  style={{ background: COLORS.ACCENTS.SALMON }}
                  onClick={() => {
                    // eslint-disable-next-line no-undef
                    const challenge = prompt(
                      `You are about to delete "${fullName}". Please enter their name to confirm deletion!`
                    )
                    if (challenge !== fullName) {
                      // eslint-disable-next-line no-undef
                      alert('You failed to enter the challenge properly, deletion ignored.')
                      return
                    }
                    console.log('yolo')
                    doDelete({
                      userId,
                      setErrorText,
                      setSuccessText,
                      setReloadDataAt
                    })
                  }}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={horizontal ? { width: '50%' } : {}}>
        <HeaderText style={{ marginBottom: '20px', marginTop: '20px' }} size='h5'>
          <img src={summarySrc} style={{ marginRight: '10px' }} />
          User Summary
        </HeaderText>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <Label>Phone Number</Label>
            <div>{crmUser.phone2 || 'N/A'}</div>
          </div>
          <div style={{ flex: 1 }}>
            <Label>Email</Label>
            <div>{crmUser.email2 || 'N/A'}</div>
          </div>
        </div>

        <div style={{ display: 'flex', marginTop: '20px' }}>
          {/* TODO: figure out how to get this back--need to store column in PG? or inject this into the response on serverside loading from firestore */}
          {/* <div style={{ flex: 1 }}>
            <Label>Region</Label>
            <div>{user.userRegionPreference || 'N/A'}</div>
          </div> */}

          {rentalApplication && (
            <div style={{ flex: 1 }}>
              <Label>Rental Application</Label>
              <div>
                <Link
                  to={`/applications/${rentalApplication.id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Link
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

UserInfo.propTypes = {
  horizontal: PropTypes.bool,
  userId: PropTypes.string.isRequired
}

export default UserInfo
