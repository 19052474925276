import React from 'react'
import { Box, IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

export const DotsMenuButton = ({ children }: { children: React.ReactNode }) => {
  return (
    <Menu>
      <MenuButton
        size='sm'
        as={IconButton}
        variant='ghost'
        icon={<EllipsisVerticalIcon height='20' />}
      />
      <MenuList>
        <Box px={2}>{children}</Box>
      </MenuList>
    </Menu>
  )
}
