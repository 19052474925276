import React from 'react'
import { Button, Icon } from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/20/solid'

const AddButton: React.FC<React.PropsWithChildren<{ disabled: boolean; onClick: () => void }>> = ({
  onClick,
  disabled
}) => {
  return (
    <Button
      disabled={disabled}
      colorScheme='teal'
      rightIcon={<Icon as={PlusIcon} />}
      onClick={onClick}
    >
      Add
    </Button>
  )
}

export default AddButton
