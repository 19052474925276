import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps
} from '@chakra-ui/react'

import { useSourceDetails } from '../../context'
import { SourceDetailsFieldName } from 'lib/user-incomes/types'

export interface BasicFormFieldProps {
  label: string
  fieldName: SourceDetailsFieldName
  isRequired?: boolean
  leftAddOn?: React.ReactNode
  inputProps?: InputProps
}

const BasicFormField = ({
  label,
  fieldName,
  isRequired = true,
  leftAddOn,
  inputProps = {}
}: BasicFormFieldProps) => {
  const context = useSourceDetails()

  if (!context) {
    return null
  }

  const { data, updateField, errors } = context

  const fieldValue = (data as any)[fieldName]
  const errorMessage = (errors as any)[fieldName]

  return (
    <FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        {leftAddOn && <InputLeftAddon>{leftAddOn}</InputLeftAddon>}
        <Input
          {...inputProps}
          value={fieldValue || ''}
          onChange={(e) => updateField(fieldName, e.target.value)}
        />
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default BasicFormField
