import React from 'react'
import { IconButton, useToast } from '@chakra-ui/react'
import { ClipboardDocumentListIcon } from '@heroicons/react/20/solid'

export const CopyIcon = ({ textToCopy }: { textToCopy: string }) => {
  const toast = useToast()

  return (
    <IconButton
      aria-label='Copy'
      variant='ghost'
      icon={<ClipboardDocumentListIcon className='h-4 w-4' />}
      onClick={() => {
        navigator.clipboard.writeText(textToCopy)

        toast({
          title: 'Copied!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'bottom-right'
        })
      }}
    />
  )
}
