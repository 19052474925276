import type { Reunderwrite } from '@homevest/types/rental-reunderwrites'
import type { ColDef } from 'ag-grid-community'
import startCase from 'lodash/startCase'
import format from 'date-fns/format'

const ADDRESS: ColDef<Reunderwrite> = {
  headerName: 'Address',
  valueGetter: ({ data }) => data?.rental_address,
  valueFormatter: ({ value, data }) => value + ' | ' + data?.rental_market_name
}

const TENANT: ColDef<Reunderwrite> = {
  headerName: 'Tenant',
  valueGetter: ({ data }) => data?.tenant_underwrites?.[0]?.user_name
}

const LEASE_END_DATE: ColDef<Reunderwrite> = {
  headerName: 'Lease End',
  valueGetter: (params) => params.data?.lease_end_date,
  valueFormatter: ({ value }) => {
    try {
      return format(new Date(value), 'MMM d, Y')
    } catch {
      return value
    }
  }
}

const CREATED_AT: ColDef<Reunderwrite> = {
  headerName: 'Created',
  valueGetter: ({ data }) => data?.created_at,
  valueFormatter: ({ value }) => {
    try {
      return format(new Date(value), 'MMM d, Y')
    } catch {
      return value
    }
  }
}

const STATUS: ColDef<Reunderwrite> = {
  headerName: 'Status',
  field: 'status',
  valueFormatter: ({ data }) => startCase(data?.status)
}

const READY_FOR_REVIEW: ColDef<Reunderwrite> = {
  headerName: 'Review Ready',
  valueGetter: ({ data }) => data?.status === 'all_tenants_submitted',
  valueFormatter: ({ value }) => (value ? '✅' : '❌')
}

export const REUNDERWRITE_COLUMNS = [
  ADDRESS,
  TENANT,
  LEASE_END_DATE,
  STATUS,
  CREATED_AT,
  READY_FOR_REVIEW
]
