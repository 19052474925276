import React from 'react'

import BasicPageLayout from 'components/TailwindUIToolkit/Layouts/BasicPageLayout'
import VendorsGrid from 'components/Vendors/Grid/VendorsGrid'

const Vendors = () => {
  return (
    <BasicPageLayout heading='Vendors' tabTitle='Vendors'>
      <VendorsGrid />
    </BasicPageLayout>
  )
}

export default Vendors
