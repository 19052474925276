import { Input, Box, Text, Select, Stack } from '@chakra-ui/react'
import { STATUS_MAP } from 'lib/document-manager'
import React, { useState, FunctionComponent, useEffect } from 'react'
import { FileInfo } from './BulkUploader'

export const BulkUploadDocumentOptions: FunctionComponent<
  React.PropsWithChildren<{
    file: File
    typeMap: { [key: string]: string }
    setFilesInfo: (fi: FileInfo) => void
  }>
> = ({ file, typeMap, setFilesInfo }) => {
  const nameWithoutFileExtension = file.name.substring(0, file.name.lastIndexOf('.')) || file.name
  const [docName, setDocName] = useState(nameWithoutFileExtension)
  const [docType, setDocType] = useState<string>('')
  const [docStatus, setDocStatus] = useState('final')

  useEffect(() => {
    const newFileInfo = {
      file: file,
      name: docName,
      type: docType,
      status: docStatus
    }
    setFilesInfo(newFileInfo)
  }, [docName, docType, docStatus])

  return (
    <Stack
      direction='column'
      borderWidth='1px'
      borderColor='gray.200'
      shadow='sm'
      rounded='md'
      p='4'
    >
      <Box>
        <Text color='gray.500'>File</Text>
        <Text>{file.name}</Text>
      </Box>
      <Box>
        <Text color='gray.500'>Document type</Text>
        <Select onChange={(event) => setDocType(event.target.value)} value={docType}>
          <option value='' disabled>
            Choose...
          </option>
          {Object.keys(typeMap).map((key) => (
            <option key={key} value={key}>
              {typeMap[key]}
            </option>
          ))}
        </Select>
      </Box>
      <Box>
        <Text color='gray.500'>Document name</Text>
        <Input type='text' value={docName} onChange={(event) => setDocName(event.target.value)} />
      </Box>
      <Box>
        <Text color='gray.500'>Document status</Text>
        <Select
          color='gray.900'
          onChange={(event) => setDocStatus(event.target.value)}
          value={docStatus}
        >
          {Object.keys(STATUS_MAP).map((key) => (
            <option key={key} value={key}>
              {STATUS_MAP[key as keyof typeof STATUS_MAP]}
            </option>
          ))}
        </Select>
      </Box>
    </Stack>
  )
}
