import React, { useState } from 'react'
import {
  FormControl,
  FormLabel,
  VStack,
  Input,
  Box,
  useToast,
  Spinner,
  Divider,
  Center,
  Button,
  Text
} from '@chakra-ui/react'

import axios from 'lib/axios'
import EditUtilityDetailsTable from './EditUtilityDetailsTable'
import AddUtilityTypeForm from './AddUtilityTypeForm'
import { UtilityDetailsByPortfolioHomeIdQuery } from 'graphql/generated'

type EditUtilityDetailsPropTypes = {
  portfolioHomeId: string
  utilityDetails: UtilityDetailsByPortfolioHomeIdQuery['portfolio_homes_by_pk']
  exitEditMode: () => void
}

type UtilityTypeConfig = Record<string, { defaultResponsibleParty: string }>

const EditUtilityDetails: React.FC<React.PropsWithChildren<EditUtilityDetailsPropTypes>> = ({
  portfolioHomeId,
  utilityDetails,
  exitEditMode
}) => {
  const initialUtilityTypeConfig = (utilityDetails?.portfolio_home_utilities ?? []).reduce(
    (acc, utility) => {
      acc[utility.utility_type.id] = {
        defaultResponsibleParty: utility.default_responsible_party
      }
      return acc
    },
    {} as UtilityTypeConfig
  )

  const [utilityTypeConfig, setUtilityTypeConfig] = useState(initialUtilityTypeConfig)
  const [conservicePropertyId, setConservicePropertyId] = useState(
    utilityDetails?.conservice_property_id ?? ''
  )
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const canSubmit = !!conservicePropertyId

  const assignedUtilityTypes = new Set(Object.keys(utilityTypeConfig))

  const handleSubmit = async () => {
    if (!canSubmit) {
      return
    }

    setIsLoading(true)
    try {
      const portfolioHomeUtilities = Object.entries(utilityTypeConfig).map(
        ([utilityTypeId, { defaultResponsibleParty }]) => ({
          utility_type_id: utilityTypeId,
          default_responsible_party: defaultResponsibleParty
        })
      )

      await axios.post(`/admin/portfolio_homes/${portfolioHomeId}/upsert_utilities_config`, {
        conservice_property_id: conservicePropertyId,
        portfolio_home_utilities: portfolioHomeUtilities
      })

      toast({
        title: 'Utilities configuration updated!',
        status: 'success',
        duration: 3000,
        position: 'top-right',
        isClosable: true
      })
      exitEditMode()
    } catch (err: any) {
      toast({
        title: 'Error updating utilities configuration!',
        description: err.message,
        status: 'error',
        duration: 3000,
        position: 'top-right',
        isClosable: true
      })
    }
    setIsLoading(false)
  }

  const addUtilityType = ({
    utilityTypeId,
    defaultResponsibleParty
  }: {
    utilityTypeId: string
    defaultResponsibleParty: string
  }) => {
    setUtilityTypeConfig({
      ...utilityTypeConfig,
      [utilityTypeId]: { defaultResponsibleParty }
    })
  }

  const removeUtilityType = (utilityTypeId: string) => {
    const newUtilityTypeConfig = {
      ...utilityTypeConfig
    }
    delete newUtilityTypeConfig[utilityTypeId]
    setUtilityTypeConfig(newUtilityTypeConfig)
  }

  const setResponsibleParty = (utilityTypeId: string, newResponsibleParty: string) => {
    setUtilityTypeConfig({
      ...utilityTypeConfig,
      [utilityTypeId]: {
        ...utilityTypeConfig[utilityTypeId],
        defaultResponsibleParty: newResponsibleParty
      }
    })
  }

  if (isLoading) {
    return (
      <Center>
        <Spinner margin={5} />
      </Center>
    )
  }

  return (
    <VStack padding={2}>
      <div className='ml-3 text-left text-lg font-semibold'>Configure Utilities</div>
      <Divider />
      <Text textAlign={'left'} as='i'>
        Edit existing utilities or add a new utility type below:
      </Text>
      <Box width='85%'>
        <EditUtilityDetailsTable
          utilityTypeConfig={utilityTypeConfig}
          removeUtilityType={removeUtilityType}
          setResponsibleParty={setResponsibleParty}
        />
      </Box>
      <Box width='85%' paddingTop={5}>
        <AddUtilityTypeForm
          addUtilityType={addUtilityType}
          assignedUtilityTypes={assignedUtilityTypes}
        />
      </Box>
      <FormControl width={'85%'} paddingTop={3} isRequired>
        <Divider padding={5} marginBottom={5} />
        <FormLabel htmlFor='conservice-id'>Conservice ID</FormLabel>
        <Input
          id='conservice-id'
          placeholder='Enter Conservice ID'
          value={conservicePropertyId}
          onChange={(e) => setConservicePropertyId(e.target.value.toLowerCase())}
        />
      </FormControl>
      <Box paddingTop={5} width='60%'>
        <Button width='100%' colorScheme='teal' onClick={handleSubmit} disabled={!canSubmit}>
          Submit
        </Button>
      </Box>
    </VStack>
  )
}

export default EditUtilityDetails
