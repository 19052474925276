import React from 'react'
import format from 'date-fns/format'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Textarea
} from '@chakra-ui/react'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import {
  ActionButton,
  ContentSectionCard,
  LeftAlignedDataList,
  TwoColumnDataList
} from 'components/TailwindUIToolkit'
import {
  useRaEscalationsByRaIdQuery,
  RaEscalationsByRaIdQuery,
  useResolveRentalAppEscalationMutation,
  Rental_Application_Manager_Escalation_Statuses_Enum
} from 'graphql/generated'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import capitalize from 'lodash/capitalize'
import { useSelector } from 'react-redux'
import { EscalateApplicationButton } from './EscalateApplicationButton'
import { combineFirstNameLastName } from 'lib/utils'

const AllEscalationDetails: React.FC<
  React.PropsWithChildren<{
    escalations: RaEscalationsByRaIdQuery['rental_application_manager_application_escalations']
  }>
> = ({ escalations }) => {
  return (
    <SC.StyledTable>
      <colgroup>
        <col width={'10%'} />
        <col width={'20%'} />
        <col width={'10%'} />
        <col width={'10%'} />
        <col width={'15%'} />
        <col width={'35%'} />
      </colgroup>
      <SC.StyledTableHeader>
        <tr>
          <SC.StyledTableHeadData>Status</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>Date created</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>Assigned by</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>Assigned to</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>Resolved at</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>Reason</SC.StyledTableHeadData>
        </tr>
      </SC.StyledTableHeader>
      <tbody>
        {escalations
          ? escalations.map((e) => {
              return (
                <SC.StyledTableRow key={e.id}>
                  <SC.StyledTableData>{capitalize(e.escalation_status)}</SC.StyledTableData>
                  <SC.StyledTableData>{format(new Date(e.created_at), 'Pp')}</SC.StyledTableData>
                  <SC.StyledTableData>
                    {combineFirstNameLastName(e.assigned_by_admin)}
                  </SC.StyledTableData>
                  <SC.StyledTableData>
                    {combineFirstNameLastName(e.assigned_to_admin)}
                  </SC.StyledTableData>
                  <SC.StyledTableData>
                    {Boolean(e.resolved_at) ? format(new Date(e.resolved_at), 'Pp') : null}
                  </SC.StyledTableData>
                  <SC.StyledTableData>{e.reason}</SC.StyledTableData>
                </SC.StyledTableRow>
              )
            })
          : null}
      </tbody>
    </SC.StyledTable>
  )
}

const RecentEscalationDetails: React.FC<
  React.PropsWithChildren<{
    escalations: RaEscalationsByRaIdQuery['rental_application_manager_application_escalations']
  }>
> = ({ escalations }) => {
  const recentEscalation = escalations[0]

  const twoColumnDisplayData = []
  const oneColumnDisplayData = []

  twoColumnDisplayData.push({
    label: 'Status',
    value: mapContentToBadge(recentEscalation.escalation_status)
  })
  recentEscalation.resolved_at
    ? twoColumnDisplayData.push({
        label: 'Resolution on',
        value: format(new Date(recentEscalation.resolved_at), 'PPpp')
      })
    : twoColumnDisplayData.push({
        label: 'Escalated on',
        value: format(new Date(recentEscalation.created_at), 'PPpp')
      })
  twoColumnDisplayData.push({
    label: 'Escalated to',
    value: combineFirstNameLastName(recentEscalation.assigned_to_admin)
  })
  twoColumnDisplayData.push({
    label: 'Escalated by',
    value: combineFirstNameLastName(recentEscalation.assigned_by_admin)
  })
  recentEscalation.resolution_note &&
    oneColumnDisplayData.push({
      label: 'Resolution note',
      value: recentEscalation.resolution_note
    })
  oneColumnDisplayData.push({
    label: 'Escalation note',
    value: recentEscalation.reason
  })
  return (
    <>
      <TwoColumnDataList data={twoColumnDisplayData} />
      <br />
      <LeftAlignedDataList data={oneColumnDisplayData} />
    </>
  )
}

// Wrapper with header and data query
const EscalateApplicationDetailsInternal: React.FC<
  React.PropsWithChildren<{
    rentalApplicationId: string
  }>
> = ({ rentalApplicationId }) => {
  const [isViewAll, setIsViewAll] = React.useState(false)
  const [resolutionReason, setResolutionReason] = React.useState('')
  const [isResolveModalOpen, setIsResolveModalOpen] = React.useState(false)

  const [{ data, error }] = useRaEscalationsByRaIdQuery({
    variables: { rentalApplicationId }
  })
  const [_, resolveRentalAppEscalation] = useResolveRentalAppEscalationMutation()

  const adminId = useSelector((state: any) => state.admin.id)

  let inner: React.ReactElement
  let resolvable = false
  if (error) {
    inner = <SC.MessageText>Something went wrong.</SC.MessageText>
  } else if ((data?.rental_application_manager_application_escalations?.length ?? 0) === 0) {
    inner = <SC.MessageText>No Escalations.</SC.MessageText>
  } else {
    const escalations = data!.rental_application_manager_application_escalations
    if (isViewAll) {
      inner = <AllEscalationDetails escalations={escalations} />
    } else {
      resolvable =
        escalations[0].escalation_status ===
        Rental_Application_Manager_Escalation_Statuses_Enum.Pending
      inner = <RecentEscalationDetails escalations={escalations} />
    }
  }
  return (
    <>
      <Modal isCentered isOpen={isResolveModalOpen} onClose={() => setIsResolveModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Resolution Note</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              value={resolutionReason}
              onChange={(e) => setResolutionReason(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='purple'
              mr={3}
              onClick={() => {
                resolveRentalAppEscalation({
                  rental_application_id: rentalApplicationId,
                  resolution_note: resolutionReason,
                  resolved_by_admin_id: adminId
                })
                setIsResolveModalOpen(false)
              }}
            >
              Resolve
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ContentSectionCard
        collapsable={false}
        padding={isViewAll ? false : true}
        title={isViewAll ? 'All Escalations' : 'Latest Escalation'}
        action={
          <SC.ButtonContainer>
            {resolvable ? (
              <ActionButton
                onClick={() => {
                  setIsResolveModalOpen(true)
                }}
              >
                Resolve
              </ActionButton>
            ) : (
              <EscalateApplicationButton rentalApplicationId={rentalApplicationId} showText />
            )}
            <ActionButton
              onClick={() => {
                setIsViewAll(!isViewAll)
              }}
            >
              {isViewAll ? 'Most recent' : 'View all'}
            </ActionButton>
          </SC.ButtonContainer>
        }
      >
        {inner}
      </ContentSectionCard>
    </>
  )
}

// Container to hold the react suspense
export const EscalateApplicationDetails: React.FC<
  React.PropsWithChildren<{
    rentalApplicationId: string
  }>
> = ({ rentalApplicationId }) => (
  <React.Suspense fallback={<SC.MessageText>Loading...</SC.MessageText>}>
    <EscalateApplicationDetailsInternal rentalApplicationId={rentalApplicationId} />
  </React.Suspense>
)
