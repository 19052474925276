import React, { useState } from 'react'
import ReactJson from 'react-json-view'
import { Text, Center, Spinner, Button, useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { GridApi, GridReadyEvent, ColDef, ICellRendererParams } from 'ag-grid-community'

import axios from 'lib/axios'
import { BaseGrid } from 'components/TailwindUIToolkit'
import { BASE_COLUMNS, TicketDeadLetterRow } from './configuration'
import { UnresolvedLatchelTicketDeadLettersQuery } from 'graphql/generated'

const Grid = ({
  data,
  isFetching,
  onRetrySync
}: {
  data: UnresolvedLatchelTicketDeadLettersQuery | undefined
  isFetching: boolean
  onRetrySync: () => void
}) => {
  const [gridApi, setGridApi] = useState<GridApi>()
  const toast = useToast()

  const onGridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api)
  }

  const { isLoading: isRetryingSync, mutateAsync: retrySyncForDeadLetterEntry } = useMutation({
    mutationFn: async (ticketsDeadLettersId: string) => {
      await axios.post(`/admin/latchel/retry_event`, {
        tickets_dead_letters_id: ticketsDeadLettersId
      })
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Sync retry successful!',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })
      onRetrySync()
    },
    onError: (error: any) => {
      toast({
        title: 'Error',
        description: `Sync retry failed: ${error?.message || ''}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })
    }
  })

  if (isFetching) {
    return (
      <Center>
        <Spinner size='lg' color='teal' />
      </Center>
    )
  }

  if (!data) {
    return <Text>All Latchel events have been properly synced!</Text>
  }

  return (
    <>
      <div className='h-[80vh] rounded border'>
        <BaseGrid
          rowHeight={125}
          onGridReady={onGridReady}
          rowData={data?.tickets_dead_letters ?? []}
          columns={[
            {
              headerName: 'Actions',
              maxWidth: 150,
              suppressSizeToFit: true,
              sortable: false,
              filter: false,
              cellRenderer: (params: ICellRendererParams<TicketDeadLetterRow>) => {
                return (
                  <Button
                    colorScheme={'teal'}
                    size={'sm'}
                    isLoading={isRetryingSync}
                    onClick={() => retrySyncForDeadLetterEntry(params.data?.id)}
                  >
                    Retry Sync
                  </Button>
                )
              },
              onCellClicked: (_params) => {
                console.log('clicky click')
                gridApi?.refreshCells()
              }
            } as ColDef<TicketDeadLetterRow>,
            ...BASE_COLUMNS,
            {
              colId: 'payload',
              headerName: 'Payload',
              field: 'payload',
              cellRenderer: (params: ICellRendererParams<TicketDeadLetterRow>) => {
                return (
                  <div className='m-3 w-full'>
                    <div className='max-h-32 w-full overflow-auto overscroll-contain p-3'>
                      <ReactJson
                        collapsed={true}
                        src={params.value}
                        displayDataTypes={false}
                        name='payload'
                        collapseStringsAfterLength={30}
                      />
                    </div>
                  </div>
                )
              },
              valueFormatter: (params) => JSON.stringify(params.value),
              flex: 3,
              autoHeight: true,
              wrapText: true
            } as ColDef<TicketDeadLetterRow>
          ]}
        />
      </div>
    </>
  )
}

export default Grid
