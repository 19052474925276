import { UserIncomeSourceSubmitPayload } from '@homevest/types/user-income-sources'
import axios from 'lib/axios'
import { SourceDetails } from 'lib/user-incomes/types'

export const createIncomeSource = async <
  T extends SourceDetails,
  V extends UserIncomeSourceSubmitPayload
>(
  userId: string,
  data: T,
  formatter: (data: T) => V
) => {
  const formattedData = formatter(data)

  const response = await axios.post<UserIncomeSourceSubmitPayload>(`/admin/user_income_source`, {
    data: formattedData,
    user_id: userId
  })

  return response.data
}
