import { useMutation } from '@tanstack/react-query'

import { useSourceDetails } from '../context'
import { SourceDetails, ValidatorFunc } from 'lib/user-incomes/types'
import { createIncomeSource } from 'lib/user-incomes/create'
import { UserIncomeSourceSubmitPayload } from '@homevest/types/user-income-sources'

export const useSubmitIncomeSourceMutation = <
  DataType extends SourceDetails,
  PayloadType extends UserIncomeSourceSubmitPayload
>(
  userId: string,
  formatFn: (data: DataType) => PayloadType,
  validateFn: ValidatorFunc,
  onSubmit: () => void
) => {
  const context = useSourceDetails()
  const { isLoading: isSubmitting, mutate: submitSourceDetails } = useMutation({
    mutationFn: async (data: DataType) => {
      await createIncomeSource(userId, data, formatFn)
    },
    onSuccess: () => {
      onSubmit()
      // TODO: Invalidate and refetch the GET user_income request
    }
  })

  if (!context) {
    return {
      isSubmitting: false,
      handleSubmit: () => {}
    }
  }

  const { data, validate } = context

  const handleSubmit = async () => {
    const { isValid } = validate(validateFn)

    if (isValid) {
      submitSourceDetails(data as DataType)
    }
  }

  return {
    isSubmitting,
    handleSubmit
  }
}
