import React, { memo } from 'react'
import PropTypes from 'prop-types'
import axios from 'lib/axios'

import { ErrorText, HeaderText, BodyText } from 'components/Toolkit'
import { Button } from '@chakra-ui/react'

function Lease({ completedAgreement }) {
  if (!completedAgreement.document) {
    return (
      <ErrorText>
        The completed agreement currently has no document. Engineering must backfill.
      </ErrorText>
    )
  }

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '20px',
        marginTop: '20px',
        backgroundColor: '#fff'
      }}
    >
      <HeaderText size='h3'>Completed Agreement</HeaderText>
      <BodyText>
        <Button
          variant='link'
          textColor='black'
          fontWeight='medium'
          onClick={async () => {
            const res = await axios.get(`/admin/documents/${completedAgreement.document.id}/url`)

            if (res.data.url) {
              window.open(res.data.url, '_blank')
            }
          }}
        >
          Lease
        </Button>
      </BodyText>
    </div>
  )
}

Lease.propTypes = {
  completedAgreement: PropTypes.object.isRequired
}

export default memo(Lease)
