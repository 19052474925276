import React, { memo } from 'react'
import PropTypes from 'prop-types'

function LeftChevron({ color = '#302654', height = '17px', width = '11px' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 11 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.1392 1.38337C10.4898 1.71798 10.4901 2.27758 10.1399 2.61257L4.85303 7.66891C4.3795 8.12179 4.3795 8.8782 4.85303 9.33109L10.1399 14.3874C10.4901 14.7224 10.4898 15.282 10.1392 15.6166L9.49371 16.2326C9.16527 16.5461 8.64849 16.5461 8.32005 16.2326L0.861626 9.11492C0.510734 8.78006 0.510734 8.21994 0.861626 7.88508L8.32005 0.767367C8.64849 0.45393 9.16527 0.453929 9.49371 0.767367L10.1392 1.38337Z'
        fill={color}
        stroke='white'
        strokeWidth='0.3'
      />
    </svg>
  )
}

LeftChevron.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default memo(LeftChevron)
