import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
`
const StyledTab = styled.div`
  padding-bottom: 20px;
  margin-left: ${(props) => (!props.isFirst ? '70px' : '0')};
  border-bottom: ${(props) => (props.isSelected ? '3px solid #00CFCC' : '0')};
  color: ${(props) => (props.isSelected ? ' #302654' : '#85807A')};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
`

function Tabs({ options, value, onChange, userId }) {
  const history = useHistory()
  const handleOnClick = useCallback(
    (userId, tab) => history.push(`/users/${userId}/${tab.toLowerCase()}`),
    [history]
  )

  return (
    <StyledContainer>
      {options.map((option, idx) => (
        <StyledTab
          isFirst={idx === 0}
          isSelected={value.toLowerCase() === option.value.toLowerCase()}
          key={option.value}
          onClick={() => {
            onChange(option.value)
            handleOnClick(userId, option.value)
          }}
        >
          {option.label}
        </StyledTab>
      ))}
    </StyledContainer>
  )
}

Tabs.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
}

export default Tabs
