// map renewal months to percent increase over previous rent
export const RENEWAL_RENT_INCREASES: {
  [key: number | string]: { increase: number; label: string }
} = {
  1: { increase: 1.22, label: '1 month' },
  6: { increase: 1.12, label: '6 months' },
  12: { increase: 1.08, label: '1 year' },
  0: { increase: 1, label: 'Other' }
} as const

export const calculateRenewalRentIncrease = (rent: number, newTerm: number): number => {
  if (!RENEWAL_RENT_INCREASES[newTerm]) {
    return rent
  }

  const { increase } = RENEWAL_RENT_INCREASES[newTerm]

  // Round rent to nearest multiple of 5
  return Math.round((rent * increase) / 5) * 5
}
