import React, { FunctionComponent, Suspense, useState } from 'react'
import { Spinner } from 'reactstrap'
import { HStack, Input, Link } from '@chakra-ui/react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import {
  useLatchelPropertyIdByPortfolioHomeIdQuery,
  useUpdateLatchelPropertyIdByPortfolioHomeIdMutation
} from 'graphql/generated'
import type { OperationContext } from 'urql'
import { ActionButton, ContentSectionCard } from 'components/TailwindUIToolkit'

const EditButtons: FunctionComponent<
  React.PropsWithChildren<{
    portfolioHomeId: string
    latchelPropertyId: number | undefined
    isEditing: boolean
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
    latchelPropertyIdInput: number | undefined
    setLatchelPropertyIdInput: React.Dispatch<React.SetStateAction<number | undefined>>
    refetchLatchelPropertyId: (opts?: Partial<OperationContext> | undefined) => void
  }>
> = ({
  portfolioHomeId,
  latchelPropertyId,
  isEditing,
  setIsEditing,
  latchelPropertyIdInput,
  setLatchelPropertyIdInput,
  refetchLatchelPropertyId
}) => {
  const [{ fetching }, updateLatchelPropertyId] =
    useUpdateLatchelPropertyIdByPortfolioHomeIdMutation()

  return (
    <HStack>
      {!isEditing && <ActionButton onClick={() => setIsEditing((x) => !x)}>Edit</ActionButton>}
      {isEditing && (
        <>
          <ActionButton
            disabled={fetching || !latchelPropertyIdInput}
            onClick={() => {
              updateLatchelPropertyId({
                portfolioHomeId,
                latchelPropertyId: latchelPropertyIdInput!
              })
              refetchLatchelPropertyId({ requestPolicy: 'network-only' })
              setIsEditing((x) => !x)
            }}
            colorScheme={isEditing ? 'secondary' : undefined}
          >
            Submit
          </ActionButton>
          <ActionButton
            onClick={() => {
              setIsEditing((x) => !x)
              setLatchelPropertyIdInput(latchelPropertyId)
            }}
          >
            Cancel
          </ActionButton>
        </>
      )}
    </HStack>
  )
}

const DisplayLatchelProperty: FunctionComponent<
  React.PropsWithChildren<{
    latchelPropertyId: number | undefined
    isLoading: boolean
  }>
> = ({ latchelPropertyId, isLoading }) => {
  const latchelLink = `https://app.latchel.com/admin/property/${latchelPropertyId}`
  return (
    <HStack className='p-4'>
      <div className='font-bold'>Latchel Property ID:</div>
      {isLoading ? (
        <Spinner size='sm' />
      ) : (
        <Link className='flex flex-row items-center' href={latchelLink} isExternal>
          {latchelPropertyId !== undefined ? (
            <>
              {latchelPropertyId}
              <ArrowTopRightOnSquareIcon className='ml-1 h-3 w-3 align-middle' />
            </>
          ) : (
            'Not set'
          )}
        </Link>
      )}
    </HStack>
  )
}

const EditLatchelProperty: FunctionComponent<
  React.PropsWithChildren<{
    latchelPropertyIdInput: number | undefined
    setLatchelPropertyIdInput: React.Dispatch<React.SetStateAction<number | undefined>>
    isLoading: boolean
  }>
> = ({ latchelPropertyIdInput, setLatchelPropertyIdInput, isLoading }) => {
  return (
    <HStack className='px-4 py-3'>
      <div className='font-bold'>Latchel Property ID:</div>
      {isLoading ? (
        <Spinner size='sm' />
      ) : (
        <Input
          w='25%'
          type='number'
          value={latchelPropertyIdInput}
          onChange={(e) => setLatchelPropertyIdInput(Number(e.target.value))}
        />
      )}
    </HStack>
  )
}

const LatchelProperty: FunctionComponent<
  React.PropsWithChildren<{
    latchelPropertyId: number | undefined
    latchelPropertyIdInput: number | undefined
    setLatchelPropertyIdInput: React.Dispatch<React.SetStateAction<number | undefined>>
    isEditing: boolean
    isLoading: boolean
  }>
> = ({
  latchelPropertyId,
  latchelPropertyIdInput,
  setLatchelPropertyIdInput,
  isEditing,
  isLoading
}) => {
  return (
    <>
      {isEditing ? (
        <EditLatchelProperty
          latchelPropertyIdInput={latchelPropertyIdInput}
          setLatchelPropertyIdInput={setLatchelPropertyIdInput}
          isLoading={isLoading}
        />
      ) : (
        <DisplayLatchelProperty latchelPropertyId={latchelPropertyId} isLoading={isLoading} />
      )}
    </>
  )
}
const LatchelCard: FunctionComponent<React.PropsWithChildren<{ portfolioHomeId: string }>> = ({
  portfolioHomeId
}) => {
  const [{ data, fetching, error }, refetchLatchelPropertyId] =
    useLatchelPropertyIdByPortfolioHomeIdQuery({
      variables: { portfolioHomeId }
    })
  const latchelPropertyId = data?.portfolio_homes_by_pk?.latchel_property_id ?? undefined
  const [latchelPropertyIdInput, setLatchelPropertyIdInput] = useState(latchelPropertyId)
  const [isEditing, setIsEditing] = useState(false)

  const isLoading = !!fetching || !!error

  return (
    <ContentSectionCard
      title='Latchel'
      action={
        <EditButtons
          {...{
            portfolioHomeId,
            latchelPropertyId,
            isEditing,
            setIsEditing,
            latchelPropertyIdInput,
            setLatchelPropertyIdInput,
            refetchLatchelPropertyId
          }}
        />
      }
    >
      <Suspense fallback={<Spinner />}>
        <LatchelProperty
          {...{
            latchelPropertyId,
            latchelPropertyIdInput,
            setLatchelPropertyIdInput,
            isEditing,
            isLoading
          }}
        />
      </Suspense>
    </ContentSectionCard>
  )
}

export default LatchelCard
