/* eslint-disable camelcase */
export const isEqual = <T>(val: T): { _eq: T } => ({ _eq: val })
export const isNotEqual = <T>(val: T): { _neq: T } => ({ _neq: val })

export const isIn = (val: any[]): { _in: any } => ({ _in: val })
export const isNotIn = (val: any[]): { _nin: any } => ({ _nin: val })

export const isLike = (
  val: string,
  caseSensitive = false
): { _like: string } | { _ilike: string } => (caseSensitive ? { _like: val } : { _ilike: val })
export const isNotLike = (
  val: string,
  caseSensitive = false
): { _nlike: string } | { _nilike: string } => (caseSensitive ? { _nlike: val } : { _nilike: val })

export const isNull = (): { _is_null: true } => ({ _is_null: true })
export const isNotNull = (): { _is_null: false } => ({ _is_null: false })

export const Comparators = {
  isEqual,
  isNotEqual,
  isIn,
  isNotIn,
  isLike,
  isNotLike,
  isNull,
  isNotNull
}
