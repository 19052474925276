import { QualificationDetails } from '@homevest/types/rental-applications'
import { rentalApplications, rentals } from '@homevest/utils'

const { getDepositMultipleForRentalProgramType } = rentalApplications
const { RENTAL_PROGRAM_TYPES } = rentals

export const UNDERWRITING_RECOMMENDATION_SUMMARIES = {
  APPROVE: 'approve',
  APPROVE_WITH_2X_DEPOSIT: 'approve_with_2x_deposit',
  APPROVE_WITH_3X_DEPOSIT: 'approve_with_3x_deposit',
  REJECT: 'reject',
  MANUAL_REVIEW: 'manual_review'
} as const

export const computeVirtualRecommendationSummary = (
  recommendation: Pick<
    QualificationDetails,
    | 'is_approved'
    | 'flags'
    | 'deposit_rent_multiple'
    | 'disqualification_reasons'
    | 'rental_program_type'
  >
) => {
  if (!recommendation.is_approved) {
    if (recommendation.disqualification_reasons.length) {
      return UNDERWRITING_RECOMMENDATION_SUMMARIES.REJECT
    }

    if (recommendation.flags.length > 0) {
      return UNDERWRITING_RECOMMENDATION_SUMMARIES.MANUAL_REVIEW
    }

    return UNDERWRITING_RECOMMENDATION_SUMMARIES.REJECT
  }

  const { rental_program_type: rentalProgramType, deposit_rent_multiple: depositRentMultiple } =
    recommendation
  const { standardDepositMultiple, additionalDepositMultiple } =
    getDepositMultipleForRentalProgramType(rentalProgramType)

  if (depositRentMultiple === standardDepositMultiple) {
    return UNDERWRITING_RECOMMENDATION_SUMMARIES.APPROVE
  } else if (recommendation.deposit_rent_multiple === additionalDepositMultiple) {
    return rentalProgramType === RENTAL_PROGRAM_TYPES.VANILLA
      ? UNDERWRITING_RECOMMENDATION_SUMMARIES.APPROVE_WITH_2X_DEPOSIT
      : UNDERWRITING_RECOMMENDATION_SUMMARIES.APPROVE_WITH_3X_DEPOSIT
  }

  throw new Error('Invalid deposit rent multiple!')
}

export const getMaxRentAtDepositMultiple = (
  recommendation: {
    max_rents: { max_rent: number | null; deposit_rent_multiple: number }[]
  },
  depositRentMultiple: number
) => {
  const standardDepositRecord = recommendation.max_rents.find(
    (rent) => rent.deposit_rent_multiple === depositRentMultiple
  )
  return standardDepositRecord ? standardDepositRecord.max_rent : null
}

export const getAdditionalIncomeNeededAtDepositMultiple = (
  recommendation: {
    additional_income_needed: {
      additional_income_needed: number | null
      deposit_rent_multiple: number
    }[]
  },
  depositRentMultiple: number
) => {
  const standardDepositRecord = recommendation.additional_income_needed.find(
    (ain) => ain.deposit_rent_multiple === depositRentMultiple
  )
  return standardDepositRecord ? standardDepositRecord.additional_income_needed : null
}
