"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LATCHEL_OPERATION_TYPES = exports.INACTIVE_LATCHEL_STATUSES = exports.ORDERED_LATCHEL_STATUSES = exports.LATCHEL_STATUSES = void 0;
exports.LATCHEL_STATUSES = {
    AWAITING_ESTIMATE: 'Awaiting Estimate',
    AWAITING_INVOICE: 'Awaiting Invoice',
    AWAITING_WORK_DATE: 'Awaiting Work Date',
    CANCELLED: 'Cancelled',
    COMPLETED: 'Completed',
    CONFIRMING_COMPLETION: 'Confirming Completion',
    FINDING_SERVICE_PROVIDER: 'Finding Service Provider',
    FORWARDED_TO_PM: 'Forwarded to PM',
    NEEDS_APPROVAL: 'Needs Approval',
    PENDING: 'Pending',
    RESCHEDULING_RESIDENT: 'Rescheduling Resident',
    SCHEDULING_RESIDENT: 'Scheduling Resident',
    SCHEDULING_SERVICE_PROVIDER: 'Scheduling Service Provider',
    WAITING_FOR_MATERIALS: 'Waiting for Materials'
};
exports.ORDERED_LATCHEL_STATUSES = [
    exports.LATCHEL_STATUSES.PENDING,
    exports.LATCHEL_STATUSES.FINDING_SERVICE_PROVIDER,
    exports.LATCHEL_STATUSES.SCHEDULING_SERVICE_PROVIDER,
    exports.LATCHEL_STATUSES.AWAITING_WORK_DATE,
    exports.LATCHEL_STATUSES.SCHEDULING_RESIDENT,
    exports.LATCHEL_STATUSES.RESCHEDULING_RESIDENT,
    exports.LATCHEL_STATUSES.WAITING_FOR_MATERIALS,
    exports.LATCHEL_STATUSES.AWAITING_ESTIMATE,
    exports.LATCHEL_STATUSES.NEEDS_APPROVAL,
    exports.LATCHEL_STATUSES.CONFIRMING_COMPLETION,
    exports.LATCHEL_STATUSES.AWAITING_INVOICE,
    exports.LATCHEL_STATUSES.FORWARDED_TO_PM,
    exports.LATCHEL_STATUSES.COMPLETED,
    exports.LATCHEL_STATUSES.CANCELLED
];
exports.INACTIVE_LATCHEL_STATUSES = [
    exports.LATCHEL_STATUSES.COMPLETED,
    exports.LATCHEL_STATUSES.CANCELLED
];
exports.LATCHEL_OPERATION_TYPES = {
    CREATED: 'created',
    UPDATED: 'updated'
};
