import React, { FunctionComponent } from 'react'
import { ArrowDown, ArrowUp } from '@styled-icons/heroicons-outline'
import { Icon } from '@chakra-ui/react'
import { ActionButton } from 'components/TailwindUIToolkit'

const SortButton: FunctionComponent<
  React.PropsWithChildren<{
    onClick: () => void
    reverse: boolean
  }>
> = ({ onClick, reverse }) => {
  return (
    <ActionButton type='button' onClick={onClick}>
      <Icon h='4' w='4' as={reverse ? ArrowUp : ArrowDown} />
      Sort
    </ActionButton>
  )
}

export default SortButton
