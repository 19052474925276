import React, { useState, createContext, useContext, FunctionComponent, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { User as FirebaseUser, getAuth } from 'firebase/auth'
import { firebaseApp } from 'lib/firebase'

export type AuthContextProps = {
  loading: boolean
  firebaseUser?: FirebaseUser | null
}

export const AuthContext = createContext<AuthContextProps>({ loading: true })

export const AuthProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null)

  useEffect(() => {
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged(
      (user) => {
        setFirebaseUser(user)
        setLoading(false)
      },
      (err) => {
        Sentry.captureException(err)
        setLoading(false)
      }
    )
    return unsubscribe
  }, [])

  return <AuthContext.Provider value={{ loading, firebaseUser }}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => useContext(AuthContext)
