import React from 'react'

import { Stack, Heading, Text } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { startCase } from 'lodash'

import { CHECK_RANGE_IN_DAYS } from 'constants/rental-site-checker'

const ChecksTableHeader: React.FC<
  React.PropsWithChildren<{
    tableType: 'successful' | 'failed' | 'muted'
    checkCount: number
  }>
> = ({ tableType, checkCount }) => {
  const tableTypeDisplay = startCase(tableType)
  return (
    <Stack spacing={5}>
      <Heading as='h2' size='xl' width='100%'>
        {tableTypeDisplay} Checks
      </Heading>
      <Text as='i' size='md' width='100%'>
        {checkCount} {tableTypeDisplay} Rental Site Checks From Last {CHECK_RANGE_IN_DAYS}{' '}
        {pluralize('Day', CHECK_RANGE_IN_DAYS)}
      </Text>
    </Stack>
  )
}

export default ChecksTableHeader
