import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import isDateBeforeRentRollLockDate from 'lib/utils'
import { rentRollLedgerLockDate } from 'lib/config'
import { Button, ErrorText } from 'components/Toolkit'
import {
  Rental_Liabilities_Insert_Input,
  useCreateRentalLiabilitiesMutation
} from 'graphql/generated'
import LiabilityInputRow from './LiabilityInputRow'
import { Divider } from '@chakra-ui/react'
import Big from 'big.js'
import { RentalDetailsContext } from '../RentalDetailsContext'

CreateRentalLiabilityForm.propTypes = {
  onSubmit: PropTypes.func,
  rentalId: PropTypes.string.isRequired
}
// function that takes a liability with start date, number of months and amount and
// creates multiple liabilities with the amount split evenly over each month
// starting on the start date
const splitLiability = (liability: Rental_Liabilities_Insert_Input, months: number) => {
  const startDate = liability.date
  const amount = liability.amount
  const monthlyAmount = Number(Big(amount).div(months).toFixed(2))
  const liabilities = []
  // keep track of how much has been allocated so far
  // if the last amount is more than the remaining amount, use the remaining amount
  // otherwise use the monthly amount
  let remainingToAllocate = Big(amount)

  for (let i = 0; i < months; i++) {
    let amountToAllocate = Math.min(monthlyAmount, remainingToAllocate.toNumber())
    remainingToAllocate = remainingToAllocate.minus(amountToAllocate)
    let note = `#PAYMENT_PLAN ${i + 1}/${months} $${amountToAllocate} of $${amount}`
    if (liability.note) {
      note += ` - ${liability.note}`
    }

    liabilities.push({
      ...liability,
      date: moment(startDate).add(i, 'months').format('YYYY-MM-DD'),
      amount: amountToAllocate,
      note
    })
  }
  return liabilities
}

const SummarySection: React.FC<
  React.PropsWithChildren<{
    liabilites: Rental_Liabilities_Insert_Input[]
    typeMap: Record<string, { name: string }>
  }>
> = ({ liabilites, typeMap }) => {
  const total = liabilites.reduce((acc, liability) => acc + liability.amount, 0)
  // create a summary by liability type name
  const summary = liabilites.reduce((acc, liability) => {
    const liabilityTypeId = liability.liability_type_id
    if (liabilityTypeId) {
      if (acc[liabilityTypeId]) {
        acc[liabilityTypeId] += liability.amount
      } else {
        acc[liabilityTypeId] = liability.amount
      }
    }
    return acc
  }, {} as { [key: string]: number })

  return (
    <div>
      <div>Total: ${total}</div>
      <ul>
        {Object.keys(summary).map((liabilityTypeId) => {
          const liabilityType = typeMap[liabilityTypeId]
          return (
            <li key={liabilityTypeId}>
              {liabilityType.name}: ${summary[liabilityTypeId]}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default function CreateRentalLiabilityForm({
  onSubmit = () => {},
  rentalId,
  occupancyDate,
  finalLiabilityDate
}: {
  onSubmit: () => void
  rentalId: string
  occupancyDate: string
  finalLiabilityDate?: string
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [newLiabilities, setNewLiabilities] = useState<Rental_Liabilities_Insert_Input[]>([])

  // fetch all liability types
  let { liabilityTypes, rentalData } = useContext(RentalDetailsContext)

  const noteRequired =
    rentalData?.status === undefined || !['complete', 'canceled'].includes(rentalData.status)
  const typeMap = useMemo(() => {
    return liabilityTypes.reduce((acc, type) => {
      acc[type.id] = type
      return acc
    }, {} as Record<string, { name: string }>)
    return {}
  }, [liabilityTypes])

  const [{ fetching, error }, createRentalLiabilities] = useCreateRentalLiabilitiesMutation()

  const isOccupancyDateValidCheck = (date: string) =>
    occupancyDate !== null &&
    moment(occupancyDate).isValid() &&
    moment(date).isSameOrAfter(occupancyDate)

  const isOccupancyDateValid = !newLiabilities.some(
    (liability) => !isOccupancyDateValidCheck(liability.date)
  )

  const isLedgerLockedCheck = (date: string) => isDateBeforeRentRollLockDate(date)

  const isLedgerLocked = newLiabilities.some((liability) => isLedgerLockedCheck(liability.date))
  const isValid = (date: string) =>
    isOccupancyDateValidCheck(date) &&
    !isLedgerLockedCheck(date) &&
    moment(date).isSameOrAfter(moment().startOf('day'))

  const saveLiabilities = useCallback(() => {
    setIsSaving(true)
    createRentalLiabilities({
      liabilities: newLiabilities.map((liability) => ({
        ...liability,
        rental_id: rentalId
      }))
    })
      .then(() => {
        setIsSaving(false)
        onSubmit()
      })
      .catch(() => {
        setIsSaving(false)
      })
  }, [onSubmit, newLiabilities, createRentalLiabilities, rentalId])

  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        {newLiabilities.map((liability, index) => (
          <LiabilityInputRow
            noteRequired={noteRequired}
            key={index}
            canEdit={false}
            onDelete={(ind) => {
              setNewLiabilities(newLiabilities.filter((_, i) => i !== ind))
            }}
            index={index}
            isDateValid={isValid}
            {...liability}
          />
        ))}
        <Divider />
        <LiabilityInputRow
          key={newLiabilities.length}
          noteRequired={noteRequired}
          finalLiabilityDate={finalLiabilityDate}
          canEdit={true}
          onDelete={(ind) => {
            setNewLiabilities(newLiabilities.filter((_, i) => i !== ind))
          }}
          index={newLiabilities.length}
          isDateValid={isValid}
          onSubmit={(liability) => {
            if (liability.paymentPlanMonths) {
              const months = liability.paymentPlanMonths
              delete liability.paymentPlanMonths
              const splitLiabilities = splitLiability(liability, months)
              setNewLiabilities([...newLiabilities, ...splitLiabilities])
            } else {
              setNewLiabilities([...newLiabilities, liability])
            }
          }}
        />
        <Divider />
        <SummarySection liabilites={newLiabilities} typeMap={typeMap} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            isSecondary={false}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={saveLiabilities}
            disabled={!isValid || fetching || isSaving || !newLiabilities.length}
          >
            Create {newLiabilities.length} Rental Liabilit
            {newLiabilities.length > 1 ? 'ies' : 'y'}
          </Button>
          {error && <ErrorText>{error.message}</ErrorText>}
          {!isOccupancyDateValid && (
            <ErrorText>
              The occupancy date {occupancyDate} is not valid. No liabilities can be created.
            </ErrorText>
          )}
          {isLedgerLocked && (
            <ErrorText>
              Updates to rent-roll before {rentRollLedgerLockDate} is not allowed. FinOps is doing a
              Rent Roll Audit. Please reach out to: Sam Maniscalco and/or Jered Griffith for more
              details.
            </ErrorText>
          )}
        </div>
      </React.Suspense>
    </>
  )
}
