import React, { useState } from 'react'
import { Center, Spinner } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import QueryString from 'qs'
import { GridApi } from 'ag-grid-community'
import { OccupancyInspectionBulkApiResponse } from '@homevest/types/occupancy-inspections'
import { occupancyInspections } from '@homevest/utils'

import axios from 'lib/axios'
import BasicPageLayout from 'components/TailwindUIToolkit/Layouts/BasicPageLayout'
import OccupancyInspectionsGrid from './Grid'

const { OCCUPANCY_INSPECTION_STATUSES } = occupancyInspections

const OccupancyInspections = () => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const statusesToShow = Object.values(OCCUPANCY_INSPECTION_STATUSES)

  const {
    data,
    isLoading,
    refetch: refetchOccupancyInspections
  } = useQuery({
    queryKey: ['getOccupancyInspections'],
    queryFn: async () => {
      const params = { status: statusesToShow }
      const response = await axios.get<OccupancyInspectionBulkApiResponse>(
        'admin/occupancy_inspections',
        {
          params,
          paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' })
        }
      )
      return response.data?.data
    }
  })

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <BasicPageLayout tabTitle='Occupancy Inspections' heading='Occupancy Inspections'>
      <div className='p-4'>
        <OccupancyInspectionsGrid
          data={data}
          refetchOccupancyInspections={refetchOccupancyInspections}
          statusesToShow={statusesToShow}
          gridApi={gridApi}
          setGridApi={setGridApi}
        />
      </div>
    </BasicPageLayout>
  )
}

export default OccupancyInspections
