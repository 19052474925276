import React, { FunctionComponent } from 'react'
import { Spinner } from '@chakra-ui/react'
import { first } from 'lodash'
import { ContentSectionCard, LinkButton, TwoColumnDataList } from 'components/TailwindUIToolkit'
import { useMarketingDetailsByPropertyIdQuery } from 'graphql/generated'
import { formatMoney } from 'lib/numbers'
import { formatNullableDateString } from 'lib/date-time'
import Error from 'components/Toolkit/Text/Error'

type PropsType = {
  portfolioHomeId: string
}

const Marketing: FunctionComponent<React.PropsWithChildren<PropsType>> = ({ portfolioHomeId }) => {
  const [{ data, error }] = useMarketingDetailsByPropertyIdQuery({
    variables: { portfolioHomeId }
  })

  if (error) {
    return <Error>{error.message}</Error>
  }

  const marketingData = data?.portfolio_homes_by_pk?.real_estate_acquisition?.mls_listing

  const listedRent = data?.portfolio_homes_by_pk?.listed_rents[0]?.listed_rent

  if (!marketingData) {
    return <Error>data not found</Error>
  }

  const dataList = [
    {
      label: '`Up&Up` Url',
      value: <a href={first(marketingData?.internal_attributes)?.url || ''}>www.upandup.co</a>
    },
    {
      label: 'Rent ready date',
      value: formatNullableDateString(first(marketingData.rent_ready_dates)?.earliest_move_in_date)
    },
    {
      label: 'Marketing status',
      value: first(marketingData.internal_attributes)?.internal_status
    },
    {
      label: 'Listed rent',
      value: formatMoney(listedRent, 2, '$')
    },
    {
      label: 'Photos',
      value: (
        <LinkButton href={`/properties/mls-photo-manager/${marketingData.id}`}>
          Manage Photos
        </LinkButton>
      )
    }
  ]

  return <TwoColumnDataList data={dataList} />
}

const MarketingWrapper: FunctionComponent<React.PropsWithChildren<PropsType>> = (props) => {
  return (
    <ContentSectionCard title='Marketing' padding>
      <React.Suspense fallback={<Spinner />}>
        <Marketing {...props} />
      </React.Suspense>
    </ContentSectionCard>
  )
}

export default MarketingWrapper
