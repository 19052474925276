import { UpUpUserTimelineByUserIdQuery } from 'graphql/generated'
import {
  SMSClose,
  SMSCustomerIO,
  TourEvent,
  RentalSiteInquiry,
  VoiceClose,
  EmailClose,
  EmailCustomerIO,
  RentalApplicationEvent,
  Interaction
} from 'types/Timeline'

const getFormattedToursFromQuery = (query: UpUpUserTimelineByUserIdQuery): TourEvent[] => {
  const leadGroupUsers = query.users[0]?.lead_group_users
  const toursArrays = leadGroupUsers.map((u) => u.lead_group.tours)
  const tours = toursArrays.flat(1)
  const formattedTours: TourEvent[] = []

  for (const tour of tours) {
    if (!tour) continue

    formattedTours.push(new TourEvent(tour, 'scheduled'))

    if (tour.canceled_at) {
      formattedTours.push(new TourEvent(tour, 'cancelled'))
    }

    if (tour.lead_group_confirmed_at) {
      formattedTours.push(new TourEvent(tour, 'confirmed'))
    }

    if (tour.completed_at) {
      formattedTours.push(new TourEvent(tour, 'completed'))
    }
  }

  return formattedTours
}

const getFormattedSiteInquiriesFromQuery = (
  query: UpUpUserTimelineByUserIdQuery
): RentalSiteInquiry[] => {
  const inquiries = query.users[0]?.rental_site_leads

  if (!inquiries) {
    return []
  }
  const formattedInquiries: RentalSiteInquiry[] = inquiries.map(
    (inquiry) => new RentalSiteInquiry(inquiry)
  )
  return formattedInquiries
}

const getFormattedSMSCloseFromQuery = (query: UpUpUserTimelineByUserIdQuery): SMSClose[] => {
  const conversations = query.users[0]?.conversations
  if (!conversations) return []
  const smsArray = conversations.filter(
    (c) =>
      c.medium === 'sms' &&
      c.external_source === 'close' &&
      c.status !== 'draft' && // don't want to include drafts
      c.status !== 'error' // or errors
  )
  const formattedSMSArray = smsArray.map((sms) => new SMSClose(sms))

  return formattedSMSArray
}

const getFormattedSMSCustomerIOFromQuery = (
  query: UpUpUserTimelineByUserIdQuery
): SMSCustomerIO[] => {
  const conversations = query.users[0]?.conversations
  if (!conversations) {
    return []
  }
  const smsArray = conversations?.filter(
    (c) => c.medium === 'sms' && c.external_source === 'customerio' && c.status === 'sent'
    // customerio seems to create a 'sent' and a 'delivered' event
    // so only look at the 'sent' ones to reduce noise in timeline
  )
  const formattedSMSArray = smsArray.map((sms) => new SMSCustomerIO(sms))

  return formattedSMSArray
}

const getFormattedEmailCloseFromQuery = (query: UpUpUserTimelineByUserIdQuery): EmailClose[] => {
  const conversations = query.users[0]?.conversations
  if (!conversations) {
    return []
  }
  const emailArray = conversations.filter(
    (c) => c.medium === 'email' && c.external_source === 'close' && c.status !== 'draft' // don't want to include drafts
  )
  const formattedEmailArray = emailArray.map((email) => new EmailClose(email))

  return formattedEmailArray
}

const getFormattedEmailCustomerIOFromQuery = (
  query: UpUpUserTimelineByUserIdQuery
): EmailCustomerIO[] => {
  const conversations = query.users[0]?.conversations
  if (!conversations) {
    return []
  }
  const emailArray = conversations.filter(
    (c) => c.medium === 'email' && c.external_source === 'customerio'
  )
  const formattedEmailArray = emailArray.map((email) => new EmailCustomerIO(email))

  return formattedEmailArray
}

const getFormattedVoiceCloseFromQuery = (query: UpUpUserTimelineByUserIdQuery): VoiceClose[] => {
  const conversations = query.users[0]?.conversations
  if (!conversations) {
    return []
  }
  const voiceArray = conversations?.filter(
    (c) => c.medium === 'voice' && c.external_source === 'close'
  )
  const formattedVoiceArray = voiceArray.map((voice) => new VoiceClose(voice))

  return formattedVoiceArray
}

const getFormattedRentalApplicationEventsFromQuery = (
  query: UpUpUserTimelineByUserIdQuery
): RentalApplicationEvent[] => {
  const associatedLGUs = query.users[0].lead_group_users
  const applicationArrays = associatedLGUs.map((u) => u.lead_group.rental_applications)
  const applications = applicationArrays.flat(1)
  const applicationEvents: RentalApplicationEvent[] = []

  for (const application of applications) {
    if (!application) {
      continue
    }

    applicationEvents.push(new RentalApplicationEvent(application, 'created'))

    if (application.underwritten_at) {
      applicationEvents.push(new RentalApplicationEvent(application, 'underwritten'))
    }
    if (application.completed_at) {
      applicationEvents.push(new RentalApplicationEvent(application, 'completed'))
    }

    if (application.canceled_at) {
      applicationEvents.push(new RentalApplicationEvent(application, 'cancelled'))
    }
  }

  return applicationEvents
}

// this array is a good indicator of all event types that
// are registered to show up in the timeline
const FORMATTERS: readonly ((query: UpUpUserTimelineByUserIdQuery) => Interaction[])[] =
  Object.freeze([
    getFormattedSiteInquiriesFromQuery,
    getFormattedToursFromQuery,
    getFormattedSMSCloseFromQuery,
    getFormattedSMSCustomerIOFromQuery,
    getFormattedEmailCloseFromQuery,
    getFormattedEmailCustomerIOFromQuery,
    getFormattedVoiceCloseFromQuery,
    getFormattedRentalApplicationEventsFromQuery
  ])

// this function is for comparison for sorting. will take two SortableActions, which
// makes sure that the happenedAt field exists on the object and then compare their
// dates to decide which goes before the other
const compareFn = (interaction1: Interaction, interaction2: Interaction) => {
  const d1 = new Date(interaction1.happenedAt)
  const d2 = new Date(interaction2.happenedAt)
  return d1 < d2 ? -1 : 1
}

export const buildTimeline = (
  query: UpUpUserTimelineByUserIdQuery,
  ascending?: boolean
): Interaction[] => {
  const formattedInteractions: Interaction[][] = FORMATTERS.map((fn) => fn(query))

  const timeline: Interaction[] = formattedInteractions.flat(1)

  timeline.sort(compareFn)

  if (!ascending) {
    timeline.reverse()
  }

  return timeline
}
