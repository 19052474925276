import React from 'react'

import { Box, Button, IconButton } from '@chakra-ui/react'
import { EscalateApplicationModal } from './EscalateApplicationModal'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

export const EscalateApplicationButtonInternal: React.FC<
  React.PropsWithChildren<{
    rentalApplicationId: string
    showText?: boolean
  }>
> = ({ rentalApplicationId, showText = false }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <>
      <EscalateApplicationModal
        rentalApplicationId={rentalApplicationId}
        isOpen={isOpen}
        setOpen={setIsOpen}
      />
      {showText ? (
        <Button
          size='sm'
          onClick={() => setIsOpen(true)}
          aria-label={'Escalate'}
          variant='outline'
          leftIcon={
            <Box h={5} w={5} color='gray.500'>
              <ExclamationTriangleIcon />
            </Box>
          }
        >
          Escalate
        </Button>
      ) : (
        <IconButton
          size='sm'
          onClick={() => setIsOpen(true)}
          aria-label={'Escalate'}
          variant='outline'
          icon={
            <Box h={5} w={5} color='gray.500'>
              <ExclamationTriangleIcon />
            </Box>
          }
        />
      )}
    </>
  )
}

export const EscalateApplicationButton: React.FC<
  React.PropsWithChildren<{
    rentalApplicationId: string
    showText?: boolean
  }>
> = ({ rentalApplicationId, showText = false }) => (
  <React.Suspense fallback={<></>}>
    <EscalateApplicationButtonInternal
      rentalApplicationId={rentalApplicationId}
      showText={showText}
    />
  </React.Suspense>
)
