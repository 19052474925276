import React from 'react'
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react'
import { OccupancyInspectionApiResponse } from '@homevest/types/occupancy-inspections'

import { formatUTCDate, formatDateWithTime } from 'lib/date-time'
import { formatProjectTitle } from 'lib/construction-projects'

const PanelBox = ({ title, value }: { title: string; value?: string | null }) => (
  <Box mb={4}>
    <strong>{title}:</strong>
    <span className='ml-2'>{value}</span>
  </Box>
)

const previousOccupancyInspection = (data: OccupancyInspectionApiResponse) => {
  if (!data.previous_occupancy_inspection) {
    return <>No Previous Occupancy Inspection!</>
  }
  const prevOi = data.previous_occupancy_inspection

  return (
    <>
      <div className='text-teal-600'>
        <PanelBox
          title={'Project'}
          value={formatProjectTitle(
            prevOi.project.type,
            prevOi.project.address,
            prevOi.project.start_date
          )}
        />
      </div>
      <PanelBox title={'Status'} value={prevOi.status} />
      <PanelBox title={'Result'} value={prevOi.result} />
      <PanelBox title={'Inspection At'} value={formatDateWithTime(prevOi.inspection_at)} />
      <PanelBox
        title={'Certificate Effective Date'}
        value={formatUTCDate(prevOi.certificate_effective_date, 'MM/dd/yy')}
      />
      <PanelBox
        title={'Certificate Expiration Date'}
        value={formatUTCDate(prevOi.certificate_expiration_date, 'MM/dd/yy')}
      />
      <PanelBox
        title={'Municipality Contacted At'}
        value={formatDateWithTime(prevOi.municipality_contacted_at)}
      />
      <PanelBox
        title={'Application Submitted At'}
        value={formatDateWithTime(prevOi.application_submitted_at)}
      />
      <PanelBox
        title={'Payment Submitted At'}
        value={formatDateWithTime(prevOi.payment_submitted_at)}
      />
      <PanelBox
        title={'Payment Confirmed At'}
        value={formatDateWithTime(prevOi.payment_confirmed_at)}
      />
      <PanelBox
        title={'Occupant Signature Deadline'}
        value={formatUTCDate(prevOi.occupant_signature_deadline, 'MM/dd/yy')}
      />
      <PanelBox
        title={'Occupant Signature At'}
        value={formatDateWithTime(prevOi.occupant_signature_at)}
      />
    </>
  )
}

const OccupancyInspectionDetails = ({ data }: { data: OccupancyInspectionApiResponse }) => {
  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left' fontWeight='bold' fontSize='larger'>
              OI
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={5}>
          <PanelBox title={'Status'} value={data.status} />
          <PanelBox title={'Result'} value={data.result} />
          <PanelBox title={'Created At'} value={formatDateWithTime(data.created_at)} />
          <PanelBox title={'Updated At'} value={formatDateWithTime(data.updated_at)} />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left' fontWeight='bold' fontSize='larger'>
              Dates
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={5}>
          <PanelBox title={'Inspection At'} value={formatDateWithTime(data.inspection_at)} />
          <PanelBox
            title={'Certificate Effective Date'}
            value={formatUTCDate(data.certificate_effective_date, 'MM/dd/yy')}
          />
          <PanelBox
            title={'Certificate Expiration Date'}
            value={formatUTCDate(data.certificate_expiration_date, 'MM/dd/yy')}
          />
          <PanelBox
            title={'Municipality Contacted At'}
            value={formatDateWithTime(data.municipality_contacted_at)}
          />
          <PanelBox
            title={'Application Submitted At'}
            value={formatDateWithTime(data.application_submitted_at)}
          />
          <PanelBox
            title={'Payment Submitted At'}
            value={formatDateWithTime(data.payment_submitted_at)}
          />
          <PanelBox
            title={'Payment Confirmed At'}
            value={formatDateWithTime(data.payment_confirmed_at)}
          />
          <PanelBox
            title={'Occupant Signature Deadline'}
            value={formatUTCDate(data.occupant_signature_deadline, 'MM/dd/yy')}
          />
          <PanelBox
            title={'Occupant Signature At'}
            value={formatDateWithTime(data.occupant_signature_at)}
          />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left' fontWeight='bold' fontSize='larger'>
              Municipality
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={5}>
          <PanelBox title={'Name'} value={data.municipality?.name} />
          <PanelBox
            title={'Primary Contact Name'}
            value={data.primary_municipality_contact?.name}
          />
          <PanelBox
            title={'Primary Contact Type'}
            value={data.primary_municipality_contact?.type}
          />
          <PanelBox
            title={'Primary Contact Email'}
            value={data.primary_municipality_contact?.email}
          />
          <PanelBox
            title={'Primary Contact Phone'}
            value={data.primary_municipality_contact?.phone}
          />
          <PanelBox
            title={'Primary Contact Category'}
            value={data.primary_municipality_contact?.category}
          />
          <PanelBox
            title={'Municipality Config Username'}
            value={data.municipality_config?.username}
          />
          <PanelBox
            title={'Municipality Config Password'}
            value={data.municipality_config?.password}
          />
          <PanelBox
            title={'Municipality Config Requirements'}
            value={data.municipality_config?.requirements}
          />
          <PanelBox title={'Municipality Config Steps'} value={data.municipality_config?.steps} />
          <PanelBox title={'Municipality Config URL'} value={data.municipality_config?.url} />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left' fontWeight='bold' fontSize='larger'>
              Previous OI
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={2}>{previousOccupancyInspection(data)}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default OccupancyInspectionDetails
