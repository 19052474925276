import React from 'react'

const Wordmark = () => (
  <svg width='66' height='22' viewBox='0 0 66 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.6162 17.6757C32.452 17.6757 33.9303 16.7697 35.2414 15.363L37.1489 17.3657H40.8441L37.0296 13.3843L39.3421 10.8332V7.25693L35.3129 11.5961L32.4998 8.66358L33.2388 8.11526C34.4307 7.2093 35.6228 5.96948 35.6228 4.30067C35.6228 1.77338 33.6441 0.10437 30.9978 0.10437C28.8041 0.10437 26.4677 1.63026 26.4677 4.4199C26.4677 5.99342 27.5883 7.30464 28.399 8.13903L28.685 8.44904L28.518 8.56824C26.7539 9.87945 25.5855 11.0716 25.5855 13.2412C25.5855 15.4586 27.3498 17.6757 30.6162 17.6757ZM30.7832 6.89939L30.3063 6.37482C29.7101 5.73115 29.1856 5.06344 29.1856 4.25289C29.1856 3.03714 30.1393 2.44098 31.0214 2.44098C32.1182 2.44098 32.905 3.25148 32.905 4.25289C32.905 4.89658 32.6429 5.51649 31.7607 6.18412L30.7832 6.89939ZM33.501 13.5511L33.4773 13.575C32.6188 14.5048 31.7844 15.1724 30.6637 15.1724C29.2812 15.1724 28.4227 14.0996 28.4227 13.0503C28.4227 11.9538 28.995 11.2622 29.8057 10.6664L30.3777 10.2372L33.501 13.5511Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.857 21.8955V5.75495H15.5272V7.32846C16.0995 6.32714 17.4347 5.49268 19.2229 5.49268C22.656 5.49268 24.587 8.11529 24.587 11.5483C24.587 15.0294 22.4651 17.6519 19.1273 17.6519C17.4586 17.6519 16.1948 16.9366 15.5989 16.0546V21.8955H12.857ZM18.6984 7.94846C16.8863 7.94846 15.575 9.3789 15.575 11.5483C15.575 13.7657 16.8863 15.1962 18.6984 15.1962C20.534 15.1962 21.8214 13.7657 21.8214 11.5483C21.8214 9.3789 20.5579 7.94846 18.6984 7.94846Z'
      fill='currentColor'
    />
    <path
      d='M4.77995 17.6996C2.01442 17.6996 0.393188 15.6492 0.393188 13.0743V5.7549H3.15872V12.5736C3.15872 14.0041 3.82632 15.2201 5.49511 15.2201C7.09244 15.2201 7.92706 14.1471 7.92706 12.6214V5.7549H10.6926V15.2439C10.6926 16.1977 10.764 16.9365 10.8118 17.3657H8.16552C8.11774 17.1036 8.07018 16.5551 8.07018 16.0784C7.49792 17.1274 6.13882 17.6996 4.77995 17.6996Z'
      fill='currentColor'
    />
    <path
      d='M45.4831 17.6996C42.7178 17.6996 41.0966 15.6492 41.0966 13.0743V5.7549H43.8621V12.5736C43.8621 14.0041 44.5295 15.2201 46.1985 15.2201C47.7958 15.2201 48.6302 14.1471 48.6302 12.6214V5.7549H51.396V15.2439C51.396 16.1977 51.4674 16.9365 51.5152 17.3657H48.8689C48.8211 17.1036 48.7733 16.5551 48.7733 16.0784C48.2011 17.1274 46.8422 17.6996 45.4831 17.6996Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M53.5604 5.75495V21.8955H56.3023V16.0546C56.8982 16.9366 58.1619 17.6519 59.831 17.6519C63.1685 17.6519 65.2906 15.0294 65.2906 11.5483C65.2906 8.11529 63.3594 5.49268 59.9261 5.49268C58.1381 5.49268 56.8028 6.32714 56.2308 7.32846V5.75495H53.5604ZM56.2784 11.5483C56.2784 9.3789 57.5897 7.94846 59.4016 7.94846C61.2613 7.94846 62.525 9.3789 62.525 11.5483C62.525 13.7657 61.2376 15.1962 59.4016 15.1962C57.5897 15.1962 56.2784 13.7657 56.2784 11.5483Z'
      fill='currentColor'
    />
  </svg>
)

export default Wordmark
