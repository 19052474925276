import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  FunctionComponent,
  useMemo
} from 'react'
import { Provider } from 'urql'
import * as Sentry from '@sentry/react'
import axios from 'lib/axios'
import { useAuthContext } from './auth'
import { createUrqlClient } from 'lib/urql-client'

export type UrqlContextProps = {
  loading: boolean
  urqlToken?: string | null
}

export const UrqlContext = createContext<UrqlContextProps>({ loading: true })

export const UrqlProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { loading: firebaseLoading, firebaseUser } = useAuthContext()
  const [loading, setLoading] = useState<boolean>(true)
  const [urqlToken, setUrqlToken] = React.useState<string | null>(null)

  const client = useMemo(() => createUrqlClient(urqlToken), [urqlToken])

  useEffect(() => {
    if (!firebaseLoading) {
      if (firebaseUser) {
        getHasuraCreds((token) => setUrqlToken(token))
      } else {
        setUrqlToken(null)
      }
      setLoading(false)
    }
  }, [firebaseLoading, firebaseUser])

  return (
    <UrqlContext.Provider value={{ loading, urqlToken }}>
      <Provider value={client}>{children}</Provider>
    </UrqlContext.Provider>
  )
}

export const useUrqlContext = () => useContext(UrqlContext)

const getHasuraCreds = async (cb: (token: string | null) => void): Promise<void> => {
  try {
    const response = await axios.post<any, any>('/hasura/auth', {})
    const {
      data: { credentials }
    } = response

    cb(credentials)
  } catch (err: any) {
    if (err.isAxiosError && err.response.status === 401) {
      if (
        window.confirm(
          `We're sorry but your token is no longer valid, do you want to reload and login again?`
        )
      ) {
        window.location.reload()
      }
    } else {
      Sentry.captureException(err)
      cb(null)
    }
  }
}
