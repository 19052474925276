export const UPDATED_ALL_PROPERTIES_SEARCH_STATE = 'UPDATED_ALL_PROPERTIES_SEARCH_STATE'
export const UPDATED_AUTOMATED_REVIEW_SEARCH_STATE = 'UPDATED_AUTOMATED_REVIEW_SEARCH_STATE'

export const updatedAllPropertiesSearchState = (searchState) => ({
  type: UPDATED_ALL_PROPERTIES_SEARCH_STATE,
  searchState
})

export const updatedAutomatedReviewSearchState = (searchState) => ({
  type: UPDATED_AUTOMATED_REVIEW_SEARCH_STATE,
  searchState
})
