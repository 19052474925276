import React, { memo } from 'react'
import styled from 'styled-components'

const StyledLabel = styled.label`
  color: #55545a;
  opactiy: 0.75;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`

function LabelText(props) {
  return (
    <div>
      <StyledLabel {...props} />
    </div>
  )
}

export default memo(LabelText)
