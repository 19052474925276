import React, { useContext } from 'react'
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItemOption,
  MenuOptionGroup
} from '@chakra-ui/react'
import { Upup_LiabilityTypesQuery } from 'graphql/generated'
import { RentalDetailsContext } from '../RentalDetailsContext'

export const LiabilityTypesSelect = ({
  selectedLiabilityTypeIds,
  onChange,
  filter
}: {
  selectedLiabilityTypeIds: string[]
  onChange: (lt: string[]) => void
  filter?: (lt: Upup_LiabilityTypesQuery['liability_types'][number]) => boolean
}) => {
  let { liabilityTypes } = useContext(RentalDetailsContext)

  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} colorScheme='blackAlpha'>
        Liability Types
      </MenuButton>
      <MenuList width='fit-content' maxHeight='md' overflowY='auto'>
        <MenuOptionGroup
          title='Liability Types'
          type='checkbox'
          value={selectedLiabilityTypeIds}
          onChange={(ltIds) => onChange(ltIds as string[])}
        >
          {liabilityTypes.map((lt) => (
            <MenuItemOption key={lt.id} value={lt.id} isDisabled={filter ? !filter(lt) : false}>
              {lt.name}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
