import React from 'react'
import PropTypes from 'prop-types'

import CreditReportDetails from './CreditReportDetails'
import ReportHeader from '../ReportHeader'

export default function CreditReport({ creditReport }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ReportHeader text='Credit Report Data' />
      <CreditReportDetails creditReport={creditReport} />
    </div>
  )
}

CreditReport.propTypes = {
  creditReport: PropTypes.object
}
