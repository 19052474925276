import {
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Skeleton,
  Alert,
  AlertIcon,
  Heading
} from '@chakra-ui/react'
import { formatMoney } from 'lib/numbers'
import React from 'react'
import { useRentalAppQualification } from './useRentalAppQualification'

export const ApplicantSummaryPanel = ({ rentalApplicationId }: { rentalApplicationId: string }) => {
  const { data, isFetching, error } = useRentalAppQualification(rentalApplicationId)

  if (isFetching) {
    return <Skeleton height='100px' />
  }

  if (!data) {
    const errorMessage = error
      ? ((error as any)?.message as string) || JSON.stringify(error)
      : 'Underwriting details not loaded'

    return (
      <Alert status='error' variant='subtle'>
        <AlertIcon />
        Error loading underwriting details: {errorMessage}
      </Alert>
    )
  }

  const { applicants } = data
  return (
    <Stack>
      <Heading size='md'>Applicants</Heading>
      <Table>
        <Thead>
          <Tr>
            <Th>Applicant</Th>
            <Th>Ann. Income</Th>
            <Th>Credit</Th>
            <Th>Criminal</Th>
            <Th>Eviction</Th>
          </Tr>
        </Thead>
        <Tbody>
          {applicants.map((applicant) => {
            const nonIncomeApplicantDecor = applicant.is_non_income_applicant
              ? 'line-through'
              : undefined
            return (
              <Tr key={applicant.user_id}>
                <Td>{applicant.name}</Td>
                <Td textDecor={nonIncomeApplicantDecor} textDecorationThickness='2px'>
                  {applicant.gross_annual_income
                    ? formatMoney(applicant.gross_annual_income, 2, '$')
                    : '-'}
                </Td>
                <Td
                  color={creditScoreColor(applicant.credit_score)}
                  textDecor={nonIncomeApplicantDecor}
                  textDecorationThickness='2px'
                >
                  {applicant.credit_score ?? '❓'}
                </Td>
                <Td>{applicant.criminal_check_clear ? '✅' : '🟧'}</Td>
                <Td>{applicant.eviction_check_clear ? '✅' : '🟧'}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Stack>
  )
}

const creditScoreColor = (creditScore: number | null) => {
  if (!creditScore) {
    return undefined
  }

  if (creditScore >= 620) {
    return 'green'
  }

  if (creditScore >= 600) {
    return 'orange'
  }

  return 'red'
}
