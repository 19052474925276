import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import axios from 'lib/axios'
import CrmField from 'components/CrmField'

const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #302654;
  margin-top: 50px;
`

async function getFields(setFields) {
  const userRes = await axios.get('/fields')
  setFields(userRes.data)
}

async function getCrmUser(userId, setCrmUser) {
  const userRes = await axios.get(`/admin/users/${userId}`)
  setCrmUser(userRes.data)
}

async function updateField(userId, fieldId, value) {
  await axios.post(`/users/${userId}/fields/${fieldId}`, {
    value
  })
}

function User({ userId, onlyFieldLabels = null }) {
  const [fields, setFields] = useState([])
  const [crmUser, setCrmUser] = useState(null)

  useEffect(() => {
    getFields(setFields)
    getCrmUser(userId, setCrmUser)
  }, [setFields, setCrmUser, userId])

  if (!crmUser) {
    return (
      <StyledLoading>
        <span>Loading...</span>
      </StyledLoading>
    )
  }

  return (
    <div>
      {fields
        .filter((field) => !onlyFieldLabels || onlyFieldLabels.includes(field.label))
        .map((item) => (
          <div key={item.id} style={{ marginTop: '20px' }}>
            <CrmField
              label={item.label}
              value={crmUser.fields[item.label] === undefined ? null : crmUser.fields[item.label]}
              fieldValueType={item.details.value_type}
              fieldType={item.details.type}
              options={item.options.map((option) => ({
                ...option,
                value: option.id
              }))}
              onChange={(val) => {
                updateField(userId, item.id, val)
              }}
            />
          </div>
        ))}
    </div>
  )
}

User.propTypes = {
  onlyFieldLabels: PropTypes.array,
  userId: PropTypes.string.isRequired
}

export default User
