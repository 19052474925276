import React, { FunctionComponent } from 'react'
import { types } from '@homevest/timelines'
import {
  INTERNAL_PROPERTY_PAGE_SLUG,
  RENTLY_PROPERTY_PAGE_SLUG,
  REMATCHING_TOOL_PAGE_SLUG
} from 'constants/urls'
import { buildZillowUrlFromAddress } from 'lib/build-urls'
import { SC } from 'components/TailwindUIToolkit'

export const PropertyDetails: FunctionComponent<
  React.PropsWithChildren<{
    details?: types.HomeDetails
    shouldHideHeading?: boolean
    applicationRent?: string | null
  }>
> = ({ details, shouldHideHeading = false, applicationRent }) => {
  if (!details) {
    return <SC.ErrorMessage>Could not find property details</SC.ErrorMessage>
  }
  const {
    listedRent,
    address,
    city,
    state,
    zip,
    beds,
    baths,
    sqft,
    market,
    firestoreId,
    publicListingUrl
  } = details

  const links: { display: string; href: string }[] = [
    {
      display: 'Rently Listing',
      href: `${RENTLY_PROPERTY_PAGE_SLUG}${firestoreId}`
    },
    {
      display: 'Zillow Listing',
      href: `${buildZillowUrlFromAddress(address, city, state, zip)}`
    },
    {
      display: 'Rematching Tool',
      href: `${REMATCHING_TOOL_PAGE_SLUG}${details.mlsListingId}`
    },
    {
      display: 'Internal Hudson Listing',
      href: `${INTERNAL_PROPERTY_PAGE_SLUG}${firestoreId}`
    }
  ]

  if (publicListingUrl) {
    links.push({
      display: 'External Up&Up Listing',
      href: publicListingUrl
    })
  }

  return (
    <SC.Subsection>
      {shouldHideHeading ? null : <SC.EventSubheading>Property</SC.EventSubheading>}
      {/* rental applications should show the application rent rather than listed rent if there is one */}
      <SC.BoldMessageText>
        ${!!applicationRent ? applicationRent : !!listedRent ? listedRent : null}
        /mo
      </SC.BoldMessageText>
      <SC.MessageText>
        {address}, {city}, {state}, {zip}
      </SC.MessageText>
      <SC.DescriptionText>
        {beds ? `${beds} Beds ` : null} {baths ? `${baths} Baths ` : null}{' '}
        {sqft ? (
          <>
            {sqft} ft<sup>2</sup>
          </>
        ) : null}
      </SC.DescriptionText>
      {market ? <SC.GrayBadge children={market} /> : null}
      <SC.Subsection>
        <SC.EventSubheading>Links</SC.EventSubheading>
        {/* property listing links */}

        {links.map((link, i) => {
          return (
            <SC.DescriptionText key={i}>
              <SC.StyledLink href={link.href}>{link.display}</SC.StyledLink>
            </SC.DescriptionText>
          )
        })}
      </SC.Subsection>
    </SC.Subsection>
  )
}
