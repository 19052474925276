import React, { useState } from 'react'
import { useMutation } from 'urql'
import { useSelector } from 'react-redux'
import { capabilities, rentals } from '@homevest/utils'
import { ErrorText } from 'components/Toolkit'

import { hasCapability } from 'lib/admin-perms'
import { Upup_SetAutoChargeDocument as AutoCharge } from 'graphql/generated'
import { StoreState } from 'store'

const { CUSTOMER_CHARGER } = capabilities.CAPABILITY_TYPES
const { RENTAL_STATUS } = rentals

export default function AutoChargeCheckbox({
  rental
}: {
  // TODO: make a fragment for this
  rental: Partial<{
    id: string
    auto_charge: boolean | null
    status: string | null
  }>
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [isAutoChargeEnabled, setIsAutoChargeEnabled] = useState(!!rental.auto_charge)
  const [{ fetching, error }, setAutoCharge] = useMutation(AutoCharge)
  const isCharger = useSelector<StoreState, boolean>((store) =>
    hasCapability(store.admin, CUSTOMER_CHARGER)
  )

  const isDisabled =
    ![RENTAL_STATUS.ACTIVE, RENTAL_STATUS.PENDING].some((s) => s === rental.status) ||
    !isCharger ||
    isSaving ||
    fetching

  const updateAutoCharge = async (autoCharge: boolean) => {
    if (isDisabled) {
      return
    }
    setIsSaving(true)

    setIsAutoChargeEnabled(autoCharge)
    await setAutoCharge({ auto_charge: autoCharge, id: rental.id })

    setIsSaving(false)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <input
        type='checkbox'
        checked={isAutoChargeEnabled}
        onChange={(e) => updateAutoCharge(e.target.checked)}
        disabled={isDisabled}
      />
      {error && <ErrorText>{error.message}</ErrorText>}
    </div>
  )
}
