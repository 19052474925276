import React, { useState } from 'react'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { EllipsisHorizontal } from 'styled-icons/heroicons-solid'

import CancelAgreementModal from './CancelAgreementModal'

const OtherActionsMenu: React.FC<React.PropsWithChildren<{ pendingAgreementId: string }>> = ({
  pendingAgreementId
}) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  return (
    <>
      <Menu>
        <MenuButton size='sm' as={Button}>
          <EllipsisHorizontal size={20} />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => setIsCancelModalOpen(true)}>Cancel</MenuItem>
        </MenuList>
      </Menu>
      <CancelAgreementModal
        pendingAgreementId={pendingAgreementId}
        isOpen={isCancelModalOpen}
        close={() => setIsCancelModalOpen(false)}
      />
    </>
  )
}

export default OtherActionsMenu
