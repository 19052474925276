import startCase from 'lodash/startCase'
import React, { useState, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import { Button, Stack, Heading, Tooltip } from '@chakra-ui/react'
import { capabilities, rentalApplications } from '@homevest/utils'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { useActiveRentalApplicationDecisionByRentalApplicationIdQuery } from 'graphql/generated'
import { hasCapability } from 'lib/admin-perms'
import { ApprovalModal } from './ApprovalModal'
import RejectModal from './RejectModal'

const { CAPABILITY_TYPES } = capabilities

const DecisionPanel = ({
  rentalApplicationId,
  rentalApplicationStatus
}: {
  rentalApplicationId: string
  rentalApplicationStatus: string
}) => {
  const [isApproveModalOpen, setIsApprovedModalOpen] = useState(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
  const [{ data, error }, refetchDecision] =
    useActiveRentalApplicationDecisionByRentalApplicationIdQuery({
      variables: { rentalApplicationId }
    })

  const admin = useSelector((store: StoreState) => store.admin)
  const canUnderwrite = hasCapability(admin, CAPABILITY_TYPES.APPLICATION_UNDERWRITER)

  let decisionBadge: ReactNode
  let decisionDetails = error?.message ?? ''
  let isUndecided = true
  let isApproved = false
  if (data) {
    const { rental_application_decisions } = data
    if (rental_application_decisions.length === 1) {
      const {
        admin: { first_name, last_name },
        decided_at,
        deposit_rent_multiple,
        is_approved,
        rejection_reason
      } = rental_application_decisions[0]
      const decision = is_approved
        ? `Approved (${deposit_rent_multiple}x deposit)`
        : `Rejected (${startCase(rejection_reason ?? '')})`

      decisionBadge = mapContentToBadge(decision, {
        colorOverride: is_approved ? 'Green' : 'Red',
        size: 'base',
        dot: true,
        formatFn: (x) => x
      })
      decisionDetails = `by ${first_name} ${last_name} on ${new Date(
        decided_at
      ).toLocaleDateString()}`
      isUndecided = false
      isApproved = is_approved ? true : false
    } else if (rental_application_decisions.length === 0) {
      decisionBadge = mapContentToBadge('Undecided', { size: 'base', dot: true })
      isUndecided = true
    } else {
      decisionBadge = mapContentToBadge('Unknown', {
        colorOverride: 'Red',
        size: 'base',
        dot: true
      })
      decisionDetails = 'More than one active decision - contact Engineering'
      isUndecided = true
    }
  }

  const isCompleteOrCanceled = [
    rentalApplications.RENTAL_APPLICATION_STATUSES.COMPLETE,
    rentalApplications.RENTAL_APPLICATION_STATUSES.CANCELED
  ].includes(rentalApplicationStatus as any)

  return (
    <>
      <Stack spacing={3}>
        <div className='flex flex-row-reverse items-center justify-between gap-3'>
          <Stack>
            <div className='w-fit'>{decisionBadge}</div>
            <div className='mt-1 text-right text-sm text-neutral-600'>{decisionDetails}</div>
          </Stack>
          <Stack>
            <Heading size='md'>Decision</Heading>
            {!isCompleteOrCanceled && (
              <div className='flex flex-row gap-2'>
                <Tooltip
                  isDisabled={canUnderwrite}
                  label='You must have the "Application Underwriter" capability to approve an application'
                >
                  <Button
                    colorScheme='teal'
                    variant='outline'
                    size='sm'
                    onClick={() => setIsApprovedModalOpen(true)}
                    disabled={!canUnderwrite || (!isUndecided && isApproved)}
                  >
                    Approve
                  </Button>
                </Tooltip>
                <Tooltip
                  isDisabled={canUnderwrite}
                  label='You must have the "Application Underwriter" capability to reject an application'
                >
                  <Button
                    colorScheme='red'
                    variant='outline'
                    size='sm'
                    onClick={() => setIsRejectModalOpen(true)}
                    disabled={!canUnderwrite || (!isUndecided && !isApproved)}
                  >
                    Reject
                  </Button>
                </Tooltip>
              </div>
            )}
          </Stack>
        </div>
      </Stack>
      <ApprovalModal
        rentalApplicationId={rentalApplicationId}
        isOpen={isApproveModalOpen}
        onClose={() => {
          refetchDecision({ requestPolicy: 'network-only' })
          setIsApprovedModalOpen(false)
        }}
      />
      <RejectModal
        rentalApplicationId={rentalApplicationId}
        isOpen={isRejectModalOpen}
        onClose={() => {
          refetchDecision({ requestPolicy: 'network-only' })
          setIsRejectModalOpen(false)
        }}
      />
    </>
  )
}

export default DecisionPanel
