import React from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  VStack,
  Divider,
  Button,
  Spacer,
  HStack,
  useToast
} from '@chakra-ui/react'

import axios from 'lib/axios'

interface CancelPendingRenewalModalProps {
  pendingRenewalId: string
  isOpen: boolean
  close: () => void
}

const CancelPendingRenewalModal: React.FC<
  React.PropsWithChildren<CancelPendingRenewalModalProps>
> = ({ pendingRenewalId, isOpen, close }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const toast = useToast()

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await axios.post(`/admin/renewals/${pendingRenewalId}/cancel`)
    } catch (error) {
      toast({
        title: 'Error cancelling pending renewal!',
        description:
          'There was an error cancelling the pending renewal for this rental. Please contact Engineering for support.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    toast({
      title: 'Pending renewal cancelled!',
      description: 'The pending renewal for this rental has been successfully cancelled.',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right'
    })

    close()
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel Pending Renewal</ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5}>
            <p>Are you sure you want to cancel the pending renewal for this rental?</p>
            <p>
              You will need to reissue renewal offers for this tenant to allow them to renew again
            </p>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button colorScheme='red' variant='outline' onClick={close}>
              Go Back
            </Button>
            <Spacer />
            <Button colorScheme='red' isLoading={isLoading} onClick={handleSubmit}>
              Cancel Pending Renewal
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CancelPendingRenewalModal
