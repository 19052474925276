import React from 'react'
import { QuestionMarkCircle } from 'styled-icons/heroicons-solid'
import { format } from 'date-fns'
import { types } from '@homevest/timelines'
import { TimelineEventContentRenderer, TimelineEventDisplayTemplate } from '../types'
import { SC } from 'components/TailwindUIToolkit'
import { PropertyDetails } from './PropertyDetails'
import { buildZillowUrlFromAddress } from 'lib/build-urls'
import { RENTLY_PROPERTY_PAGE_SLUG } from 'constants/urls'

type TimelineEventType = types.InquiryTimelineEvent

const PreviewContent: TimelineEventContentRenderer<TimelineEventType> = ({
  event,
  customer,
  home
}) => {
  return (
    <div>
      <SC.MessageText>
        <SC.InlineBold>{customer?.displayName}</SC.InlineBold> inquired about{' '}
        <SC.InlineBold>{home?.address || 'some property'}</SC.InlineBold> from{' '}
        {event.externalSource}
      </SC.MessageText>
    </div>
  )
}

const ExpandedContent: TimelineEventContentRenderer<TimelineEventType> = ({
  event,
  home: homeDetails
}) => {
  const renderExternalSource = (event: TimelineEventType) => {
    switch (event.externalSource) {
      case 'rently':
        return homeDetails?.firestoreId ? (
          <SC.StyledLink href={`${RENTLY_PROPERTY_PAGE_SLUG}${homeDetails?.firestoreId}`}>
            Rently Link
          </SC.StyledLink>
        ) : null
      case 'zillow':
        return (
          <SC.StyledLink
            href={buildZillowUrlFromAddress(
              homeDetails?.address,
              homeDetails?.city,
              homeDetails?.state,
              homeDetails?.zip
            )}
          >
            Zillow Link
          </SC.StyledLink>
        )

      default:
        return <SC.ErrorMessage>don't have a link to the listing yet</SC.ErrorMessage>

      // TODO: add links for zumper, rentals.com, apartments.com, and rentable?
    }
  }

  const externalListing = (
    <SC.Subsection>
      <SC.EventSubheading>External Listing</SC.EventSubheading>
      <SC.MessageText>{renderExternalSource(event)}</SC.MessageText>
    </SC.Subsection>
  )

  const message = !!event.message ? (
    <SC.Subsection>
      <SC.EventSubheading>Message</SC.EventSubheading>
      <SC.MessageText>{event.message}</SC.MessageText>
    </SC.Subsection>
  ) : null

  const moveInDate = !!event.occupancyDate ? (
    <SC.Subsection>
      <SC.EventSubheading>Move In Date</SC.EventSubheading>
      <SC.MessageText>{format(new Date(event.occupancyDate), 'P')}</SC.MessageText>
    </SC.Subsection>
  ) : null

  return (
    <div>
      {message}
      {externalListing}
      {moveInDate}
      <PropertyDetails details={homeDetails} />
      <SC.Subsection>
        <SC.DescriptionText>
          Sent on <SC.InlineBold>{format(new Date(event.eventTimeAt), 'PP')}</SC.InlineBold>
        </SC.DescriptionText>
      </SC.Subsection>
    </div>
  )
}

export const InquiryEventDisplayTemplate: TimelineEventDisplayTemplate<TimelineEventType> = {
  icon: QuestionMarkCircle,
  iconColor: '#0EA5E9',
  previewContent: PreviewContent,
  expandedContent: ExpandedContent
}
