import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { startCase } from 'lodash'
import { capabilities, userFunnelStatus } from '@homevest/utils'
import { FormControl, FormLabel, Select, Spinner, useToast } from '@chakra-ui/react'

import { StoreState } from 'store'
import { ActionButton } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import { hasCapability } from 'lib/admin-perms'
import { useArchiveUserMutation } from 'graphql/generated'

const { CAPABILITY_TYPES } = capabilities
const { ARCHIVE_REASONS } = userFunnelStatus

const ArchiveUser: React.FC<React.PropsWithChildren<{ reexecuteQuery: () => void }>> = ({
  reexecuteQuery
}) => {
  const { userId } = useParams<{ userId: string }>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [archiveReason, setArchiveReason] = useState('')

  const admin = useSelector((store: StoreState) => store.admin)
  const canArchive = hasCapability(admin, CAPABILITY_TYPES.LEAD_MANAGER_TOOL_USER)
  const canSubmit = canArchive && !!archiveReason

  const toast = useToast()
  const [{ error, fetching }, archiveUser] = useArchiveUserMutation()

  const handleArchive = async () => {
    if (!canSubmit) {
      return
    }

    const result = await archiveUser({
      userId,
      archivedByAdminId: admin.id,
      archiveReason
    })

    const didUpdate = !!result.data?.update_user_funnel_statuses?.affected_rows
    if (didUpdate) {
      toast({
        title: 'User archived!',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })
    } else {
      toast({
        title: error ? 'Error archiving user' : 'No associated lead record to archive!',
        description: error
          ? error.message
          : 'The user has either already been archived or is not a primary user in our system.',
        status: error ? 'error' : 'warning',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })
    }

    reexecuteQuery()
    setIsModalOpen(false)
    setArchiveReason('')
  }

  if (!canArchive) {
    return null
  }

  return (
    <>
      <SC.DropDownMenuItem onClick={() => setIsModalOpen(true)}>Archive</SC.DropDownMenuItem>
      <SC.Modal isOpen={isModalOpen}>
        Archive User
        <SC.ModalSubText className='pb-3 text-left'>
          <p className='pb-3'>
            To archive this user and remove them from our call and automated communication lists,
            please select an archive reason below.
          </p>
          <FormControl isRequired>
            <FormLabel htmlFor='archive-reason-select'>Archive Reason</FormLabel>
            <Select
              id='archive-reason-select'
              placeholder='Select archive reason...'
              onChange={(e) => setArchiveReason(e.target.value)}
              value={archiveReason}
            >
              {Object.values(ARCHIVE_REASONS).map((reason) => (
                <option key={reason} value={reason}>
                  {startCase(reason)}
                </option>
              ))}
            </Select>
          </FormControl>
        </SC.ModalSubText>
        {fetching ? (
          <div className='justify-center'>
            <Spinner />
          </div>
        ) : (
          <div className='flex place-content-between'>
            <>
              <ActionButton
                onClick={handleArchive}
                colorScheme={canSubmit ? 'secondary' : 'disabled'}
                disabled={!canSubmit}
              >
                Archive
              </ActionButton>
              <ActionButton onClick={() => setIsModalOpen(false)}>Cancel</ActionButton>
            </>
          </div>
        )}
      </SC.Modal>
    </>
  )
}

export default ArchiveUser
