import React from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'
import { agreementSigners } from '@homevest/utils'

const { AGREEMENT_SIGNER_STATUSES } = agreementSigners

interface SignButtonProps {
  signer: { role: string; status: string }
  launchAgreement: (signer: any) => void
  buttonOptions?: ButtonProps
}

const SignButton: React.FC<React.PropsWithChildren<SignButtonProps>> = ({
  signer,
  launchAgreement,
  buttonOptions
}) => {
  const needsSignature = signer?.status === AGREEMENT_SIGNER_STATUSES.PENDING_SIGNATURE

  if (!needsSignature) {
    return null
  }

  return (
    <Button
      onClick={() => launchAgreement(signer)}
      colorScheme='teal'
      variant='outline'
      size='sm'
      {...buttonOptions}
    >
      Sign as {signer?.role}
    </Button>
  )
}

export default SignButton
