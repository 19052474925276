import React, { FC, useCallback } from 'react'
import { RentRollQuery } from 'graphql/generated'
import { GridReadyEvent, RowNode } from 'ag-grid-community'
import { useHistory } from 'react-router-dom'
import { BaseGrid } from 'components/TailwindUIToolkit'
import { RentRollStatus, getRentRollStatus } from 'lib/rent-roll/statuses'
import { getColumnsForStatus, getRowStyleForStatus, RentRollRentalType } from './configuration'

interface RentRollGridProps {
  statusFilter?: RentRollStatus
  rowData: RentRollRentalType[]
  onGridReady?: (evt: GridReadyEvent) => void
}

const RentRollGrid: FC<React.PropsWithChildren<RentRollGridProps>> = ({
  rowData,
  onGridReady,
  statusFilter
}) => {
  const history = useHistory()
  const isExternalFilterPresent = useCallback(() => {
    return !!statusFilter
  }, [statusFilter])
  const doesExternalFilterPass = useCallback(
    (node: RowNode<RentRollQuery['rent_roll'][number]>): boolean => {
      if (!node.data) {
        return false
      }
      if (!statusFilter) {
        return true
      }

      return getRentRollStatus(node.data) === statusFilter
    },
    [statusFilter]
  )
  if (!rowData) {
    return null
  }
  return (
    <div style={{ height: '74vh' }}>
      <BaseGrid<RentRollRentalType>
        columns={getColumnsForStatus(statusFilter)}
        rowData={rowData}
        optionOverrides={{
          context: {
            history
          },
          pagination: true,
          rowHeight: 45,
          paginationPageSize: 20
        }}
        rowAutoHeight={true}
        defaultColDefOverrides={{ floatingFilter: true }}
        getRowStyle={(params) => getRowStyleForStatus(params, statusFilter)}
        isExternalFilterPresent={isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        onRowDoubleClicked={(evt) => {
          history.push(`/rent-roll/${evt.data?.id}`)
        }}
        onGridReady={(params) => {
          params.api?.sizeColumnsToFit()
          onGridReady?.(params)
        }}
      />
    </div>
  )
}

export default RentRollGrid
