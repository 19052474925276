import axios from 'lib/axios'

export const RECEIVED_FIREBASE_USER = 'RECEIVED_FIREBASE_USER'
export const RECEIVED_USER = 'RECEIVED_USER'
export const START_SYNCING_FIREBASE_USER = 'SYNC_FIREBASE_USER'
export const STOP_SYNCING_FIREBASE_USER = 'STOP_SYNCING_FIREBASE_USER'

export const startSyncingFirebaseUser = (userId) => ({
  userId,
  type: START_SYNCING_FIREBASE_USER
})

export const stopSyncingFirebaseUser = (userId) => ({
  userId,
  type: STOP_SYNCING_FIREBASE_USER
})

export const receivedFirebaseUser = (firebaseUser) => ({
  type: RECEIVED_FIREBASE_USER,
  firebaseUser
})

export const receivedUser = (user) => ({
  type: RECEIVED_USER,
  user
})

export const fetchUser = (userId) => {
  return async (dispatch) => {
    const userRes = await axios.get(`/users/${userId}`)

    dispatch(receivedUser(userRes.data))
  }
}

export const createUser = (data) => {
  return async (dispatch) => {
    const res = await axios.post('/users/upsert', data)

    dispatch(receivedUser(res.data))

    return res.data
  }
}
