import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Checkbox,
  HStack,
  Stack,
  Center,
  MenuButton,
  Menu,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import { utilities } from '@homevest/utils'
import type { ICellRendererParams } from 'ag-grid-community'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { startCase } from 'lodash'
import { GridReadyEvent } from 'ag-grid-community'
import { ApplyUtilityChargebacksPayload, UnfinalizedChargeback } from '@homevest/types/utilities'
import { BaseGrid } from 'components/TailwindUIToolkit'
import axios from 'lib/axios'
import { BASE_UTILITY_GRID_COL_DEFS } from '../utilities-grid-configs'
import { CustomSpinner } from 'ui/Loading'

const { UTILITY_CHARGEBACK_STATUSES } = utilities

export enum UtilitiesChargebacksView {
  ALL = 'all',
  PENDING = 'pending_triage',
  REJECTED = 'rejected',
  DEFERRED = 'deferred',
  APPROVED = 'approved'
}

export type UtilityChargebackStatus =
  (typeof UTILITY_CHARGEBACK_STATUSES)[keyof typeof UTILITY_CHARGEBACK_STATUSES]

const chargebackStatusToColor: { [_status in UtilityChargebackStatus]: string } = {
  [UTILITY_CHARGEBACK_STATUSES.APPROVED]: 'green',
  [UTILITY_CHARGEBACK_STATUSES.DEFERRED]: 'yellow',
  [UTILITY_CHARGEBACK_STATUSES.REJECTED]: 'red',
  [UTILITY_CHARGEBACK_STATUSES.PENDING]: 'gray'
}

const chargebackStatusToAction: { [_status in UtilityChargebackStatus]: string } = {
  [UTILITY_CHARGEBACK_STATUSES.APPROVED]: 'approve',
  [UTILITY_CHARGEBACK_STATUSES.DEFERRED]: 'defer',
  [UTILITY_CHARGEBACK_STATUSES.REJECTED]: 'reject',
  [UTILITY_CHARGEBACK_STATUSES.PENDING]: 'pending'
}

export const Grid: React.FC<
  React.PropsWithChildren<{ onGridReady: (evt: GridReadyEvent) => void }>
> = ({ onGridReady }) => {
  const [view, setView] = useState<UtilitiesChargebacksView>(UtilitiesChargebacksView.ALL)
  const [error, setError] = useState<string | null>(null)
  const [chargebacksData, setChargebacksData] = useState<UnfinalizedChargeback[]>([])
  const [selectedChargebacks, setSelectedChargebacks] = useState<Set<string>>(new Set())

  const { isLoading: chargebacksAreLoading, refetch: refetchChargebacks } = useQuery({
    queryKey: ['chargebacks', 'unfinalized'],
    queryFn: async () => {
      return await axios
        .get<{ chargebacks: UnfinalizedChargeback[] }>('/admin/utility_chargebacks/unfinalized')
        .then((res) => res.data)
    },
    onSuccess: (data) => setChargebacksData(data.chargebacks),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  const { mutate: applyChanges, isLoading: applyingChanges } = useMutation({
    mutationFn: async ({
      chargebackStatuses,
      finalize = false
    }: {
      chargebackStatuses: [string, UtilityChargebackStatus][]
      finalize?: boolean
    }) => {
      if (chargebackStatuses.length === 0) {
        return
      }

      return await axios.post('/admin/utility_chargebacks/apply', {
        finalize,
        updated_chargebacks: chargebackStatuses.map(([id, newStatus]) => ({
          utility_chargeback_id: id,
          new_status: newStatus
        }))
      } as ApplyUtilityChargebacksPayload)
    },
    onSuccess: (res, { chargebackStatuses }) => {
      if (res?.data.success) {
        setError(null)
        setChargebacksData((prev) =>
          prev.map((chb) => {
            const newStatus = chargebackStatuses.find(([id, _]) => id === chb.id)?.[1]
            if (newStatus) {
              return {
                ...chb,
                status: newStatus
              }
            }
            return chb
          })
        )
      } else {
        setError(res?.data.message)
      }
    }
  })

  const applySameChangeToAll = (
    { chargebackIds, newStatus }: { chargebackIds: string[]; newStatus: UtilityChargebackStatus },
    options: Parameters<typeof applyChanges>[1]
  ) => {
    applyChanges({ chargebackStatuses: chargebackIds.map((id) => [id, newStatus]) }, options)
  }

  const toggleSelectedForChargebackId = (id: string) => {
    setSelectedChargebacks((prev) => {
      if (prev.has(id)) {
        prev.delete(id)
      } else {
        prev.add(id)
      }
      return new Set(prev)
    })
  }

  const isDisabled = chargebacksAreLoading || applyingChanges
  const isLoading = chargebacksAreLoading

  const chargebacksToShow = useMemo(() => {
    if (view === UtilitiesChargebacksView.ALL) {
      return chargebacksData
    }

    return chargebacksData.filter((c) => c.status === view)
  }, [chargebacksData, view])

  useEffect(() => {
    setSelectedChargebacks(new Set())
  }, [view])

  return (
    <Stack>
      <HStack w='full' justifyContent='center' paddingBottom='4'>
        {Object.values(UtilitiesChargebacksView).map((v) => (
          <Button
            key={v}
            border={v === view ? 'solid' : undefined}
            onClick={() => {
              setView(v)
            }}
          >
            {startCase(v)}
          </Button>
        ))}
      </HStack>
      <HStack>
        <Button
          variant='outline'
          flex={1}
          isDisabled={applyingChanges || chargebacksAreLoading}
          onClick={() => {
            setSelectedChargebacks(new Set(chargebacksToShow.map((c) => c.id)))
          }}
          colorScheme='blue'
        >
          Select All
        </Button>
        <Button
          variant='outline'
          flex={1}
          isDisabled={applyingChanges || chargebacksAreLoading || selectedChargebacks.size === 0}
          onClick={() => setSelectedChargebacks(new Set())}
          colorScheme='yellow'
        >
          Clear Selected
        </Button>
        <Menu matchWidth placement='bottom'>
          <MenuButton
            variant='outline'
            flex={1}
            as={Button}
            rightIcon={<ChevronDownIcon height={24} />}
            isDisabled={applyingChanges || chargebacksAreLoading || selectedChargebacks.size === 0}
            flexGrow={1}
            colorScheme='green'
          >
            Update Selected
          </MenuButton>
          <MenuList>
            {[
              UTILITY_CHARGEBACK_STATUSES.REJECTED,
              UTILITY_CHARGEBACK_STATUSES.DEFERRED,
              UTILITY_CHARGEBACK_STATUSES.APPROVED
            ].map((status) => {
              return (
                <MenuItem key={status}>
                  <Button
                    w='full'
                    colorScheme={chargebackStatusToColor[status]}
                    onClick={() =>
                      applySameChangeToAll(
                        { chargebackIds: Array.from(selectedChargebacks), newStatus: status },
                        {
                          onSuccess: () => {
                            setSelectedChargebacks(new Set())
                          }
                        }
                      )
                    }
                  >
                    {startCase(chargebackStatusToAction[status])}
                  </Button>
                </MenuItem>
              )
            })}
          </MenuList>
        </Menu>
        <Menu matchWidth>
          <MenuButton
            variant='outline'
            flex={1}
            as={Button}
            isDisabled={applyingChanges || chargebacksAreLoading || selectedChargebacks.size === 0}
            flexGrow={1}
            colorScheme='red'
          >
            Finalize Selected
          </MenuButton>
          <MenuList>
            <MenuItem>
              <Button
                w='full'
                colorScheme='red'
                onClick={() =>
                  applyChanges(
                    {
                      chargebackStatuses: chargebacksData
                        .filter((chb) => selectedChargebacks.has(chb.id))
                        .map((chb) => [chb.id, chb.status as UtilityChargebackStatus]),
                      finalize: true
                    },
                    {
                      onSuccess: () => {
                        refetchChargebacks()
                      }
                    }
                  )
                }
              >
                Confirm Finalization
              </Button>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
      {error && (
        <div>
          <pre className='text-red-500'>Error: {JSON.stringify(error, undefined, 2)}</pre>
        </div>
      )}
      <div className='h-[80vh] rounded border'>
        {chargebacksAreLoading && (
          <Center h='full'>
            <CustomSpinner />
          </Center>
        )}
        {!chargebacksAreLoading && (
          <BaseGrid<UnfinalizedChargeback>
            onGridReady={onGridReady}
            columns={[
              {
                headerName: 'selected',
                cellRenderer: ({ data }: ICellRendererParams<UnfinalizedChargeback>) => {
                  if (!data) return null
                  return (
                    <Checkbox
                      isChecked={selectedChargebacks.has(data.id)}
                      onChange={() => toggleSelectedForChargebackId(data.id)}
                    />
                  )
                },
                sortable: false,
                filter: false,
                flex: 1
              },
              ...BASE_UTILITY_GRID_COL_DEFS,
              {
                headerName: 'actions',
                cellRenderer: ({ data }: ICellRendererParams<UnfinalizedChargeback>) => {
                  if (!data) return null

                  const statusButton = (buttonStatus: UtilityChargebackStatus) => (
                    <Button
                      isDisabled={isLoading || isDisabled}
                      colorScheme={chargebackStatusToColor[buttonStatus]}
                      onClick={() =>
                        applyChanges({ chargebackStatuses: [[data.id, buttonStatus]] })
                      }
                      variant={data.status === buttonStatus ? 'solid' : 'outline'}
                    >
                      {startCase(chargebackStatusToAction[buttonStatus])}
                    </Button>
                  )

                  return (
                    <HStack>
                      {statusButton(UTILITY_CHARGEBACK_STATUSES.REJECTED)}
                      {statusButton(UTILITY_CHARGEBACK_STATUSES.DEFERRED)}
                      {statusButton(UTILITY_CHARGEBACK_STATUSES.APPROVED)}
                    </HStack>
                  )
                },
                sortable: false,
                filter: false,
                flex: 2
              }
            ]}
            rowData={chargebacksToShow}
          />
        )}
      </div>
    </Stack>
  )
}
