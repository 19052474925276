import React from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import { capabilities } from '@homevest/utils'
import { convertDateToUTC, isDateBeforeToday } from 'lib/date-time'

import RenewalActions from './RenewalActions'

import { hasCapability } from 'lib/admin-perms'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { PendingRenewalInfoFragment } from 'graphql/generated'
import RenewalsPanelContent from './RenewalsPanelContent'

const { RENEWAL_CREATOR } = capabilities.CAPABILITY_TYPES

type RenewalInfo = PendingRenewalInfoFragment & {
  move_out_date?: any
}

export default function RenewalsPanel({ rental }: { rental: RenewalInfo }) {
  const admin = useSelector((state: StoreState) => state.admin)
  const canRenew = hasCapability(admin, RENEWAL_CREATOR)

  const moveOutDate = (rental as any).move_out_date
  const isMoveOutDateInPast = moveOutDate ? isDateBeforeToday(convertDateToUTC(moveOutDate)) : false

  if (!canRenew && isMoveOutDateInPast) {
    return null
  }

  return (
    <ContentSectionCard
      title={'Pending Renewals'}
      padding
      collapsable={false}
      action={<RenewalActions />}
    >
      <RenewalsPanelContent rental={rental} />
    </ContentSectionCard>
  )
}
