import React, { useState } from 'react'
import { Comparators as Comp } from 'lib/hasura-comparators'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash'
import { Helmet } from 'react-helmet'
import { Heading } from '@chakra-ui/react'

import { useUserListQuery } from 'graphql/generated'
import { getContactInfo } from 'lib/users'
import { Input, Checkbox, Stack, Spinner, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'

function UserEntries({
  searchText = '',
  showDeleted = false
}: {
  searchText: string
  showDeleted: boolean
}) {
  const [{ data, fetching }] = useUserListQuery({
    variables: {
      where: {
        user_searchable: {
          info: Comp.isLike(`%${(searchText || '').replace(/\s/g, '')}%`)
        },
        deleted_at: showDeleted ? Comp.isNotNull() : Comp.isNull()
      }
    }
  })
  const users = data?.users ?? []
  if (fetching) {
    return <Spinner />
  }

  return (
    <>
      <Helmet>Users</Helmet>
      <div>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Phone</Th>
            </Tr>
          </Thead>

          <Tbody>
            {users.map(
              ({
                id,
                first_name: firstName,
                last_name: lastName,
                user_contact_details
                // email,
                // phone
              }) => {
                return (
                  <Tr key={id}>
                    <Td>
                      <Link
                        to={{
                          pathname: `/users/${id}`
                        }}
                      >
                        {id}
                      </Link>
                    </Td>
                    <Td>
                      {firstName} {lastName}
                    </Td>
                    <Td>{getContactInfo('email', user_contact_details)}</Td>
                    <Td>{getContactInfo('phone', user_contact_details)}</Td>
                  </Tr>
                )
              }
            )}
          </Tbody>
        </Table>
      </div>
    </>
  )
}

UserEntries.propTypes = {
  searchText: PropTypes.string.isRequired,
  deleted: PropTypes.bool
}

function Users() {
  const [searchText, setSearchText] = useState('')
  const [inputText, setInputText] = useState('')
  const [showDeleted, setShowDeleted] = useState(false)

  const setSearchTextDebounced = React.useMemo(
    () => debounce(setSearchText, 250, { trailing: true }),
    [setSearchText]
  )

  return (
    <div className='mx-5 p-5'>
      <Heading size='xl'>User Search</Heading>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '40px'
        }}
      >
        <div
          style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '20px'
          }}
        >
          <Input
            type='text'
            value={inputText}
            onChange={(e) => {
              // We need two separate values so we can debounce to the subscription
              setInputText(e.target.value)
              setSearchTextDebounced(e.target.value)
            }}
            placeholder='Search...'
          />
          <Stack pl={15} mt={2}>
            <Checkbox
              isChecked={showDeleted}
              onChange={(e) => setShowDeleted(e.target.checked)}
              style={{ whiteSpace: 'nowrap' }}
            >
              Show Deleted Users
            </Checkbox>
          </Stack>
        </div>
      </div>

      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner />
          </div>
        }
      >
        <UserEntries searchText={searchText} showDeleted={showDeleted} />
      </React.Suspense>
    </div>
  )
}

export default Users
