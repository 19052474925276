import React from 'react'
import { useParams } from 'react-router'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react'

import { RenewalDetails } from 'types/Renewals'
import axios from 'lib/axios'

const RenewRentalLeaseCreateModal: React.FC<
  React.PropsWithChildren<{
    renewalDetails: RenewalDetails
    isOpen: boolean
    setOpen: (closed: boolean) => void
    goBack: (closed: boolean) => void
  }>
> = ({ renewalDetails, isOpen, setOpen, goBack }) => {
  const { rentalId } = useParams<{ rentalId: string }>()
  const [creatingLease, setCreatingLease] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const toast = useToast()

  return (
    <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={() => setOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Generate Lease</Text>
        </ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody>
          {errorMessage && (
            <Alert status='error'>
              <AlertIcon />
              <Box>
                <AlertTitle>Whoops!</AlertTitle>
                <AlertDescription>{errorMessage}</AlertDescription>
              </Box>
            </Alert>
          )}
          {creatingLease ? (
            <Flex width={'100%'} padding={3} justifyContent={'center'} alignItems={'center'}>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Flex>
          ) : (
            <Text fontSize='md'>
              Clicking "Generate Lease" will generate the renewal agreement and sent it to the
              customer for signature. <b>This cannot be undone.</b>
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Box w={'full'} pb='2'>
            <Flex>
              <Button
                colorScheme='gray'
                onClick={() => {
                  setOpen(false)
                  goBack(true)
                }}
              >
                Back
              </Button>
              <Spacer />
              <Button
                colorScheme='green'
                onClick={async () => {
                  setCreatingLease(true)
                  setErrorMessage('')
                  try {
                    await axios.post(`/admin/renew_rental/${rentalId}`, renewalDetails)
                    setOpen(false)
                    toast({
                      title: 'Lease created.',
                      description: "We've created a renewal lease for this rental.",
                      status: 'success',
                      duration: 6000,
                      isClosable: true,
                      position: 'top-right'
                    })
                  } catch (err: any) {
                    setErrorMessage(err.message)
                  } finally {
                    setCreatingLease(false)
                  }
                }}
              >
                Generate Lease
              </Button>
            </Flex>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RenewRentalLeaseCreateModal
