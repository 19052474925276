import {
  SET_ERROR_MESSAGE,
  SET_ERROR_MODAL_IS_OPEN,
  SET_SUCCESS_MESSAGE,
  SET_SUCCESS_MODAL_IS_OPEN
} from './actions'

export const initialState = {
  errorMessage: '',
  errorModalIsOpen: false,
  successMessage: '',
  successModalIsOpen: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.message
      }
    }
    case SET_ERROR_MODAL_IS_OPEN: {
      return {
        ...state,
        errorModalIsOpen: action.isOpen
      }
    }
    case SET_SUCCESS_MESSAGE: {
      return {
        ...state,
        successMessage: action.message
      }
    }
    case SET_SUCCESS_MODAL_IS_OPEN: {
      return {
        ...state,
        successModalIsOpen: action.isOpen
      }
    }
    default:
      return state
  }
}
