import React from 'react'
import startCase from 'lodash/startCase'
import {
  Button,
  IconButton,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Table,
  Tbody
} from '@chakra-ui/react'
import {
  EnvelopeIcon,
  PencilIcon,
  PhoneIcon,
  UserIcon,
  BriefcaseIcon
} from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import { EmployedUserIncomeSource } from '@homevest/types/user-income-sources'

import EmploymentVerificationItem from './EmploymentVerificationItem'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { isValidEmail, isValidPhoneNumber } from 'lib/form-validation'
import { formatPhoneFromDatabase, parsePhoneNumber } from 'lib/formatting'
import { StoreState } from 'store'
import {
  Applicant_Income_Underwrites_User_Income_Employment_Verification_Statuses_Enum_Enum as EmploymentStatusesEnum,
  useSetEmploymentVerificationStatusMutation,
  useUpdateIncomeSourceContactNameMutation,
  useUpdateIncomeSourceContactTitleMutation,
  useUpdateIncomeSourceContactEmailMutation,
  useUpdateIncomeSourceContactPhoneMutation
} from 'graphql/generated'

const EmploymentVerification: React.FC<
  React.PropsWithChildren<{
    userIncomeSource: EmployedUserIncomeSource
    onUpdate: () => Promise<void>
  }>
> = ({ userIncomeSource, onUpdate }) => {
  const [{ fetching: isContactNameFetching }, updateContactName] =
    useUpdateIncomeSourceContactNameMutation()
  const [{ fetching: isContactTitleFetching }, updateContactTitle] =
    useUpdateIncomeSourceContactTitleMutation()
  const [{ fetching: isContactEmailFetching }, updateContactEmail] =
    useUpdateIncomeSourceContactEmailMutation()
  const [{ fetching: isContactPhoneFetching }, updateContactPhone] =
    useUpdateIncomeSourceContactPhoneMutation()

  return (
    <Stack w='fit-content'>
      <HStack>
        <Heading size='sm'>Employment Verification</Heading>
        {mapContentToBadge(userIncomeSource.employment_verification_status)}
        <SetEmploymentVerificationStatus onUpdate={onUpdate} userIncomeSource={userIncomeSource} />
      </HStack>
      <Table size='sm' w='fit-content'>
        <Tbody>
          <EmploymentVerificationItem
            icon={<UserIcon width={18} />}
            value={userIncomeSource.contact_name}
            formatDisplayValue={(value) => startCase(value || '')}
            isLoading={isContactNameFetching}
            onSubmit={async (value) => {
              await updateContactName({
                userIncomeSourceId: userIncomeSource.id,
                contactName: value
              })
              await onUpdate()
            }}
          />
          <EmploymentVerificationItem
            icon={<BriefcaseIcon width={18} />}
            value={userIncomeSource.contact_title}
            formatDisplayValue={(value) => startCase(value || '')}
            isLoading={isContactTitleFetching}
            onSubmit={async (value) => {
              await updateContactTitle({
                userIncomeSourceId: userIncomeSource.id,
                contactTitle: value
              })
              await onUpdate()
            }}
          />
          <EmploymentVerificationItem
            icon={<PhoneIcon width={18} />}
            value={userIncomeSource.contact_phone}
            formatDisplayValue={formatPhoneFromDatabase}
            formatInputValue={formatPhoneFromDatabase}
            validate={(value) =>
              value && !isValidPhoneNumber(parsePhoneNumber(value)) ? 'Invalid phone number!' : ''
            }
            isLoading={isContactPhoneFetching}
            onSubmit={async (value) => {
              await updateContactPhone({
                userIncomeSourceId: userIncomeSource.id,
                contactPhone: value ? parsePhoneNumber(value) : null
              })
              await onUpdate()
            }}
            inputProps={{ type: 'tel', maxLength: 15 }}
          />
          <EmploymentVerificationItem
            icon={<EnvelopeIcon width={18} />}
            value={userIncomeSource.contact_email}
            isLoading={isContactEmailFetching}
            validate={(value) => (value && !isValidEmail(value) ? 'Invalid email!' : '')}
            onSubmit={async (value) => {
              await updateContactEmail({
                userIncomeSourceId: userIncomeSource.id,
                contactEmail: value
              })
              await onUpdate()
            }}
            inputProps={{ type: 'email' }}
          />
        </Tbody>
      </Table>
    </Stack>
  )
}

const SetEmploymentVerificationStatus = ({
  userIncomeSource,
  onUpdate
}: {
  userIncomeSource: EmployedUserIncomeSource
  onUpdate: () => Promise<void>
}) => {
  const [{ fetching }, mutate] = useSetEmploymentVerificationStatusMutation()
  const adminId = useSelector((state: StoreState) => state.admin.id)

  const handleSetStatus = (status: EmploymentStatusesEnum) => async () => {
    await mutate({
      adminId,
      employmentVerificationStatus: status,
      now: new Date(),
      userIncomeSourceId: userIncomeSource.id
    })
    await onUpdate()
  }

  return (
    <Menu placement='bottom'>
      <MenuButton
        as={IconButton}
        icon={<PencilIcon className='h-4 w-4' />}
        size='md'
        variant='ghost'
        colorScheme='blackAlpha'
      >
        Set Verification Status
      </MenuButton>
      <MenuList minW={0}>
        <HStack px={2}>
          <Button
            size='sm'
            colorScheme='green'
            isDisabled={fetching}
            isLoading={fetching}
            onClick={handleSetStatus(EmploymentStatusesEnum.Verified)}
          >
            Verified
          </Button>
          <Button
            size='sm'
            colorScheme='red'
            isDisabled={fetching}
            isLoading={fetching}
            onClick={handleSetStatus(EmploymentStatusesEnum.Failed)}
          >
            Failed
          </Button>
        </HStack>
      </MenuList>
    </Menu>
  )
}

export default EmploymentVerification
