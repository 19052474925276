"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_PET_WEIGHT = exports.MAX_LICENSE_PLATE_LENGTH = exports.MIN_VEHICLE_YEAR = exports.MAX_OCCUPANT_AGE = exports.MAX_COUNTS = exports.PET_TYPES = exports.OCCUPANT_RELATIONS = exports.REQUIRED_LEASE_DETAIL_TYPES = void 0;
exports.REQUIRED_LEASE_DETAIL_TYPES = {
    OCCUPANTS: 'occupants',
    PETS: 'pets',
    VEHICLES: 'vehicles',
    MOVE_IN_DATE: 'move_in_date'
};
exports.OCCUPANT_RELATIONS = {
    CHILD: 'child',
    GRANDCHILD: 'grandchild',
    NIECE_OR_NEPHEW: 'niece_or_nephew',
    SIBLING: 'sibling',
    OTHER: 'other'
};
exports.PET_TYPES = {
    DOG: 'dog',
    CAT: 'cat',
    BIRD: 'bird',
    RABBIT: 'rabbit',
    HAMSTER: 'hamster',
    GUINEA_PIG: 'guinea_pig',
    TURTLE: 'turtle',
    LIZARD: 'lizard',
    SNAKE: 'snake',
    FISH: 'fish',
    OTHER: 'other'
};
exports.MAX_COUNTS = {
    [exports.REQUIRED_LEASE_DETAIL_TYPES.PETS]: 4,
    [exports.REQUIRED_LEASE_DETAIL_TYPES.OCCUPANTS]: 8,
    [exports.REQUIRED_LEASE_DETAIL_TYPES.VEHICLES]: 4,
    [exports.REQUIRED_LEASE_DETAIL_TYPES.MOVE_IN_DATE]: 1
};
exports.MAX_OCCUPANT_AGE = 17;
exports.MIN_VEHICLE_YEAR = 1900;
exports.MAX_LICENSE_PLATE_LENGTH = 15;
exports.MAX_PET_WEIGHT = 400;
