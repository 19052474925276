import React, { useState } from 'react'
import { Button, Center, Spinner } from '@chakra-ui/react'
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { useQuery } from '@tanstack/react-query'
import QueryString from 'qs'
import { GridApi } from 'ag-grid-community'
import { GetConstructionProjectsApiResponse } from '@homevest/types/construction-projects'
import { constructionProjects } from '@homevest/utils'

import axios from 'lib/axios'
import BasicPageLayout from 'components/TailwindUIToolkit/Layouts/BasicPageLayout'
import CreateProjectModal from 'components/Construction/Projects/Grid/CreateProjectModal'
import ProjectsGrid from './Grid'

const { ACTIVE_PROJECT_STATUSES, PROJECT_STATUSES } = constructionProjects

const Projects = () => {
  const [isShowingAllProjects, setIsShowingAllProjects] = useState(false)
  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const statusesToShow = isShowingAllProjects
    ? Object.values(PROJECT_STATUSES)
    : ACTIVE_PROJECT_STATUSES

  const {
    data,
    isLoading,
    refetch: refetchProjects
  } = useQuery({
    queryKey: ['getConstructionProjects', isShowingAllProjects],
    queryFn: async () => {
      const params = { status: statusesToShow }
      const response = await axios.get<GetConstructionProjectsApiResponse>(
        'admin/construction_projects',
        {
          params,
          paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' })
        }
      )
      return response.data?.data
    }
  })

  const handleToggleAllProjects = () => {
    setIsShowingAllProjects(!isShowingAllProjects)
    refetchProjects()
  }

  const handleCsvExport = () => {
    const currentTimestamp = new Date().getTime()
    gridApi?.exportDataAsCsv({
      allColumns: true,
      fileName: `construction_projects_export_${currentTimestamp}.csv`
    })
  }

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <BasicPageLayout
      tabTitle='Construction | Projects'
      heading={isShowingAllProjects ? 'Construction Projects (All)' : 'Construction Projects'}
      topRightElement={
        <div className='flex flex-col place-items-end gap-2'>
          {!isShowingAllProjects && <CreateProjectModal refetchProjects={refetchProjects} />}
          <Button onClick={handleToggleAllProjects}>
            {isShowingAllProjects ? 'View Active Projects' : 'View All Projects'}
          </Button>
          <Button
            onClick={() => handleCsvExport()}
            rightIcon={<ArrowDownTrayIcon className='h-4 w-4' />}
          >
            Export to CSV
          </Button>
        </div>
      }
    >
      <div className='p-4'>
        <ProjectsGrid
          data={data}
          statusesToShow={statusesToShow}
          gridApi={gridApi}
          setGridApi={setGridApi}
        />
      </div>
    </BasicPageLayout>
  )
}

export default Projects
