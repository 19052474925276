import { Combobox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import {
  useUpupDocumentRealEstateAcquisitionsQuery,
  UpupDocumentRealEstateAcquisitionsQuery
} from '../../graphql/generated'

type RealEstateAcquisition =
  UpupDocumentRealEstateAcquisitionsQuery['real_estate_acquisitions'][number]

type Props = {
  resourceId?: string
  onSelect: (selected: RealEstateAcquisition) => void
}

const ResourceSelector: React.FC<React.PropsWithChildren<Props>> = ({ resourceId, onSelect }) => (
  <Suspense fallback={<div>Loading...</div>}>
    <Body onSelect={onSelect} resourceId={resourceId} />
  </Suspense>
)

const Body: React.FC<React.PropsWithChildren<Props>> = ({ resourceId, onSelect }) => {
  const [selected, setSelected] = useState<RealEstateAcquisition | undefined>()
  const [query, setQuery] = useState('')

  const [{ data, error }] = useUpupDocumentRealEstateAcquisitionsQuery()

  useEffect(() => {
    if (resourceId && data) {
      const found = data.real_estate_acquisitions.find(({ id }) => resourceId === id)
      if (found) {
        setSelected(found)
        onSelect(found)
      }
    }
  }, [resourceId, data, onSelect])

  // todo v2: modularize options/display/etc, pass as prop from plugin
  const getOptionDisplay = useCallback((option: any) => {
    if (!option) {
      return ''
    }
    const { delivery_line, city, state } = option.mls_listing ?? {}
    return `${delivery_line} ${city} ${state}`
  }, [])

  const options = useMemo(
    () =>
      (data?.real_estate_acquisitions ?? []).map((rea) => {
        const { delivery_line, city, state } = rea.mls_listing ?? {}
        const fuzzy = `${delivery_line}${city}${state}`.replace(/\s/g, '').toLowerCase()
        return { ...rea, fuzzy }
      }),
    [data]
  )

  const filtered = options.filter((option) =>
    option.fuzzy.includes(query.replace(/\s/g, '').toLowerCase())
  )

  if (error) {
    return <div>Error</div>
  }

  return (
    <Combobox
      value={selected}
      onChange={(value) => {
        onSelect(value)
        setSelected(value)
      }}
    >
      <div className='relative'>
        <div className='relative flex max-w-md flex-row items-center'>
          <Combobox.Input
            className='w-full rounded-md border border-gray-300 px-2 py-1'
            displayValue={getOptionDisplay}
            placeholder='Search...'
            autoComplete=''
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className='absolute right-0 flex h-full w-8 items-center justify-center'>
            <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
          </Combobox.Button>
        </div>
        <Combobox.Options className='absolute max-h-96 w-full max-w-md divide-y divide-gray-100 overflow-scroll rounded-md bg-white'>
          {filtered.map((option) => (
            <Combobox.Option
              className='cursor-pointer p-1 hover:bg-gray-100'
              key={option.id}
              value={option}
            >
              {getOptionDisplay(option)}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}

export default ResourceSelector
