import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Heading, Stack, Button, HStack, Box, Center, Divider, Text } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

import { CsvUploadValidationError } from 'types/Utilities'
import CsvUploadForm from 'components/Utilities/CsvUpload/CsvUploadForm'
import UploadResultView from 'components/Utilities/CsvUpload/UploadResultView'


const CsvUpload = () => {
  const history = useHistory()

  const [uploadId, setUploadId] = useState<string>(uuidv4())
  const [uploadState, setUploadState] = useState<any>({})
  const [downloadUrl, setDownloadUrl] = useState<string>('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errors, setErrors] = useState<CsvUploadValidationError[]>([])
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false)

  const resetForm = () => {
    setDownloadUrl('')
    setUploadState({})
    setUploadId(uuidv4())
    setIsSubmitted(false)
    setErrors([])
    setIsSubmissionSuccessful(false)
  }

  return (
    <Stack padding={4}>
      <HStack justify='space-between'>
        <Heading>Utilities - CSV Upload</Heading>
        <Button
          variant='outline'
          size={'lg'}
          onClick={() => history.push('/utilities/chargebacks-manager')}
        >
          Back
        </Button>
      </HStack>
      <Divider />
      <Text as={'i'}>
        Use this page to upload a scrubbed Conservice CSV of utility bills to our system.
      </Text>
      <Center>
        <Box paddingTop={10} justifyContent={'center'} width='50%'>
          {isSubmitted && (
            <UploadResultView
              isSuccessful={isSubmissionSuccessful}
              resetForm={resetForm}
              errors={errors}
            />
          )}
          {!isSubmitted && (
            <CsvUploadForm
              uploadId={uploadId}
              uploadState={uploadState}
              setUploadState={setUploadState}
              downloadUrl={downloadUrl}
              setDownloadUrl={setDownloadUrl}
              setIsSubmitted={setIsSubmitted}
              setIsSubmissionSuccessful={setIsSubmissionSuccessful}
              setErrors={setErrors}
            />
          )}
        </Box>
      </Center>

    </Stack>
  )
}

export default CsvUpload
