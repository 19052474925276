import React from 'react'

import { useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { ConstructionProjectApiResponse } from '@homevest/types/construction-projects'
import { OccupancyInspectionApiResponse } from '@homevest/types/occupancy-inspections'

import { Sidepeek } from 'ui'
import axios from 'lib/axios'
import {
  OccupancyInspectionFormData,
  validateOccupancyInspectionForm
} from 'lib/occupancy-inspections'
import OccupancyInspectionForm from './OccupancyInspectionForm'
import { formatProjectTitle } from 'lib/construction-projects'

const CreateOccupancyInspectionSidepeek = ({
  project,
  isOpen,
  onSubmit,
  onClose
}: {
  project: ConstructionProjectApiResponse
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}) => {
  const toast = useToast()
  const { mutate: createOccupancyInspection } = useMutation({
    mutationFn: async (data: OccupancyInspectionFormData) => {
      return axios.post<OccupancyInspectionApiResponse>('/admin/occupancy_inspections', data)
    },
    onSuccess: () => {
      toast({
        title: 'Occupancy Inspection Created',
        status: 'success',
        duration: 5000,
        position: 'bottom-right'
      })
      onSubmit()
      onClose()
    },
    onError: (error: any) => {
      toast({
        title: 'Error Creating Occupancy Inspection',
        description: error?.message || 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        position: 'bottom-right'
      })
    }
  })

  return (
    <Sidepeek
      isOpen={isOpen}
      onClose={onClose}
      title={'Create Occupancy Inspection'}
      subtitle={
        <>
          <div className='text-teal-600'>
            <strong>Project:</strong>{' '}
            {formatProjectTitle(project.type, project.address, project.start_date)}
          </div>
          <div className='text-teal-600'>
            <strong>Municipality:</strong> {project.municipalities?.name}
          </div>
        </>
      }
    >
      <OccupancyInspectionForm
        initialData={{ construction_project_id: project.id }}
        onSubmit={(data) => createOccupancyInspection(data)}
        validator={validateOccupancyInspectionForm}
      />
    </Sidepeek>
  )
}

export default CreateOccupancyInspectionSidepeek
