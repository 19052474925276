import React, { FunctionComponent } from 'react'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import { formatPersonalDetails as format } from '../formats'
import { AddButton } from '../icons'
import { UserContactDetailsWithValidation } from '../types'
import NonEditMode from './NonEditMode'
import EditMode from './EditMode'

type MultiDetailRowPropTypes = {
  header: string
  content: UserContactDetailsWithValidation[]
  editable: boolean
  onEdit?: Function
  onAdd: React.MouseEventHandler<HTMLButtonElement>
  onDelete: Function
  onSetPrimary: Function
  onBlur: Function
}

const MultiDetailRow: FunctionComponent<React.PropsWithChildren<MultiDetailRowPropTypes>> = ({
  header,
  content,
  editable,
  onEdit = () => {},
  onAdd,
  onDelete,
  onSetPrimary,
  onBlur
}) => {
  return (
    <SC.DetailBoxWithBottomBorder>
      <SC.InfoHeading>{header}</SC.InfoHeading>
      <SC.EditDetailsBox>
        {content.map((item: UserContactDetailsWithValidation, index) => {
          const itemContent = format(item.contact_info).toString()

          return (
            <div key={`${header}-${index}`}>
              {!editable ? (
                <NonEditMode content={itemContent} primary={item.is_primary_for_contact_type} />
              ) : (
                <EditMode
                  content={itemContent}
                  primary={item.is_primary_for_contact_type}
                  addable
                  handleEdit={onEdit}
                  handleDelete={onDelete}
                  handleSetPrimary={onSetPrimary}
                  index={index}
                  isInvalid={!item.isValid}
                  onBlur={onBlur}
                />
              )}
            </div>
          )
        })}

        {editable ? AddButton(header.toLowerCase(), onAdd) : null}
      </SC.EditDetailsBox>
    </SC.DetailBoxWithBottomBorder>
  )
}

export default MultiDetailRow
