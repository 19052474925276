import {
  SET_EDIT_MODE,
  SET_PERSONAL_DETAILS,
  EDIT_FIRST_NAME,
  EDIT_LAST_NAME,
  ADD_PHONE,
  ADD_EMAIL,
  DELETE_PHONE,
  DELETE_EMAIL,
  SET_PRIMARY_PHONE,
  SET_PRIMARY_EMAIL,
  EDIT_PHONE,
  EDIT_EMAIL,
  SET_PHONE_VALIDITY,
  SET_EMAIL_VALIDITY
} from './actions'

export const initialState = {
  editMode: false,
  reexecuteQuery: false,
  first_name: '',
  last_name: '',
  emails: [],
  phones: []
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_EDIT_MODE: {
      return {
        ...state,
        editMode: action.mode
      }
    }
    case SET_PERSONAL_DETAILS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case EDIT_FIRST_NAME: {
      return {
        ...state,
        first_name: action.first_name
      }
    }
    case EDIT_LAST_NAME: {
      return {
        ...state,
        last_name: action.last_name
      }
    }
    case ADD_PHONE: {
      return {
        ...state,
        phones: [
          ...state.phones.concat({
            contact_info: '',
            is_primary_for_contact_type: !state.phones.length,
            isValid: true
          })
        ]
      }
    }
    case ADD_EMAIL: {
      return {
        ...state,
        emails: [
          ...state.emails.concat({
            contact_info: '',
            is_primary_for_contact_type: !state.emails.length,
            isValid: true
          })
        ]
      }
    }
    case DELETE_PHONE: {
      return {
        ...state,
        phones: [...state.phones.filter((number, i) => i !== action.index)]
      }
    }
    case DELETE_EMAIL: {
      return {
        ...state,
        emails: [...state.emails.filter((emailAddress, i) => i !== action.index)]
      }
    }
    case SET_PRIMARY_PHONE: {
      return {
        ...state,
        phones: [
          ...state.phones.map((number, i) =>
            i === action.index
              ? { ...number, is_primary_for_contact_type: true }
              : { ...number, is_primary_for_contact_type: false }
          )
        ]
      }
    }
    case SET_PRIMARY_EMAIL: {
      return {
        ...state,
        emails: [
          ...state.emails.map((emailAddress, i) =>
            i === action.index
              ? { ...emailAddress, is_primary_for_contact_type: true }
              : { ...emailAddress, is_primary_for_contact_type: false }
          )
        ]
      }
    }
    case EDIT_PHONE: {
      return {
        ...state,
        phones: [
          ...state.phones.map((number, i) =>
            i === action.index ? { ...number, contact_info: action.newPhone } : { ...number }
          )
        ]
      }
    }
    case EDIT_EMAIL: {
      return {
        ...state,
        emails: [
          ...state.emails.map((emailAddress, i) =>
            i === action.index
              ? { ...emailAddress, contact_info: action.newEmail.trim() }
              : { ...emailAddress }
          )
        ]
      }
    }
    case SET_PHONE_VALIDITY: {
      return {
        ...state,
        phones: [
          ...state.phones.map((number, i) =>
            i === action.index ? { ...number, isValid: action.isValid } : { ...number }
          )
        ]
      }
    }
    case SET_EMAIL_VALIDITY: {
      return {
        ...state,
        emails: [
          ...state.emails.map((emailAddress, i) =>
            i === action.index ? { ...emailAddress, isValid: action.isValid } : { ...emailAddress }
          )
        ]
      }
    }
    default:
      return state
  }
}
