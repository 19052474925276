import { CashoutsQuery } from 'graphql/generated'
import { ColDef, ICellRendererParams } from 'ag-grid-community'

import React, { FC } from 'react'
import BaseGrid from 'components/TailwindUIToolkit/Grid/BaseGrid'
import { useHistory } from 'react-router-dom'

type Cashout = CashoutsQuery['cashouts'][number]
interface CashoutListProps {
  cashouts: Cashout[]
}

// address, move_out_date, status, balance, wallet_value, action
const columns: ColDef<Cashout>[] = [
  {
    colId: 'address',
    valueGetter: (node) => {
      return node.data?.rental?.portfolio_home?.home?.address || {}
    },
    cellRenderer: (params: ICellRendererParams<Cashout>) => {
      const userName = params.node.data?.user.first_name + ' ' + params.node.data?.user.last_name
      const { display_line_1, display_line_2 } = params.value
      return (
        <div className='flex flex-col'>
          {display_line_1}&nbsp;
          {display_line_2}&nbsp;
          <span className='text-xs text-gray-500'>{userName}</span>
        </div>
      )
    },
    headerName: 'Address',
    flex: 1
  },
  { field: 'move_out_date', headerName: 'Move Out Date', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 1 },
  { field: 'balance', headerName: 'Balance', flex: 1 },
  { field: 'wallet_value', headerName: 'Wallet Value', flex: 1 },
  { field: 'action', headerName: 'Action', flex: 1 }
]
export const CashoutList: FC<React.PropsWithChildren<CashoutListProps>> = ({ cashouts }) => {
  const history = useHistory()
  return (
    <BaseGrid<CashoutsQuery['cashouts'][number]>
      columns={columns}
      rowData={cashouts}
      onRowDoubleClicked={(params) => {
        history.push(`/rent-roll/${params.data?.rental.id}/cashout`)
      }}
    />
  )
}
