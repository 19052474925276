import React from 'react'
import { get, startCase } from 'lodash'
import { Alert, Button, Spinner } from 'reactstrap'
import { properties } from '@homevest/utils'
import { Link } from 'react-router-dom'
import { useQuery } from 'urql'
import moment from 'moment'
import ListItemToggle from './ItemInfo'
import { UpupApplicationListDocument, UpupApplicationListQueryVariables } from 'graphql/generated'
import { REQUEST_POLICY } from 'constants/urql'

const { homevestStatus } = properties

type Props = {
  onItemCount: (items: number) => void
  variables: UpupApplicationListQueryVariables
}

const List: React.FC<React.PropsWithChildren<Props>> = ({ variables, onItemCount }) => {
  const [{ data, fetching, error }] = useQuery({
    query: UpupApplicationListDocument,
    variables,
    requestPolicy: REQUEST_POLICY.NETWORK_ONLY
  })
  const [openId, setOpenId] = React.useState<string | null>(null)

  React.useEffect(() => {
    onItemCount(data?.rental_applications?.length ?? 0)
  }, [onItemCount, data?.rental_applications?.length])

  if (error != null) {
    return (
      <PaddedRow>
        <Alert color='danger'>
          <div>There was a problem</div>
          <div>
            <code>
              {error.name}: {error.message}
            </code>
          </div>
        </Alert>
      </PaddedRow>
    )
  }

  if (fetching || data == null) {
    return (
      <PaddedRow>
        <Spinner color='primary' />
      </PaddedRow>
    )
  }

  if (data?.rental_applications.length < 1) {
    return (
      <PaddedRow>
        <b>No results found</b>
      </PaddedRow>
    )
  }

  return (
    <>
      {data.rental_applications.map((application: any) => {
        const activeAgreement = application.agreements[0]
        const isPriority = application.mls_listing.internal_attributes.find(
          (prop: any) => prop.internal_status === homevestStatus.internallyListed
        )
        const occupancyDate = get(
          activeAgreement,
          'metadata.custom_fields.startDate',
          get(activeAgreement, 'metadata.startDate', application.projected_occupancy_date)
        )

        return (
          <React.Fragment key={application.id}>
            <div
              style={{
                paddingBottom: '0.25rem',
                gridColumnStart: 1,
                gridColumnEnd: 'end'
              }}
            />
            <div style={{ gridColumnStart: 1 }}>
              <Link
                to={`/applications/${application.id as string}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {application.mls_listing.display_line_1}
              </Link>
            </div>
            <div style={{ textAlign: 'center' }}>{REGION_MAP[application.mls_listing.region!]}</div>
            <div>
              {(application.lead_group.lead_group_users ?? []).map(({ user }: any) => (
                <div key={user.id}>
                  {user.first_name} {user.last_name}
                </div>
              ))}
            </div>
            <div>{occupancyDate && moment(occupancyDate).format('MMM D, YYYY')}</div>
            <div style={{ textAlign: 'center' }}>{isPriority !== undefined && 'Yes'}</div>
            <div>{startCase(application.status)}</div>
            <div>{moment(application.created_at).format('MMM D, YYYY')}</div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Button
                outline
                color='dark'
                size='sm'
                onClick={() => setOpenId(openId === application.id ? null : application.id)}
              >
                {openId === application.id ? '-' : '+'}
              </Button>
            </div>
            {openId === application.id && (
              <ListItemToggle leadGroupUsers={application.lead_group.lead_group_users} />
            )}
            <div
              style={{
                paddingTop: '0.25rem',
                gridColumnStart: 1,
                gridColumnEnd: 'end',
                borderBottom: '1px solid #eee'
              }}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

export default List

const REGION_MAP = {
  'atlanta-ga': 'ATL',
  'st-louis-mo': 'STL',
  'los-angeles-ca': 'LA'
} as { [key: string]: string }

const PaddedRow: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div
    style={{
      gridColumnStart: 1,
      gridColumnEnd: 'end',
      paddingTop: '2rem',
      paddingBottom: '2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    {children}
  </div>
)
