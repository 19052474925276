import React, { memo, useEffect, useState } from 'react'
import moment from 'moment'

import axios from 'lib/axios'
import { formatMoney } from 'lib/numbers'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { HStack, Stack } from '@chakra-ui/react'

function PaymentSchedule({
  occupancyDate: propsOccupancyDate,
  rent,
  rentalApplicationId
}: {
  occupancyDate: Date | string
  rent?: number | null
  rentalApplicationId: string
}) {
  const [moveInCalculation, setMoveInCalculation] = useState<any>(null)
  const occupancyDate = moment(propsOccupancyDate)
  const occupancyDateString = occupancyDate.format('YYYY-MM-DD')

  useEffect(() => {
    async function run() {
      try {
        const { data } = await axios.get(
          `/admin/rental_applications/${rentalApplicationId}/move_in_payment?rent=${rent}&occupancy_date=${occupancyDateString}`
        )
        setMoveInCalculation(data)
      } catch (e) {
        console.log('Unable to retrieve move in payment info', e)
      }
    }
    run()
  }, [])

  if (!rent || !moveInCalculation) {
    return null
  }

  return (
    <ContentSectionCard padding={true} title={'Move In Payment Schedule'}>
      <HStack alignItems='flex-start' spacing={12}>
        <Stack>
          <div className='pb-1 text-lg font-bold'>Initial Payment (Today)</div>
          <div>Initial Deposit: {formatMoney(moveInCalculation.initial_payment, 2, '$')}</div>
          <div className='font-semibold'>
            Total: {formatMoney(moveInCalculation.initial_payment, 2, '$')}
          </div>
        </Stack>
        <Stack>
          <div className='pb-1'>
            <span className='text-lg font-bold'>Before Move-In</span>
            {' ('}assuming move-in date {occupancyDate.format('MMM DD, YYYY')}
            {')'}
          </div>
          <div>Prorated Rent: {formatMoney(moveInCalculation.prorated_rent, 2, '$')}</div>
          {moveInCalculation.next_month_rent > 0 && (
            <div>Next Month's Rent: {formatMoney(moveInCalculation.next_month_rent, 2, '$')}</div>
          )}
          {moveInCalculation.prorated_add_ons.length > 0 &&
            moveInCalculation.prorated_add_ons.map((ao: any) => (
              <div>
                Prorated {ao.name}: {formatMoney(ao.amount, 2, '$')}
              </div>
            ))}
          {moveInCalculation.next_month_add_ons.length > 0 &&
            moveInCalculation.next_month_add_ons.map((ao: any) => (
              <div>
                Prorated {ao.name}: {formatMoney(ao.amount, 2, '$')}
              </div>
            ))}
          {moveInCalculation.remaining_initial_contribution > 0 && (
            <div>
              Remaining Wallet Contribution:{' '}
              {formatMoney(moveInCalculation.remaining_initial_contribution, 2, '$')}
            </div>
          )}
          {moveInCalculation.remaining_initial_deposit > 0 && (
            <div>
              Remaining Security Deposit:{' '}
              {formatMoney(moveInCalculation.remaining_initial_deposit, 2, '$')}
            </div>
          )}
          {moveInCalculation.credit_card_fee > 0 && (
            <div>Card Processing Fee: {formatMoney(moveInCalculation.credit_card_fee, 2, '$')}</div>
          )}
          <div className='font-semibold'>
            Total: {formatMoney(moveInCalculation.total_move_in_payment_with_fees, 2, '$')}
          </div>
        </Stack>
      </HStack>
    </ContentSectionCard>
  )
}

export default memo(PaymentSchedule)
