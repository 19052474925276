import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useSubscription } from 'urql'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'

import PropTypes from 'prop-types'

import { Spinner, Table } from 'reactstrap'
import { Comparators as Comp } from 'lib/hasura-comparators'
import { debounce, get } from 'lodash'

import { hasCapability } from 'lib/admin-perms'
import { getContactInfo } from 'lib/users'
import { BodyText, Button, ErrorText, HeaderText, Input } from 'components/Toolkit'
import { UpupUserListDocument as query } from 'graphql/generated'

import MergeModal from './MergeModal'

const { USER_MANAGER } = capabilities.CAPABILITY_TYPES

const StyledContainer = styled.div`
  padding: 20px;
  height: calc(100vh - 248px);
  width: 100%;
`

function UserEntries({ sourceUser, searchText = '' }) {
  const [destinationUser, setDestinationUser] = useState(null)

  const [{ data }] = useSubscription({
    query,
    variables: {
      where: {
        // Do not allow to pick the same user
        id: { _neq: sourceUser.id },
        user_searchable: {
          info: Comp.isLike(`%${(searchText || '').replace(/\s/g, '')}%`)
        }
      }
    }
  })

  const users = data?.users || []

  // We have a source and destination picked, render a Modal
  if (sourceUser && destinationUser) {
    return (
      <div>
        <MergeModal
          destinationUser={destinationUser}
          sourceUser={sourceUser}
          close={() => setDestinationUser(null)}
          isOpen={true}
        />
      </div>
    )
  }

  return (
    <div>
      <Table responsive='true'>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {users.map(
            (
              {
                id,
                first_name: firstName,
                last_name: lastName,
                email,
                phone,
                user_contact_details
              },
              idx
            ) => {
              return (
                <tr key={id}>
                  <td>
                    <Link
                      target='_blank'
                      to={{
                        pathname: `/users/${id}`
                      }}
                    >
                      {id}
                    </Link>
                  </td>
                  <td>
                    {firstName} {lastName}
                  </td>
                  <td>{getContactInfo('email', user_contact_details)}</td>
                  <td>{getContactInfo('phone', user_contact_details)}</td>
                  <td style={{ minWidth: '100px' }}>
                    <Button
                      size='vvs'
                      onClick={() => {
                        setDestinationUser(users[idx])
                      }}
                    >
                      Merge
                    </Button>
                  </td>
                </tr>
              )
            }
          )}
        </tbody>
      </Table>
    </div>
  )
}

UserEntries.propTypes = {
  searchText: PropTypes.string.isRequired,
  sourceUser: PropTypes.object.isRequired
}

function Merge({ user }) {
  const [searchText, setSearchText] = useState('')
  const [inputText, setInputText] = useState('')

  const setSearchTextDebounced = React.useMemo(
    () => debounce(setSearchText, 250, { trailing: true }),
    [setSearchText]
  )

  const admin = useSelector((store) => store.admin)
  const isMergeAllowed = hasCapability(admin, USER_MANAGER)

  if (!isMergeAllowed) {
    return (
      <StyledContainer>
        <ErrorText>You do not have the permission to merge users in Hudson, 😢.</ErrorText>
      </StyledContainer>
    )
  }

  if (user.primary_user_id) {
    return (
      <StyledContainer>
        <BodyText>
          {user.first_name} was merged into{' '}
          <Link to={`/users/${user.primary_user_id}`} target='_blank' rel='noopener noreferrer'>
            {' '}
            {user.primary_user.first_name} {user.primary_user.last_name}
          </Link>{' '}
          on {moment(user.merged_at).format('MM/DD/yyyy @ hh:mm A')} by{' '}
          {get(user, 'merged_by_admin.first_name', 'System')}{' '}
          {get(user, 'merged_by_admin.last_name')}.
        </BodyText>
      </StyledContainer>
    )
  }

  return (
    <StyledContainer>
      <HeaderText size='h3'>Merge A User</HeaderText>
      <BodyText>
        Please select a destination user to merge <b>{user.id}</b> into:
      </BodyText>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '40px'
        }}
      >
        <div style={{ width: '600px', marginRight: '20px' }}>
          <Input
            placeholder='Search...'
            value={inputText}
            onChange={(value) => {
              // We need two separate values so we can debounce to the subscription
              setInputText(value)
              setSearchTextDebounced(value)
            }}
          />
        </div>
      </div>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <UserEntries sourceUser={user} searchText={searchText} />
      </React.Suspense>
    </StyledContainer>
  )
}

Merge.propTypes = {
  user: PropTypes.object.isRequired
}

export default Merge
