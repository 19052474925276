import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'

const StyledContainer = styled.div`
  background: ${COLORS.ACCENTS.SARDINE};
  height: 6px;
  position: relative;
`

const StyledProgress = styled.div`
  background: ${COLORS.PRIMARY.PLUM};
  height: 6px;
  position: absolute;
  width: ${(props) => (props.step / props.total) * 100}%;
`

function ProgressBar({ step, total }) {
  return (
    <StyledContainer>
      <StyledProgress step={step} total={total} />
    </StyledContainer>
  )
}

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

export default memo(ProgressBar)
