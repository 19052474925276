import React, { FunctionComponent } from 'react'
import { ContentSectionCard, CreateNoteButtonWithModal } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import { format, compareDesc } from 'date-fns'
import { TimelineEvent, NoteTimelineEvent } from '@homevest/timelines/build/utils/types'

type NoteComponentPropTypes = {
  events?: TimelineEvent[]
  userId: string
}

const isNoteTimelineEvent = (event: TimelineEvent): event is NoteTimelineEvent => {
  return event.eventType === 'note'
}

const Note: FunctionComponent<React.PropsWithChildren<NoteComponentPropTypes>> = ({
  events,
  userId
}) => {
  return (
    <ContentSectionCard
      title={'Notes'}
      action={<CreateNoteButtonWithModal resourceId={userId} resourceType='users' />}
    >
      <SC.StyledTable>
        <colgroup>
          <col width={'60%'} />
          <col width={'20%'} />
          <col width={'20%'} />
        </colgroup>
        <SC.StyledTableHeader>
          <tr>
            <SC.StyledTableHeadData>Note</SC.StyledTableHeadData>
            <SC.StyledTableHeadData>Date created</SC.StyledTableHeadData>
            <SC.StyledTableHeadData>Posted by</SC.StyledTableHeadData>
          </tr>
        </SC.StyledTableHeader>
        <tbody>
          {events
            ? events
                .filter(isNoteTimelineEvent)
                .sort((a, b) => compareDesc(new Date(a.eventTimeAt), new Date(b.eventTimeAt)))
                .map((note: NoteTimelineEvent) => {
                  return (
                    <SC.StyledTableRow key={note.eventId}>
                      <SC.StyledTableData>{note.content}</SC.StyledTableData>
                      <SC.StyledTableData>
                        {format(new Date(note.eventTimeAt), 'PPp')}
                      </SC.StyledTableData>
                      <SC.StyledTableData>{note.createdByAdminName}</SC.StyledTableData>
                    </SC.StyledTableRow>
                  )
                })
            : null}
        </tbody>
      </SC.StyledTable>
    </ContentSectionCard>
  )
}

export default Note
