// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React from 'react'
import { Spinner } from 'reactstrap'
import { properties } from '@homevest/utils'
import { Comparators as Comp } from 'lib/hasura-comparators'

import ListItems from './ListItems'
import ListHeaders from './ListHeaders'
import { UpupApplicationListQueryVariables, Order_By as OrderBy } from 'graphql/generated'

const {
  homevestStatus: { homevested, internallyListed }
}: { homevestStatus: { [id: string]: string } } = properties

type HeaderState = {
  pageCount: number
  pageOffset: number
  pageSize: number
  status?: string[]
  priority?: boolean
  address?: string
  name?: string
  created?: OrderBy.Desc | OrderBy.Asc
  occupancy?: OrderBy.DescNullsLast | OrderBy.Asc
  region?: string
}

const List: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [itemCount, setItemCount] = React.useState<number>(0)
  const [variables, setVariables] = React.useState<UpupApplicationListQueryVariables>({})
  const [headers, setHeaders] = React.useState<HeaderState>({
    pageCount: 1,
    pageOffset: 0,
    pageSize: 50,
    created: OrderBy.Desc
  })

  React.useEffect(() => {
    const orderBy = {}

    // We only want to sort by EITHER created or occupancy
    if (headers.occupancy) {
      orderBy.projected_occupancy_date = headers.occupancy
    } else if (headers.created) {
      orderBy.created_at = headers.created
    }

    setVariables({
      limit: headers.pageSize,
      offset: headers.pageOffset * headers.pageSize,
      order_by: orderBy,
      where: getApplicationsListQueryWhereVariable(
        headers.status,
        headers.priority,
        headers.address,
        headers.name,
        headers.region
      )
    })
  }, [headers])

  return (
    <div
      style={{
        display: 'grid',
        columnGap: '0.25rem',
        gridTemplateColumns: '4fr 1fr 3fr 2fr 1fr 3fr 2fr 1fr'
      }}
    >
      <ListHeaders
        pages={headers.pageCount}
        pageSize={headers.pageSize}
        itemCount={itemCount}
        pageOffset={headers.pageOffset}
        onPageChange={(pageOffset) => {
          setItemCount(0)
          const pageCount = Math.max(headers.pageCount, pageOffset + 1)
          setHeaders({ ...headers, pageOffset, pageCount })
        }}
        onHeaderChange={(key, value) => {
          setItemCount(0)
          const reset = { pageOffset: 0, pageCount: 1 }
          setHeaders({ ...headers, [key]: value, ...reset })
        }}
        variables={variables}
      />

      <React.Suspense
        fallback={
          <div
            style={{
              gridColumnStart: 1,
              gridColumnEnd: 'end',
              paddingTop: '2rem',
              paddingBottom: '2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <ListItems variables={variables} onItemCount={setItemCount} />
      </React.Suspense>
    </div>
  )
}

export default List

const getApplicationsListQueryWhereVariable = (
  status: string[] = [],
  priority?: boolean,
  addressSearch?: string,
  nameSearch?: string,
  region?: string
): UpupApplicationListQueryVariables['where'] => ({
  ...((nameSearch?.length ?? 0) < 2
    ? {}
    : {
        lead_group: {
          lead_group_users: {
            user_searchable: {
              name: Comp.isLike(`%${nameSearch.replace(/\s/g, '')}%`)
            }
          }
        }
      }),
  ...((addressSearch?.length ?? 0) < 2 && typeof priority !== 'boolean'
    ? {}
    : {
        mls_listing: {
          ...(addressSearch?.length < 2
            ? {}
            : { display_line_1: Comp.isLike(`%${addressSearch || ''}%`) }),
          ...(typeof priority !== 'boolean'
            ? {}
            : {
                internal_attributes: {
                  internal_status: priority
                    ? Comp.isIn([internallyListed, homevested])
                    : Comp.isNotIn([internallyListed, homevested])
                }
              })
        }
      }),
  ...(status?.length < 1 ? {} : { status: Comp.isIn(status) }),
  ...((region?.length ?? 0) < 1
    ? {}
    : {
        mls_listing: {
          region: Comp.isEqual(region)
        }
      })
})
