import React, { useEffect, useState, FunctionComponent } from 'react'
import { RouteComponentProps, useParams } from 'react-router'
import { Tabs, TabList, TabPanels, TabPanel, Tab, Stack } from '@chakra-ui/react'
import { types as TimelineTypes } from '@homevest/timelines'
import { Helmet } from 'react-helmet'

import { useHomeByIdQuery } from 'graphql/generated'
import HomeHeader from './HomeHeader'
import { HudsonPathPart } from 'components/TailwindUIToolkit/Navigation/BreadcrumbBar'
import { ConstructionPanel } from './Panels/ConstructionPanel'
import { OverviewPanel } from './Panels/OverviewPanel'
import { WalkthroughsPanel } from './Panels/WalkthroughsPanel'
import { DocumentsPanel } from './Panels/DocumentsPanel'
import { UtilitiesPanel } from './Panels/UtilitiesPanel'
import { TwoColumn } from 'components/TailwindUIToolkit'
import { MessageText } from 'components/TailwindUIToolkit/StyledComponents'
import first from 'lodash/first'
import { DynamicWidthPageContainer } from 'components/TailwindUIToolkit/Layouts/DynamicWidthPageContainer'
import axios from 'lib/axios'
import { TimelineCard } from './Cards/TimelineCard'
import { Loading } from 'ui'

const TABS = ['Overview', 'Construction', 'Property Visits', 'Documents', 'Utilities', 'Timeline']
const PATH_TO_PAGE: HudsonPathPart[] = [
  {
    name: 'Homes',
    href: '/homes'
  }
]

type TimelineResponse = TimelineTypes.TimelineResponse

const HomeProfile: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { propertyId } = useParams<{ propertyId: string }>()
  const [{ data }] = useHomeByIdQuery({ variables: { homeId: propertyId } })
  const [fetchingTimeline, setFetchingTimeline] = useState(false)
  const [timelineResponse, setTimelineResponse] = useState<TimelineResponse>()

  useEffect(() => {
    setFetchingTimeline(true)
    axios.get(`/360/homes/${propertyId}/timeline`).then((response) => {
      setTimelineResponse(response.data as TimelineResponse)
      setFetchingTimeline(false)
    })
  }, [propertyId])

  const home = first(data?.homes360)

  if (!home) {
    return <MessageText>Could not find property with id: {propertyId}</MessageText>
  }

  const mainContent = (
    <Tabs>
      <TabList className='flex space-x-4 border-0 border-b border-t border-solid border-gray-200'>
        {TABS.map((tab) => (
          <Tab key={tab}>{tab}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {[
          <OverviewPanel
            portfolioHomeId={home.portfolio_home?.id}
            acquisitionId={home.portfolio_home?.real_estate_acquisition?.id}
          />,
          <ConstructionPanel portfolioHomeId={home.portfolio_home?.id} />,
          <WalkthroughsPanel portfolioHomeId={home.portfolio_home?.id} />,
          <DocumentsPanel portfolioHomeId={home.portfolio_home?.id} />,
          <UtilitiesPanel portfolioHomeId={home.portfolio_home?.id} />,
          <TimelineCard
            events={timelineResponse?.timeline}
            loading={fetchingTimeline}
            home={home}
          />
        ].map((element, idx) => (
          <TabPanel className='flex flex-col gap-6' key={TABS[idx]}>
            <Stack direction='column' gap='6'>
              {element}
            </Stack>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )

  return (
    <>
      <Helmet>
        <title>Home | {home.display_line_1}</title>
      </Helmet>
      <DynamicWidthPageContainer
        pathToPage={[
          ...PATH_TO_PAGE,
          {
            name: home?.display_line_1 || '<address>',
            href: `#`
          }
        ]}
      >
        <TwoColumn
          sidebarSide='left'
          sidebarContent={<HomeHeader home={home} />}
          mainContent={mainContent}
          useRowsOnSmallScreens
        />
      </DynamicWidthPageContainer>
    </>
  )
}

const HomeProfileWrapper: FunctionComponent<React.PropsWithChildren<RouteComponentProps>> = (
  props
) => (
  <React.Suspense fallback={<Loading />}>
    <HomeProfile {...props} />
  </React.Suspense>
)

export default HomeProfileWrapper
