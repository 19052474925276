"use strict";
exports.SELLER_AGENT_REJECTION_REASONS = Object.freeze({
    BLACKLISTED_BROKERAGE: 'blacklisted_brokerage',
    CO_AGENT_OWNS_LISTING: 'co_agent_owns_listing',
    HOME_HAS_OFFER: 'home_has_offer',
    RENT_RESTRICTIONS: 'rent_restrictions',
    REQUIRES_HARD_OFFER: 'requires_hard_offer',
    SELLER_AGENT_DECLINED: 'seller_agent_declined',
    SELLER_AGENT_UNRESPONSIVE: 'seller_agent_unresponsive',
    TAKING_OFF_MARKET: 'taking_off_market',
    UNDER_CONTRACT: 'under_contract',
    UP_AND_UP_DECLINED: 'upandup_declined'
});
exports.SELLER_AGENT_PROPERTY_APPROVAL_STATUSES = Object.freeze({
    APPROVED: 'approved',
    PENDING_HOME_OWNER_APPROVAL: 'pending_home_owner_approval',
    PENDING_SELLER_AGENT_APPROVAL: 'pending_seller_agent_approval',
    REJECTED: 'rejected'
});
