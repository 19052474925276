import React from 'react'
import { agreementSigners } from '@homevest/utils'
import { Box, HStack } from '@chakra-ui/react'

import axios from 'lib/axios'
import hellosign from 'lib/hellosign'
import SignButton from 'components/RentRoll/Details/Agreements/SignButton'

const { ROLES, AGREEMENT_SIGNER_STATUSES } = agreementSigners

type AgreementSigner = {
  id: string
  external_id: string
  status: string
  role: string
}

const SignSection: React.FC<
  React.PropsWithChildren<{
    agreementWithSigners?: { agreement_signers?: AgreementSigner[] } | null
    onSign: () => Promise<void>
  }>
> = ({ agreementWithSigners, onSign }) => {
  if (!agreementWithSigners) {
    return null
  }

  const agreementSigners = agreementWithSigners?.agreement_signers ?? []
  const landlordSigner = agreementSigners.find((signer) => signer.role === ROLES.LANDLORD)
  const needsLandlordSignature =
    landlordSigner?.status === AGREEMENT_SIGNER_STATUSES.PENDING_SIGNATURE

  const agentSigner = agreementSigners.find((signer) => signer.role === ROLES.AGENT)
  const needsAgentSignature = agentSigner?.status === AGREEMENT_SIGNER_STATUSES.PENDING_SIGNATURE

  const launchAgreement = async (signer: AgreementSigner) => {
    const signingId = signer.external_id

    const {
      data: { sign_url: signedUrl }
    } = await axios.get(`/hellosign/sign_url/${signingId}`)

    hellosign.on('sign', onSign)

    hellosign.open(signedUrl)
  }

  return (
    <Box>
      <HStack>
        {needsLandlordSignature && (
          <SignButton
            launchAgreement={launchAgreement}
            signer={landlordSigner}
            buttonOptions={{ mr: 2 }}
          />
        )}
        {needsAgentSignature && (
          <SignButton launchAgreement={launchAgreement} signer={agentSigner} />
        )}
      </HStack>
    </Box>
  )
}

export default SignSection
