import React from 'react'
import PropTypes from 'prop-types'

import CreateRentalLiabilityForm from './Form'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'

CreateRentalLiabilityModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  rentalId: PropTypes.string.isRequired
}

export default function CreateRentalLiabilityModal({
  close,
  isOpen,
  rentalId,
  occupancyDate,
  finalLiabilityDate
}: {
  close: () => void
  isOpen: boolean
  rentalId: string
  occupancyDate: string
  finalLiabilityDate?: string
}) {
  return (
    <>
      <Modal size='6xl' isOpen={isOpen} onClose={close}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Rental Liabilities</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CreateRentalLiabilityForm
              onSubmit={close}
              rentalId={rentalId}
              occupancyDate={occupancyDate}
              finalLiabilityDate={finalLiabilityDate}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={close}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
