import React from 'react'

import { useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { BuyersInspection } from '@homevest/types/buyers-inspections'

import { Sidepeek } from 'ui'

import axios from 'lib/axios'
import { BuyersInspectionFormData, validateBuyersInspectionForm } from 'lib/buyers-inspections'
import BuyersInspectionForm from './BuyersInspectionForm'

const BuyersInspectionSidepeek = ({
  portfolioHomeId,
  isOpen,
  onSubmit,
  onClose
}: {
  portfolioHomeId: string
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}) => {
  const toast = useToast()
  const { mutate: createBuyersInspection } = useMutation({
    mutationFn: async (data: BuyersInspectionFormData) => {
      return axios.post<BuyersInspection>('/admin/buyers_inspections', data)
    },
    onSuccess: () => {
      toast({
        title: "Buyer's Inspection Created",
        status: 'success',
        duration: 5000,
        position: 'bottom-right'
      })
      onSubmit()
      onClose()
    },
    onError: (error: any) => {
      toast({
        title: "Error Creating Buyer's Inspection",
        description: error?.message || 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        position: 'bottom-right'
      })
    }
  })

  return (
    <Sidepeek isOpen={isOpen} onClose={onClose} title={"Create Buyer's Inspection"}>
      <BuyersInspectionForm
        portfolioHomeId={portfolioHomeId}
        onSubmit={(data) => createBuyersInspection(data)}
        validator={validateBuyersInspectionForm}
      />
    </Sidepeek>
  )
}

export default BuyersInspectionSidepeek
