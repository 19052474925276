import React from 'react'
import startCase from 'lodash/startCase'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import {
  ConstructionProjectsFragment,
  useConstructionByPortfolioHomeIdQuery
} from 'graphql/generated'
import { Link } from 'react-router-dom'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import Error from 'components/Toolkit/Text/Error'
import { formatNullableDateString } from 'lib/date-time'
import { BaseGrid } from 'components/TailwindUIToolkit'
import { LINK_COLOR } from 'constants/colors'
import { HStack } from '@chakra-ui/react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

export type ConstructionProject = ConstructionProjectsFragment

const columns: ColDef<ConstructionProject>[] = [
  {
    headerName: 'Type',
    field: 'type',
    cellRenderer: (params: ICellRendererParams<ConstructionProject, string>) => (
      <Link
        target='_blank'
        rel='noopener noreferrer'
        to={`/construction/projects/${params.data?.id}`}
        style={{ color: LINK_COLOR }}
      >
        <HStack>
          <div>{startCase(params.value)}</div>
          <ArrowTopRightOnSquareIcon height='16px' />
        </HStack>
      </Link>
    ),
    flex: 2
  },
  {
    headerName: 'Start',
    colId: 'created',
    field: 'start_date',
    sort: 'desc',
    valueFormatter: (v) => formatNullableDateString(v.value),
    flex: 2
  },
  {
    headerName: 'Completed',
    colId: 'completed',
    field: 'end_date',
    valueFormatter: (v) => formatNullableDateString(v.value),
    flex: 2
  },
  {
    headerName: 'Status',
    colId: 'status',
    field: 'status',
    cellStyle: { padding: 0 },
    cellRenderer: (params: ICellRendererParams<ConstructionProject, string>) =>
      mapContentToBadge(params.value),
    flex: 2
  }
]

interface GridProps {
  portfolioHomeId: string
}

export const Grid: React.FC<React.PropsWithChildren<GridProps>> = ({ portfolioHomeId }) => {
  const [{ data, error }] = useConstructionByPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  if (error) {
    return <Error>{error.message}</Error>
  }

  const constructionProjects: ConstructionProject[] =
    data?.portfolio_homes_by_pk?.construction_projects || []

  if (!constructionProjects) {
    return <Error>could not find data</Error>
  }

  return <BaseGrid<ConstructionProject> columns={columns} rowData={constructionProjects} />
}
