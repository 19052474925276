import React, { ChangeEventHandler, Suspense, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from '@chakra-ui/modal'
import { Text, Divider, Heading, Stack, HStack, Link, Center } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { Checkbox, Input, Select, Spinner, Textarea, Tooltip } from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { ArrowTopRightOnSquare } from 'styled-icons/heroicons-solid'
import type {
  AdminCreateRentalReunderwritePayload,
  NewTenantConnectInfo
} from '@homevest/types/rental-reunderwrites'
import {
  ExistingRentalReunderwriteForRentalQuery,
  Note_Priorities_Enum,
  useExistingRentalReunderwriteForRentalQuery,
  useFindNotesByResourceIdsQuery,
  useRentalForRenewalQuery,
  useUpupInsertNoteMutation
} from 'graphql/generated'
import { LeftChevron } from 'components/Toolkit'
import { getRentalLeaseSigners } from 'lib/rentals'
import axios from 'lib/axios'
import { formatDate } from 'lib/date-time'
import { isObject, startCase } from 'lodash'

const tooltipText = `Use this screen to issue a new reunderwrite for this rental. A reunderwrite
should be issued if any previous agreement signers are being dropped from the
lease. Issuing a new reunderwrite will trigger new tenant underwrites for any
remaining renters/cosigners, and if any new renters are being added to the
lease, they will be emailed with a link to submit their materials.`

export const Reunderwrite = ({ rentalId, goBack }: { rentalId: string; goBack: () => void }) => {
  return (
    <Suspense
      fallback={
        <Center>
          <Spinner />
        </Center>
      }
    >
      <ReunderwriteContent rentalId={rentalId} goBack={goBack} />
    </Suspense>
  )
}

const ReunderwriteContent = ({ rentalId, goBack }: { rentalId: string; goBack: () => void }) => {
  const [{ data }] = useRentalForRenewalQuery({
    variables: { id: rentalId }
  })

  const [{ data: rentalUnderwrites, fetching: fetchingReunderwrite }, refetchReunderwrite] =
    useExistingRentalReunderwriteForRentalQuery({
      variables: { rentalId }
    })

  const existingRentalUnderwrite = rentalUnderwrites?.rental_reunderwrites?.[0]

  console.log({ existingRentalUnderwrite })

  const [newTenants, setNewTenant] = useState<NewTenantConnectInfo[]>([])
  const [removedTenantIds, setRemovedTenantIds] = useState<string[]>([])
  const [confirm, setConfirm] = useState(false)
  const [loading, setLoading] = useState(false)

  if (fetchingReunderwrite) {
    return <div>Loading...</div>
  }

  const rental = data?.rentals_by_pk

  if (!rental) {
    return <div>Something went wrong, not sure how you got here</div>
  }

  const addNewSigner = () => {
    setNewTenant((prev) => [...prev, { type: 'email', info: '' }])
  }

  const originalAgreementSigners = getRentalLeaseSigners(rental) || []
  const remainingSignerIds: AdminCreateRentalReunderwritePayload['remaining_tenant_user_ids'] =
    originalAgreementSigners
      .filter((signer) => !removedTenantIds.includes(signer.user?.id))
      .map((signer) => signer.user?.id || '')

  const stateToSubmit: AdminCreateRentalReunderwritePayload = {
    new_tenants: newTenants,
    rental_id: rentalId,
    remaining_tenant_user_ids: remainingSignerIds
  }

  const postNewReunderwrite = async () => {
    setLoading(true)
    const response = await axios.post('/admin/rental_reunderwrites', stateToSubmit)
    console.log({ response })
    setLoading(false)
    setConfirm(false)
    refetchReunderwrite({ requestPolicy: 'network-only' })
  }

  const cancelNewUnderwrite = async () => {
    setLoading(true)
    const response = await axios.post(
      '/admin/rental_reunderwrites/' + existingRentalUnderwrite?.id + '/cancel'
    )
    console.log({ response })
    setLoading(false)
    setConfirm(false)
    refetchReunderwrite({ requestPolicy: 'network-only' })
  }

  const agreementSignersElement = originalAgreementSigners.map((signer) => {
    const isRemoved = removedTenantIds.includes(signer.user?.id)
    const toggle = () => {
      if (isRemoved) {
        setRemovedTenantIds((prev) => prev.filter((id) => id !== signer.user?.id))
      } else {
        setRemovedTenantIds([...removedTenantIds, signer.user?.id || ''])
      }
    }

    return (
      <label key={signer.user.id} className='flex flex-row items-center justify-between'>
        <div className={'h-fit' + (isRemoved ? ' line-through' : '')} key={signer.user?.id}>
          {signer.user?.first_name} {signer.user?.last_name} - {signer.role}
        </div>
        <Button color={isRemoved ? 'green' : 'red'} size='sm' onClick={toggle}>
          {removedTenantIds.includes(signer.user?.id) ? 'Restore' : 'Remove'}
        </Button>
      </label>
    )
  })

  const newTenantsElement = newTenants.map((tenant, index) => {
    // for each tenant have a select to choose type, and then input for email or user id
    const setInfoType: ChangeEventHandler<HTMLSelectElement> = (e) => {
      setNewTenant((prev) => {
        prev[index].type = e.target.value as 'email' | 'id'
        return [...prev]
      })
    }
    const setNewTenantInfo: ChangeEventHandler<HTMLInputElement> = (e) => {
      setNewTenant((prev) => {
        prev[index].info = e.target.value
        return [...prev]
      })
    }
    const removeTenant = () => {
      setNewTenant((prev) => prev.filter((_, i) => i !== index))
    }
    return (
      <div key={index} className='flex flex-row items-center justify-between gap-2'>
        <div className='flex flex-row gap-0'>
          <Select w={32} onChange={setInfoType}>
            <option value='email'>Email</option>
            <option value='id'>ID</option>
          </Select>
          <Input onChange={setNewTenantInfo} value={tenant.info} />
        </div>
        <Button color='red' size='sm' onClick={removeTenant}>
          Remove
        </Button>
      </div>
    )
  })

  const newReunderwriteBody = (
    <Stack gap={2}>
      <Heading size='md'>Signers to Underwrite</Heading>
      <Stack gap='1'>
        {agreementSignersElement}
        {newTenantsElement}
        <Stack gap={2}>
          <Button colorScheme='gray' onClick={addNewSigner}>
            + New Signer
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )

  const existingReunderwriteElement = (
    <Stack>
      <Stack>
        <Heading size='sm'>Existing Reunderwrite</Heading>
        <Stack>
          {existingRentalUnderwrite?.rental_reunderwrite_tenant_underwrites.map(
            ({ tenant_underwrite }) => {
              const user = tenant_underwrite.user

              if (tenant_underwrite.submitted_at && user) {
                return (
                  <SubmittedTenantUnderwrite
                    key={tenant_underwrite.id}
                    tenantUnderwrite={tenant_underwrite}
                  />
                )
              }

              return (
                <div key={tenant_underwrite.id}>
                  {user ? (
                    <Link href={'/users/' + user.id}>
                      {user.first_name} {user.last_name}
                    </Link>
                  ) : (
                    tenant_underwrite.contact_email
                  )}
                  {' - '}
                  {user ? 'Pending submission' : 'Pending acceptance'}
                </div>
              )
            }
          )}
          <RentalReunderwriteNotes rentalReunderwriteId={existingRentalUnderwrite?.id} />
        </Stack>
      </Stack>
      {/* TODO: a box to add notes, and display previously attached notes */}
    </Stack>
  )

  const cancelButton = (
    <Button
      isLoading={loading}
      isDisabled={!confirm || loading}
      colorScheme='red'
      onClick={cancelNewUnderwrite}
    >
      Cancel
    </Button>
  )

  const submitButton = (
    <Button
      isLoading={loading}
      isDisabled={!confirm || loading}
      colorScheme='blue'
      onClick={postNewReunderwrite}
    >
      Submit
    </Button>
  )

  return (
    <ModalContent>
      <ModalHeader>
        <HStack>
          <Text>Issue or View a Rental Reunderwrite</Text>
          <Tooltip label={tooltipText} aria-label='A tooltip'>
            <InformationCircleIcon height='24' width='24' />
          </Tooltip>
        </HStack>
      </ModalHeader>
      <Divider />
      <ModalCloseButton />
      <ModalBody display='flex' flexDir='column'>
        {!existingRentalUnderwrite && newReunderwriteBody}
        {!!existingRentalUnderwrite && existingReunderwriteElement}
      </ModalBody>

      <ModalFooter>
        <Stack w='full'>
          <HStack w='full' justify='end'>
            <Checkbox
              w='fit-content'
              checked={confirm}
              onChange={(e) => setConfirm(e.target.checked)}
            >
              Confirm
            </Checkbox>
          </HStack>
          <HStack w='full' justify='space-between'>
            <Button flexGrow={1} colorScheme='gray' onClick={goBack}>
              <LeftChevron height='12' width='12' /> Back
            </Button>
            {existingRentalUnderwrite ? cancelButton : submitButton}
          </HStack>
        </Stack>
      </ModalFooter>
    </ModalContent>
  )
}

const DocumentLink = ({ docId, name }: { docId: string; name: string }) => {
  const openInNewTab = () => {
    axios.get(`/admin/documents/${docId}/url`).then(({ data }) => window.open(data.url, '_blank'))
  }
  return (
    <Link onClick={docId ? openInNewTab : undefined} w='fit-content'>
      {name}
      <ArrowTopRightOnSquare width={16} />
    </Link>
  )
}

type TenatnUnderwrite =
  ExistingRentalReunderwriteForRentalQuery['rental_reunderwrites'][0]['rental_reunderwrite_tenant_underwrites'][number]['tenant_underwrite']
const SubmittedTenantUnderwrite = ({
  tenantUnderwrite: { user, tenant_underwrite_documents, submitted_at }
}: {
  tenantUnderwrite: TenatnUnderwrite
}) => {
  const [open, setOpen] = useState(false)

  if (!user) {
    return <div>Unknown user</div>
  }

  const creditReport = user.credit_reports.at(0)
  const evictionReport = user.eviction_reports.at(0)
  const criminalReport = user.criminal_reports.at(0)

  return (
    <Stack>
      <div>
        <Link w='fit-content' href={'/users/' + user.id}>
          {user.first_name} {user.last_name}
        </Link>{' '}
        - <span className='italic'>Submitted on {formatDate(new Date(submitted_at))}</span>
      </div>
      {!open && (
        <Button size='sm' onClick={() => setOpen(true)}>
          View submission details
        </Button>
      )}
      {open && (
        <div className='flex flex-col gap-2 rounded bg-neutral-200 px-4 py-3'>
          <div className='font-bold underline'>Documents</div>
          {tenant_underwrite_documents.map(({ document: doc }) => (
            <DocumentLink key={doc.id} docId={doc.id} name={doc.friendly_name} />
          ))}
          <div className='font-bold underline'>Background Checks</div>
          <Report report={creditReport} reportType='credit' />
          <Report report={evictionReport} reportType='eviction' />
          <Report report={criminalReport} reportType='criminal' />
          <Button
            size='sm'
            variant='outline'
            colorScheme='blackAlpha'
            onClick={() => setOpen(false)}
          >
            Hide details
          </Button>
        </div>
      )}
    </Stack>
  )
}

const RentalReunderwriteNotes = ({ rentalReunderwriteId }: { rentalReunderwriteId: string }) => {
  const [{ data }, refetchNotes] = useFindNotesByResourceIdsQuery({
    variables: { resourceIds: [rentalReunderwriteId] }
  })
  const [{ fetching: creatingNote }, createNote] = useUpupInsertNoteMutation()
  const [noteToSubmit, setNoteToSubmit] = useState<string | null>(null)

  const notes = data?.notes ? [...data.notes] : []

  const adminId = useSelector((store: any) => store.admin?.id)

  return (
    <Stack>
      <Stack>
        <Heading size='sm'>Notes</Heading>
        {noteToSubmit !== null && (
          <>
            <div>Leave a note:</div>
            {/* adjustable size textarea */}
            <Textarea value={noteToSubmit} onChange={(e) => setNoteToSubmit(e.target.value)} />
            <HStack>
              <Button
                onClick={() => {
                  setNoteToSubmit(null)
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme='blue'
                isDisabled={!noteToSubmit || creatingNote}
                isLoading={creatingNote}
                flexGrow={1}
                onClick={async () => {
                  await createNote({
                    resource_type: 'rental_reunderwrite',
                    resource_id: rentalReunderwriteId,
                    note: noteToSubmit,
                    created_by_admin_id: adminId,
                    priority: Note_Priorities_Enum.Default
                  })
                  setNoteToSubmit(null)
                  refetchNotes({ requestPolicy: 'network-only' })
                }}
              >
                Add a note
              </Button>
            </HStack>
          </>
        )}
        {noteToSubmit === null && (
          <Button opacity={0.8} colorScheme='blue' onClick={() => setNoteToSubmit('')}>
            Add a note
          </Button>
        )}
      </Stack>
      {notes.length > 0 && (
        <Stack>
          <div className='flex max-h-48 flex-col gap-2 overflow-y-auto rounded border bg-neutral-100 p-2'>
            {notes.length &&
              notes.reverse().map((note) => (
                <Stack key={note.id} className='roudned gap-2 rounded border bg-white p-2'>
                  <pre className='font-sans'>{note.note}</pre>
                  <div className='text-sm italic'>
                    {note.admin.first_name} {note.admin.last_name} -{' '}
                    {formatDate(new Date(note.created_at))}
                  </div>
                </Stack>
              ))}
          </div>
        </Stack>
      )}
    </Stack>
  )
}

const Report = ({
  report,
  reportType
}: {
  report?: { document_id?: string; score?: number | null; created_at: Date; matches?: object[] }
  reportType: string
} & { [_k: string]: any }) => {
  const [matchesOpen, setMatchesOpen] = useState(false)
  return (
    <Stack>
      <HStack>
        <div>{startCase(reportType)} report: </div>
        {report?.score && <div className='underline'>Score: {report.score}</div>}
        {report && report.document_id && (
          <DocumentLink docId={report.document_id} name='Document' />
        )}
        {!report && <div>Pending...</div>}
        {report && !report.matches && <div className='text-sm'>✅ No matches found ✅</div>}
      </HStack>
      {report && report.matches && (
        <>
          {matchesOpen && (
            // trying to display arbitrary JSON data as reasonably as possible here, forgive the mess
            <Stack>
              {report.matches.map((match: object, i) => (
                <Stack key={i} className='rounded border bg-neutral-100 p-4'>
                  {Object.entries(match).map(([key, value]) => (
                    <div key={key}>
                      {startCase(key)}:{' '}
                      {isObject(value) ? <pre>{JSON.stringify(value, undefined, 2)}</pre> : value}
                    </div>
                  ))}
                </Stack>
              ))}
            </Stack>
          )}
          <Button
            size='sm'
            variant='outline'
            colorScheme='blackAlpha'
            onClick={() => setMatchesOpen(!matchesOpen)}
          >
            {matchesOpen ? 'Hide' : 'Show'} matches
          </Button>
        </>
      )}
      {report && (
        <div className='text-sm italic'>Ran report on: {formatDate(report.created_at)}</div>
      )}
    </Stack>
  )
}
