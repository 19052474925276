import { Nullable } from '@homevest/types/utils'
import { BuyersInspection } from '@homevest/types/buyers-inspections'

export type BuyersInspectionFormData = Nullable<
  Pick<
    BuyersInspection,
    | 'portfolio_home_disposition_bid_id'
    | 'status'
    | 'submitted_by_buyer_at'
    | 'vendor_bids_sent_at'
    | 'counter_submitted_at'
    | 'counter_deadline_date'
    | 'counter_response_received_at'
    | 'counter_response_type'
  >
>

export type ValidationResponse = {
  isValid: boolean
  errors: Record<keyof BuyersInspectionFormData, string>
}

const REQUIRED_FIELDS = [
  'portfolio_home_disposition_bid_id',
  'status',
  'submitted_by_buyer_at',
  'counter_deadline_date'
] as const

export const validateBuyersInspectionForm = (
  data: BuyersInspectionFormData
): ValidationResponse => {
  let isValid = true
  const errors = {} as Record<keyof BuyersInspectionFormData, string>

  REQUIRED_FIELDS.forEach((field) => {
    if (!data[field]) {
      isValid = false
      errors[field] = 'This field is required!'
    }
  })

  if (data.counter_response_type && !data.counter_response_received_at) {
    isValid = false
    errors.counter_response_received_at = 'Please enter when the counter response was received!'
  }

  if (!data.counter_response_type && data.counter_response_received_at) {
    isValid = false
    errors.counter_response_received_at = 'Please enter the counter response type!'
  }

  return {
    isValid,
    errors
  }
}
