"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATES_REQUIRING_POA_NOTARIZATION = exports.AGREEMENT_TYPES = exports.AGREEMENT_EXTERNAL_SOURCES = exports.AGREEMENT_STATUSES = void 0;
const documents_1 = require("./documents");
const regions_1 = require("./regions");
exports.AGREEMENT_STATUSES = Object.freeze({
    CANCELED: 'canceled',
    COMPLETE: 'complete',
    PENDING: 'pending'
});
exports.AGREEMENT_EXTERNAL_SOURCES = documents_1.DOCUMENT_EXTERNAL_SOURCES;
exports.AGREEMENT_TYPES = Object.freeze({
    HOLDOVER: documents_1.DOCUMENT_TYPES.HOLDOVER,
    LEASE: documents_1.DOCUMENT_TYPES.LEASE,
    LEASE_AMENDMENT: documents_1.DOCUMENT_TYPES.LEASE_AMENDMENT,
    LEASE_RENEWAL: documents_1.DOCUMENT_TYPES.LEASE_RENEWAL,
    LEASE_TERMINATION: documents_1.DOCUMENT_TYPES.LEASE_TERMINATION,
    PURCHASE_OPTION_AGREEMENT: documents_1.DOCUMENT_TYPES.PURCHASE_OPTION_AGREEMENT,
    STEP_UP: documents_1.DOCUMENT_TYPES.STEP_UP,
    PURCHASE_OPTION_AGREEMENT_AMENDMENT: documents_1.DOCUMENT_TYPES.PURCHASE_OPTION_AGREEMENT_AMENDMENT
});
exports.STATES_REQUIRING_POA_NOTARIZATION = Object.freeze([regions_1.STATES.NC]);
