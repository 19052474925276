// the file containing the components for the customer profile screen
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'
import { types } from '@homevest/timelines/'
import { Helmet } from 'react-helmet'

import { TwoColumn } from 'components/TailwindUIToolkit'
import Timeline from './Timeline'
import PersonalDetails from './PersonalDetails'
import PrequalificationDetails from './PrequalificationDetails'
import LeadGroup from './LeadGroup'
import LeasingStaff from './LeasingStaff'
import PowerUserTools from './PowerUserTools'
import BackgroundChecks from './BackgroundChecks'
import Properties from './Properties'
import Applications from './Applications'
import Rentals from './Rentals'
import PaymentMethods from './PaymentMethods'
// import BreadcrumbBar from './BreadcrumbBar'
import PageTitle from './PageTitle'
import Note from './Note'
import Documents from './Documents'
import axios from 'lib/axios'
import { Link } from 'react-router-dom'
import { Customer } from './types'
import Modals from './Modals'
import { DynamicWidthPageContainer } from 'components/TailwindUIToolkit/Layouts/DynamicWidthPageContainer'

import { hasCapability } from 'lib/admin-perms'
import IdentityVerification from './IdentityVerificationDetails'
const { APPLICATION_REVIEWER, INITIAL_PAYMENTS_CHARGER } = capabilities.CAPABILITY_TYPES

const CustomerProfile: FunctionComponent<React.PropsWithChildren<{ userId: string }>> = ({
  userId
}) => {
  const [response, setResponse] = useState<types.TimelineResponse>()
  const [loading, setLoading] = useState(false)
  const [userName, setUserName] = useState({
    firstName: '...',
    lastName: '...',
    displayName: '...'
  })
  useEffect(() => {
    setLoading(true)
    axios
      .get(`/360/users/${userId}/timeline`)
      .then((response) => {
        setLoading(false)
        setResponse(response.data)
      })
      .catch((err) => {
        throw err
      })
  }, [userId])

  const customer: Customer = {
    userId,
    firstName: userName.firstName,
    lastName: userName.lastName,
    displayName: userName.displayName
  }

  const isInitialPaymentsCharger = useSelector((store: any) =>
    hasCapability(store.admin, INITIAL_PAYMENTS_CHARGER)
  )

  const isApplicationReviewer = useSelector((store: any) =>
    hasCapability(store.admin, APPLICATION_REVIEWER)
  )

  const mainContent = (
    <>
      <PersonalDetails userId={userId} setProfileName={setUserName} />
      <Timeline customer={customer} timelineResponse={response} loading={loading} />
      <Documents userId={userId} />
      <Note events={response?.timeline} userId={userId} />
    </>
  )

  const sidebarContent = (
    <>
      <PrequalificationDetails userId={userId} />
      <IdentityVerification userId={userId} />
      <Applications userId={userId} />
      <Rentals userId={userId} />
      <Properties homes={response?.properties} />
      <LeadGroup userId={userId} />
      <LeasingStaff userId={userId} />
      <PaymentMethods userId={userId} />
      {isApplicationReviewer && (
        <BackgroundChecks userId={userId} isAllowed={isApplicationReviewer} />
      )}
      {isInitialPaymentsCharger && (
        <PowerUserTools userId={userId} isInitialPaymentsCharger={isInitialPaymentsCharger} />
      )}
    </>
  )

  // TODO: implement working breadcrumb history

  return (
    <>
      <Helmet>
        <title>{customer.displayName}</title>
      </Helmet>
      <DynamicWidthPageContainer
        breadcrumbBar={true}
        pathToPage={[
          { name: 'Users', href: '/users/' },
          { name: customer.displayName, href: '#' }
        ]}
      >
        <div className='mb-4 h-fit'>
          {/* <BreadcrumbBar history={breadcrumbPages} /> */}
          <PageTitle customer={customer} />
          <Link to={{ pathname: `/legacy/users/${userId}` }}>Legacy user page</Link>
        </div>
        <TwoColumn mainContent={mainContent} sidebarContent={sidebarContent} sidebarSide='right' />
        <Modals />
      </DynamicWidthPageContainer>
    </>
  )
}

export default CustomerProfile
