import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Button } from 'reactstrap'
import styled from 'styled-components'
import includes from 'lodash/includes'

// Importing our lib first to make sure the firebase app is initialized
// TODO fix the lib so we don't have to do this
import '../lib/firebase'
import firebase from 'firebase/compat/app'

const Center = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Text = styled.span`
  font-size: 20px;
  margin-bottom: 50px;
`

const provider = new firebase.auth.GoogleAuthProvider()

class AuthScreen extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    admin: PropTypes.object,
    initialPathname: PropTypes.string,
    initialSearch: PropTypes.string
  }

  UNSAFE_componentWillMount() {
    if (this.props.admin.id) {
      this.redirect()
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.admin.id && !this.props.admin.id) {
      this.redirect()
    }
  }

  redirect = () => {
    if (this.props.initialPathname === '/auth') {
      this.props.history.push('/users')
    } else {
      this.props.history.push({
        pathname: this.props.initialPathname,
        search: this.props.initialSearch
      })
    }
  }

  handleAuth = async () => {
    try {
      const res = await firebase.auth().signInWithPopup(provider)

      const { profile } = res.additionalUserInfo

      const domain = profile.email.split('@')[1]

      if (!includes(['upandup.co', 'homevest.co'], domain)) {
        await res.user.delete()
      }
    } catch (err) {
      return
    }
  }

  render() {
    return (
      <Container>
        <Center>
          <Text>Please authenticate with your Up&Up gmail account.</Text>

          <Button
            outline
            size='lg'
            color='primary'
            style={{ width: 300 }}
            onClick={this.handleAuth}
          >
            Login
          </Button>
        </Center>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  admin: state.admin,
  initialPathname: state.url.initialPathname,
  initialSearch: state.url.initialSearch
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AuthScreen)
