import React from 'react'

import { Button, Icon } from '@chakra-ui/react'
import { MoonIcon, BellAlertIcon } from '@heroicons/react/20/solid'
import { startCase } from 'lodash'

const MuteUnmuteButton: React.FC<
  React.PropsWithChildren<{
    buttonType: 'mute' | 'unmute'
    handleMuteAction: () => Promise<void>
  }>
> = ({ buttonType, handleMuteAction }) => {
  return (
    <Button
      leftIcon={<Icon as={buttonType === 'mute' ? MoonIcon : BellAlertIcon} />}
      colorScheme='teal'
      onClick={() => {
        handleMuteAction()
      }}
      size='sm'
    >
      {startCase(buttonType)}
    </Button>
  )
}

export default MuteUnmuteButton
