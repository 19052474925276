import React from 'react'
import { startCase } from 'lodash'
import { ColDef, ICellRendererParams } from 'ag-grid-community'

import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { BaseGrid } from 'components/TailwindUIToolkit'
import { UtilityDetailsByPortfolioHomeIdQuery } from 'graphql/generated'

type PortfolioHomeUtilities = NonNullable<
  UtilityDetailsByPortfolioHomeIdQuery['portfolio_homes_by_pk']
>['portfolio_home_utilities']
type PortfolioHomeUtility = PortfolioHomeUtilities[number]
type UtilitiesGridPropTypes = {
  portfolioHomeUtilities: PortfolioHomeUtilities
}

const COLUMNS: ColDef<PortfolioHomeUtility>[] = [
  {
    headerName: 'Utility Type',
    colId: 'utility_type_name',
    valueGetter: (v) => v.data?.utility_type.name,
    valueFormatter: (v) => startCase(v.value),
    flex: 2
  },
  {
    headerName: 'Default Responsible Party',
    field: 'default_responsible_party',
    cellRenderer: (params: ICellRendererParams) => mapContentToBadge(params.value),
    flex: 2
  }
]

const UtilitiesGrid: React.FC<React.PropsWithChildren<UtilitiesGridPropTypes>> = ({
  portfolioHomeUtilities
}) => {
  return (
    <BaseGrid<PortfolioHomeUtility>
      columns={COLUMNS}
      rowData={portfolioHomeUtilities}
      domLayout={'autoHeight'}
    />
  )
}

export default UtilitiesGrid
