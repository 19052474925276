import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from 'urql'

import { rentalApplications } from '@homevest/utils'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Checkbox,
  CircularProgress,
  Stack,
  Text
} from '@chakra-ui/react'
import { ContentSectionCard } from 'components/TailwindUIToolkit'

import {
  SetCreditCardEnabledAtDocument as SaveCreditCardEnabled,
  SetCreditCardInitialDepositFeeDisabledAtDocument as SaveCreditCardInitialDepositFeeDisabled,
  RentalApplicationCreditCardConfigurationDocument as CreditCardQuery
} from 'graphql/generated'
import { REQUEST_POLICY } from 'constants/urql'

interface Admin {
  first_name: string
  last_name: string
  id: string
  email: string
}

interface RentalApplication {
  id: string
  credit_card_enabled_at: Date | null
  credit_card_initial_deposit_fee_disabled_at: Date | null
  credit_card_enabled_by_admin: Admin | null
  status: string
}

const { RENTAL_APPLICATION_STATUSES } = rentalApplications

// Wrapper with header and data query
const CreditCardPaymentDetailsInternal: React.FC<
  React.PropsWithChildren<{
    rentalApplication: RentalApplication
  }>
> = ({ rentalApplication }) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [paymentEnabledAt, setPaymentEnabledAt] = useState<Date | null>(
    rentalApplication.credit_card_enabled_at
  )
  const [feeDisabledAt, setFeeDisabledAt] = useState<Date | null>(
    rentalApplication.credit_card_initial_deposit_fee_disabled_at
  )
  const [admin] = useState<Admin | null>(rentalApplication.credit_card_enabled_by_admin)

  const adminId: string = useSelector((store: any) => store.admin.id)

  const savePaymentEnabledAt = async (d: Date | null): Promise<boolean> => {
    try {
      setIsLoading(true)
      await mutateCreditCardEnabledAt({
        credit_card_enabled_at: d,
        credit_card_enabled_by_admin_id: adminId,
        id: rentalApplication.id
      })

      return true
    } catch (err: any) {
      setErrorMessage(err.message)
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const saveInitialDepositFeeDisabledAt = async (d: Date | null): Promise<boolean> => {
    try {
      setIsLoading(true)
      await mutateCreditCardInitialPaymentFeeDisabledAt({
        credit_card_initial_deposit_fee_disabled_at: d,
        id: rentalApplication.id
      })
      return true
    } catch (err: any) {
      setErrorMessage(err.message)
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const isComplete = (): boolean => {
    return rentalApplication.status === RENTAL_APPLICATION_STATUSES.COMPLETE
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, mutateCreditCardEnabledAt] = useMutation(SaveCreditCardEnabled)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, mutateCreditCardInitialPaymentFeeDisabledAt] = useMutation(
    SaveCreditCardInitialDepositFeeDisabled
  )

  return (
    <>
      {errorMessage && (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Whoops!</AlertTitle>
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
      )}
      <ContentSectionCard
        collapsable={false}
        padding={true}
        title={'Enable Card Payment for Initial Deposit'}
      >
        <Stack padding={4}>
          <Text>
            Credit controls allow you to enable credit card payment and fees for the application.
          </Text>
          {admin && (
            <h1>
              {admin.first_name} {admin.last_name} was messing around with this last.
            </h1>
          )}
          <Checkbox
            onChange={async (e: any): Promise<any> => {
              const d = e.target.checked ? new Date() : null
              setPaymentEnabledAt(d)
              await savePaymentEnabledAt(d)
              if (feeDisabledAt && !d) {
                setFeeDisabledAt(null)
                return saveInitialDepositFeeDisabledAt(null)
              }
            }}
            isChecked={!!paymentEnabledAt}
            isDisabled={isComplete() || isLoading}
          >
            Enable credit card payments for application
          </Checkbox>
          <Checkbox
            onChange={(e: any) => {
              const d = e.target.checked ? new Date() : null
              setFeeDisabledAt(d)
              return saveInitialDepositFeeDisabledAt(d)
            }}
            isChecked={!!feeDisabledAt}
            isDisabled={isComplete() || !paymentEnabledAt || isLoading}
          >
            Do NOT charge the customer a transaction fee.
          </Checkbox>
        </Stack>
      </ContentSectionCard>
    </>
  )
}

// Container to hold the react suspense
export const CreditCardPaymentDetails: React.FC<
  React.PropsWithChildren<{
    rentalApplicationId: string
  }>
> = ({ rentalApplicationId }) => {
  const [{ data, error }] = useQuery({
    query: CreditCardQuery,
    variables: {
      id: rentalApplicationId
    },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK
  })

  if (!data) {
    return <CircularProgress isIndeterminate color='teal.300' />
  }

  let dataError = null
  if (!data || !data.rental_application) {
    dataError = 'No rental application found!'
  }

  if (error || dataError) {
    return (
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>Credit Card Tool Load Error!</AlertTitle>
        <AlertDescription>{JSON.stringify(error || dataError)}</AlertDescription>
      </Alert>
    )
  }

  return (
    <React.Suspense fallback={<CircularProgress isIndeterminate color='teal.300' />}>
      <CreditCardPaymentDetailsInternal rentalApplication={data.rental_application} />
    </React.Suspense>
  )
}
