import React from 'react'
import PropTypes from 'prop-types'
import { UpupAdminsWithCapabilitiesQuery, UpupCapabilitiesWithAdminQuery } from 'graphql/generated'
import { BodyText, Button, COLORS } from 'components/Toolkit'
import EditCapAdminsModal from './EditCapAdminsModal'

type Props = {
  allAdmins?: UpupAdminsWithCapabilitiesQuery['admins']
  capabilities?: UpupCapabilitiesWithAdminQuery['capabilities']
}

const CapabilityList: React.FC<React.PropsWithChildren<Props>> = ({
  capabilities = [],
  allAdmins = []
}) => {
  const [selected, setSelected] = React.useState<number | null>(null)

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '2fr 2fr 4fr 1fr'
        }}
      >
        {(capabilities ?? []).map((cap, idx) => {
          return (
            <React.Fragment key={cap.id}>
              {idx !== 0 && (
                <hr
                  style={{
                    gridColumnStart: 1,
                    gridColumnEnd: 'end',
                    width: '100%',
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem'
                  }}
                />
              )}
              <BodyText weight='bold'>{cap.name}</BodyText>
              <BodyText>{cap.type}</BodyText>
              <BodyText>{cap.description}</BodyText>
              <div style={{ textAlign: 'right' }}>
                <Button size='vs' isSecondary onClick={() => setSelected(idx)}>
                  Edit
                </Button>
              </div>
              <div
                style={{
                  gridColumnStart: 1,
                  gridColumnEnd: 'end',
                  background: COLORS.LIGHT_NEUTRALS.GARLIC,
                  padding: '0.5rem'
                }}
              >
                <BodyText
                  size='s'
                  style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}
                  weight='bold'
                >
                  {cap.admin_capabilities.length > 0 ? 'Admins' : 'No current admins'}
                </BodyText>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap'
                  }}
                >
                  {cap.admin_capabilities
                    .sort((a, b) => a.admin.first_name.localeCompare(b.admin.first_name))
                    .map((cap) => (
                      <BodyText
                        key={cap.id}
                        size='s'
                        style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}
                      >
                        {cap.admin.first_name} {cap.admin.last_name}
                      </BodyText>
                    ))}
                </div>
              </div>
            </React.Fragment>
          )
        })}
      </div>
      <EditCapAdminsModal
        isOpen={selected !== null}
        onClose={() => setSelected(null)}
        capability={selected !== null ? capabilities[selected] ?? undefined : undefined}
        admins={allAdmins}
      />
    </>
  )
}

CapabilityList.propTypes = {
  allAdmins: PropTypes.array.isRequired,
  capabilities: PropTypes.array.isRequired
}

export default CapabilityList
