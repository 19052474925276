import { RECEIVED_RENTAL, RECEIVED_RENTALS, RECEIVED_RENTALS_PENDING_PAYMENT } from './actions'

import { append } from '../../lib/map'

export default function reducer(
  state = {
    data: new Map(),
    rentalsPendingPayment: new Map(),
    activeRentals: new Map()
  },
  action
) {
  switch (action.type) {
    case RECEIVED_RENTAL: {
      const { rental } = action

      return {
        ...state,
        data: append(state.data, rental.id, rental)
      }
    }

    case RECEIVED_RENTALS: {
      const { rentals } = action

      return {
        ...state,
        data: new Map(rentals.map((rental) => [rental.id, rental]))
      }
    }

    case RECEIVED_RENTALS_PENDING_PAYMENT: {
      const pendingPaymentRentals = action.rentals
      return {
        ...state,
        rentalsPendingPayment: new Map(pendingPaymentRentals.map((rental) => [rental.id, rental]))
      }
    }

    default:
      return state
  }
}
