import React, { FunctionComponent } from 'react'
import { ContentSectionCard, LinkButton } from 'components/TailwindUIToolkit'
import Grid from '../Grids/WorkOrderGrid'
import { Spinner } from 'reactstrap'
import { RETOOL_APP_URL, RETOOL_WORK_ORDERS_APP_ID } from 'constants/urls'

type WorkOrderHistoryPropTypes = {
  portfolioHomeId: string
}

const WorkOrderToolButton = (
  <LinkButton href={`${RETOOL_APP_URL}${RETOOL_WORK_ORDERS_APP_ID}`}>Work Order Tool</LinkButton>
)

const WorkOrderHistory: FunctionComponent<React.PropsWithChildren<WorkOrderHistoryPropTypes>> = ({
  portfolioHomeId
}) => (
  <ContentSectionCard title='Work Order History' staticSize action={WorkOrderToolButton}>
    <React.Suspense fallback={<Spinner />}>
      <Grid portfolioHomeId={portfolioHomeId} />
    </React.Suspense>
  </ContentSectionCard>
)

export default WorkOrderHistory
