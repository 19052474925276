"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VALIDITY_INTERVAL_IN_DAYS = exports.STATUSES = exports.REPORT_TYPES = void 0;
exports.REPORT_TYPES = Object.freeze({
    CREDIT: 'credit_reports',
    EVICTION: 'eviction_reports',
    CRIMINAL: 'criminal_reports'
});
exports.STATUSES = Object.freeze({
    FAILED: 'failed',
    INVALIDATED: 'invalidated',
    PENDING: 'pending',
    SUCCEEDED: 'succeeded'
});
exports.VALIDITY_INTERVAL_IN_DAYS = 30;
