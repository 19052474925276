import React from 'react'
import { Helmet } from 'react-helmet'
import { Box, Heading, Stack, Text } from '@chakra-ui/react'
import { CompletedRenewal } from '@homevest/types/renewals'
import CompletedRenewalsTable from './CompletedRenewalsTable'
import axios from 'lib/axios'

async function fetchUpcomingRentals() {
  return await axios.get<CompletedRenewal[]>('/admin/renewals/complete')
}

const RenewalManagerCompletedView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [data, setData] = React.useState<CompletedRenewal[]>()
  const [loading, setLoading] = React.useState(false)

  const getData = () => {
    setLoading(true)
    fetchUpcomingRentals()
      .then((res) => {
        console.log(res.data)
        setData(res.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(getData, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <Helmet>
        <title>Renewal Manager</title>
      </Helmet>
      <Box w='100%' p={1} padding={3}>
        <Stack gap={4}>
          <Heading as='h5' size='lg' width='100%'>
            Completed/Canceled Renewals
          </Heading>
          <Text as='i'>Displaying the 25 most recently completed/canceled renewals.</Text>
          <CompletedRenewalsTable data={data || []} />
        </Stack>
      </Box>
    </>
  )
}

export default RenewalManagerCompletedView
