import React from 'react'
import { MapIcon } from '@heroicons/react/20/solid'
import { capitalize } from 'lodash'
import { types } from '@homevest/timelines'
import { TimelineEventContentRenderer, TimelineEventDisplayTemplate } from '../types'
import { PropertyDetails } from './PropertyDetails'
import { SC } from 'components/TailwindUIToolkit'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'

type TimelineEventType = types.TourTimelineEvent

const PreviewContent: TimelineEventContentRenderer<TimelineEventType> = ({
  event,
  customer,
  home
}) => {
  return (
    <div>
      <SC.MessageText>
        <SC.InlineBold>{customer?.displayName}</SC.InlineBold> {event.actionType} a tour for{' '}
        <SC.InlineBold>{home?.address || `some property`}</SC.InlineBold>
      </SC.MessageText>
      {mapContentToBadge(event.actionType)}
    </div>
  )
}

const ExpandedContent: TimelineEventContentRenderer<TimelineEventType> = ({ event, home }) => {
  return (
    <>
      <PropertyDetails details={home} />
      <SC.Subsection>
        <SC.DescriptionText>
          {capitalize(event.actionType)} on {''}
          <SC.InlineBold>{new Date(event.eventTimeAt).toDateString()}</SC.InlineBold>
        </SC.DescriptionText>
      </SC.Subsection>
    </>
  )
}

export const TourEventDisplayTemplate: TimelineEventDisplayTemplate<TimelineEventType> = {
  icon: MapIcon,
  iconColor: 'orange.500',
  previewContent: PreviewContent,
  expandedContent: ExpandedContent
}
