export const REFRESH_TABLE_DATA = 'DISPATCH/REFRESH_TABLE_DATA'
export const TOGGLE_REFRESH_TABLE_DATA = 'DISPATCH/TOGGLE_REFRESH_TABLE_DATA'
export const SEARCH_STATE_CHANGE = 'DISPATCH/SEARCH_STATE_CHANGE'
export const CLOSE_MODAL = 'DISPATCH/CLOSE_MODAL'
export const OPEN_MODAL = 'DISPATCH/OPEN_MODAL'
export const CLEAR = 'DISPATCH/CLEAR'
export const START_MODAL_ACTION = 'DISPATCH/START_MODAL_ACTION'
export const END_MODAL_ACTION = 'DISPATCH/END_MODAL_ACTION'
export const TOURS_RECEIVED = 'DISPATCH/TOURS_RECEIVED'
export const TOUR_RECEIVED = 'DISPATCH/TOUR_RECEIVED'
export const TOUR_PROPERTY_RECEIVED = 'DISPATCH/TOUR_PROPERTY_RECEIVED'

export const clear = () => ({
  type: CLEAR
})

export const openModal = (name, data) => ({
  type: OPEN_MODAL,
  payload: {
    name,
    data
  }
})

export const closeModal = () => ({
  type: CLOSE_MODAL
})

export const startModalAction = () => ({
  type: START_MODAL_ACTION
})

export const endModalAction = () => ({
  type: END_MODAL_ACTION
})

export const searchStateChange = () => ({
  type: SEARCH_STATE_CHANGE
})

export const refreshTableData = () => ({
  type: REFRESH_TABLE_DATA
})

export const toggleRefreshTableData = () => ({
  type: TOGGLE_REFRESH_TABLE_DATA
})

export const receivedTours = (tours, status) => ({
  type: TOURS_RECEIVED,
  tours,
  status
})

export const receivedTour = (tour, previousStatus) => ({
  type: TOUR_RECEIVED,
  tour,
  previousStatus
})

export const receivedTourProperty = (tourProperty, tourStatus) => ({
  type: TOUR_PROPERTY_RECEIVED,
  tourProperty,
  tourStatus
})
