import React from 'react'
import { userIncomeSources } from '@homevest/utils'

import { useSourceDetails } from './context'
import EmployedSourceDetails from './EmployedSourceDetails'
import BenefitsSourceDetails from './BenefitsSourceDetails'
import SelfEmployedSourceDetails from './SelfEmployedSourceDetails'

const { USER_INCOME_SOURCE_TYPES: SOURCE_TYPES } = userIncomeSources

const SourceDetailsFormContent = ({
  userId,
  onSubmit
}: {
  userId: string
  onSubmit: () => void
}) => {
  const context = useSourceDetails()

  if (!context) {
    return null
  }

  const { data } = context
  const sourceType = data.type

  switch (sourceType) {
    case SOURCE_TYPES.EMPLOYED:
      return <EmployedSourceDetails userId={userId} onSubmit={onSubmit} />
    case SOURCE_TYPES.SELF_EMPLOYED:
      return <SelfEmployedSourceDetails userId={userId} onSubmit={onSubmit} />
    case SOURCE_TYPES.BENEFITS:
      return <BenefitsSourceDetails userId={userId} onSubmit={onSubmit} />
    default:
      return null
  }
}

const SourceDetailsFormWrapper = ({
  userId,
  onSubmit
}: {
  userId: string
  onSubmit: () => void
}) => {
  return (
    <div className='flex flex-col gap-3'>
      <SourceDetailsFormContent userId={userId} onSubmit={onSubmit} />
    </div>
  )
}

export default SourceDetailsFormWrapper
