"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVENT_NAMES = void 0;
exports.EVENT_NAMES = Object.freeze({
    AGENT_TOUR_FEEDBACK_FORM_SUBMITTED: 'agents.tour_feedback_form_submitted',
    DOCUMENT_STATUS_UPDATED: 'documents.status_updated',
    GLOBAL_USER_FIELD_UPDATED: 'global.user_field_updated',
    LEAD_GROUP_AVAILABILITY_CREATED: 'lead_groups.availability_created',
    LEAD_GROUP_PROPERTY_CREATED: 'lead_groups.property_created',
    LEAD_GROUP_PROPERTY_UPDATED: 'lead_groups.property_updated',
    PLAID_MICRODEPOSITS_EXPIRED: 'plaid.microdeposits.expired',
    PLAID_MICRODEPOSITS_VERIFIED: 'plaid.microdeposits.verified',
    RENTAL_APPLICATION_ADDED_LEAD_TO_GROUP: 'rental_application.added_lead_to_group',
    RENTAL_APPLICATION_ATTEMPTED_JOINING: 'rental_application.attempted_joining',
    RENTAL_APPLICATION_CANCELED: 'rental_application.canceled',
    RENTAL_APPLICATION_CREATED: 'rental_application.created',
    RENTAL_APPLICATION_DOCUMENT_STATUS_UPDATED: 'rental_application.document_status_updated',
    RENTAL_APPLICATION_DOCUMENT_UPLOADED: 'rental_application.document_uploaded',
    RENTAL_APPLICATION_INITIAL_REVIEW_SUBMITTED: 'rental_application.initial_review_submitted',
    RENTAL_APPLICATION_INVITE_ACCEPTED: 'rental_application.invite_accepted',
    RENTAL_APPLICATION_ONBOARDING: 'rental_application.onboarding',
    RENTAL_APPLICATION_REVIEWED: 'rental_application.reviewed',
    RENTAL_APPLICATION_STATE_CHANGED: 'rental_application.state_changed',
    RENTAL_APPLICATION_SEND_INVITE: 'rental_application.send_invite',
    RENTAL_APPLICATION_SUPPLEMENTAL_DOCUMENT_REQUESTED: 'rental_application.supplemental_document_requested',
    RENTAL_APPLICATION_GET_HELP: 'rental_application.get_help',
    RENTAL_APPLICATION_SECTION_COMPLETE: 'rental_application.section_complete',
    RENTAL_APPLICATION_EDIT_SECTION: 'rental_application.edit_section',
    RENTAL_APPLICATION_SUBMIT_ATTEMPT: 'rental_application.submit.attempt',
    RENTAL_APPLICATION_SUBMIT_FAILURE: 'rental_application.submit.failure',
    RENTAL_APPLICATION_SUBMIT_SUCCESS: 'rental_application.submit.success',
    RENTAL_APPLICATION_USER_INCOME_SUBMITTED: 'rental_application.user_income_submitted',
    RENTAL_APPLICATION_USER_INCOME_EDIT_SUBMITTED: 'rental_application.user_income_edit_submitted',
    // Accidental mis-fire on naming convention between plural and singular.
    // These are live and must remain plural.
    RENTAL_APPLICATIONS_LEASE_EXECUTED: 'rental_applications.lease_executed',
    RENTAL_APPLICATIONS_LEASE_GENERATED: 'rental_applications.lease_generated',
    RENTAL_APPLICATIONS_UNDERWRITING_APPROVED: 'rental_applications.underwriting_approved',
    TASK_CREATED: 'tasks.created',
    TOUR_CREATED: 'tours.created',
    TOUR_LEAD_GROUP_CONFIRMED: 'tours.lead_group_confirmed',
    TOUR_STATUS_UPDATED: 'tours.status_updated',
    TOUR_PROPERTY_UPDATED: 'tours.property_updated',
    TOUR_START_AT_UPDATED: 'tours.start_at_updated',
    TOUR_USERS_NOT_INTERESTED: 'tours.users_not_interested_set',
    USERS_FAILED_PAYMENT: 'users.failed_payment',
    USERS_FAILED_SECOND_PAYMENT: 'users.failed_second_payment',
    USERS_FIREBASE_ACCONT_CREATED_BY_ADMIN: 'users.firebase_account_created_by_admin',
    USERS_MICRODEPOSITS_SELF_HEALED: 'users.microdeposits_self_healed',
    USERS_IDENTITY_VERIFICATION_UPDATED: 'users.identity_verification_updated',
    USERS_ADDITIONAL_INCOME_SOURCE_REQUESTED: 'users.additional_income_source_requested',
    USERS_INCOME_SOURCE_EDIT_REQUESTED: 'users.income_source_edit_requested'
});
