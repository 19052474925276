import React, { memo } from 'react'
import styled from 'styled-components'
import hexRgb from 'hex-rgb'

import COLORS from 'components/Toolkit/colors'

const rbgaGreen = hexRgb(COLORS.ACCENTS.GREEN, { format: 'array' })
rbgaGreen[3] = 0.05
const lightGreen = `rgba(${rbgaGreen.join(',')})`

const StyledContainer = styled.div`
  color: ${COLORS.ACCENTS.GREEN};
  background: ${lightGreen};
  padding: 9px 17px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  margin: 25px 0;
`

function SuccessText(props) {
  return <StyledContainer {...props} />
}

export default memo(SuccessText)
