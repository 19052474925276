import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  VStack,
  Button,
  Spacer,
  HStack,
  useToast,
  ModalOverlay,
  ModalContent,
  Divider
} from '@chakra-ui/react'

import axios from 'lib/axios'

interface CancelAgreementModalProps {
  pendingAgreementId: string
  isOpen: boolean
  close: () => void
}

const CancelAgreementModal: React.FC<React.PropsWithChildren<CancelAgreementModalProps>> = ({
  pendingAgreementId,
  isOpen,
  close
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const toast = useToast()

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await axios.post(`/admin/agreements/${pendingAgreementId}/cancel`)
    } catch (error) {
      toast({
        title: 'Error cancelling pending agreement!',
        description:
          'There was an error cancelling the pending agreement for this rental. Please contact Engineering for support.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    toast({
      title: 'Pending agreement cancelled!',
      description: 'The pending agreement for this rental has been successfully cancelled.',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right'
    })
    close()
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel Agreement</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <VStack spacing={4}>
            <p>Are you sure you want to cancel this agreement?</p>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button colorScheme='red' variant='outline' onClick={close}>
              Go Back
            </Button>
            <Spacer />
            <Button colorScheme='red' isLoading={isLoading} onClick={handleSubmit}>
              Cancel Agreement
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CancelAgreementModal
