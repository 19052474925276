import { getAuth } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import { kebabCase } from 'lodash'

import { firebaseConfig } from '../lib/config'

export const firebaseApp = firebase.initializeApp(firebaseConfig)

export const auth = getAuth(firebaseApp)

export const firestore = firebase.firestore()

export const mergeLeftOrCreate = async (ref, defaults) => {
  const doc = await ref.get()
  let data = defaults

  if (doc.exists) {
    data = Object.assign(defaults, doc.data())
  }

  await ref.set(data)
}

export const generateUploadPath = (userId, key) => {
  return `/users/${userId}/documents/${kebabCase(key)}`
}

export default firebase
