import upperFirst from 'lodash/upperFirst'

import { PersonalDetails, ContactDetails } from 'types/User'

export const getName = (name: string) => {
  const splits = name.split(' ')

  const firstName =
    splits[0] === splits[0].toUpperCase() ? upperFirst(splits[0].toLowerCase()) : splits[0]

  const lastName =
    splits[1] === splits[1].toUpperCase() ? upperFirst(splits[1].toLowerCase()) : splits[1]

  return { firstName, lastName }
}

export const getDisplayName = (personalDetails: PersonalDetails): string => {
  const { firstName, lastName, emails, phones } = personalDetails

  if (firstName || lastName) {
    return `${firstName} ${lastName}`
  }

  if (emails.length) {
    return getPrimaryContactInfo(emails)
  }

  if (phones.length) {
    return getPrimaryContactInfo(phones)
  }

  return 'No Name Provided'
}

const getPrimaryContactInfo = (contactDetails: ContactDetails[]): string => {
  const primaryContact = contactDetails.filter((cd) => cd.is_primary_for_contact_type)

  return primaryContact?.[0]?.contact_info
}

export const getContactInfo = (
  type: string,
  userContactDetails?: { contact_type: string; contact_info: string }[]
) => (userContactDetails ?? []).find(({ contact_type }) => type === contact_type)?.contact_info
