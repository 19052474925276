import React from 'react'
import { createRoot } from 'react-dom/client'
import './lib/sentry'
import 'bootstrap/dist/css/bootstrap.css'
import 'sanitize.css/sanitize.css'
import './index.css'
import App from './App'
import { Container } from 'react-dom'

const root: Container = document.getElementById('root')!

const container = createRoot(root)

container.render(<App />)
