import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import TasksScreen from 'screens/Tasks'
import InitialReviewScreen from 'screens/InitialReview'
import AllPropertiesScreen from 'screens/Properties'
import LegacyApplicationsScreen from 'screens/Applications'
import ApplicationScreen from 'screens/Application'
import UsersCreateScreen from 'screens/Users/Create'
import UsersSearchScreen from 'screens/Users/Search'
import AuthScreen from 'screens/Auth'
import LeadGroupsScreen from 'screens/Dispatch/LeadGroups'
import ShortUrlsScreen from 'screens/ShortUrls'
import UserCrm from 'screens/UserCrm'
import UserIncomeEditor from 'screens/UserIncome/Editor'
import CapabilitiesScreen from 'screens/Capabilities'
import LatchelSyncManager from 'screens/Construction/LatchelSyncManager'
import RentRollScreen from 'screens/RentRoll'
import RentRollDetailsScreen from 'screens/RentRoll/Details'
import Projects from 'screens/Construction/Projects'
import ProjectDetails from 'screens/Construction/Projects/Details'
import PropertiesMlsPhotoManager from 'screens/Properties/MlsPhotoManager'
import PropertiesMlsUploader from 'screens/Properties/MlsUploader'
import StatementsBackupAndRebuild from 'screens/Statements/BackupAndRebuild'
import StatementsGlTransactions from 'screens/Statements/GlTransactions'
import StatementsBulkJournalEntryUploader from 'screens/Statements/BulkJournalEntryUploader'
import ManualFMVUploader from 'screens/Fmv/ManualFmvUpload'
import StatementsHpaEngine from 'screens/Statements/HpaEngine'
import StatementsMortgageTransformer from 'screens/Statements/MortgageTransformer'
import StatementsProrationEngine from 'screens/Statements/ProrationEngine'
import StatementsSendTest from 'screens/Statements/SendTest'
import Tickets from 'screens/Construction/Tickets'
import CustomerProfile from 'screens/CustomerProfile'
import RouteConnectedAuth from 'components/Routes/ConnectedAuth'
import HomesGridPage from 'screens/Homes'
import HomeProfile from 'components/Homes/HomeProfile'
import DocumentsManager from 'screens/DocumentsManager'
import RentalApplications from 'screens/RentalApplications'
import DelinquencyManager from 'screens/DelinquencyManager'
import RentalSiteChecker from 'screens/RentalSiteChecker'
import RenewalManager from 'screens/RenewalManager'
import UtilitiesCsvUpload from 'screens/Utilities/CsvUpload'
import UtilitiesChargebacksManager from 'screens/Utilities/ChargebacksManager'
import UtilitiesDeferredChargebacks from 'screens/Utilities/DeferredChargebacks'
import Vendors from 'screens/Construction/Vendors'
import VendorDetails from 'screens/Construction/Vendors/Details'
import Collections from 'screens/Collections'
import { Cashouts } from 'screens/Cashouts/List'
import { CashoutDetails } from 'screens/Cashouts/Detail'
import OccupancyInspections from 'screens/Construction/OccupancyInspections'

const Routes = () => (
  <Switch>
    <Route exact path='/auth' component={AuthScreen} />
    <RouteConnectedAuth path='/documents' component={DocumentsManager} />
    <RouteConnectedAuth exact path='/review' component={TasksScreen} />
    <RouteConnectedAuth exact path='/users' component={UsersSearchScreen} />
    <RouteConnectedAuth exact path='/users/create' component={UsersCreateScreen} />
    <RouteConnectedAuth exact path='/legacy/users/:userId' component={UserCrm} />
    <RouteConnectedAuth
      exact
      path='/users/:userId/user-income-editor'
      component={UserIncomeEditor}
    />
    <RouteConnectedAuth exact path='/users/:userId/:tab' component={UserCrm} />
    <RouteConnectedAuth exact path='/users/:userId' component={CustomerProfile} />
    <RouteConnectedAuth path='/legacy/applications' component={LegacyApplicationsScreen} />
    <RouteConnectedAuth exact path='/applications' component={RentalApplications} />
    <RouteConnectedAuth exact path='/applications/:applicationId' component={ApplicationScreen} />
    <RouteConnectedAuth exact path='/homes' component={HomesGridPage} />
    <RouteConnectedAuth exact path='/home/:propertyId' component={HomeProfile} />
    <RouteConnectedAuth exact path='/construction/projects' component={Projects} />
    <RouteConnectedAuth
      exact
      path='/construction/projects/:constructionProjectId'
      component={ProjectDetails}
    />
    <RouteConnectedAuth exact path='/properties' component={AllPropertiesScreen} />
    <RouteConnectedAuth
      exact
      path='/properties/mls-photo-manager/:mlsListingId'
      component={PropertiesMlsPhotoManager}
    />
    <RouteConnectedAuth exact path='/properties/mls-uploader' component={PropertiesMlsUploader} />
    <RouteConnectedAuth
      exact
      path='/properties/rental-site-checker'
      component={RentalSiteChecker}
    />
    <RouteConnectedAuth
      exact
      path='/statements/backup-and-rebuild'
      component={StatementsBackupAndRebuild}
    />
    <RouteConnectedAuth
      exact
      path='/statements/bulk-je-uploader'
      component={StatementsBulkJournalEntryUploader}
    />
    <RouteConnectedAuth exact path='/statements/fmv' component={ManualFMVUploader} />
    <RouteConnectedAuth
      exact
      path='/statements/gl-transactions'
      component={StatementsGlTransactions}
    />
    <RouteConnectedAuth exact path='/statements/hpa-engine' component={StatementsHpaEngine} />
    <RouteConnectedAuth
      exact
      path='/statements/mortgage-transformer'
      component={StatementsMortgageTransformer}
    />
    <RouteConnectedAuth
      exact
      path='/statements/proration-engine'
      component={StatementsProrationEngine}
    />
    <RouteConnectedAuth exact path='/statements/send-test' component={StatementsSendTest} />
    <RouteConnectedAuth
      exact
      path='/review/initial/:mlsListingId'
      component={InitialReviewScreen}
    />
    <RouteConnectedAuth exact path='/dispatch-lead-groups' component={LeadGroupsScreen} />
    <RouteConnectedAuth exact path='/short-urls' component={ShortUrlsScreen} />
    <RouteConnectedAuth exact path='/delinquency-manager' component={DelinquencyManager} />
    <RouteConnectedAuth exact path='/capabilities' component={CapabilitiesScreen} />
    <RouteConnectedAuth exact path='/rent-roll/cashouts' component={Cashouts} />
    <RouteConnectedAuth exact path='/rent-roll/:rentalId/cashout' component={CashoutDetails} />
    <RouteConnectedAuth exact path='/rent-roll/:rentalId' component={RentRollDetailsScreen} />
    <RouteConnectedAuth exact path='/rent-roll' component={RentRollScreen} />
    <RouteConnectedAuth exact path='/properties/renewal-manager' component={RenewalManager} />
    <RouteConnectedAuth exact path='/utilities/upload' component={UtilitiesCsvUpload} />
    <RouteConnectedAuth
      exact
      path='/utilities/chargebacks-manager'
      component={UtilitiesChargebacksManager}
    />
    <RouteConnectedAuth exact path='/utilities/deferred' component={UtilitiesDeferredChargebacks} />
    <RouteConnectedAuth exact path='/collections' component={Collections} />
    <RouteConnectedAuth
      exact
      path='/construction/latchel-sync-manager'
      component={LatchelSyncManager}
    />
    <RouteConnectedAuth exact path='/construction/tickets' component={Tickets} />
    <RouteConnectedAuth exact path='/construction/vendors' component={Vendors} />
    <RouteConnectedAuth exact path='/construction/vendors/:vendorId' component={VendorDetails} />
    <RouteConnectedAuth
      exact
      path='/construction/occupancy_inspections'
      component={OccupancyInspections}
    />
    <Redirect to='/homes' />
  </Switch>
)

export default Routes
