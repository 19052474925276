import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Text } from '@chakra-ui/react'

import axios from 'lib/axios'

export default function ReportLink({ documentId, reportName, style = {} }) {
  const [reportUrl, setReportUrl] = useState(null)

  useEffect(() => {
    async function getReportUrl(docId) {
      const res = await axios.get(`/admin/documents/${docId}/url`)
      setReportUrl(res.data.url)
    }

    if (documentId) {
      getReportUrl(documentId)
    }
  }, [documentId])

  if (!documentId) {
    return null
  }

  return (
    <div style={style}>
      <Text fontSize='xs'>{reportName}</Text>
      <Text marginTop={0}>
        {reportUrl ? (
          <a rel='noopener noreferrer' target='_blank' href={reportUrl}>
            Link
          </a>
        ) : (
          'Not uploaded'
        )}
      </Text>
    </div>
  )
}

ReportLink.propTypes = {
  documentId: PropTypes.string,
  reportName: PropTypes.string.isRequired,
  style: PropTypes.object
}
