import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import styled from 'styled-components'
import $ from 'jquery'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'styles/carousel.css'

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media (min-width: 568px) {
    overflow: visible;
  }
`

const StyledImg = styled.img`
  object-fit: ${(props) => (props.objectFit ? props.objectFit : 'contain')};
  overflow: hidden;
`

export default class PropertyCarousel extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
    objectFit: PropTypes.string,
    showThumbnails: PropTypes.bool
  }

  static defaultProps = {
    showThumbnails: true
  }

  state = {
    mainSlider: null,
    thumbSlider: null
  }

  UNSAFE_componentWillMount() {
    this.setupPhotos()
  }

  componentDidMount() {
    this.setState({
      mainSlider: this.mainSlider,
      thumbSlider: this.thumbSlider
    })

    // eslint-disable-next-line no-undef
    $(window).on('DOMContentLoaded load resize', () => {
      this.fixHeight('carousel')
      this.fixHeight('thumbnail')
    })

    this.fixHeight('carousel')
    this.fixHeight('thumbnail')
  }

  fixHeight(className) {
    const element = $(`.${className} .carousel-image`)[0]
    if (!element) return
    $(`.${className} .slick-list`).css('height', `${(element.width * 425) / 635}px`)
    $(`.${className} .carousel-image`).css('height', `${(element.width * 425) / 635}px`)
  }

  setupPhotos() {
    this.setState({
      photos: this.props.images.map((source, index) => (
        <StyledImg
          key={index}
          className='carousel-image'
          objectFit={this.props.objectFit}
          src={source.url}
        />
      ))
    })
  }

  render() {
    return (
      <Container>
        <Slider
          style={{ marginBottom: '5px' }}
          className='carousel'
          ref={(mainSlider) => (this.mainSlider = mainSlider)}
          fade
          adaptiveHeight={false}
          slidesToShow={1}
          slidesToScroll={1}
          arrows={false}
          asNavFor={this.state.thumbSlider}
        >
          {this.state.photos}
        </Slider>

        {this.props.showThumbnails && (
          <Slider
            className='thumbnail'
            ref={(thumbSlider) => (this.thumbSlider = thumbSlider)}
            centerMode
            adaptiveHeight={false}
            focusOnSelect
            // eslint-disable-next-line no-undef
            slidesToShow={window.innerWidth >= 568 ? 5 : 3}
            slidesToScroll={1}
            asNavFor={this.state.mainSlider}
          >
            {this.state.photos}
          </Slider>
        )}
      </Container>
    )
  }
}
