import React from 'react'
import { format } from 'date-fns'
import { Heading, Stack, Image, Text, Link, Button, HStack } from '@chakra-ui/react'
import { LINK_COLOR } from 'constants/colors'

interface PropertyDisplayProps {
  primary_tenant_name: string
  address: string
  city: string
  state: string
  zip: string
  beds: number
  baths: number
  sqft: number | string | null
  portfolio_home_id: string
  photo_url?: string
  rental_id: string
  most_recent_note?: string
  most_recent_note_created_at?: Date
}

const PropertyDisplay: React.FC<React.PropsWithChildren<PropertyDisplayProps>> = (props) => {
  const {
    primary_tenant_name: tenantName,
    address,
    city,
    state,
    zip,
    beds,
    baths,
    sqft,
    portfolio_home_id: portfolioHomeId,
    photo_url: photoUrl,
    rental_id: rentalId,
    most_recent_note: mostRecentNote,
    most_recent_note_created_at: mostRecentNoteCreatedAt
  } = props

  const mostRecentNoteDate = mostRecentNoteCreatedAt
    ? format(new Date(mostRecentNoteCreatedAt), 'yyyy-MM-dd')
    : undefined

  return (
    <Stack w='fit-content' maxW='md'>
      {photoUrl && (
        <Image key={photoUrl} src={photoUrl} w='full' alt='Property Image' borderRadius='5px' />
      )}
      <Heading as='h5' size='md' width='100%'>
        {address}
      </Heading>
      <Heading fontWeight='medium' size='sm' width='100%'>
        {city}, {state} {zip}
      </Heading>
      <Text>
        {beds} beds | {baths} baths {sqft && `| ${sqft} sqft`}
      </Text>
      <Text>Primary Tenant: {tenantName}</Text>
      <HStack justifyContent='center'>
        <Link isExternal href={`/rent-roll/${rentalId}`}>
          <Button color={LINK_COLOR} variant='ghost'>
            Rent Roll
          </Button>
        </Link>
        <Link isExternal href={`/home/${portfolioHomeId}`}>
          <Button color={LINK_COLOR} variant='ghost'>
            Home Data
          </Button>
        </Link>
      </HStack>
      <Text>
        <span className='font-semibold'>
          Most Recent Note{' ['}
          {mostRecentNoteDate}
          {']'}
        </span>
        {': '}
        {mostRecentNote}
      </Text>
    </Stack>
  )
}

export default PropertyDisplay
