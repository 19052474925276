import React, { FunctionComponent, useState } from 'react'
import { Input, Stack, Text } from '@chakra-ui/react'
import { Sidepeek } from 'ui'
import { BulkUploadDocumentOptions } from './BulkUploadDocumentOptions'
import { BulkUploader, FileInfo } from './BulkUploader'

const BulkUploadSidepeek: FunctionComponent<
  React.PropsWithChildren<{
    isOpen: boolean
    onClose: () => void
    typeMap: { [key: string]: string }
    resourceId: string
    resourceType: string
  }>
> = ({ isOpen, onClose, typeMap, resourceId, resourceType }) => {
  const [files, setFiles] = useState<File[]>([])
  const [filesInfo, setFilesInfo] = useState<FileInfo[]>([])

  const clearState = () => {
    setFiles([])
    setFilesInfo([])
  }

  const setFileIndex = (idx: number) => (fileInfo: FileInfo) => {
    const temp = [...filesInfo]

    temp[idx] = fileInfo

    setFilesInfo(temp)
  }

  const handleFilesSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!evt.target.files) {
      return
    }

    setFiles([...evt.target.files])
    setFilesInfo(
      [...evt.target.files].map((file) => ({
        // defaults
        file,
        name: file.name,
        status: 'final',
        type: ''
      }))
    )
  }

  return (
    <Sidepeek
      isOpen={isOpen}
      onClose={() => {
        clearState()
        onClose()
      }}
      title='Bulk Upload'
    >
      {!resourceId && (
        <Text color='red'>Make sure to select a resource to attach documents to!</Text>
      )}
      <Stack direction='column' spacing='4'>
        <Input
          h='fit-content'
          p='4'
          type='file'
          multiple
          shadow='sm'
          onChange={handleFilesSelect}
        />
        <BulkUploader
          files={filesInfo}
          resourceId={resourceId}
          resourceType={resourceType}
          onSuccess={() => setFiles([])}
        />
        {[...files].map((file, idx) => (
          <BulkUploadDocumentOptions
            typeMap={typeMap}
            file={file}
            key={file.name}
            setFilesInfo={setFileIndex(idx)}
          />
        ))}
      </Stack>
    </Sidepeek>
  )
}

export default BulkUploadSidepeek
