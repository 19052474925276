import React from 'react'
import { Container } from 'reactstrap'

import TasksRentalsPendingPayment from '../components/Tasks/RentalsPendingPaymentTable'

export default function TasksScreen() {
  return (
    <Container className='tasks-container'>
      <TasksRentalsPendingPayment linkTo={(id) => `/rent-roll/${id}`} />
    </Container>
  )
}
