"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAutoChargeDisabledAt = exports.RENTAL_USER_ROLES = void 0;
const date_fns_1 = require("date-fns");
const date_fns_tz_1 = require("date-fns-tz");
const dates_1 = require("./dates");
exports.RENTAL_USER_ROLES = Object.freeze({
    PRIMARY: 'primary',
    TENANT: 'tenant'
});
const isAutoChargeDisabledAt = (autoChargeDisabledAt, today = (0, dates_1.getCurrentUTCDate)()) => {
    const estToday = (0, date_fns_tz_1.utcToZonedTime)(today, dates_1.TIME_ZONES.EASTERN);
    const firstDayOfPriorMonth = (0, date_fns_1.startOfMonth)((0, date_fns_1.subMonths)(estToday, 1));
    return (autoChargeDisabledAt !== null &&
        (0, date_fns_1.isAfter)((0, date_fns_tz_1.utcToZonedTime)(autoChargeDisabledAt, dates_1.TIME_ZONES.EASTERN), firstDayOfPriorMonth));
};
exports.isAutoChargeDisabledAt = isAutoChargeDisabledAt;
