import React, { createContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import type { MoveInPayment } from '@homevest/types/move-in-payment'

import axios from 'lib/axios'
import { Loading } from 'ui'

const useMoveInPaymentQuery = (rentalId: string) =>
  useQuery<MoveInPayment, Error>({
    queryKey: ['fetchMoveInPayment'],
    queryFn: async () => {
      const { data } = await axios.get<MoveInPayment>(`/rentals/${rentalId}/move_in_payment`)
      return data
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

type MoveInPaymentContextType = {
  moveInPayment?: MoveInPayment
  moveInPaymentError?: Error
}

export const MoveInPaymentContext = createContext<MoveInPaymentContextType>({})

export const MoveInPaymentContextProvider: React.FC<
  React.PropsWithChildren<{ rentalId: string }>
> = ({ rentalId, children }) => {
  const { isLoading, isFetching, data, error } = useMoveInPaymentQuery(rentalId)

  if (isLoading || isFetching) {
    return <Loading />
  }

  return (
    <MoveInPaymentContext.Provider
      value={{
        moveInPayment: data,
        moveInPaymentError: error ?? undefined
      }}
    >
      {children}
    </MoveInPaymentContext.Provider>
  )
}

type RefetchMoveInPaymentContextType = {
  refetchMoveInPayment?: (options?: {
    throwOnError?: boolean
    cancelRefetch?: boolean
  }) => Promise<ReturnType<typeof useQuery<MoveInPayment, Error>>>
}

export const RefetchMoveInPaymentContext = createContext<RefetchMoveInPaymentContextType>({})

export const RefetchMoveInPaymentContextProvider: React.FC<
  React.PropsWithChildren<{ rentalId: string }>
> = ({ rentalId, children }) => {
  const { refetch } = useMoveInPaymentQuery(rentalId)

  return (
    <RefetchMoveInPaymentContext.Provider value={{ refetchMoveInPayment: refetch }}>
      {children}
    </RefetchMoveInPaymentContext.Provider>
  )
}
