import React, { memo } from 'react'
import PropTypes from 'prop-types'

function Pencil({ width = '16', height = '16', color = '#85807A' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 12.7409V16H3.2591L12.8713 6.38783L9.61217 3.12873L0 12.7409ZM16 3.2591L12.7409 0L10.5421 2.2075L13.8012 5.46659L16 3.2591Z'
        fill={color}
      />
    </svg>
  )
}

Pencil.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default memo(Pencil)
