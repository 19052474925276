import { FormControl, FormLabel, HStack, Icon, Select, Spinner, Tooltip } from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import {
  RentalUserWithPaymentMethodsFragment,
  UserWithPaymentMethodsFragment,
  UserPaymentMethodFragment,
  useRentalUsersWithPaymentMethodsQuery
} from 'graphql/generated'
import { first } from 'lodash'
import React, { useEffect, useState } from 'react'

const defaultUserFromList = (
  users: RentalUserWithPaymentMethodsFragment[]
): UserWithPaymentMethodsFragment | undefined => {
  // find primary user if possible
  const primaryUser = users.find((u) => u.role === 'primary')

  return (
    primaryUser?.user ??
    users.find((u) => u.user?.payment_methods?.length)?.user ??
    first(users)?.user
  )
}

interface UserPaymentMethodSelectorProps {
  rentalId: string
  onUserChange: (userId: string) => void
  onPaymentMethodChange: (paymentMethodId: string) => void
}
const UserPaymentMethodSelector: React.FC<
  React.PropsWithChildren<UserPaymentMethodSelectorProps>
> = ({ rentalId, onUserChange, onPaymentMethodChange }) => {
  const [{ data }] = useRentalUsersWithPaymentMethodsQuery({
    variables: {
      rentalId
    }
  })

  const [user, setUser] = useState<UserWithPaymentMethodsFragment>()
  const [paymentMethod, setPaymentMethod] = useState<UserPaymentMethodFragment>()
  useEffect(() => {
    const rentalUsers = data?.rentals_by_pk?.rental_users
    if (rentalUsers?.length) {
      const defaultUser = defaultUserFromList(rentalUsers)
      setUser(defaultUser)
      onUserChange(defaultUser?.id)
      if (defaultUser?.payment_methods?.length) {
        const pm = defaultUser.payment_methods.find((p) => p.is_default === true)
        setPaymentMethod(pm)
        onPaymentMethodChange(pm?.id)
      }
    }
  }, [data, onPaymentMethodChange, onUserChange])

  const [paymentMethodOptions, setPaymentMethodOptions] = useState<UserPaymentMethodFragment[]>([])
  useEffect(() => {
    setPaymentMethodOptions(user?.payment_methods || [])
    const pm =
      user?.payment_methods.find((p) => p.is_default === true) ?? first(user?.payment_methods)
    setPaymentMethod(pm)
    onPaymentMethodChange(pm?.id)
    onUserChange(user?.id)
  }, [user, onUserChange, onPaymentMethodChange])

  if (!data?.rentals_by_pk) {
    return <Spinner />
  }

  const handlePaymentMethodChange = (paymentMethodId: string) => {
    const pm = paymentMethodOptions.find((p) => p.id === paymentMethodId)
    setPaymentMethod(pm)
    onPaymentMethodChange(pm?.id)
  }

  const { rentals_by_pk: rental } = data
  return (
    <HStack>
      <FormControl>
        <FormLabel>Tenant</FormLabel>
        <Select
          value={user?.id}
          onChange={(evt) => {
            const user = rental.rental_users.find((ru) => ru.user.id === evt.target.value)
            setUser(user?.user)
          }}
        >
          {rental.rental_users.map((rentalUser) => (
            <option value={rentalUser.user.id} disabled={!rentalUser.user.payment_methods?.length}>
              {rentalUser.user.first_name} {rentalUser.user.last_name} ({rentalUser.role})
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel justifyContent='center'>
          Payment Method
          <Tooltip label='* is the default payment method'>
            <Icon as={InformationCircleIcon} />
          </Tooltip>
        </FormLabel>

        <Select
          value={paymentMethod?.id}
          onChange={(evt) => {
            handlePaymentMethodChange(evt.target.value)
          }}
        >
          {paymentMethodOptions.map((pm) => (
            <option value={pm.id}>
              {pm.type === 'ach' ? 'Bank' : 'Card'} - {pm.is_default ? '*' : ''}
              {pm.name} ({pm.mask})
            </option>
          ))}
        </Select>
      </FormControl>
    </HStack>
  )
}

export default UserPaymentMethodSelector
