import React, { Suspense } from 'react'
import { Divider, Stack } from '@chakra-ui/react'
import { rentalApplications } from '@homevest/utils'
import { RentalApplication } from 'types/RentalApplication'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { CustomSpinner } from 'ui/Loading'
import { ApplicantSummaryPanel } from './ApplicantSummaryPanel'
import DecisionPanel from './DecisionPanel'
import RecommendationPanel from './RecommendationPanel'

const {
  RENTAL_APPLICATION_STATUSES: { PENDING_CUSTOMER_INFORMATION, PENDING_APPLICATION_REVIEW }
} = rentalApplications

const UnderwritingDetails = ({ rentalApplication }: { rentalApplication: RentalApplication }) => {
  const isCustomerInfo = rentalApplication.status === PENDING_CUSTOMER_INFORMATION
  const isReview = rentalApplication.status === PENDING_APPLICATION_REVIEW

  if (isCustomerInfo) {
    return null
  }

  return (
    <ContentSectionCard
      title='Underwriting Details'
      collapsable={isReview}
      isOpenDefault={!isReview}
      padding
    >
      <Suspense fallback={<CustomSpinner />}>
        <Stack gap={4}>
          {!isReview && (
            <>
              <DecisionPanel
                rentalApplicationId={rentalApplication.id}
                rentalApplicationStatus={rentalApplication.status}
              />
              <Divider />
            </>
          )}
          <RecommendationPanel rentalApplicationId={rentalApplication.id} />
          <ApplicantSummaryPanel rentalApplicationId={rentalApplication.id} />
        </Stack>
      </Suspense>
    </ContentSectionCard>
  )
}

export default UnderwritingDetails
