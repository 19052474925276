import React from 'react'
import { ColDef, GridReadyEvent, ICellRendererParams } from 'ag-grid-community'
import startCase from 'lodash/startCase'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Alert, Link, Spinner } from '@chakra-ui/react'
import { ScopeLineItemWithVendor } from '@homevest/types/construction-scopes'

import { BaseGrid } from 'components/TailwindUIToolkit'
import { LINK_COLOR } from 'constants/colors'
import { RETOOL_SCOPE_MANAGER_APP_URL } from 'constants/urls'
import type { useConstructionProjectScopeQuery } from 'lib/construction-projects'
import { formatMoney } from 'lib/numbers'

const columns: ColDef<ScopeLineItemWithVendor>[] = [
  {
    colId: 'title',
    headerName: 'Title',
    field: 'title',
    valueFormatter: (params) => startCase(params.value),
    flex: 0.75
  },
  {
    colId: 'details',
    headerName: 'Details',
    field: 'details',
    flex: 2
  },
  {
    colId: 'category',
    headerName: 'Category',
    field: 'category',
    flex: 0.75
  },
  {
    colId: 'location',
    headerName: 'Location',
    field: 'location_within_home',
    valueFormatter: (params) => startCase(params.value),
    flex: 0.5
  },
  {
    colId: 'budget',
    headerName: 'Budget',
    field: 'budgeted_amount',
    valueFormatter: (params) => formatMoney(params.value, 2, '$'),
    flex: 0.5
  },
  {
    colId: 'vendor',
    headerName: 'Vendor',
    field: 'vendor_name',
    cellRenderer: (params: ICellRendererParams<ScopeLineItemWithVendor>) => {
      return (
        <Link
          as={ReactRouterLink}
          isExternal
          to={`/construction/vendors/${params.data?.vendor_id}`}
          color={LINK_COLOR}
        >
          {params.value}
        </Link>
      )
    }
  },
  {
    colId: 'ticket',
    headerName: 'Latchel Ticket',
    field: 'ticket_title',
    cellRenderer: (params: ICellRendererParams<ScopeLineItemWithVendor>) => {
      return (
        <Link
          isExternal
          href={`https://app.latchel.com/admin/work-order/${params.data?.ticket_latchel_id}`}
          color={LINK_COLOR}
        >
          {params.value}
        </Link>
      )
    }
  }
]

const ScopeLineItemsGrid: React.FC<{
  data: ReturnType<typeof useConstructionProjectScopeQuery>
}> = ({ data }) => {
  const { data: scope, error, isError, isFetching, isLoading } = data

  if (isLoading || isFetching) {
    return <Spinner />
  }

  if (isError && error.message) {
    return <Alert status='error'>{error.message}</Alert>
  }

  if (!scope) {
    return (
      <div className='px-4 py-3 text-sm'>
        No construction scope for this project. Create a scope in the{' '}
        <Link isExternal color={LINK_COLOR} href={RETOOL_SCOPE_MANAGER_APP_URL}>
          Scope Manager Retool
        </Link>
        .
      </div>
    )
  }

  const onGridReady = (evt: GridReadyEvent) => {
    evt.columnApi.applyColumnState({
      state: [
        {
          colId: 'title',
          sort: 'asc'
        }
      ]
    })
  }

  return (
    <div className='h-[60vh]'>
      <BaseGrid<ScopeLineItemWithVendor>
        rowData={scope.line_items}
        columns={columns}
        onGridReady={onGridReady}
        defaultColDefOverrides={{
          resizable: true,
          flex: 1
        }}
        rowAutoHeight={true}
      />
    </div>
  )
}

export default ScopeLineItemsGrid
