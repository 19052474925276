import React, { FunctionComponent } from 'react'
import { Spinner } from 'reactstrap'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { useResourceIdsByPortfolioHomeIdQuery } from 'graphql/generated'
import { NotesGrid } from 'components/TailwindUIToolkit'
import { CreateNoteButtonWithModal } from 'components/TailwindUIToolkit'
import { recursiveObjectKeySearch } from 'lib/utils'

type NotesPropTypes = {
  portfolioHomeId: string
}

const NotesCard: FunctionComponent<React.PropsWithChildren<NotesPropTypes>> = ({
  portfolioHomeId
}) => {
  const [{ data }] = useResourceIdsByPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  const ids = data && recursiveObjectKeySearch(data, 'id')

  return (
    <ContentSectionCard
      title='Notes'
      staticSize={true}
      action={
        <CreateNoteButtonWithModal resourceId={portfolioHomeId} resourceType='portfolio_home' />
      }
    >
      <React.Suspense fallback={<Spinner />}>
        <NotesGrid resourceIds={ids || []} filter={(note) => note.category !== 'tag'} />
      </React.Suspense>
    </ContentSectionCard>
  )
}

export default NotesCard
