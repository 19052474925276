import axios from 'lib/axios'
import { QualificationDetails } from '@homevest/types/rental-applications'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export const useRentalAppQualification = (
  rentalApplicationId: string,
  options: UseQueryOptions<QualificationDetails> = {}
) => {
  return useQuery({
    queryKey: ['rentalApplicationQualification', rentalApplicationId],
    queryFn: async () => {
      const response = await axios.get(
        `/admin/rental_applications/${rentalApplicationId}/qualification`
      )
      return response.data as QualificationDetails
    },
    // refetchOnMount: false,
    // refetchOnReconnect: false,
    // refetchOnWindowFocus: false,
    ...options
  })
}
