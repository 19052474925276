import {
  RECEIVED_MARKETS,
  MARKET_SELECTED,
  TOUR_TO_BOOK_RECEIVED,
  TOUR_BOOKED,
  TOUR_SKIPPED,
  RECEIVED_POTENTIAL_TOURS,
  SHOWINGTIME_UNAVAILABILITY_CHANGED,
  SHOWINGTIME_UNAVAILABILITY_ROW_ADDED,
  SHOWINGTIME_UNAVAILABILITY_CONFIRMED,
  TASK_TRIGGERED,
  SET_MODAL_STATE
} from './actions'
import {
  ALL_MARKETS,
  INDICATED_SHOWINGTIME_UNAVAILABLE,
  MODAL_CLOSED,
  MODAL_LOADING
} from 'constants/dispatch'
import { getDatesFromAvailabilities, isUnavailabilityValid } from 'lib/dispatch-heuristic'

const DEFAULT = {
  markets: [],
  potentialTours: [],
  tourToBook: {},
  leadGroupPropertiesToSkip: new Set(),
  showingtimeAvailabilityRestrictions: {},
  selectedMarket: ALL_MARKETS,
  modalState: MODAL_CLOSED,
  additionalNotes: ''
}

export default function reducer(state = { ...DEFAULT }, action) {
  switch (action.type) {
    case RECEIVED_MARKETS: {
      const { markets } = action
      const isSelectedMarketValid = markets.filter((m) => m.id === state.selectedMarket)
      return {
        ...state,
        markets,
        selectedMarket: isSelectedMarketValid ? state.selectedMarket : DEFAULT.selectedMarket
      }
    }
    case RECEIVED_POTENTIAL_TOURS: {
      const { potentialTours } = action
      const filteredTours = potentialTours
        .map((t) => ({
          ...t,
          requestedProperties: t.requested_properties.filter(
            (p) => !state.leadGroupPropertiesToSkip.has(p.lead_group_property_id)
          )
        }))
        .filter(({ requestedProperties }) => requestedProperties.length > 0)

      return {
        ...state,
        potentialTours: filteredTours
      }
    }
    case MARKET_SELECTED: {
      const { selectedMarket } = action
      return { ...state, selectedMarket }
    }
    case SET_MODAL_STATE:
      return { ...state, modalState: action.modalState }
    case TOUR_TO_BOOK_RECEIVED: {
      const { modalState, tourToBook } = action
      return { ...state, modalState, tourToBook }
    }
    case TOUR_BOOKED: {
      return {
        ...state,
        modalState: MODAL_LOADING,
        tourToBook: {},
        additionalNotes: ''
      }
    }

    case TOUR_SKIPPED: {
      const { lead_group_properties_on_tour: additionalPropertiesToSkip } = state.tourToBook
      const { leadGroupPropertiesToSkip } = state
      additionalPropertiesToSkip.forEach((p) => leadGroupPropertiesToSkip.add(p))
      return {
        ...state,
        leadGroupPropertiesToSkip,
        additionalNotes: '',
        modalState: MODAL_LOADING
      }
    }
    case INDICATED_SHOWINGTIME_UNAVAILABLE: {
      const currentUnavailabilities = {}
      const eligibleDates = getDatesFromAvailabilities(
        state.tourToBook.eligible_lead_group_availabilities
      )
      const { suggested_tour_route: suggestedTourRoute } = state.tourToBook

      suggestedTourRoute.forEach(({ property_id: propertyId }) => {
        currentUnavailabilities[propertyId] = [
          {
            currentDate: eligibleDates[0],
            currentStart: '',
            currentEnd: '',
            isValid: true
          }
        ]
      })
      return {
        ...state,
        modalState: INDICATED_SHOWINGTIME_UNAVAILABLE,
        eligibleDates,
        currentUnavailabilities
      }
    }
    case SHOWINGTIME_UNAVAILABILITY_CHANGED: {
      const { currentUnavailabilities } = state
      const { inputField, propertyId, rowIdx, value } = action
      currentUnavailabilities[propertyId][rowIdx][inputField] = value
      const { currentStart, currentEnd } = currentUnavailabilities[propertyId][rowIdx]

      currentUnavailabilities[propertyId][rowIdx].isValid =
        currentStart && currentEnd ? isUnavailabilityValid(currentStart, currentEnd) : true

      return {
        ...state,
        currentUnavailabilities
      }
    }
    case SHOWINGTIME_UNAVAILABILITY_ROW_ADDED: {
      const { currentUnavailabilities, eligibleDates } = state
      const { propertyId } = action

      currentUnavailabilities[propertyId].push({
        currentDate: eligibleDates[0],
        currentStart: '',
        currentEnd: '',
        isValid: true
      })

      return {
        ...state,
        currentUnavailabilities
      }
    }
    case SHOWINGTIME_UNAVAILABILITY_CONFIRMED: {
      const { mergedUnavailabilities, additionalNotes } = action

      return {
        ...state,
        showingtimeAvailabilityRestrictions: mergedUnavailabilities,
        additionalNotes,
        currentUnavailabilities: {},
        eligibleDates: [],
        modalState: MODAL_LOADING
      }
    }
    case TASK_TRIGGERED:
      return {
        ...state,
        modalState: MODAL_LOADING,
        tourToBook: {},
        additionalNotes: ''
      }
    default:
      return { ...state }
  }
}
