import React from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

const CurrencyInput = ({
  value,
  onChange
}: {
  value: number | ''
  onChange: React.ChangeEventHandler<HTMLInputElement>
}) => {
  return (
    <InputGroup>
      <InputLeftAddon>$</InputLeftAddon>
      <Input type='number' value={value} onChange={onChange} />
    </InputGroup>
  )
}

export default CurrencyInput
