import React from 'react'
import { HomeIcon } from '@heroicons/react/20/solid'
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { types } from '@homevest/timelines'
import { TimelineEventContentRenderer, TimelineEventDisplayTemplate } from '../types'
import { PropertyDetails } from './PropertyDetails'
import { SC } from 'components/TailwindUIToolkit'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'

type TimelineEventType = types.RentalAppTimelineEvent

const PreviewContent: TimelineEventContentRenderer<TimelineEventType> = ({
  event,
  customer,
  home
}) => {
  const ApplicationBadge: React.FC<React.PropsWithChildren<unknown>> = () => {
    if (event.status === 'reviewed') {
      if (event.metadata?.is_review_approved === true) {
        return mapContentToBadge('review_approved')
      } else if (event.metadata?.is_review_approved === false) {
        return mapContentToBadge('review_unapproved')
      }
    }
    return mapContentToBadge(event.status)
  }

  return (
    <div>
      <SC.MessageText>
        <SC.InlineBold>{customer?.displayName}'s</SC.InlineBold> application for{' '}
        <SC.InlineBold>{home?.address || 'some property'}</SC.InlineBold> was {event.status}
      </SC.MessageText>
      <ApplicationBadge />
    </div>
  )
}

const ExpandedContent: TimelineEventContentRenderer<TimelineEventType> = ({ event, home }) => {
  const applicationRent = event.metadata?.application_rent
  return (
    <>
      <PropertyDetails details={home} applicationRent={applicationRent} />
      <SC.Subsection>
        <SC.MessageText>
          <SC.InlineBold>ID</SC.InlineBold> {event.rentalApplicationId}
        </SC.MessageText>
        <SC.MessageText>
          <SC.InlineBold>{capitalize(event.status)}</SC.InlineBold> at{' '}
          {format(new Date(event.eventTimeAt), 'PPPp')}
        </SC.MessageText>
      </SC.Subsection>
    </>
  )
}

export const RentalApplicationEventDisplayTemplate: TimelineEventDisplayTemplate<TimelineEventType> =
  {
    icon: HomeIcon,
    iconColor: '#14B8A6',
    previewContent: PreviewContent,
    expandedContent: ExpandedContent
  }
