import React, { FunctionComponent } from 'react'
import { Box, Avatar, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { auth } from 'lib/firebase'
import { useAuthContext } from 'context/auth'

const ProfileMenu: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { firebaseUser } = useAuthContext()

  return (
    <Box px={8}>
      {firebaseUser && (
        <div>
          <Menu>
            <MenuButton as={Avatar} src={firebaseUser.photoURL || ''} h={10} w={10} />
            <MenuList>
              <MenuItem
                onClick={async () => {
                  await auth.signOut()
                  window.location.reload()
                }}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      )}
    </Box>
  )
}

export default ProfileMenu
