import React, { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import { Spinner, Button, Text } from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'
import { StoreState } from 'store'
import firebase from 'lib/firebase'
import { useUpupInsertDocumentMutation } from 'graphql/generated'
import { documents } from '@homevest/utils'

const storageRef = firebase.storage().ref()

export type FileInfo = {
  file: File
  name: string
  status: string
  type: string
}

const uploadDoc = async ({ uploadPath, file }: { uploadPath: string; file: File }) => {
  const upload = storageRef.child(uploadPath).put(file, { contentType: file.type })

  let downloadUrl: undefined | string

  upload.on(firebase.storage.TaskEvent.STATE_CHANGED, {
    complete: () => {
      upload.snapshot.ref.getDownloadURL().then((result) => (downloadUrl = result))
    }
  })

  while (!downloadUrl) {
    await new Promise((resolve) => setTimeout(resolve, 100))
  }

  return downloadUrl
}

export const BulkUploader: FunctionComponent<
  React.PropsWithChildren<{
    files?: FileInfo[]
    resourceType: string
    resourceId: string
    onSuccess?: () => void
  }>
> = ({ files = [], resourceId, resourceType, onSuccess }) => {
  const [uploadActive, setUploadActive] = useState(false)
  const [uploadSuccessful, setUploadSuccessful] = useState(false)
  const [error, setError] = useState<any>()

  const admin = useSelector((state: StoreState) => state.admin)

  const [_, insertDocument] = useUpupInsertDocumentMutation()

  const insertDocs = async (uploadedFiles: (FileInfo & { docPath: string })[]) => {
    for (const file of uploadedFiles) {
      console.log('inserting to db: ', file.name)
      await insertDocument({
        external_id: file.docPath,
        external_source: documents.DOCUMENT_EXTERNAL_SOURCES.GOOGLE_CLOUD,
        friendly_name: file.name,
        resource_id: resourceId,
        resource_type: resourceType,
        status: file.status,
        type: file.type,
        uploaded_by_admin_id: admin.id
      })
    }
  }

  const handleUpload = () => {
    if (files.length === 0 || resourceId === undefined) {
      setError('something went wrong')
      return
    }

    setUploadActive(true)

    // first upload all files to storage
    const promises = files.map(async (file) => {
      const docPath = `${resourceType}/${resourceId}/documents/${file.type}/${uuidv4()}`

      console.log('uploading to storage: ', file.name)
      await uploadDoc({
        file: file.file,
        uploadPath: `/${docPath}`
      }).catch((reason) => {
        console.error(reason)
        setError(reason)
      })

      return { ...file, docPath }
    })

    // then insert into documents table
    Promise.all(promises)
      .then(insertDocs)
      .then((_) => {
        setUploadSuccessful(true)
        setUploadActive(false)
        onSuccess?.()
      })
      .catch((reason) => {
        console.error(reason)
        setError(reason)
      })
  }

  if (files.length === 0) {
    return null
  }

  return (
    <>
      <Button
        h='32'
        shadow='md'
        colorScheme='blackAlpha'
        onClick={handleUpload}
        disabled={
          files.length === 0 || !!files.find((fi) => !fi.type) || !resourceId || uploadSuccessful
        }
      >
        {uploadSuccessful ? (
          `Successfully uploaded ${files.length} documents!`
        ) : !resourceId ? (
          <Text color='red.500'>'Make sure to select a resource to attach documents to!!!'</Text>
        ) : uploadActive ? (
          <Spinner />
        ) : (
          'Upload'
        )}
      </Button>
      {error && <Text color='red.500'>{error}</Text>}
    </>
  )
}
