import React, { FunctionComponent, useEffect, useMemo } from 'react'
import { parseISO, format } from 'date-fns'
import { useLocation } from 'react-router-dom'
import {
  ColumnDef,
  flexRender,
  SortingState,
  PaginationState,
  ColumnFiltersState,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
  FilterFn
} from '@tanstack/react-table'
import { rankItem } from '@tanstack/match-sorter-utils'
import { ChevronDownIcon, EyeIcon } from '@heroicons/react/20/solid'
import { UpupDocumentsByResourceQuery } from '../../graphql/generated'
import TablePagination from './TablePagination'
import { STATUS_MAP, ACQUISITIONS_DOCUMENT_TYPE_MAP, URL_QUERY_MAP } from 'lib/document-manager'

type Document = UpupDocumentsByResourceQuery['documents'][number]

type TableData = Document & { uploaded_by?: string }

const DocumentsTable: FunctionComponent<
  React.PropsWithChildren<{
    documents: Document[]
    onManage: (document: Document) => void
    onView: (document: Document) => void
    onNote: (document: Document) => void
  }>
> = ({ documents, onManage, onView, onNote }) => {
  const { search } = useLocation()
  const [sorting, setSorting] = React.useState<SortingState>([{ desc: true, id: 'created_at' }])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  })

  const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize])

  useEffect(() => {
    const params = new URLSearchParams(search)
    const filters = Object.keys(URL_QUERY_MAP)
      .map((id) => ({
        id: URL_QUERY_MAP[id as keyof typeof URL_QUERY_MAP],
        value: params.get(id)
      }))
      .filter(({ value }) => typeof value === 'string' && value.length > 0)

    setColumnFilters(filters)
  }, [search])

  const data = useMemo(
    () =>
      documents.map((document) => ({
        ...document,
        type: ACQUISITIONS_DOCUMENT_TYPE_MAP[
          document.type as keyof typeof ACQUISITIONS_DOCUMENT_TYPE_MAP
        ],
        status: STATUS_MAP[document.status as keyof typeof STATUS_MAP],
        created_at: format(parseISO(document.created_at), 'yyyy-MM-dd'),
        uploaded_by: document.uploaded_by_admin?.first_name
      })),
    [documents]
  )

  const columns = useMemo(
    () => getTableHeaders(onManage, onView, onNote),
    [onManage, onView, onNote]
  )

  const table = useReactTable({
    data,
    columns,
    state: { sorting, columnFilters, pagination },
    filterFns: { fuzzy: fuzzyFilter },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  return (
    <div className='flex flex-col gap-4'>
      {table.getPageCount() > 1 && (
        <TablePagination
          currentPage={table.getState().pagination.pageIndex}
          pageCount={table.getPageCount()}
          onFirst={() => table.setPageIndex(0)}
          onPrev={() => table.previousPage()}
          onNext={() => table.nextPage()}
          onLast={() => table.setPageIndex(table.getPageCount() - 1)}
        />
      )}
      <div className='w-full overflow-x-scroll'>
        <table className='min-w-[64rem] divide-y divide-gray-100 overflow-hidden rounded-lg'>
          <thead className='bg-gray-100'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isSorted = header.column.getIsSorted()
                  return (
                    <th className='p-2' key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div className='flex flex-col gap-2'>
                          <div
                            className={`flex flex-row items-center whitespace-nowrap ${
                              header.column.getCanSort() ? 'cursor-pointer select-none' : ''
                            }`}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {isSorted && (
                              <ChevronDownIcon
                                className={`${
                                  isSorted === 'asc' ? 'rotate-180 transform' : ''
                                } h-5 w-5`}
                              />
                            )}
                          </div>
                          {header.column.getCanFilter() && (
                            <div>
                              <input
                                value={(header.column.getFilterValue() as string) || ''}
                                onChange={(event) =>
                                  header.column.setFilterValue(event.target.value)
                                }
                                type='text'
                                className='block w-full rounded-md border-gray-300 px-2 py-1 text-sm font-normal'
                                placeholder='Filter...'
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody className='divide-y divide-gray-100'>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td className='p-2' key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {table.getPageCount() > 1 && (
        <TablePagination
          currentPage={table.getState().pagination.pageIndex}
          pageCount={table.getPageCount()}
          onFirst={() => table.setPageIndex(0)}
          onPrev={() => table.previousPage()}
          onNext={() => table.nextPage()}
          onLast={() => table.setPageIndex(table.getPageCount() - 1)}
        />
      )}
    </div>
  )
}

export default DocumentsTable

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value)
  addMeta({ itemRank })
  return itemRank.passed
}

const getTableHeaders = (
  onManage: (document: Document) => void,
  onView: (document: Document) => void,
  onNote: (document: Document) => void
): ColumnDef<TableData>[] => [
  {
    accessorKey: 'action',
    header: 'Action',
    enableColumnFilter: false,
    cell: (info) => (
      <div className='flex flex-row items-center gap-2'>
        <button
          className='whitespace-nowrap rounded bg-gray-200 px-2 py-1 font-medium'
          onClick={() => onManage(info.row.original)}
        >
          Manage
        </button>
        <button
          className='whitespace-nowrap rounded bg-rose-200 px-2 py-1 font-medium'
          onClick={() => onView(info.row.original)}
        >
          View
        </button>
      </div>
    )
  },
  {
    accessorKey: 'type',
    header: 'Document type',
    cell: (info) => info?.getValue() ?? null
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: (info) => info?.getValue() ?? null
  },
  {
    accessorKey: 'uploaded_by',
    header: 'Uploaded by',
    cell: (info) => info?.getValue() ?? null
  },
  {
    accessorKey: 'created_at',
    header: 'Date uploaded',
    cell: (info) => info?.getValue() ?? null
  },
  {
    accessorKey: 'resource_notes',
    header: 'Notes',
    cell: (info) => {
      const note = (info?.getValue() as string) ?? null
      if (!note) {
        return null
      }
      return (
        <div className='flex flex-row items-center gap-2'>
          <div className='max-w-xs overflow-hidden text-ellipsis whitespace-nowrap'>{note}</div>
          {note.length > 32 && (
            <button
              className='rounded border border-gray-300 p-1 text-gray-500'
              onClick={() => onNote(info.row.original)}
            >
              <EyeIcon className='h-4 w-4' />
            </button>
          )}
        </div>
      )
    }
  },
  {
    accessorKey: 'friendly_name',
    header: 'Document name',
    cell: (info) => info?.getValue() ?? null
  }
]
