import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Button, IconButton, Link } from '@chakra-ui/react'
import { TrashIcon } from '@heroicons/react/20/solid'

import { UploadContainer } from 'components/Toolkit'

const MenuFilePicker = ({
  uploadPrefix,
  onUpload,
  onClear
}: {
  uploadPrefix: string
  onUpload: (data: {
    isLoading: boolean
    downloadUrl: string
    file: File
    error?: string
    uploadPath: string
  }) => void
  onClear: () => void
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const [uploadState, setUploadState] = useState<any>({})
  const [uploadId, setUploadId] = useState(uuidv4())

  const uploadPath = `${uploadPrefix}/${uploadId}`

  const onDropzoneChange = async (data: any) => {
    if (data.isLoading) {
      setIsUploading(true)
    }
    if (data.downloadUrl) {
      onUpload(data)
      setIsUploading(false)
    }
    setUploadState(data)
  }

  if (uploadState.downloadUrl) {
    return (
      <div className='flex flex-row items-center justify-center gap-2'>
        <Link color={'blue.500'} href={uploadState.downloadUrl} target='_blank' rel='noreferrer'>
          {' '}
          {uploadState?.file?.name}
        </Link>
        <IconButton
          size='sm'
          variant='ghost'
          aria-label='Remove file'
          icon={<TrashIcon className='h-4 w-4' />}
          onClick={() => {
            setUploadState({})
            setUploadId(uuidv4())
            onClear()
          }}
        />
      </div>
    )
  }

  return (
    <UploadContainer uploadPath={uploadPath} onChange={onDropzoneChange as any}>
      <Button w='full' size='sm' colorScheme='teal' variant='outline' isLoading={isUploading}>
        Choose file...
      </Button>
    </UploadContainer>
  )
}

export default MenuFilePicker
