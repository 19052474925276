import React from 'react'
import { Box, Button, Spinner, Text } from '@chakra-ui/react'
import { startCase, sortBy } from 'lodash'

import { useLeadGroupAssignmentsByLeadGroupIdQuery } from 'graphql/generated'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { combineFirstNameLastName } from 'lib/utils'
import { AssignApplicationModal } from './AssignApplicationModal'

const AssignApplicationDetailsInternal: React.FC<
  React.PropsWithChildren<{
    leadGroupId: string
  }>
> = ({ leadGroupId }) => {
  const [isResolveModalOpen, setIsResolveModalOpen] = React.useState(false)
  const [{ data, error }, refetchAssignments] = useLeadGroupAssignmentsByLeadGroupIdQuery({
    variables: { leadGroupId }
  })

  let inner: React.ReactElement | React.ReactElement[]
  if (error) {
    inner = <Text>Something went wrong.</Text>
  } else if ((data?.agent_lead_group_owners?.length ?? 0) === 0) {
    inner = <Text>App Unassigned.</Text>
  } else {
    inner = sortBy(data!.agent_lead_group_owners, ['agent_type']).map((assignment) => (
      <Box key={assignment.id}>
        <Text as='b'> {startCase(assignment.agent_type)}: </Text>
        <Text>{combineFirstNameLastName(assignment.admin)}</Text>
      </Box>
    ))
  }
  return (
    <>
      {isResolveModalOpen ? (
        <AssignApplicationModal
          leadGroupId={leadGroupId}
          isOpen={isResolveModalOpen}
          setOpen={setIsResolveModalOpen}
          onSubmit={() => refetchAssignments({ requestPolicy: 'network-only' })}
        />
      ) : null}
      <ContentSectionCard
        padding
        collapsable={false}
        title={'Assignments'}
        action={
          <Button
            onClick={() => {
              setIsResolveModalOpen(true)
            }}
          >
            Assign
          </Button>
        }
      >
        <Box display={'flex'} flexDir={'row'} justifyContent={'space-around'}>
          {inner}
        </Box>
      </ContentSectionCard>
    </>
  )
}

// Container to hold the react suspense
export const AssignApplicationDetails: React.FC<
  React.PropsWithChildren<{
    leadGroupId: string
  }>
> = ({ leadGroupId }) => (
  <React.Suspense fallback={<Spinner />}>
    <AssignApplicationDetailsInternal leadGroupId={leadGroupId} />
  </React.Suspense>
)
