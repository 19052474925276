import React from 'react'
import { countBy, startCase } from 'lodash'
import { Stack, Spinner, Tabs, Tab, TabList, TabPanels, TabPanel } from '@chakra-ui/react'
import { HomeIcon } from '@heroicons/react/20/solid'
import { HomesGridQuery } from 'graphql/generated'
import type { StatusFilter } from 'screens/Homes/HomesList'
import StatusCountsDisplay from 'components/StatusCounts'

type HomeStatusType =
  | 'summary_status'
  | 'occupancy_status'
  | 'construction_status'
  | 'ownership_status'

const statusCountDefinitions: HomeStatusType[] = [
  'summary_status',
  'occupancy_status',
  'construction_status',
  'ownership_status'
]

export const StatusCountBar: React.FC<
  React.PropsWithChildren<{
    homes360Data?: HomesGridQuery
    onStatusClicked?: (statusFilter?: StatusFilter) => void
  }>
> = ({ homes360Data, onStatusClicked = () => {} }) => {
  const homes = homes360Data?.homes_list

  return (
    <Stack direction='column' mt='8' spacing='8'>
      {homes360Data ? (
        <Tabs variant='soft-rounded' align='center'>
          <TabList>
            {statusCountDefinitions.map((statusType) => (
              <Tab key={statusType}>{startCase(statusType)}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {statusCountDefinitions.map((statusType) => (
              <TabPanel key={statusType}>
                <StatusCountsDisplay<HomeStatusType>
                  onStatusClicked={(status) => {
                    onStatusClicked(
                      status
                        ? {
                            statusName: status,
                            statusType: statusType
                          }
                        : undefined
                    )
                  }}
                  icon={HomeIcon}
                  statusCounts={
                    countBy(homes, (h) => h[statusType]) as Record<HomeStatusType, number>
                  }
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        <Spinner boxSize='48' size='xl' alignSelf='center' />
      )}
    </Stack>
  )
}
