import React, { useState } from 'react'
import { first } from 'lodash'
import { Textarea, IconButton, Text, Tooltip, Box } from '@chakra-ui/react'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { PencilIcon } from '@heroicons/react/24/solid'

import { LockboxCodeFragment, useCreateNewLockboxCodeMutation } from 'graphql/generated'

export const LockboxCode: React.FC<React.PropsWithChildren<LockboxCodeFragment>> = ({
  id: portfolioHomeId,
  active_lockbox_code
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const [{ data }, addLockboxCode] = useCreateNewLockboxCodeMutation()
  const lockboxCode = data?.insert_lockbox_codes_one ?? first(active_lockbox_code)
  const handleAddLockboxCode = async (code: string) => {
    await addLockboxCode({
      code,
      portfolioHomeId
    })
    setIsEditing(false)
  }

  const CodeInput = (
    <div className='p-2'>
      <Textarea
        autoFocus
        size='sm'
        variant='unstyled'
        placeholder={lockboxCode?.code ?? 'Add lockbox code...'}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleAddLockboxCode(e.currentTarget.value)
          } else if (e.key === 'Escape') {
            setIsEditing(false)
          }
        }}
        onBlur={() => {
          setIsEditing(false)
        }}
      />
    </div>
  )

  const createdAt = new Date(lockboxCode?.created_at ?? '')
  const createdBy = `${lockboxCode?.admin?.first_name} ${lockboxCode?.admin?.last_name}`
  const CodeDisplay = (
    <Tooltip
      shouldWrapChildren={!!lockboxCode}
      label={`Updated ${createdAt.toLocaleDateString()} by ${createdBy}`}
    >
      <div className='flex flex-row items-center gap-2 py-2'>
        <div>
          <LockClosedIcon className='h-4 w-4' />
        </div>
        <Text fontSize={'smaller'}>{lockboxCode ? lockboxCode.code : 'Add lockbox code...'}</Text>
        <IconButton
          size={'xs'}
          icon={<PencilIcon className='h-4 w-4' />}
          onClick={() => {
            setIsEditing(true)
          }}
          variant={'ghost'}
          aria-label='Edit lockbox code'
        />
      </div>
    </Tooltip>
  )

  if (!portfolioHomeId) {
    return null
  }

  return (
    <Box
      bgColor={'whitesmoke'}
      onDoubleClick={() => setIsEditing(true)}
      my={2}
      px={2}
      borderRadius={10}
    >
      {isEditing ? CodeInput : CodeDisplay}
    </Box>
  )
}
