import React, { useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'
import { StoreState } from 'store'
import { startCase } from 'lodash'

import { Button, TableContainer, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { formatMoney, formatMonths } from 'lib/numbers'
import { hasCapability } from 'lib/admin-perms'
import CreateRentalAgreementHistoryModal from './CreateRentalAgreementHistoryModal'
import EditRentalAgreementHistoryModal from './EditRentalAgreementHistoryModal'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { WithRentalAgreementInfoFragment } from 'graphql/generated'

const { LEDGER_RENTAL_AGREEMENT_HISTORY_CREATOR, LEDGER_RENTAL_AGREEMENT_HISTORY_EDITOR } =
  capabilities.CAPABILITY_TYPES

export default function RentalAgreementHistories({
  rental
}: {
  rental: WithRentalAgreementInfoFragment
}) {
  const [isCreateRentalAgreementHistoryModalOpen, setIsCreateRentalAgreementHistoryModalOpen] =
    useState(false)
  const [isEditRentalAgreementHistoryModalOpen, setIsEditRentalAgreementHistoryModalOpen] =
    useState(false)
  const [rentalAgreementHistoryToEdit, setRentalAgreementHistoryToEdit] = useState<any>(null)
  const admin = useSelector<StoreState, any>((state) => state.admin)

  const canCreateRentalAgreements = hasCapability(admin, LEDGER_RENTAL_AGREEMENT_HISTORY_CREATOR)
  const canEditRentalAgreements = hasCapability(admin, LEDGER_RENTAL_AGREEMENT_HISTORY_EDITOR)

  const { id: rentalId, rental_agreement_histories: rentalAgreementHistories = [] } = rental

  return (
    <ContentSectionCard
      collapsable={false}
      title={'Rental Agreements'}
      action={
        canCreateRentalAgreements ? (
          <Button
            size='sm'
            colorScheme={'teal'}
            onClick={() => setIsCreateRentalAgreementHistoryModalOpen(true)}
          >
            Create +
          </Button>
        ) : undefined
      }
    >
      {rentalAgreementHistories.length ? (
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Starts At</Th>
                <Th>Ends At</Th>
                <Th>Rent</Th>
                <Th>Option Premium</Th>
                <Th>Type</Th>
                <Th>Renewal Term</Th>
                {canEditRentalAgreements && <Th />}
              </Tr>
            </Thead>
            <Tbody>
              {rentalAgreementHistories
                .sort((a, b) => (moment(a.ends_at).isBefore(b.ends_at) ? 1 : -1))
                .map((rentalAgreementHistory) => (
                  <Tr key={rentalAgreementHistory.id}>
                    <Td>{rentalAgreementHistory.starts_at}</Td>
                    <Td>{rentalAgreementHistory.ends_at}</Td>
                    <Td>{formatMoney(rentalAgreementHistory.rent, 2, '$')}</Td>
                    <Td>{formatMoney(rentalAgreementHistory.option_premium, 2, '$')}</Td>
                    <Td>{startCase(rentalAgreementHistory.type || '')}</Td>
                    <Td>{formatMonths(rentalAgreementHistory.renewal_months)}</Td>
                    {canEditRentalAgreements && (
                      <Td>
                        <Button
                          size='xs'
                          colorScheme='teal'
                          onClick={() => {
                            setIsEditRentalAgreementHistoryModalOpen(true)
                            setRentalAgreementHistoryToEdit(rentalAgreementHistory)
                          }}
                        >
                          Edit
                        </Button>
                      </Td>
                    )}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <div>Need to backfill rental agreement histories</div>
      )}

      {canCreateRentalAgreements && (
        <CreateRentalAgreementHistoryModal
          rentalId={rentalId}
          close={() => setIsCreateRentalAgreementHistoryModalOpen(false)}
          isOpen={isCreateRentalAgreementHistoryModalOpen}
        />
      )}
      {canEditRentalAgreements && rentalAgreementHistoryToEdit && (
        <EditRentalAgreementHistoryModal
          rentalAgreementHistory={rentalAgreementHistoryToEdit}
          close={() => {
            setIsEditRentalAgreementHistoryModalOpen(false)
            setRentalAgreementHistoryToEdit(null)
          }}
          isOpen={isEditRentalAgreementHistoryModalOpen}
        />
      )}
    </ContentSectionCard>
  )
}
