import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { capabilities } from '@homevest/utils'
import { Spinner } from '@chakra-ui/react'

import axios from 'lib/axios'
import { StoreState, TypedDispatch } from 'store'
import { ActionButton } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import { hasCapability } from 'lib/admin-perms'
import {
  setErrorMessage,
  setErrorModalIsOpen,
  setSuccessMessage,
  setSuccessModalIsOpen
} from 'state/modals/actions'

const { CAPABILITY_TYPES } = capabilities

type CreateAccountComponentProps = {
  refreshFirebaseAccount: () => Promise<void>
  setErrorMessage: (message: string) => void
  setErrorModalIsOpen: (mode: boolean) => void
  setSuccessMessage: (message: string) => void
  setSuccessModalIsOpen: (mode: boolean) => void
}

const CreateAccount: React.FC<React.PropsWithChildren<CreateAccountComponentProps>> = ({
  refreshFirebaseAccount,
  setSuccessModalIsOpen,
  setSuccessMessage,
  setErrorModalIsOpen,
  setErrorMessage
}) => {
  const { userId } = useParams<{ userId: string }>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const admin = useSelector((store: StoreState) => store.admin)
  const canCreateAccount = hasCapability(admin, CAPABILITY_TYPES.USER_ACCOUNT_CREATOR)

  if (!canCreateAccount) {
    return null
  }

  const createFirebaseAccount = async () => {
    try {
      setIsLoading(true)
      await axios.post(`/admin/users/${userId}/create_firebase_account`)
      setIsModalOpen(false)
      setIsLoading(false)
      setSuccessMessage('Account succesfully created!')
      setSuccessModalIsOpen(true)
      await refreshFirebaseAccount()
    } catch (err: any) {
      setIsModalOpen(false)
      setIsLoading(false)

      setErrorMessage(err.message)
      setErrorModalIsOpen(true)
    }
  }

  return (
    <>
      <SC.DropDownMenuItem onClick={() => setIsModalOpen(true)}>Create Account</SC.DropDownMenuItem>
      <SC.Modal isOpen={isModalOpen}>
        Create Account
        <SC.ModalSubText className='pb-3 text-left'>
          <p className='pb-3'>
            This user does not currently have an account linked to their profile.
          </p>
          <p>
            Click below to create an account for them with a temporary password and send them a
            password reset link.
          </p>
        </SC.ModalSubText>
        {isLoading ? (
          <div className='justify-center'>
            <Spinner />
          </div>
        ) : (
          <div className='flex place-content-between'>
            <>
              <ActionButton onClick={createFirebaseAccount} colorScheme='secondary'>
                Create Account
              </ActionButton>
              <ActionButton onClick={() => setIsModalOpen(false)}>Cancel</ActionButton>
            </>
          </div>
        )}
      </SC.Modal>
    </>
  )
}

const mapDispatchToProps = (dispatch: TypedDispatch) => ({
  setErrorMessage: (message: string) => dispatch(setErrorMessage(message)),
  setErrorModalIsOpen: (mode: boolean) => dispatch(setErrorModalIsOpen(mode)),
  setSuccessMessage: (message: string) => dispatch(setSuccessMessage(message)),
  setSuccessModalIsOpen: (mode: boolean) => dispatch(setSuccessModalIsOpen(mode))
})

export default connect(null, mapDispatchToProps)(CreateAccount)
