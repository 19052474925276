import styled, { css } from 'styled-components'

export const TimelineItemsContainer = styled.div`
  width: 100%;
  padding: 0px;
`

export const ItemTitle = styled.h3``

export const StyledFieldContainer = styled.div`
  padding: 0;
`

export const PropertyTitle = styled.span`
  font-weight: 600;
`

export const PropertyLine = styled.p`
  margin-bottom: 0px;
`

const sharedItemCSS = css`
  border-bottom: 1px solid #dbd9d8;
  width: 100%;
  padding: 20px;
  margin: 0px;
  height: auto;
`

export const StyledClosedItem = styled.div`
  ${sharedItemCSS}
  &:hover {
    background-color: #f1f1f1;
  }
`

export const StyledOpenItem = styled.div`
  ${sharedItemCSS}
  background-color: #f1f1f1;
`
export const StyledPre = styled.pre`
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -pre-wrap;
  word-wrap: break-word;
`

export const NestedDetails = styled.div`
  padding-left: 20px;
  height: fit-content;
  width: 100%;
`
