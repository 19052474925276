import React from 'react'
import { startCase } from 'lodash'
import { FormControl, FormErrorMessage, FormLabel, Select, SelectProps } from '@chakra-ui/react'

import { useSourceDetails } from '../../context'
import { SourceDetailsFieldName } from 'lib/user-incomes/types'

interface SelectFormFieldProps {
  label: string
  fieldName: SourceDetailsFieldName
  optionValues: string[]
  selectProps?: SelectProps
}

const SelectFormField = ({
  label,
  fieldName,
  optionValues,
  selectProps = {}
}: SelectFormFieldProps) => {
  const context = useSourceDetails()

  if (!context) {
    return null
  }

  const { data, updateField, errors } = context

  const fieldValue = (data as any)[fieldName]
  const errorMessage = (errors as any)[fieldName]

  return (
    <FormControl isRequired isInvalid={!!errorMessage}>
      <FormLabel htmlFor='fieldName'>{label}</FormLabel>
      <Select
        {...selectProps}
        id={fieldName}
        value={fieldValue || ''}
        onChange={(e) => updateField(fieldName, e.target.value)}
      >
        <option value='' disabled>
          Select...
        </option>
        {optionValues.map((option) => (
          <option key={option} value={option}>
            {startCase(option)}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default SelectFormField
