import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Upup_LiabilityTypesQuery } from 'graphql/generated'
import { Dropdown } from 'components/Toolkit'
import { sortBy } from 'lodash'
import { RentalDetailsContext } from '../RentalDetailsContext'

LiabilityTypesDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  filter: PropTypes.func
}

export default function LiabilityTypesDropdown({
  disabled,
  onSelect,
  value,
  filter
}: {
  disabled: boolean
  onSelect: any
  value: any
  filter?: (lt: Upup_LiabilityTypesQuery['liability_types'][number]) => boolean
}) {
  let { liabilityTypes } = useContext(RentalDetailsContext)

  if (filter) {
    liabilityTypes = liabilityTypes.filter(filter)
  }

  const liabilityTypeOptions = sortBy(
    liabilityTypes.map((lt) => ({
      label: lt.name,
      value: lt.id
    })),
    'label'
  )

  const onSelectLiabilityType = (id: string) => {
    onSelect(liabilityTypes.find((x) => x.id === id))
  }

  const selectedValue = typeof value === 'string' ? value : value?.id
  return (
    <Dropdown
      disabled={disabled}
      label='Liability Type'
      placeholder='Select a liability type'
      onChange={onSelectLiabilityType}
      options={liabilityTypeOptions}
      value={selectedValue || ''}
    />
  )
}
