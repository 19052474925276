import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { Link } from '@chakra-ui/react'
import { Ticket } from '@homevest/types/tickets'
import ExpandableCellRenderer from 'components/AgGrid/ExpandableCell'
import SetFilter from 'components/AgGrid/filters/SetFilter'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { LINK_COLOR } from 'constants/colors'
import { formatMoney } from 'lib/numbers'
import { formatUTCDate } from 'lib/date-time'
import { startCase } from 'lodash'
import { formatTicketTypeForDisplay, ticketStatusComparator } from 'lib/tickets'

const renderBadge = (
  params: ICellRendererParams<Ticket, string>,
  formatFn: (val: string) => string = startCase
) => mapContentToBadge(params.value, { dot: true, size: 'xs', formatFn })

export const TICKETS_GRID_COLUMN_IDS = {
  TITLE: 'title',
  TYPE: 'type',
  CATEGORY: 'category',
  STATUS: 'status',
  ADDRESS: 'address',
  VENDOR: 'vendor',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  DESCRIPTION: 'description',
  COST: 'cost',
  ACTIONS: 'actions'
} as const

const TITLE: ColDef<Ticket> = {
  colId: TICKETS_GRID_COLUMN_IDS.TITLE,
  headerName: 'Title',
  field: 'title',
  cellRenderer: (params: ICellRendererParams<Ticket, string>) => (
    <Link
      isExternal
      href={`https://app.latchel.com/admin/work-order/${params.data?.external_id}`}
      color={LINK_COLOR}
    >
      {params.value}
    </Link>
  ),
  resizable: true,
  flex: 1
}

const TYPE: ColDef<Ticket> = {
  colId: TICKETS_GRID_COLUMN_IDS.TYPE,
  headerName: 'Type',
  field: 'type',
  resizable: true,
  filter: SetFilter,
  cellRenderer: (params: ICellRendererParams<Ticket, string>) =>
    renderBadge(params, formatTicketTypeForDisplay),
  flex: 1
}

const CATEGORY: ColDef<Ticket> = {
  colId: TICKETS_GRID_COLUMN_IDS.CATEGORY,
  headerName: 'Category',
  field: 'category',
  resizable: true,
  filter: SetFilter,
  cellRenderer: (params: ICellRendererParams<Ticket, string>) => renderBadge(params),
  flex: 1
}

const STATUS: ColDef<Ticket> = {
  colId: TICKETS_GRID_COLUMN_IDS.STATUS,
  headerName: 'Status',
  field: 'status',
  filter: SetFilter,
  resizable: true,
  cellRenderer: (params: ICellRendererParams<Ticket, string>) => renderBadge(params),
  flex: 1,
  comparator: (statusA, statusB) => ticketStatusComparator(statusA, statusB),
  sort: 'asc',
  sortIndex: 0
}

const ADDRESS: ColDef<Ticket> = {
  colId: TICKETS_GRID_COLUMN_IDS.ADDRESS,
  headerName: 'Address',
  field: 'display_line_1',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<Ticket, string>) => (
    <Link
      as={ReactRouterLink}
      to={`/home/${params.data?.portfolio_home_id}`}
      isExternal
      color={LINK_COLOR}
    >
      {params.value}
    </Link>
  ),
  flex: 1
}

const VENDOR: ColDef<Ticket> = {
  colId: TICKETS_GRID_COLUMN_IDS.VENDOR,
  headerName: 'Vendor',
  field: 'vendor_name',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<Ticket, string>) => (
    <Link
      as={ReactRouterLink}
      to={`/construction/vendors/${params.data?.vendor_id}`}
      isExternal
      color={LINK_COLOR}
    >
      {params.value}
    </Link>
  ),
  flex: 1
}

const START_DATE: ColDef<Ticket> = {
  colId: TICKETS_GRID_COLUMN_IDS.START_DATE,
  headerName: 'Start Date',
  resizable: true,
  valueGetter: (params) => params.data?.actual_start_date ?? params.data?.current_start_date,
  valueFormatter: (params) => {
    if (!params.data?.actual_start_date && !params.data?.current_start_date) {
      return ''
    }
    return formatUTCDate(params.value) + (!params.data?.actual_start_date ? ' (est.)' : '')
  },
  flex: 1
}

const END_DATE: ColDef<Ticket> = {
  colId: TICKETS_GRID_COLUMN_IDS.END_DATE,
  headerName: 'End Date',
  resizable: true,
  valueGetter: (params) => params.data?.actual_end_date ?? params.data?.estimated_end_date,
  valueFormatter: (params) => {
    if (!params.data?.actual_end_date && !params.data?.estimated_end_date) {
      return ''
    }
    return formatUTCDate(params.value) + (!params.data?.actual_end_date ? ' (est.)' : '')
  },
  flex: 1
}

const COST: ColDef<Ticket> = {
  colId: TICKETS_GRID_COLUMN_IDS.COST,
  headerName: 'Cost',
  resizable: true,
  valueGetter: (params) => params.data?.actual_cost ?? params.data?.estimated_cost,
  valueFormatter: (params) => {
    if (!params.data?.actual_cost && !params.data?.estimated_cost) {
      return ''
    }
    const isEstimate = !params.data?.actual_cost
    return formatMoney(params.value, 2, '$') + (isEstimate ? ' (est.)' : '')
  },
  flex: 1
}

const DESCRIPTION: ColDef<Ticket> = {
  colId: TICKETS_GRID_COLUMN_IDS.DESCRIPTION,
  headerName: 'Description',
  field: 'description',
  tooltipField: 'description',
  flex: 2,
  resizable: true,
  cellRenderer: ExpandableCellRenderer,
  autoHeight: true,
  wrapText: true
}

export const TICKETS_GRID_COLUMNS = [
  TITLE,
  ADDRESS,
  TYPE,
  CATEGORY,
  STATUS,
  VENDOR,
  START_DATE,
  END_DATE,
  COST,
  DESCRIPTION
]
