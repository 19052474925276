import React, { useState, useEffect, useCallback } from 'react'
import { GridReadyEvent, ColDef, GridApi } from 'ag-grid-community'

import BaseGrid from 'components/TailwindUIToolkit/Grid/BaseGrid'
import { RentalSiteChecksData, RentalSiteChecksDataType } from 'types/RentalSiteChecker'

interface RentalSiteChecksGridProps {
  rowData: RentalSiteChecksData
  onGridReady: (evt: GridReadyEvent<RentalSiteChecksDataType>) => void
  columns: ColDef<RentalSiteChecksDataType>[]
  isInitialGrid: boolean
}

const Grid: React.FC<React.PropsWithChildren<RentalSiteChecksGridProps>> = ({
  rowData,
  columns,
  onGridReady,
  isInitialGrid
}) => {
  const [gridApi, setGridApi] = useState<GridApi<RentalSiteChecksDataType>>()
  const gridSetup = (evt: GridReadyEvent<RentalSiteChecksDataType>) => {
    setGridApi(evt.api)
    onGridReady(evt)
  }

  const onFirstDataRendered = useCallback(() => {
    isInitialGrid && gridApi?.sizeColumnsToFit()
  }, [isInitialGrid, gridApi])

  useEffect(() => {
    if (gridApi && rowData) {
      try {
        gridApi.setRowData(rowData)
        gridApi.sizeColumnsToFit()
      } catch {
        console.log('Ran into an error drawing the grid, you may need to refresh the page')
      }
    }
  }, [gridApi, rowData])

  return (
    <div className='ag-theme-material' style={{ height: '74vh' }}>
      <BaseGrid<RentalSiteChecksDataType>
        optionOverrides={{
          defaultColDef: {
            width: undefined
          }
        }}
        columns={columns}
        rowData={rowData}
        onGridReady={gridSetup}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  )
}

export default Grid
