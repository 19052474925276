"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRIORITIES = exports.CATEGORIES = void 0;
exports.CATEGORIES = {
    ACCESS: 'access',
    COLLECTIONS: 'collections',
    DOCUMENT_UPLOAD: 'document_upload',
    EARLY_TERM: 'early_term',
    EVICTIONS: 'evictions',
    HOA: 'hoa',
    HOME_PURCHASE: 'home_purchase',
    INSURANCE: 'insurance',
    LEASING: 'leasing',
    LEASING_COMMUNICATION: 'leasing_communication',
    HOME_MATCHING: 'home_matching',
    MOVE_IN: 'move_in',
    MOVE_OUT: 'move_out',
    PROJECT: 'project_notes',
    RENEWALS: 'renewals',
    RENTAL_APPLICATION: 'rental_application',
    RESOLUTION: 'resolution',
    UTILITIES: 'utilities',
    QUALIFICATION: 'qualification',
    WORK_ORDER: 'work_order'
};
exports.PRIORITIES = {
    DEFAULT: 'default',
    PINNED: 'pinned'
};
