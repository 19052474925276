import React, { FunctionComponent } from 'react'
import { Box, Button } from '@chakra-ui/react'

const TabLinkWrapper: FunctionComponent<React.PropsWithChildren<{ active?: boolean }>> = ({
  active,
  children
}) => (
  <Button
    as={Box}
    variant='ghost'
    p={0}
    h='full'
    rounded='none'
    borderBottomWidth={2}
    borderBottomColor={active ? 'brand.600' : 'transparent'}
    fontSize='sm'
    color={active ? 'grey.900' : 'gray.500'}
  >
    {children}
  </Button>
)

export default TabLinkWrapper
