import React from 'react'
import { useSelector } from 'react-redux'
import isBefore from 'date-fns/isBefore'
import add from 'date-fns/add'
import capitalize from 'lodash/capitalize'
import {
  Radio,
  Text,
  RadioGroup,
  Stack,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Modal,
  Button,
  Select,
  NumberInput,
  NumberInputField,
  Input,
  Box
} from '@chakra-ui/react'
import {
  Rental_Application_Manager_Snooze_Reasons_Enum,
  useSnoozeReasonsQuery,
  useSnoozeRentalApplicationMutation
} from 'graphql/generated'

enum TimeExpirationMode {
  amountOfTime,
  targetTime
}

const RADIO_OPTIONS = Object.freeze([
  {
    label: 'For a period time (days, hours, min)',
    value: TimeExpirationMode.amountOfTime
  },
  {
    label: 'Until a specific date and time',
    value: TimeExpirationMode.targetTime
  }
])

const AmountForm = React.memo<{
  setTargetDate: (date: Date) => void
  setSnoozeReason: Function
}>(({ setTargetDate, setSnoozeReason }) => {
  const [days, setDays] = React.useState(0)
  const [hours, setHours] = React.useState(0)
  const [minutes, setMinutes] = React.useState(0)

  React.useEffect(() => {
    setTargetDate(add(new Date(), { days, hours, minutes }))
  }, [days, hours, minutes, setTargetDate])

  return (
    <>
      <Text mt={'10'} textColor={'gray.600'}>
        Days
      </Text>
      <NumberInput min={0} onChange={(value) => setDays(parseInt(value))}>
        <NumberInputField />
      </NumberInput>
      <Text mt={'1'} textColor={'gray.600'}>
        Hours
      </Text>
      <NumberInput min={0} onChange={(value) => setHours(parseInt(value))}>
        <NumberInputField />
      </NumberInput>
      <Text mt={'1'} textColor={'gray.600'}>
        Minutes
      </Text>
      <NumberInput min={0} onChange={(value) => setMinutes(parseInt(value))}>
        <NumberInputField />
      </NumberInput>
      <SnoozeReasonInput setSnoozeReason={setSnoozeReason} />
    </>
  )
})

const TargetForm = React.memo<{
  setTargetDate: (date: Date) => void
  setSnoozeReason: Function
}>(({ setTargetDate, setSnoozeReason }) => (
  <>
    <Text mt={'1'} textColor={'gray.600'}>
      Snooze date
    </Text>
    <Input
      onChange={(event) => {
        setTargetDate(new Date(event.target.value))
      }}
      type='datetime-local'
    />
    <SnoozeReasonInput setSnoozeReason={setSnoozeReason} />
  </>
))

const SnoozeReasonInput = React.memo<{ setSnoozeReason: Function }>(({ setSnoozeReason }) => {
  const [{ data: snoozeReasons }] = useSnoozeReasonsQuery()

  return (
    <>
      <Text mt={'1'} textColor={'gray.600'}>
        Snooze reason
      </Text>
      <Select
        onChange={(event) => {
          setSnoozeReason(event.target.value)
        }}
      >
        <option key={'sa_empty_radio'} value={undefined}>
          Select...
        </option>
        {snoozeReasons &&
          snoozeReasons.rental_application_manager_snooze_reasons.map(({ snooze_reason }) => (
            <option key={snooze_reason} value={snooze_reason}>
              {capitalize(snooze_reason.replaceAll('_', ' '))}
            </option>
          ))}
      </Select>
    </>
  )
})

export const SnoozeApplicationModal: React.FC<
  React.PropsWithChildren<{
    rentalApplicationId: string
    isOpen: boolean
    setOpen: (closed: boolean) => void
  }>
> = ({ rentalApplicationId, isOpen, setOpen }) => {
  const [_, snoozeRentalApplication] = useSnoozeRentalApplicationMutation()
  const [mode, setMode] = React.useState(TimeExpirationMode.amountOfTime)
  const [targetDate, setTargetDate] = React.useState<Date | null>(null)
  const [snoozeReason, setSnoozeReason] = React.useState<
    Rental_Application_Manager_Snooze_Reasons_Enum | undefined
  >()

  const adminId = useSelector((state: any) => state.admin.id)

  return (
    <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={() => setOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Snooze Application</Text>
          <Text fontSize={'sm'} color={'gray.400'} borderBottom={'1px'} borderColor={'gray.300'}>
            Adds a temporary snooze on this application
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box borderBottom={'1px'} borderColor={'gray.300'} pb={'5'}>
            <Text fontSize={'md'} color={'gray.600'} mb={'3'}>
              How would you like to snooze this customer?
            </Text>
            <RadioGroup
              onChange={(newMode) => {
                if (parseInt(newMode) !== mode) {
                  setMode(parseInt(newMode))
                  setTargetDate(null)
                  setSnoozeReason(undefined)
                }
              }}
              value={mode as any}
              textColor={'gray.600'}
            >
              <Stack direction='column'>
                {RADIO_OPTIONS.map((ro) => (
                  <Radio colorScheme={'purple'} key={'sam_' + ro.value} value={ro.value as any}>
                    {ro.label}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </Box>
          {mode === TimeExpirationMode.amountOfTime ? (
            <AmountForm setTargetDate={setTargetDate} setSnoozeReason={setSnoozeReason} />
          ) : mode === TimeExpirationMode.targetTime ? (
            <TargetForm setTargetDate={setTargetDate} setSnoozeReason={setSnoozeReason} />
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Box mt={'10'} borderTop={'1px'} borderColor={'gray.300'} w={'full'}>
            <Box float={'right'} p={'2'}>
              <Stack direction={'row'}>
                <Button
                  onClick={() => {
                    setOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme='purple'
                  disabled={targetDate === null || snoozeReason === undefined}
                  onClick={() => {
                    if (isBefore(targetDate!, new Date())) {
                      alert('Need to select a time in the future!')
                    } else {
                      snoozeRentalApplication({
                        rental_application_id: rentalApplicationId,
                        snoozed_until: targetDate,
                        snooze_reason: snoozeReason!,
                        snoozed_by_admin_id: adminId
                      })
                      setOpen(false)
                    }
                  }}
                >
                  Snooze
                </Button>
              </Stack>
            </Box>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
