import React from 'react'
import startCase from 'lodash/startCase'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Alert, Heading, Spinner } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import type { ConstructionProjectApiResponse } from '@homevest/types/construction-projects'

import { TwoColumn } from 'components/TailwindUIToolkit'
import { DynamicWidthPageContainer } from 'components/TailwindUIToolkit/Layouts/DynamicWidthPageContainer'
import { LINK_COLOR } from 'constants/colors'
import axios from 'lib/axios'
import ProjectDetailsSidebar from './Sidebar'
import ProjectDetailsContent from './Content'
import { mapProjectTypeToLabel } from 'lib/construction-projects'

const ProjectDetails = () => {
  const { constructionProjectId } = useParams<{ constructionProjectId: string }>()
  const {
    data: project,
    error,
    isLoading,
    isFetching,
    isError,
    refetch: refetchProjectData
  } = useQuery<ConstructionProjectApiResponse, Error>({
    queryKey: ['getConstructionProjectById'],
    queryFn: async () => {
      const { data } = await axios.get(`/admin/construction_projects/${constructionProjectId}`)
      return data
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  if (isLoading || isFetching) {
    return <Spinner size='16px' />
  }

  if (isError && error) {
    return <Alert status='error'>{error.message}</Alert>
  }

  const displayType = mapProjectTypeToLabel(project.type)
  const displayAddress = startCase(project.address)
  const displayTitle = `${displayType} | ${displayAddress}`
  const displayHeading = `${displayType} for ${displayAddress}`

  return (
    <>
      <Helmet>
        <title>{displayTitle}</title>
      </Helmet>
      <DynamicWidthPageContainer
        pathToPage={[
          { href: '/construction/projects', name: 'Construction Projects' },
          { href: '#', name: displayHeading }
        ]}
        maxWidth='max-w-full'
      >
        <Heading as='h3' size='xl' mb={6}>
          {displayType} for{' '}
          <span style={{ color: LINK_COLOR }}>
            <Link
              to={`/home/${project.portfolio_home_id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {displayAddress}
            </Link>
          </span>
        </Heading>
        <TwoColumn
          sidebarMaxWidth='md'
          sidebarSide='left'
          sidebarContent={<ProjectDetailsSidebar project={project} refetch={refetchProjectData} />}
          mainContent={<ProjectDetailsContent project={project} />}
          useRowsOnSmallScreens
        />
      </DynamicWidthPageContainer>
    </>
  )
}

export default ProjectDetails
