import React, { useState } from 'react'
import startCase from 'lodash/startCase'
import ReactSelect from 'react-select'
import { Text, HStack, Link, Button, Select, useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { markets } from '@homevest/utils'

import { useMarketsFromNamesQuery, useVendorTypesQuery } from 'graphql/generated'
import { GrayBadge } from 'components/TailwindUIToolkit/StyledComponents'
import {
  ContentSectionCard,
  LeftAlignedDataList,
  StarRatingDisplay
} from 'components/TailwindUIToolkit'
import { formatPhoneFromDatabase } from 'lib/formatting'
import { Vendor, VendorDetailsEditState, updateVendorDetails, formatVendorTypes } from 'lib/vendors'

const { OPERATIONAL_MARKET_NAMES } = markets

const VendorDetails = ({ vendor, onUpdate }: { vendor: Vendor; onUpdate: () => void }) => {
  const [editing, setEditing] = useState(false)
  const [editState, setEditState] = useState<VendorDetailsEditState>({
    types: vendor.vendors_vendor_types.map(({ vendor_type }) => ({
      value: vendor_type.id,
      label: vendor_type.display_name
    })),
    markets: vendor.vendor_markets.map(({ market }) => ({
      value: market.id,
      label: market.display_name
    })),
    rating: vendor.internal_rating || null
  })

  const toast = useToast()

  const [{ data: allVendorTypes, fetching: fetchingVendorTypes }] = useVendorTypesQuery()
  const [{ data: allActiveMarkets, fetching: fetchingMarkets }] = useMarketsFromNamesQuery({
    variables: { marketNames: OPERATIONAL_MARKET_NAMES }
  })

  const { mutate: saveVendorDetails, isLoading: savingVendorDetails } = useMutation({
    mutationFn: async () => {
      return updateVendorDetails(vendor.id, editState)
    },
    onSuccess: () => {
      toast({
        title: 'Vendor details updated!',
        description: 'Vendor details have been successfully updated',
        status: 'success',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true
      })
      onUpdate()
      setEditing(false)
    },
    onError: (error: any) => {
      console.log(error)
      toast({
        title: 'Error updating vendor details',
        description: 'An error occurred while saving vendor details: ' + error.message || '',
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true
      })
    }
  })
  const fetching = fetchingMarkets || fetchingVendorTypes || savingVendorDetails

  const typeDisplay = vendor.vendors_vendor_types.length ? (
    <Text>{formatVendorTypes(vendor)}</Text>
  ) : (
    '-'
  )
  const typeEdit = (
    <ReactSelect
      isSearchable
      isClearable
      isMulti
      defaultValue={editState.types}
      onChange={(value) => {
        setEditState({ ...editState, types: [...value] ?? [] })
      }}
      options={(allVendorTypes?.vendor_types ?? []).map((vendorType) => ({
        label: vendorType.display_name,
        value: vendorType.id
      }))}
    />
  )

  const marketBadges = (
    <HStack flexWrap='wrap' lineHeight='8'>
      {(vendor.vendor_markets || [])
        .filter(({ market }) => OPERATIONAL_MARKET_NAMES.includes(market.name as any))
        .map(({ market }) => (
          <div key={market.id}>
            <GrayBadge>{market.display_name}</GrayBadge>
          </div>
        ))}
    </HStack>
  )

  const marketsDisplay = marketBadges || '-'
  const marketsEdit = (
    <ReactSelect
      isSearchable
      isClearable
      isMulti
      defaultValue={editState.markets}
      onChange={(value) => {
        setEditState({ ...editState, markets: [...value] ?? [] })
      }}
      options={(allActiveMarkets?.markets ?? []).map((market) => ({
        label: market.display_name,
        value: market.id
      }))}
    />
  )

  const phoneDisplay = vendor.phone ? (
    <Link href={`tel:${vendor.phone}`}>{formatPhoneFromDatabase(vendor.phone)}</Link>
  ) : (
    '-'
  )

  const emailDisplay = vendor.email ? (
    <Link href={`mailto:${vendor.email}`}>{vendor.email}</Link>
  ) : (
    '-'
  )

  const buildiumIdDisplay = vendor.external_id ? (
    <Link
      href={`https://upandup.managebuilding.com/manager/app/maintenance/vendors/${vendor.external_id}/summary`}
      isExternal
    >
      <HStack>
        <Text>{vendor.external_id}</Text>
        <ArrowTopRightOnSquareIcon className='h-4 w-4' />
      </HStack>
    </Link>
  ) : (
    '-'
  )

  const ratingDisplay = <StarRatingDisplay rating={vendor.internal_rating || 0} />
  const ratingEdit = (
    <Select
      isDisabled={fetching}
      value={editState.rating ?? ''}
      onChange={(e) =>
        setEditState((prev) => ({
          ...prev,
          rating: e.target.value ? Number(e.target.value) : null
        }))
      }
    >
      <option value={''}>No rating</option>
      {[1, 2, 3, 4, 5].map((rating) => (
        <option key={rating} value={rating}>
          {rating}
        </option>
      ))}
    </Select>
  )

  const handleEditOn = () => {
    setEditState({
      types: vendor.vendors_vendor_types.map(({ vendor_type }) => ({
        value: vendor_type.id,
        label: vendor_type.display_name
      })),
      markets: vendor.vendor_markets.map(({ market }) => ({
        value: market.id,
        label: market.display_name
      })),
      rating: vendor.internal_rating || null
    })
    setEditing(true)
  }

  const handleEditOff = () => {
    setEditing(false)
  }

  const editButton = (
    <Button
      colorScheme={editing ? 'purple' : 'gray'}
      variant={editing ? 'solid' : 'outline'}
      onClick={editing ? async () => saveVendorDetails() : handleEditOn}
    >
      {editing ? 'Save' : 'Edit'}
    </Button>
  )

  const actions = (
    <HStack>
      {editing && (
        <Button variant='outline' onClick={handleEditOff}>
          Cancel
        </Button>
      )}
      {editButton}
    </HStack>
  )

  return (
    <ContentSectionCard title='Details' padding collapsable={false} action={actions}>
      <LeftAlignedDataList
        data={[
          { label: 'Phone', value: phoneDisplay },
          { label: 'Email', value: emailDisplay },
          { label: 'Buildium ID', value: buildiumIdDisplay, labelFormatter: startCase },
          { label: 'Type', value: editing ? typeEdit : typeDisplay },
          { label: 'Rating', value: editing ? ratingEdit : ratingDisplay },
          { label: 'Markets', value: editing ? marketsEdit : marketsDisplay }
        ]}
      />
    </ContentSectionCard>
  )
}

export default VendorDetails
