import React, { useState } from 'react'
import { Button, Heading, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { GridApi, GridReadyEvent } from 'ag-grid-community'
import { Helmet } from 'react-helmet'
import {
  ArrowUpTrayIcon,
  ArrowDownTrayIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline'
import { Grid } from './Grid'
import { InfoTooltip } from 'ui/InfoTooltip'

const tooltipText = (
  <Stack padding={2} gap={4}>
    <Text>This page allows you to manage unfinalized utility chargebacks. </Text>
    <Text>You can approve, reject, or defer chargebacks.</Text>
    <Text>
      No changes are saved until you save changes using the "Submit" button, and only chargebacks
      with selected changes will be finalized when the "Finalized" is checked.
    </Text>
    <Text>
      Once a utility charge is finalized, if it is accepted, a rental liability charge will be
      created in the corresponding rental's ledger.
    </Text>
    <Text>Deferred charges can be viewed on the "Utilities - Deferred Chargebacks" page.</Text>
    <Text>
      Double click on any chargeback&apos;s address to open rent roll for the corresponding rental.
    </Text>
    <Text>
      Since there are likely to be over 1000 utility charges per month, the most effective way to
      use this tool is probably to find all the flagged chargebacks, and triage them into rejected,
      approved, or deferred statuses, submit and finalize those, and then use the "Accept All"
      button to finalize the rest after a quick review.
    </Text>
  </Stack>
)

const UtilitiesChargebacksManager = () => {
  const history = useHistory()

  const [gridApi, setGridApi] = useState<GridApi>()
  const onGridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api)
  }

  const handleCsvExport = () => {
    const currentTimestamp = new Date().getTime()
    gridApi?.exportDataAsCsv({
      allColumns: true,
      fileName: `chargebacks_${currentTimestamp}.csv`
    })
  }

  return (
    <>
      <Helmet>
        <title>Utilities | Chargebacks Manager</title>
      </Helmet>
      <Stack padding={4}>
        <Stack>
          <HStack justify='space-between'>
            <HStack verticalAlign='baseline'>
              <Heading>Utilities - Chargebacks Manager</Heading>
              <InfoTooltip label={tooltipText} />
            </HStack>
            <HStack>
              <Button
                aria-label='Upload CSV'
                leftIcon={<ArrowUpTrayIcon className={'h-4 w-4'} />}
                variant='outline'
                size='lg'
                onClick={() => history.push('/utilities/upload')}
              >
                Upload CSV
              </Button>
              <Button
                aria-label='Deferred Chargebacks'
                leftIcon={<ExclamationTriangleIcon className={'h-4 w-4'} />}
                variant='outline'
                size='lg'
                onClick={() => history.push('/utilities/deferred')}
              >
                Deferred Chargebacks
              </Button>
              <Button
                aria-label='Export'
                leftIcon={<ArrowDownTrayIcon className={'h-4 w-4'} />}
                variant='outline'
                size='lg'
                onClick={handleCsvExport}
              >
                Export
              </Button>
            </HStack>
          </HStack>
          <Spacer />
          <Grid onGridReady={onGridReady} />
        </Stack>
      </Stack>
    </>
  )
}

export default UtilitiesChargebacksManager
