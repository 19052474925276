import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import XThinIcon from 'components/Toolkit/Icons/XThin'

const StyledTag = styled.div`
  display: inline-block;
  border-radius: 100px;
  background: rgba(48, 38, 84, 0.1);
  padding: 5px 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: ${COLORS.PRIMARY.PLUM};
  margin-right: 12px;
  white-space: nowrap;
  margin-bottom: 10px;
`

const StyledClose = styled.div`
  margin-left: 10px;
  cursor: pointer;
`

const StyledContentContainer = styled.span`
  display: flex;
  align-items: center;
`

function Tag({ label, onClose }) {
  return (
    <StyledTag>
      <StyledContentContainer>
        {label}
        {onClose ? (
          <StyledClose onClick={onClose}>
            <XThinIcon />
          </StyledClose>
        ) : null}
      </StyledContentContainer>
    </StyledTag>
  )
}

Tag.propTypes = {
  label: PropTypes.string,
  onClose: PropTypes.func
}

export default memo(Tag)
