import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Textarea,
  Tooltip
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Upup_LiabilityTypesQuery as LiabilityTypesQuery } from 'graphql/generated'
import moment from 'moment'
import LiabilityTypesDropdown from '../Dropdowns/LiabilityTypesDropdown'
import { TrashIcon } from 'components/TailwindUIToolkit/StyledComponents'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
const { REACT_APP_CC_FEE_LIABILITY_TYPE_ID } = process.env

type LiabilityType = LiabilityTypesQuery['liability_types'][number]
/**
 * Row of a larger form for editing a new rental liability to insert.
 * Has inputs for liability type, amount, date and note. Validates the inputs.
 * On edit, calls the callback to the parent component to update the overall list of new liabilities.
 */
export default function LiabilityInputRow({
  date: defaultDate = moment().format('YYYY-MM-DD'),
  amount: defaultAmount = 0,
  note: defaultNote = '',
  liability_type_id: defaultLiabilityTypeId,
  onSubmit,
  onDelete,
  index,
  canEdit,
  isDateValid,
  finalLiabilityDate,
  noteRequired
}: {
  date?: string
  canEdit: boolean
  index: number
  amount?: number
  note?: string | null
  liability_type_id?: string | null
  onSubmit?: (obj: {
    amount: number
    liability_type_id: string
    date: string
    paymentPlanMonths?: number
    note: string | null
  }) => void
  isDateValid: (date: string) => boolean
  onDelete: (ind: number) => void
  finalLiabilityDate?: string
  noteRequired: boolean
}) {
  // manage state variables for price, date, note, and liabilityType
  const [amount, setAmount] = useState<string>(defaultAmount ? defaultAmount.toFixed(2) : '')
  const [date, setDate] = useState(defaultDate)
  const [paymentPlanMonths, setPaymentPlanMonths] = useState<number>()
  const [note, setNote] = useState(defaultNote)
  const [liabilityType, setLiabilityType] = useState<LiabilityType>()

  const [isPaymentPlan, setIsPaymentPlan] = useState(false)

  const isPaymentPlanMonthsValid =
    !finalLiabilityDate ||
    !paymentPlanMonths ||
    (!!paymentPlanMonths &&
      !moment(date).add(paymentPlanMonths, 'months').isAfter(finalLiabilityDate))
  const isValid =
    date &&
    moment(date).isValid() &&
    amount &&
    liabilityType &&
    isPaymentPlanMonthsValid &&
    (!noteRequired || (note && note.trim()))

  return (
    <Stack>
      {canEdit && (
        <FormControl>
          <HStack>
            <FormLabel>
              Would you like to create a payment plan?
              <Tooltip
                label='Payment plans are charges that repeat on the same day of every month until the total amount is paid.
               The total is split evenly across the number of months selected. Only some liability types are eligible.'
              >
                <Icon>
                  <QuestionMarkCircleIcon />
                </Icon>
              </Tooltip>
            </FormLabel>
            <Checkbox
              checked={isPaymentPlan}
              onChange={(e) => {
                setIsPaymentPlan(e.target.checked)
                // if the user unchecks the box, clear the end date
                if (!e.target.checked) {
                  setPaymentPlanMonths(undefined)
                }
              }}
            />
          </HStack>
        </FormControl>
      )}
      <HStack>
        <FormControl isRequired isDisabled={!canEdit}>
          <LiabilityTypesDropdown
            filter={(lType) =>
              lType.id !== REACT_APP_CC_FEE_LIABILITY_TYPE_ID &&
              (!isPaymentPlan || !lType.recurring)
            }
            onSelect={(lType) => {
              setLiabilityType(lType)
              if (lType.price) {
                setAmount(`${lType.price}`)
              }
            }}
            value={defaultLiabilityTypeId ?? liabilityType}
            disabled={false}
          />
        </FormControl>
        <FormControl isRequired isDisabled={!canEdit || !liabilityType?.is_variable}>
          <FormLabel>{isPaymentPlan ? 'Total Amount' : 'Amount'}</FormLabel>
          <NumberInput
            value={amount}
            onChange={(val) => setAmount(val)}
            precision={2}
            format={(val) => `$${val}`}
            isInvalid={!amount || parseFloat(amount?.replace(/^\$/, '') || '0') <= 0}
            step={0.01}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
        <FormControl isRequired isDisabled={!canEdit}>
          <FormLabel>{isPaymentPlan ? 'Start Date' : 'Date'}</FormLabel>
          <Input
            placeholder='Select Date'
            size='md'
            type='date'
            value={date}
            isInvalid={!isDateValid(date)}
            onChange={(e) => setDate(e.target.value)}
          />
        </FormControl>
        {isPaymentPlan && (
          <FormControl isRequired isDisabled={!canEdit}>
            <FormLabel># of months</FormLabel>
            <NumberInput
              value={paymentPlanMonths}
              onChange={(_, val) => setPaymentPlanMonths(val)}
              precision={0}
              min={2}
              step={1}
              isInvalid={
                !!finalLiabilityDate &&
                moment(date).add(paymentPlanMonths, 'months').isAfter(finalLiabilityDate)
              }
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        )}
        <FormControl isRequired={noteRequired} isDisabled={!canEdit}>
          <FormLabel>Note</FormLabel>
          <Textarea
            size='sm'
            value={note || ''}
            placeholder='Insert note...'
            onChange={(e) => setNote(e.target.value)}
            isInvalid={noteRequired && (!note || !note.trim())}
          />
        </FormControl>
        {canEdit && (
          <Button
            type='submit'
            onClick={() => {
              onSubmit?.({
                liability_type_id: liabilityType?.id,
                amount: parseFloat(amount.replace(/^\$/, '')),
                date,
                paymentPlanMonths,
                note
              })
              setNote(defaultNote)
              setAmount(defaultAmount.toFixed(2))
              setLiabilityType(undefined)
              setDate(defaultDate)
              setIsPaymentPlan(false)
              setPaymentPlanMonths(undefined)
            }}
            disabled={!isValid}
          >
            Add
          </Button>
        )}

        {!canEdit && (
          <IconButton aria-label='delete' icon={<TrashIcon />} onClick={() => onDelete(index)}>
            Delete
          </IconButton>
        )}
      </HStack>
    </Stack>
  )
}
