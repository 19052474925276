import React, { useMemo, useState } from 'react'
import { utilities } from '@homevest/utils'
import type { ICellRendererParams } from 'ag-grid-community'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, HStack } from '@chakra-ui/react'
import { ApplyUtilityChargebacksPayload, UnfinalizedChargeback } from '@homevest/types/utilities'
import { BaseGrid } from 'components/TailwindUIToolkit'
import axios from 'lib/axios'
import { BASE_UTILITY_GRID_COL_DEFS } from '../utilities-grid-configs'
import { CustomSpinner } from 'ui/Loading'
import { AxiosResponse } from 'axios'
import { Sidepeek } from 'ui'
import { ConfirmButton } from 'ui/ConfirmButton'
import { CreatePaymentPlanForm } from './CreatePaymentPlanForm'

const { UTILITY_CHARGEBACK_STATUSES } = utilities

export enum UtilitiesChargebacksView {
  ALL = 'all',
  PENDING = 'pending_triage',
  REJECTED = 'rejected',
  DEFERRED = 'deferred',
  APPROVED = 'approved'
}

export type UtilityChargebackStatus =
  (typeof UTILITY_CHARGEBACK_STATUSES)[keyof typeof UTILITY_CHARGEBACK_STATUSES]

export const Grid: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    isLoading,
    data: { chargebacks: deferredChargebacks },
    refetch: refetchDeferredChargebacks
  } = useQuery({
    queryKey: ['chargebacks', 'deferred'],
    queryFn: async () => {
      return await axios
        .get<{ chargebacks: UnfinalizedChargeback[] }>('/admin/utility_chargebacks/deferred')
        .then((res) => res.data)
    },
    initialData: { chargebacks: [] },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  const [actionState, setActionState] = useState<{
    action: 'reject' | 'payment-plan' | 'accept'
    chargebackId: string
  } | null>(null)

  const activeChargebackDetails = useMemo(
    () => deferredChargebacks.find((chargeback) => chargeback.id === actionState?.chargebackId),

    [deferredChargebacks, actionState]
  )

  return (
    <>
      <div className='h-[80vh] rounded border'>
        {isLoading && <CustomSpinner />}
        {!isLoading && (
          <BaseGrid<UnfinalizedChargeback>
            columns={[
              ...BASE_UTILITY_GRID_COL_DEFS,
              {
                headerName: 'actions',
                cellRenderer: ({ data }: ICellRendererParams<UnfinalizedChargeback>) => (
                  <HStack>
                    <AcceptOrRejectChargebackButton
                      chargebackId={data!.id}
                      newStatus='rejected'
                      onSuccess={refetchDeferredChargebacks}
                    >
                      Reject
                    </AcceptOrRejectChargebackButton>
                    <Button
                      colorScheme='yellow'
                      size='sm'
                      onClick={() => {
                        setActionState({ action: 'payment-plan', chargebackId: data!.id })
                      }}
                    >
                      Payment Plan
                    </Button>
                    <AcceptOrRejectChargebackButton
                      chargebackId={data!.id}
                      newStatus='approved'
                      onSuccess={refetchDeferredChargebacks}
                    >
                      Accept
                    </AcceptOrRejectChargebackButton>
                  </HStack>
                ),
                flex: 2
              }
            ]}
            rowData={deferredChargebacks}
          />
        )}
      </div>
      <Sidepeek isOpen={!!actionState} onClose={() => setActionState(null)}>
        {!!actionState && (
          <CreatePaymentPlanForm
            allChargebacks={deferredChargebacks}
            chargebackDetails={activeChargebackDetails!}
            onSuccess={async () => {
              await refetchDeferredChargebacks()
              setActionState(null)
            }}
          />
        )}
      </Sidepeek>
    </>
  )
}

const AcceptOrRejectChargebackButton: React.FC<
  React.PropsWithChildren<{
    chargebackId: string
    newStatus: UtilityChargebackStatus
    onSuccess: () => void
  }>
> = ({ chargebackId, newStatus, onSuccess }) => {
  const { mutate, isLoading } = useMutation<
    AxiosResponse,
    unknown,
    { chargebackId: string; newStatus: UtilityChargebackStatus }
  >({
    mutationFn: async ({ chargebackId, newStatus }) => {
      return await axios.post('/admin/utility_chargebacks/apply', {
        finalize: true,
        override_already_finalized: true,
        updated_chargebacks: [
          {
            utility_chargeback_id: chargebackId,
            new_status: newStatus
          }
        ]
      } as ApplyUtilityChargebacksPayload)
    },
    onSuccess: onSuccess
  })

  return (
    <ConfirmButton
      colorScheme={newStatus === 'approved' ? 'green' : 'red'}
      isLoading={isLoading}
      isDisabled={isLoading}
      onClick={() => mutate({ chargebackId, newStatus })}
      confirmPlacement='left'
      size='sm'
    >
      {newStatus === 'approved' ? 'Accept' : 'Reject'}
    </ConfirmButton>
  )
}
