import React from 'react'
import PropTypes from 'prop-types'

import EvictionReportDetails from './EvictionReportDetails'
import ReportHeader from '../ReportHeader'

export default function EvictionReport({ evictionReport }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ReportHeader text='Eviction Report Data' />
      <EvictionReportDetails evictionReport={evictionReport} />
    </div>
  )
}

EvictionReport.propTypes = {
  evictionReport: PropTypes.object
}
