import React from 'react'

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
// This is what our server is using, might want to swap others to using this too
export const phoneRegex = /^\+\d{11}$/

export const required = (value) => (value ? undefined : 'Required')

export const email = (value) =>
  value && !emailRegex.test(value) ? 'Invalid email address' : undefined

export const isValidEmail = (email) => emailRegex.test(email)

export const phone = (value) =>
  value && !phoneRegex.test(value) ? 'Invalid phone number' : undefined

export const phoneNumber = (value) => {
  const nums = onlyNums(value)
  return nums && !/^(0|[1-9][0-9]{9})$/i.test(nums)
    ? 'Invalid phone number, must be 10 digits'
    : undefined
}

export const isValidPhoneNumber = (phoneNumber) => {
  const nums = onlyNums(phoneNumber)
  return nums && (/^[0-9]{10}$/g.test(nums) || /^1[0-9]{10}$/g.test(nums))
}

export const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value
  }
  const nums = onlyNums(value)
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (nums.length === 3) {
      return `(${nums})`
    }
    if (nums.length === 6) {
      return `(${nums.slice(0, 3)}) ${nums.slice(3)}-`
    }
  }
  if (nums.length <= 3) {
    return nums
  }
  if (nums.length <= 6) {
    return `(${nums.slice(0, 3)}) ${nums.slice(3)}`
  }
  return `(${nums.slice(0, 3)}) ${nums.slice(3, 6)}-${nums.slice(6, 10)}`
}

export const renderField = ({ input, placeholder, type, meta: { touched, error } }) => (
  <input
    {...input}
    placeholder={placeholder}
    type={type}
    className={touched && error ? 'auth-input error' : 'auth-input'}
  />
)

export const onlyNums = (value) => (value ? value.replace(/[^\d]/g, '') : '')

export const validateNumericRequired = (value, key, errors) => {
  const errorKey = `${key}Error`

  if (!value) {
    errors[errorKey] = `${key} is required`
  } else if (Number(value) < 0) {
    errors[errorKey] = `${key} must be at least 0`
  }
}

export const validateRequired = (value, key, errors) => {
  const errorKey = `${key}Error`

  if (!value) {
    errors[errorKey] = `${key} is required`
  }
}

export const validateRequiredInteger = (value, key, errors, min, max) => {
  const errorKey = `${key}Error`

  const numericValue = parseInt(value)

  if (!value) {
    errors[errorKey] = `${key} is required`
  } else if (`${numericValue}` !== value) {
    errors[errorKey] = `${key} must be an integer`
  } else if (numericValue < min || numericValue > max) {
    errors[errorKey] = `${key} must be between ${min} and ${max} inclusive`
  }
}
