import React from 'react'
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid'
import { StarIcon as OutlineStarIcon } from '@heroicons/react/24/outline'

const StarRatingDisplay = ({
  rating,
  starIconSize = 4
}: {
  rating: number
  starIconSize?: number
}) => {
  if (rating < 0 || rating > 5) {
    return <div>Invalid rating</div>
  }

  return (
    <div className='flex flex-row'>
      {Array.from({ length: 5 }, (_, i) => {
        const star = i + 1
        if (star <= rating) {
          return (
            <SolidStarIcon
              key={i}
              color={'black'}
              className={`h-${starIconSize} w-${starIconSize}`}
            />
          )
        }

        return <OutlineStarIcon key={i} className={`h-${starIconSize} w-${starIconSize}`} />
      })}
    </div>
  )
}

export default StarRatingDisplay
