import React, { FunctionComponent, useState } from 'react'
import { rentalApplications } from '@homevest/utils'
import { Tabs, TabList, Flex, Text, Spinner, Box, Button } from '@chakra-ui/react'
import ApplicationsTable from 'components/RentalApplications/ApplicationsTable'
import FilterTab from 'components/RentalApplications/FilterTab'
import { Comparators } from 'lib/hasura-comparators'
import { Application, STATUS_MAP } from 'components/RentalApplications/applications-table-utils'
import SidePeek from 'components/RentalApplications/SidePeek'
import { Link } from 'ui'
import CreateApplicationModal from './Applications/CreateApplicationModal'
import { DocumentPlusIcon } from '@heroicons/react/20/solid'
import { Rental_Application_Manager_Escalation_Statuses_Enum } from 'graphql/generated'

const STATUS = rentalApplications.RENTAL_APPLICATION_STATUSES

const TAB_CONFIG = [
  { id: 'all', label: 'All', partial: null },
  ...Object.keys(STATUS_MAP)
    .filter(
      (id) =>
        ![
          // STATUS.PENDING_ADDITIONAL_UNDERWRITING_INFORMATION,
          STATUS.PENDING_PROPERTY_PURCHASE,
          STATUS.CANCELED
        ].some((s) => s === id)
    )
    .map((id) => ({
      id,
      label: STATUS_MAP[id as keyof typeof STATUS_MAP],
      partial: { status: Comparators.isIn([id]) }
    })),
  {
    id: 'escalated',
    label: 'Escalated',
    partial: {
      application_escalations: {
        escalation_status: {
          _eq: Rental_Application_Manager_Escalation_Statuses_Enum.Pending
        }
      }
    }
  }
]

const RentalApplications: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const [tab, setTab] = useState<number>(0)
  const [count, setCount] = useState<number>()
  const [peeked, setPeeked] = useState<Application>()
  const [refetch, setRefetch] = useState<() => void>()
  const [createNew, setCreateNew] = useState(false)

  return (
    <Flex direction='column' p={8} gap={4}>
      <Flex direction='row' align='center' justify='space-between'>
        <Link to='/legacy/applications' fontSize='sm' color='gray.500' useRouter>
          View legacy rental applications page
        </Link>
        <Button
          variant='solid'
          colorScheme='indigo'
          size='sm'
          onClick={() => setCreateNew(true)}
          leftIcon={
            <Box h={4} w={4}>
              <DocumentPlusIcon />
            </Box>
          }
        >
          Create new
        </Button>
      </Flex>
      <Box overflowX='auto' p={1}>
        <Tabs colorScheme='indigo' onChange={setTab}>
          <TabList>
            {TAB_CONFIG.map((item, idx) => (
              <FilterTab
                key={item.id}
                label={item.label}
                partial={item.partial}
                active={tab === idx}
              />
            ))}
          </TabList>
        </Tabs>
      </Box>
      <Flex direction='row' align='center' gap={2}>
        {count === undefined && <Spinner mr={3} />}
        <Text fontWeight={600} fontSize='3xl'>
          {count} applications
        </Text>
      </Flex>
      <ApplicationsTable
        selectedTab={TAB_CONFIG[tab].id}
        onPeek={setPeeked}
        onSetRefetch={setRefetch} // hacky callback to refresh
        onCount={(result) => {
          setCount(result.fetching || result.count === undefined ? undefined : result.count)
        }}
      />
      {peeked && (
        <SidePeek
          appId={peeked.id}
          onClose={() => setPeeked(undefined)}
          onCancel={() => refetch!()}
        />
      )}
      {createNew && <CreateApplicationModal setShowCreateModal={setCreateNew} />}
    </Flex>
  )
}

export default RentalApplications
