"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SKIP_BILL_PROCESSING_FEE = exports.BILL_PROCESSING_FEE = exports.UTILITY_CHARGEBACK_FLAG_REASONS = exports.UTILITY_BILL_UPLOAD_TYPES = exports.UTILITY_BILL_UPLOAD_FORMATS = exports.UTILITY_BILL_CHARGE_TYPES = exports.UTILITY_TYPES = exports.UTILITY_CHARGEBACK_STATUSES = exports.UTILITIES_RESPONSIBLE_PARTIES = void 0;
exports.UTILITIES_RESPONSIBLE_PARTIES = {
    UPANDUP: 'upandup',
    TENANT: 'tenant'
};
exports.UTILITY_CHARGEBACK_STATUSES = {
    PENDING: 'pending_triage',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    DEFERRED: 'deferred'
};
exports.UTILITY_TYPES = {
    TRASH: 'trash',
    SEWER: 'sewer',
    STORMWATER: 'stormwater',
    WATER: 'water',
    GAS: 'gas',
    ELECTRIC: 'electric'
};
exports.UTILITY_BILL_CHARGE_TYPES = {
    SERVICE_CHARGE: 'service_charge',
    LATE_FEE: 'late_fee',
    DEPOSIT: 'deposit'
};
exports.UTILITY_BILL_UPLOAD_FORMATS = {
    CONSERVICE: 'conservice'
};
exports.UTILITY_BILL_UPLOAD_TYPES = {
    SCRUBBED: 'scrubbed'
};
exports.UTILITY_CHARGEBACK_FLAG_REASONS = {
    LARGE_AMOUNT: 'LARGE_AMOUNT',
    NORTH_CAROLINA_HOME: 'NORTH_CAROLINA_HOME',
    LATE_FEE: 'LATE_FEE',
    DEPOSIT: 'DEPOSIT',
    MOVE_OUT_SOON: 'MOVE_OUT_SOON',
    NEGATIVE_AMOUNT: 'NEGATIVE_AMOUNT'
};
exports.BILL_PROCESSING_FEE = 7.34;
exports.SKIP_BILL_PROCESSING_FEE = [
    { city: 'Florissant', type: exports.UTILITY_TYPES.TRASH },
    { city: 'Ferguson', type: exports.UTILITY_TYPES.TRASH }
];
