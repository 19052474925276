import React, { useState, useCallback, useEffect } from 'react'
import { RowNode, GridApi, ICellRendererParams } from 'ag-grid-community'
import {
  Tabs,
  TabList,
  Tab,
  Text,
  Stack,
  Center,
  Input,
  useDisclosure,
  IconButton
} from '@chakra-ui/react'
import { ArrowRightOnRectangleIcon } from '@heroicons/react/20/solid'
import { ConstructionProjectApiResponse } from '@homevest/types/construction-projects'
import { PROJECT_TYPES, PROJECT_STATUSES } from '@homevest/utils/construction-projects'

import { BaseGrid } from 'components/TailwindUIToolkit'
import ProjectsSidepeek from 'components/Construction/Projects/Grid/ProjectsSidepeek'
import { BASE_COLUMNS, DISPOSITION_COLUMNS } from './configuration'
import { getProjectCountsBreakdown } from 'lib/construction-projects'

const ALL = 'all'

const ProjectsGrid = ({
  data = [],
  statusesToShow,
  gridApi,
  setGridApi
}: {
  data?: ConstructionProjectApiResponse[]
  statusesToShow: (typeof PROJECT_STATUSES)[keyof typeof PROJECT_STATUSES][]
  gridApi: GridApi | null
  setGridApi: (api: GridApi) => void
}) => {
  const [projectsSearch, setProjectsSearch] = useState('')
  const [typeTabIndex, setTypeTabIndex] = useState(0)
  const [statusTabIndex, setStatusTabIndex] = useState(0)
  const [selectedProject, setSelectedProject] = useState<ConstructionProjectApiResponse | null>(
    null
  )
  const { isOpen, onOpen, onClose } = useDisclosure()

  const openSidepeekForProject = (project?: ConstructionProjectApiResponse) => {
    if (!project) {
      return
    }

    setSelectedProject(project)
    onOpen()
  }

  const onGridReady = (params: { api: GridApi }) => {
    setGridApi(params.api)
  }

  const selectedType = typeTabIndex === 0 ? ALL : Object.values(PROJECT_TYPES)[typeTabIndex - 1]
  const selectedStatus = statusTabIndex === 0 ? ALL : statusesToShow[statusTabIndex - 1]

  const isExternalFilterPresent = useCallback(() => {
    return selectedType !== ALL || selectedStatus !== ALL
  }, [selectedStatus, selectedType])

  useEffect(() => {
    gridApi?.setQuickFilter(projectsSearch)
    gridApi?.onFilterChanged()
  }, [selectedStatus, selectedType, gridApi, projectsSearch])

  const rowCount = gridApi?.getModel()?.getRowCount() ?? 0
  const doesExternalFilterPass = useCallback(
    (node: RowNode<ConstructionProjectApiResponse>) => {
      if (!node.data) {
        return false
      }
      if (selectedStatus === ALL && selectedType === ALL) {
        return true
      }

      if (selectedStatus === ALL) {
        return node.data.type === selectedType
      }

      if (selectedType === ALL) {
        return node.data.status === selectedStatus
      }

      return node.data.status === selectedStatus && node.data.type === selectedType
    },
    [selectedStatus, selectedType]
  )

  const isDisposition = ['disposition', 'disposition_inspection', 'disposition_oi'].includes(
    selectedType
  )
  let COLUMNS = [...BASE_COLUMNS]
  if (isDisposition) {
    COLUMNS = [...DISPOSITION_COLUMNS]
  }

  const countsBreakdown = getProjectCountsBreakdown(data)
  return (
    <Stack gap={4}>
      <Center>
        <Tabs
          index={typeTabIndex}
          onChange={(index) => {
            setTypeTabIndex(index)
          }}
          variant='solid-rounded'
          colorScheme='teal'
          size='lg'
          defaultIndex={0}
        >
          <TabList>
            <Tab>All ({countsBreakdown[ALL][ALL]})</Tab>
            {Object.values(PROJECT_TYPES).map((type) => (
              <Tab key={type}>
                {mapProjectTypeToTabLabel(type)} ({countsBreakdown[type][ALL]})
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Center>
      <Center>
        <Tabs
          index={statusTabIndex}
          onChange={(index) => {
            setStatusTabIndex(index)
          }}
          variant='soft-rounded'
          colorScheme='teal'
          size='lg'
          defaultIndex={0}
        >
          <TabList>
            <Tab>All ({countsBreakdown[selectedType][ALL]})</Tab>
            {statusesToShow.map((status) => (
              <Tab key={status}>
                {mapProjectStatusToTabLabel(status)} ({countsBreakdown[selectedType][status]})
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Center>
      <Input
        type='text'
        value={projectsSearch}
        onChange={(e) => setProjectsSearch(e.target.value)}
        placeholder='Search by address, project type, etc...'
      />
      <div className='h-[80vh] rounded border'>
        <BaseGrid
          columns={[
            {
              headerName: '',
              cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse>) => {
                return (
                  <IconButton
                    aria-label='Open sidepeek'
                    icon={<ArrowRightOnRectangleIcon className='h-5 w-5' />}
                    onClick={() => openSidepeekForProject(params?.data)}
                    variant={'ghost'}
                  />
                )
              },
              cellRendererParams: {
                openSidepeekForProject
              },
              minWidth: 80,
              flex: 1
            },
            ...COLUMNS
          ]}
          rowData={data}
          rowHeight={80}
          rowAutoHeight={false}
          tooltipShowDelay={200}
          onGridReady={onGridReady}
          defaultColDefOverrides={{
            minWidth: 150,
            wrapHeaderText: true
          }}
          optionOverrides={{
            suppressHorizontalScroll: false
          }}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
        />
        <ProjectsSidepeek project={selectedProject} isOpen={isOpen} onClose={onClose} />
      </div>
      <div className='flex justify-end'>
        <Text fontSize='sm' color='gray.500'>
          Row Count: {rowCount}
        </Text>
      </div>
    </Stack>
  )
}

const mapProjectTypeToTabLabel = (type: string) => {
  switch (type) {
    case PROJECT_TYPES.TURN:
      return 'Turns'
    case PROJECT_TYPES.TURN_OI:
      return 'Turns - OI'
    case PROJECT_TYPES.REHAB:
      return 'Rehabs'
    case PROJECT_TYPES.DISPOSITION:
      return 'Dispos'
    case PROJECT_TYPES.DISPOSITION_INSPECTION:
      return 'Dispos - Inspection'
    case PROJECT_TYPES.DISPOSITION_OI:
      return 'Dispos - OI'
    default:
      throw new Error(`Unknown project type: ${type}`)
  }
}

const mapProjectStatusToTabLabel = (status: string) => {
  switch (status) {
    case PROJECT_STATUSES.PENDING_MOVE_OUT:
      return 'Pending MO'
    case PROJECT_STATUSES.PENDING_MOVE_OUT_INSPECTION:
      return 'MO Inspection'
    case PROJECT_STATUSES.PENDING_SCOPE:
      return 'Scoping'
    case PROJECT_STATUSES.PENDING_DISPATCH:
      return 'Dispatch'
    case PROJECT_STATUSES.PENDING_CONSTRUCTION_START:
      return 'Pending Construction'
    case PROJECT_STATUSES.PENDING_CONSTRUCTION_COMPLETION:
      return 'Construction'
    case PROJECT_STATUSES.PENDING_QUALITY_CONTROL:
      return 'QC'
    case PROJECT_STATUSES.POST_QUALITY_CONTROL_TASKS:
      return 'Post QC'
    case PROJECT_STATUSES.COMPLETE:
      return 'Complete'
    case PROJECT_STATUSES.CANCELED:
      return 'Canceled'
    default:
      throw new Error(`Unknown project status: ${status}`)
  }
}

export default ProjectsGrid
