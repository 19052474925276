import React from 'react'
import { IconButton } from '@chakra-ui/react'
import { MinusCircleIcon } from '@heroicons/react/24/outline'

const RemoveButton: React.FC<React.PropsWithChildren<{ onClick: () => void }>> = ({ onClick }) => {
  return (
    <IconButton
      variant='ghost'
      icon={<MinusCircleIcon className='h-6 w-6' />}
      aria-label='Remove'
      colorScheme='red'
      onClick={onClick}
    />
  )
}

export default RemoveButton
