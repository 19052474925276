import { Nullable } from '@homevest/types/utils'
import { OccupancyInspection } from '@homevest/types/occupancy-inspections'

type OccupancyInspectionFields = Pick<
  OccupancyInspection,
  | 'construction_project_id'
  | 'previous_occupancy_inspection_id'
  | 'primary_municipality_contact_id'
  | 'status'
  | 'result'
  | 'inspection_at'
  | 'certificate_effective_date'
  | 'certificate_expiration_date'
  | 'municipality_contacted_at'
  | 'application_submitted_at'
  | 'payment_submitted_at'
  | 'payment_confirmed_at'
  | 'occupant_signature_deadline'
  | 'occupant_signature_at'
>

export type OccupancyInspectionFormDataInput = Pick<
  OccupancyInspection,
  'construction_project_id'
> &
  Partial<Nullable<Omit<OccupancyInspectionFields, 'construction_project_id'>>>

export type OccupancyInspectionFormData = Nullable<OccupancyInspectionFields>

export type ValidationResponse = {
  isValid: boolean
  errors: Record<keyof OccupancyInspectionFormData, string>
}

const REQUIRED_FIELDS = ['status'] as const

export const validateOccupancyInspectionForm = (
  data: OccupancyInspectionFormData
): ValidationResponse => {
  let isValid = true
  const errors = {} as Record<keyof OccupancyInspectionFormData, string>

  REQUIRED_FIELDS.forEach((field) => {
    if (!data[field]) {
      isValid = false
      errors[field] = 'This field is required!'
    }
  })

  if (data.certificate_effective_date && !data.certificate_expiration_date) {
    isValid = false
    errors.certificate_expiration_date = 'Please enter the certificate expiration date!'
  }

  if (!data.certificate_effective_date && data.certificate_expiration_date) {
    isValid = false
    errors.certificate_effective_date = 'Please enter the certificate effective date!'
  }

  return {
    isValid,
    errors
  }
}
