"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COUNTER_RESPONSE_TYPES = exports.BUYERS_INSPECTION_STATUSES = void 0;
exports.BUYERS_INSPECTION_STATUSES = {
    SUBMITTED_BY_BUYER: 'submitted_by_buyer',
    VENDOR_BIDS_SENT: 'vendor_bids_sent',
    COUNTER_SUBMITTED: 'counter_submitted',
    COUNTER_RESPONSE_RECEIVED: 'counter_response_received',
    CANCELED: 'canceled'
};
exports.COUNTER_RESPONSE_TYPES = {
    ACCEPTED: 'accepted',
    COUNTERED: 'countered',
    REJECTED: 'rejected'
};
