import React, { useState } from 'react'
import { ICellRendererParams } from 'ag-grid-community'

const ExpandableCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const [expanded, setExpanded] = useState(false)
  const cellValue: string = props.value
  if (!props.value) {
    return <></>
  }

  const truncatedValue = cellValue.length > 50 ? cellValue.slice(0, 50) + '...' : cellValue

  return (
    <div className='flex items-center'>
      <span className='mr-1 cursor-pointer' onClick={() => setExpanded(!expanded)}>
        {expanded ? '▼' : '►'}
      </span>
      <span className='overflow-hidden text-ellipsis'>{expanded ? cellValue : truncatedValue}</span>
    </div>
  )
}

export default ExpandableCellRenderer
