import React from 'react'
import format from 'date-fns/format'
import capitalize from 'lodash/capitalize'
import { ContentSectionCard, TwoColumnDataList, ActionButton } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import { useRaSnoozesByRaIdQuery, useSoftDeleteRaSnoozeMutation } from 'graphql/generated'
import { SnoozeApplicationButton } from './SnoozeApplicationButton'

const UnsnoozeButton: React.FC<React.PropsWithChildren<{ rentalApplicationId: string }>> = ({
  rentalApplicationId
}) => {
  const [_, softDeleteRentalApplication] = useSoftDeleteRaSnoozeMutation()

  return (
    <ActionButton
      onClick={() => {
        softDeleteRentalApplication({ rentalApplicationId })
      }}
    >
      Unsnooze
    </ActionButton>
  )
}

const SnoozeDetailsInternal: React.FC<React.PropsWithChildren<{ rentalApplicationId: string }>> = ({
  rentalApplicationId
}) => {
  const [{ data, error }] = useRaSnoozesByRaIdQuery({
    variables: { id: rentalApplicationId }
  })

  let inner: React.ReactElement
  let canDelete = false
  if (error) {
    inner = <SC.MessageText>Something went wrong.</SC.MessageText>
  } else if ((data?.rental_application_manager_application_snoozes?.length ?? 0) === 0) {
    inner = <SC.MessageText>No active snoozes.</SC.MessageText>
  } else {
    const snooze = data!.rental_application_manager_application_snoozes[0]
    canDelete = true
    inner = (
      <TwoColumnDataList
        data={[
          {
            label: 'Snoozed until',
            value: format(new Date(snooze.snoozed_until), 'PPpp')
          },
          {
            label: 'Snoozed by',
            value: snooze.snoozed_by_admin.first_name + ' ' + snooze.snoozed_by_admin.last_name
          },
          {
            label: 'Snoozed reason',
            value: capitalize(snooze.snooze_reason.replaceAll('_', ' '))
          }
        ]}
      />
    )
  }

  return (
    <ContentSectionCard
      padding
      collapsable={false}
      title={'Snooze Details'}
      action={
        canDelete ? (
          <UnsnoozeButton rentalApplicationId={rentalApplicationId} />
        ) : (
          <SnoozeApplicationButton rentalApplicationId={rentalApplicationId} showText />
        )
      }
    >
      {inner}
    </ContentSectionCard>
  )
}

export const SnoozeDetails: React.FC<React.PropsWithChildren<{ rentalApplicationId: string }>> = ({
  rentalApplicationId
}) => (
  <React.Suspense fallback={<SC.MessageText>Loading...</SC.MessageText>}>
    <SnoozeDetailsInternal rentalApplicationId={rentalApplicationId} />
  </React.Suspense>
)
