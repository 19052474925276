import React, { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  UpupDocumentRealEstateAcquisitionsQuery,
  UpupDocumentsByResourceQuery,
  useUpupUpdateDocumentMutation
} from '../../graphql/generated'
import { STATUS_MAP } from 'lib/document-manager'

type Document = UpupDocumentsByResourceQuery['documents'][number]
type RealEstateAcquisition =
  UpupDocumentRealEstateAcquisitionsQuery['real_estate_acquisitions'][number]

const ManageModal: FunctionComponent<
  React.PropsWithChildren<{
    document?: Document
    resource?: RealEstateAcquisition
    isOpen: boolean
    onClose: () => void
    onUpdate: () => Promise<void>
  }>
> = ({ document, resource, isOpen, onClose, onUpdate }) => {
  const [name, setName] = useState<string | undefined>(document?.friendly_name)
  const [status, setStatus] = useState<string | undefined>(document?.status)
  const [note, setNote] = useState<string | undefined>(document?.resource_notes || undefined)

  const [_, updateDocument] = useUpupUpdateDocumentMutation()

  useEffect(() => {
    if (document) {
      setStatus(document?.status)
      setNote(document?.resource_notes || undefined)
      setName(document?.friendly_name)
    }
  }, [document])

  const handleClose = () => {
    setStatus(document?.status)
    setNote(document?.resource_notes || undefined)
    setName(document?.friendly_name)
    onClose()
  }

  const disabled =
    status === document?.status &&
    name === document?.friendly_name &&
    note === (document?.resource_notes || undefined)

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={handleClose}>
          <div className='fixed inset-0 bg-black bg-opacity-25' />

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all '>
                <div className='flex flex-col gap-6'>
                  <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                    Update document
                  </Dialog.Title>
                  <div className='flex flex-col gap-1'>
                    <div>
                      <div className='text-gray-500'>Resource id</div>
                      <div className='text-gray-900'>{resource?.id}</div>
                    </div>
                    <div>
                      <div className='text-gray-500'>Address</div>
                      <div className='text-gray-900'>
                        {resource?.mls_listing.delivery_line}, {resource?.mls_listing.city},{' '}
                        {resource?.mls_listing.state}
                      </div>
                    </div>
                    <div>
                      <div className='text-gray-500'>Document type</div>
                      <div className='text-gray-900'>{document?.type}</div>
                    </div>
                    <div>
                      <div className='text-gray-500'>Document name</div>
                      <input
                        type='text'
                        value={name}
                        className='rounded-md border border-gray-300 px-1 py-1'
                        onChange={(event) => setName(event.target.value)}
                      />
                    </div>
                    <div>
                      <div className='text-gray-500'>Document status</div>
                      <div className='text-gray-900'>
                        <select
                          value={status}
                          className='rounded-md border border-gray-300 px-1 py-1'
                          onChange={(event) => setStatus(event.target.value)}
                        >
                          {Object.keys(STATUS_MAP).map((key) => (
                            <option key={key} value={key}>
                              {STATUS_MAP[key as keyof typeof STATUS_MAP]}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <div className='text-gray-500'>Document note</div>
                      <textarea
                        value={note}
                        className='rounded-md border border-gray-300 px-1 py-1'
                        onChange={(event) => setNote(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className='flex flex-row gap-4'>
                    <button
                      type='button'
                      className={`whitespace-nowrap rounded bg-green-200 px-2 py-1 font-medium ${
                        disabled ? 'bg-gray-300 text-gray-500' : ''
                      }`}
                      disabled={disabled}
                      onClick={async () => {
                        await updateDocument({
                          documentId: document?.id,
                          friendlyName: name,
                          status,
                          resourceNotes: note
                        })
                        await onUpdate()
                        handleClose()
                      }}
                    >
                      Update
                    </button>
                    <button
                      type='button'
                      className='whitespace-nowrap rounded bg-gray-200 px-2 py-1 font-medium'
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ManageModal
