import { ModalOverlay, Modal } from '@chakra-ui/react'
import React from 'react'
import { Reunderwrite as ReunderwriteModalContent } from 'screens/RentRoll/Details/Renewals/CustomRenewalModal/ReunderwriteModalContent'

export const ReunderwriteModal = ({
  rentalId,
  isOpen = true,
  close
}: {
  rentalId?: string
  isOpen?: boolean
  onAction?: () => void
  close: () => void
}) => {
  return (
    <Modal isOpen={isOpen && !!rentalId} onClose={close} size='2xl'>
      <ModalOverlay />
      {rentalId && <ReunderwriteModalContent goBack={close} rentalId={rentalId} />}
    </Modal>
  )
}
