// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React from 'react'
import { Container } from 'reactstrap'
import { capabilities } from '@homevest/utils'
import { Helmet } from 'react-helmet'

import { Button, ErrorText, HeaderText } from 'components/Toolkit'

import List from './List'
import CreateApplicationModal from './CreateApplicationModal'

import { useSelector } from 'react-redux'
import { hasCapability } from 'lib/admin-perms'
import { StoreState } from 'store'

const { APPLICATION_VIEWER } = capabilities.CAPABILITY_TYPES

const ApplicationsScreen: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isRentalAppViewer = useSelector<StoreState, boolean>((store) =>
    hasCapability(store.admin, APPLICATION_VIEWER)
  )
  const [showCreateModal, setShowCreateModal] = React.useState(false)

  if (!isRentalAppViewer) {
    return (
      <Container>
        <ErrorText>You do not have permissions to view this content</ErrorText>
      </Container>
    )
  }

  return (
    <>
      <Helmet>
        <title>Applications</title>
      </Helmet>
      <div>
        <Container>
          <div
            style={{
              marginLeft: '4%',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <HeaderText style={{ margin: 0 }} size='h3'>
              Applications
            </HeaderText>
            <Button isSecondary={false} onClick={() => setShowCreateModal(true)} size='s'>
              + Create
            </Button>
          </div>

          <List />
        </Container>

        {showCreateModal && <CreateApplicationModal setShowCreateModal={setShowCreateModal} />}
      </div>
    </>
  )
}

export default ApplicationsScreen
