import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
`

const StyledIframe = styled.iframe`
  height: calc(100vh - 248px);
  width: 100%;
`

const StyledHeader = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #302654;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledButton = styled.button`
  background: #00cfcc;
  box-shadow: 0px 3px 5px rgba(2, 190, 187, 0.2);
  border: 0;
  border-radius: 100px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 30px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
`

function Homes({ userId }) {
  const homesLink = `https://app.upandup.co/homes?id=${userId}`
  const [canSave, setCanSave] = useState(false)

  const updateHandler = useCallback(
    (event) => {
      if (event.data === 'homePreferencesUpdated') {
        setCanSave(true)
      }
    },
    [setCanSave]
  )

  const savedHandler = useCallback(
    (event) => {
      if (event.data === 'homePreferencesSaved') {
        setCanSave(false)
        // eslint-disable-next-line no-undef
        window.alert('Saved Preferences')
      }
    },
    [setCanSave]
  )

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('message', updateHandler, false)
    // eslint-disable-next-line no-undef
    window.addEventListener('message', savedHandler, false)

    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('message', updateHandler)
      // eslint-disable-next-line no-undef
      window.removeEventListener('message', savedHandler)
    }
  }, [updateHandler, savedHandler])

  return (
    <StyledContainer>
      <StyledHeader>
        <div>
          <div>
            <b>Make sure you are logged out of your own account before using this feature</b>
          </div>
          <span style={{ color: '#85807A' }}>{homesLink}</span>
        </div>
        <StyledButton
          disabled={!canSave}
          onClick={() => {
            // eslint-disable-next-line no-undef
            document
              .querySelector('#homes-iframe')
              .contentWindow.postMessage('saveHomePreferences', '*')
          }}
        >
          Save Filters
        </StyledButton>
      </StyledHeader>
      <StyledIframe id='homes-iframe' src={`${homesLink}&no_events=true`} />
    </StyledContainer>
  )
}

Homes.propTypes = {
  userId: PropTypes.string.isRequired
}

export default Homes
