import { Center, Spinner } from '@chakra-ui/react'
import React from 'react'

export const CustomSpinner = () => <Spinner size='lg' color='blue.500' thickness='3px' />

export const SmallLoading = () => (
  <Center w='full'>
    <CustomSpinner />
  </Center>
)

const Loading = () => (
  <Center height='400px' maxH='full' w='full'>
    <CustomSpinner />
  </Center>
)

export default Loading
