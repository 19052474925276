import React from 'react'

import { Button } from '@chakra-ui/react'

const SubmitButton = ({ onSubmit }: { onSubmit: () => void }) => {
  return (
    <Button w='full' mt='5' onClick={onSubmit} colorScheme='teal'>
      Submit
    </Button>
  )
}

export default SubmitButton
