import { Text, Td, Tooltip, Tr } from '@chakra-ui/react'
import { formatDate } from 'lib/date-time'
import { formatMoney } from 'lib/numbers'
import React from 'react'
import { FC } from 'react'
import { FmvEstimateRow } from '../types'

interface ManualFmvTableRowProps {
  row: FmvEstimateRow
  showError: boolean
}
const ManualFmvTableRow: FC<React.PropsWithChildren<ManualFmvTableRowProps>> = ({
  row,
  showError
}) => {
  const oldFmv = row.oldFmv?.fmv
  const didFmvIncrease = oldFmv && row.fmv > oldFmv
  const fmvChange = oldFmv ? `${((row.fmv / oldFmv - 1) * 100).toFixed(2)}%` : 'N/A'
  const oldFmvText = row.oldFmv
    ? `${formatMoney(oldFmv, 2, '$')} on ${row.oldFmv.date.split('T')[0]}`
    : ''
  return (
    <Tr key={row.propertyId}>
      <Td>{row.address}</Td>
      <Td>{row.fmv}</Td>
      <Td>{formatDate(new Date(row.date))}</Td>
      <Td>{row.source}</Td>
      <Td>
        {oldFmv ? (
          <Text color={`${didFmvIncrease ? 'green' : 'red'}.500`} fontWeight='bold'>
            <Tooltip label={oldFmvText}>{fmvChange}</Tooltip>
          </Text>
        ) : (
          'N/A'
        )}
      </Td>
      {showError && (
        <Td maxWidth={'400px'}>
          <Text isTruncated>
            <Tooltip label={row.errorMessage}>{row.errorMessage}</Tooltip>
          </Text>
        </Td>
      )}
    </Tr>
  )
}

export default ManualFmvTableRow
