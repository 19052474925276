import React, { FunctionComponent, useState } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Box,
  Container,
  Stack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spinner
} from '@chakra-ui/react'
import SidePeekHeader from './SidePeekHeader'
import {
  ContentSectionCard,
  CreateNoteButtonWithModal,
  NotesGrid
} from 'components/TailwindUIToolkit'
import { SnoozeDetails } from '../SnoozeApplication/SnoozeDetails'
import { EscalateApplicationDetails } from '../EscalateApplication/EscalateApplicationDetails'
import { LatestNoteDetails } from '../LatestNoteDetails'
import SidePeekInfo from './SidePeekInfo'
import SidePeekDetails from './SidePeekDetails'

const SidePeek: FunctionComponent<
  React.PropsWithChildren<{
    appId: string
    onClose: () => void
    onCancel: () => void
  }>
> = ({ appId, onCancel, onClose }) => {
  const [size, setSize] = useState<'full' | 'xl'>('xl')

  return (
    <Drawer size={size} placement='right' onClose={onClose} isOpen={Boolean(appId)}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader py={2} px={6} borderBottomWidth={1} borderBottomColor='gray.200'>
          <SidePeekHeader
            appId={appId}
            size={size}
            onCancel={onCancel}
            onClose={onClose}
            setSize={setSize}
          />
        </DrawerHeader>
        <DrawerBody p={0}>
          <SidePeekInfo appId={appId} />
          <Box as='section' bg='bg-surface'>
            <Container maxW={'100ch'}>
              <Stack spacing='16'>
                <Tabs size={'md'} align='center' colorScheme='indigo' isFitted>
                  <TabList>
                    <Tab>Overview</Tab>
                    <Tab>Details</Tab>
                    <Tab>Notes</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Box mb={'4'}>
                        <EscalateApplicationDetails rentalApplicationId={appId} />
                      </Box>
                      <Box mb={'4'}>
                        <SnoozeDetails rentalApplicationId={appId} />
                      </Box>
                      <Box>
                        <LatestNoteDetails rentalApplicationId={appId} />
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <SidePeekDetails appId={appId} />
                    </TabPanel>
                    <TabPanel>
                      <Box h={96}>
                        <ContentSectionCard
                          title='Notes'
                          staticSize={true}
                          collapsable={false}
                          action={
                            <CreateNoteButtonWithModal
                              resourceId={appId}
                              resourceType='rental_applications'
                            />
                          }
                        >
                          <React.Suspense fallback={<Spinner />}>
                            <NotesGrid resourceIds={[appId]} />
                          </React.Suspense>
                        </ContentSectionCard>
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Stack>
            </Container>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default SidePeek
