import React, { FunctionComponent } from 'react'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import {
  AdjustmentsHorizontalIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/20/solid'
import { MultiSelect, MultiSelectOption } from 'ui'

type Props = {
  label: string
  sortable?: boolean
  searchable?: boolean
  selectable?: boolean
  sorted?: 'asc' | 'desc'
  searched?: string
  selected?: string[]
  selectOptions?: MultiSelectOption[]
  onSort?: () => void
  onSearch?: (text: string) => void
  onSelect?: (selected: string[]) => void
}

const ColumnHeader: FunctionComponent<React.PropsWithChildren<Props>> = ({
  label,
  sortable,
  searchable,
  selectable,
  sorted,
  searched,
  selected,
  selectOptions,
  onSort,
  onSearch,
  onSelect
}) => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <Box h='full'>
      <Flex direction='row' align='center' justify='start' gap={2} p={1}>
        <Box flexGrow={1}>
          {sortable ? (
            <Button
              variant='ghost'
              size='sm'
              whiteSpace='nowrap'
              cursor='pointer'
              userSelect='none'
              onClick={onSort}
            >
              <Flex direction='row' align='end' gap={2}>
                <Text textTransform='uppercase' fontSize='xs' fontWeight={500} color='gray.600'>
                  {label}
                </Text>
                {sorted === 'asc' && (
                  <Box h={4} w={4} color='indigo.600'>
                    <BarsArrowUpIcon />
                  </Box>
                )}
                {sorted === 'desc' && (
                  <Box h={4} w={4} color='indigo.600'>
                    <BarsArrowDownIcon />
                  </Box>
                )}
              </Flex>
            </Button>
          ) : (
            <Box whiteSpace='nowrap' flexGrow={1} px={4} py={2}>
              <Text textTransform='uppercase' fontSize='xs' fontWeight={500} color='gray.600'>
                {label}
              </Text>
            </Box>
          )}
        </Box>
        {(searchable || selectable) && (
          <IconButton
            variant='ghost'
            aria-label='Filters'
            size='xs'
            color={
              (searched?.length ?? 0) > 1 || (selected?.length ?? 0) > 0 ? 'indigo.600' : 'gray.400'
            }
            onClick={onToggle}
            icon={
              <Box h={4} w={4}>
                <AdjustmentsHorizontalIcon />
              </Box>
            }
          />
        )}
      </Flex>
      {isOpen && searchable && (
        <Box p={1}>
          <InputGroup>
            <InputLeftElement h='full' pointerEvents='none'>
              <Box h={4} w={4}>
                <MagnifyingGlassIcon />
              </Box>
            </InputLeftElement>
            <Input
              variant='filled'
              borderRadius='md'
              size='sm'
              type='search'
              placeholder='Search...'
              value={searched || ''}
              onChange={({ target }) => onSearch!(target.value)}
            />
          </InputGroup>
        </Box>
      )}
      {isOpen && selectable && (
        <Box p={1}>
          <MultiSelect value={selected || []} options={selectOptions!} onChange={onSelect!} />
        </Box>
      )}
    </Box>
  )
}

export default ColumnHeader
