import React from 'react'
import { startCase } from 'lodash'
import {
  Badge,
  Box,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  Text,
  Heading,
  Link
} from '@chakra-ui/react'
import { InformationCircle } from 'styled-icons/heroicons-solid'
import { RentRollPendingEvictionsFragment } from 'graphql/generated'
import { PopoverBody } from 'reactstrap'

const EVICTIONS_TRACKER_URL =
  'https://upandup.tryretool.com/apps/d612e6cc-7947-11ec-b407-6be499bf05b9/Property%20Management/Evictions'

const Evictions = ({ rental }: { rental: RentRollPendingEvictionsFragment }) => {
  if (!rental?.pending_evictions?.length) {
    return null
  }

  const pendingEviction = rental.pending_evictions[0]

  return (
    <Box>
      <Badge colorScheme={'red'} variant='outline' fontSize='xl'>
        Eviction in Progess
      </Badge>
      <Popover>
        <PopoverTrigger>
          <IconButton
            size='sm'
            variant='ghost'
            aria-label='Eviction Info'
            icon={<InformationCircle className='h-5 w-5' />}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>
            <Heading size='md'>Pending Eviction</Heading>
          </PopoverHeader>
          <PopoverBody>
            <Text>
              An eviction for this rental is currently in status{' '}
              <Text as={'span'} fontWeight='bold'>
                {startCase(pendingEviction.status)}.
              </Text>
            </Text>
            <Text>
              Check the{' '}
              <Link isExternal href={EVICTIONS_TRACKER_URL}>
                <Text color={'teal'} as='span'>
                  Evictions Tracker
                </Text>
              </Link>{' '}
              for more details.
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export default Evictions
