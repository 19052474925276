import React, { useState } from 'react'
import { Center, Spinner, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { rentalApplications } from '@homevest/utils'
import {
  GetRequiredLeaseDetailsResponse,
  OccupantDetails,
  PetDetails,
  VehicleDetails
} from '@homevest/types/rental-applications'

import axios from 'lib/axios'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import Occupants from './Sections/Occupants'
import Pets from './Sections/Pets'
import VehiclesSection from './Sections/Vehicles'

const { PRE_AGREEMENT_STATUSES } = rentalApplications

type RequiredLeaseDetailsProps = {
  rentalApplicationId: string
  rentalApplicationStatus: string
}

const RequiredLeaseDetails: React.FC<React.PropsWithChildren<RequiredLeaseDetailsProps>> = ({
  rentalApplicationId,
  rentalApplicationStatus
}) => {
  const [requiredLeaseDetails, setRequiredLeaseDetails] =
    useState<GetRequiredLeaseDetailsResponse | null>(null)

  const { isLoading, isFetching, isError } = useQuery({
    queryKey: ['fetchRequiredLeaseDetails'],
    queryFn: async () => {
      const { data } = await axios.get<GetRequiredLeaseDetailsResponse>(
        `/admin/rental_applications/${rentalApplicationId}/required_lease_details`
      )
      return data
    },
    onSuccess: (data) => setRequiredLeaseDetails(data),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  if (isLoading) {
    return (
      <Center marginY={3}>
        <Spinner colorScheme='teal' />
      </Center>
    )
  }

  if (isError) {
    return <Text>Failed to fetch required lease details!</Text>
  }

  const isEditable = (PRE_AGREEMENT_STATUSES as unknown as string[]).includes(
    rentalApplicationStatus
  )
  const pets = requiredLeaseDetails?.pets ?? ({} as PetDetails)
  const occupants = requiredLeaseDetails?.occupants ?? ({} as OccupantDetails)
  const vehicles = requiredLeaseDetails?.vehicles ?? ({} as VehicleDetails)

  return (
    <>
      <Occupants
        rentalApplicationId={rentalApplicationId}
        occupantDetails={occupants}
        isEditable={isEditable}
        isFetching={isFetching}
      />
      <Pets
        rentalApplicationId={rentalApplicationId}
        petDetails={pets}
        isEditable={isEditable}
        isFetching={isFetching}
      />
      <VehiclesSection
        rentalApplicationId={rentalApplicationId}
        vehicleDetails={vehicles}
        isEditable={isEditable}
        isFetching={isFetching}
      />
    </>
  )
}

const RequiredLeaseDetailsWrapper = ({
  rentalApplicationId,
  rentalApplicationStatus
}: RequiredLeaseDetailsProps) => {
  return (
    <ContentSectionCard title={'Required Lease Details'} padding>
      <RequiredLeaseDetails
        rentalApplicationId={rentalApplicationId}
        rentalApplicationStatus={rentalApplicationStatus}
      />
    </ContentSectionCard>
  )
}

export default RequiredLeaseDetailsWrapper
