import React, { MouseEventHandler } from 'react'
import { ButtonProps } from 'reactstrap'

type ColorTheme = 'primary' | 'secondary' | 'disabled'

type ActionButtonProps = ButtonProps & {
  show?: boolean
  colorScheme?: ColorTheme
}

export const ActionButton: React.FC<React.PropsWithChildren<ActionButtonProps>> = ({
  show = true,
  colorScheme = 'primary',
  onClick,
  children,
  ...rest
}) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    onClick && onClick(e)
    e.stopPropagation()
  }

  const colorClasses =
    colorScheme === 'primary'
      ? 'bg-white hover:bg-slate-300'
      : colorScheme === 'secondary'
      ? 'text-white bg-indigo-600 hover:bg-indigo-500'
      : colorScheme === 'disabled'
      ? 'text-white bg-indigo-200'
      : ''

  return show ? (
    <button
      className={`shadow-reg order-1 box-border inline-flex flex-none items-center justify-center gap-2 rounded-md border border-gray-300 px-3 py-2 text-sm transition-colors duration-300 ${colorClasses}`}
      {...rest}
      onClick={handleClick}
    >
      {children}
    </button>
  ) : null
}

export const LinkButton: React.FC<
  React.PropsWithChildren<ActionButtonProps & { href: string }>
> = ({ href, ...buttonProps }) => (
  <a href={href} className='hover:no-underline' target='_blank' rel='noreferrer'>
    <ActionButton {...buttonProps} />
  </a>
)
