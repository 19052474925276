import React, { useContext, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Divider,
  VStack,
  Button,
  Spacer,
  useToast
} from '@chakra-ui/react'

import axios from 'lib/axios'
import { RentalDetailsContext } from '../RentalDetailsContext'

const ConfirmationModal: React.FC<
  React.PropsWithChildren<{ isOpen: boolean; close: () => void }>
> = ({ isOpen, close }) => {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const { rentalData: rental } = useContext(RentalDetailsContext)
  const rentalId = rental?.id

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await axios.post(`/admin/rentals/${rentalId}/generate_poa_amendment`)

      toast({
        title: 'POA Amendment Issued!',
        description: 'The POA Amendment has been successfully issued.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
    } catch (error) {
      console.error(error)
      toast({
        title: 'Error',
        description:
          'Whoops! An error occurred while trying to issue the POA Amendment. Please contact Engineering.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
    }
    setIsLoading(false)
    close()
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>POA Amendment Confirmation</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <VStack spacing={7}>
            <Text> Are you sure you want to issue a POA Amendment for this rental? </Text>
            <VStack spacing={4}>
              <Text>
                This will create a document in Hellosign and allow the primary tenant to sign on
                their web portal.
              </Text>
              <Text>
                Note that when a rental is <b>renewed</b>, we automatically issue a POA Amendment if
                necessary, so you <b>do not</b> need to issue a POA Amendment separately here if you
                are already issuing a renewal.
              </Text>
            </VStack>
          </VStack>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Button colorScheme='red' variant='outline' disabled={isLoading} onClick={close}>
            Go Back
          </Button>
          <Spacer />
          <Button isLoading={isLoading} colorScheme='teal' onClick={handleSubmit}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmationModal
