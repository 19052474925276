import React from 'react'
import { HomeIcon } from '@heroicons/react/20/solid'
import humanizeString from 'humanize-string'
import { HomeStatusEventDisplayTemplate } from './HomeStatusEventDisplayTemplate'
import { SmsEventDisplayTemplate } from './SmsEventDisplayTemplate'
import { CallEventDisplayTemplate } from './CallEventDisplayTemplate'
import { EmailEventDisplayTemplate } from './EmailEventDisplayTemplate'
import { InquiryEventDisplayTemplate } from './InquiryEventDisplayTemplate'
import { TourEventDisplayTemplate } from './TourEventDisplayTemplate'
import { RentalApplicationEventDisplayTemplate } from './RentalApplicationEventDisplayTemplate'
import { NoteEventDisplayTemplate } from './NoteEventDisplayTemplate'
import type { TimelineEventDisplayTemplate, TimelineEventDisplayTemplateMapType } from '../types'

const DefaultEventDisplayTemplate: TimelineEventDisplayTemplate<any> = {
  icon: HomeIcon,
  iconColor: 'gray.300',
  previewContent: ({ event }) => <>{humanizeString(event.eventType)} event</>,
  expandedContent: ({ event }) => (
    <pre>
      <code>{JSON.stringify(event, undefined, 2)}</code>
    </pre>
  )
}

export const eventDisplayTemplateMap: TimelineEventDisplayTemplateMapType = {
  email: EmailEventDisplayTemplate,
  sms: SmsEventDisplayTemplate,
  tour: TourEventDisplayTemplate,
  task: DefaultEventDisplayTemplate,
  inquiry: InquiryEventDisplayTemplate,
  note: NoteEventDisplayTemplate,
  rental_application: RentalApplicationEventDisplayTemplate,
  call: CallEventDisplayTemplate,
  home_status: HomeStatusEventDisplayTemplate
}
