import React, { useEffect, useState } from 'react'
import { parseISO, format } from 'date-fns'
import { startCase } from 'lodash'

type Document = {
  id?: any
  type?: string | null
  status?: string | null
  created_at?: string | null
  instructions?: string | null
  is_supplemental?: boolean | null
}

const DocumentList: React.FC<
  React.PropsWithChildren<{ documents?: Document[]; hideSize?: number }>
> = ({ documents, hideSize = 8 }) => {
  const [showAll, setShowAll] = useState<boolean | null>(null)

  const nonSuppDocs = (documents ?? []).filter((doc) => !doc.is_supplemental)
  const suppDocs = (documents ?? []).filter((doc) => doc.is_supplemental)
  const nonSuppSize = nonSuppDocs.length

  useEffect(() => {
    if (nonSuppSize > hideSize) {
      setShowAll(false)
    }
  }, [nonSuppSize, hideSize])

  return (
    <div className='w-full overflow-x-scroll'>
      <table className='min-w-[48rem] table-auto divide-y divide-gray-100 overflow-hidden rounded-lg'>
        <thead className='bg-gray-100'>
          <tr>
            <th className='p-1'>Type</th>
            <th className='p-1'>Status</th>
            <th className='p-1'>Submitted at</th>
            <th className='p-1'>Notes</th>
            <th className='p-1'>Supplemental?</th>
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-100'>
          {[...suppDocs, ...(showAll !== false ? nonSuppDocs : [])].map((document) => (
            <tr key={document.id}>
              <td className='p-1'>{startCase(document.type || '')}</td>
              <td className='p-1'>{startCase(document.status || '')}</td>
              <td className='p-1'>
                {document.created_at ? format(parseISO(document.created_at), 'yyyy-MM-dd') : ''}
              </td>
              <td className='p-1'>{document.instructions}</td>
              <td className='p-1'>{document.is_supplemental ? '✅' : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {showAll !== null && (
        <button
          type='button'
          className='whitespace-nowrap rounded py-1 text-sm font-medium text-blue-500'
          onClick={() => setShowAll(!showAll)}
        >
          {showAll
            ? 'Hide non-supplemental'
            : `Show ${nonSuppDocs.length} non-supplemental documents`}
        </button>
      )}
    </div>
  )
}

export default DocumentList
