import React, { memo } from 'react'
import PropTypes from 'prop-types'

function CircleCheckmarkOutline({ color = '#6EBA83', width = '14', height = '14' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.0599 3.77078L5.66659 8.16411L3.27325 5.77745L2.33325 6.71745L5.66659 10.0508L10.9999 4.71745L10.0599 3.77078ZM6.99992 0.0507812C3.31992 0.0507812 0.333252 3.03745 0.333252 6.71745C0.333252 10.3974 3.31992 13.3841 6.99992 13.3841C10.6799 13.3841 13.6666 10.3974 13.6666 6.71745C13.6666 3.03745 10.6799 0.0507812 6.99992 0.0507812ZM6.99992 12.0508C4.05325 12.0508 1.66659 9.66411 1.66659 6.71745C1.66659 3.77078 4.05325 1.38411 6.99992 1.38411C9.94659 1.38411 12.3333 3.77078 12.3333 6.71745C12.3333 9.66411 9.94659 12.0508 6.99992 12.0508Z'
        fill={color}
      />
    </svg>
  )
}

CircleCheckmarkOutline.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default memo(CircleCheckmarkOutline)
