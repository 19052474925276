export function append(collection, key, val) {
  const newCollection = new Map(collection)
  newCollection.set(key, val)

  return newCollection
}

export function remove(collection, key) {
  const newCollection = new Map(collection)
  newCollection.delete(key)

  return newCollection
}
