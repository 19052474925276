import React, { FunctionComponent } from 'react'
import { Interaction } from 'types/Timeline'
import { ItemTitle } from './StyledComponents'
import RenderFields from './RenderFields'
import { uncategorizedItemFields } from './DisplayedFields'

type UncategorizedItemPropTypes = {
  interaction: Interaction
  open: boolean
}
// a react component for displaying an uncategorized interaction
// event on the customer timeline

const UncategorizedItem: FunctionComponent<React.PropsWithChildren<UncategorizedItemPropTypes>> = ({
  interaction,
  open
}) => {
  if (open) {
    return (
      <div>
        <ItemTitle>Uncategorized Interaction</ItemTitle>
        <p>Occured at: {interaction.happenedAtFormatted}</p>
        <pre>
          <code>{JSON.stringify(interaction, null, 4)}</code>
        </pre>
      </div>
    )
  } else {
    return (
      <div>
        <ItemTitle>Uncategorized Interaction</ItemTitle>
        <RenderFields
          fieldsObject={uncategorizedItemFields}
          interaction={interaction}
          open={open}
        />
      </div>
    )
  }
}

export default UncategorizedItem
