import React, { FunctionComponent } from 'react'
import { useQuery } from 'urql'
import { Spinner } from 'reactstrap'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import LeadGroupUsers from './LeadGroupUsers'
import { REQUEST_POLICY } from 'constants/urql'
import {
  LeadGroupByUserIdDocument as queryDoc,
  Lead_Group_Users,
  Lead_Groups
} from 'graphql/generated'

type LeadGroupComponentPropTypes = {
  userId: string
}

const LeadGroupWrapper: FunctionComponent<React.PropsWithChildren<LeadGroupComponentPropTypes>> = (
  props
) => (
  <ContentSectionCard title={'Lead Group'} padding>
    <LeadGroup {...props} />
  </ContentSectionCard>
)

const LeadGroup: FunctionComponent<React.PropsWithChildren<LeadGroupComponentPropTypes>> = ({
  userId
}) => {
  const [{ data, fetching, error }] = useQuery({
    query: queryDoc,
    variables: { id: userId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    context: React.useMemo(() => ({ suspense: false }), [])
  })

  if (fetching) {
    return <Spinner />
  }

  if (error) {
    return <SC.ErrorMessage>{error.message}</SC.ErrorMessage>
  }

  const leadGroups = data?.lead_group_users?.map((lgu: Lead_Group_Users) => lgu.lead_group)

  const sortedLeadGroups = [
    ...leadGroups?.filter((lg: Lead_Groups) => lg.status === 'active'),
    ...leadGroups?.filter((lg: Lead_Groups) => lg.status === 'inactive')
  ].sort((a, b) => (a.created_at < b.created_at ? 1 : -1))

  const leadGroupId = sortedLeadGroups[0]?.id

  if (!leadGroupId) {
    return <SC.MessageText>No lead group found for user</SC.MessageText>
  }

  return <LeadGroupUsers leadGroupId={leadGroupId} />
}

export default LeadGroupWrapper
