import React from 'react'
import {
  Box,
  Divider,
  useToast,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from '@chakra-ui/react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { MagnifyingGlassIcon, PencilIcon } from '@heroicons/react/20/solid'
import { OccupancyInspectionApiResponse } from '@homevest/types/occupancy-inspections'

import axios from 'lib/axios'
import { Sidepeek } from 'ui'
import { formatProjectTitle } from 'lib/construction-projects'
import {
  OccupancyInspectionFormData,
  validateOccupancyInspectionForm
} from 'lib/occupancy-inspections'
import OccupancyInspectionForm from './OccupancyInspectionForm'
import OccupancyInspectionDetails from './OccupancyInspectionDetails'

const OccupancyInspectionDetailsEditSidepeek = ({
  inspectionId,
  isOpen,
  onSubmit,
  onClose
}: {
  inspectionId?: string
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}) => {
  const toast = useToast()

  const {
    data,
    isFetching,
    refetch: refetchOccupancyInspection
  } = useQuery({
    queryKey: ['getOccupancyInspectionsById', inspectionId],
    queryFn: async () => {
      const response = await axios.get<OccupancyInspectionApiResponse>(
        `/admin/occupancy_inspections/${inspectionId}`
      )
      return response.data
    }
  })

  const { mutate: editOccupancyInspection } = useMutation({
    mutationFn: async (data: OccupancyInspectionFormData) => {
      if (!inspectionId) {
        return
      }
      return axios.put<OccupancyInspectionApiResponse>(
        `/admin/occupancy_inspections/${inspectionId}`,
        data
      )
    },
    onSuccess: () => {
      toast({
        title: 'Occupancy Inspection Edited',
        status: 'success',
        duration: 5000,
        position: 'bottom-right'
      })
      onSubmit()
      refetchOccupancyInspection()
    },
    onError: (error: any) => {
      toast({
        title: 'Error Editing Occupancy Inspection',
        description: error?.message || 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        position: 'bottom-right'
      })
    }
  })

  if (isFetching || !data) {
    return <Spinner />
  }

  return (
    <Sidepeek
      isOpen={isOpen}
      onClose={onClose}
      title={'Occupancy Inspection'}
      subtitle={
        <>
          <div className='text-teal-600'>
            <strong>Project:</strong>{' '}
            {formatProjectTitle(data.project.type, data.project.address, data.project.start_date)}
          </div>
        </>
      }
    >
      <Tabs isFitted variant='solid-rounded' colorScheme='teal'>
        <TabList mb='1em'>
          <Tab>{<MagnifyingGlassIcon className='mr-1 h-4 w-4' />} Details</Tab>
          <Tab>{<PencilIcon className='mr-1 h-4 w-4' />} Edit</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <OccupancyInspectionDetails data={data}></OccupancyInspectionDetails>
          </TabPanel>
          <TabPanel>
            <Box mb={4}>
              <Divider />
            </Box>
            <OccupancyInspectionForm
              initialData={{
                construction_project_id: data.construction_project_id,
                previous_occupancy_inspection_id: data.previous_occupancy_inspection_id,
                primary_municipality_contact_id: data.primary_municipality_contact_id,
                status: data.status,
                result: data.result,
                inspection_at: data.inspection_at,
                certificate_effective_date: data.certificate_effective_date,
                certificate_expiration_date: data.certificate_expiration_date,
                municipality_contacted_at: data.municipality_contacted_at,
                application_submitted_at: data.application_submitted_at,
                payment_submitted_at: data.payment_submitted_at,
                payment_confirmed_at: data.payment_confirmed_at,
                occupant_signature_deadline: data.occupant_signature_deadline,
                occupant_signature_at: data.occupant_signature_at
              }}
              validator={validateOccupancyInspectionForm}
              onSubmit={(data) => editOccupancyInspection(data)}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Sidepeek>
  )
}

export default OccupancyInspectionDetailsEditSidepeek
