"use strict";
exports.LEDGER_TYPES = Object.freeze({
    ASSET: 'asset',
    EQUITY: 'equity',
    EXPENSE: 'expense',
    INCOME: 'income',
    LIABILITY: 'liability'
});
exports.EXTERNAL_SOURCES = Object.freeze({
    BUILDIUM: 'buildium',
    QUICKBOOKS: 'quickbooks',
    QUICKBOOKS_CA: 'quickbooks_ca',
    QUICKBOOKS_WAREHOUSE: 'quickbooks_warehouse'
});
exports.LEDGER_SUBTYPES = Object.freeze({
    [exports.LEDGER_TYPES.ASSET]: Object.freeze({
        CURRENT_ASSET: 'current_asset',
        FIXED_ASSET: 'fixed_asset'
    }),
    [exports.LEDGER_TYPES.EQUITY]: Object.freeze({
        EQUITY: 'equity'
    }),
    [exports.LEDGER_TYPES.EXPENSE]: Object.freeze({
        OPERATING_EXPENSES: 'operating_expenses',
        NON_OPERATING_EXPENSES: 'non_operating_expenses'
    }),
    [exports.LEDGER_TYPES.INCOME]: Object.freeze({
        INCOME: 'income',
        NON_OPERATING_INCOME: 'non_operating_income'
    }),
    [exports.LEDGER_TYPES.LIABILITY]: Object.freeze({
        CURRENT_LIABILITY: 'current_liability',
        LONG_TERM_LIABILITY: 'long_term_liability'
    })
});
exports.REPORT_TYPES = Object.freeze({
    CALCULATOR: 'calculator',
    MANAGEMENT: 'management'
});
