import React, { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  FormControl,
  Checkbox,
  Textarea,
  FormLabel,
  HStack,
  Center,
  Button,
  useToast
} from '@chakra-ui/react'
import { rentals } from '@homevest/utils'

import { StoreState } from 'store'
import { ErrorText } from 'components/Toolkit'
import { useUpup_SetRentalDoNotRenewMutation } from 'graphql/generated'
import { RentalDetailsContext } from '../../RentalDetailsContext'

const { LEASE_END_REASONS } = rentals
interface DoNotRenewFormProps {
  onSubmit: () => void
  onCancel: () => void
}

const DoNotRenewForm: React.FC<React.PropsWithChildren<DoNotRenewFormProps>> = ({
  onSubmit,
  onCancel
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [reason, setReason] = useState('')
  const toast = useToast()

  const [{ fetching, error }, setRentalDoNotRenew] = useUpup_SetRentalDoNotRenewMutation()

  let { rentalData } = useContext(RentalDetailsContext)
  const admin = useSelector((state: StoreState) => state.admin)

  const canSubmit = isConfirmed && reason

  const handleSubmit = async () => {
    if (!canSubmit) {
      return
    }

    await setRentalDoNotRenew({
      id: rentalData?.id,
      do_not_renew_selected_at: new Date(),
      do_not_renew_selected_by_admin_id: admin.id,
      do_not_renew_reason: reason,
      lease_end_reason: LEASE_END_REASONS.DO_NOT_RENEW
    })

    if (error !== undefined) {
      return
    }

    toast({
      title: '"Do Not Renew" recorded successfully',
      description: 'The rental has been successfully marked as "Do Not Renew"',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right'
    })
    onSubmit()
  }

  return (
    <>
      {error !== undefined && <ErrorText>{error.message}</ErrorText>}
      <FormControl isRequired>
        <HStack>
          <Checkbox
            id='confirm-do-not-renew'
            colorScheme='teal'
            size='lg'
            isChecked={isConfirmed}
            isRequired
            onChange={(e) => setIsConfirmed(e.target.checked)}
          />
          <Center>
            <FormLabel htmlFor='confirm-do-not-renew'>Confirm "Do Not Renew"</FormLabel>
          </Center>
        </HStack>
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor='do-not-renew-reason'>Do Not Renew Reason</FormLabel>
        <Textarea
          id='do-not-renew-reason'
          value={reason}
          isRequired
          onChange={(e) => setReason(e.target.value)}
          placeholder='Enter a reason for not renewing this rental'
        />
      </FormControl>

      <HStack>
        <Button colorScheme='red' mr={3} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!canSubmit}
          onClick={handleSubmit}
          isLoading={fetching}
          colorScheme='teal'
        >
          Submit
        </Button>
      </HStack>
    </>
  )
}

export default DoNotRenewForm
