import React, { FunctionComponent } from 'react'
import { format, parse } from 'date-fns'
import { first } from 'lodash'
import { Link } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import {
  useRentalHistoryByPortfolioHomeIdQuery,
  useWorkOrdersFromPortfolioHomeIdQuery,
  useConstructionByPortfolioHomeIdQuery,
  PrimaryRentalUserFragment,
  ConstructionProjectsFragment,
  PortfolioHomesRentalHistoryFragment,
  WorkOrdersHistoryFragment
} from 'graphql/generated'
import { LeftAlignedDataList, DisplayDataType } from 'components/TailwindUIToolkit'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { LINK_COLOR } from 'constants/colors'
import { formatUTCDate } from 'lib/date-time'

type PropTypes = {
  portfolioHomeId: string
}

const parseAndFormatDBDatestring = (datestring: string) =>
  format(parse(datestring, 'yyyy-MM-dd', new Date()), 'PP')

export const SummarySidebar: FunctionComponent<React.PropsWithChildren<PropTypes>> = ({
  portfolioHomeId
}) => {
  const [{ data: rentalData }] = useRentalHistoryByPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  const [{ data: workOrderData }] = useWorkOrdersFromPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  const [{ data: constructionData }] = useConstructionByPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  const sections: {
    label: string
    data: DisplayDataType
  }[] = []

  const currentRental = first(
    rentalData?.portfolio_homes_by_pk?.rentals?.filter((r) => r.status === 'active')
  )

  const currentTenant = first(currentRental?.primary_rental_user)?.user

  if (currentRental && currentTenant) {
    sections.push({
      label: 'Current Tenant',
      data: getDisplayDataForTenant(currentTenant, currentRental)
    })
  }

  const activeWorkOrder = first(
    workOrderData?.portfolio_homes_by_pk?.work_orders.filter(
      (wo) => !(wo.status === 'complete' || wo.status === 'canceled')
    )
  )

  if (activeWorkOrder) {
    sections.push({
      label: 'Active Work Order',
      data: getDisplayDataForWorkOrder(activeWorkOrder)
    })
  }

  const recentConstruction = first(
    constructionData?.portfolio_homes_by_pk?.construction_projects?.sort(
      (c1, c2) => new Date(c2.created_at).getTime() - new Date(c1.created_at).getTime()
    )
  )

  if (recentConstruction) {
    sections.push({
      label: 'Recent Construction',
      data: getDisplayDataForConstructionProject(recentConstruction)
    })
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <div className='mt-4 w-full divide-y divide-gray-200 p-0'>
        <div className='h-0 w-0' />
        {sections.map((section) => (
          <div className='w-full py-6' key={section.label}>
            <div className='mb-3 text-base font-medium text-gray-900'>{section.label}</div>
            <LeftAlignedDataList data={section.data} />
          </div>
        ))}
      </div>
    </React.Suspense>
  )
}

type UserData = PrimaryRentalUserFragment['primary_rental_user'][number]['user']
type RentalData = PortfolioHomesRentalHistoryFragment['rentals'][number]

const getDisplayDataForTenant = (tenant: UserData, rental: RentalData): DisplayDataType => {
  return [
    {
      label: 'Tenant',
      value: `${tenant?.first_name} ${tenant?.last_name}`
    },
    {
      label: 'Lease',
      value: mapContentToBadge(rental.status || '')
    },
    {
      label: 'Email',
      value: first(tenant.primary_email)?.contact_info
    },
    {
      label: 'Phone',
      value: first(tenant.primary_phone)?.contact_info
    },
    {
      label: 'Occupancy',
      value: `${parseAndFormatDBDatestring(rental.occupancy_date)}/${
        rental.move_out_date ? parseAndFormatDBDatestring(rental.move_out_date) : 'Present'
      }`
    }
  ]
}

type WorkOrder = WorkOrdersHistoryFragment

const getDisplayDataForWorkOrder = (workOrder: WorkOrder): DisplayDataType => {
  return [
    {
      label: 'Status',
      value: mapContentToBadge(workOrder.status || '-')
    },
    {
      label: 'Created',
      value: format(new Date(workOrder.created_at), 'PP')
    },
    {
      label: 'Vendor',
      value: workOrder.vendor?.name
    }
  ]
}

type ConstructionProject = ConstructionProjectsFragment

const getDisplayDataForConstructionProject = (
  constructionProject: ConstructionProject
): DisplayDataType => {
  return [
    {
      label: 'Link to project',
      value: (
        <Link
          target='_blank'
          rel='noopener noreferrer'
          to={`/construction/projects/${constructionProject.id}`}
          style={{ color: LINK_COLOR }}
        >
          <ArrowTopRightOnSquareIcon height='20px' />
        </Link>
      )
    },
    {
      label: 'Type',
      value: mapContentToBadge(constructionProject.type, { dot: false })
    },
    {
      label: 'Status',
      value: mapContentToBadge(constructionProject.status)
    },
    {
      label: 'Start Date',
      value: formatUTCDate(constructionProject.start_date, 'MMM do, yyyy')
    }
  ]
}
