import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Text } from '@chakra-ui/react'
import ReportLink from '../ReportLink'

export default function CreditReportDetails({ creditReport }) {
  const creditReportDocumentId = get(creditReport, 'document.id', null)

  if (!creditReport) {
    return (
      <Text marginTop='0.5' fontWeight={'light'}>
        No report run
      </Text>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <ReportLink
        documentId={creditReportDocumentId}
        reportName='Credit Report'
        style={{ flex: 1 }}
      />
      <div style={{ flex: 1 }}>
        <Text fontSize='xs'>OFAC Status Type</Text>
        <Text marginTop={0}>{creditReport.ofac_status_type || 'N/A'}</Text>
      </div>
      <div style={{ flex: 1 }}>
        <Text fontSize='xs'>OFAC Status Result</Text>
        <Text marginTop={0}>{creditReport.ofac_result || 'N/A'}</Text>
      </div>
    </div>
  )
}

CreditReportDetails.propTypes = {
  creditReport: PropTypes.object.isRequired
}
