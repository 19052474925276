import { leadGroups } from '@homevest/utils'

import { LeadGroupUser } from 'types/RentalApplication'
const { LEAD_GROUP_USER_TYPES } = leadGroups

export const sortLeadGroupUsers = (leadGroupUsers: LeadGroupUser[]) => {
  return leadGroupUsers.sort((lgu1, lgu2) => {
    if (lgu1.type === LEAD_GROUP_USER_TYPES.PRIMARY) {
      return -1
    } else if (lgu2.type === LEAD_GROUP_USER_TYPES.PRIMARY) {
      return 1
    }

    if (lgu1.type === LEAD_GROUP_USER_TYPES.TENANT) {
      return -1
    } else if (lgu2.type === LEAD_GROUP_USER_TYPES.TENANT) {
      return 1
    }

    return 0
  })
}
