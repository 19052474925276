import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { documents } from '@homevest/utils'
import ReactLoading from 'react-loading'
import { v4 as uuidv4 } from 'uuid'

import axios from 'lib/axios'

import {
  HeaderText,
  BodyText,
  CircleCheckmarkOutline,
  UploadContainer,
  Modal,
  Dropdown,
  Button
} from 'components/Toolkit'

const { DOCUMENT_TYPES, DOCUMENT_EXTERNAL_SOURCES } = documents

const FINANCIAL_DOC_TYPES = [
  {
    label: 'Identity Document',
    value: DOCUMENT_TYPES.IDENTITY_DOCUMENT
  },
  // These should now be handled on the user income review panel
  // {
  //   label: 'Pay Stubs',
  //   value: DOCUMENT_TYPES.PAY_STUB
  // },
  // {
  //   label: 'Offer Letter',
  //   value: DOCUMENT_TYPES.OFFER_LETTER
  // },
  // {
  //   label: 'Tax Return',
  //   value: DOCUMENT_TYPES.TAX_RETURN
  // },
  {
    label: 'Bank Statement',
    value: DOCUMENT_TYPES.BANK_STATEMENT
  },
  {
    label: 'Supplemental Document',
    value: DOCUMENT_TYPES.SUPPLEMENTAL_DOCUMENT
  },
  {
    label: 'Credit Report',
    value: DOCUMENT_TYPES.CREDIT_REPORT
  },
  {
    label: 'Criminal Report',
    value: DOCUMENT_TYPES.CRIMINAL_REPORT
  },
  {
    label: 'Eviction Report',
    value: DOCUMENT_TYPES.EVICTION_REPORT
  },
  {
    label: 'Other Financial Document',
    value: DOCUMENT_TYPES.OTHER_FINANCIAL_DOCUMENT
  }
]

function FileContainer({ name, isSuccessful, isUploading, remove = () => null }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px dashed #EEEDEA',
        borderRadius: '5px',
        padding: '10px 20px',
        marginTop: '10px'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ lineHeight: '17px', marginRight: '20px' }}>
          {isSuccessful ? <CircleCheckmarkOutline width='20' height='20' /> : null}
          {isUploading ? <ReactLoading type='spin' color='#black' height={20} width={20} /> : null}
        </span>

        <BodyText>{name}</BodyText>
      </div>
      <div>
        {isSuccessful ? (
          <BodyText
            onClick={remove}
            style={{
              textDecoration: 'underline',
              fontWeight: '600',
              color: '#E16B6B',
              cursor: 'pointer'
            }}
          >
            Remove
          </BodyText>
        ) : null}
        {isUploading ? (
          <BodyText
            style={{
              fontWeight: '600',
              color: '#BFBDBB'
            }}
          >
            In progress
          </BodyText>
        ) : null}
      </div>
    </div>
  )
}

FileContainer.propTypes = {
  isSuccessful: PropTypes.bool,
  isUploading: PropTypes.bool,
  name: PropTypes.string,
  remove: PropTypes.func
}

function AddDocument({ userId, close, onFinish }) {
  const [uploadType, setUploadType] = useState('')
  const [uploadId, setUploadId] = useState(uuidv4())
  const [isFinishingUp, setIsFinishingUp] = useState(false)
  const [fileState, setFileState] = useState({
    docType: '',
    isLoading: false,
    downloadUrl: null,
    uploadPath: null,
    resetState: () => null,
    error: null,
    file: null
  })

  return (
    <Modal close={close} hasCloseButton>
      <HeaderText style={{ marginTop: '40px', marginBottom: '20px' }} size='h4'>
        What type of document are you uploading?
      </HeaderText>
      <Dropdown
        onChange={(value) => {
          setUploadType(value)
        }}
        options={FINANCIAL_DOC_TYPES}
        label='Document type'
        value={uploadType}
      />
      {fileState.file ? (
        <div style={{ marginBottom: '40px' }}>
          <FileContainer
            name={(fileState.file && fileState.file.name) || fileState.docType}
            isUploading={fileState.isLoading}
          />
        </div>
      ) : null}
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        {uploadType && (
          <UploadContainer
            hideCss
            onChange={async (newFileState) => {
              let updatedFileState = newFileState
              updatedFileState = { ...newFileState, docType: uploadType }

              if (updatedFileState.downloadUrl) {
                setUploadId(uuidv4())
                setIsFinishingUp(true)

                await axios.post(`/admin/documents`, {
                  user_id: userId,
                  type: updatedFileState.docType,
                  external_id: updatedFileState.uploadPath.substring(1),
                  external_source: DOCUMENT_EXTERNAL_SOURCES.GOOGLE_CLOUD
                })
                await onFinish()
                close()
              } else {
                setFileState(updatedFileState)
              }
            }}
            uploadPath={`/users/${userId}/documents/${uploadType}/${uploadId}`}
          >
            <Button style={{ width: '100%' }} disabled={fileState.isLoading || isFinishingUp}>
              {isFinishingUp || fileState.isLoading ? 'Uploading...' : 'Choose File'}
            </Button>
          </UploadContainer>
        )}

        {!uploadType && (
          <Button disabled style={{ width: '100%' }}>
            Choose File
          </Button>
        )}
      </div>
    </Modal>
  )
}

AddDocument.propTypes = {
  close: PropTypes.func,
  onFinish: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
}

export default memo(AddDocument)
