import { Payment_Methods as PaymentMethod } from 'graphql/generated'

export const generatePaymentMethodDescription = (
  paymentMethod: Pick<PaymentMethod, 'type' | 'mask'>,
  chargeType: string = 'balance'
): string => {
  const pmTypeString = paymentMethod?.type === 'ach' ? 'bank' : 'card'
  const description = paymentMethod
    ? `${pmTypeString} ending in ${paymentMethod?.mask}`
    : 'unknown payment method'

  return `Charge ${chargeType} to ${description}`
}
