import { Box, Button, IconButton } from '@chakra-ui/react'
import { ClockIcon } from '@heroicons/react/20/solid'
import { useRaSnoozesByRaIdQuery, useSoftDeleteRaSnoozeMutation } from 'graphql/generated'
import React from 'react'
import { SnoozeApplicationModal } from './SnoozeApplicationModal'

export const SnoozeApplicationButtonInternal: React.FC<
  React.PropsWithChildren<{
    rentalApplicationId: string
    showText: boolean
  }>
> = ({ rentalApplicationId, showText }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [{ data }] = useRaSnoozesByRaIdQuery({
    variables: { id: rentalApplicationId }
  })

  const [_, softDeleteRaSnooze] = useSoftDeleteRaSnoozeMutation()

  const isSnoozed = (data?.rental_application_manager_application_snoozes.length || 0) !== 0

  const onClick = isSnoozed
    ? () => softDeleteRaSnooze({ rentalApplicationId: rentalApplicationId })
    : () => setIsOpen(true)

  return (
    <>
      <SnoozeApplicationModal
        rentalApplicationId={rentalApplicationId}
        isOpen={isOpen}
        setOpen={setIsOpen}
      />
      {showText ? (
        <Button
          size='sm'
          onClick={onClick}
          aria-label={'Snooze'}
          isActive={isSnoozed}
          variant={isSnoozed ? 'solid' : 'outline'}
          leftIcon={
            <Box h={5} w={5} color='gray.500'>
              <ClockIcon />
            </Box>
          }
        >
          {isSnoozed ? 'Unsnooze' : 'Snooze'}
        </Button>
      ) : (
        <IconButton
          size='sm'
          onClick={onClick}
          aria-label={'Snooze'}
          variant={isSnoozed ? 'solid' : 'outline'}
          icon={
            <Box h={5} w={5} color='gray.500'>
              <ClockIcon />
            </Box>
          }
        />
      )}
    </>
  )
}

export const SnoozeApplicationButton: React.FC<
  React.PropsWithChildren<{
    rentalApplicationId: string
    showText?: boolean
  }>
> = ({ rentalApplicationId, showText = false }) => (
  <React.Suspense fallback={<></>}>
    <SnoozeApplicationButtonInternal
      rentalApplicationId={rentalApplicationId}
      showText={showText}
    />
  </React.Suspense>
)
