import React from 'react'
import { isAfter } from 'date-fns'
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, useToast } from '@chakra-ui/react'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import { renewals } from '@homevest/utils'

import SignSection from 'components/RentRoll/Details/Agreements/SignSection'
import { formatMoney } from 'lib/numbers'
import { Renewal } from 'types/Renewals'

const { RENEWAL_STATUSES, PENDING_RENEWAL_STATUSES } = renewals

const PendingRenewalsTable: React.FC<
  React.PropsWithChildren<{
    renewals: Renewal[]
  }>
> = ({ renewals }) => {
  const toast = useToast()

  const formatDateForTable = (inputDate: string) => format(new Date(inputDate), 'MMM d, Y')
  const sortedRenewals = renewals.sort((a, b) => {
    if ((PENDING_RENEWAL_STATUSES as string[]).includes(a?.status)) {
      return -1
    } else if ((PENDING_RENEWAL_STATUSES as string[]).includes(b?.status)) {
      return 1
    } else {
      return isAfter(new Date(a?.offered_at), new Date(b?.offered_at)) ? -1 : 1
    }
  })

  const onSign = async () => {
    toast({
      title: 'Renewal Signed',
      description: 'The renewal has been signed successfully!',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right'
    })
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Status</Th>
            <Th>Offered At</Th>
            <Th>Start Date</Th>
            <Th>End Date</Th>
            <Th>Rent</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedRenewals.map((renewal) => {
            const customFields = renewal?.agreement?.metadata?.custom_fields ?? {}
            return (
              <Tr key={renewal.id}>
                <Td>{startCase(renewal?.status).replace('Upandup', 'Up&Up')}</Td>
                <Td>{format(new Date(renewal?.offered_at), 'MMM d, Y h:mm a')}</Td>
                <Td>
                  {customFields?.newLeaseStartDate
                    ? formatDateForTable(customFields?.newLeaseStartDate)
                    : '--'}
                </Td>
                <Td>
                  {customFields?.newLeaseEndDate
                    ? formatDateForTable(customFields?.newLeaseEndDate)
                    : '--'}
                </Td>
                <Td>
                  {customFields?.newLeaseRent
                    ? formatMoney(customFields.newLeaseRent, 0, '$')
                    : '--'}
                </Td>
                <Td>
                  {renewal.status === RENEWAL_STATUSES.PENDING_UPANDUP_SIGNATURE && (
                    <SignSection agreementWithSigners={renewal?.agreement} onSign={onSign} />
                  )}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default PendingRenewalsTable
