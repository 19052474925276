"use strict";
exports.ONBOARDING_STATUSES = {
    complete: 'complete',
    pendingHomevestAuthorization: 'pending_homevest_authorization',
    pendingUnderwriting: 'pending_underwriting',
    pendingApplicationReview: 'pending_application_review',
    pendingCustomerAuthorization: 'pending_customer_authorization',
    pendingReview: 'pending_review',
    pendingPropertyReview: 'pending_property_review',
    pendingAgreement: 'pending_agreement',
    canceled: 'canceled'
};
/** Warning: to be deprecated */
exports.onboardingStatuses = exports.ONBOARDING_STATUSES;
