import styled from 'styled-components'
import { Table } from 'reactstrap'

export const ModalBodyContainerStyled = styled.div`
  padding: 5px 10px;
`

export const InfoHeaderStyled = styled.h5`
  text-align: center;
`

export const InfoPaddedContainerStyled = styled.div`
  margin-top: 30px;
`

export const NotesStyled = styled.div`
  color: #999999;
  margin-top: 5px;
`
export const CurrencyStyled = styled.td`
  text-align: right;
`

export const PropertyTableStyled = styled.table`
  margin: 0 auto;

  & > tbody > tr > td {
    padding: 5px;
  }
`

export const FeeInputStyled = styled.div`
  padding: 5px;
`

// Overriding reactstrap
export const TightTableStyled = styled(Table)`
  margin-bottom: 0 !important;
`
