import React, { useState } from 'react'
import { omit } from 'lodash'
import {
  Table,
  Thead,
  Tr,
  Th,
  TableContainer,
  Button,
  Spinner,
  Center,
  VStack,
  HStack,
  Tbody,
  Tfoot,
  Text
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { requiredLeaseDetails } from '@homevest/utils'
import { PetWithId } from '@homevest/types/rental-applications'

import axios from 'lib/axios'
import EditPetsRow from './EditPetsRow'
import AddPetRow from './AddPetRow'
import SkipValidationSwitch from '../../Common/SkipValidationSwitch'
import { isPetValid } from 'lib/required-lease-details'

const { MAX_COUNTS, REQUIRED_LEASE_DETAIL_TYPES } = requiredLeaseDetails

type IndexedPets = Record<string, PetWithId>

type EditPetsProps = {
  rentalApplicationId: string
  currentPets: PetWithId[]
  exitEditMode: () => void
}

const EditPets: React.FC<React.PropsWithChildren<EditPetsProps>> = ({
  currentPets,
  rentalApplicationId,
  exitEditMode
}) => {
  const indexedPets = currentPets.reduce((acc, pet) => {
    acc[pet.id] = pet
    return acc
  }, {} as IndexedPets)

  const [pets, setPets] = useState<IndexedPets>(indexedPets)
  const [skipValidation, setSkipValidation] = useState(false)

  const canSubmit = skipValidation || Object.values(pets).every((pet) => isPetValid(pet))

  const petCount = Object.keys(pets).length
  const maxPetCount = MAX_COUNTS[REQUIRED_LEASE_DETAIL_TYPES.PETS]
  const canAddPet = petCount < maxPetCount

  const queryClient = useQueryClient()

  const { isLoading, mutate: submitPetDetails } = useMutation({
    mutationFn: async (pets: IndexedPets) => {
      await axios.post(`/admin/rental_applications/${rentalApplicationId}/required_lease_details`, {
        type: 'pets',
        data: Object.values(pets),
        skipValidation
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchRequiredLeaseDetails'] })
      exitEditMode()
    }
  })

  const editPet = (petId: string, updatedPet: PetWithId) => {
    setPets({
      ...pets,
      [petId]: updatedPet
    })
  }

  const removePet = (petId: string) => {
    setPets(omit(pets, [petId]))
  }

  const addPet = (pet: PetWithId) => {
    setPets({
      ...pets,
      [pet.id]: pet
    })
  }

  if (isLoading) {
    return (
      <Center marginY={3}>
        <Spinner colorScheme='teal' />
      </Center>
    )
  }

  return (
    <>
      <VStack>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Type</Th>
                <Th>Color</Th>
                <Th>Weight (lbs)</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.values(pets).map((pet) => (
                <EditPetsRow
                  key={pet.id}
                  pet={pet}
                  removePet={removePet}
                  editPet={editPet}
                  skipValidation={skipValidation}
                />
              ))}
            </Tbody>
            <Tfoot>
              <AddPetRow addPet={addPet} canAddPet={canAddPet} skipValidation={skipValidation} />
            </Tfoot>
          </Table>
        </TableContainer>
        {!canAddPet && (
          <Text color='red.500'>A maximum of {maxPetCount} pets can be listed on the lease!</Text>
        )}
        <SkipValidationSwitch
          skipValidation={skipValidation}
          setSkipValidation={setSkipValidation}
        />
        <HStack width='100%' paddingX={3} spacing={3}>
          <Button
            width='100%'
            onClick={() => submitPetDetails(pets)}
            colorScheme='teal'
            disabled={!canSubmit}
          >
            Save
          </Button>
          <Button width='100%' variant='outline' onClick={exitEditMode} colorScheme='red'>
            Cancel
          </Button>
        </HStack>
      </VStack>
    </>
  )
}

export default EditPets
