import React from 'react'
import { Select } from '@chakra-ui/react'
import { startCase } from 'lodash'

const SelectDropdown: React.FC<
  React.PropsWithChildren<{
    id?: string
    options: string[]
    value: string
    onChange: React.ChangeEventHandler<HTMLSelectElement>
  }>
> = ({ id, options, value, onChange }) => {
  return (
    <Select id={id} placeholder='Select...' value={value} onChange={onChange}>
      {options.map((relation) => (
        <option key={relation} value={relation}>
          {startCase(relation)}
        </option>
      ))}
    </Select>
  )
}

export default SelectDropdown
