import React, { FunctionComponent, useEffect, useState } from 'react'
import { hasCapability } from 'lib/admin-perms'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { StoreState, TypedDispatch } from 'store'

import { setErrorMessage, setErrorModalIsOpen } from 'state/modals/actions'

import * as SC from 'components/TailwindUIToolkit/StyledComponents'

import { capabilities } from '@homevest/utils'
import { impersonateAndOpenWeb } from 'lib/impersonation'
const { IMPERSONATOR } = capabilities.CAPABILITY_TYPES

type ImpersonateComponentPropTypes = {
  setErrorModalIsOpen: Function
  setErrorMessage: Function
}

const Impersonate: FunctionComponent<React.PropsWithChildren<ImpersonateComponentPropTypes>> = ({
  setErrorModalIsOpen,
  setErrorMessage
}) => {
  const { userId } = useParams<{ userId: string }>()
  const admin = useSelector((store: StoreState) => store.admin)

  const [isImpersonationAllowed, setIsImpersonationAllowed] = useState(false)

  useEffect(() => {
    setIsImpersonationAllowed(hasCapability(admin, IMPERSONATOR))
  }, [admin.id])

  const impersonateUser = async (userId: string, adminId: string) => {
    try {
      await impersonateAndOpenWeb(userId, adminId)
    } catch (err: any) {
      setErrorMessage(err.message)
      setErrorModalIsOpen(true)
    }
  }

  return isImpersonationAllowed ? (
    <SC.DropDownMenuItem onClick={() => impersonateUser(userId, admin.id)}>
      Impersonate
    </SC.DropDownMenuItem>
  ) : null
}

const mapDispatchToProps = (dispatch: TypedDispatch) => ({
  setErrorModalIsOpen: (mode: boolean) => dispatch(setErrorModalIsOpen(mode)),
  setErrorMessage: (message: string) => dispatch(setErrorMessage(message))
})

export default connect(null, mapDispatchToProps)(Impersonate)
