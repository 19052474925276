export const SET_INITIAL_PATH = 'SET_INITIAL_PATH'

export function setInitialPath(initialPathname, initialSearch) {
  return (dispatch) => {
    return dispatch({
      type: SET_INITIAL_PATH,
      initialPathname,
      initialSearch
    })
  }
}
