import React from 'react'
import { Button, FormControl, FormLabel, Select } from '@chakra-ui/react'
import { userIncomeSources } from '@homevest/utils'

import { useSourceDetails } from './context'
import { SelectFormField } from './common/form'

const { USER_INCOME_SOURCE_TYPES: SOURCE_TYPES } = userIncomeSources

const SourceTypeForm = ({ onContinue }: { onContinue: () => void }) => {
  const context = useSourceDetails()

  if (!context) {
    return null
  }
  const { data, updateField } = context

  const sourceType = data?.type

  const isNewlyEmployed = (data.type === 'employed' && data?.is_newly_employed) ?? false

  return (
    <div className='flex flex-col gap-3'>
      <SelectFormField
        label='Income Source Type'
        fieldName='type'
        optionValues={Object.values(SOURCE_TYPES)}
      />
      {sourceType === SOURCE_TYPES.EMPLOYED && (
        <FormControl isRequired>
          <FormLabel>Does the applicant have pay stubs?</FormLabel>
          <Select
            value={!isNewlyEmployed ? 'yes' : 'no'}
            onChange={(e) => updateField('is_newly_employed', e.target.value === 'no')}
          >
            <option value='' disabled>
              Select an option...
            </option>
            <option value={'yes'}>Yes, they have pay stubs</option>
            <option value={'no'}>No, they have only an offer letter</option>
          </Select>
        </FormControl>
      )}
      <Button w='full' mt='5' onClick={onContinue} colorScheme='teal' disabled={!sourceType}>
        Continue
      </Button>
    </div>
  )
}

export default SourceTypeForm
