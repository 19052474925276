import axios from 'lib/axios'

export const SET_EDIT_MODE = 'SET_EDIT_MODE'
export const SET_PERSONAL_DETAILS = 'SET_PERSONAL_DETAILS'
export const EDIT_FIRST_NAME = 'EDIT_FIRST_NAME'
export const EDIT_LAST_NAME = 'EDIT_LAST_NAME'
export const ADD_PHONE = 'ADD_PHONE'
export const ADD_EMAIL = 'ADD_EMAIL'
export const DELETE_PHONE = 'DELETE_PHONE'
export const DELETE_EMAIL = 'DELETE_EMAIL'
export const SET_PRIMARY_PHONE = 'SET_PRIMARY_PHONE'
export const SET_PRIMARY_EMAIL = 'SET_PRIMARY_EMAIL'
export const EDIT_PHONE = 'EDIT_PHONE'
export const EDIT_EMAIL = 'EDIT_EMAIL'
export const SET_PHONE_VALIDITY = 'SET_PHONE_VALIDITY'
export const SET_EMAIL_VALIDITY = 'SET_EMAIL_VALIDITY'

export const setEditMode = (mode) => ({
  type: SET_EDIT_MODE,
  mode
})

export const setPersonalDetails = (payload) => ({
  type: SET_PERSONAL_DETAILS,
  payload
})

export const editFirstName = (first_name) => ({
  type: EDIT_FIRST_NAME,
  first_name
})

export const editLastName = (last_name) => ({
  type: EDIT_LAST_NAME,
  last_name
})

export const addPhone = () => ({
  type: ADD_PHONE
})

export const addEmail = () => ({
  type: ADD_EMAIL
})

export const deletePhone = (index) => ({
  type: DELETE_PHONE,
  index
})

export const deleteEmail = (index) => ({
  type: DELETE_EMAIL,
  index
})

export const setPrimaryPhone = (index) => ({
  type: SET_PRIMARY_PHONE,
  index
})

export const setPrimaryEmail = (index) => ({
  type: SET_PRIMARY_EMAIL,
  index
})

export const editPhone = (newPhone, index) => ({
  type: EDIT_PHONE,
  newPhone,
  index
})

export const editEmail = (newEmail, index) => ({
  type: EDIT_EMAIL,
  newEmail,
  index
})

export const setEmailValidity = (isValid, index) => ({
  type: SET_EMAIL_VALIDITY,
  isValid,
  index
})

export const setPhoneValidity = (isValid, index) => ({
  type: SET_PHONE_VALIDITY,
  isValid,
  index
})

export const updateUserName = (userId, userName) => {
  return async (dispatch) => {
    try {
      await axios.put(`/admin/users/${userId}`, {
        first_name: userName.first_name,
        last_name: userName.last_name
      })
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const updateUserContactDetails = (userId, contactDetails) => {
  return async (dispatch) => {
    try {
      await axios.put(`/360/users/${userId}/contact-details`, contactDetails)
    } catch (err) {
      throw err
    }
  }
}
