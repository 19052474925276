import React, { FunctionComponent, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { rentalApplications } from '@homevest/utils'
import { startCase } from 'lodash'

import axios from 'lib/axios'

const CANCELATION_REASONS = Object.values(
  rentalApplications.RENTAL_APPLICATION_CANCELATION_REASONS
).map((value) => ({ label: startCase(value), value }))

const CancelApplicationModal: FunctionComponent<
  React.PropsWithChildren<{
    rentalApplicationId: string
    isOpen: boolean
    onCancel: () => void
    onClose: () => void
  }>
> = ({ rentalApplicationId, isOpen, onCancel, onClose }) => {
  const [reason, setReason] = useState<string>('')

  const handleClose = async () => {
    setReason('')
    onClose()
  }

  const handleCancel = async () => {
    await axios.post(`/admin/rental_applications/${rentalApplicationId}/cancel`, {
      cancelation_reason: reason
    })
    onCancel()
    handleClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel application</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor='reason'>Cancelation reason</FormLabel>
            <Select id='reason' defaultValue='' onChange={({ target }) => setReason(target.value)}>
              <option key='default' value='' disabled>
                Pick a reason...
              </option>
              {CANCELATION_REASONS.map((reason) => (
                <option key={reason.value} value={reason.value}>
                  {reason.label}
                </option>
              ))}
            </Select>
            <ModalFooter pt={4}>
              <Button
                type='submit'
                variant='solid'
                bg='red.300'
                mr={3}
                disabled={!reason}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button variant='ghost' onClick={handleClose}>
                Close
              </Button>
            </ModalFooter>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CancelApplicationModal
