import React from 'react'
import axios from 'lib/axios'
import { Helmet } from 'react-helmet'
import { Box, Heading, Stack } from '@chakra-ui/react'
import type { Reunderwrite } from '@homevest/types/rental-reunderwrites'
import { ReunderwritesTable } from './ReunderwritesTable'
import { ReunderwriteModal } from './ReunderwriteModal'

const fetchActiveReunderwrites = async () => {
  return await axios.get<Reunderwrite[]>('/admin/rental_reunderwrites/active')
}

const ReunderwritesView = () => {
  const [selectedReunderwrite, setSelectedReunderwrite] = React.useState<Reunderwrite>()
  const [data, setData] = React.useState<Reunderwrite[]>()
  const [loading, setLoading] = React.useState(false)

  const getData = () => {
    setLoading(true)
    fetchActiveReunderwrites()
      .then((res) => {
        console.log(res.data)
        setData(res.data)
      })
      .finally(() => {
        setLoading(false)
        setSelectedReunderwrite(undefined)
      })
  }

  React.useEffect(getData, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <Helmet>
        <title>Renewal Manager</title>
      </Helmet>
      <Box w='100%' p={1} padding={3}>
        <Stack gap={4}>
          <Heading as='h5' size='lg' width='100%'>
            Underway Reunderwrites
          </Heading>
          <ReunderwritesTable
            reunderwrites={data || []}
            setSelectedReunderwrite={setSelectedReunderwrite}
          />
          <ReunderwriteModal
            rentalId={selectedReunderwrite?.rental_id}
            close={() => setSelectedReunderwrite(undefined)}
          />
        </Stack>
      </Box>
    </>
  )
}

export default ReunderwritesView
