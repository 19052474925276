"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FUND_NAMES = void 0;
exports.FUND_NAMES = Object.freeze({
    FUND_1_CA: 'Fund 1 - CA',
    FUND_1_MO: 'Fund 1 - MO',
    FUND_2: 'Fund 2',
    WAREHOUSE: 'Warehouse',
    GP_SEED_FUND_1: 'GP Seed Fund I, L.P.',
    HREP_III: 'Homevest Real Estate Partners III, L.P.'
});
