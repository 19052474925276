import React from 'react'
import { Button, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import { ArrowPathIcon } from '@heroicons/react/20/solid'

import BasicPageLayout from 'components/TailwindUIToolkit/Layouts/BasicPageLayout'
import { useUnresolvedLatchelTicketDeadLettersQuery } from 'graphql/generated'
import Grid from './Grid'

const LatchelSyncManager = () => {
  const [{ data, fetching }, refetchData] = useUnresolvedLatchelTicketDeadLettersQuery()

  return (
    <BasicPageLayout tabTitle='Latchel Sync Manager'>
      <Stack>
        <Heading>Latchel Sync Manager</Heading>
        <Text>Use this page to view and retry all Latchel events that failed to sync</Text>
        <Stack padding={'3'} spacing='4'>
          <HStack gap='4'>
            <Heading size='md'>Unresolved Sync Errors</Heading>
            <Button
              leftIcon={<ArrowPathIcon className='h-4 w-4' />}
              colorScheme={'teal'}
              size={'sm'}
              variant='outline'
              onClick={() => refetchData({ requestPolicy: 'network-only' })}
            >
              Refresh
            </Button>
          </HStack>
          <Grid
            data={data}
            isFetching={fetching}
            onRetrySync={() => refetchData({ requestPolicy: 'network-only' })}
          />
        </Stack>
      </Stack>
    </BasicPageLayout>
  )
}

export default LatchelSyncManager
