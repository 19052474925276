import React, { FunctionComponent, Suspense, useState } from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'
import SupplementalModal from './SupplementalModal'

type Props = {
  rentalAppId?: string
  filterUserId?: string
  buttonOptions?: ButtonProps
}

const SupplementalDocs: FunctionComponent<React.PropsWithChildren<Props>> = ({
  rentalAppId,
  filterUserId,
  buttonOptions
}) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Button
        type='button'
        size='sm'
        colorScheme={'yellow'}
        onClick={() => setShowModal(true)}
        {...buttonOptions}
      >
        Request Supplemental Documents
      </Button>
      <Suspense fallback={'Loading...'}>
        <SupplementalModal
          rentalAppId={rentalAppId}
          filterUserId={filterUserId}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={() => setShowModal(false)}
        />
      </Suspense>
    </>
  )
}

export default SupplementalDocs
