import React, { memo, useState } from 'react'
import { startCase } from 'lodash'
import { documents, capabilities } from '@homevest/utils'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Heading, Button } from '@chakra-ui/react'

import { BodyText } from 'components/Toolkit'

import ApproveDocumentModal from './approve-document-modal'
import UpdateDocumentModal from './update-document-modal'
import { useSelector } from 'react-redux'
import { hasCapability } from 'lib/admin-perms'
import axios from 'lib/axios'
import SupplementalDocs from 'components/SupplementalDocs'

const { USER_DOC_VIEWER, APPLICATION_REVIEWER } = capabilities.CAPABILITY_TYPES

const { DOCUMENT_STATUSES } = documents

function UserDocuments({
  rentalAppId,
  userId,
  documents,
  isSaving,
  setDocumentReviewStatus,
  updateDocumentReviewStatus,
  addDocument
}) {
  const isDocViewer = useSelector((store) => hasCapability(store.admin, USER_DOC_VIEWER))
  const isReviewer = useSelector((store) => hasCapability(store.admin, APPLICATION_REVIEWER))
  const [approveDocId, setApproveDocId] = useState(null)
  const [updateDocId, setUpdateDocId] = useState(null)

  return (
    <div>
      <div className='align-center mt-4 mb-3 flex flex-wrap'>
        <Heading flexWrap={'nowrap'} size='md' as='h3'>
          Documents
        </Heading>
        {addDocument && (
          <Button
            colorScheme={'teal'}
            variant={'outline'}
            onClick={addDocument}
            style={{ marginLeft: '25px' }}
            size='sm'
          >
            + Add
          </Button>
        )}
        {isReviewer && (
          <div className='ml-2'>
            <SupplementalDocs rentalAppId={rentalAppId} filterUserId={userId} />
          </div>
        )}
      </div>
      {documents.length === 0 && (
        <div>
          <BodyText>No documents uploaded! 📑</BodyText>
        </div>
      )}
      {documents.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>
                <BodyText weight='600' size='s'>
                  Name
                </BodyText>
              </th>
              <th>
                <BodyText weight='600' size='s'>
                  Status
                </BodyText>
              </th>
              <th style={{ padding: '0 10px' }}>
                <BodyText weight='600' size='s'>
                  Uploaded
                </BodyText>
              </th>
              <th>
                <BodyText weight='600' size='s'>
                  Reviewed
                </BodyText>
              </th>
              <th style={{ padding: '0 10px' }}>
                <BodyText weight='600' size='s'>
                  Reviewed By
                </BodyText>
              </th>
              <th>
                <BodyText weight='600' size='s'>
                  Notes
                </BodyText>
              </th>
            </tr>
          </thead>
          <tbody>
            {documents
              .sort((a, b) => {
                if (a.status < b.status) {
                  return -1
                } else if (a.status > b.status) {
                  return 1
                } else {
                  return a.type < b.type ? -1 : 1
                }
              })
              .map((doc) => (
                <tr key={doc.id}>
                  <td style={{ paddingRight: '10px' }}>
                    {' '}
                    <BodyText size='s'>
                      {isDocViewer ? (
                        <Button
                          variant='link'
                          textColor='black'
                          fontWeight='medium'
                          fontSize='sm'
                          onClick={async () => {
                            const res = await axios.get(`/admin/documents/${doc.id}/url`)

                            if (res.data.url) {
                              window.open(res.data.url, '_blank')
                            }
                          }}
                        >
                          {doc.friendly_name}
                        </Button>
                      ) : (
                        doc.friendly_name
                      )}
                    </BodyText>
                  </td>
                  <td>
                    <BodyText size='s'>{startCase(doc.status)}</BodyText>
                  </td>
                  <td style={{ padding: '0 10px' }}>
                    <BodyText size='s'>{moment(doc.created_at).format('YYYY-MM-DD')}</BodyText>
                  </td>
                  <td>
                    <BodyText size='s'>
                      {doc.reviewed_at ? moment(doc.reviewed_at).format('YYYY-MM-DD') : 'N/A'}
                    </BodyText>
                  </td>
                  <td style={{ padding: '0 10px' }}>
                    <BodyText size='s'>
                      {doc.reviewed_by
                        ? `${doc.reviewed_by.first_name} ${doc.reviewed_by.last_name}`
                        : 'N/A'}
                    </BodyText>
                  </td>
                  <td>
                    <BodyText size='s'>{doc.review_notes ? `${doc.review_notes}` : 'N/A'}</BodyText>
                  </td>

                  <td style={{ padding: '0 10px 10px 10px' }}>
                    {setDocumentReviewStatus &&
                      isDocViewer &&
                      doc.status !== DOCUMENT_STATUSES.VALID && (
                        <Button
                          disabled={isSaving}
                          size='xs'
                          colorScheme={'teal'}
                          style={{ whiteSpace: 'nowrap' }}
                          onClick={() => setApproveDocId(doc.id)}
                        >
                          Approve
                        </Button>
                      )}
                    {updateDocumentReviewStatus &&
                      isDocViewer &&
                      doc.status === DOCUMENT_STATUSES.VALID && (
                        <Button
                          disabled={isSaving}
                          colorScheme={'teal'}
                          size='xs'
                          style={{ whiteSpace: 'nowrap' }}
                          onClick={() => setUpdateDocId(doc.id)}
                        >
                          Edit
                        </Button>
                      )}
                  </td>
                  <td style={{ padding: '0 10px 10px 0px' }}>
                    {setDocumentReviewStatus && isDocViewer && (
                      <Button
                        disabled={isSaving}
                        size='xs'
                        variant='outline'
                        colorScheme='red'
                        style={{ whiteSpace: 'nowrap' }}
                        onClick={() => setDocumentReviewStatus(doc.id, DOCUMENT_STATUSES.INVALID)}
                      >
                        Reject
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {approveDocId ? (
        <ApproveDocumentModal
          close={() => setApproveDocId(null)}
          approve={(notes) => {
            setDocumentReviewStatus(approveDocId, DOCUMENT_STATUSES.VALID, notes || undefined)
            setApproveDocId(null)
          }}
        />
      ) : null}

      {updateDocId ? (
        <UpdateDocumentModal
          close={() => setUpdateDocId(null)}
          document={documents.find((i) => i.id === updateDocId)}
          update={(notes) => {
            updateDocumentReviewStatus(updateDocId, notes)
            setUpdateDocId(null)
          }}
        />
      ) : null}
    </div>
  )
}

UserDocuments.propTypes = {
  documents: PropTypes.array.isRequired,
  isSaving: PropTypes.bool.isRequired,
  updateDocumentReviewStatus: PropTypes.func,
  setDocumentReviewStatus: PropTypes.func,
  addDocument: PropTypes.func
}

export default memo(UserDocuments)
