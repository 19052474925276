import React from 'react'

import {
  Modal,
  ModalOverlay,
  Button,
  Text,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalHeader,
  UnorderedList,
  ListItem,
  Stack,
  Divider,
  Flex,
  Spacer
} from '@chakra-ui/react'

import { formatMoney } from 'lib/numbers'
import { RenewalOffersByTerm } from 'lib/renewals'

interface SubmitConfirmationModalProps {
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
  renewalOffers: RenewalOffersByTerm
  submitRenewalOffers: () => void
}

const SubmitConfirmationModal: React.FC<React.PropsWithChildren<SubmitConfirmationModalProps>> = ({
  isModalOpen,
  setIsModalOpen,
  renewalOffers,
  submitRenewalOffers
}) => {
  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Submit Renewal Offers</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <Text>Are you sure you want to submit these renewal offers?</Text>
            <UnorderedList paddingLeft={5} paddingRight={5}>
              {Object.values(renewalOffers)
                .sort((a, b) => b.term - a.term)
                .map((renewalOffer) => (
                  <ListItem key={renewalOffer.term}>
                    <b>
                      {renewalOffer.isMonthToMonth
                        ? 'Month to Month'
                        : `${renewalOffer.term} months`}
                      :
                    </b>{' '}
                    {formatMoney(renewalOffer.price, 0, '$')}
                  </ListItem>
                ))}
            </UnorderedList>
            <Text>Clicking submit will send an email to the tenant and cannot be reversed!</Text>
          </Stack>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Flex flexDirection='row'>
            <Button mr={5} colorScheme='red' onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Spacer />
            <Button
              colorScheme='teal'
              variant='outline'
              onClick={() => {
                submitRenewalOffers()
                setIsModalOpen(false)
              }}
            >
              Submit
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SubmitConfirmationModal
