import React from 'react'
import { Helmet } from 'react-helmet'
import { Box, Heading, Stack, Text } from '@chakra-ui/react'
import { PendingRenewal } from '@homevest/types/renewals'
import PendingRenewalsTable from './PendingRenewalsTable'
import axios from 'lib/axios'
import { SelectedPendingRenewalView } from './SelectedPendingRenewalView'

async function fetchUpcomingRentals() {
  return await axios.get<PendingRenewal[]>('/admin/renewals/pending')
}

const RenewalManagerPendingView = () => {
  const [selectedRenewal, setSelectedRenewal] = React.useState<PendingRenewal>()
  const [data, setData] = React.useState<PendingRenewal[]>()
  const [loading, setLoading] = React.useState(false)

  const getData = () => {
    setLoading(true)
    fetchUpcomingRentals()
      .then((res) => {
        console.log(res.data)
        setData(res.data)
      })
      .finally(() => {
        setLoading(false)
        setSelectedRenewal(undefined)
      })
  }

  React.useEffect(getData, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <Helmet>
        <title>Renewal Manager</title>
      </Helmet>
      <Box w='100%' p={1} padding={3}>
        <Stack gap={4}>
          <Heading as='h5' size='lg' width='100%'>
            Pending Renewals
          </Heading>
          {!selectedRenewal && <Text as='i'>Click on a row to view a rental's renewal data</Text>}
          <PendingRenewalsTable data={data || []} setSelectedRental={setSelectedRenewal} />
          <SelectedPendingRenewalView onAction={getData} renewal={selectedRenewal} />
        </Stack>
      </Box>
    </>
  )
}

export default RenewalManagerPendingView
