import React, { type ReactNode, useState, useEffect, useMemo, useContext } from 'react'
import axios from 'lib/axios'
import {
  Stack,
  Button,
  InputGroup,
  Input,
  Select,
  InputLeftAddon,
  Text,
  InputLeftElement,
  HStack,
  IconButton,
  Flex,
  Icon,
  Alert,
  AlertIcon,
  Center
} from '@chakra-ui/react'
import { startCase } from 'lodash'
import { useSelector } from 'react-redux'

import { GreenCheck, RedXIcon, TrashIcon } from 'components/TailwindUIToolkit/StyledComponents'
import { PAYMENT_CATEGORIES } from 'constants/payments'
import {
  type Upup_LiabilityTypesQuery,
  useBankAccountsQuery,
  useRentalUsersWithPaymentMethodsQuery
} from 'graphql/generated'
import { RentalDetailsContext } from '../RentalDetailsContext'
import { formatMoney } from 'lib/numbers'
import { LiabilityTypesSelect } from '../Dropdowns/LiabilityTypesMultipleSelect'
import {
  CheckDetails,
  PaymentBackfillDefaultAllocationParams,
  PaymentBackfillDefaultAllocationResult,
  SubmitCheckBackfillWithAllocationsPayload,
  SubmitCheckBackfillWithAllocationsResult
} from '@homevest/types/check-deposits'
import { getLlc } from 'lib/rentals'
import { StoreState } from 'store'

type FormItems = { title: string; input: ReactNode }[]

export type Allocation = {
  amount: number
  liabilityTypeId: string
  key: string | number
}

const BACKFILL_PAYMENT_METHODS = {
  CHECK: 'check',
  RENTAL_ASSISTANCE: 'rental_assistance'
} as const

export default function BackfillModalForm({
  close = () => {},
  rentalId
}: {
  close?: () => void
  rentalId: string
}) {
  const { rentalData, liabilityTypes } = useContext(RentalDetailsContext)
  const [bankAccountsQuery] = useBankAccountsQuery()
  const [rentalUsersQuery] = useRentalUsersWithPaymentMethodsQuery({ variables: { rentalId } })

  const admin = useSelector((state: StoreState) => state.admin)

  const [userId, setUserId] = useState<string>()
  const [paymentMethod, setPaymentMethod] = useState<string>('check')
  const [paymentDateString, setPaymentDateString] = useState<string>()
  const [availableOnDateString, setAvailableOnDateString] = useState<string>()
  const [paymentAmount, setPaymentAmount] = useState<number>()
  const [imageUrl, setImageUrl] = useState<string>()
  const [selectedLiabilityTypeIds, setSelectedLiabilityTypeIds] = useState<string[]>(
    liabilityTypes.map(({ id }) => id)
  )
  const [allocations, setAllocations] = useState<Allocation[]>([])
  const [depositedBankAccountId, setDepositedBankAccountId] = useState<string>()
  const [expectedBankAccountId, setExpectedBankAccountId] = useState<string>()
  const [allocationsFetching, setAllocationsFetching] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const paymentDetailsAreFilled = !!(
    paymentMethod &&
    userId &&
    paymentDateString &&
    availableOnDateString &&
    paymentAmount &&
    (paymentMethod === BACKFILL_PAYMENT_METHODS.CHECK ? !!imageUrl : true) &&
    depositedBankAccountId
  )

  const rentalUsers = useMemo(
    () => rentalUsersQuery.data?.rentals_by_pk?.rental_users || [],
    [rentalUsersQuery.data]
  )

  useEffect(() => {
    setUserId(rentalUsers.find((ru) => ru.role === 'primary')?.user.id)
  }, [rentalUsers])

  const llcs = useMemo(
    () =>
      bankAccountsQuery.data?.funds.flatMap((f) => f.fund_llcs.flatMap((fllc) => fllc.llc)) || [],
    [bankAccountsQuery.data]
  )

  const accounts = useMemo(
    () =>
      llcs
        .flatMap(({ ledger_account }) => (!!ledger_account ? [ledger_account] : []))
        // dedupe accounts list
        .filter((acc, idx, arr) => !arr.slice(idx + 1).find((a) => a.id === acc.id)),
    [llcs]
  )

  const llcId = useMemo(() => getLlc(rentalData)?.id as string | undefined, [rentalData])

  useEffect(() => {
    const llcPropertyLedgerAccountId = llcs.find((l) => l.id === llcId)?.ledger_account?.id as
      | string
      | undefined
    setExpectedBankAccountId(llcPropertyLedgerAccountId)
  }, [llcId, llcs])

  const filteredLiabilityTypes = useMemo(
    () =>
      liabilityTypes.filter(
        (lt) =>
          !(
            paymentMethod === 'rental_assistance' &&
            lt.payment_category === PAYMENT_CATEGORIES.OPTION_PREMIUM
          )
      ),
    [paymentMethod, liabilityTypes]
  )

  useEffect(() => {
    const filteredIds = filteredLiabilityTypes.map((lt) => lt.id)
    setSelectedLiabilityTypeIds((selectedLiabilityTypeIds) =>
      selectedLiabilityTypeIds.filter((id) => filteredIds.includes(id))
    )
  }, [filteredLiabilityTypes])

  const details: ValidationDetails = {
    paymentAmount,
    allocations,
    depositedBankAccountId,
    expectedBankAccountId,
    paymentDetailsAreFilled
  }

  const { valid: allDetailsAreValid, text: validationText } = validateDetailsWithText(details)

  const getDefaultPaymentAllocations = async () => {
    if (!paymentAmount || paymentAmount <= 0) {
      console.error('invalid payment amount')
      return
    }

    setAllocationsFetching(true)

    const requestData: PaymentBackfillDefaultAllocationParams = {
      rentalId,
      paymentAmount,
      validLiabilityTypeIds: selectedLiabilityTypeIds.join(',')
    }

    const res = await axios.get<PaymentBackfillDefaultAllocationResult>(
      `/admin/check_deposits/default_backfill_allocations`,
      {
        params: requestData
      }
    )

    if (res.data.status === 'success') {
      setAllocations(res.data.allocations.map((a) => ({ ...a, key: Math.random() })))
    }

    if (res.data.status === 'error') {
      setErrorMessage(res.data.message)
    }

    setAllocationsFetching(false)
  }

  const submitPaymentBackfill = async () => {
    if (!allDetailsAreValid || !paymentDetailsAreFilled) {
      console.error('invalid allocation details')
      return
    }

    const checkDetails: CheckDetails = {
      paymentMethod,
      paymentDateString,
      availableOnDateString,
      paymentAmount,
      imageUrl,
      bankAccountId: depositedBankAccountId,
      userId,
      submittedByAdminId: admin.id
    }

    const payload: SubmitCheckBackfillWithAllocationsPayload = {
      allocations,
      checkDetails,
      rentalId
    }

    setSubmitting(true)

    const result = await axios.post<SubmitCheckBackfillWithAllocationsResult>(
      `/admin/check_deposits/submit_backfill`,
      payload
    )

    setSubmitting(false)

    if (result.data.status === 'success') {
      setSubmitted(true)
    } else {
      console.error(result)
      setErrorMessage(result.data.message)
      return
    }
  }

  const paymentDetailsFormItems: FormItems = [
    { title: 'rental', input: <Input value={rentalId} readOnly /> },
    {
      title: 'user',
      input: (
        <Select
          onChange={(e) => setUserId(e.currentTarget.value)}
          value={userId}
          roundedLeft='none'
        >
          {rentalUsers.map((ru) => (
            <option value={ru.user.id} key={ru.user.id}>
              {`${ru.user.first_name} ${ru.user.last_name}`}
            </option>
          ))}
        </Select>
      )
    },
    {
      title: 'payment method',
      input: (
        <Select
          onChange={(e) => {
            setPaymentMethod(e.currentTarget.value)
          }}
          value={paymentMethod}
          roundedLeft='none'
        >
          {Object.values(BACKFILL_PAYMENT_METHODS).map((pm) => (
            <option value={pm} key={pm}>
              {startCase(pm)}
            </option>
          ))}
        </Select>
      )
    },
    {
      title: 'payment date',
      input: (
        <Input
          type='date'
          value={paymentDateString}
          isRequired
          onChange={(e) => setPaymentDateString(e.currentTarget.value)}
        />
      )
    },
    {
      title: 'available on',
      input: (
        <Input
          type='date'
          value={availableOnDateString}
          isRequired
          onChange={(e) => setAvailableOnDateString(e.currentTarget.value)}
        />
      )
    },
    {
      title: 'payment amount',
      input: (
        <InputGroup>
          <InputLeftElement>$</InputLeftElement>
          <Input
            roundedLeft='none'
            type='number'
            step={0.01}
            value={paymentAmount}
            isRequired
            onChange={(e) => setPaymentAmount(+Number(e.currentTarget.value).toFixed(2))}
            placeholder='1,000,000'
          />
        </InputGroup>
      )
    },
    {
      title: 'image upload',
      input: (
        <Input
          type='url'
          value={imageUrl}
          placeholder='https://image.google.com/abcdxyz'
          onChange={(e) => setImageUrl(e.currentTarget.value)}
        />
      )
    },
    {
      title: 'expected account',
      input: (
        <Input
          readOnly
          roundedLeft='none'
          value={
            accounts.find((a) => a.id === expectedBankAccountId)?.name ??
            'No Default Bank Account Found'
          }
        />
      )
    },
    {
      title: 'bank account',
      input: (
        <Select
          onChange={(e) => setDepositedBankAccountId(e.currentTarget.value)}
          value={depositedBankAccountId || 'default'}
          roundedLeft='none'
        >
          <option disabled value='default'>
            Select a Bank Account
          </option>
          {accounts.map((account) => (
            <option key={account.id} value={account.id}>
              {account.name}
            </option>
          ))}
        </Select>
      )
    }
  ]

  if (submitted) {
    return (
      <Center>
        <Stack spacing={4}>
          <Text fontSize='32px'>Backfill successfully submitted &#128077;</Text>
          <Button onClick={close}>Close</Button>
        </Stack>
      </Center>
    )
  }

  return (
    <Stack>
      {/* Payment Detail Inputs */}
      <Stack>
        <Text textTransform='uppercase' letterSpacing='wider' fontWeight='semibold'>
          Payment Details
        </Text>
        {paymentDetailsFormItems.map(({ title, input }) => (
          <InputGroup key={title}>
            <InputLeftAddon textTransform='capitalize' w={48}>
              {title}
            </InputLeftAddon>
            {input}
          </InputGroup>
        ))}
        <HStack>
          <Button
            onClick={async () => await getDefaultPaymentAllocations()}
            disabled={!paymentDetailsAreFilled}
            colorScheme='blue'
            flexGrow={1}
            isLoading={allocationsFetching}
          >
            Generate Default Allocations
          </Button>
          <LiabilityTypesSelect
            filter={(lt) => filteredLiabilityTypes.map(({ id }) => id).includes(lt.id)}
            onChange={setSelectedLiabilityTypeIds}
            selectedLiabilityTypeIds={selectedLiabilityTypeIds}
          />
        </HStack>
      </Stack>
      {/* Allocation Details */}
      <Stack marginTop={12}>
        <Text textTransform='uppercase' letterSpacing='wider' fontWeight='semibold'>
          Allocations
        </Text>
        <AllocationsDetails
          availableLiabilityTypes={filteredLiabilityTypes}
          setAllocations={setAllocations}
          allocations={allocations}
        />
        {/* Create New Allocation Button */}
        <Button
          colorScheme='gray'
          onClick={() =>
            setAllocations((allocations) => [
              ...allocations,
              { amount: 0, liabilityTypeId: filteredLiabilityTypes[0].id, key: Math.random() }
            ])
          }
        >
          +
        </Button>
        <ValidPaymentAmountIndicator valid={allDetailsAreValid} text={validationText} />
        {/* Submit Backfill Button */}
        <Button
          onClick={submitPaymentBackfill}
          disabled={!allDetailsAreValid || submitting}
          colorScheme='blue'
          isLoading={submitting}
        >
          Submit Backfill
        </Button>
        {errorMessage && (
          <Alert status='error'>
            <AlertIcon />
            {errorMessage}
          </Alert>
        )}
      </Stack>
    </Stack>
  )
}

const ValidPaymentAmountIndicator = ({ valid, text }: { valid: boolean; text: string }) => {
  return (
    <HStack h='10'>
      <Flex
        h='full'
        rounded='md'
        w='50%'
        justifyContent='center'
        alignItems='center'
        backgroundColor={valid ? 'green.300' : 'red.300'}
      >
        {valid ? <Icon as={GreenCheck} /> : <Icon as={RedXIcon} />}
      </Flex>
      <Flex w='50%' h='full' justifyContent='center' alignItems='center'>
        <div>{text}</div>
      </Flex>
    </HStack>
  )
}

const AllocationsDetails = ({
  allocations,
  setAllocations,
  availableLiabilityTypes
}: {
  allocations: Allocation[]
  setAllocations: (fn: (allocations: Allocation[]) => Allocation[]) => void
  availableLiabilityTypes: Upup_LiabilityTypesQuery['liability_types']
}) => {
  return (
    <>
      {allocations.map((a, idx) => (
        <HStack key={a.key}>
          {/* Category Selector */}
          <InputGroup key={`${a.liabilityTypeId}+${a.amount}`}>
            <InputLeftAddon>Category</InputLeftAddon>
            <Select
              roundedLeft='none'
              defaultValue={a?.liabilityTypeId}
              onChange={(e) =>
                setAllocations((allocations) => {
                  const copy = [...allocations]
                  copy[idx] = { ...a, liabilityTypeId: e.target.value }
                  return copy
                })
              }
            >
              {availableLiabilityTypes.map((lt) => (
                <option key={lt.id} value={lt.id}>
                  {startCase(lt.name)}
                </option>
              ))}
            </Select>
          </InputGroup>
          {/* Amount Input */}
          <InputGroup>
            <InputLeftAddon>Amount</InputLeftAddon>
            <InputGroup>
              <InputLeftElement>$</InputLeftElement>
              <Input
                defaultValue={a.amount}
                type='number'
                step={0.01}
                onChange={(e) =>
                  setAllocations((allocations) => {
                    const copy = [...allocations]
                    copy[idx] = { ...a, amount: Number(parseFloat(e.target.value).toFixed(2)) }
                    return copy
                  })
                }
                roundedLeft='none'
              />
            </InputGroup>
          </InputGroup>
          {/* Delete Button */}
          <IconButton
            aria-label='delete'
            icon={<TrashIcon />}
            onClick={() =>
              setAllocations((allocations) => {
                const copy = [...allocations]
                copy.splice(idx, 1)
                return copy
              })
            }
          />
        </HStack>
      ))}
    </>
  )
}

type ValidationDetails = {
  paymentAmount?: number
  allocations: Allocation[]
  depositedBankAccountId?: string
  expectedBankAccountId?: string
  paymentDetailsAreFilled: boolean
}

function validateDetailsWithText({
  paymentAmount,
  allocations,
  expectedBankAccountId,
  depositedBankAccountId,
  paymentDetailsAreFilled
}: ValidationDetails): { valid: boolean; text: string } {
  const allocationsSum = allocations.reduce((sum, allocation) => sum + (allocation.amount || 0), 0)

  if (!paymentAmount) {
    return { valid: false, text: 'please input a payment amount' }
  }

  if (!depositedBankAccountId) {
    return { valid: false, text: 'please select a bank account' }
  }

  if (!paymentDetailsAreFilled) {
    return { valid: false, text: 'please fill out all relevant payment details' }
  }

  const allocationsSumNote = `$ ${formatMoney(allocationsSum)} / ${formatMoney(paymentAmount)}`
  const bankAccountsMismatchNote =
    expectedBankAccountId && depositedBankAccountId !== expectedBankAccountId
      ? ' | bank accounds do not match'
      : ''

  return {
    valid: Math.round(allocationsSum * 100) === Math.round(paymentAmount * 100),
    text: allocationsSumNote + bankAccountsMismatchNote
  }
}
