import React from 'react'
import { Phone } from 'styled-icons/heroicons-solid'
import { format } from 'date-fns'
import { types } from '@homevest/timelines'
import { TimelineEventContentRenderer, TimelineEventDisplayTemplate } from '../types'
import { SC } from 'components/TailwindUIToolkit'
import { formatPersonalDetails } from 'components/CustomerProfile/formats'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'

type TimelineEventType = types.CallTimelineEvent

const PreviewContent: TimelineEventContentRenderer<TimelineEventType> = ({ event, customer }) => {
  let from, to, customerNumber, ourNumber
  if (event.direction === 'out') {
    customerNumber = formatPersonalDetails(event.toPhone || '')
    ourNumber = formatPersonalDetails(event.fromPhone || '')
    from = event.adminName ?? ourNumber
    to = customerNumber
  } else {
    customerNumber = formatPersonalDetails(event.fromPhone || '')
    ourNumber = formatPersonalDetails(event.toPhone || '')
    from = customer?.firstName + `(${customerNumber})`
    to = ourNumber
  }

  return (
    <div>
      <SC.MessageText>
        <SC.InlineBold>{from}</SC.InlineBold> called {}
        <SC.InlineBoldClickable
          onClick={(e) => e.stopPropagation()}
          href={event.messageLink}
          rel='noopener noreferrer'
          target='_blank'
        >
          {to}
        </SC.InlineBoldClickable>
      </SC.MessageText>
      {mapContentToBadge(event.status)}
    </div>
  )
}

const ExpandedContent: TimelineEventContentRenderer<TimelineEventType> = ({ event }) => {
  let customerNumber, ourNumber
  if (event.direction === 'out') {
    customerNumber = formatPersonalDetails(event.toPhone || '')
    ourNumber = formatPersonalDetails(event.fromPhone || '')
  } else {
    customerNumber = formatPersonalDetails(event.fromPhone || '')
    ourNumber = formatPersonalDetails(event.toPhone || '')
  }

  return (
    <div>
      <SC.EventSubheading>Call</SC.EventSubheading>
      {event.recordingLink ? (
        <>
          <SC.MessageText>Recording</SC.MessageText>
          <a
            href={event.recordingLink}
            onClick={(e) => e.stopPropagation()}
            target='_blank'
            rel='noopener noreferrer'
          >
            Call recording
          </a>
        </>
      ) : null}
      {event.voicemailLink ? (
        <SC.Subsection>
          <SC.MessageText>Recording</SC.MessageText>
          <a
            href={event.voicemailLink}
            onClick={(e) => e.stopPropagation()}
            target='_blank'
            rel='noopener noreferrer'
          >
            Voicemail recording
          </a>
        </SC.Subsection>
      ) : null}
      <SC.Subsection>
        <SC.DescriptionText>
          Customer number <SC.InlineBold>{customerNumber}</SC.InlineBold>
        </SC.DescriptionText>
        <SC.DescriptionText>
          Up&Up number <SC.InlineBold>{ourNumber}</SC.InlineBold>
        </SC.DescriptionText>
        <SC.DescriptionText>
          Call duration <SC.InlineBold>{event.duration}</SC.InlineBold>
        </SC.DescriptionText>
        <SC.DescriptionText>
          Call started <SC.InlineBold>{format(new Date(event.eventTimeAt), 'PPppp')}</SC.InlineBold>
        </SC.DescriptionText>
      </SC.Subsection>
    </div>
  )
}

export const CallEventDisplayTemplate: TimelineEventDisplayTemplate<TimelineEventType> = {
  icon: Phone,
  iconColor: '#6366F1',
  previewContent: PreviewContent,
  expandedContent: ExpandedContent
}
