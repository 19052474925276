import React, { useState, useEffect, useContext } from 'react'
import { Text, VStack, Button } from '@chakra-ui/react'

import axios from 'lib/axios'
import { RentalDetailsContext } from '../RentalDetailsContext'
import ConfirmationModal from './ConfirmationModal'

const IssuePOAAmendmentView = () => {
  const { rentalData: rental } = useContext(RentalDetailsContext)
  const [needsPOAAmendment, setNeedsPOAAmendment] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const rentalId = rental?.id
  const isRentalActive = rental?.status === 'active' && rental?.move_out_date === null
  const agreements = rental?.pending_agreements

  const checkIfPOAAmendmentNeeded = async (rentalId?: string) => {
    const response = await axios.get(`/admin/rentals/${rentalId}/needs_poa_amendment`)

    setNeedsPOAAmendment(response.data.needs_poa_amendment)
  }

  useEffect(() => {
    if (!rentalId || !isRentalActive) {
      return
    }
    try {
      checkIfPOAAmendmentNeeded(rentalId)
    } catch (error) {
      console.error(error)
    }
  }, [rentalId, isRentalActive, agreements])

  if (!rental || !isRentalActive || !needsPOAAmendment) {
    return null
  }

  return (
    <div>
      <VStack align={'flex-start'}>
        <ConfirmationModal isOpen={isModalOpen} close={() => setIsModalOpen(false)} />
        <Button colorScheme='teal' variant={'outline'} onClick={() => setIsModalOpen(true)}>
          {' '}
          Issue POA Amendment{' '}
        </Button>
        <Text fontSize='sm' color='gray.500' as={'i'}>
          This rental is still on the legacy POA. To issue a POA amendment, click the button above.{' '}
        </Text>
      </VStack>
    </div>
  )
}

export default IssuePOAAmendmentView
