import React, { FunctionComponent } from 'react'
import { Button } from '@chakra-ui/react'
import { Check } from 'styled-icons/heroicons-solid'
import { XMarkIcon, DocumentArrowUpIcon } from '@heroicons/react/20/solid'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import SupplementalDocs from 'components/SupplementalDocs'

type Props = {
  rentalAppId: string
  rentalStatus: string
  isSaving: boolean
  onNeedsCosigner: () => void | Promise<void>
  onNeedsCoapplicant: () => void | Promise<void>
  onAccept: () => void | Promise<void>
}

const Review: FunctionComponent<React.PropsWithChildren<Props>> = ({
  rentalAppId,
  isSaving,
  onNeedsCosigner,
  onNeedsCoapplicant,
  onAccept
}) => (
  <ContentSectionCard collapsable={false} title='Initial Document Review' padding>
    <div className='flex flex-row flex-wrap gap-4'>
      <Button
        rightIcon={<XMarkIcon className='h-4 w-4' />}
        colorScheme='gray'
        disabled={isSaving}
        onClick={onNeedsCosigner}
        size='md'
      >
        Needs Co-signer
      </Button>
      <Button
        rightIcon={<XMarkIcon className='h-4 w-4' />}
        colorScheme='gray'
        disabled={isSaving}
        onClick={onNeedsCoapplicant}
        size='md'
      >
        Needs Co-applicant
      </Button>
      <Button
        rightIcon={<Check className='h-4 w-4' />}
        colorScheme={'teal'}
        disabled={isSaving}
        onClick={onAccept}
        size='md'
      >
        Looks Good
      </Button>
      <SupplementalDocs
        rentalAppId={rentalAppId}
        buttonOptions={{
          size: 'md',
          rightIcon: <DocumentArrowUpIcon className='h-4 w-4' />
        }}
      />
    </div>
  </ContentSectionCard>
)

export default Review
