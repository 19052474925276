import axios from '../../lib/axios'
import { formatPhoneNumber } from '../../lib/phone'

export const RECEIVED_PROPERTIES = 'RECEIVED_PROPERTIES'
export const RECEIVED_PROPERTY = 'RECEIVED_PROPERTY'
export const RECEIVED_PROPERTY_SELLER_AGENTS = 'RECEIVED_PROPERTY_SELLER_AGENTS'
export const CLEAR_PROPERTY_SELLER_AGENT_DATA = 'CLEAR_PROPERTY_SELLER_AGENT_DATA'
export const RECEIVED_ALERT = 'RECEIVED_ALERT'
export const DISMISS_ALERT = 'DISMISS_ALERT'

export const receivedProperties = (properties) => ({
  type: RECEIVED_PROPERTIES,
  properties
})

export const receivedProperty = (property) => ({
  type: RECEIVED_PROPERTY,
  property
})

export const receivedAlert = (message, alertColor) => ({
  type: RECEIVED_ALERT,
  message,
  alertColor
})

export const dismissAlert = () => ({
  type: DISMISS_ALERT
})

export const receivedPropertySellerAgents = (sellerAgentData) => ({
  type: RECEIVED_PROPERTY_SELLER_AGENTS,
  sellerAgents: sellerAgentData
})

export const fetchPropertySellerAgents = (propertyId) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/properties/${propertyId}/seller_agent_properties`)
      const sellerAgentData = res.data

      const sellerAgentDataCleaned = sellerAgentData.map((sellerAgentProperty) => ({
        ...sellerAgentProperty.seller_agent,
        is_primary_agent: sellerAgentProperty.is_primary_agent,
        seller_agent_property_id: sellerAgentProperty.id
      }))

      dispatch(receivedPropertySellerAgents(sellerAgentDataCleaned))
    } catch {
      dispatch(receivedPropertySellerAgents([]))
    }
  }
}

export const setPrimaryAgent = (propertyId, sellerAgentPropertyId) => {
  return async (dispatch) => {
    try {
      const payload = {
        is_primary_agent: true
      }
      await axios.post(`/seller_agent_properties/${sellerAgentPropertyId}`, payload)

      dispatch(fetchPropertySellerAgents(propertyId))

      return true
    } catch {
      dispatch(receivedAlert('An error has occurred: Unable to update the primary agent', 'danger'))
      return false
    }
  }
}

export const addSellerAgentToProperty = (propertyId, sellerAgentId, isPrimaryAgent) => {
  return async (dispatch) => {
    try {
      const payload = {
        property_id: propertyId,
        seller_agent_id: sellerAgentId,
        is_primary_agent: isPrimaryAgent
      }
      await axios.post('/seller_agent_properties', payload)

      dispatch(fetchPropertySellerAgents(propertyId))
      dispatch(receivedAlert('Successfully added agent to the property!', 'success'))
    } catch {
      dispatch(receivedAlert('An error has occurred: Unable to add agent', 'danger'))
    }
  }
}

export const addSellerAgentToSystem = (listingAgent, market, propertyId) => {
  if (!listingAgent) {
    throw new Error('A listing agent is required!')
  }

  if (!market) {
    throw new Error('A market is required!')
  }

  if (!propertyId) {
    throw new Error('A firestore property ID is required!')
  }

  return async (dispatch) => {
    const { name, phone, email, mls_id: mlsId } = listingAgent
    try {
      const params = {
        mls_id: mlsId,
        mls: market
      }
      const sellerAgentsResponse = await axios.get('/seller_agents', { params })

      // if success, agent is already in seller_agents, just need
      // to add to seller_agent_properties

      const { data: sellerAgentData } = sellerAgentsResponse
      const { id: sellerAgentId } = sellerAgentData

      dispatch(addSellerAgentToProperty(propertyId, sellerAgentId, false))
    } catch {
      try {
        // otherwise we need to add the seller agent to the db
        const { firstName, lastName } = parseFullName(name)
        const phoneFormatted = phone ? formatPhoneNumber(phone) : undefined
        const payload = {
          mls: market,
          mls_id: mlsId,
          first_name: firstName,
          last_name: lastName,
          phone: phoneFormatted,
          email,
          seller_agent_properties: [
            {
              property_id: propertyId,
              is_primary_agent: false
            }
          ]
        }
        await axios.post('/seller_agents', payload)

        dispatch(fetchPropertySellerAgents(propertyId))
        dispatch(receivedAlert('Successfully added contact to the property!', 'success'))
      } catch {
        dispatch(
          receivedAlert('Something went wrong... try adding the agent through the form!', 'danger')
        )
      }
    }
  }
}

export const clearPropertySellerAgentData = () => ({
  type: CLEAR_PROPERTY_SELLER_AGENT_DATA
})

const parseFullName = (name) => {
  const s = name.split(' ')
  return {
    firstName: s[0],
    lastName: s.slice(1).join(' ')
  }
}
