import moment from 'moment'

import { RentRollRentalSubscription } from 'graphql/generated'
import { payments as PAYMENTS } from '@homevest/utils'

const { PAYMENT_CATEGORIES, PAYMENT_STATUSES } = PAYMENTS

type Rental = NonNullable<RentRollRentalSubscription['rentals_by_pk']>

type RentalPayment = Rental['payments'][number]

type Payment = Omit<RentalPayment, 'user'> & { user?: RentalPayment['user'] }

export type LedgerItem = {
  amount: number
  category?: string | null
  liabilityLabel: string
  date: string
  id: string
  status: string
  type: typeof PAYMENT_TYPE | typeof LIABILITY_TYPE | typeof CREDIT_TYPE | typeof ADJUSTMENT_TYPE
  availableOn?: string
  user?: RentalPayment['user']
  lifecycle?: string
  initiatedAt?: string
  paymentMethod?: string | null
  note?: string | null
  refundReason?: string | null
  liabilityTypeId: string
  stripeId?: string | null
  creditType?: string | null
}

const PAYMENT_TYPE = 'Payment' as const
const LIABILITY_TYPE = 'Charge' as const
const CREDIT_TYPE = 'Credit' as const
const ADJUSTMENT_TYPE = 'Adjustment' as const
export const getLedgerItemsWithBalance = (
  payments: Payment[],
  rentalLiabilities: Rental['rental_liabilities'],
  rentalCredits: Rental['rental_credits'],
  adjustments?: NonNullable<Rental['cashout']>['cashout_adjustments']
) => {
  const ledgerData = [
    ...payments.map(
      ({
        amount,
        available_on: availableOn,
        category,
        created_at: createdAt,
        id,
        initiated_at: initiatedAt,
        liability_type: liabilityType,
        lifecycle,
        note,
        payment_date: paymentDate = '',
        payment_method: paymentMethod,
        refund_reason: refundReason,
        status,
        stripe_charge_id: stripeChargeId,
        stripe_payment_intent_id: stripePaymentIntentId,
        user
      }) => ({
        amount,
        availableOn,
        category,
        date: paymentDate || moment(createdAt).format('YYYY-MM-DD'),
        id,
        initiatedAt,
        liabilityLabel: liabilityType.name,
        liabilityTypeId: liabilityType.id,
        lifecycle,
        note,
        paymentMethod,
        refundReason,
        status,
        stripeId: stripeChargeId || stripePaymentIntentId,
        type: PAYMENT_TYPE,
        user
      })
    ),
    ...rentalLiabilities.map(({ amount, date, id, liability_type, note }) => ({
      amount,
      category: liability_type.payment_category,
      date,
      id,
      liabilityLabel: liability_type.name,
      liabilityTypeId: liability_type.id,
      note,
      status: '',
      type: LIABILITY_TYPE
    })),
    ...rentalCredits.map(({ amount, date, id, note, liability_type, type: creditType }) => ({
      amount,
      category: liability_type.payment_category,
      liabilityLabel: liability_type.name,
      liabilityTypeId: liability_type.id,
      date,
      id,
      note,
      status: 'succeeded',
      type: CREDIT_TYPE,
      creditType
    })),
    ...(adjustments ?? []).map(({ amount, date, id, liability_type_id, liability_type, type }) => ({
      amount,
      category: liability_type.payment_category,
      liabilityLabel: `${type} ${liability_type.name}`,
      liabilityTypeId: liability_type_id,
      date,
      id,
      note: '',
      status: 'succeeded',
      type: ADJUSTMENT_TYPE
    }))
  ]

  const sortedWithData = ledgerData
    .sort((a, b) => {
      const daysDiff = moment(a.date).diff(b.date, 'days')

      if (daysDiff !== 0) {
        return daysDiff
      } else if (a.type === LIABILITY_TYPE) {
        return -1
      } else {
        return 1
      }
    })
    .reduce(
      (
        { ledgerDataWithBalances, balance, investmentToDate, rentPaidToDate, currentBalance },
        ld
      ) => {
        if (
          ([PAYMENT_TYPE, ADJUSTMENT_TYPE, CREDIT_TYPE] as string[]).includes(ld.type) &&
          ld.category !== PAYMENT_CATEGORIES.INITIAL_PAYMENT &&
          [PAYMENT_STATUSES.SUCCEEDED, PAYMENT_STATUSES.PROCESSING].includes(ld.status as any)
        ) {
          balance -= ld.amount

          // only update current balance if the date is today or in the past
          if (moment(ld.date).isSameOrBefore(moment(), 'day')) {
            currentBalance -= ld.amount
          }

          if (ld.category === PAYMENT_CATEGORIES.RENT) {
            rentPaidToDate += ld.amount
          } else if (ld.category === PAYMENT_CATEGORIES.OPTION_PREMIUM) {
            investmentToDate += ld.amount
          }
        } else if (ld.type === 'Charge') {
          balance += ld.amount

          // only update current balance if the date is today or in the past
          if (moment(ld.date).isSameOrBefore(moment(), 'day')) {
            currentBalance += ld.amount
          }
        }

        const dataWithBalance = {
          ...ld,
          balance
        }

        ledgerDataWithBalances.push(dataWithBalance)

        return {
          ledgerDataWithBalances,
          balance,
          investmentToDate,
          rentPaidToDate,
          currentBalance
        }
      },
      {
        ledgerDataWithBalances: [] as Array<LedgerItem & { balance: number }>,
        balance: 0,
        investmentToDate: 0,
        rentPaidToDate: 0,
        currentBalance: 0
      }
    )

  sortedWithData.ledgerDataWithBalances.reverse()
  return sortedWithData
}
