import { RentRollQuery } from 'graphql/generated'
import { parse } from 'date-fns'
export type RentRollStatus =
  | 'pending_move_in'
  | 'in_evictions'
  | 'ready_to_be_cashed_out'
  | 'on_notice'
  | 'standard'
  | 'completed'

export const getRentRollStatus = (rental: RentRollQuery['rent_roll'][number]): RentRollStatus => {
  if (
    rental.status === 'pending' ||
    (rental.status === 'active' &&
      parse(rental.occupancy_date, 'yyyy-MM-dd', new Date()) > new Date())
  ) {
    return 'pending_move_in'
  }

  if (rental.rental?.evictions?.length) {
    return 'in_evictions'
  }

  if (rental.rental?.cashout) {
    return rental.rental.cashout.status === 'cashed_out' ? 'completed' : 'ready_to_be_cashed_out'
  }

  if (rental.move_out_date && parse(rental.move_out_date, 'yyyy-MM-dd', new Date()) > new Date()) {
    return 'on_notice'
  }

  return rental.status && ['canceled', 'complete'].includes(rental.status)
    ? 'completed'
    : 'standard'
}
