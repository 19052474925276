import React, { FunctionComponent, useState } from 'react'
import { formatDistanceToNowStrict } from 'date-fns'
import { Box, Flex, Icon, Stack, Text } from '@chakra-ui/react'
import { types } from '@homevest/timelines'
import { Customer, TimelineEventContentRenderer } from './types'
import { eventDisplayTemplateMap } from './EventTypeDisplayTemplates'
import { SlideDownTransition } from 'ui/Transitions/SlideDownTransition'

type HomeDetails = types.HomeDetails
type PolymorphicEvent = types.PolymorphicEvent

type TimelineItemComponentPropTypes = {
  event: types.TimelineEvent
  eventIdx: number
  timelineLength: number
  homes?: HomeDetails[]
  customer?: Customer
}

const TimelineItemComponent: FunctionComponent<
  React.PropsWithChildren<TimelineItemComponentPropTypes>
> = ({ event, eventIdx, timelineLength, homes = [], customer }) => {
  const [expanded, setExpanded] = useState(false)

  const relativeTime = formatDistanceToNowStrict(new Date(event.eventTimeAt), {
    roundingMethod: 'floor'
  })

  const VerticalLine = (
    <Box position='absolute' zIndex='0' top='3rem' bg='gray.200' w='0.5' h='calc(100% - 2rem)' />
  )

  const { icon, iconColor, previewContent, expandedContent } =
    eventDisplayTemplateMap[event.eventType]

  const PreviewContent = previewContent as TimelineEventContentRenderer<
    Extract<PolymorphicEvent, { eventType: typeof event.eventType }>
  >

  const ExpandedContent = expandedContent as TimelineEventContentRenderer<
    Extract<PolymorphicEvent, { eventType: typeof event.eventType }>
  >

  const home = chooseHome(event, homes)

  return (
    <li>
      <Stack
        direction='row'
        cursor='pointer'
        p='4'
        rounded='md'
        position='relative'
        transition='background-color 0.25s linear'
        _hover={{ bg: 'gray.50' }}
        onClick={() => setExpanded(!expanded)}
        spacing='2'
      >
        <Flex
          alignItems='center'
          justifyContent='center'
          bg={iconColor}
          borderRadius='1000'
          h='8'
          w='8'
        >
          <Icon as={icon} fill='white' />
          {eventIdx !== timelineLength - 1 ? VerticalLine : null}
        </Flex>
        <Flex pt='1' flex='1 1 0%' justify='space-between' minW='0'>
          <Box w='full' h='fit-content' overflow='hidden' textOverflow='ellipsis'>
            <PreviewContent event={event} home={home} customer={customer} />
            <Box overflow='hidden' h='fit-content'>
              <SlideDownTransition show={expanded}>
                <ExpandedContent event={event} home={home} customer={customer} />
              </SlideDownTransition>
            </Box>
          </Box>
          <Text color='gray.500' fontSize='sm' whiteSpace='nowrap'>
            {relativeTime} ago
          </Text>
        </Flex>
      </Stack>
    </li>
  )
}

const chooseHome = (event: PolymorphicEvent, homes?: HomeDetails[]): HomeDetails | undefined => {
  if (homes?.length === 1) {
    return homes[0]
  }

  if ('mlsListingId' in event) {
    return homes?.find((h) => h.mlsListingId === event.mlsListingId)
  }

  return
}

export default TimelineItemComponent
