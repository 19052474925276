import React, { useState } from 'react'
import { useClient } from 'urql'
import { Link } from 'react-router-dom'
import {
  Badge,
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Text,
  Textarea,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useToast
} from '@chakra-ui/react'
import addDays from 'date-fns/addDays'

import { RENEWAL_RENT_INCREASES } from 'constants/renewal-rent-increases'
import { combineFirstNameLastName } from 'lib/utils'
import { UserByUserIdDocument } from 'graphql/generated'
import { parseDate, formatDate } from 'lib/date-time'

import { agreementSigners } from '@homevest/utils'
import { AgreementSigners } from 'types/FullRental'
const { ROLES, TENANT_PREFIX } = agreementSigners

const RenewRentalModalForm: React.FC<
  React.PropsWithChildren<{
    leaseEndDate?: Date | string | null
    rent: number
    setRent: (rent: number) => void
    optionPremium: number
    setOptionPremium: (rent: number) => void
    term: number
    setTerm: (term: number) => void
    notes: string
    setNotes: (notes: string) => void
    startDate: Date | null
    setStartDate: (date: Date | null) => void
    endDate: Date | null
    setEndDate: (date: Date | null) => void
    showOtherTerm: boolean
    setShowOtherTerm: (show: boolean) => void
    agreementSigners: AgreementSigners
    setAgreementSigners: (as: AgreementSigners) => void
  }>
> = ({
  leaseEndDate,
  rent,
  setRent,
  optionPremium,
  setOptionPremium,
  term,
  setTerm,
  notes,
  setNotes,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showOtherTerm,
  setShowOtherTerm,
  agreementSigners,
  setAgreementSigners
}) => {
  const [shouldShowAddUserButton, setShouldShowAddUserButton] = useState(true)
  const [newUserId, setNewUserId] = useState('')
  const urqlClient = useClient()
  const toast = useToast()

  const triggerToast = (message: string) => {
    toast({
      title: 'Cannot add user.',
      description: message,
      status: 'error',
      duration: 6000,
      isClosable: true,
      position: 'top'
    })
  }

  return (
    <Box mt='5'>
      <Text fontSize={'md'} color={'gray.400'} borderBottom={'1px'} borderColor={'gray.300'} mb='2'>
        Enter Renewal Details
      </Text>
      <FormControl>
        <Flex direction={'column'} gap={2}>
          <Flex align={'flex-start'} justify={'space-between'}>
            <FormLabel fontSize='md'>Signers</FormLabel>
            <Flex direction={'column'} gap={1} align='flex-end' w='60%'>
              {agreementSigners?.map(({ user, role }) => {
                if (!user) {
                  return null
                }
                return (
                  <Flex gap={1} key={user.id} width='100%' justify='space-between' align='center'>
                    <Link to={{ pathname: `/users/${user?.id}` }} target='_blank'>
                      {combineFirstNameLastName(user)}
                    </Link>
                    {role === ROLES.PRIMARY_TENANT /** Tenant #1 is Primary */ ? (
                      <Badge mr='1' variant='solid' colorScheme='green'>
                        P
                      </Badge>
                    ) : (
                      <CloseButton
                        size='sm'
                        onClick={() => {
                          setAgreementSigners([
                            ...agreementSigners.filter((as) => as.user?.id !== user.id)
                          ])
                        }}
                      />
                    )}
                  </Flex>
                )
              })}
            </Flex>
          </Flex>
          <Flex direction={'row'} justifyContent={'flex-end'} w='100%'>
            {shouldShowAddUserButton || (
              <Input
                mr='1'
                placeholder='user id'
                size='sm'
                onChange={(event) => {
                  setNewUserId(event.target.value)
                }}
              />
            )}
            {shouldShowAddUserButton && agreementSigners.length < 4 ? (
              <Button
                size='sm'
                colorScheme='blue'
                onClick={() => setShouldShowAddUserButton(false)}
              >
                Add Signer
              </Button>
            ) : (
              <Button
                size='sm'
                colorScheme='blue'
                isDisabled={newUserId === ''}
                onClick={async () => {
                  if (agreementSigners.find((s) => s.user?.id === newUserId)) {
                    triggerToast('Already have this signer.')
                  } else {
                    const userData = await urqlClient
                      .query(UserByUserIdDocument, { id: newUserId })
                      .toPromise()
                    if (!userData.data?.users_by_pk) {
                      triggerToast(`No user found with id ${newUserId}`)
                    } else {
                      setAgreementSigners([
                        ...agreementSigners,
                        {
                          user: userData.data.users_by_pk,
                          role: TENANT_PREFIX
                        }
                      ])
                    }
                  }
                  setNewUserId('')
                  setShouldShowAddUserButton(true)
                }}
              >
                Confirm
              </Button>
            )}
          </Flex>
          <Flex align={'center'} justify={'space-between'}>
            <FormLabel fontSize='md'>Renewal Term</FormLabel>
            <Select
              size='sm'
              w={'60%'}
              defaultValue={term}
              onChange={(event) => {
                const { value } = event?.currentTarget
                setTerm(parseInt(value))
                setShowOtherTerm(value === '0')
              }}
            >
              {Object.keys(RENEWAL_RENT_INCREASES).map((key) => (
                <option key={key} value={key}>
                  {RENEWAL_RENT_INCREASES[key].label}
                </option>
              ))}
            </Select>
          </Flex>
          {showOtherTerm ? (
            <>
              <Flex align='center' justify='space-between'>
                <FormLabel fontSize='md'>Other Term</FormLabel>
                <InputGroup size='sm' w='60%'>
                  <NumberInput defaultValue={term} onChange={(value) => setTerm(parseInt(value))}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <InputRightAddon children='months' />
                </InputGroup>
              </Flex>
              <Flex align='center' justify='space-between'>
                <FormLabel fontSize='md'>Start Date</FormLabel>
                <Input
                  w='60%'
                  type='date'
                  min={leaseEndDate ? formatDate(addDays(new Date(leaseEndDate), 1)) : undefined}
                  value={startDate ? formatDate(startDate) : ''}
                  onChange={(event) =>
                    setStartDate(
                      event?.currentTarget.value ? parseDate(event?.currentTarget.value) : null
                    )
                  }
                />
              </Flex>
              <Flex align='center' justify='space-between'>
                <FormLabel fontSize='md'>End Date</FormLabel>
                <Input
                  w='60%'
                  type='date'
                  min={startDate ? formatDate(startDate) : undefined}
                  value={endDate ? formatDate(endDate) : ''}
                  onChange={(event) =>
                    setEndDate(
                      event?.currentTarget.value ? parseDate(event?.currentTarget.value) : null
                    )
                  }
                />
              </Flex>
            </>
          ) : null}
          <Flex align={'center'} justify={'space-between'}>
            <FormLabel fontSize='md'>Rent Price</FormLabel>
            <InputGroup size='sm' w={'60%'}>
              <InputLeftAddon children='$' />
              <NumberInput
                defaultValue={rent}
                min={100}
                onChange={(value) => setRent(parseInt(value))}
                precision={0}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </InputGroup>
          </Flex>
          <Flex align='center' justify='space-between'>
            <FormLabel fontSize='md'>Option Premium</FormLabel>
            <InputGroup size='sm' w='60%'>
              <InputLeftAddon children='$' />
              <NumberInput
                defaultValue={optionPremium}
                onChange={(value) => setOptionPremium(parseInt(value))}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </InputGroup>
          </Flex>
          <Flex align='flex-start' justify='space-between'>
            <FormLabel fontSize='md'>Notes</FormLabel>
            <Textarea
              w='60%'
              size='lg'
              placeholder='Notes'
              defaultValue={notes}
              onChange={(event) => setNotes(event?.currentTarget.value)}
            />
          </Flex>
        </Flex>
      </FormControl>
    </Box>
  )
}

export default RenewRentalModalForm
