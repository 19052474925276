import React from 'react'
import { Button, HStack, Stack, Text, Tooltip } from '@chakra-ui/react'
import { UnfinalizedChargeback } from '@homevest/types/utilities'
import { startCase } from 'lodash'
import { FlagIcon } from '@heroicons/react/20/solid'
import type { ColDef, ICellRendererParams } from 'ag-grid-community'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { formatMoney } from 'lib/numbers'
import { formatDate } from 'lib/date-time'
import { InfoTooltip } from 'ui/InfoTooltip'

export const UTILITIES_GRID_COL_DEFS: { [colName: string]: ColDef<UnfinalizedChargeback> } = {
  ADDRESS: {
    sort: 'desc',
    colId: 'address',
    headerName: 'address',
    valueGetter: (params) =>
      `${params.data?.rental.home_address}, ${params.data?.rental.home_city_state}`,
    flex: 2,
    onCellDoubleClicked: (params) => {
      window.open(`${process.env.PUBLIC_URL}/rent-roll/${params.data?.rental.id}`, '_blank')
    }
  },
  PRIMARY_USER: {
    headerName: 'primary',
    valueGetter: (params) => params.data?.rental.primary_tenant_name,
    valueFormatter: (params) => startCase(params.value),
    flex: 1
  },
  AMOUNT: {
    headerName: 'amount',
    valueGetter: (params) => params.data?.current_prorated_amount,
    valueFormatter: (params) => formatMoney(params.value, 2, '$'),
    flex: 1
  },
  UTIL_TYPE: {
    headerName: 'util type',
    valueGetter: (params) => params.data?.utility_bill.utility_type.name,
    valueFormatter: (params) => startCase(params.value),
    flex: 1
  },
  STATUS: {
    colId: 'status',
    headerName: 'cur. status',
    valueGetter: (params) => params.data?.status,
    cellRenderer: ({ value }: ICellRendererParams<UnfinalizedChargeback>) => {
      return mapContentToBadge(value)
    },
    flex: 1
  },
  BILL_DATA: {
    headerName: 'bill',
    valueGetter: (params) => params.data?.utility_bill.url,
    cellRenderer: ({ value, data }: ICellRendererParams<UnfinalizedChargeback>) => {
      if (!value || !data) return '-'
      const utilityBill = data.utility_bill
      const billData = {
        chargeType: startCase(utilityBill.charge_type),
        vendor: startCase(utilityBill.vendor_name),
        serviceStartDate: formatDate(utilityBill.service_start_date),
        serviceEndDate: formatDate(utilityBill.service_end_date),
        billDate: formatDate(utilityBill.bill_date),
        billDueDate: formatDate(utilityBill.due_date),
        originalBillAmount: formatMoney(utilityBill.amount, 2, '$'),
        proratedAmount: formatMoney(data.current_prorated_amount, 2, '$')
      }
      const label = (
        <Stack p={2}>
          {Object.entries(billData).map(([key, value]) => (
            <Text key={key}>
              <span className='underline'>{startCase(key)}</span>: {value}
            </Text>
          ))}
        </Stack>
      )

      return (
        <HStack>
          <InfoTooltip label={label} />
          <a href={value} target='_blank' rel='noreferrer'>
            <Button>Link</Button>
          </a>
        </HStack>
      )
    },
    sortable: false,
    filter: false,
    flex: 1
  },
  FLAGS: {
    headerName: 'flags',
    valueGetter: (params) => params.data?.flags.map(startCase).join(', '),
    cellRenderer: ({ value }: ICellRendererParams<UnfinalizedChargeback>) => {
      if (!value) return null
      return (
        <div className='w-full flex-grow text-center'>
          <Tooltip label={value}>
            <Button variant='ghost'>
              <FlagIcon color='orange' height={20} width={20} />
            </Button>
          </Tooltip>
        </div>
      )
    },
    flex: 0.75
  },
  // Below are hidden columns used for CSV export
  RENTAL_ID: {
    headerName: 'rental_id',
    valueGetter: (params) => params.data?.rental.id,
    hide: true
  },
  CHARGEBACK_ID: {
    headerName: 'chargeback_id',
    valueGetter: (params) => params.data?.id,
    hide: true
  },
  UTILITY_BILL_ID: {
    headerName: 'utility_bill_id',
    valueGetter: (params) => params.data?.utility_bill.id,
    hide: true
  },
  ORIGINAL_BILL_AMOUNT: {
    headerName: 'original_bill_amount',
    valueGetter: (params) => params.data?.utility_bill.amount,
    hide: true
  },
  SERVICE_START_DATE: {
    headerName: 'service_start_date',
    valueGetter: (params) => params.data?.utility_bill.service_start_date,
    hide: true
  },
  SERVICE_END_DATE: {
    headerName: 'service_end_date',
    valueGetter: (params) => params.data?.utility_bill.service_end_date,
    hide: true
  },
  BILL_DATE: {
    headerName: 'bill_date',
    valueGetter: (params) => params.data?.utility_bill.bill_date,
    hide: true
  }
}

export const BASE_UTILITY_GRID_COL_DEFS: ColDef<UnfinalizedChargeback>[] = [
  UTILITIES_GRID_COL_DEFS.ADDRESS,
  UTILITIES_GRID_COL_DEFS.PRIMARY_USER,
  UTILITIES_GRID_COL_DEFS.AMOUNT,
  UTILITIES_GRID_COL_DEFS.UTIL_TYPE,
  // UTILITIES_GRID_COL_DEFS.STATUS,
  UTILITIES_GRID_COL_DEFS.BILL_DATA,
  UTILITIES_GRID_COL_DEFS.FLAGS,
  // Hidden columns for CSV export
  UTILITIES_GRID_COL_DEFS.RENTAL_ID,
  UTILITIES_GRID_COL_DEFS.CHARGEBACK_ID,
  UTILITIES_GRID_COL_DEFS.UTILITY_BILL_ID,
  UTILITIES_GRID_COL_DEFS.ORIGINAL_BILL_AMOUNT,
  UTILITIES_GRID_COL_DEFS.SERVICE_START_DATE,
  UTILITIES_GRID_COL_DEFS.SERVICE_END_DATE,
  UTILITIES_GRID_COL_DEFS.BILL_DATE
]
