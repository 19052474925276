import { mapContentToBadge, mapStatusToBadgeColor } from 'components/TailwindUIToolkit/badges'
import { isValidPhoneNumber } from 'lib/phone'
import { YesCircle, NoCircle, NACircle } from './icons'

export const formatPreQualData = (content: boolean | null | string) => {
  if (content === true) {
    return YesCircle()
  }
  if (content === false) {
    return NoCircle()
  }
  if (content) {
    return mapContentToBadge(content)
  }
  return NACircle()
}

export const formatPersonalDetails = (content: string | undefined) => {
  if (content === undefined || content === 'N/A') return NACircle()

  if (isValidPhoneNumber(content))
    return `(${content?.slice(2, 5)}) ${content?.slice(5, 8)}-${content?.slice(8)}`

  if (mapStatusToBadgeColor[content]) return mapContentToBadge(content)

  return content
}
