import React, { useState, useMemo } from 'react'
import { utilities } from '@homevest/utils'
import {
  useToast,
  Stack,
  Center,
  Button,
  HStack,
  Box,
  Text,
  Select,
  FormControl,
  FormLabel,
  FormHelperText,
  Link,
  Spinner
} from '@chakra-ui/react'
import { ArrowUpTrayIcon, TrashIcon } from '@heroicons/react/20/solid'
import { startCase } from 'lodash'

import axios from 'lib/axios'
import { UploadContainer } from 'components/Toolkit'
import { CsvUploadValidationError } from 'types/Utilities'

const { UTILITY_BILL_UPLOAD_FORMATS } = utilities

type CsvUploadFormProps = {
  uploadId: string
  uploadState: any
  setUploadState: (uploadState: any) => void
  downloadUrl: string
  setDownloadUrl: (downloadUrl: string) => void
  setIsSubmitted: (isSubmitted: boolean) => void
  setIsSubmissionSuccessful: (isSubmissionSuccessful: boolean) => void
  setErrors: (errors: CsvUploadValidationError[]) => void
}

const CsvUploadForm: React.FC<React.PropsWithChildren<CsvUploadFormProps>> = ({
  setIsSubmitted,
  uploadId,
  uploadState,
  setUploadState,
  downloadUrl,
  setDownloadUrl,
  setIsSubmissionSuccessful,
  setErrors
}) => {
  const toast = useToast()

  const [isUploading, setIsUploading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const canSubmit = !!downloadUrl && !!uploadState?.file?.name

  const documentPath = useMemo(
    () => `/utilities/documents/utility-bill-uploads/${uploadId}`,
    [uploadId]
  )

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      await axios.post('/admin/utility_bills/upload_raw_bills', {
        document_url: downloadUrl,
        document_path: documentPath,
        document_friendly_name: uploadState?.file?.name,
        bill_format: UTILITY_BILL_UPLOAD_FORMATS.CONSERVICE
      })

      toast({
        title: 'Upload Success!',
        description: 'Your utility bills have been uploaded successfully!',
        status: 'success',
        duration: 4000,
        isClosable: true
      })
      setIsSubmissionSuccessful(true)
    } catch (err: any) {
      toast({
        title: 'Upload Failed!',
        description: `There was an error uploading your utility bills! ${err?.message}`,
        status: 'error',
        duration: 4000,
        isClosable: true
      })

      setErrors(err?.details?.errors ?? [])
      setIsSubmissionSuccessful(false)
    }
    setIsSubmitting(false)
    setIsSubmitted(true)
  }

  const onDropzoneChange = async (data: any) => {
    if (data.isLoading) {
      setIsUploading(true)
    }
    if (data.downloadUrl) {
      setDownloadUrl(data.downloadUrl)
      setIsUploading(false)
    }
    setUploadState(data)
  }

  if (isSubmitting) {
    return (
      <Box>
        <Center>
          <Stack>
            <Center>
              <Spinner />
            </Center>
            <Text>Submitting...</Text>
          </Stack>
        </Center>
      </Box>
    )
  }

  return (
    <Stack spacing={4}>
      <FormControl isRequired>
        <FormLabel htmlFor='format-select'>CSV Format</FormLabel>
        <Select id='format-select' value={UTILITY_BILL_UPLOAD_FORMATS.CONSERVICE} isReadOnly>
          {Object.values(UTILITY_BILL_UPLOAD_FORMATS).map((format) => (
            <option key={format} value={format}>
              {startCase(format)}
            </option>
          ))}
        </Select>
        <FormHelperText>
          {' '}
          To upload a CSV in another format, please contact Engineering!{' '}
        </FormHelperText>
      </FormControl>
      {downloadUrl ? (
        <>
          <Text as='b'>Document</Text>
          <HStack>
            <Link color={'blue.500'} href={downloadUrl} target='_blank' rel='noreferrer'>
              {' '}
              {uploadState?.file?.name}
            </Link>
            <TrashIcon
              className='h-4 w-4 cursor-pointer'
              onClick={() => {
                setDownloadUrl('')
                setUploadState({})
              }}
            />
          </HStack>
        </>
      ) : (
        <UploadContainer
          onChange={onDropzoneChange as any}
          uploadPath={documentPath}
          onSuccess={() => setIsUploading(false) as any}
        >
          <Box
            rounded='md'
            borderWidth='1px'
            borderColor='gray.300'
            p='10'
            justifyContent='center'
            display='flex'
            flexDir='column'
            alignItems='center'
            backgroundColor={'gray.100'}
          >
            {isUploading && (
              <>
                <Spinner />
                <Text>Uploading {uploadState?.file?.name}...</Text>
              </>
            )}
            {!isUploading && (
              <>
                <ArrowUpTrayIcon className='m-3 h-8 w-8' />
                <div>Drag and drop, or choose a file.</div>
              </>
            )}
          </Box>
        </UploadContainer>
      )}
      <Button
        onClick={handleSubmit}
        disabled={!canSubmit}
        isLoading={isSubmitting}
        colorScheme='teal'
      >
        Submit
      </Button>
    </Stack>
  )
}

export default CsvUploadForm
