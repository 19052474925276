import { isSameDay } from 'date-fns'
import startCase from 'lodash/startCase'
import React, { useEffect, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import type { ColDef, ICellRendererParams, GridApi, GridReadyEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Input, Link, Spinner, Stack } from '@chakra-ui/react'
import { latchel } from '@homevest/utils'

import { VendorsGridQuery, useVendorsGridQuery } from 'graphql/generated'
import { formatPhoneFromDatabase } from 'lib/formatting'
import { LINK_COLOR } from 'constants/colors'
import { formatVendorTypes } from 'lib/vendors'

const { INACTIVE_LATCHEL_STATUSES } = latchel

type VendorRow = {
  id: string
  name: string
  email: string | null
  phone: string | null
  types: string
  rating: number | null
  markets: string[]
  ticketsToday: number
  activeTickets: number
  linkToLatchel: string
}

const GRID_OPTIONS = {
  defaultColDef: {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1
  },
  pagination: true,
  paginationPageSize: 20
}

const renderHudsonLink = (params: ICellRendererParams) => {
  const data: VendorRow = params.data
  const hudsonUrl = `vendors/${data.id}`
  return (
    <Link
      as={ReactRouterLink}
      to={hudsonUrl}
      color={LINK_COLOR}
      target='_blank'
      rel='noopener noreferrer'
    >
      {params.value}
    </Link>
  )
}

const renderLatchelLink = (params: ICellRendererParams) => (
  <Link href={params.value} isExternal color={LINK_COLOR}>
    {params.value}
  </Link>
)

const formatVendorsAsRowDataColDefs = (vendors: VendorsGridQuery['vendors']) => {
  const rowData: VendorRow[] = vendors.map((v) => {
    const markets = v.vendor_markets.map((m) => m.market.display_name)

    const activeTickets = v.ticket_vendor_assignments.reduce((acc, tva) => {
      if (!INACTIVE_LATCHEL_STATUSES.includes(tva.ticket.status as any)) {
        return acc + 1
      }
      return acc
    }, 0)

    const ticketsToday = v.ticket_vendor_assignments.reduce((acc, tva) => {
      let ticketDate: Date | null = null
      if (tva.ticket.planned_start_date) {
        ticketDate = new Date(tva.ticket.planned_start_date)
      } else if (tva.ticket.tickets_latchel_data?.scheduled_start) {
        ticketDate = new Date(tva.ticket.tickets_latchel_data?.scheduled_start)
      }

      if (ticketDate && isSameDay(ticketDate, new Date())) {
        return acc + 1
      }
      return acc
    }, 0)

    const linkToLatchel = v.latchel_id
      ? `https://app.latchel.com/admin/vendor-company/${v.latchel_id}`
      : ''

    return {
      id: v.id,
      name: v.name,
      email: v.email ?? null,
      phone: v.phone ?? null,
      types: formatVendorTypes(v),
      markets,
      rating: v.internal_rating ?? null,
      ticketsToday,
      activeTickets,
      linkToLatchel
    }
  })

  const colDefs = Object.keys(rowData[0]).map((key) => {
    const column: ColDef = {
      headerName: startCase(key),
      field: key
    }

    if (key === 'id') {
      column.hide = true
    }
    if (key === 'name') {
      column.cellRenderer = renderHudsonLink
    }
    if (key === 'linkToLatchel') {
      column.cellRenderer = renderLatchelLink
    }
    if (key === 'phone') {
      column.valueFormatter = (params) => formatPhoneFromDatabase(params.value)
    }
    // Set default filter - sort by # tickets today
    if (key === 'ticketsToday') {
      column.sort = 'desc'
    }

    if (key === 'types') {
      column.flex = 2
      column.wrapText = true
      column.autoHeight = true
    }

    return column
  })

  return { rowData, colDefs }
}

const VendorsGrid = () => {
  const [gridApi, setGridApi] = useState<GridApi>()
  const [vendorsSearch, setVendorsSearch] = useState<string>()
  const onGridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api)
  }

  // Update filters upon typing in search bar
  useEffect(() => {
    if (gridApi && vendorsSearch !== undefined) {
      gridApi.setQuickFilter(vendorsSearch)
      gridApi.onFilterChanged()
    }
  }, [vendorsSearch, gridApi])

  const [{ data, fetching }] = useVendorsGridQuery()

  if (fetching) {
    return <Spinner />
  }
  if (!data?.vendors) {
    return <div>No vendors to show!</div>
  }

  const { rowData, colDefs } = formatVendorsAsRowDataColDefs(data.vendors)

  return (
    <Stack>
      <div className='m-4'>
        <Input
          type='text'
          value={vendorsSearch}
          onChange={(e) => setVendorsSearch(e.target.value)}
          placeholder="Search by vendor's name, email, market, etc..."
        />
      </div>
      <div className='ag-theme-material' style={{ height: '74vh' }}>
        <AgGridReact
          gridOptions={GRID_OPTIONS}
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={colDefs}
          onGridSizeChanged={() => gridApi && gridApi.sizeColumnsToFit()}
        />
      </div>
    </Stack>
  )
}

export default VendorsGrid
