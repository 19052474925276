import React, { useState } from 'react'
import { OccupantDetails, OccupantWithId } from '@homevest/types/rental-applications'

import DetailsSection from '../Common/DetailsSection'
import OccupantsDisplay from './Display'
import EditOccupants from './EditMode'
import { Center, Spinner } from '@chakra-ui/react'

type OccupantsSectionWrapperProps = {
  rentalApplicationId: string
  occupantDetails: OccupantDetails
  isEditable: boolean
  isFetching: boolean
}

type OccupantsSectionProps = {
  rentalApplicationId: string
  occupants: OccupantWithId[]
  isFetching: boolean
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
}

const OccupantsSectionWrapper: React.FC<React.PropsWithChildren<OccupantsSectionWrapperProps>> = ({
  rentalApplicationId,
  occupantDetails,
  isEditable,
  isFetching
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const { data: occupants, submitted_at: submittedAt } = occupantDetails

  return (
    <DetailsSection
      title='Occupants'
      submittedAt={submittedAt}
      isEditable={isEditable}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
    >
      <OccupantsSection
        rentalApplicationId={rentalApplicationId}
        occupants={occupants ?? []}
        isFetching={isFetching}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </DetailsSection>
  )
}

const OccupantsSection: React.FC<React.PropsWithChildren<OccupantsSectionProps>> = ({
  rentalApplicationId,
  occupants,
  isFetching,
  isEditing,
  setIsEditing
}) => {
  if (isFetching) {
    return (
      <Center>
        <Spinner colorScheme='teal' />
      </Center>
    )
  }

  if (isEditing) {
    return (
      <EditOccupants
        rentalApplicationId={rentalApplicationId}
        currentOccupants={occupants}
        exitEditMode={() => setIsEditing(false)}
      />
    )
  }

  return <OccupantsDisplay occupants={occupants} />
}

export default OccupantsSectionWrapper
