import React from 'react'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import { format as formatDate } from 'date-fns'
import { Heading, Text, VStack } from '@chakra-ui/react'

import CrmField from 'components/CrmField'
import LeadType from '../../components/LeadType'
import { CrmField as CrmFieldType, CrmUser, LeadGroupUser } from 'types/RentalApplication'

type UserInfoProps = {
  crmFields: Array<CrmFieldType>
  crmUser: CrmUser
  firestoreUser: any
  hasSigned: boolean
  leadGroupUser: LeadGroupUser
  updateField: Function
}

const UserInfo: React.FC<React.PropsWithChildren<UserInfoProps>> = ({
  leadGroupUser,
  firestoreUser,
  crmUser,
  crmFields,
  updateField,
  hasSigned
}) => {
  const user = leadGroupUser.user
  const creditScore =
    get(leadGroupUser, 'user.successful_credit_report.score') ||
    get(leadGroupUser, 'user.credit_report.score', 'N/A')
  const hasSubmittedApplication = !!leadGroupUser.rental_application_submitted_at

  return (
    <div>
      <VStack alignItems={'flex-start'} spacing={0} gap={0}>
        <Heading size='lg' justifyContent={'space-between'} as='h2'>
          <span>
            <Link to={`/users/${user.id}`} target='_blank' rel='noopener noreferrer'>
              {user.first_name} {user.last_name}
            </Link>
          </span>
        </Heading>
        {hasSigned && <span style={{ color: '#CCC' }}>Signed Lease</span>}
        {hasSubmittedApplication && (
          <Text color='gray' as='i'>
            Submitted application on{' '}
            {formatDate(new Date(leadGroupUser.rental_application_submitted_at), 'M/d/yy h:mm a')}
          </Text>
        )}
      </VStack>

      <div style={styles.userDiv}>
        <div style={{ width: '33%' }}>
          <LeadType leadGroupUser={leadGroupUser} />
        </div>
        <UserInfoItem label='Email' value={user.email} />
        <UserInfoItem label='Phone' value={user.phone || 'N/A'} />
      </div>
      <div style={styles.userDiv}>
        <UserInfoItem label='Verified Credit' value={creditScore} />
        <UserInfoItem
          label='Date of Birth'
          value={get(firestoreUser, 'underwriting.dateOfBirth', 'N/A')}
        />
        <UserInfoItem
          label='Current Address'
          value={get(firestoreUser, 'underwriting.currentAddress.formatted', 'N/A')}
        />
      </div>
      <div style={styles.userDiv}>
        {crmFields.map((item) => (
          <div key={item.id} style={{ width: '33%' }}>
            <CrmField
              label={item.label}
              value={crmUser.fields[item.label] === undefined ? null : crmUser.fields[item.label]}
              fieldValueType={item.details.value_type}
              fieldType={item.details.type}
              options={item.options.map((option) => ({
                ...option,
                value: option.id
              }))}
              onChange={(val) => {
                updateField(user.id, item.id, val)
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const styles = {
  userDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px'
  }
}

const UserInfoItem: React.FC<React.PropsWithChildren<{ label: string; value: string | null }>> = ({
  label,
  value
}) => {
  return (
    <div style={{ width: '33%' }}>
      <Text fontSize='xs'>{label}</Text>
      <Text marginTop={0}>{value ?? ''}</Text>
    </div>
  )
}

export default UserInfo
