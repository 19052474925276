import React, { FunctionComponent } from 'react'
import {
  Flex,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  MenuDivider
} from '@chakra-ui/react'
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  ArrowTopRightOnSquareIcon,
  ChevronDoubleRightIcon,
  EllipsisHorizontalIcon,
  EyeIcon,
  XCircleIcon
} from '@heroicons/react/20/solid'
import { Link as ReactLink } from 'react-router-dom'
import CancelApplicationModal from '../CancelApplicationModal'

const SidePeekHeader: FunctionComponent<
  React.PropsWithChildren<{
    appId: string
    size: 'xl' | 'full'
    onCancel: () => void
    onClose: () => void
    setSize: (size: 'xl' | 'full') => void
  }>
> = ({ appId, size, onCancel, onClose, setSize }) => {
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure()
  return (
    <>
      <Flex direction='row' align='center'>
        <Flex direction='row' align='center' gap={1} flexGrow={1}>
          <IconButton
            variant='ghost'
            aria-label='Close side peek'
            size='sm'
            color='gray.500'
            onClick={onClose}
            icon={
              <Box h={6} w={6}>
                <ChevronDoubleRightIcon />
              </Box>
            }
          />
          <IconButton
            variant='ghost'
            aria-label={size === 'full' ? 'Shorten side peek' : 'Extend side peek'}
            size='sm'
            color='gray.500'
            icon={
              <Box h={5} w={5}>
                {size === 'full' ? <ArrowsPointingInIcon /> : <ArrowsPointingOutIcon />}
              </Box>
            }
            onClick={() => setSize(size === 'full' ? 'xl' : 'full')}
          />
        </Flex>
        <Menu>
          <MenuButton
            as={IconButton}
            variant='ghost'
            aria-label='Options'
            size='sm'
            color='gray.500'
            icon={
              <Box h={5} w={5}>
                <EllipsisHorizontalIcon />
              </Box>
            }
          >
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem
              as={ReactLink}
              to={`/applications/${appId}`}
              target='_blank'
              fontSize='sm'
              color='gray.700'
            >
              <Flex direction='row' align='center'>
                <Box h={5} w={5} color='gray.500' mr={2}>
                  <EyeIcon />
                </Box>
                View
                <Box h={3} w={3} ml={1}>
                  <ArrowTopRightOnSquareIcon />
                </Box>
              </Flex>
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={openModal} fontSize='sm' color='red.500'>
              <Flex direction='row' align='center' gap={2}>
                <Box h={5} w={5}>
                  <XCircleIcon />
                </Box>
                Cancel application
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <CancelApplicationModal
        rentalApplicationId={appId}
        isOpen={isModalOpen}
        onCancel={onCancel}
        onClose={closeModal}
      />
    </>
  )
}

export default SidePeekHeader
