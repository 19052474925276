import React from 'react'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'

import { useSourceDetails } from '../../context'
import DocumentUpload from '../utils/DocumentUpload'
import { SourceDetailsFieldName } from 'lib/user-incomes/types'

interface DocumentFormInputProps {
  label: string
  fieldName: SourceDetailsFieldName
  documentType: string
  uploadPrefix: string
}

const DocumentFormInput = ({
  label,
  fieldName,
  documentType,
  uploadPrefix
}: DocumentFormInputProps) => {
  const context = useSourceDetails()

  if (!context) {
    return null
  }

  const { data, updateField, errors } = context

  const fieldValue = (data as any)[fieldName]
  const errorMessage = (errors as any)?.[fieldName]?.file as string

  return (
    <FormControl isRequired isInvalid={!!errorMessage}>
      <FormLabel>{label}</FormLabel>
      <DocumentUpload
        uploadPrefix={uploadPrefix}
        onUpload={({ uploadPath }) =>
          updateField(fieldName, {
            ...fieldValue,
            upload_path: uploadPath,
            type: documentType
          })
        }
        onClear={() => updateField(fieldName, {})}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default DocumentFormInput
