import React, { useState } from 'react'
import { useQuery } from 'urql'
import { Spinner, Table } from 'reactstrap'
import { Comparators as Comp } from 'lib/hasura-comparators'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { debounce, get } from 'lodash'
import moment from 'moment'
import { regions, properties } from '@homevest/utils'

import { formatMoney } from 'lib/numbers'
import { Upup_Properties_MlsListingsDocument as queryDoc } from 'graphql/generated'
import { HeaderText, Input, Dropdown } from 'components/Toolkit'

const { REGIONS } = regions
const { homevestStatus } = properties

const REGION_OPTIONS = Object.keys(REGIONS).map((region) => ({
  label: region,
  value: REGIONS[region]
}))

const STATUS_OPTIONS = Object.keys(homevestStatus).map((status) => ({
  label: status,
  value: homevestStatus[status]
}))

function PropertyEntries({ searchText, regionFilter, statusFilter }) {
  const [{ data }] = useQuery({
    query: queryDoc,
    variables: {
      where: {
        _or: [
          { display_line_1: Comp.isLike(`%${searchText}%`) },
          { display_line_2: Comp.isLike(`%${searchText}%`) }
        ],
        ...(regionFilter ? { region: { _eq: regionFilter } } : {}),
        ...(statusFilter ? { internal_attributes: { internal_status: { _eq: statusFilter } } } : {})
      }
    }
  })

  const mlsListings = data?.mls_listings || []

  return (
    <div>
      <Table responsive='true'>
        <thead>
          <tr>
            <th>Address</th>
            <th>Up&Up Status</th>
            <th>City</th>
            <th>Area</th>
            <th>State</th>
            <th>Days on Market</th>
            <th>List Price</th>
            <th>Provider</th>
          </tr>
        </thead>

        <tbody>
          {mlsListings.map(
            ({
              id,
              internal_attributes: internalAttributes,
              display_line_1: displayLine1,
              area,
              city,
              listing_date: listingDate,
              list_price: listPrice,
              mls,
              provider,
              state
            }) => {
              return (
                <tr key={id}>
                  <td>
                    <Link
                      to={{
                        pathname: `/review/initial/${id}`
                      }}
                    >
                      {displayLine1}
                    </Link>
                  </td>
                  <td>{get(internalAttributes, '[0].internal_status', 'N/A')}</td>
                  <td>{city}</td>
                  <td>{area}</td>
                  <td>{state}</td>
                  <td>{listingDate ? moment().diff(moment(listingDate), 'd') : 'N/A'}</td>
                  <td>${formatMoney(listPrice, 0)}</td>
                  <td>{provider === 'homejunction' ? mls : provider}</td>
                </tr>
              )
            }
          )}
        </tbody>
      </Table>
    </div>
  )
}

PropertyEntries.propTypes = {
  searchText: PropTypes.string.isRequired,
  regionFilter: PropTypes.string.isRequired,
  statusFilter: PropTypes.string.isRequired
}

function Properties() {
  const [searchText, setSearchText] = useState('')
  const [inputText, setInputText] = useState('')
  const [regionFilter, setRegionFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')

  const setSearchTextDebounced = React.useMemo(
    () => debounce(setSearchText, 250, { trailing: true }),
    [setSearchText]
  )

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto  ' }}>
      <HeaderText size='h3'>Property Search</HeaderText>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '40px'
        }}
      >
        <div style={{ width: '600px', marginRight: '20px' }}>
          <Input
            placeholder='Search...'
            value={inputText}
            onChange={(value) => {
              // We need two separate values so we can debounce to the subscription
              setInputText(value)
              setSearchTextDebounced(value)
            }}
          />
        </div>

        <div style={{ width: '300px', marginRight: '20px' }}>
          <Dropdown
            onChange={setRegionFilter}
            placeholder='No region filter'
            options={REGION_OPTIONS}
            value={regionFilter}
          />
        </div>
        <div style={{ width: '300px' }}>
          <Dropdown
            onChange={setStatusFilter}
            placeholder='No status filter'
            options={STATUS_OPTIONS}
            value={statusFilter}
          />
        </div>
      </div>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <PropertyEntries
          statusFilter={statusFilter}
          regionFilter={regionFilter}
          searchText={searchText}
        />
      </React.Suspense>
    </div>
  )
}

export default Properties
