import React, { useContext } from 'react'
import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import { startCase } from 'lodash'

import { UtilityTypesContext } from '../../UtilityTypesContext'

type UtilityTypesDropdownPropTypes = {
  id: string
  value: string
  setValue: (value: string) => void
  assignedUtilityTypes: Set<string>
}

const UtilityTypesDropdown: React.FC<React.PropsWithChildren<UtilityTypesDropdownPropTypes>> = ({
  id,
  value,
  setValue,
  assignedUtilityTypes
}) => {
  const { utilityTypes } = useContext(UtilityTypesContext)
  const unassignedUtilityTypes = utilityTypes.filter((type) => !assignedUtilityTypes.has(type.id))

  return (
    <FormControl isRequired>
      <FormLabel htmlFor={id}>Utility Type</FormLabel>
      <Select
        id={id}
        placeholder='Select a type...'
        value={value}
        onChange={(event) => setValue(event.target.value)}
      >
        {unassignedUtilityTypes.map((utilityType) => (
          <option key={utilityType.id} value={utilityType.id}>
            {startCase(utilityType.name)}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default UtilityTypesDropdown
