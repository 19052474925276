import React from 'react'
import PropTypes from 'prop-types'

import CriminalReportDetails from './CriminalReportDetails'
import ReportHeader from '../ReportHeader'

export default function CriminalReport({ criminalReport }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ReportHeader text='Criminal Report Data' />
      <CriminalReportDetails criminalReport={criminalReport} />
    </div>
  )
}

CriminalReport.propTypes = {
  criminalReport: PropTypes.object
}
