import React from 'react'
import { startCase } from 'lodash'
import { TableContainer, Table, Thead, Tr, Th, Td, Tbody } from '@chakra-ui/react'
import { PetWithId } from '@homevest/types/rental-applications'

const PetsDisplay: React.FC<React.PropsWithChildren<{ pets: PetWithId[] }>> = ({ pets }) => {
  return (
    <TableContainer>
      <Table variant='striped' size='sm'>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Type</Th>
            <Th>Color</Th>
            <Th>Weight (lbs)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pets.map((pet) => (
            <Tr key={pet.id}>
              <Td>{startCase(pet.name)}</Td>
              <Td>{startCase(pet.type)}</Td>
              <Td>{startCase(pet.color)}</Td>
              <Td>{pet.weight_in_pounds}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default PetsDisplay
