import React, { FunctionComponent } from 'react'
import { Link as ReactLink, LinkProps as ReactLinkProps } from 'react-router-dom'
import { Box, Flex, Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'

const Link: FunctionComponent<
  React.PropsWithChildren<
    | (LinkProps & { useRouter?: false; showIcon?: boolean })
    | (LinkProps & ReactLinkProps & { useRouter: true; showIcon?: boolean })
  >
> = ({ children, useRouter, showIcon = false, ...props }) => (
  <ChakraLink
    {...(useRouter ? { as: ReactLink } : {})}
    color='blue.600'
    textDecoration='underline'
    {...props}
  >
    <Flex direction='row' align='center' gap={1}>
      {children}
      {showIcon && (
        <Box h={3} w={3}>
          <ArrowTopRightOnSquareIcon />
        </Box>
      )}
    </Flex>
  </ChakraLink>
)

export default Link
