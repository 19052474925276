// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!

import React, { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import moment from 'moment'
import { get, startCase } from 'lodash'

import { COLORS } from 'components/Toolkit'
import { Box, Heading, Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import {
  Notes_Bool_Exp as NotesBoolExp,
  UpupGenericNotesDocument as queryDoc,
  Order_By as OrderBy
} from 'graphql/generated'
import CreateNoteModal from './CreateNoteModal'

import axios from 'lib/axios'

import NoteWidgetOption from 'types/NoteWidgetOption'

function getAuthorLine(note: any): string {
  const author = note.admin

  return `By: ${author.first_name} ${author.last_name}`
}

function displayNote(text: string, term: string): string {
  if (!term) {
    return text
  }
  return text.replace(
    new RegExp(term, 'gi'),
    (match) => `<span style="background-color: yellow">${match}</span>`
  )
}

function getEntityLine(
  note: any,
  noteWidgetOptions: NoteWidgetOption[],
  entityType: string
): string {
  const option = noteWidgetOptions.find((nwo) => {
    if (entityType === 'resource') {
      return nwo.id === note.resource_id
    } else if (entityType === 'subject') {
      return nwo.id === note.subject_id
    }
    return false
  })
  const displayEntityType = entityType === 'resource' ? note.resource_type : note.subject_type

  let display = startCase(displayEntityType)
  if (option && option.displayName) {
    display = option.displayName
  }

  if (option && option.linkTo) {
    return `${startCase(entityType)}: <a target="_blank" href="${option.linkTo}">${display}</a>`
  } else {
    return `${startCase(entityType)}: ${display}`
  }
}

function Notes({
  readCategories = [],
  resourceNoteWidgetOptions = []
}: {
  readCategories?: string[]
  resourceNoteWidgetOptions?: NoteWidgetOption[]
  writeCategory?: string | null
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sortOrder, setSortOrder] = useState<OrderBy>(OrderBy.DescNullsLast)
  const [searchTerm, setSearchTerm] = useState<string>('')

  return (
    <ContentSectionCard
      padding
      title='Notes'
      collapsable={false}
      action={
        <Button size='sm' colorScheme={'teal'} onClick={() => setIsModalOpen(true)}>
          Add Note
        </Button>
      }
    >
      <Stack>
        <Heading size='s'>Search</Heading>
        <Input
          value={searchTerm || ''}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Heading size='s'>Sort</Heading>
        <RadioGroup
          value={sortOrder}
          onChange={(i) => {
            setSortOrder(i)
          }}
        >
          <Stack spacing={0}>
            <Radio value={OrderBy.AscNullsLast}>By Oldest</Radio>
            <Radio value={OrderBy.DescNullsLast}>By Newest</Radio>
          </Stack>
        </RadioGroup>
        <Heading size='s'>Notes</Heading>
        <NotesResults
          readCategories={readCategories}
          resourceNoteWidgetOptions={resourceNoteWidgetOptions}
          isModalOpen={isModalOpen}
          searchTerm={searchTerm}
          sortOrder={sortOrder}
          setIsModalOpen={setIsModalOpen}
        />
      </Stack>
    </ContentSectionCard>
  )
}

function NotesResults({
  isModalOpen,
  sortOrder,
  searchTerm,
  setIsModalOpen,
  readCategories = [],
  resourceNoteWidgetOptions = []
}: {
  isModalOpen: boolean
  readCategories: string[]
  resourceNoteWidgetOptions?: NoteWidgetOption[]
  sortOrder: OrderBy
  searchTerm: string
  setIsModalOpen: (toggle: boolean) => void
}) {
  const { ids, types } = resourceNoteWidgetOptions.reduce<{
    ids: string[]
    types: string[]
  }>(
    (acc, nwo) => {
      acc.ids.push(nwo.id)
      acc.types.push(nwo.type)

      return acc
    },
    {
      ids: [],
      types: []
    }
  )

  const andClause: NotesBoolExp[] = [
    {
      _or: [
        {
          resource_id: { _in: ids },
          resource_type: { _in: types }
        },
        {
          subject_id: { _in: ids },
          subject_type: { _in: types }
        }
      ]
    }
  ]

  if (readCategories && readCategories.length) {
    andClause.push({ category: { _in: readCategories } })
  }

  const [{ data }] = useQuery({
    query: queryDoc,
    variables: {
      where: {
        is_deleted: { _eq: false },
        _and: andClause
      },
      created_at: sortOrder
    }
  })

  const notes: any[] = data?.notes ?? []

  return (
    <>
      <NotesResultsDisplay
        isModalOpen={isModalOpen}
        notes={notes}
        resourceNoteWidgetOptions={resourceNoteWidgetOptions}
        setIsModalOpen={setIsModalOpen}
        searchTerm={searchTerm}
      />
    </>
  )
}

function NotesResultsDisplay({
  isModalOpen,
  notes,
  searchTerm,
  setIsModalOpen,
  resourceNoteWidgetOptions = []
}: {
  isModalOpen: boolean
  notes: object[]
  resourceNoteWidgetOptions?: NoteWidgetOption[]
  searchTerm?: string
  setIsModalOpen: (toggle: boolean) => void
}) {
  const [documentUrlsMapped, setDocumentUrlsMapped] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const allDocuments = notes.reduce((acc, note) => {
        return acc.concat(note.documents)
      }, [])

      const documentUrls = await Promise.all(
        allDocuments.map(({ id }) =>
          axios.get(`/admin/documents/${id}/url`).then((resp) => resp.data.url)
        )
      )

      const mapping = allDocuments.reduce(
        (acc, { id }, idx) => ({ ...acc, [id]: documentUrls[idx] }),
        {}
      )

      setDocumentUrlsMapped(mapping)
    }
    fetchData()
  }, [notes])

  return (
    <>
      {notes.length === 0 && <Text size='r'>There are no notes yet.</Text>}
      {notes.length > 0 ? (
        <div style={{ marginBottom: '1rem' }}>
          {notes
            .filter((n) => (searchTerm ? n.note.includes(searchTerm) : true))
            .map((note, idx) => (
              <Stack gap={4} key={note.id} p={5} shadow='md' borderWidth='1px'>
                <Text
                  p={4}
                  bg='gray.100'
                  rounded='md'
                  fontFamily='sans-serif'
                  whiteSpace='pre-wrap'
                  as='pre'
                  size='r'
                  color={COLORS.PRIMARY.PLUM}
                >
                  {displayNote(note.note)}
                </Text>
                <Stack>
                  <div style={{ display: 'flex' }}>
                    <div style={{ minWidth: '200px' }}>
                      <Text size='r'>{getAuthorLine(note)}</Text>
                    </div>
                    <div>{note.category && mapContentToBadge(note.category)}</div>
                  </div>
                  <Text size='s'>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getEntityLine(note, resourceNoteWidgetOptions, 'resource')
                      }}
                    />
                  </Text>
                  {note.subject_id && (
                    <Text size='s'>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: getEntityLine(note, resourceNoteWidgetOptions, 'subject')
                        }}
                      />
                    </Text>
                  )}
                  <Text size='s'>
                    {moment(note.created_at).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                  </Text>
                  {documentUrlsMapped &&
                    get(note, 'documents', []).map((document: any) => (
                      <div key={document.id}>
                        <a href={documentUrlsMapped[document.id]} target='_blank' rel='noreferrer'>
                          {document.friendly_name}
                        </a>
                      </div>
                    ))}
                </Stack>
              </Stack>
            ))}
        </div>
      ) : null}
      {isModalOpen && (
        <CreateNoteModal
          isOpen={isModalOpen}
          close={() => setIsModalOpen(false)}
          resourceNoteWidgetOptions={resourceNoteWidgetOptions}
        />
      )}
    </>
  )
}

export default Notes
