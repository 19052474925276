import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { StoreState } from 'store'
import { Dispatch } from 'redux'

import { setErrorMessage, setErrorModalIsOpen } from 'state/modals/actions'
import { translateMessage as translate } from './errorMessages'
import { ActionButton } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'

const ErrorModal: FunctionComponent<
  React.PropsWithChildren<{
    isOpen: boolean
    errorMessage: string
    setErrorMessage: Function
    setIsOpen: Function
  }>
> = ({ isOpen, errorMessage, setIsOpen }) => {
  return (
    <SC.Modal isOpen={isOpen}>
      <SC.ErrorModalXCircle />
      {translate(errorMessage)}
      <ActionButton onClick={() => setIsOpen(false)}>Continue</ActionButton>
    </SC.Modal>
  )
}

const mapStateToProps = (state: StoreState) => ({
  isOpen: state.modals.errorModalIsOpen,
  errorMessage: state.modals.errorMessage
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setErrorMessage: (message: string) => dispatch(setErrorMessage(message)),
  setIsOpen: (isOpen: boolean) => dispatch(setErrorModalIsOpen(isOpen))
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal)
