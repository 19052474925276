import React from 'react'
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, HStack, useToast } from '@chakra-ui/react'
import { format as formatDate } from 'date-fns'
import { startCase } from 'lodash'
import { agreementSigners } from '@homevest/utils'

import { PendingAgreementsFragment } from 'graphql/generated'
import SignSection from 'components/RentRoll/Details/Agreements/SignSection'
import OtherActionsMenu from 'components/RentRoll/Details/Agreements/OtherActionsMenu'

const { AGREEMENT_SIGNER_STATUSES, TENANT_PREFIX } = agreementSigners

const VIRTUAL_AGREEMENT_STATUSES = {
  PENDING_CUSTOMER_SIGNATURE: 'Pending Customer Signature',
  PENDING_UPANDUP_SIGNATURE: 'Pending Up&Up Signature'
}

type PendingAgreements = PendingAgreementsFragment['pending_agreements']

const PendingAgreementsTable: React.FC<
  React.PropsWithChildren<{
    agreements: PendingAgreements
  }>
> = ({ agreements }) => {
  const toast = useToast()

  const onSign = async () => {
    toast({
      title: 'Agreement Signed',
      description: 'The agreement has been signed successfully!',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right'
    })
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Status</Th>
            <Th>Date Issued</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {agreements.map((agreement) => {
            const virtualStatus = mapAgreementToVirtualStatus(agreement)
            return (
              <Tr key={agreement.id}>
                <Td>{mapAgreementTypeToDisplayName(agreement.type)}</Td>
                <Td>{virtualStatus}</Td>
                <Td>{formatDate(new Date(agreement.created_at), 'MMM d, Y')}</Td>
                <Td>
                  <HStack>
                    {virtualStatus === 'Pending Up&Up Signature' ? (
                      <SignSection agreementWithSigners={agreement} onSign={onSign} />
                    ) : (
                      '-'
                    )}
                    <OtherActionsMenu pendingAgreementId={agreement.id} />
                  </HStack>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

const mapAgreementToVirtualStatus = (agreement: PendingAgreements[number]): string => {
  if (agreement.status !== 'pending') {
    return startCase(agreement.status)
  }

  const agreementSigners = agreement.agreement_signers

  const haveTenantsSigned = agreementSigners
    .filter((signer) => signer.role.startsWith(TENANT_PREFIX))
    .every((signer) => signer.status === AGREEMENT_SIGNER_STATUSES.SIGNED)

  return haveTenantsSigned
    ? VIRTUAL_AGREEMENT_STATUSES.PENDING_UPANDUP_SIGNATURE
    : VIRTUAL_AGREEMENT_STATUSES.PENDING_CUSTOMER_SIGNATURE
}

const mapAgreementTypeToDisplayName = (type: string): string => {
  switch (type) {
    case 'purchase_option_agreement_amendment':
      return 'POA Amendment'
    default:
      return startCase(type)
  }
}

export default PendingAgreementsTable
