import React, { FunctionComponent, useMemo } from 'react'
import HomesList from './HomesList'
import { useLocation } from 'react-router-dom'
import { Homes360View } from 'components/Homes/Grid/ColumnDefs'
import { Loading } from 'ui'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
const HomesGridPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const queryParams = useQuery()
  return (
    <React.Suspense fallback={<Loading />}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <HomesList view={(queryParams.get('gridView') || Homes360View.OVERVIEW) as Homes360View} />
      </div>
    </React.Suspense>
  )
}

export default HomesGridPage
