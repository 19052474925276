import axios from 'lib/axios'
import { VendorDetailsByIdQuery } from 'graphql/generated'

export type Vendor = NonNullable<VendorDetailsByIdQuery['vendors_by_pk']>

export type VendorDetailsEditState = {
  types: { label: string; value: string }[]
  markets: { label: string; value: string }[]
  rating: number | null
}

export const updateVendorDetails = async (
  vendorId: string,
  editPayload: VendorDetailsEditState
) => {
  const vendorTypeIds = editPayload.types.map((type) => type.value)
  const marketIds = editPayload.markets.map((market) => market.value)

  const { data } = await axios.put(`/admin/vendors/${vendorId}`, {
    internal_rating: editPayload.rating,
    vendor_type_ids: vendorTypeIds,
    market_ids: marketIds
  })

  return data
}

export const formatVendorTypes = (vendor: Vendor): string => {
  return vendor.vendors_vendor_types
    .map(({ vendor_type }) => vendor_type.display_name.replace('Contractors - ', ''))
    .join(', ')
}
