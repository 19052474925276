import React, { useState } from 'react'
import { Text, Link, HStack, Stack, Box, Spinner, Center, Button, useToast } from '@chakra-ui/react'
import { ArrowTopRightOnSquareIcon, ClipboardDocumentListIcon } from '@heroicons/react/20/solid'
import { format as formatDate } from 'date-fns'
import { verifications } from '@homevest/utils'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { useMostRelevantUserIdentityVerificationByUserQuery } from 'graphql/generated'
import ActionsMenu from './ActionsMenu'

const { VERIFICATION_STATUSES } = verifications

const IdentityVerificationDetails: React.FC<React.PropsWithChildren<{ userId: string }>> = ({
  userId
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const [{ data, fetching, error }, refetchVerifications] =
    useMostRelevantUserIdentityVerificationByUserQuery({
      variables: { userId }
    })

  if (fetching || isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  const copyVerificationLink = () => {
    navigator.clipboard.writeText(`https://app.upandup.co/get-verified?user_id=${userId}`)

    toast({
      title: 'Verification link copied!',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'bottom-right'
    })
  }

  const verification = data?.most_relevant_user_identity_verification_by_user.at(0)

  if (!verification) {
    return (
      <Stack>
        <Text>This user has not yet attempted to verify their identity!</Text>
        <Button
          leftIcon={<ClipboardDocumentListIcon className='h-4 w-4' />}
          onClick={copyVerificationLink}
        >
          Copy Verification Link
        </Button>
      </Stack>
    )
  }

  const startedAt = verification.created_at
  const completedAt = verification.completed_at

  const isFailed = verification.status === VERIFICATION_STATUSES.FAILED
  const isInvalidated = !!verification.invalidated_at

  const invalidatedAt = verification.invalidated_at
  const invalidatedByAdminName = `${verification.invalidated_by_admin?.first_name} ${verification.invalidated_by_admin?.last_name}`

  return (
    <>
      <Stack gap={'1'}>
        <div className='mb-2 flex flex-row flex-wrap justify-between'>
          <Link
            href={`https://dashboard.stripe.com/identity/verification-sessions/${verification.stripe_session_id}`}
            isExternal
          >
            <HStack>
              <Text className='underline' color='teal'>
                View in Stripe
              </Text>
              <ArrowTopRightOnSquareIcon color='teal' className='h-4 w-4' />
            </HStack>
          </Link>
          <ActionsMenu
            userId={userId}
            copyVerificationLink={copyVerificationLink}
            refetch={refetchVerifications}
            setIsLoading={setIsLoading}
          />
        </div>
        <div>
          <HStack>
            <Text as='b' whiteSpace={'nowrap'}>
              Status:
            </Text>
            {mapContentToBadge(verification.status!, { strikethrough: isInvalidated })}
          </HStack>
          {isInvalidated && (
            <Text as='i'>
              (Invalidated by {invalidatedByAdminName} on{' '}
              {formatDate(new Date(invalidatedAt), 'M/d/yy')})
            </Text>
          )}
        </div>

        <Text>
          <Text as='b'>Started At: </Text>
          {startedAt ? formatDate(new Date(startedAt), 'M/d/yy h:mm a') : '-'}
        </Text>
        <Text>
          <Text as='b'>Completed At: </Text>
          {completedAt ? formatDate(new Date(completedAt), 'M/d/yy h:mm a') : '-'}
        </Text>
        {isFailed && (
          <Box marginY={3}>
            <Text>
              <Text as='b'>Failure Message: </Text>
              {verification.failure_message ?? 'Unknown failure reason'}
            </Text>
          </Box>
        )}
      </Stack>
    </>
  )
}

const IdentityVerificationDetailsWrapper: React.FC<React.PropsWithChildren<{ userId: string }>> = ({
  userId
}) => {
  return (
    <ContentSectionCard title='Identity Verification' size='small' padding>
      <IdentityVerificationDetails userId={userId} />
    </ContentSectionCard>
  )
}

export default IdentityVerificationDetailsWrapper
