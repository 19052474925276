"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NON_RENTLY_SHOWING_TYPES = exports.SHOWING_TYPES = void 0;
exports.SHOWING_TYPES = {
    RENTLY_SELF_SHOWING: 'rently_standard',
    RENTLY_SAFE_MODE: 'rently_safe_mode',
    UPANDUP_IDENTITY_VERIFIED: 'upandup_identity_verified',
    ADT_SHOWING: 'adt_showing'
};
exports.NON_RENTLY_SHOWING_TYPES = [
    exports.SHOWING_TYPES.UPANDUP_IDENTITY_VERIFIED,
    exports.SHOWING_TYPES.ADT_SHOWING
];
