import React from 'react'
import { Box, Stack, Heading, Divider, Spinner } from '@chakra-ui/react'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/tabs'
import { Helmet } from 'react-helmet'

import RenewalManagerUpcomingView from 'components/RenewalManager/Views/Upcoming/UpcomingView'
import RenewalManagerPendingView from 'components/RenewalManager/Views/Pending/PendingView'
import RenewalManagerCompletedView from 'components/RenewalManager/Views/Completed/CompletedView'
import RenewalManagerReunderwritesView from 'components/RenewalManager/Views/Reunderwrites/ReunderwritesView'

const RenewalManager: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <Helmet>
        <title>Renewals</title>
      </Helmet>
      <Box w='100%' p={1} padding={5}>
        <Stack spacing={4}>
          <Heading as='h3' size='xl' width='100%'>
            Renewal Manager
          </Heading>
          <Divider />
        </Stack>
        <React.Suspense fallback={<Spinner />}>
          <Tabs>
            <TabList>
              <Tab>Upcoming</Tab>
              <Tab>In Progress</Tab>
              <Tab>Completed/Canceled</Tab>
              <Tab>Reunderwrites</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <RenewalManagerUpcomingView />
              </TabPanel>
              <TabPanel>
                <RenewalManagerPendingView />
              </TabPanel>
              <TabPanel>
                <RenewalManagerCompletedView />
              </TabPanel>
              <TabPanel>
                <RenewalManagerReunderwritesView />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </React.Suspense>
      </Box>
    </>
  )
}

export default RenewalManager
