import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import PropTypes from 'prop-types'

import CreateRentalAddOnForm from './Form'

CreateRentalAddOnModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  rentalId: PropTypes.string.isRequired
}

export default function CreateRentalAddOnModal({
  close,
  isOpen,
  rentalId,
  occupancyDate
}: {
  close: () => void
  isOpen: boolean
  rentalId: string
  occupancyDate: string
}) {
  return (
    <>
      <Modal toggle={close} isOpen={isOpen}>
        <ModalHeader toggle={close}>Create a Rental Add On</ModalHeader>
        <ModalBody>
          <CreateRentalAddOnForm
            onSubmit={close}
            rentalId={rentalId}
            occupancyDate={occupancyDate}
          />
        </ModalBody>
      </Modal>
    </>
  )
}
