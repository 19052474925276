import React, { FC, useContext, useState } from 'react'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import { format, parse } from 'date-fns'
import { useSelector } from 'react-redux'
import { capabilities, rentals } from '@homevest/utils'
import startCase from 'lodash/startCase'

import { Helmet } from 'react-helmet'
import { Badge, Text, Flex, HStack, Button, Input, IconButton } from '@chakra-ui/react'
import { CheckIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import Evictions from './Evictions'
import Notes from 'components/Notes'
import Ledger from './Ledger'
import Tenants from './Tenants'
import Documents from './Documents'
import Cashouts from './Cashouts'
import RentalAgreementHistories from './RentalAgreementHistories'
import MoveInPayment from './MoveInPayment'
import MoveInFees from './MoveInFees'
import LineItemsOwed from './LineItemsOwed'
import AddOns from './AddOns'
import BankAccount from './BankAccount'
import Renewals from './Renewals'
import PendingAgreements from './PendingAgreements'
import IssuePOAAmendmentView from './IssuePOAAmendmentView'
import MoveOut from './MoveOut'
import UtilityResponsibilities from './UtilityResponsibilities'
import { RentalDetailsContext, RentalDetailsContextProvider } from './RentalDetailsContext'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { getLlc, getRentForRental, getProgramLeaseTypeForRental } from 'lib/rentals'
import { convertDateToUTC } from 'lib/date-time'
import { hasCapability } from 'lib/admin-perms'
import { StoreState } from 'store'
import { useUpdateTargetMonthlyOptionPremiumMutation } from 'graphql/generated'

import NoteWidgetOption from 'types/NoteWidgetOption'
import { numbers } from '@homevest/utils'
import { Loading } from 'ui'

const DEFAULT_SIDEBAR_COMPONENTS = [
  Renewals,
  PendingAgreements,
  MoveOut,
  UtilityResponsibilities,
  BankAccount,
  LineItemsOwed,
  MoveInPayment,
  MoveInFees,
  AddOns,
  Tenants,
  RentalAgreementHistories,
  Documents
]

const CASHOUT_SIDE_BAR_COMPONENTS = [Cashouts, MoveOut, UtilityResponsibilities, Tenants, Documents]

const { LEDGER_VIEWER } = capabilities.CAPABILITY_TYPES
const { RENTAL_PROGRAM_LEASE_TYPES } = rentals

const RentAndOptionPremiumDisplay: FC<
  React.PropsWithChildren<{
    rentalId: string
    rent: any
    optionPremium: number
    rentalProgramType?: string
  }>
> = ({ rentalId, rent, optionPremium, rentalProgramType }) => {
  const [isEditingOptionPremium, setIsEditingOptionPremium] = useState(false)
  const [optionPremiumInput, setOptionPremiumInput] = useState(optionPremium)
  const [_, updateOptionPremium] = useUpdateTargetMonthlyOptionPremiumMutation()

  const rentLabel = `${numbers.formatMoney(rent, 0, '$')} rent`
  const optionPremiumLabel = `${numbers.formatMoney(optionPremium, 0, '$')} investment`

  const EditButton = (
    <>
      <Text fontSize='2xl'>{`${rentLabel} | ${optionPremiumLabel}`}</Text>
      <Button
        pl={1}
        size='sm'
        variant='unstyled'
        disabled={!!rentalProgramType && rentalProgramType !== RENTAL_PROGRAM_LEASE_TYPES.upandup}
        onClick={() => setIsEditingOptionPremium((x) => !x)}
      >
        <PencilSquareIcon height='1.5em' />
      </Button>
    </>
  )

  const EditOptionPremium = (
    <>
      <Text fontSize='2xl'>{`${rentLabel} | $`}</Text>
      <Input
        w='20'
        type='number'
        value={optionPremiumInput}
        onChange={(e) => setOptionPremiumInput(Number(e.target.value))}
      />
      <Text fontSize='2xl'>investment</Text>
      <IconButton
        aria-label='Set'
        icon={<CheckIcon height={24} width={24} />}
        size='sm'
        colorScheme='teal'
        variant='outline'
        onClick={async () => {
          await updateOptionPremium({
            id: rentalId,
            target_monthly_option_premium: optionPremiumInput * 100
          })
          setIsEditingOptionPremium((x) => !x)
        }}
      ></IconButton>
    </>
  )

  return <HStack>{isEditingOptionPremium ? EditOptionPremium : EditButton}</HStack>
}

function RentalView({ id }: { id: string }) {
  const { rentalData: rental } = useContext(RentalDetailsContext)
  const history = useHistory()

  const admin = useSelector<StoreState, any>((state) => state.admin)

  const canViewPage = hasCapability(admin, LEDGER_VIEWER)

  if (!canViewPage) {
    history.push('/review')
    return null
  }

  if (!rental) {
    return (
      <div>
        <Link to={{ pathname: '/rent-roll' }}>Back to Rent Roll</Link>
      </div>
    )
  }

  const addressLineOne = rental.portfolio_home?.home?.address?.display_line_1
  const addressLineTwo = rental.portfolio_home?.home?.address?.display_line_2

  const llc = getLlc(rental)

  const rentalProgramType = getProgramLeaseTypeForRental(rental)
  const { optionPremium, rent } = getRentForRental(rental)

  const leaseEndReason = rental.lease_end_reason
  const occupancyDate = rental.occupancy_date

  const resourceNoteWidgetOptions: NoteWidgetOption[] = []

  const rentalOption: NoteWidgetOption = {
    id: rental.id,
    type: 'rentals',
    writable: true,
    displayName: `Rental for ${addressLineOne}`
  }
  resourceNoteWidgetOptions.push(rentalOption)

  const collectionsOptions: NoteWidgetOption[] = rental.collections.map((c) => ({
    id: c.id,
    type: 'collections',
    writable: false,
    displayName: `Collection for ${format(parse(c.month, 'yyyy-MM-dd', new Date()), 'MMM yyyy')}`
  }))
  resourceNoteWidgetOptions.push(...collectionsOptions)

  const sidebarComponents = rental.cashout?.id
    ? CASHOUT_SIDE_BAR_COMPONENTS
    : DEFAULT_SIDEBAR_COMPONENTS
  return (
    <div>
      <Helmet>
        <title>Rental | {addressLineOne}</title>
      </Helmet>
      <Link to={'/rent-roll'}>Back to Rent Roll</Link>
      <div
        style={{
          display: 'flex',
          marginTop: '2rem',
          flexWrap: 'wrap',
          gap: '1rem'
        }}
      >
        <div
          style={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <Flex direction={'column'}>
            <Text fontSize={'3xl'}>
              <a href={'/home/' + rental.property.id} target='_blank'>
                {addressLineOne}
                <br />
              </a>
              {addressLineTwo}
            </Text>
            <Text fontSize='2xl'>{llc?.name || 'Need to backfill llc_property'}</Text>
            <RentAndOptionPremiumDisplay
              rentalId={rental.id}
              rent={rent}
              optionPremium={optionPremium}
              rentalProgramType={rentalProgramType}
            />
            <Flex direction='column' gap={1} my={3}>
              <HStack>
                {leaseEndReason && (
                  <Badge colorScheme='red' fontSize='xl' variant='outline'>
                    {startCase(leaseEndReason)}
                  </Badge>
                )}
                {rentalProgramType &&
                  mapContentToBadge(rentalProgramType, {
                    dot: true,
                    size: 'lg'
                  })}
              </HStack>
              <Evictions rental={rental} />
              <Text fontSize='xl'>Occupancy date: {convertAndDisplayDate(occupancyDate)}</Text>
            </Flex>
          </Flex>
          {/* Can remove this after all POA amendments are signed! */}
          <IssuePOAAmendmentView />
          {sidebarComponents.map((Component, index) => (
            <Component key={index} rental={rental} />
          ))}
          <Notes resourceNoteWidgetOptions={resourceNoteWidgetOptions} />
        </div>
        <div style={{ flex: 3, minWidth: '50%' }}>
          <Ledger rentalId={id} ledgerData={rental} />
        </div>
      </div>
    </div>
  )
}

function RentalDetails() {
  const { rentalId } = useParams<{ rentalId: string }>()

  return (
    <div style={{ margin: '0 1rem' }}>
      <React.Suspense fallback={<Loading />}>
        <RentalDetailsContextProvider rentalId={rentalId}>
          <RentalView id={rentalId} />
        </RentalDetailsContextProvider>
      </React.Suspense>
    </div>
  )
}

function convertAndDisplayDate(dateObject: any): string {
  return format(convertDateToUTC(dateObject), 'MMMM dd, yyyy')
}

export default RentalDetails
