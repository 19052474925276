import React from 'react'
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Button,
  FormErrorMessage
} from '@chakra-ui/react'

import DocumentUpload from './DocumentUpload'

type DocumentUploadWithAmountProps = {
  title: string
  instructions?: string
  uploadPrefix: string
  onUpload: (data: {
    isLoading: boolean
    downloadUrl: string
    file: File
    error?: string
    uploadPath: string
  }) => void
  onClear: () => void
  inputLabel: string
  amount: number | ''
  amountError?: string
  fileError?: string
  setAmount: (amount: number) => void
  isOptional?: boolean
  onRemove?: () => void
}

const DocumentUploadWithAmount = ({
  title,
  instructions,
  amount,
  setAmount,
  inputLabel,
  uploadPrefix,
  onUpload,
  onClear,
  isOptional,
  onRemove,
  amountError,
  fileError
}: DocumentUploadWithAmountProps) => {
  return (
    <>
      <div className='flex w-full flex-col items-center gap-3 md:flex-row'>
        <div className='md:w-2/3'>
          <FormControl isRequired isInvalid={!!fileError}>
            <FormLabel>{title}</FormLabel>
            {instructions && <Text mb={2}>{instructions}</Text>}
            <DocumentUpload uploadPrefix={uploadPrefix} onUpload={onUpload} onClear={onClear} />
            <FormErrorMessage>{fileError}</FormErrorMessage>
          </FormControl>
        </div>
        <div className='md:w-1/3'>
          <FormControl isRequired isInvalid={!!amountError}>
            <FormLabel>{inputLabel}</FormLabel>
            <InputGroup>
              <InputLeftAddon>$</InputLeftAddon>
              <Input
                type='number'
                placeholder={'1000'}
                value={amount}
                onWheel={(e) => e.currentTarget.blur()}
                onChange={(e) => {
                  setAmount(Number(e.target.value))
                }}
              />
            </InputGroup>
            <FormErrorMessage>{amountError}</FormErrorMessage>
          </FormControl>
        </div>
      </div>
      {isOptional && (
        <div>
          <Button colorScheme='red' variant='outline' onClick={onRemove} w='full'>
            Remove
          </Button>
        </div>
      )}
    </>
  )
}

export default DocumentUploadWithAmount
