import React, { FunctionComponent } from 'react'
import { types } from '@homevest/timelines'
import { HomeByIdQuery } from 'graphql/generated'
import Timeline from 'ui/Timeline'

type Home = HomeByIdQuery['homes360'][number]

type TimelinePropTypes = {
  events?: types.TimelineResponse['timeline']
  loading: boolean
  home: Home
}

export const TimelineCard: FunctionComponent<React.PropsWithChildren<TimelinePropTypes>> = ({
  home,
  events,
  loading
}) => {
  const homeDetails: types.HomeDetails = {
    address: home.display_line_1 ?? '',
    beds: home.beds ?? 0,
    baths: home.baths_total ?? 0,
    sqft: home.sqft?.toString() ?? '0',
    market: home.market?.display_name ?? '',
    city: home.city ?? '',
    state: home.state ?? '',
    zip: home.zip ?? '00000'
  }

  return (
    <Timeline
      homes={[homeDetails]}
      events={events}
      loading={loading}
      eventTypesToDisplay={['home_status']}
      hideFilter={true}
    />
  )
}
