import React, { memo } from 'react'
import PropTypes from 'prop-types'
import COLORS from '../colors'

function Checkmark({ width = '17', height = '12', color = COLORS.PRIMARY.COCONUT }) {
  return (
    <svg width={width} height={height} viewBox='0 0 17 12' fill='none'>
      <path
        d='M5.63079 8.7472L2.6902 6.13516C2.24635 5.7409 1.53969 5.7409 1.09584 6.13516C0.634721 6.54475 0.634721 7.22065 1.09584 7.63025L4.838 10.9543C5.28185 11.3486 5.98851 11.3486 6.43236 10.9543L15.9042 2.54079C16.3653 2.13119 16.3653 1.45529 15.9042 1.04569C15.4603 0.651463 14.7537 0.651435 14.3099 1.04561C14.3099 1.04564 14.3098 1.04567 14.3098 1.04569L5.63079 8.7472Z'
        fill={color}
        stroke={color}
        strokeWidth='0.5'
      />
    </svg>
  )
}

Checkmark.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default memo(Checkmark)
