"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAYMENT_METHODS = exports.AUTO_CHARGE_RETRY_DAYS_OF_MONTH = exports.AUTO_CHARGE_DAY_OF_MONTH = exports.AUTO_CHARGE_HOUR_UTC = exports.PAYMENT_LIFECYCLE = exports.PAYMENT_STATUSES = exports.PAYMENT_CATEGORIES = exports.PAYMENT_METHOD_STATUSES = exports.PAYMENT_METHOD_TYPES = void 0;
exports.PAYMENT_METHOD_TYPES = Object.freeze({
    ACH: 'ach',
    CARD: 'card',
    CHECK: 'check'
});
exports.PAYMENT_METHOD_STATUSES = Object.freeze({
    ACTIVE: 'active',
    PENDING_REAUTH: 'pending_reauth',
    PENDING: 'pending',
    INACTIVE: 'inactive'
});
exports.PAYMENT_CATEGORIES = Object.freeze({
    ADD_ON: 'add_on',
    INITIAL_PAYMENT: 'initial_payment',
    LATE_FEE: 'late_fee',
    MAINTENANCE_CHARGEBACK: 'maintenance_chargeback',
    MISCELLANEOUS_FEE: 'miscellaneous_fee',
    NSF_FEE: 'nsf_fee',
    OPTION_PREMIUM: 'option_premium',
    RENT: 'rent',
    SECURITY_DEPOSIT: 'security_deposit',
    UTILITIES: 'utilities'
});
exports.PAYMENT_STATUSES = Object.freeze({
    DISPUTED: 'disputed',
    FAILED: 'failed',
    HOLD: 'hold',
    INTENDED: 'intended',
    NSF_FAILED: 'nsf_failed',
    PENDING: 'pending',
    PROCESSING: 'processing',
    REFUNDED: 'refunded',
    SUCCEEDED: 'succeeded'
});
exports.PAYMENT_LIFECYCLE = Object.freeze({
    QUEUED: 'queued',
    INITIATED: 'initiated',
    INITIALLY_CLEARED: 'initially_cleared',
    CLEARED: 'cleared',
    NOT_CLEARED: 'not_cleared'
});
exports.AUTO_CHARGE_HOUR_UTC = 15; // 10 AM EST
exports.AUTO_CHARGE_DAY_OF_MONTH = 1;
exports.AUTO_CHARGE_RETRY_DAYS_OF_MONTH = [3, 8, 13];
/** Warning: to be deprecated */
exports.PAYMENT_METHODS = exports.PAYMENT_METHOD_TYPES;
