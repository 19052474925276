import React, { useState, useEffect } from 'react'
import { GridReadyEvent, GridApi } from 'ag-grid-community'

import { BaseGrid } from 'components/TailwindUIToolkit'
import { COMPLETED_COLUMNS } from '../../Grid/completed-configuration'
import { CompletedRenewal } from '@homevest/types/renewals'

interface RentalsToRenewTableProps {
  data: CompletedRenewal[]
  setSelectedRental?: (rental: any) => void
}

const RentalsToRenewTable: React.FC<React.PropsWithChildren<RentalsToRenewTableProps>> = ({
  data,
  setSelectedRental
}) => {
  const [gridApi, setGridApi] = useState<GridApi<CompletedRenewal>>()

  const onGridReady = (evt: GridReadyEvent<CompletedRenewal>) => {
    setGridApi(evt.api)
  }

  const gridSetup = (evt: GridReadyEvent<CompletedRenewal>) => {
    setGridApi(evt.api)
    onGridReady(evt)
  }

  useEffect(() => {
    if (gridApi && data) {
      try {
        gridApi.setRowData(data)
        gridApi.sizeColumnsToFit()
      } catch {
        console.log('Ran into an error drawing the grid, you may need to refresh the page')
      }
    }
  }, [gridApi, data])

  return (
    <>
      <div className='ag-theme-material h-[70vh] border'>
        <BaseGrid<CompletedRenewal>
          optionOverrides={{
            defaultColDef: {
              width: undefined
            },
            rowHeight: 45
          }}
          columns={COMPLETED_COLUMNS}
          rowData={data}
          onGridReady={gridSetup}
          onRowClicked={(evt) => setSelectedRental?.(evt.data)}
          onGridSizeChanged={() => gridApi && gridApi.sizeColumnsToFit()}
        />
      </div>
    </>
  )
}

export default RentalsToRenewTable
