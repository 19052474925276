import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import { startCase } from 'lodash'
import moment from 'moment'
import { useMutation } from 'urql'

import { Dropdown, Button, Input, ErrorText } from 'components/Toolkit'
import { Upup_CreateRentalAgreementHistoryDocument as CreateRentalAgreementHistory } from 'graphql/generated'

import { agreements } from '@homevest/utils'

const AGREEMENT_TYPES_OPTIONS = Object.values(agreements.AGREEMENT_TYPES).map((type) => ({
  label: startCase(type),
  value: type
}))
export default function CreateRentalAgreementHistoryForm({
  onSubmit = () => null,
  rentalId
}: {
  onSubmit: () => void
  rentalId: string
}) {
  const [rent, setRent] = useState('')
  const [agreementType, setAgreementType] = useState('')
  const [startsAt, setStartsAt] = useState(moment().format('YYYY-MM-DD'))
  const [endsAt, setEndsAt] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const [{ error }, createRentalAgreementHistory] = useMutation(CreateRentalAgreementHistory)

  const numericRent = rent ? Number(rent) : null
  const momentStartsAt = startsAt ? moment(startsAt) : null
  const momentEndsAt = endsAt ? moment(endsAt) : null

  const isValid =
    numericRent &&
    !Number.isNaN(numericRent) &&
    momentStartsAt &&
    momentStartsAt.isValid() &&
    momentEndsAt &&
    momentEndsAt.isValid() &&
    momentStartsAt.isSameOrBefore(momentEndsAt) &&
    agreementType

  const submitRentalAgreementHistory = async () => {
    if (!isValid) {
      return
    }

    setIsSaving(true)

    const result = await createRentalAgreementHistory({
      ends_at: momentEndsAt.format('YYYY-MM-DD'),
      rental_id: rentalId,
      rent: numericRent,
      type: agreementType,
      starts_at: momentStartsAt.format('YYYY-MM-DD')
    })

    setIsSaving(false)
    if (result.error === undefined) {
      setRent('')
      setStartsAt('')
      setEndsAt('')
      setAgreementType('')
      onSubmit()
    }
  }

  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <Input
          label='Rent'
          type='number'
          onChange={setRent}
          value={rent}
          placeholder='Enter the rent'
          required
          min='0'
          hasError={Number(rent) <= 0}
          disabled={isSaving}
        />
        <Input
          label='Starts at'
          type='date'
          onChange={setStartsAt}
          value={startsAt}
          placeholder='Enter the date the new rent starts'
          required
          disabled={isSaving}
          hasError={!startsAt || !momentStartsAt?.isValid()}
        />
        <Input
          label='Ends at'
          type='date'
          onChange={setEndsAt}
          value={endsAt}
          placeholder='Enter the date the new rent ends'
          required
          disabled={isSaving}
          hasError={!endsAt || !momentEndsAt?.isValid()}
        />
        <Dropdown
          label='Agreement Type'
          showSelectText={true}
          value={agreementType}
          onChange={setAgreementType}
          options={AGREEMENT_TYPES_OPTIONS}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            isSecondary={false}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={submitRentalAgreementHistory}
            disabled={!isValid || isSaving}
          >
            Create Rental Agreement History
          </Button>
          {error && <ErrorText>{error.message}</ErrorText>}
        </div>
      </React.Suspense>
    </>
  )
}
