import { roundToNearest } from './numbers'

// TODO: Move these somewhere better
const MIN_INCREASE_PERCENT = 0.04
const MAX_INCREASE_PERCENT = 0.08
const SIX_MONTH_INCREASE_MULIPLIER = 1.5
const MONTH_TO_MONTH_INCREASE_MULTIPLIER = 2.75

export type RenewalOfferItem = {
  term: number
  price: number
  isMonthToMonth: boolean
}

export type RenewalOffersByTerm = Record<number, RenewalOfferItem>

export const buildDefaultRenewalOffers = (
  currentRent: number,
  marketRent: number,
  minIncreasePercent: number = MIN_INCREASE_PERCENT,
  maxIncreasePercent: number = MAX_INCREASE_PERCENT
): RenewalOffersByTerm => {
  let twelveMonthRent: number = marketRent

  // We want to get as close to market as possible while maintaining
  // reasonable rent growth numbers for fund performance
  const marketIncreasePercentage = (marketRent - currentRent) / currentRent

  if (marketIncreasePercentage < minIncreasePercent) {
    twelveMonthRent = currentRent * (1 + minIncreasePercent)
  } else if (marketIncreasePercentage > maxIncreasePercent) {
    twelveMonthRent = currentRent * (1 + maxIncreasePercent)
  }

  const twelveMonthIncreasePercentage = roundToNearest(
    (twelveMonthRent - currentRent) / currentRent,
    0.01,
    2
  )

  const sixMonthRent =
    currentRent * (1 + twelveMonthIncreasePercentage * SIX_MONTH_INCREASE_MULIPLIER)
  const monthToMonthRent =
    currentRent * (1 + twelveMonthIncreasePercentage * MONTH_TO_MONTH_INCREASE_MULTIPLIER)

  return {
    12: {
      term: 12,
      price: roundToNearest(twelveMonthRent, 5),
      isMonthToMonth: false
    },
    6: {
      term: 6,
      price: roundToNearest(sixMonthRent, 5),
      isMonthToMonth: false
    },
    1: {
      term: 1,
      price: roundToNearest(monthToMonthRent, 5),
      isMonthToMonth: true
    }
  }
}
