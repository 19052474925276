import React from 'react'
import { Transition } from '@headlessui/react'

const slideDownTransitionClasses = {
  enter: 'transform transition ease-in-out duration-300',
  enterFrom: 'translate-y-[-100%]',
  enterTo: 'translate-y-0',
  leave: 'transform transition ease-in-out duration-300',
  leaveFrom: 'translate-y-0',
  leaveTo: 'translate-y-[-100%]'
}

export const SlideDownTransition: React.FC<React.PropsWithChildren<{ show: boolean }>> = ({
  show,
  children
}) => {
  return (
    <Transition show={show} {...slideDownTransitionClasses}>
      {children}
    </Transition>
  )
}
