import React, { FunctionComponent } from 'react'
import { useQuery } from 'urql'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import { Spinner } from 'reactstrap'
import axios from 'lib/axios'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import { REQUEST_POLICY } from 'constants/urql'
import { DocumentsByUserIdDocument as queryDoc } from 'graphql/generated'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { DocumentType } from '../types'
import ReviewButtons from './ReviewButtons'

import { documents } from '@homevest/utils'
import { Button } from '@chakra-ui/react'
const { DOCUMENT_STATUSES } = documents

type DocumentsComponentPropTypes = {
  userId: string
}

const DocumentsWrapper: FunctionComponent<React.PropsWithChildren<DocumentsComponentPropTypes>> = (
  props
) => (
  <ContentSectionCard title={'Documents'} size={'large'}>
    <Documents {...props} />
  </ContentSectionCard>
)

const Documents: FunctionComponent<React.PropsWithChildren<DocumentsComponentPropTypes>> = ({
  userId
}) => {
  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query: queryDoc,
    variables: { id: userId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    context: React.useMemo(() => ({ suspense: false }), [])
  })

  const documents = data?.documents

  if (fetching) {
    return <Spinner />
  }

  if (error) {
    return <SC.ErrorMessage>{error.message}</SC.ErrorMessage>
  }

  if (!documents?.length) {
    return (
      <SC.StyledTable>
        <tbody>
          <SC.StyledTableRow>
            <SC.StyledTableData>No documents found for user</SC.StyledTableData>
          </SC.StyledTableRow>
        </tbody>
      </SC.StyledTable>
    )
  }

  return (
    <SC.StyledTable>
      <SC.StyledTableHeader>
        <tr>
          <SC.StyledTableHeadData>NAME</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>STATUS</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>UPLOADED</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>REVIEWED</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>REVIEWER</SC.StyledTableHeadData>
          {/* placeholder for review buttons */}
          <SC.StyledTableHeadData />
          <SC.StyledTableHeadData />
        </tr>
      </SC.StyledTableHeader>
      <tbody>
        {documents.map((d: DocumentType) => (
          <SC.StyledTableRow key={d.id}>
            <SC.StyledTableData center>
              <Button
                fontWeight='medium'
                fontSize='sm'
                textColor='black'
                variant='link'
                onClick={async () => {
                  const res = await axios.get(`/admin/documents/${d.id}/url`)

                  if (res.data.url) {
                    window.open(res.data.url, '_blank')
                  }
                }}
              >
                {startCase(d.friendly_name)}
              </Button>
            </SC.StyledTableData>
            <SC.StyledTableData center>
              {mapContentToBadge(d.status, { dot: false })}
            </SC.StyledTableData>
            <SC.StyledTableData center>{format(new Date(d.created_at), 'PP')}</SC.StyledTableData>
            <SC.StyledTableData center>
              {d.reviewed_at ? format(new Date(d.reviewed_at), 'PP') : 'N/A'}
            </SC.StyledTableData>
            <SC.StyledTableData center>
              {d.reviewed_by_admin_id ? `${d.admin?.first_name} ${d.admin?.last_name}` : 'N/A'}
            </SC.StyledTableData>
            {d.status === DOCUMENT_STATUSES.PENDING_REVIEW ? (
              <SC.StyledTableData center>
                <ReviewButtons docId={d.id} reexecuteQuery={reexecuteQuery} />
              </SC.StyledTableData>
            ) : null}
            <SC.StyledTableData center />
          </SC.StyledTableRow>
        ))}
      </tbody>
    </SC.StyledTable>
  )
}

export default DocumentsWrapper
