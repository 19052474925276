import React, { FunctionComponent } from 'react'
import { types } from '@homevest/timelines'
import { Customer } from './types'
import Timeline from 'ui/Timeline'

type UserTimelinePropTypes = {
  customer: Customer
  timelineResponse?: types.TimelineResponse
  loading: boolean
}

const UserTimeline: FunctionComponent<React.PropsWithChildren<UserTimelinePropTypes>> = (props) => (
  <Timeline
    {...props}
    events={props.timelineResponse?.timeline}
    homes={props.timelineResponse?.properties}
    eventTypesToDisplay={['call', 'email', 'inquiry', 'note', 'rental_application', 'sms', 'tour']}
  />
)

export default UserTimeline
