import React from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Button, Tooltip } from '@chakra-ui/react'

export const InfoTooltip = ({
  label,
  iconSize = 24
}: {
  label: JSX.Element | string
  iconSize?: number
}) => {
  return (
    <Tooltip hasArrow label={label} placement='right' maxWidth='xl'>
      <Button variant='ghost' size={'lg'} px={2}>
        <InformationCircleIcon height={iconSize} />
      </Button>
    </Tooltip>
  )
}
