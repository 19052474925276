import React from 'react'
import { Stack } from '@chakra-ui/react'
import { GridReadyEvent } from 'ag-grid-community'
import ChecksTableHeader from './ChecksTableHeader'

import ChecksGrid from './Grid/ChecksGrid'
import { mapTableTypeToColumnSet } from './Grid/configuration'
import { RentalSiteChecksData, RentalSiteChecksDataType } from 'types/RentalSiteChecker'

type SuccessfulChecksTableProps = {
  handleMuteAction?: undefined
  tableType: 'successful'
  onGridReady: (evt: GridReadyEvent<RentalSiteChecksDataType>) => void
  checksToDisplay: RentalSiteChecksData
}

type MutedOrFailedChecksTableProps = {
  handleMuteAction: (id: string) => Promise<void>
  tableType: 'failed' | 'muted'
  onGridReady: (evt: GridReadyEvent<RentalSiteChecksDataType>) => void
  checksToDisplay: RentalSiteChecksData
}

type ChecksTableProps = SuccessfulChecksTableProps | MutedOrFailedChecksTableProps

const ChecksTable: React.FC<React.PropsWithChildren<ChecksTableProps>> = ({
  handleMuteAction,
  tableType,
  checksToDisplay,
  onGridReady
}) => {
  let rowData = checksToDisplay

  return (
    <Stack spacing={5}>
      <ChecksTableHeader tableType={tableType} checkCount={checksToDisplay.length} />
      <ChecksGrid
        isInitialGrid={tableType === 'failed'}
        columns={mapTableTypeToColumnSet(tableType, {
          handleMuteAction
        })}
        rowData={rowData}
        onGridReady={onGridReady}
      />
    </Stack>
  )
}

export default ChecksTable
