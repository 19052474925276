import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'

import { Spinner, Table } from 'reactstrap'
import styled from 'styled-components'

import { Dropdown, HeaderText, ErrorText, SuccessText } from 'components/Toolkit'
import { useQuery } from 'urql'
import {
  Kafka_Connector_Prorated_Utility_Bill_Line_Items,
  UpUpPropertyInformationForUtilityBillsDocument,
  useUpUpAddProcessedUtilityBillMutation,
  useUpupProcessedUtilityBillsQuery
} from 'graphql/generated'
import moment from 'moment'
import { parseUtilityBillData } from './utility_chargebacks'
import _ from 'lodash'
import { Box, Button } from '@chakra-ui/react'
import { hasCapability } from 'lib/admin-perms'
const { DAGGER } = capabilities.CAPABILITY_TYPES
const CONSERVICE = 'conservice'
const BILL_TYPES = Object.freeze({
  Conservice: CONSERVICE
})

const StyledContent = styled.div`
  width: 90%;
  margin: 0 auto;
`

export default function ProrationEngineUploader() {
  const isDagger = true
  useSelector((store: any) => hasCapability(store.admin, DAGGER))

  if (!isDagger) {
    return <ErrorText>You do not have the capability of DAGGER.</ErrorText>
  }

  return (
    <StyledContent>
      <HeaderText size='h3' style={{ textAlign: 'center' }}>
        Proration Engine Uploader
      </HeaderText>
      <React.Suspense fallback={<Spinner color='primary' />}>
        <ProrationEngineUploaderViewer />
      </React.Suspense>
    </StyledContent>
  )
}

function ProrationEngineUploaderViewer() {
  const [billType, setBillType] = useState<string>(CONSERVICE)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [{ data }] = useQuery({
    query: UpUpPropertyInformationForUtilityBillsDocument
  })

  const [result, setResult] = useState<any>(null)
  const [fetchAllBills, setFetchAllBills] = useState(false) // only fetch 1 if false, otherwise all
  const [propertyData, setPropertyData] = useState<any>()

  useEffect(() => {
    setPropertyData(data)
  }, [data])

  // const [showAllRows, setShowAllRows] = React.useState<{
  //   buildium: boolean[]
  //   rental_liabilities: boolean[]
  // }>({ buildium: [false], rental_liabilities: [false] })

  const [processed_utility_bills, set_processed_utility_bills] = useState<
    Kafka_Connector_Prorated_Utility_Bill_Line_Items[]
  >([])

  const [showAllRowsNew, setShowAllRowsNew] = React.useState<{}>({})

  useEffect(() => {
    setShowAllRowsNew(
      processed_utility_bills.map((bill) =>
        Object.keys(bill).reduce((arr, key) => {
          if (key == 'id') {
            return arr
          }
          _.set(arr, `${bill.id} ${key}`, false)
          return arr
        }, {})
      )
    )
  }, [processed_utility_bills])

  const [{ data: processedBills }] = useUpupProcessedUtilityBillsQuery({
    variables: {
      limit: fetchAllBills ? undefined : 1
    }
  })

  const [{ data: submissionData }, saveProcessedUtilityBill] =
    useUpUpAddProcessedUtilityBillMutation()

  React.useEffect(() => {
    const lineItems = []

    if (submissionData?.insert_kafka_connector_prorated_utility_bill_line_items_one != null) {
      lineItems.push(submissionData.insert_kafka_connector_prorated_utility_bill_line_items_one)
    }
    if (processedBills?.kafka_connector_prorated_utility_bill_line_items != null) {
      lineItems.push(...processedBills.kafka_connector_prorated_utility_bill_line_items)
    }
    set_processed_utility_bills(lineItems)
  }, [processedBills, submissionData])

  const handleSuccess = async (csv_data: string) => {
    try {
      setIsLoading(true)
      setSuccessMessage('')
      setResult(
        await parseUtilityBillData(
          csv_data,
          {
            ...propertyData
          },
          saveProcessedUtilityBill
        )
      )
    } catch (err: any) {
      if (err.isAxiosError) {
        setErrorMessage(err.response.data)
      } else {
        setErrorMessage(err.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const FileUploadPage = () => {
    const [selectedFile, setSelectedFile] = useState<any>()
    const [isSelected, setIsSelected] = useState<any>(false)

    const changeHandler = (event: any) => {
      setSelectedFile(event.target.files[0])
      setIsSelected(true)
    }

    const handleSubmission = () => {
      const reader = new FileReader()
      reader.onload = (event) =>
        event && event.target ? handleSuccess(event?.target?.result as string) : null
      reader.onerror = (error) => console.log(error)
      reader.readAsText(selectedFile)
    }

    return (
      <div>
        <input type='file' name='file' onChange={changeHandler} />
        {isSelected ? (
          <div>
            <p>Filename: {selectedFile.name}</p>
            <p>Filetype: {selectedFile.type}</p>
            <p>Size in bytes: {selectedFile.size}</p>
            <p>lastModifiedDate: {selectedFile.lastModifiedDate.toLocaleDateString()}</p>
          </div>
        ) : (
          <p>Select a file to show details</p>
        )}
        <div>
          <button onClick={handleSubmission}>Submit</button>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <Spinner color='primary' style={{ textAlign: 'center' }} />
  }

  if (successMessage) {
    return (
      <div>
        <SuccessText>{successMessage}</SuccessText>
      </div>
    )
  }

  function CSVToArray(strData: string, strDelimiter: string = ',') {
    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
      // Delimiters.
      '(\\' +
        strDelimiter +
        '|\\r?\\n|\\r|^)' +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' +
        strDelimiter +
        '\\r\\n]*))',
      'gi'
    )

    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData: string[][] = [[]]

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while ((arrMatches = objPattern.exec(strData))) {
      // Get the delimiter that was found.
      var strMatchedDelimiter = arrMatches[1]

      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        // Since we have reached a new row of data,
        // add an empty row to our data array.
        arrData.push([])
      }

      var strMatchedValue

      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      if (arrMatches[2]) {
        // We found a quoted value. When we capture
        // this value, unescape any double quotes.
        strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"')
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3]
      }

      // Now that we have our value string, let's add
      // it to the data array.
      arrData[arrData.length - 1].push(strMatchedValue)
    }

    // Return the parsed data.
    return arrData
  }

  // const columnToDetails = {
  //   buildium_csv: { header: 'Buildium Output' },
  //   potential_duplicates_csv: {
  //     header: 'Potential Duplicate Line Items',
  //     details:
  //       'These rows of the csv have NOT been added to the outputs since they contain the same external property id, reference_number, and memo. If you want to '
  //   },
  //   known_liabilities_do_not_cover_full_bill_csv: {
  //     header: 'Remaining Balance After Payments',
  //     details:
  //       'These are line items that will have a greater than $0.00 balance after taking into account all known responsible parties (funds, tenants, etc).'
  //   },
  //   unknown_memos: {
  //     header: 'Charges Without Charge Numbers',
  //     details:
  //       'These memos do not have known mappings to internal charge codes. While the chrages have been processed and included in the output, the charge codes will need to be added.'
  //   },
  //   original_upload: { header: 'Original Upload' },
  //   rental_liabilities_csv: { header: 'Rental Liabilities' }
  // }

  return (
    <div>
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      <h1>Upload a bill to the proration engine.</h1>

      {result}

      <Dropdown
        label='Bill Type'
        value={billType}
        onChange={(val) => {
          setBillType(val)
        }}
        options={Object.entries(BILL_TYPES).map(([key, value]) => {
          return {
            label: key,
            value
          }
        })}
      />

      <FileUploadPage />

      <Box display='flex' justifyContent='space-between' flexDir='row'>
        <h1>Processed Files</h1>
        <Button onClick={() => setFetchAllBills(!fetchAllBills)}>Toggle Fetch All</Button>
      </Box>
      <Table responsive='true'>
        <thead>
          <tr>
            <th>Links</th>
            <th>Csv Samples</th>
          </tr>
        </thead>

        <tbody>
          {processed_utility_bills.map(
            ({
              id,
              buildium_csv,
              potential_duplicates_csv,
              known_liabilities_do_not_cover_full_bill_csv,
              // unknown_memos,
              original_upload,
              created_at,
              rental_liabilities_csv,
              transformations
            }) => {
              const downloadableCsvs = Object.entries({
                original_upload,
                buildium_csv,
                rental_liabilities_csv,
                potential_duplicates_csv,
                known_liabilities_do_not_cover_full_bill_csv
              }).map(([name, csv]) => {
                const downloadableBlob = new Blob([csv || ''], {
                  type: 'text/csv'
                })
                const downloadUrl = URL.createObjectURL(downloadableBlob)

                let csvData: string[][] = [[]]

                if (csv && csv.length > 10) {
                  csvData = CSVToArray(csv)
                }

                return {
                  id,
                  name,
                  downloadUrl,
                  data: csvData
                }
              })

              // const buildiumCSV = CSVToArray(buildium_csv, ',')

              // const buildiumCsvFile = new Blob([buildium_csv], {
              //   type: 'text/csv'
              // })
              // const buildium_file_url = URL.createObjectURL(buildiumCsvFile)

              // const rentalLiabilitiesCsv = CSVToArray(
              //   rental_liabilities_csv,
              //   ','
              // )
              // const rentalLiabilitiesCsvFile = new Blob(
              //   [rental_liabilities_csv],
              //   {
              //     type: 'text/csv'
              //   }
              // )
              // const rental_liabilities_file_url = URL.createObjectURL(
              //   rentalLiabilitiesCsvFile
              // )

              const transformationsFile = new Blob(
                [JSON.stringify(JSON.parse(transformations), null, 4)],
                {
                  type: 'text/plain'
                }
              )
              const transformations_file_url = URL.createObjectURL(transformationsFile)

              const toggleNew = (_key: string) => {
                return (
                  <h6
                    onClick={() => {
                      setShowAllRowsNew({
                        ...showAllRowsNew,
                        [_key]: !_.get(showAllRowsNew, _key)
                      })
                    }}
                  >
                    Toggle View All
                  </h6>
                )
              }

              return (
                <tr key={created_at}>
                  <td scope='row' style={{ minWidth: 280 }}>
                    {downloadableCsvs.map(({ name, downloadUrl }) => {
                      return (
                        <>
                          <a href={downloadUrl} download={name}>
                            Download{' '}
                            {name
                              .split('_')
                              .map((val) => val[0].toUpperCase() + val.slice(1))
                              .join(' ')}
                          </a>
                          <div></div>
                        </>
                      )
                    })}

                    <br />

                    <p>Processed on: {moment(created_at).format('MMM D, YYYY')}</p>

                    <a href={transformations_file_url} download={'utility-calculations'}>
                      Download Calculations
                    </a>
                    <br />
                  </td>

                  <td>
                    {downloadableCsvs
                      .map(({ name, data, id }) => {
                        const toggleId = `${id} ${name}`
                        return {
                          name,
                          csv: data,
                          toggleButton: toggleNew(toggleId),
                          toggleId
                        }
                      })
                      .map(
                        ({
                          name,
                          csv,
                          toggleButton,
                          toggleId
                        }: {
                          name: string
                          csv: string[][]
                          toggleId: string
                          toggleButton: JSX.Element
                        }) => {
                          return createCSVSample(name, toggleId, toggleButton, csv)
                        }
                      )}
                  </td>
                </tr>
              )
            }
          )}
        </tbody>
      </Table>
    </div>
  )

  function createCSVSample(
    name: string,
    toggleId: string,
    toggleButton: JSX.Element,
    csv: string[][]
  ): JSX.Element {
    if (!csv[0].length) {
      return <></>
    }
    return (
      <div>
        <h3>
          {name
            .split('_')
            .map((val) => val[0].toUpperCase() + val.slice(1))
            .join(' ')}
        </h3>
        {toggleButton}
        <Table responsive='true'>
          <thead>
            <tr>
              {csv ? csv[0].map((headerVal) => <th>{headerVal}</th>) : <th>No Items to Display</th>}
            </tr>
          </thead>

          <tbody>
            {csv ? (
              (_.get(showAllRowsNew, toggleId) ? csv.slice(1) : csv.slice(1, 5)).map(
                (row: string[]) => {
                  return (
                    <tr>
                      {row.map((val: string) => (
                        <td>
                          <p style={{ minWidth: 100 }}>{val}</p>
                        </td>
                      ))}
                    </tr>
                  )
                }
              )
            ) : (
              <tr>No Data</tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}
