import {
  HStack,
  FormControl,
  Checkbox,
  FormLabel,
  NumberInput,
  NumberInputField,
  Textarea,
  Text,
  FormErrorMessage
} from '@chakra-ui/react'
import { formatMoney } from 'lib/numbers'
import React, { useState } from 'react'

interface LiabilityChargeRowProps {
  liabilityLabel: string
  amount: number
  enabled: boolean
  note?: string
  updateAmount: (amount: number) => void
  updateNote: (note: string) => void
  updateEnabled: (enabled: boolean) => void
}

const LiabilityChargeRow: React.FC<React.PropsWithChildren<LiabilityChargeRowProps>> = ({
  liabilityLabel,
  amount,
  note,
  enabled,
  updateAmount,
  updateNote,
  updateEnabled
}) => {
  // form row where you can change the amount of the liability
  const [maxAmount, _] = useState(amount)
  return (
    <HStack>
      <FormControl>
        <Checkbox isChecked={enabled} onChange={(e) => updateEnabled(e.target.checked)}></Checkbox>
      </FormControl>
      <FormControl>
        <Text>{liabilityLabel}</Text>
      </FormControl>
      <FormControl isDisabled={!enabled} isInvalid={!amount || amount <= 0}>
        <FormLabel>Amount (Bal: {formatMoney(maxAmount, 2, '$')})</FormLabel>
        <NumberInput
          defaultValue={amount}
          onBlur={(val) => {
            const numVal = parseFloat(val.target.value.replace('$', ''))
            updateAmount(Math.min(numVal, maxAmount))
          }}
          precision={2}
          format={(val) => `$${val}`}
          isInvalid={!amount || amount <= 0}
          max={maxAmount}
          step={0.01}
        >
          <NumberInputField />
        </NumberInput>
        <FormErrorMessage pb={1}>
          Enter an amount greater than 0 or deselect this liability
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isDisabled={!enabled}>
        <FormLabel>Note</FormLabel>
        <Textarea
          size='sm'
          value={note || ''}
          placeholder='Insert note...'
          onChange={(e) => updateNote(e.target.value)}
          isInvalid={!note || !note.trim()}
        />
      </FormControl>
    </HStack>
  )
}

export default LiabilityChargeRow
