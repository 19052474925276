import React, { Fragment, FunctionComponent, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import {
  Box,
  Checkbox,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Text
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/20/solid'

export type MultiSelectOption = { id: string; label: string }

type Props = {
  value: string[]
  options: MultiSelectOption[]
  onChange: (selected: string[]) => void
}

const MultiSelect: FunctionComponent<React.PropsWithChildren<Props>> = ({
  value,
  options,
  onChange
}) => {
  const [query, setQuery] = useState('')

  const labelMap: { [id: string]: string } = options.reduce(
    (acc, { id, label }) => ({ ...acc, [id]: label }),
    {}
  )

  const filtered =
    query.length > 0 ? options.filter((item) => isSimpleMatch(item.label, query)) : options

  return (
    <Combobox value={value} onChange={onChange} multiple>
      {({ open }) => (
        <Box position='relative'>
          <Box position='relative' w='full' cursor='default' borderRadius='md'>
            <InputGroup>
              <Combobox.Input
                as={Input}
                variant='filled'
                borderRadius='md'
                size='sm'
                placeholder='Select...'
                paddingInlineEnd={10}
                displayValue={(items: string[]) => items?.map((item) => labelMap[item]).join(',')}
                onChange={({ target }) => setQuery(target.value)}
                autoComplete='off'
              />
              <InputRightElement h='full' justifyContent='end' gap={1} right={2}>
                {value.length > 0 && (
                  <IconButton
                    variant='solid'
                    aria-label='Clear results'
                    size='xs'
                    onClick={() => {
                      onChange([])
                      setQuery('')
                    }}
                    icon={
                      <Box h={4} w={4}>
                        <XMarkIcon />
                      </Box>
                    }
                  />
                )}
                <Combobox.Button
                  as={IconButton}
                  size='xs'
                  variant='solid'
                  aria-label='Narrow options'
                  icon={
                    <Box h={4} w={4}>
                      {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </Box>
                  }
                />
              </InputRightElement>
            </InputGroup>
          </Box>
          <Transition as={Fragment} afterLeave={() => setQuery('')}>
            <Combobox.Options
              as={List}
              position='absolute'
              maxH={32}
              w='full'
              minW={48}
              overflow='auto'
              borderRadius='md'
              bg='gray.50'
              textTransform='none'
              zIndex={1}
            >
              {filtered.length === 0 && query.length > 0 && (
                <Text p={3} whiteSpace='normal'>
                  Nothing found.
                </Text>
              )}
              {filtered.map((item) => (
                <Combobox.Option as={ListItem} key={item.id} value={item.id}>
                  {({ selected, active }) => (
                    <Flex
                      direction='row'
                      align='center'
                      px={3}
                      py={2}
                      gap={1}
                      bg={active ? 'gray.100' : 'gray.50'}
                    >
                      <Checkbox isChecked={selected} pointerEvents='none' m={0} isReadOnly />
                      <Box bg={active ? 'gray.100' : 'gray.50'}>{item.label}</Box>
                    </Flex>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </Box>
      )}
    </Combobox>
  )
}

export default MultiSelect

const isSimpleMatch = (test: string, matcher: string) =>
  test.toLowerCase().replace(/\s+/g, '').includes(matcher.toLowerCase().replace(/\s+/g, ''))
