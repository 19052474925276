import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@chakra-ui/react'

export default function ReportHeader({ text }) {
  return (
    <Heading
      size='sm'
      style={{
        marginTop: '1rem',
        marginBottom: 0
      }}
      as='h4'
    >
      {text}
    </Heading>
  )
}

ReportHeader.propTypes = {
  text: PropTypes.string.isRequired
}
