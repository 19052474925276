import React from 'react'
import { Heading, HStack } from '@chakra-ui/react'
import { UserIncomeDetails } from '@homevest/types/user-incomes'
import Source from './Source'

const SourceDetails: React.FC<
  React.PropsWithChildren<{
    userIncomeDetails: UserIncomeDetails
    userId: string
    onUpdate: () => Promise<void>
  }>
> = ({ userIncomeDetails: { user_income_sources }, userId, onUpdate }) => {
  return (
    <>
      <HStack justify='space-between'>
        <Heading mt='3' size='sm'>
          Sources of Income
        </Heading>
      </HStack>
      {user_income_sources
        .sort((a, b) => (a.created_at < b.created_at ? -1 : 1))
        .map((source, idx) => (
          <Source
            key={source.id}
            userIncomeSource={source}
            userId={userId}
            idx={idx}
            onUpdate={onUpdate}
          />
        ))}
    </>
  )
}

export default SourceDetails
