import React, { FunctionComponent } from 'react'
import { capitalize } from 'lodash'

export type DisplayDataType = {
  label: string
  value: JSX.Element | string | null | undefined
  labelFormatter?: (label: string) => string
}[]

type PropTypes = {
  data: DisplayDataType
}

export const LeftAlignedDataList: FunctionComponent<React.PropsWithChildren<PropTypes>> = ({
  data
}) => {
  return (
    <div className='sm:p-0'>
      <dl>
        {data.map((dataElement) => (
          <div className='mb-2 p-0 sm:grid sm:grid-cols-3 sm:gap-4' key={dataElement.label}>
            <dt className='flex items-center text-sm font-medium text-gray-500'>
              {dataElement.labelFormatter
                ? dataElement.labelFormatter(dataElement.label)
                : capitalize(dataElement.label)}
            </dt>
            <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
              {dataElement.value || '-'}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
