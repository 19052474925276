import React, { useState } from 'react'
import { Spinner, Table, Thead, Tr, Th, Tbody, Td, Button, Input } from '@chakra-ui/react'
import { useQuery } from 'urql'
import { Comparators as Comp } from 'lib/hasura-comparators'
import { useSelector, useDispatch } from 'react-redux'
import { UpupLeadGroupsUserListDocument as query } from 'graphql/generated'

import UpdateModal from 'components/Dispatch/LeadGroups/UpdateModal'
import CreateModal from 'components/Dispatch/LeadGroups/CreateModal'

import { HeaderText } from 'components/Toolkit'

import { updateLeadGroup, createLeadGroup } from 'state/lead-groups/actions'
import { openModal, closeModal } from 'state/dispatch/actions'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'

const LeadGroupEntries = ({ searchText }) => {
  const dispatch = useDispatch()
  const { modalOpen, modalData, modalIsActioning } = useSelector((state) => state.dispatch)
  const [{ data, fetching }] = useQuery({
    query,
    variables: {
      where: {
        status: { _eq: 'active' },
        lead_group_users: {
          user_searchable: {
            info: Comp.isLike(`%${(searchText || '').replace(/\s/g, '')}%`)
          }
        }
      }
    }
  })

  const leadGroups = data?.lead_groups || []

  if (fetching) {
    return <Spinner />
  }

  return (
    <div>
      <>
        <div className='rounded border'>
          <Table>
            <Thead bgColor='gray.100'>
              <Tr>
                <Th>Users</Th>
                <Th>Status</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {leadGroups.map((leadGroup) => {
                return (
                  <Tr key={leadGroup.id}>
                    <Td>
                      {leadGroup.lead_group_users.map(
                        (
                          { user: { first_name: firstName, last_name: lastName, email, phone } },
                          idx
                        ) => (
                          <div
                            key={`lead_group_user_${idx}`}
                            style={idx !== 0 ? { marginTop: '5px' } : {}}
                          >
                            <div className='font-medium'>
                              {firstName} {lastName}
                            </div>
                            <div style={{ whiteSpace: 'nowrap' }}>{email}</div>
                            <div>{phone}</div>
                          </div>
                        )
                      )}
                    </Td>
                    <Td>{mapContentToBadge(leadGroup.status, { dot: false })}</Td>
                    <Td>
                      <Button
                        variant='outline'
                        size='sm'
                        onClick={() => dispatch(openModal('update', leadGroup))}
                      >
                        Update
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </div>

        {modalOpen === 'update' ? (
          <UpdateModal
            onUpdate={(...props) => dispatch(updateLeadGroup(...props))}
            onClose={(...props) => dispatch(closeModal(...props))}
            leadGroup={modalData}
            isActioning={modalIsActioning}
          />
        ) : null}
        {modalOpen === 'create' ? (
          <CreateModal
            onCreate={(...props) => dispatch(createLeadGroup(...props))}
            onClose={(...props) => dispatch(closeModal(...props))}
            isActioning={modalIsActioning}
          />
        ) : null}
      </>
    </div>
  )
}

function LeadGroups() {
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto  ' }}>
      <HeaderText size='h3'>Lead Groups</HeaderText>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '40px',
          gap: '20px'
        }}
      >
        <Input
          placeholder='Search...'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Button colorScheme='cyan' variant='outline' onClick={() => dispatch(openModal('create'))}>
          Create +
        </Button>
      </div>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner />
          </div>
        }
      >
        <LeadGroupEntries searchText={searchText} />
      </React.Suspense>
    </div>
  )
}

export default LeadGroups
