import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  HStack,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Select,
  ModalContent,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import { leadGroups } from '@homevest/utils'
import startCase from 'lodash/startCase'

const { LEAD_GROUP_USER_TYPES } = leadGroups

type UpdateModalProps = {
  onClose: () => void
  onUpdate: (leadGroupId: string, users: any[], lguIdsToRemove: string[], opts: any) => void
  isActioning: boolean
  leadGroup: any
}

const UpdateModal = ({ onClose, onUpdate, isActioning, leadGroup }: UpdateModalProps) => {
  const [state, setState] = useState({
    originalUserIdsToLeadGroupUsers: {} as any,
    users: [] as any[],
    newUserId: '',
    newType: '',
    overrideRentalApplication: false
  })

  const { users, newType, newUserId, originalUserIdsToLeadGroupUsers, overrideRentalApplication } =
    state

  const setPartialState = (partialState: Partial<typeof state>) =>
    setState((prev) => ({ ...prev, ...partialState }))

  useEffect(() => {
    const newOriginalUserIdsToLeadGroupUsers = leadGroup.lead_group_users.reduce(
      (acc: any, lgu: any) => {
        acc[lgu.user.id] = lgu

        return acc
      },
      {}
    )

    setPartialState({
      originalUserIdsToLeadGroupUsers: newOriginalUserIdsToLeadGroupUsers,
      users: leadGroup.lead_group_users.map(({ user, type }: any) => ({ ...user, type }))
    })
  }, [])

  const handleSubmitUpdate = () => {
    const usersToAdd = [] as any[]

    if (newUserId && newType && !originalUserIdsToLeadGroupUsers[newUserId]) {
      usersToAdd.push({ user_id: newUserId, type: newType })
    }

    const userIds = users.map((user: any) => user.id)

    const leadGroupUserIdsToRemove = Object.keys(originalUserIdsToLeadGroupUsers).reduce(
      (acc, userId) => {
        if (!userIds.includes(userId)) {
          acc.push(originalUserIdsToLeadGroupUsers[userId].id)
        }

        return acc
      },
      [] as any[]
    )

    onUpdate(leadGroup.id, usersToAdd, leadGroupUserIdsToRemove, { overrideRentalApplication })
  }

  const removeUser = (userIndexToRemove: number) => {
    setPartialState({
      users: users.filter((_user, idx) => idx !== userIndexToRemove)
    })
  }

  const userIds = users.map((user: any) => user.id)
  const userIdsToRemove = Object.keys(originalUserIdsToLeadGroupUsers).filter(
    (id) => userIds.indexOf(id) === -1
  )

  const canUpdate = !isActioning && ((newUserId && newType) || userIdsToRemove.length)

  return (
    <>
      <Modal size='lg' isOpen onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Lead Group</ModalHeader>
          <ModalBody>
            <Stack gap={4}>
              {users.length ? (
                <div>
                  <div>
                    <b>Existing users *</b>
                  </div>
                  <Stack>
                    {users.map((user: any, idx: number) => (
                      <HStack gap={2} key={user.id}>
                        <div>
                          {`${user.first_name} ${user.last_name} | ${startCase(user.type)}`}
                        </div>
                        <Button
                          size='sm'
                          colorScheme='red'
                          variant='outline'
                          onClick={() => removeUser(idx)}
                        >
                          Remove
                        </Button>
                      </HStack>
                    ))}
                  </Stack>
                </div>
              ) : null}

              <div>
                <div>
                  <b>Add new user by User ID *</b>
                </div>
                <div>
                  <Input
                    onChange={(event) => setPartialState({ newUserId: event.target.value })}
                    value={newUserId}
                    type='text'
                  />
                  <Select
                    style={{ marginTop: '1rem' }}
                    onChange={(event) => setPartialState({ newType: event.target.value })}
                    value={newType}
                  >
                    <option value=''>Select the tenant type</option>
                    {Object.values(LEAD_GROUP_USER_TYPES).map((type) => (
                      <option key={type} value={type}>
                        {startCase(type)}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack w='full' justifyContent='space-between'>
              <Checkbox
                checked={overrideRentalApplication}
                onChange={(e) => setPartialState({ overrideRentalApplication: e.target.checked })}
              >
                Override Rental App
              </Checkbox>
              <HStack gap={2}>
                <Button
                  colorScheme='cyan'
                  variant='outline'
                  disabled={!canUpdate}
                  onClick={handleSubmitUpdate}
                >
                  {isActioning ? 'Updating...' : 'Update'}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </HStack>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UpdateModal
