import React, { memo, useState } from 'react'
import startCase from 'lodash/startCase'
import get from 'lodash/get'
import { agreements, rentals } from '@homevest/utils'
import moment from 'moment'
import { useMutation } from 'urql'
import { Alert, AlertIcon, Heading, HStack, Text } from '@chakra-ui/react'

import {
  SetRentalApplicationPriorityDocument,
  SetRentalApplicationRecievedProductPitchDocument,
  useRentReadyDatesByOpportunityIdQuery,
  SetRentalApplicationRentDocument,
  SetRentalApplicationAutoRemindersDisabledAtDocument
} from 'graphql/generated'

import CrmField from 'components/CrmField'
import { formatMoney } from 'lib/numbers'
import axios from 'lib/axios'
import { Checkbox } from 'components/Toolkit'
import { CopyIcon } from 'components/TailwindUIToolkit/Utils'
import PaymentSchedule from './PaymentSchedule'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { RentalApplication } from 'types/RentalApplication'

const { AGREEMENT_STATUSES } = agreements
const { RENTAL_PROGRAM_TYPES } = rentals

const FIELD_TYPES = {
  VALUE: 'value',
  RANGE: 'range',
  SELECT: 'select',
  MULTI_SELECT: 'multi_select'
}

const FIELD_VALUE_TYPES = {
  STRING: 'string',
  INTEGER: 'integer',
  FLOAT: 'float',
  BOOLEAN: 'boolean',
  DATE: 'date',
  MONEY: 'money'
}
const MONTHLY_OPTION_PREMIUM = 50

function ApplicationDetails({
  rentalApplication,
  setRentalApplication,
  admin
}: {
  rentalApplication: RentalApplication & { listedRent?: number | null }
  setRentalApplication: (rentalApplication: RentalApplication) => void
  admin: any
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [_, setPriority] = useMutation(SetRentalApplicationPriorityDocument)
  const [__, setRent] = useMutation(SetRentalApplicationRentDocument)
  const [___, setReceivedInitialProductPitch] = useMutation(
    SetRentalApplicationRecievedProductPitchDocument
  )
  const [____, setAutoRemindersDisabledAt] = useMutation(
    SetRentalApplicationAutoRemindersDisabledAtDocument
  )
  const [autoRemindersDisabledFlag, setAutoRemindersDisabledFlag] = useState(
    !!rentalApplication.auto_reminders_disabled_at
  )
  const [{ data, error, fetching: loading }] = useRentReadyDatesByOpportunityIdQuery({
    variables: { opportunityId: rentalApplication.mls_listing_id }
  })

  const updateProjectedOccupancyDate = async (occupancyDate: Date | string) => {
    if (isSaving) {
      return
    }

    setIsSaving(true)

    try {
      await axios.post(`/admin/rental_applications/${rentalApplication.id}`, {
        projected_occupancy_date: occupancyDate
      })
    } catch (err: any) {
      // eslint-disable-next-line no-undef
      window.alert(err.message)
    }

    setIsSaving(false)
  }

  const updateAutoRemindersDisabledFlag = async () => {
    const newValues = {
      auto_reminders_disabled_at: autoRemindersDisabledFlag ? null : new Date(),
      auto_reminders_disabled_by_admin_id: autoRemindersDisabledFlag ? null : admin.id
    }

    const result = await setAutoRemindersDisabledAt({
      pk_columns: { id: rentalApplication.id },
      ...newValues
    })

    if (result.error) {
      alert(
        'Issue with editing application auto-reminders preference, refresh page and/or contact Eng'
      )
    }

    const updatedValues = result.data.update_rental_applications_by_pk
    setRentalApplication({
      ...rentalApplication,
      auto_reminders_disabled_at: updatedValues.auto_reminders_disabled_at,
      auto_reminders_disabled_by_admin_id: updatedValues.auto_reminders_disabled_by_admin_id
    })
    setAutoRemindersDisabledFlag(!!updatedValues.auto_reminders_disabled_at)
  }

  const {
    agreements,
    created_at: createdAt,
    projected_occupancy_date: projectedOccupancyDate,
    status,
    status_metadata: statusMetadata,
    rent: applicationRent,
    listedRent,
    mls_listing,
    rental_program_type_applied_for: rentalProgramType
  } = rentalApplication

  const internalStatus = mls_listing?.internal_attributes.internal_status
  const rent = applicationRent || listedRent

  const leaseAgreement =
    agreements.length &&
    agreements.find((agreement) => agreement.status !== AGREEMENT_STATUSES.CANCELED)

  const occupancyDate = get(
    leaseAgreement,
    'metadata.custom_fields.startDate',
    projectedOccupancyDate
  )

  return (
    <>
      <ContentSectionCard padding collapsable={true} title={'Details'}>
        {rentalProgramType === (RENTAL_PROGRAM_TYPES.VANILLA as any) && (
          <Alert status='warning' variant='subtle' mb={4}>
            <AlertIcon />
            This is an application for a vanilla lease. The applicants will not be eligible for the
            Up&Up program.
          </Alert>
        )}

        <Heading size='sm' as='h5' marginBottom={2}>
          Created: {moment(createdAt).format('MMM Do, h:mm A')}
        </Heading>

        <div className='flex flex-wrap gap-y-2'>
          <div style={{ flex: '1', minWidth: '50%' }}>
            <Text fontSize='xs'>Status</Text>
            <Text marginTop={0}>{startCase(status).replace('Upandup', 'Up&Up')}</Text>
            <Text fontSize='xs' marginTop={0}>
              {statusMetadata.message}
            </Text>
          </div>
          <div style={{ flex: '1', minWidth: '50%' }}>
            <Text fontSize='xs'>Property Status</Text>
            <Text marginTop={0}>{startCase(internalStatus)}</Text>
          </div>

          <div style={{ flex: '1', minWidth: '50%' }}>
            <CrmField
              label='Priority Score'
              fieldType={FIELD_TYPES.VALUE}
              fieldValueType={FIELD_VALUE_TYPES.INTEGER}
              value={rentalApplication.priority_score}
              onChange={async (score) => {
                const result = await setPriority({
                  pk_columns: { id: rentalApplication.id },
                  priority_score: score
                })
                if (result.error) {
                  alert('Issue with setting priority, contact eng and refresh page')
                }
              }}
            />
          </div>
          <div style={{ flex: '1', minWidth: '50%' }}>
            <CrmField
              label='Received Initial Product Pitch'
              fieldType={FIELD_TYPES.VALUE}
              fieldValueType={FIELD_VALUE_TYPES.BOOLEAN}
              value={rentalApplication.received_initial_product_pitch_at !== null}
              onChange={async (recievedPitch) => {
                const result = await setReceivedInitialProductPitch({
                  pk_columns: { id: rentalApplication.id },
                  did_receive_initial_product_pitch: Boolean(recievedPitch)
                })
                if (result.error) {
                  alert('Issue with setting value, contact eng and refresh page')
                }
              }}
            />
            <Text marginTop={0} fontSize='xs'>
              Not Known is the same as False
            </Text>
          </div>

          <div style={{ flex: '1', minWidth: '50%' }}>
            <CrmField
              label='Application Rent'
              fieldType={FIELD_TYPES.VALUE}
              fieldValueType={FIELD_VALUE_TYPES.MONEY}
              value={rent}
              onChange={async (rent) => {
                const result = await setRent({
                  pk_columns: { id: rentalApplication.id },
                  rent: rent
                })
                if (result.error) {
                  alert('Issue with setting application rent, contact eng and refresh page')
                }
                setRentalApplication({
                  ...rentalApplication,
                  rent: result.data.update_rental_applications_by_pk.rent
                })
              }}
            />
          </div>
          <div style={{ flex: '1', minWidth: '50%' }}>
            <CrmField
              label='Projected Occupancy Date'
              fieldType={FIELD_TYPES.VALUE}
              fieldValueType={FIELD_VALUE_TYPES.DATE}
              value={projectedOccupancyDate || ''}
              onChange={updateProjectedOccupancyDate}
            />
          </div>

          <div style={{ flex: '1', minWidth: '50%' }}>
            <Text fontSize='xs'>Monthly Option Premium</Text>
            <Text marginTop={0}>${formatMoney(MONTHLY_OPTION_PREMIUM, 0)}</Text>
          </div>
          <div style={{ flex: '1', minWidth: '50%' }}>
            <Text fontSize='xs'>Total Monthly Payment</Text>
            <Text marginTop={0}>
              ${rent ? formatMoney(rent + MONTHLY_OPTION_PREMIUM, 0) : '--'}
            </Text>
          </div>

          <div style={{ flex: '1', minWidth: '50%' }}>
            <Text fontSize='xs'>Rent Ready Date</Text>
            <Text marginTop={0}>
              {error
                ? 'error'
                : loading
                ? 'loading'
                : data
                ? data.rent_ready_dates[0]?.earliest_move_in_date
                : null}
            </Text>
          </div>

          <div style={{ flex: 1 }}>
            <Text fontSize='xs'>Home Rematching Tool</Text>
            <Text marginTop={0}>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={`https://upandup.tryretool.com/apps/6899f83e-dc3d-11ec-b888-b74d10445f94/Leasing/Home%20Matching%20Tool?_releaseVersion=latest#${mls_listing.id}&mls_listing_id=${mls_listing.id}`}
              >
                {' '}
                Click here
              </a>
            </Text>
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <Text fontSize='xs'>User Application</Text>
          <HStack>
            <Text
              marginTop={0}
            >{`https://app.upandup.co/application/${rentalApplication.id}`}</Text>
            <CopyIcon textToCopy={`https://app.upandup.co/application/${rentalApplication.id}`} />
          </HStack>
        </div>

        <div style={{ flex: 1 }}>
          <Text fontSize='xs'>Application Invite Link</Text>
          <HStack>
            <Text marginTop={0}>{`https://app.upandup.co/join/${rentalApplication.id}`}</Text>
            <CopyIcon textToCopy={`https://app.upandup.co/join/${rentalApplication.id}`} />
          </HStack>
        </div>

        <div>
          <Checkbox
            label={'Disable Daily Auto-Reminders (to submit documents)'}
            value={autoRemindersDisabledFlag}
            onChange={updateAutoRemindersDisabledFlag}
          ></Checkbox>
        </div>
      </ContentSectionCard>
      {occupancyDate && rent && (
        <div style={{ marginTop: '20px' }}>
          <PaymentSchedule
            occupancyDate={occupancyDate}
            rent={rent}
            rentalApplicationId={rentalApplication.id}
          />
        </div>
      )}
    </>
  )
}

export default memo(
  ApplicationDetails,
  (prevProps, newProps) =>
    prevProps?.rentalApplication?.status === newProps?.rentalApplication?.status &&
    prevProps?.rentalApplication?.id === newProps?.rentalApplication?.id
)
