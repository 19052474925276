import { Exchange } from 'urql'
import { Cache, cacheExchange, FieldInfo, CacheExchangeOpts } from '@urql/exchange-graphcache'
import {
  Mutation_RootDelete_Admin_Capabilities_By_PkArgs,
  Mutation_RootInsert_Admin_Capabilities_OneArgs,
  Mutation_RootInsert_Documents_OneArgs,
  Rental_Application_Manager_Admin_Assignments_Mutation_Response,
  Rental_Application_Manager_Application_Snoozes_Mutation_Response,
  SnoozeRentalApplicationMutation,
  UpupUpdateDocumentMutation
} from 'graphql/generated'

export const refetchMatchingFieldName = (
  cache: Cache,
  fieldName: string,
  custom?: (filtered: FieldInfo[]) => FieldInfo[]
) => {
  const filtered = cache
    .inspectFields('query_root')
    .filter((field) => field.fieldName === fieldName)
  return (custom ? custom(filtered) : filtered).forEach((field) => {
    cache.invalidate('query_root', field.fieldKey)
  })
}

export const refetchFieldForRelatedEntity = (
  cache: Cache,
  entityName: string,
  entityId: string,
  fieldName: string
) => {
  const entityKey = `${entityName}:${entityId}`
  cache.inspectFields(entityKey).forEach((field) => {
    if (field.fieldName === fieldName) {
      cache.invalidate(entityKey, field.fieldKey)
    }
  })
}

export const getCacheExchange = (schema: CacheExchangeOpts['schema']): Exchange =>
  cacheExchange({
    keys: {
      UserQualificationData: () => null,
      collections_liabilities_remaining: (data) =>
        data.collection_id && data.month && data.liability_id
          ? `${data.collection_id}:${data.month}:${data.liability_id}`
          : null,
      rental_application_manager_admin_roles: () => null,
      rental_application_manager_snooze_reasons: () => null,
      rental_applications_aggregate_fields: () => null,
      rental_applications_aggregate: () => null,
      vendor_markets: (data) =>
        data.vendor_id && data.market_id ? `${data.vendor_id}:${data.market_id}` : null,
      latchel_categories: (data) => (data.category_id ? String(data.category_id) : null),
      latchel_vendor_categories: (data) =>
        data.latchel_category_id && data.latchel_vendor_company_id
          ? `${data.latchel_category_id}:${data.latchel_vendor_company_id}`
          : null,
      tickets_latchel_data: (data) => `${data.ticket_id}`
    },
    updates: {
      Mutation: {
        update_rental_application_manager_admin_assignments(result, _args, cache, _info) {
          const response =
            result.update_rental_application_manager_admin_assignments as Rental_Application_Manager_Admin_Assignments_Mutation_Response
          response.returning.forEach((r) =>
            cache.invalidate({
              __typename: 'rental_application_manager_admin_assignments',
              id: r.id
            })
          )
        },
        insert_rental_application_manager_admin_assignments_one(_result, _args, cache, _info) {
          refetchMatchingFieldName(cache, 'rental_application_manager_admin_assignments')
        },
        insert_rental_application_manager_application_snoozes_one(result, _args, cache, _info) {
          const snooze = (result as SnoozeRentalApplicationMutation)
            .insert_rental_application_manager_application_snoozes_one

          if (!!snooze) {
            refetchMatchingFieldName(cache, 'rental_applications')
            refetchMatchingFieldName(
              cache,
              'rental_application_manager_application_snoozes',
              (filtered) =>
                filtered.filter(
                  (field) =>
                    (field.arguments?.where as any).rental_application_id._eq ===
                    snooze.rental_application_id
                )
            )
          }
        },
        insert_rental_application_manager_application_escalations_one(
          _result,
          _args,
          cache,
          _info
        ) {
          refetchMatchingFieldName(cache, 'rental_application_manager_application_escalations')
        },
        update_rental_application_manager_application_snoozes(result, _args, cache, _info) {
          const snooze = (
            result.update_rental_application_manager_application_snoozes as Rental_Application_Manager_Application_Snoozes_Mutation_Response
          ).returning[0]
          refetchMatchingFieldName(
            cache,
            'rental_application_manager_application_snoozes',
            (filtered) =>
              filtered.filter(
                (field) =>
                  (field.arguments?.where as any).rental_application_id._eq ===
                  snooze.rental_application_id
              )
          )
          refetchMatchingFieldName(cache, 'rental_applications')
        },
        insert_admins_one(_result, _args, cache, _info) {
          refetchMatchingFieldName(cache, 'admins')
        },
        insert_admin_capabilities(_result, _args, cache, _info) {
          refetchMatchingFieldName(cache, 'admins')
        },
        insert_notes_one(_result, _args, cache, _info) {
          refetchMatchingFieldName(cache, 'notes')
        },
        insert_admin_capabilities_one(_result, args, cache, _info) {
          cache.invalidate({
            __typename: 'admins',
            // eslint-disable-next-line
            id: (args as Mutation_RootInsert_Admin_Capabilities_OneArgs).object.admin_id
          })
        },
        delete_admin_capabilities_by_pk(_result, args, cache, _info) {
          cache.invalidate({
            __typename: 'admin_capabilities',
            // eslint-disable-next-line
            id: (args as Mutation_RootDelete_Admin_Capabilities_By_PkArgs).id
          })
        },
        insert_capabilities_one(_result, _args, cache, _info) {
          refetchMatchingFieldName(cache, 'capabilities')
        },
        insert_documents_one(_result, args, cache, _info) {
          const insertDocumentsArgs = args as Mutation_RootInsert_Documents_OneArgs
          refetchMatchingFieldName(cache, 'documents')
          if (insertDocumentsArgs.object.resource_type && insertDocumentsArgs.object.resource_id) {
            refetchFieldForRelatedEntity(
              cache,
              insertDocumentsArgs.object.resource_type,
              insertDocumentsArgs.object.resource_id,
              'documents'
            )
          }
        },
        update_documents_by_pk(_result, _args, cache, _info) {
          cache.invalidate({
            __typename: 'documents',
            id: (_result as UpupUpdateDocumentMutation).update_documents_by_pk?.id
          })
        }
      }
    },
    schema
  })
