"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDENTITY_SESSION_ERROR_CODES = exports.STRIPE_VERIFICATION_WEBHOOK_TYPES = exports.VERIFICATION_STATUSES = void 0;
exports.VERIFICATION_STATUSES = Object.freeze({
    FAILED: 'failed',
    PROCESSING: 'processing',
    VERIFIED: 'verified',
    SESSION_CREATED: 'session_created'
});
exports.STRIPE_VERIFICATION_WEBHOOK_TYPES = Object.freeze({
    PROCESSING: 'identity.verification_session.processing',
    VERIFIED: 'identity.verification_session.verified',
    REQUIRES_INPUT: 'identity.verification_session.requires_input',
    CANCELED: 'identity.verification_session.canceled'
});
exports.IDENTITY_SESSION_ERROR_CODES = Object.freeze({
    TOO_MANY_ATTEMPTS: 'too_many_attempts',
    USER_NOT_FOUND: 'user_not_found',
    SESSION_NOT_REQUIRES_INPUT: 'session_not_requires_input',
    SESSION_MISSING_PARAMETERS: 'session_missing_parameters',
    SESSION_UNKNOWN_STATUS: 'session_unknown_status'
});
