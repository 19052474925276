import React, { memo } from 'react'
import PropTypes from 'prop-types'
import COLORS from '../colors'

function ThickPlus({ width = '17', height = '12', color = COLORS.PRIMARY.JELLO }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M14.5 8H8.5V14H6.5V8H0.5V6H6.5V0H8.5V6H14.5V8Z' fill={color} />
    </svg>
  )
}

ThickPlus.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default memo(ThickPlus)
