"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CUSTOM_FIELDS_TYPES = exports.NEW_POA_VERSIONS = exports.POA_VERSIONS = exports.TEMPLATE_TYPES = exports.EXTERNAL_SOURCES = void 0;
exports.EXTERNAL_SOURCES = Object.freeze({
    HELLOSIGN: 'hellosign'
});
exports.TEMPLATE_TYPES = Object.freeze({
    LEASE: 'lease',
    RENEWAL: 'renewal',
    PURCHASE_OPTION_AGREEMENT_AMENDMENT: 'purchase_option_agreement_amendment',
    WATER_SERVICE_TRANSFER: 'water_service_transfer'
});
exports.POA_VERSIONS = Object.freeze({
    LEGACY: 'legacy',
    V2023_08_02: '2023-08-02',
    V2023_12_14: '2023-12-14'
});
// Versions of the POA that include updated language introduced in Aug 2023
// Leases with these versions of the POA do not require an amendment
exports.NEW_POA_VERSIONS = Object.freeze([
    exports.POA_VERSIONS.V2023_08_02,
    exports.POA_VERSIONS.V2023_12_14
]);
exports.CUSTOM_FIELDS_TYPES = Object.freeze({
    STANDARD_LEASE: 'standard_lease',
    STANDARD_RENEWAL: 'standard_renewal',
    SANDALWOOD_LEASE: 'sandalwood_lease',
    SANDALWOOD_RENEWAL: 'sandalwood_renewal'
});
