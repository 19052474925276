import React from 'react'
import { IconButton } from '@chakra-ui/react'
import axios from 'lib/axios'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'

export const DocumentDownloadButton: React.FC<React.PropsWithChildren<{ documentId: string }>> = ({
  documentId
}) => {
  const onClick = async () => {
    const { data } = await axios.get(`/admin/documents/${documentId}/url`)
    window.open(data.url, '_blank')
  }
  return <IconButton p={2} icon={<ArrowDownTrayIcon />} aria-label='download' onClick={onClick} />
}
