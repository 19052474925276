import { documents } from '@homevest/utils'

const { DOCUMENT_TYPES } = documents

export const URL_QUERY_MAP = {
  status: 'status',
  type: 'type',
  date_uploaded: 'created_at',
  uploaded_by: 'uploaded_by',
  name: 'friendly_name',
  resource_notes: 'resource_notes'
}

// todo v2: use utils
export const ACQUISITIONS_DOCUMENT_TYPE_MAP = {
  purchase_sale_agreement: 'Executed Purchase and Sale Agreement',
  purchase_sale_agreement_addendum: 'Addendums',
  sellers_disclosure_notice: `Seller's Disclosure Notice`,
  earnest_money_receipt: 'Earnest Money Receipt',
  commission_agreement: 'Commission Agreement',
  mls_listing_from_purchase_document: 'MLS Listing from Purchase',
  preliminary_title_commitment: 'Prelim Title Commitment',
  acquisition_amendments: 'Amendments',
  acquisition_mutual_release: 'Mutual Release',
  owners_title_policy: `Owner's Title Policy`,
  final_signed_settlement_statement: 'Final Signed Settlement Statement',
  signed_deed: 'Signed Deed'
}

export const CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP = {
  [DOCUMENT_TYPES.APPROVED_BID]: 'Approved Bid',
  [DOCUMENT_TYPES.CHANGE_ORDER]: 'Change Order',
  [DOCUMENT_TYPES.CONSTRUCTION_SCOPE]: 'Construction Scope',
  [DOCUMENT_TYPES.PHOTO]: 'Photo',
  [DOCUMENT_TYPES.NOTE]: 'Note',
  [DOCUMENT_TYPES.INVOICE]: 'Invoice',
  [DOCUMENT_TYPES.INSPECTION_REPORT]: 'Inspection Report',
  [DOCUMENT_TYPES.QC_REPORT]: 'QC Report',
  [DOCUMENT_TYPES.VENDOR_CORRESPONDENCE]: 'Vendor Correspondence',
  [DOCUMENT_TYPES.VENDOR_QUOTE]: 'Vendor Quote',
  [DOCUMENT_TYPES.WARRANTY_INFO]: 'Warranty Info',
  [DOCUMENT_TYPES.OTHER]: 'Other'
}

// todo v2: use utils
export const STATUS_MAP = {
  draft: 'Draft',
  archive: 'Archive',
  final: 'Final'
}
