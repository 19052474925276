import React, { FunctionComponent } from 'react'
import { upperFirst, lowerCase } from 'lodash'
import { Interaction } from 'types/Timeline'
import RenderPropertyDetails from './RenderPropertyDetails'
import { PropertyTitle, PropertyLine, StyledFieldContainer } from './StyledComponents'

export interface RenderableField {
  fieldName: string
  displayName?: string
  unit?: string
}

export type FieldsObject = {
  open: RenderableField[]
  closed: RenderableField[]
}

type FieldRendererPropsType = {
  fieldsObject: FieldsObject
  interaction: Interaction
  open: boolean
}

// this component takes an object that holds information about which
// detail fields to list for a displayed timeline item, the said data
// object of that timeline item, and a bool describing whether the displayed
// timeline item is open or closed.

// returns a rendered list of all fields given in fieldsObject with
// the corresponding data from the given data item

const RenderFields: FunctionComponent<React.PropsWithChildren<FieldRendererPropsType>> = ({
  fieldsObject,
  interaction,
  open
}) => {
  const fieldsToRender = open ? fieldsObject.closed.concat(fieldsObject.open) : fieldsObject.closed

  return (
    <StyledFieldContainer>
      {fieldsToRender.map(({ fieldName: name, displayName }, i) => {
        if (!interaction[name]) {
          return null
        }

        let displayFields

        if (name === 'propertyDetails') {
          displayFields = <RenderPropertyDetails details={interaction[name]} />
        } else {
          displayFields = interaction[name]
        }

        return (
          <PropertyLine key={i}>
            <PropertyTitle>{upperFirst(lowerCase(displayName ?? name))}</PropertyTitle>:{' '}
            {displayFields}
          </PropertyLine>
        )
      })}
    </StyledFieldContainer>
  )
}

export default RenderFields
