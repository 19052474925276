import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'

import COLORS from 'components/Toolkit/colors'
import Label from 'components/Toolkit/Text/Label'
import DownChevron from 'components/Toolkit/Icons/DownChevron'

const StyledDropdown = styled.div`
  background: #ffffff;
  border: 1px solid #e6e4e3;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.NEUTRALS.OYSTER};
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  cursor: pointer;
  padding: 16px 13px;
  text-align: center;
`

const StyledInputContainer = styled.div`
  margin: 25px 0;
`

const StyledHiddenSelect = styled.select`
  position: absolute;
  margin: 0 4px;
  opacity: 0.01;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  cursor: pointer;
`

const StyledDropdownWrapper = styled.div`
  position: relative;
`

const StyledDurationWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledChevronWrapper = styled.div`
  margin-left: 21px;
`

const StyledText = styled.div`
  opacity: 0.3;
`

const StyledTo = styled.div`
  margin: 0 17px;
  color: ${COLORS.NEUTRALS.OYSTER};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
`

const JUST_A_DATE = '1989-12-18'

function getTimesBetween(start, end, timeFormat, step) {
  const values = []
  const startClone = start.clone()

  while (startClone.isSameOrBefore(end)) {
    values.push(startClone.format(timeFormat))
    startClone.add(step.amount, step.type)
  }

  return values
}

function TimeDuration({
  label,
  onChange = () => null,
  min = '09:00 AM',
  max = '08:00 PM',
  timeFormat = 'hh:mm A',
  step = { amount: 30, type: 'minute' },
  startValue = '',
  endValue = ''
}) {
  const parseFormat = `YYYY-MM-DD ${timeFormat}`
  const startTime = moment(JUST_A_DATE + ' ' + min, parseFormat)
  const endTime = moment(JUST_A_DATE + ' ' + max, parseFormat)
  const startValueTime = moment(JUST_A_DATE + ' ' + startValue, parseFormat)
  const endValueTime = moment(JUST_A_DATE + ' ' + endValue, parseFormat)

  const possibleStartValues = getTimesBetween(startTime, endTime, timeFormat, step)
  const possibleEndValues = getTimesBetween(
    startValue ? startValueTime : startTime,
    endTime,
    timeFormat,
    step
  )

  return (
    <StyledInputContainer>
      <Label>{label}</Label>
      <StyledDurationWrapper>
        <StyledDropdownWrapper>
          <StyledDropdown>
            <StyledText>{startValue ? startValueTime.format(timeFormat) : '- Select -'}</StyledText>

            <StyledChevronWrapper>
              <DownChevron />
            </StyledChevronWrapper>
          </StyledDropdown>
          <StyledHiddenSelect
            onChange={(event) => {
              const newStartValueTime = moment(JUST_A_DATE + ' ' + event.target.value, parseFormat)
              onChange({
                start: event.target.value,
                end: newStartValueTime.isSameOrAfter(endValueTime) ? '' : endValue
              })
            }}
            value={startValue}
          >
            <option value=''>- Select -</option>
            {possibleStartValues.map((value, idx) => (
              <option key={idx} value={value}>
                {value}
              </option>
            ))}
          </StyledHiddenSelect>
        </StyledDropdownWrapper>

        <StyledTo>to</StyledTo>
        <StyledDropdownWrapper>
          <StyledDropdown>
            <StyledText>{endValue ? endValueTime.format(timeFormat) : '- Select -'}</StyledText>
            <StyledChevronWrapper>
              <DownChevron />
            </StyledChevronWrapper>
          </StyledDropdown>
          <StyledHiddenSelect
            onChange={(event) =>
              onChange({
                start: startValue,
                end: event.target.value
              })
            }
            value={endValue}
          >
            <option value=''>- Select -</option>
            {possibleEndValues.map((value, idx) => (
              <option key={idx} value={value}>
                {value}
              </option>
            ))}
          </StyledHiddenSelect>
        </StyledDropdownWrapper>
      </StyledDurationWrapper>
    </StyledInputContainer>
  )
}

TimeDuration.propTypes = {
  label: PropTypes.string,
  startValue: PropTypes.string.isRequired,
  endValue: PropTypes.string.isRequired,
  min: PropTypes.string,
  max: PropTypes.string,
  timeFormat: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.shape({
    amount: PropTypes.number,
    type: PropTypes.string
  })
}

export default memo(TimeDuration)
