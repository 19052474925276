import React, { Fragment, FunctionComponent, Suspense } from 'react'
import { Spinner, Text, Tab } from '@chakra-ui/react'
import {
  Rental_Applications_Bool_Exp,
  useUpupRentalApplicationsCountQuery
} from 'graphql/generated'
import { Tag } from 'ui'

type Props = {
  label: string
  partial: Rental_Applications_Bool_Exp | null
  active: boolean
}

const FilterTab: FunctionComponent<React.PropsWithChildren<Props>> = ({
  label,
  partial,
  active
}) => {
  return (
    <Tab gap={2} px={2}>
      <Text fontSize='sm' whiteSpace='nowrap'>
        {label}
      </Text>
      <Suspense fallback={<Spinner />}>
        <Tag bg={active ? 'indigo.100' : undefined} color={active ? 'indigo.600' : undefined}>
          <Count partial={partial} />
        </Tag>
      </Suspense>
    </Tab>
  )
}

const Count: FunctionComponent<React.PropsWithChildren<Pick<Props, 'partial'>>> = ({ partial }) => {
  const [{ data, error }] = useUpupRentalApplicationsCountQuery({
    variables: { where: partial },
    pause: partial === undefined
  })
  if (error) {
    return <div>😢</div>
  }
  return <Fragment>{data?.rental_applications_aggregate.aggregate?.count}</Fragment>
}

export default FilterTab
