import { UserIncomeByUserIdQuery } from 'graphql/generated'

export const computeUserIncomeStatus = (
  userIncome?: Pick<
    UserIncomeByUserIdQuery['user_incomes'][number],
    | 'initially_completed_at'
    | 'additional_income_requested_at'
    | 'additional_income_submitted_at'
    | 'approved_at'
  >
) => {
  if (!userIncome) return 'Not Submitted'

  if (userIncome.approved_at) {
    return 'approved'
  }

  if (userIncome.initially_completed_at) {
    if (userIncome.additional_income_requested_at) {
      return userIncome.additional_income_submitted_at
        ? 'additional_income_submitted'
        : 'additional_income_requested'
    }

    return 'submitted'
  }

  return 'not_submitted'
}
