import React from 'react'

import UtilityDetails from '../Cards/Utilities'
import { UtilityTypesContextProvider } from '../UtilityTypesContext'

interface UtilitiesPanelProps {
  portfolioHomeId: string
}

export const UtilitiesPanel: React.FC<React.PropsWithChildren<UtilitiesPanelProps>> = ({
  portfolioHomeId
}) => (
  <UtilityTypesContextProvider>
    <UtilityDetails portfolioHomeId={portfolioHomeId} />
  </UtilityTypesContextProvider>
)
