import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios'
import firebase from './firebase'
import { apiUrl } from './config'

const client = axios.create({
  baseURL: apiUrl
})

function responseInterceptor(res: AxiosResponse | AxiosError): AxiosResponse | Promise<any> {
  // Log in development
  if (typeof window !== 'undefined' && window.location.hostname !== 'hudson.upandup.co') {
    const response = (res as AxiosError).response || (res as AxiosResponse)

    if (response) {
      console.log(
        '[Axios] %s %s %s',
        response.status,
        response.config.method?.toUpperCase(),
        response.config.url
      )
      console.log('[Axios]', response)
    } else if ((res as AxiosError).message) {
      console.log('[Axios]', (res as AxiosError).message)
      console.log(res)
    }
  }

  return (res as AxiosError).isAxiosError
    ? Promise.reject(handleAxiosError(res as AxiosError))
    : (res as AxiosResponse)
}

client.interceptors.response.use(responseInterceptor as any, responseInterceptor as any)

client.interceptors.request.use(async (requestConfig: AxiosRequestConfig) => {
  if (!requestConfig.headers.Authorization && firebase.auth().currentUser) {
    const token = await firebase.auth().currentUser?.getIdToken()

    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`
    }

    return requestConfig
  } else {
    return requestConfig
  }
})

export const isAxiosError = <T = any>(error: any): error is AxiosError<T> => {
  return error.isAxiosError
}

const handleAxiosError = (errorObject: AxiosError) => {
  if (errorObject.response) {
    return errorObject.response.data
  } else if (errorObject.request) {
    return errorObject.request
  } else {
    return errorObject.message
  }
}

export default client

export const uninterceptedClient = axios.create({
  baseURL: apiUrl
})
