import { Container, Col, Row } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { capabilities } from '@homevest/utils'

import Map from '../components/Map'
import PropertyInsights from '../components/Property/Insights'
import PropertyInfo from '../components/Property/Info'
import { ErrorText, HeaderText } from 'components/Toolkit'
import { canBeReviewed } from '../lib/property'
import AlertBar from '../components/Property/AlertBar'
import { clearPropertySellerAgentData } from '../state/properties/actions'
import { hasCapability } from 'lib/admin-perms'
import { REQUEST_POLICY } from 'constants/urql'
import { UpUpMlsListingByIdDocument } from 'graphql/generated'

const { PROPERTY_PRICER } = capabilities.CAPABILITY_TYPES

const RightCol = styled(Col)``

const LeftCol = styled(RightCol)``

const StyledRow = styled(Row)``

function InitialReviewScreen() {
  const { mlsListingId } = useParams()
  return (
    <React.Suspense
      fallback={
        <div>
          <HeaderText style={{ textAlign: 'center', marginTop: '400px' }} size='h3'>
            Loading...
          </HeaderText>
        </div>
      }
    >
      <InitialReviewScreenView mlsListingId={mlsListingId} />
    </React.Suspense>
  )
}

function InitialReviewScreenView({ mlsListingId }) {
  const admin = useSelector((state) => state.admin)
  const dispatch = useDispatch()
  const history = useHistory()

  const [mlsListing, setMlsListing] = useState(null)
  const [canReview, setCanReview] = useState(false)
  const [floodPlain, setFloodPlain] = useState(false)

  useEffect(() => {
    if (!mlsListing) {
      return
    }

    setCanReview(canBeReviewed(mlsListing))
    setFloodPlain((mlsListing.xf_attributes.xf_disc || []).join(',').includes('Flood Plain Yes'))

    return () => dispatch(clearPropertySellerAgentData())
  }, [dispatch, history, mlsListing])

  const isPricer = hasCapability(admin, PROPERTY_PRICER)

  const [{ data, fetching, error }] = useQuery({
    query: UpUpMlsListingByIdDocument,
    variables: { id: mlsListingId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK
  })

  useEffect(() => {
    if (!data || !data?.mls_listing) {
      return
    }
    setMlsListing(data.mls_listing)
  }, [data])

  if (error) {
    return <ErrorText>{error}</ErrorText>
  }

  if (fetching || !mlsListing) {
    return (
      <div>
        <HeaderText style={{ textAlign: 'center', marginTop: '400px' }} size='h3'>
          Loading...
        </HeaderText>
      </div>
    )
  }

  return (
    <Container className='initial-review-container'>
      <AlertBar />

      <StyledRow>
        <LeftCol xs={12} md={6}>
          <PropertyInfo property={mlsListing} />
          {isPricer && floodPlain && (
            <div>
              <br />
              This property cannot be reviewed: {canReview[1]}.
              {floodPlain && (
                <>
                  <br />
                  ⚠️ THIS PROPERTY IS IN A FLOOD PLAIN!
                </>
              )}
            </div>
          )}
        </LeftCol>

        <RightCol xs={12} md={6}>
          <PropertyInsights mlsListing={mlsListing} />

          {mlsListing.lat && mlsListing.lng && (
            <Map
              coordinates={{
                latitude: mlsListing.lat,
                longitude: mlsListing.lng
              }}
            />
          )}
        </RightCol>
      </StyledRow>
    </Container>
  )
}

export default InitialReviewScreen

InitialReviewScreenView.propTypes = {
  mlsListingId: PropTypes.string.isRequired
}
