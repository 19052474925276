import React from 'react'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon
} from '@chakra-ui/react'

import { useSourceDetails } from '../../context'
import BasicFormField, { BasicFormFieldProps } from './BasicFormField'
import { formatPhoneForDisplay } from 'lib/formatting'

export const CurrencyFormField = (props: Omit<BasicFormFieldProps, 'leftAddOn'>) => {
  return (
    <BasicFormField
      {...props}
      leftAddOn='$'
      inputProps={{
        ...(props.inputProps || {}),
        type: 'number',
        placeholder: '1000'
      }}
    />
  )
}

export const EmailFormField = (props: Omit<BasicFormFieldProps, 'leftAddOn'>) => {
  return (
    <BasicFormField
      {...props}
      leftAddOn={<EnvelopeIcon className='h-5 w-5' />}
      inputProps={{
        ...(props.inputProps || {}),
        type: 'email',
        placeholder: 'welcome@home.com'
      }}
    />
  )
}

export const PhoneFormField = (props: Omit<BasicFormFieldProps, 'leftAddOn'>) => {
  const { label, fieldName, isRequired = true, inputProps = {} } = props
  const context = useSourceDetails()

  if (!context) {
    return null
  }

  const { data, updateField, errors } = context

  const fieldValue = (data as any)[fieldName]
  const errorMessage = (errors as any)[fieldName]

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, '')
    const formattedInput = formatPhoneForDisplay(input)

    updateField(fieldName, formattedInput)
  }

  return (
    <FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
      <FormLabel htmlFor={fieldName}>{label}</FormLabel>
      <InputGroup>
        <InputLeftAddon>+1</InputLeftAddon>
        <Input
          {...inputProps}
          id={fieldName}
          value={fieldValue}
          onChange={onChange}
          placeholder='(123) 456-7890'
          type='tel'
        />
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
