import React, { useState, useEffect } from 'react'
import {
  Tr,
  Td,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Center,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'
import { VehicleWithId } from '@homevest/types/rental-applications'
import { requiredLeaseDetails } from '@homevest/utils'

import RemoveButton from '../../Common/RemoveButton'
import { isVehicleYearValid, isVehicleLicensePlateValid } from 'lib/required-lease-details'

const { MIN_VEHICLE_YEAR, MAX_LICENSE_PLATE_LENGTH } = requiredLeaseDetails
const MAX_VEHICLE_YEAR = new Date().getFullYear() + 1

type EditVehiclesRowProps = {
  vehicle: VehicleWithId
  removeVehicle: (vehicleId: string) => void
  editVehicle: (vehicleId: string, updatedVehicle: VehicleWithId) => void
  skipValidation: boolean
}

const EditVehiclesRow: React.FC<React.PropsWithChildren<EditVehiclesRowProps>> = ({
  vehicle,
  removeVehicle,
  editVehicle,
  skipValidation
}) => {
  const [id, _setId] = useState(vehicle.id)
  const [make, setMake] = useState(vehicle.make)
  const [model, setModel] = useState(vehicle.model)
  const [year, setYear] = useState<number | null>(vehicle.year)
  const [licensePlate, setLicensePlate] = useState(vehicle.license_plate)

  const isYearValid = year !== null && (isVehicleYearValid(year) || skipValidation)
  const isLicensePlateValid = isVehicleLicensePlateValid(licensePlate) || skipValidation

  useEffect(() => {
    handleEdit()
  }, [make, model, year, licensePlate])

  const handleEdit = () => {
    editVehicle(id, { id, make, model, year: year!, license_plate: licensePlate })
  }

  return (
    <Tr>
      <Td>
        <FormControl isRequired isInvalid={!make}>
          <Input
            placeholder='Toyota...'
            value={make}
            onChange={(evt) => setMake(evt.target.value)}
          />
          <FormErrorMessage>Make is required</FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired isInvalid={!model}>
          <Input
            placeholder='Corolla...'
            value={model}
            onChange={(evt) => setModel(evt.target.value)}
          />
          <FormErrorMessage>Model is required</FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired isInvalid={!isYearValid}>
          <NumberInput
            value={year ?? undefined}
            onChange={(value) => setYear(value ? parseInt(value) : null)}
            min={MIN_VEHICLE_YEAR}
            max={MAX_VEHICLE_YEAR}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>
            Please enter a year between {MIN_VEHICLE_YEAR} and {MAX_VEHICLE_YEAR}
          </FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired isInvalid={!isLicensePlateValid}>
          <Input
            placeholder='License Plate...'
            value={licensePlate}
            onChange={(evt) => setLicensePlate(evt.target.value)}
          />
          <FormErrorMessage>
            License plate should be under {MAX_LICENSE_PLATE_LENGTH + 1} characters
          </FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <Center>
          <RemoveButton onClick={() => removeVehicle(id)} />
        </Center>
      </Td>
    </Tr>
  )
}

export default EditVehiclesRow
