import React, { useState } from 'react'
import { Button, useDisclosure } from '@chakra-ui/react'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { BuyersInspectionApiResponse } from '@homevest/types/buyers-inspections'

import { Loading } from 'ui'
import { BaseGrid } from 'components/TailwindUIToolkit'
import { formatUTCDate, formatDateWithTime } from 'lib/date-time'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import EditBuyersInspectionSidepeek from './EditBuyersInspectionSidepeek'

const BuyersInspectionGrid = ({
  data,
  portfolioHomeId,
  isFetching,
  refetch
}: {
  data?: BuyersInspectionApiResponse[]
  portfolioHomeId: string
  isFetching: boolean
  refetch: () => void
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedInspection, setSelectedInspection] = useState<BuyersInspectionApiResponse | null>(
    null
  )

  if (isFetching) {
    return <Loading />
  }

  const openEditSidepeek = (inspection?: BuyersInspectionApiResponse) => {
    setSelectedInspection(inspection ?? null)
    onOpen()
  }

  return (
    <>
      <EditBuyersInspectionSidepeek
        inspection={selectedInspection}
        portfolioHomeId={portfolioHomeId}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={refetch}
      />
      <div className='h-[60vh]'>
        <BaseGrid
          rowData={data ?? []}
          columns={[
            {
              colId: 'actions',
              headerName: '',
              width: 70,
              cellRenderer: (params: ICellRendererParams<BuyersInspectionApiResponse>) => (
                <>
                  <Button
                    aria-label='Edit'
                    variant={'outline'}
                    colorScheme='teal'
                    size='sm'
                    onClick={() => openEditSidepeek(params.data)}
                  >
                    Edit
                  </Button>
                </>
              )
            },
            ...COLUMNS
          ]}
          defaultColDefOverrides={{
            wrapHeaderText: true
          }}
        />
      </div>
    </>
  )
}

const COLUMNS: ColDef<BuyersInspectionApiResponse>[] = [
  {
    colId: 'buyer_name',
    headerName: 'Buyer',
    field: 'counterparty.name'
  },
  {
    colId: 'status',
    headerName: 'Status',
    field: 'status',
    cellRenderer: (params: ICellRendererParams<BuyersInspectionApiResponse>) =>
      mapContentToBadge(params.value, { size: 'xs' })
  },
  {
    colId: 'submitted_by_buyer_at',
    headerName: 'Submitted At',
    field: 'submitted_by_buyer_at',
    valueFormatter: (params) => formatDateWithTime(params.value),
    sort: 'desc',
    sortIndex: 0
  },
  {
    colId: 'counter_deadline_date',
    headerName: 'Counter Deadline',
    field: 'counter_deadline_date',
    valueFormatter: (params) => formatUTCDate(params.value, 'MM/dd/yy')
  },
  {
    colId: 'vendor_bids_sent_at',
    headerName: 'Bids Sent At',
    field: 'vendor_bids_sent_at',
    valueFormatter: (params) => formatDateWithTime(params.value)
  },
  {
    colId: 'counter_response_type',
    headerName: 'Counter Response',
    field: 'counter_response_type',
    cellRenderer: (params: ICellRendererParams<BuyersInspectionApiResponse>) =>
      params.value ? mapContentToBadge(params.value) : null
  },
  {
    colId: 'counter_submitted_at',
    headerName: 'Counter Submitted At',
    field: 'counter_submitted_at',
    valueFormatter: (params) => formatDateWithTime(params.value)
  },
  {
    colId: 'counter_response_received_at',
    headerName: 'Response Received At',
    field: 'counter_response_received_at',
    valueFormatter: (params) => formatDateWithTime(params.value)
  }
]

export default BuyersInspectionGrid
