import React, { FunctionComponent } from 'react'
import _ from 'lodash'
import { useUpupPaymentMethodsByUserIdQuery } from '../../graphql/generated'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import { ContentSectionCard } from 'components/TailwindUIToolkit'

const PaymentMethodsWrapper: FunctionComponent<React.PropsWithChildren<{ userId?: string }>> = ({
  userId
}) => (
  <ContentSectionCard title={'Payment Methods'} padding>
    <React.Suspense fallback={<SC.MessageText>Loading...</SC.MessageText>}>
      <PaymentMethods userId={userId} />
    </React.Suspense>
  </ContentSectionCard>
)

const PaymentMethods: FunctionComponent<React.PropsWithChildren<{ userId?: string }>> = ({
  userId
}) => {
  const [{ data, error }] = useUpupPaymentMethodsByUserIdQuery({
    variables: { userId }
  })

  if (error) {
    return <SC.MessageText>Something went wrong.</SC.MessageText>
  }

  if (data?.payment_methods.length === 0) {
    return <SC.MessageText>No payment methods found for this user.</SC.MessageText>
  }

  return (
    <div>
      {(data?.payment_methods ?? []).map((paymentMethod) => (
        <SC.PaymentMethodItem key={paymentMethod.id}>
          <SC.PaymentMethodHeader>
            <SC.DetailHeading>Ending in {paymentMethod.mask}</SC.DetailHeading>
            {paymentMethod.is_default && <SC.GreenBadge>Default</SC.GreenBadge>}
          </SC.PaymentMethodHeader>
          <SC.PaymentMethodRow>
            <div style={{ flex: 1 }}>
              <SC.PaymentMethodProp>Status</SC.PaymentMethodProp>
              <SC.PaymentMethodValue>{paymentMethod.status}</SC.PaymentMethodValue>
            </div>
            <div style={{ flex: 1 }}>
              <SC.PaymentMethodProp>Type</SC.PaymentMethodProp>
              <SC.PaymentMethodValue>{paymentMethod.type}</SC.PaymentMethodValue>
            </div>
          </SC.PaymentMethodRow>
          {(paymentMethod.stripe_source_id || paymentMethod.stripe_payment_method_id) && (
            <>
              <SC.PaymentMethodProp>Stripe id</SC.PaymentMethodProp>
              <SC.PaymentMethodValue>
                {paymentMethod.stripe_source_id || paymentMethod.stripe_payment_method_id}
              </SC.PaymentMethodValue>
            </>
          )}
        </SC.PaymentMethodItem>
      ))}
    </div>
  )
}

export default PaymentMethodsWrapper
