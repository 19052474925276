import React from 'react'
import { Heading } from '@chakra-ui/react'
import { userIncomeSources, documents } from '@homevest/utils'

import { useSourceDetails } from './context'
import { useSubmitIncomeSourceMutation } from './hooks/submit'
import { formatBenefitsUserIncomeSource } from 'lib/user-incomes/format'
import { CurrencyFormField, SelectFormField, DocumentFormField } from './common/form'
import SubmitButton from './common/SubmitButton'
import SubmittingScreen from './common/SubmittingScreen'
import { validateBenefitsIncomeSource } from 'lib/user-incomes/validate'

const {
  BENEFIT_TYPES,
  USER_INCOME_SOURCE_PAY_FREQUENCIES: PAY_FREQUENCIES,
  USER_INCOME_SOURCE_TYPES: SOURCE_TYPES
} = userIncomeSources
const { DOCUMENT_TYPES } = documents

const BenefitsSourceDetails = ({ userId, onSubmit }: { userId: string; onSubmit: () => void }) => {
  const context = useSourceDetails()
  const { isSubmitting, handleSubmit } = useSubmitIncomeSourceMutation(
    userId,
    formatBenefitsUserIncomeSource,
    validateBenefitsIncomeSource,
    onSubmit
  )

  if (isSubmitting) {
    return <SubmittingScreen />
  }

  if (!context || context.data.type !== SOURCE_TYPES.BENEFITS) {
    return null
  }

  return (
    <>
      <div>
        <Heading as='h3' size='md' mb='3'>
          Benefits Income Details
        </Heading>
        <div className='flex flex-col gap-5'>
          <SelectFormField
            label='Benefits Type'
            fieldName='benefits_type'
            optionValues={Object.values(BENEFIT_TYPES)}
          />
          <div className='flex flex-col gap-5 md:flex-row'>
            <SelectFormField
              label='Pay Frequency'
              fieldName='pay_frequency'
              optionValues={Object.values(PAY_FREQUENCIES)}
            />
            <CurrencyFormField
              label='Gross Benefit Amount Per Pay Period'
              fieldName='income_per_period'
            />
          </div>
          <DocumentFormField
            label='Award Letter'
            fieldName='award_letter'
            uploadPrefix={DOCUMENT_TYPES.SOCIAL_SECURITY_AWARD_LETTER}
            documentType={DOCUMENT_TYPES.SOCIAL_SECURITY_AWARD_LETTER}
          />
        </div>
      </div>
      <SubmitButton onSubmit={handleSubmit} />
    </>
  )
}

export default BenefitsSourceDetails
