import { Buffer } from 'buffer'

export const generateAuthorizationHeader = (username, password) => {
  if (!username) {
    throw new Error('No username configured to communicate with Servicing API!')
  }

  const credential = Buffer.from(username + ':' + password).toString('base64')
  return `Basic ${credential}`
}
