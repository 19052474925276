export const manualSwaps: { [addressWord: string]: string } = {
  '205 & 209 lindy blvd': '209 lindy blvd',
  '9 st luke dr': '9 saint luke dr',
  '4643 morgan ford rd': '4643 morganford',
  '3715 upton': '3715 upton st',
  '6023 shillington dr': '6023 shillington ln'
}

export const swapsNames: { [addressWord: string]: string } = {
  avenue: 'ave',
  drive: 'dr',
  road: 'rd',
  circle: 'cir',
  street: 'st',
  place: 'pl',
  court: 'ct',
  lane: 'ln',
  terrace: 'terr',
  boulevard: 'blvd'
}

export const removeExtras: string[] = [
  'east',
  'west',
  'south',
  'southeast',
  'southwest',
  'north',
  'northwest',
  'northeast',
  'se',
  'ne',
  'sw',
  'nw',
  's',
  'e',
  'w',
  'n'
]
