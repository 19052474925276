import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Divider,
  Spacer
} from '@chakra-ui/react'

import DoNotRenewForm from './Form'

interface DoNotRenewModalProps {
  isOpen: boolean
  close: () => void
}

const DoNotRenewModal: React.FC<React.PropsWithChildren<DoNotRenewModalProps>> = ({
  isOpen,
  close
}) => {
  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do Not Renew</ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5}>
            <p>Are you sure you want to mark this rental as "do not renew"?</p>
            <p>This will stop the automated renewal offer email from going out for this tenant.</p>
            <Spacer />
            <DoNotRenewForm onSubmit={close} onCancel={close} />
          </VStack>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DoNotRenewModal
