import React, { useState } from 'react'

import { capabilities } from '@homevest/utils'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { StoreState } from 'store'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  Heading,
  Select,
  Spinner,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  Tooltip
} from '@chakra-ui/react'

import Dropzone from 'components/Dropzone'

import { uninterceptedClient } from 'lib/axios'
import { bulkJournalEntryUploaderUrl, bulkJournalEntryValidatorUrl } from 'lib/config'
import { hasCapability } from 'lib/admin-perms'

import { generateAuthorizationHeader } from 'lib/servicing-api'

const { DAGGER } = capabilities.CAPABILITY_TYPES

export default function BulkJournalEntryUploader() {
  const isDagger = useSelector((store: any) => hasCapability(store.admin, DAGGER))

  if (!isDagger) {
    return (
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>Access Denied!</AlertTitle>
        <AlertDescription>You must be a DAGGER to use this tool.</AlertDescription>
      </Alert>
    )
  }

  return (
    <Box w='100%' p={1}>
      <Heading as='h3' size='xl' width='100%'>
        Bulk Journal Entry Manager
      </Heading>
      <React.Suspense fallback={<Spinner />}>
        <BulkJournalEntryUploaderViewer />
      </React.Suspense>
    </Box>
  )
}

function BulkJournalEntryUploaderViewer() {
  const [journalEntryRows, setJournalEntryRows] = useState<Array<any>>([])
  const [draftUrl, setDraftUrl] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [format, setFormat] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isUploadConfirmed, setIsUploadConfirmed] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')

  const admin: any = useSelector((store: StoreState) => store.admin)
  const adminId: string = admin.id

  const handleUpload = async () => {
    const credential = admin.credentials.dagger.api
    const authorization = generateAuthorizationHeader(credential.username, credential.password)

    try {
      setSuccessMessage('')
      setErrorMessage('')
      setIsLoading(true)
      await uninterceptedClient.post(
        bulkJournalEntryUploaderUrl,
        {
          adminId,
          format,
          url: draftUrl
        },
        {
          headers: {
            Authorization: authorization
          }
        }
      )
      setSuccessMessage(`Your CSV is on it's way to Buildium! Feel like giving it another go?`)
      setIsUploadConfirmed(false)
      setJournalEntryRows([])
      setDraftUrl('')
    } catch (err: any) {
      if (err.isAxiosError) {
        setErrorMessage(err.response.data)
      } else {
        setErrorMessage(err.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleValidation = async (url: string) => {
    const credential = admin.credentials.dagger.api
    const authorization = generateAuthorizationHeader(credential.username, credential.password)

    try {
      setSuccessMessage('')
      setErrorMessage('')
      setIsUploadConfirmed(false)
      setIsLoading(true)
      const { data } = await uninterceptedClient.get(bulkJournalEntryValidatorUrl, {
        headers: {
          Authorization: authorization
        },
        params: {
          adminId,
          format,
          url
        }
      })

      setJournalEntryRows(data.journalEntryRows)
      setDraftUrl(data.draftUrl)
    } catch (err: any) {
      if (err.isAxiosError) {
        if (err.response.status === 400) {
          setJournalEntryRows(err.response.data.journalEntryRows)
        } else {
          setErrorMessage(err.response.data)
        }
      } else {
        setErrorMessage(err.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  let invalidRows: Array<any> = []
  let validRows: Array<any> = []
  if (journalEntryRows) {
    invalidRows = journalEntryRows.filter((d: any) => d.validations.length)
    validRows = journalEntryRows.filter((d: any) => !d.validations.length)
  }

  invalidRows.map((row) => {
    row.errorMessage = row.validations.map((v: any) => v.errorMessage).join(' ')
  })

  const showUpload = invalidRows.length === 0 && validRows.length > 0
  const showDropzone = !showUpload

  return (
    <div>
      {errorMessage && (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Whoops!</AlertTitle>
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
      )}
      {successMessage && (
        <Alert status='success'>
          <AlertIcon />
          <AlertTitle>Hurrah!</AlertTitle>
          <AlertDescription>{successMessage}</AlertDescription>
        </Alert>
      )}
      <Text>
        This crazy ass tool allows you to upload validated journal entry CSVs to Buildium 🤡.
      </Text>
      <Heading as='h1' size='md'>
        Invalid Rows ({(invalidRows || []).length})
      </Heading>
      <TableContainer>
        <Table variant='striped' colorScheme='pink'>
          <TableCaption>You gotta fix these up!</TableCaption>
          <Thead>
            <Tr>
              <Th>Address</Th>
              <Th>Debit</Th>
              <Th>Credit</Th>
              <Th>Date</Th>
              <Th>Ledger Account</Th>
              <Th>Memo</Th>
              <Th>Accounting Entity Id</Th>
              <Th>Errors</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invalidRows &&
              invalidRows.map((row) => (
                <Tr>
                  <Td>{row.address}</Td>
                  <Td>{row.debit}</Td>
                  <Td>{row.credit}</Td>
                  <Td>{row.date}</Td>
                  <Td>{row.ledgerAccount}</Td>
                  <Td maxWidth={'400px'}>
                    <Tooltip label={row.memo}>
                      <Text isTruncated>{row.memo}</Text>
                    </Tooltip>
                  </Td>
                  <Td>{row.accountingEntityId}</Td>
                  <Td maxWidth={'400px'}>
                    <Text isTruncated>
                      <Tooltip label={row.errorMessage}>{row.errorMessage}</Tooltip>
                    </Text>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Heading as='h1' size='md'>
        Valid Rows ({(validRows || []).length})
      </Heading>
      <TableContainer>
        <Table variant='striped' colorScheme='teal'>
          <TableCaption>These are okely dokely!</TableCaption>
          <Thead>
            <Tr>
              <Th>Address</Th>
              <Th>Debit</Th>
              <Th>Credit</Th>
              <Th>Date</Th>
              <Th>Ledger Account</Th>
              <Th>Memo</Th>
              <Th>Accounting Entity Id</Th>
            </Tr>
          </Thead>
          <Tbody>
            {validRows &&
              validRows.map((row) => (
                <Tr>
                  <Td>{row.address}</Td>
                  <Td>{row.debit}</Td>
                  <Td>{row.credit}</Td>
                  <Td>{row.date}</Td>
                  <Td>{row.ledgerAccount}</Td>
                  <Td maxWidth={'400px'}>
                    <Tooltip label={row.memo}>
                      <Text isTruncated>{row.memo}</Text>
                    </Tooltip>
                  </Td>
                  <Td>{row.accountingEntityId}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {showUpload && (
        <Stack>
          <Checkbox
            isChecked={isUploadConfirmed}
            isDisabled={isLoading}
            onChange={(e: any) => {
              setIsUploadConfirmed(e.target.checked)
            }}
          >
            You sure you want to upload {validRows.length} entries to Buildium?
          </Checkbox>
          <Button
            colorScheme='green'
            isDisabled={!isUploadConfirmed || isLoading}
            isLoading={isLoading}
            onClick={handleUpload}
          >
            Upload
          </Button>
        </Stack>
      )}
      {showDropzone && (
        <Stack spacing={5}>
          <Select
            onChange={(event: any) => {
              return setFormat(event.target.value)
            }}
            placeholder='Please choose a CSV format'
          >
            <option value='v1'>The Old School Way</option>
            <option value='v2'>Credit/Debit In One Row</option>
          </Select>
          <Box borderWidth='1px' borderRadius='lg'>
            <Dropzone
              onSuccess={handleValidation}
              uploadPath={'/statements/bulk-journal-entry-uploader/' + uuidv4()}
            />
          </Box>
        </Stack>
      )}
    </div>
  )
}
