import React, { FunctionComponent, useMemo } from 'react'
import { Spinner } from 'reactstrap'
import { startCase } from 'lodash'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import { REQUEST_POLICY } from 'constants/urql'
import { useLeasingStaffByUserIdQuery } from 'graphql/generated'

type LeasingStaffComponentPropTypes = {
  userId: string
}

const LeasingStaffWrapper: FunctionComponent<
  React.PropsWithChildren<LeasingStaffComponentPropTypes>
> = (props) => (
  <ContentSectionCard title={'Leasing Staff'} padding>
    <LeasingStaff {...props} />
  </ContentSectionCard>
)

const LeasingStaff: FunctionComponent<React.PropsWithChildren<LeasingStaffComponentPropTypes>> = ({
  userId
}) => {
  const [{ data, fetching, error }] = useLeasingStaffByUserIdQuery({
    variables: { id: userId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    context: useMemo(() => ({ suspense: false }), [])
  })

  const leasingStaff = data?.agent_lead_group_owners

  if (fetching) {
    return <Spinner />
  }

  if (error) {
    return <SC.ErrorMessage>{error.message}</SC.ErrorMessage>
  }

  if (!leasingStaff?.length) {
    return <SC.MessageText>No assigned leasing staff found</SC.MessageText>
  }

  return (
    <SC.SmallBoxContainer>
      {leasingStaff.map((ls, i: number) => (
        <SC.DetailsContainer key={i}>
          <SC.DetailHeading>{startCase(ls.agent_type) || ''}</SC.DetailHeading>
          <SC.DetailText>
            {ls.admin?.first_name || ''} {ls.admin?.last_name || ''}
          </SC.DetailText>
        </SC.DetailsContainer>
      ))}
    </SC.SmallBoxContainer>
  )
}

export default LeasingStaffWrapper
