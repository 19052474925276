import React from 'react'
import { UpupDocumentRealEstateAcquisitionsQuery } from '../../graphql/generated'

type RealEstateAcquisition =
  UpupDocumentRealEstateAcquisitionsQuery['real_estate_acquisitions'][number]

// todo v2: modularize resource, set as a plugin
const ResourceInformation: React.FC<
  React.PropsWithChildren<{ resource: RealEstateAcquisition }>
> = ({ resource }) => (
  <div className='flex flex-col gap-1'>
    <div className='text-lg font-medium'>Information</div>
    <div>
      <div className='text-gray-500'>Resource id</div>
      <div className='text-gray-900'>{resource?.id}</div>
    </div>
    <div>
      <div className='text-gray-500'>Address</div>
      <div className='text-gray-900'>
        {resource?.mls_listing.delivery_line}, {resource?.mls_listing.city},{' '}
        {resource?.mls_listing.state}
      </div>
    </div>
    <div>
      <div className='text-gray-500'>Acquisition status</div>
      <div className='text-gray-900'>{resource?.status}</div>
    </div>
    <div>
      <div className='text-gray-500'>Closing date</div>
      <div className='text-gray-900'>{resource?.closing_date}</div>
    </div>
  </div>
)

export default ResourceInformation
