"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REJECTION_REASONS = void 0;
exports.REJECTION_REASONS = Object.freeze({
    CRIMINAL_HISTORY: 'criminal_history',
    EVICTION_HISTORY: 'eviction_history',
    INSUFFICIENT_INCOME: 'insufficient_income',
    INSUFFICIENT_CREDIT: 'insufficient_credit',
    INSUFFICIENT_INCOME_AND_CREDIT: 'insufficient_income_and_credit',
    OTHER: 'other'
});
