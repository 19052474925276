import React, { FunctionComponent, Suspense } from 'react'
import { format, parseISO } from 'date-fns'
import { Flex, Text, Spinner, Center, Stack, Heading } from '@chakra-ui/react'
import { useUpupRentalApplicationByIdQuery } from 'graphql/generated'
import StatusTag from '../StatusTag'
import { SnoozeApplicationButton } from '../SnoozeApplication/SnoozeApplicationButton'
import { EscalateApplicationButton } from '../EscalateApplication/EscalateApplicationButton'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'

const SidePeekInfo: FunctionComponent<React.PropsWithChildren<{ appId: string }>> = ({ appId }) => (
  <Suspense
    fallback={
      <Center h={32}>
        <Spinner />
      </Center>
    }
  >
    <SidePeekInfoContent appId={appId} />
  </Suspense>
)

const SidePeekInfoContent: FunctionComponent<React.PropsWithChildren<{ appId: string }>> = ({
  appId
}) => {
  const [{ data, error }] = useUpupRentalApplicationByIdQuery({
    variables: { id: appId },
    pause: !appId
  })
  if (error) {
    return <div>Please let Eng know something went wrong 😢</div>
  }
  const app = data?.rental_applications_by_pk
  return (
    <Stack p={6} gap={4}>
      <Flex direction='row' align='start' gap={4}>
        <Flex direction='column' gap={1} flexGrow={1}>
          <Flex direction='column'>
            {app?.lead_group.lead_group_users.map((lgu) => (
              <Text key={lgu.id} fontWeight={500}>
                {lgu.user.first_name} {lgu.user.last_name}
              </Text>
            ))}
          </Flex>
          <Text fontSize='sm' color='gray.700'>
            {app?.mls_listing.display_line_1}, {app?.mls_listing.city}, {app?.mls_listing.state}{' '}
            {app?.mls_listing.zip}{' '}
          </Text>
          <Text fontSize='xs' color='gray.500'>
            Created{' '}
            {app?.created_at ? format(parseISO(app?.created_at), "MMM dd, yyyy 'at' HH:mm") : null}
          </Text>
          <Flex direction='row' align='center' gap={4} pt={2}>
            <SnoozeApplicationButton rentalApplicationId={app?.id} showText />
            <EscalateApplicationButton rentalApplicationId={app?.id} showText />
          </Flex>
        </Flex>
        <Flex direction='column' gap={2}>
          {Boolean(app?.status) && <StatusTag status={app!.status} />}
          {app?.rental_program_type_applied_for &&
            mapContentToBadge(app?.rental_program_type_applied_for as string)}
        </Flex>
      </Flex>
      <Stack p={4} fontSize='md' bg='gray.100' rounded='md' w='full' gap={2}>
        <Heading size='sm'>Next Steps</Heading>
        <Text>{app?.status_metadata.message}</Text>
      </Stack>
    </Stack>
  )
}

export default SidePeekInfo
