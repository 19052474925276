"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MEDIUMS = exports.EXTERNAL_SOURCES = exports.DIRECTIONS = void 0;
exports.DIRECTIONS = {
    IN: 'in',
    OUT: 'out'
};
exports.EXTERNAL_SOURCES = {
    CLOSE: 'close',
    CUSTOMERIO: 'customerio'
};
exports.MEDIUMS = {
    VOICE: 'voice',
    EMAIL: 'email',
    SMS: 'sms'
};
