import React from 'react'
import PropTypes from 'prop-types'

export default function CardBody({ children }) {
  return <div style={{ paddingBottom: '.5rem', paddingTop: '.5rem', flex: 1 }}>{children}</div>
}

CardBody.propTypes = {
  children: PropTypes.node
}
