import React from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text
} from '@chakra-ui/react'

import { RenewalDetails } from 'types/Renewals'
import RenewRentalConfirmationDetails from './RenewRentalConfirmationDetails'
import RenewRentalLeaseCreateModal from './RenewRentalLeaseCreateModal'

const RenewRentalConfirmation: React.FC<
  React.PropsWithChildren<{
    renewalDetails: RenewalDetails
    isOpen: boolean
    setOpen: (closed: boolean) => void
    goBack: (closed: boolean) => void
  }>
> = ({ renewalDetails, isOpen, setOpen, goBack }) => {
  const [leaseCreateIsOpen, setLeaseCreateIsOpen] = React.useState(false)

  return (
    <>
      <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Confirm Renewal Details</Text>
          </ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize='md'>
              Please confirm the following renewal details. If you need to update the tenant contact
              information, you can do so in Customer 360.
            </Text>
            <RenewRentalConfirmationDetails renewalDetails={renewalDetails} />
          </ModalBody>

          <ModalFooter>
            <Box w={'full'} pb='2'>
              <Flex>
                <Button
                  colorScheme='gray'
                  onClick={() => {
                    setOpen(false)
                    goBack(true)
                  }}
                >
                  Back
                </Button>
                <Spacer />
                <Button
                  colorScheme='green'
                  onClick={async () => {
                    setOpen(false)
                    setLeaseCreateIsOpen(true)
                  }}
                >
                  Confirm
                </Button>
              </Flex>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <RenewRentalLeaseCreateModal
        renewalDetails={renewalDetails}
        isOpen={leaseCreateIsOpen}
        setOpen={setLeaseCreateIsOpen}
        goBack={setOpen}
      />
    </>
  )
}

export default RenewRentalConfirmation
