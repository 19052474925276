"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISPOSITION_COUNTERPARTY_TYPES = exports.DISPOSITION_BID_STATUSES = exports.DISPOSITION_STATUSES = void 0;
exports.DISPOSITION_STATUSES = {
    ACTIVE_ON_MLS: 'active_on_mls',
    CANCELED: 'canceled',
    CLEAR_TO_CLOSE: 'clear_to_close',
    LISTING_PREP: 'listing_prep',
    PENDING_DUE_DILIGENCE: 'pending_due_diligence',
    PRE_CONTRACT_AGREEMENT: 'pre_contract_agreement',
    SOLD: 'sold'
};
exports.DISPOSITION_BID_STATUSES = {
    ACCEPTED: 'accepted',
    ACCEPTED_AND_CLOSED: 'accepted_and_closed',
    PENDING: 'pending',
    TERMINATED: 'terminated'
};
exports.DISPOSITION_COUNTERPARTY_TYPES = {
    CUSTOMER: 'customer',
    INVESTOR: 'investor',
    OWNER_OCCUPANT: 'owner_occupant'
};
