import React from 'react'

const BrandIcon = () => (
  <svg width='27' height='32' viewBox='0 0 27 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.2473 0.849886L24.2391 6.05526C25.7332 7.02849 26.6253 8.67406 26.6253 10.4575V15.0634H22.6972V10.4575C22.6972 10.0075 22.4721 9.5923 22.0953 9.34685L14.1033 4.14148C13.6668 3.85726 13.1017 3.85546 12.6636 4.13674L4.5373 9.35586C4.15589 9.60082 3.92811 10.0179 3.92811 10.4711V15.0634H0V10.4711C0 8.67428 0.902615 7.02173 2.41449 6.05075L10.541 0.831631C12.2776 -0.283822 14.5175 -0.276611 16.2473 0.849886Z'
      fill='currentColor'
    />
    <path
      d='M13.3126 28.072C8.13798 28.072 3.92811 23.8619 3.92811 18.6872H0C0 26.0281 5.97203 32 13.3126 32C20.6533 32 26.6253 26.0281 26.6253 18.6872H22.6972C22.6972 23.8619 18.4873 28.072 13.3126 28.072Z'
      fill='currentColor'
    />
    <path
      d='M19.0667 18.6925H15.1388C15.1388 19.6993 14.3194 20.5186 13.3126 20.5186C12.3058 20.5186 11.4866 19.6993 11.4866 18.6925H7.55855C7.55855 21.8654 10.1399 24.4466 13.3126 24.4466C16.4856 24.4466 19.0667 21.8654 19.0667 18.6925Z'
      fill='currentColor'
    />
  </svg>
)

export default BrandIcon
