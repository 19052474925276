import { manualSwaps, removeExtras, swapsNames } from './addressStandardizationValues'
import _ from 'lodash'

export const standardizeAddress = (address: string) => {
  const removedPunctuation = address.replace(new RegExp('[.,]'), '')

  const addressArr = removedPunctuation.toLocaleLowerCase().split(' ')
  const updatedAddressArr = addressArr
    .map((word) => {
      if (swapsNames[word]) {
        return swapsNames[word]
      }
      return word
    })
    .filter((word) => !removeExtras.includes(word))

  const removedDuplicateWordsNextToOneAnother = updatedAddressArr.slice(1).filter((word, ind) => {
    if (word === updatedAddressArr[ind]) {
      return false
    }
    return true
  })

  const updated = [updatedAddressArr[0], ...removedDuplicateWordsNextToOneAnother].join(' ')

  if (manualSwaps[updated]) {
    return manualSwaps[updated]
  }
  return updated
}

export const reStandardizeAddress = (address: string) => {
  const addressArr = address.split(' ')

  const secondSwap = {
    st: 1,
    pl: 1,
    ln: 1,
    dr: 1,
    cr: 1,
    rd: 1,
    ave: 1,
    ct: 1,
    park: 1
  }

  const lastWord = addressArr.slice(-1)[0]
  if (_.get(secondSwap, lastWord)) {
    return addressArr.slice(0, -1).join(' ')
  }
  return address
}
