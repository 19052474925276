import React, { FunctionComponent, useState } from 'react'
import { ActionButton } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'

const InputModal: FunctionComponent<
  React.PropsWithChildren<{
    isOpen: boolean
    setIsOpen: Function
    callback: (input: string) => void
    message: string
  }>
> = ({ isOpen, setIsOpen, callback, message }) => {
  const [value, setValue] = useState('')

  const handleSubmit = () => {
    callback(value)
    setIsOpen(false)
    setValue('')
  }

  const handleCancel = () => {
    setIsOpen(false)
    setValue('')
  }

  return (
    <SC.Modal isOpen={isOpen}>
      <SC.ModalSubText>{message}</SC.ModalSubText>
      <SC.EditDetailsWrapper>
        <SC.EditDetailsInput
          type='text'
          value={value}
          onChange={(evt) => setValue(evt.currentTarget.value)}
        />
      </SC.EditDetailsWrapper>
      <SC.ButtonContainer>
        <ActionButton colorScheme='secondary' onClick={handleSubmit}>
          Submit
        </ActionButton>
        <ActionButton show onClick={handleCancel}>
          Cancel
        </ActionButton>
      </SC.ButtonContainer>
    </SC.Modal>
  )
}

export default InputModal
