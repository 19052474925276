import React from 'react'
import { useQuery } from 'urql'
import { Input, InputGroup, InputGroupAddon, Button as StrapButton, Spinner } from 'reactstrap'
import { debounce } from 'lodash'
import { capabilities } from '@homevest/utils'

import { ErrorText, Button, HeaderText, ThickPlusIcon, COLORS } from 'components/Toolkit'
import CapabilityList from './CapabilityList'
import CreateCapabilityModal from './CreateCapabilityModal'
import {
  UpupAdminsWithCapabilitiesDocument,
  UpupCapabilitiesWithAdminDocument
} from 'graphql/generated'
import { REQUEST_POLICY } from 'constants/urql'
import { isLike, isNotEqual } from 'lib/hasura-comparators'

const { ACCOUNT_CREATOR } = capabilities.CAPABILITY_TYPES

const CapabilityTab: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [searchTextView, setSearchTextView] = React.useState<string>('')
  const [searchTextControl, setSearchTextControl] = React.useState<string>('')

  const setSearchSlow = React.useMemo(
    () => debounce(setSearchTextControl, 250, { trailing: true }),
    [setSearchTextControl]
  )

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '2rem',
          marginBottom: '1rem'
        }}
      >
        <HeaderText style={{ margin: 0 }} size='h3'>
          Manage by capability
        </HeaderText>
        <Button isSecondary={false} onClick={() => setShowModal(true)}>
          <ThickPlusIcon color={COLORS.PRIMARY.COCONUT} /> Create
        </Button>
      </div>
      <InputGroup style={{ marginBottom: '1rem' }}>
        <Input
          autoFocus
          placeholder='Search...'
          value={searchTextView}
          onChange={({ target }) => {
            setSearchTextView(target.value)
            setSearchSlow(target.value)
          }}
        />
        {searchTextView.length > 0 && (
          <InputGroupAddon addonType='append'>
            <StrapButton
              outline
              onClick={() => {
                setSearchTextView('')
                setSearchTextControl('')
              }}
            >
              &#10005;
            </StrapButton>
          </InputGroupAddon>
        )}
      </InputGroup>
      <React.Suspense
        fallback={
          <div
            style={{
              paddingTop: '2rem',
              paddingBottom: '2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <CapabilityListWrapper searchText={searchTextControl} />
      </React.Suspense>
      <CreateCapabilityModal
        isOpen={showModal}
        onCreate={() => setShowModal(false)}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}

export default CapabilityTab

const CapabilityListWrapper: React.FC<React.PropsWithChildren<{ searchText: string }>> = ({
  searchText
}) => {
  const [{ data: capData, error: capError }] = useQuery({
    query: UpupCapabilitiesWithAdminDocument,
    variables: {
      where: {
        type: isNotEqual(ACCOUNT_CREATOR),
        ...(searchText.length > 1 ? { name: isLike(`%${searchText}%`) } : {})
      }
    },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK
  })

  const [{ data: adminData, error: adminError }] = useQuery({
    query: UpupAdminsWithCapabilitiesDocument,
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK
  })

  if (capError !== undefined || adminError !== undefined) {
    return (
      <ErrorText>
        {capError?.message ?? ''}
        {adminError?.message ?? ''}
      </ErrorText>
    )
  }

  return <CapabilityList capabilities={capData?.capabilities} allAdmins={adminData?.admins} />
}
