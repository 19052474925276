import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Spinner } from 'reactstrap'
import { useUnpaidLiabilitiesQuery } from 'graphql/generated'
import { Button, ButtonSpinner, Divider } from '@chakra-ui/react'
import LiabilityChargeRow from './LiabilityChargeRow'
import UserPaymentMethodSelector from './UserPaymentSelector'
import { formatMoney } from 'lib/numbers'
import axios from 'lib/axios'
import { RentalDetailsContext } from '../RentalDetailsContext'

interface LiabilityCharge {
  enabled: boolean
  liability_type_id: string
  amount: number
  note?: string
}

const SummarySection: React.FC<
  React.PropsWithChildren<{
    liabilites: LiabilityCharge[]
    typeMap: Record<string, { name: string }>
  }>
> = ({ liabilites, typeMap }) => {
  const enabledLiabilities = liabilites.filter((liability) => liability.enabled)
  const total = enabledLiabilities.reduce((acc, liability) => acc + liability.amount, 0)
  // create a summary by liability type name
  const summary = enabledLiabilities.reduce((acc, liability) => {
    const liabilityTypeId = liability.liability_type_id
    if (liabilityTypeId) {
      if (acc[liabilityTypeId]) {
        acc[liabilityTypeId] += liability.amount
      } else {
        acc[liabilityTypeId] = liability.amount
      }
    }
    return acc
  }, {} as { [key: string]: number })

  return (
    <div>
      <ul>
        {Object.keys(summary).map((liabilityTypeId) => {
          const liabilityType = typeMap[liabilityTypeId]
          return (
            <li key={liabilityTypeId}>
              {liabilityType.name}: ${summary[liabilityTypeId].toFixed(2)}
            </li>
          )
        })}
      </ul>
      <Divider />
      <strong>Total: ${total.toFixed(2)}</strong>
    </div>
  )
}

interface ChargeUserRequest {
  rentalId: string
  userId: string
  paymentMethodId: string
  liabilities: LiabilityCharge[]
}

const chargeLiabilities = async (chargeUserRequest: ChargeUserRequest) => {
  const { rentalId, userId, paymentMethodId, liabilities } = chargeUserRequest
  const response = await axios.post(`/admin/rentals/${rentalId}/charge-liabilities`, {
    userId,
    paymentMethodId,
    liabilities: liabilities.filter((l) => l.enabled === true)
  })
  return response.data
}

export default function ChargeLiabilitiesModal({
  onSubmit = () => {},
  rentalId
}: {
  onSubmit: () => void
  rentalId: string
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [liabilitiesToCharge, setLiabilitiesToCharge] = useState<LiabilityCharge[]>([])
  const [userIdToCharge, setUserIdToCharge] = useState<string>()
  const [paymentMethodId, setPaymentMethodId] = useState<string>()

  let { liabilityTypes } = useContext(RentalDetailsContext)

  const typeMap = useMemo(() => {
    return liabilityTypes.reduce((acc, type) => {
      acc[type.id] = type
      return acc
    }, {} as Record<string, { name: string }>)
  }, [liabilityTypes])
  const [{ data: unpaidLiabilities }] = useUnpaidLiabilitiesQuery({
    variables: {
      rentalId
    }
  })

  useEffect(() => {
    const groupedByType = unpaidLiabilities?.unpaid_liabilities.reduce((acc, liability) => {
      const liabilityTypeId = liability.liability_type_id
      if (liabilityTypeId) {
        if (acc[liabilityTypeId]) {
          acc[liabilityTypeId].amount += liability.amount_remaining
        } else {
          acc[liabilityTypeId] = {
            liability_type_id: liability.liability_type_id,
            amount: liability.amount_remaining,
            enabled: true
          }
        }
      }
      return acc
    }, {} as { [key: string]: LiabilityCharge })
    setLiabilitiesToCharge(
      Object.values(groupedByType ?? {}).map((l) => ({
        ...l
      }))
    )
  }, [unpaidLiabilities])

  const areLiabilityAmountsInvalid = liabilitiesToCharge.some(
    (liability) => liability.enabled && (!liability.amount || liability.amount <= 0)
  )
  const isSubmissionDisabled =
    !paymentMethodId || !userIdToCharge || isSaving || areLiabilityAmountsInvalid

  const handleChargeUser = async () => {
    if (!userIdToCharge || !paymentMethodId) {
      alert('Please select a user and payment method')
      return
    }

    if (areLiabilityAmountsInvalid) {
      alert(
        'Please enter a valid amount (greater than 0) for each liability, or deselect any liabilities that you do not wish to charge'
      )
      return
    }

    setIsSaving(true)
    try {
      await chargeLiabilities({
        rentalId,
        userId: userIdToCharge,
        paymentMethodId: paymentMethodId,
        liabilities: liabilitiesToCharge
      })
      onSubmit()
    } catch (err: any) {
      alert(err.message)
    } finally {
      setIsSaving(false)
    }
  }

  const total = liabilitiesToCharge
    .filter((l) => l.enabled === true)
    .reduce((acc, liability) => acc + liability.amount, 0)
  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <UserPaymentMethodSelector
          rentalId={rentalId}
          onPaymentMethodChange={setPaymentMethodId}
          onUserChange={setUserIdToCharge}
        />
        {liabilitiesToCharge.map((liability, index) => (
          <LiabilityChargeRow
            key={liability.liability_type_id}
            liabilityLabel={typeMap[liability.liability_type_id].name}
            amount={liability.amount}
            note={liability.note}
            enabled={liability.enabled}
            updateAmount={(amount) => {
              setLiabilitiesToCharge((prev) => {
                const newLiabilities = [...prev]
                newLiabilities[index].amount = amount
                return newLiabilities
              })
            }}
            updateNote={(note) => {
              setLiabilitiesToCharge((prev) => {
                const newLiabilities = [...prev]
                newLiabilities[index].note = note
                return newLiabilities
              })
            }}
            updateEnabled={(enabled) => {
              setLiabilitiesToCharge((prev) => {
                const newLiabilities = [...prev]
                newLiabilities[index].enabled = enabled
                return newLiabilities
              })
            }}
          />
        ))}
        <Divider />
        <SummarySection liabilites={liabilitiesToCharge} typeMap={typeMap} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            colorScheme='teal'
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={handleChargeUser}
            isDisabled={isSubmissionDisabled}
          >
            {isSaving ? <ButtonSpinner /> : `Charge ${formatMoney(total, 2, '$')}?`}
          </Button>
        </div>
      </React.Suspense>
    </>
  )
}
