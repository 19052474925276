import { toPairs } from 'lodash'
import moment from 'moment'
import { rentRollLedgerLockDate } from 'lib/config'

export const combineFirstNameLastName: (person?: {
  first_name?: string | null
  last_name?: string | null
}) => string = (person) => `${person?.first_name ?? ''} ${person?.last_name ?? ''}`

export const recursiveObjectKeySearch = (
  obj: { [k: string]: any },
  searchKey: string,
  results: any[] = []
) => {
  toPairs(obj).forEach(([key, value]) => {
    if (!value) {
      return
    }

    if (key === searchKey) {
      results.push(value)
      return
    }

    if (typeof value === 'object') {
      recursiveObjectKeySearch(value, searchKey, results)
      return
    }
  })

  return results
}

export default function isDateBeforeRentRollLockDate(targetDate: string): boolean {
  const ledgerLockDate = rentRollLedgerLockDate
  return moment(targetDate).isBefore(moment(ledgerLockDate, 'MM-DD-YYYY'))
}
