import React, { useEffect, useState } from 'react'

import { capabilities } from '@homevest/utils'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { add, format } from 'date-fns'

import { BodyText, Button, HeaderText, ErrorText, SuccessText } from 'components/Toolkit'
import Dropzone from 'components/Dropzone'

import { hasCapability } from 'lib/admin-perms'
import { uninterceptedClient } from 'lib/axios'
import { mortgageEngineUrl } from 'lib/config'

import { generateAuthorizationHeader } from 'lib/servicing-api'

const { DAGGER } = capabilities.CAPABILITY_TYPES

const StyledContent = styled.div`
  width: 500px;
  margin: 0 auto;
`

export default function MortgageUploader() {
  const isDagger = useSelector((store) => hasCapability(store.admin, DAGGER))

  if (!isDagger) {
    return <ErrorText>You do not have the capability of DAGGER.</ErrorText>
  }

  return (
    <StyledContent>
      <HeaderText size='h3' style={{ textAlign: 'center' }}>
        Mortgage Uploader
      </HeaderText>
      <React.Suspense fallback={<Spinner color='primary' />}>
        <MortgageUploaderViewer />
      </React.Suspense>
    </StyledContent>
  )
}

function MortgageUploaderViewer() {
  const [endDate, setEndDate] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [startDate, setStartDate] = useState('')
  const admin = useSelector((store) => store.admin)

  const handleSuccess = async (url) => {
    if (!areDatesValid()) {
      return
    }

    const credential = admin.credentials.dagger.api
    const authorization = generateAuthorizationHeader(credential.username, credential.password)

    try {
      setIsLoading(true)
      const { data } = await uninterceptedClient.post(
        mortgageEngineUrl,
        {
          url,
          start_date: startDate,
          end_date: endDate
        },
        {
          headers: {
            Authorization: authorization
          }
        }
      )
      if (!data.url) {
        throw new Error('No url returned, contact Sun cause he did something dumb!' + data.message)
      }
      setSuccessMessage(
        `Upload complete, your mortgage XLSX can be <a target="_blank" href="${data.url}">downloaded here</a> sugar <img src="https://storage.googleapis.com/homevest-production.appspot.com/public/saucey-holt.png"/>!`
      )
    } catch (err) {
      if (err.isAxiosError) {
        setErrorMessage(err.response.data)
      } else {
        setErrorMessage(err.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getFormattedDate = (date) => {
    if (!date) {
      return ''
    }

    try {
      return format(date, 'yyyy-MM-dd')
    } catch (err) {
      if (err instanceof RangeError) {
        return null
      } else {
        throw err
      }
    }
  }

  const clearDate = (setter) => {
    setter('')
  }

  const handleReset = () => {
    setIsLoading(false)
    setErrorMessage(null)
    setSuccessMessage(null)
  }

  const areDatesOk = () => {
    if (!startDate || !endDate) {
      return true
    }

    return startDate && endDate && startDate < endDate
  }

  const areDatesValid = () => {
    if (!areDatesOk()) {
      setErrorMessage('Start date must be less than end date!')
      return false
    }

    setErrorMessage('')
    return true
  }

  const onEndDateChange = (event) => {
    const offset = new Date().getTimezoneOffset()
    setEndDate(add(new Date(event.target.value), { minutes: offset }))
  }

  const onStartDateChange = (event) => {
    const offset = new Date().getTimezoneOffset()
    setStartDate(add(new Date(event.target.value), { minutes: offset }))
  }

  if (isLoading) {
    return <Spinner color='primary' style={{ textAlign: 'center' }} />
  }

  if (successMessage) {
    return (
      <div>
        <SuccessText>
          <div dangerouslySetInnerHTML={{ __html: successMessage }} />
        </SuccessText>
        <Button
          onClick={() => {
            handleReset()
          }}
        >
          Upload Again
        </Button>
      </div>
    )
  }

  return (
    <div>
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      <BodyText style={{ marginBottom: '20px' }}>
        This tool takes a CSV of mortgages and returns a XLSX of their payment schedules for
        Buildium. Hopefully Sam knows how to do math!
      </BodyText>
      <BodyText style={{ marginBottom: '20px' }}>
        NOTE: This tool no longer guards against duplicate payments.
      </BodyText>
      <BodyText>Start Date</BodyText>
      <input type='date' onChange={onStartDateChange} value={getFormattedDate(startDate)}></input>
      <Button
        size='vvs'
        onClick={() => {
          clearDate(setStartDate)
        }}
      >
        Clear
      </Button>
      <BodyText>End Date</BodyText>
      <input type='date' onChange={onEndDateChange} value={getFormattedDate(endDate)}></input>
      <Button
        size='vvs'
        onClick={() => {
          clearDate(setEndDate)
        }}
      >
        Clear
      </Button>

      {areDatesOk() && (
        <div style={{ marginTop: '20px' }}>
          <Dropzone
            onSuccess={handleSuccess}
            uploadPath={'/statements/mortgage-transformer/' + uuidv4()}
          />
        </div>
      )}
    </div>
  )
}
