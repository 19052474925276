import React, { useState } from 'react'
import { Center, Spinner } from '@chakra-ui/react'
import { PetDetails, PetWithId } from '@homevest/types/rental-applications'

import DetailsSection from '../Common/DetailsSection'
import EditPets from './EditMode'
import PetsDisplay from './Display'

type PetsSectionWrapperProps = {
  petDetails: PetDetails
  rentalApplicationId: string
  isEditable: boolean
  isFetching: boolean
}

type PetsSectionProps = {
  pets: PetWithId[]
  rentalApplicationId: string
  isFetching: boolean
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
}

const PetsSectionWrapper: React.FC<React.PropsWithChildren<PetsSectionWrapperProps>> = ({
  petDetails,
  rentalApplicationId,
  isEditable,
  isFetching
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const { data: pets, submitted_at: submittedAt } = petDetails

  return (
    <DetailsSection
      title='Pets'
      submittedAt={submittedAt}
      isEditable={isEditable}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
    >
      <PetsSection
        rentalApplicationId={rentalApplicationId}
        pets={pets ?? []}
        isFetching={isFetching}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </DetailsSection>
  )
}

const PetsSection: React.FC<React.PropsWithChildren<PetsSectionProps>> = ({
  pets,
  rentalApplicationId,
  isFetching,
  isEditing,
  setIsEditing
}) => {
  if (isFetching) {
    return (
      <Center marginY={3}>
        <Spinner colorScheme='teal' />
      </Center>
    )
  }

  if (isEditing) {
    return (
      <EditPets
        currentPets={pets}
        rentalApplicationId={rentalApplicationId}
        exitEditMode={() => setIsEditing(false)}
      />
    )
  }

  return <PetsDisplay pets={pets} />
}

export default PetsSectionWrapper
