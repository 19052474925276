import React from 'react'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { Center, Heading, Text, Spinner } from '@chakra-ui/react'

import { useVendorDetailsByIdQuery } from 'graphql/generated'
import { DynamicWidthPageContainer } from 'components/TailwindUIToolkit/Layouts/DynamicWidthPageContainer'
import VendorInformation from 'components/Vendors/Details/VendorInformation'
import TicketsTable from 'components/Vendors/Details/TicketsTable'
import { TwoColumn } from 'components/TailwindUIToolkit'

const VendorDetails = () => {
  const { vendorId } = useParams<{ vendorId: string }>()

  const [{ data, fetching }, refetchVendorDetails] = useVendorDetailsByIdQuery({
    variables: { vendorId }
  })

  if (fetching) {
    return (
      <Center>
        <Spinner color='teal' size='lg' />
      </Center>
    )
  }

  const vendorData = data?.vendors_by_pk

  if (!vendorData) {
    return (
      <div className='p-3'>
        <Heading size='lg'>Error!</Heading>
        <Text>No vendor found with ID {vendorId} - are you sure you have the right link?</Text>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>Vendor | {vendorData.name}</title>
      </Helmet>
      <DynamicWidthPageContainer
        pathToPage={[
          {
            name: 'Vendors',
            href: '/construction/vendors'
          },
          {
            name: vendorData.name || '<vendor>',
            href: `/construction/vendors/${vendorId}`
          }
        ]}
        maxWidth='max-w-full'
      >
        <Heading size='lg'>{vendorData.name}</Heading>
        <div className='p-2 pt-4'>
          <TwoColumn
            sidebarMaxWidth='md'
            sidebarSide='left'
            sidebarContent={
              <VendorInformation
                onUpdate={() => refetchVendorDetails({ requestPolicy: 'network-only' })}
                vendor={vendorData}
              />
            }
            mainContent={<TicketsTable vendorId={vendorId} />}
            useRowsOnSmallScreens
          />
        </div>
      </DynamicWidthPageContainer>
    </>
  )
}

export default VendorDetails
