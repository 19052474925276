import { createClient, fetchExchange, subscriptionExchange } from 'urql'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { devtoolsExchange } from '@urql/devtools'
import { CacheExchangeOpts } from '@urql/exchange-graphcache'
import schema from '../graphql/graphql.schema.json'
import { graphqlConfig } from './config'
import { getCacheExchange } from './urql-cache-exchange'

const createSubscriptionExchange = (authToken: string) => {
  const subscriptionClient = new SubscriptionClient(graphqlConfig.websocketURL, {
    reconnect: true,
    connectionParams: {
      headers: { Authorization: `Bearer ${authToken}` }
    }
  })
  return subscriptionExchange({
    forwardSubscription(operation) {
      return subscriptionClient.request(operation)
    }
  })
}

export const createUrqlClient = (authToken: string | null) =>
  createClient({
    url: graphqlConfig.serverURL,
    suspense: true,
    fetchOptions: {
      ...(authToken ? { headers: { Authorization: `Bearer ${authToken}` } } : {})
    },
    exchanges: [
      ...(process.env.NODE_ENV === 'development' ? [devtoolsExchange] : []),
      getCacheExchange(schema as CacheExchangeOpts['schema']),
      ...(authToken ? [createSubscriptionExchange(authToken)] : []),
      fetchExchange
    ]
  })
