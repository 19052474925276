import { SET_LEAD_GROUPS } from './actions'

export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_LEAD_GROUPS:
      return action.payload
    default:
      return state
  }
}
