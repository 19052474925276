import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

class RentalsPendingPaymentTable extends Component {
  static propTypes = {
    rentalsPendingPayment: PropTypes.array.isRequired,
    linkTo: PropTypes.func.isRequired
  }

  render() {
    return (
      <div style={{ marginBottom: 50 }}>
        <h3>Pending Move-in Payments</h3>

        <Table responsive='true'>
          <thead>
            <tr>
              <th>Tenant</th>
              <th>Property</th>
              <th>Days Until Move-in</th>
              <th>Occupancy Date</th>
            </tr>
          </thead>

          <tbody>
            {this.props.rentalsPendingPayment.map((rental) => (
              <tr key={rental.id}>
                <th scope='row'>
                  <Link to={this.props.linkTo(rental.id)}>{rental.user.name}</Link>
                </th>

                <td>
                  {rental?.property?.real_estate_acquisition?.mls_listing?.display_line_1 ||
                    'need to backfill'}
                </td>
                <td>
                  {moment(rental.occupancy_date).startOf('d').diff(moment().startOf('d'), 'd')}
                </td>
                <td>{moment(rental.occupancy_date).format('YYYY-MM-DD')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  rentalsPendingPayment: Array.from(
    state.rentals.rentalsPendingPayment ? state.rentals.rentalsPendingPayment.values() : []
  )
})

export default connect(mapStateToProps, null)(RentalsPendingPaymentTable)
