import React from 'react'
import { Helmet } from 'react-helmet'
import { Heading } from '@chakra-ui/react'

const BasicPageLayout: React.FC<{
  heading?: string
  tabTitle?: string
  topRightElement?: React.ReactNode
  children?: React.ReactNode
}> = ({ heading, tabTitle, topRightElement, children }) => {
  return (
    <>
      {tabTitle && (
        <Helmet>
          <title>{tabTitle}</title>
        </Helmet>
      )}
      <div className='p-4'>
        <div className='flex flex-row justify-between'>
          {heading && <Heading>{heading}</Heading>}
          {topRightElement}
        </div>
        {children}
      </div>
    </>
  )
}

export default BasicPageLayout
