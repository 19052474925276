import moment from 'moment'
import {
  agreementSigners as AGREEMENT_SIGNERS,
  payments as PAYMENTS,
  rentals as RENTALS,
  rentalUsers as RENTAL_USERS
} from '@homevest/utils'

import { getLedgerItemsWithBalance } from 'lib/ledger'
import { getContactInfo } from './users'
import {
  Rental_Program_Types_Enum_Enum,
  RentRollRentalFragment,
  TenantsFragment,
  WithAddressInfoFragment,
  WithLlcInfoFragment,
  WithRentalAgreementInfoFragment
} from 'graphql/generated'
import { FullRental, FullListRental } from 'types/FullRental'
import { first, startCase } from 'lodash'
import { LedgerPayment, RentalLedger } from 'screens/RentRoll/Details/Ledger'
import { formatMoney } from './numbers'
import { RentRollListRentalFragment } from '../graphql/generated'

const { PAYMENT_CATEGORIES, PAYMENT_STATUSES } = PAYMENTS
const { LEASE_END_REASONS, RENTAL_PROGRAM_LEASE_TYPES, RENTAL_STATUS } = RENTALS
const { ROLES: SIGNER_ROLES, TENANT_PREFIX } = AGREEMENT_SIGNERS
const { RENTAL_USER_ROLES } = RENTAL_USERS

// Have to manually add agreements type because they have different query params in the List vs Index view
type Rental = RentRollListRentalFragment

export const getAddress = (rental: WithAddressInfoFragment) => {
  const address = rental.portfolio_home?.home?.address

  return address
    ? `${address.display_line_1}, ${address.display_line_2}`
    : 'Need to backfill opportunities and addresses'
}

export const getMoveOutDate = (rental: RentRollListRentalFragment) => {
  const { final_liability_date: finalLiabilityDate, move_out_date: moveOutDate } = rental

  const rentalAgreements = rental.rental_agreement_histories || []

  const endAt = moveOutDate || finalLiabilityDate || rentalAgreements[0]?.ends_at

  if (!endAt) {
    return null
  }

  if (endAt.includes('/')) {
    return moment(endAt, 'M/D/YYYY')
  }

  return moment(endAt)
}

export const getMoveOutStatus = (rental: RentRollListRentalFragment) => {
  const { occupancy_date: occupancyDate, status } = rental

  const moveOutDate = getMoveOutDate(rental)
  const isFutureMoveIn = moment(occupancyDate).isAfter(moment())

  const daysUntilMoveout = moveOutDate
    ? moment(moveOutDate).diff(moment().startOf('day'), 'days')
    : 0

  let statusLabel: string

  if (typeof daysUntilMoveout === 'number') {
    if (daysUntilMoveout > 90) {
      statusLabel = isFutureMoveIn ? 'Pending move-in' : '3+ months'
    } else if (status === 'active') {
      if (daysUntilMoveout < 0) {
        statusLabel = 'Holdover'
      } else {
        statusLabel = `${daysUntilMoveout}`
      }
    } else if (status === 'pending') {
      statusLabel = 'Pending move-in'
    } else {
      statusLabel = 'Moved out'
    }
  } else if (status === 'active') {
    statusLabel = 'Holdover'
  } else if (status === 'pending') {
    statusLabel = 'Pending move-in'
  } else {
    statusLabel = 'Moved out'
  }

  return {
    daysUntilMoveout,
    statusLabel
  }
}

export const getLlc = (rental?: WithLlcInfoFragment | null, date?: string) => {
  if (!rental) {
    return undefined
  }

  if (!date) {
    return rental.property.most_recent_llc_property?.llc
  }

  const {
    property: { llc_properties: llcProperties }
  } = rental

  let llcProperty =
    llcProperties?.find((lp) => {
      const { end_date: endsAt, start_date: startsAt } = lp

      return (
        moment(date).isSameOrAfter(startsAt) && (!endsAt || moment(date).isSameOrBefore(endsAt))
      )
    }) ?? null

  return llcProperty?.llc
}

export const getCurrentRentalAgreementHistory = (rental: WithRentalAgreementInfoFragment) => {
  const { rental_agreement_histories: rentalAgreementHistories } = rental

  return rentalAgreementHistories.find((rah) => {
    const { ends_at: endsAt, starts_at: startsAt } = rah

    const occupancyDate = moment(rental.occupancy_date).startOf('day')
    const today = moment().startOf('day')
    const dateForAgreement = occupancyDate.isAfter(today) ? occupancyDate : today

    return (
      dateForAgreement.isSameOrAfter(startsAt) &&
      (!endsAt || dateForAgreement.isSameOrBefore(endsAt))
    )
  })
}

export const getRentForRental = (rental: WithRentalAgreementInfoFragment) => {
  const { target_monthly_option_premium: targetMonthlyOptionPremium } = rental
  const { rent } = getCurrentRentalAgreementHistory(rental) || {}

  return {
    optionPremium: targetMonthlyOptionPremium / 100,
    rent
  }
}

export const getAddressForRental = (rental: WithAddressInfoFragment) => {
  const address = rental.portfolio_home?.home?.address

  return address
    ? `${address.display_line_1}, ${address.display_line_2}`
    : 'Need to backfill opportunities and addresses'
}

export const getRentalDetailDisplayData = (rental: RentRollRentalFragment) => {
  // const { statusLabel, daysUntilMoveout } = getMoveOutStatus(rental)

  return {
    ...rental,
    address: getAddressForRental(rental),
    ...getRentForRental(rental),
    investmentTransfer: first(rental.option_premium_transfers_received)?.amount
  }
}

export const convertRentalToFullRental = <T extends Rental>(rental: T): T & FullRental => {
  const {
    payments = [],
    rental_credits: rentalCredits = [],
    rental_liabilities: rentalLiabilities = [],
    target_monthly_option_premium: targetMonthlyOptionPremium
  } = rental

  const address = rental.portfolio_home?.home?.address
  const addressLine = `${address?.display_line_1}, ${address?.display_line_2}`

  const {
    option_premium: optionPremium,
    rent,
    renewal_months: renewalMonths
  } = getCurrentRentalAgreementHistory(rental) || {}

  const { statusLabel, daysUntilMoveout } = getMoveOutStatus(rental)

  const { balance, investmentToDate, rentPaidToDate, currentBalance } = getLedgerItemsWithBalance(
    payments,
    rentalLiabilities,
    rentalCredits
  )

  return {
    ...rental,
    address: addressLine,
    balance,
    currentBalance,
    daysUntilMoveout,
    investmentToDate: investmentToDate,
    investmentTransfer: first(rental.option_premium_transfers_received)?.amount,
    optionPremium: optionPremium || targetMonthlyOptionPremium / 100,
    renewalMonths: renewalMonths ?? undefined,
    rent,
    rentPaidToDate: rentPaidToDate,
    statusLabel
  }
}

export const getCSVExportForRentalLedger = (
  rental: RentRollRentalFragment,
  ledger: RentalLedger
) => {
  const address = getAddressForRental(rental)
  const rentalUsers = rental.rental_users
  const ledgerLineItems = ledger.ledgerDataWithBalances.map((ledgerLineItem) => {
    const itemUser = rentalUsers.find((u) => u?.user_id === ledgerLineItem.userId)?.user
    return {
      llc_name: getLlc(rental, ledgerLineItem.date)?.name,
      charge_date: ledgerLineItem.date,
      available_on: (ledgerLineItem as LedgerPayment).availableOn,
      type: ledgerLineItem.type === 'Liability' ? 'Charge' : ledgerLineItem.type,
      category: startCase(ledgerLineItem.category || ''),
      amount: formatMoney(ledgerLineItem.amount),
      status: (ledgerLineItem as LedgerPayment).status,
      paid_by: `${itemUser?.first_name} ${itemUser?.last_name}`,
      overall_balance: formatMoney(ledgerLineItem.balance),
      comments: ledgerLineItem.note
    }
  })

  const formatTenantForLedger = (user: (typeof rentalUsers)[number]['user']) => {
    return `${user?.first_name} ${user?.last_name} - ${getContactInfo(
      'phone',
      user?.user_contact_details
    )} - ${getContactInfo('email', user?.user_contact_details)}`
  }

  const headerData = [
    ['Tenant Ledger'],
    ['Up&Up'],
    [''],
    ['Address', address],
    ['Current Owner', getLlc(rental)?.name],
    ...rentalUsers.map((user, index) =>
      index === 0
        ? ['Tenants', formatTenantForLedger(user.user)]
        : ['', formatTenantForLedger(user.user)]
    ),
    ['Current Balance:', formatMoney(ledger.balance)],
    [
      'Current Balance (excl. option premium):',
      formatMoney(
        ledger.balance -
          (Object.values(ledger.summaries).find(
            (s) => s.liabilityTypeName === 'Wallet Contribution'
          )?.balance || 0)
      )
    ],
    ['Rent Paid:', formatMoney(ledger.rentPaidToDate)],
    ['Wallet Contribution:', formatMoney(ledger.investmentToDate)]
  ]

  const ledgerCsvData = [
    [
      'address',
      'llc_name',
      'date',
      'available_on',
      'type',
      'category',
      'amount',
      'status',
      'paid_by',
      'balance'
    ],
    ...ledgerLineItems.map((ledgerLineItem) => [
      address,
      ledgerLineItem.llc_name,
      ledgerLineItem.charge_date,
      ledgerLineItem.available_on,
      ledgerLineItem.type,
      ledgerLineItem.category,
      ledgerLineItem.amount,
      ledgerLineItem.status,
      ledgerLineItem.paid_by,
      ledgerLineItem.overall_balance
    ])
  ]

  return [...headerData, [], ...ledgerCsvData]
}

export const convertRentalToCsvLedgerData = (rental: FullListRental, addBalance = false) => {
  const csvData = [
    [
      'rental_id',
      'property_id',
      'address',
      'llc_name',
      'tenant',
      'email',
      'phone',
      'date',
      'available_on',
      'category',
      'amount',
      'type',
      'status'
    ]
  ]

  const {
    balance,
    payments = [],
    rental_credits: rentalCredits = [],
    rental_liabilities: rentalLiabilities = []
  } = rental

  const user = rental.rental_users.find((ru) => ru.role === 'primary')?.user

  if (!user) {
    console.error('No primary user found for rental', rental.id)
    return csvData
  }

  if (!payments.length && !rentalLiabilities.length) {
    return csvData
  }

  const address = getAddress(rental)
  const email = getContactInfo('email', user.user_contact_details)
  const phone = getContactInfo('phone', user.user_contact_details)
  const llc = getLlc(rental)
  const llcName = llc?.name
  const propertyId = rental.property?.id

  payments.forEach((payment) => {
    const newCsvData = [
      rental.id,
      propertyId,
      address,
      llcName,
      `${user.first_name} ${user.last_name}`,
      email,
      phone,
      payment.payment_date || moment(payment.created_at).format('YYYY-MM-DD'),
      payment.available_on,
      payment.liability_type.name,
      payment.amount.toFixed(2),
      payment.payment_method === 'rental_credit' ? 'credit' : 'payment',
      payment.status
    ]

    csvData.push(newCsvData)
  })

  rentalLiabilities.forEach((rl) => {
    const newCsvData = [
      rental.id,
      propertyId,
      address,
      llcName,
      `${user.first_name} ${user.last_name}`,
      email,
      phone,
      rl.date,
      '',
      rl.liability_type.name,
      rl.amount.toFixed(2),
      'charge',
      ''
    ]

    csvData.push(newCsvData)
  })

  rentalCredits.forEach((credit) => {
    const newCsvData = [
      rental.id,
      propertyId,
      address,
      llcName,
      `${user.first_name} ${user.last_name}`,
      email,
      phone,
      credit.date,
      '',
      'Rent Credit',
      credit.amount.toFixed(2),
      'credit',
      ''
    ]

    csvData.push(newCsvData)
  })

  if (addBalance) {
    csvData.unshift(['Balance', `${balance}`])
    csvData.unshift(['Date', moment().format('YYYY-MM-DD')])
  }

  return csvData
}

export const convertRentalToCsvBalanceData = (rental: FullListRental) => {
  const csvData = [
    [
      'rental_id',
      'property_id',
      'address',
      'tenant',
      'status',
      'email',
      'phone',
      'llc_name',
      'balance',
      '0-30',
      '30-60',
      '60-90',
      '90+'
    ]
  ]

  const address = getAddress(rental)
  const llc = getLlc(rental)
  const llcName = llc?.name
  const propertyId = rental.property?.id

  const {
    payments = [],
    rental_credits: rentalCredits = [],
    rental_liabilities: rentalLiabilities = []
  } = rental

  const user = rental.rental_users.find((ru) => ru.role === 'primary')?.user

  if (!user) {
    console.error('No primary user found for rental', rental.id)
    return csvData
  }

  if (!payments.length && !rentalLiabilities.length) {
    return csvData
  }

  const zeroDaysAgo = moment().startOf('day')
  const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day')
  const sixtyDaysAgo = moment().subtract(60, 'days').startOf('day')
  const ninetyDaysAgo = moment().subtract(90, 'days').startOf('day')

  let zeroToThirtyBalance = 0
  let thirtyToSixtyBalance = 0
  let sixtyToNinetyBalance = 0
  let ninetyPlusBalance = 0

  payments.forEach(
    ({ amount, category, created_at: createdAt, payment_date: paymentDate, status }) => {
      const date = paymentDate || moment(createdAt).format('YYYY-MM-DD')

      if (
        category !== PAYMENT_CATEGORIES.INITIAL_PAYMENT &&
        [PAYMENT_STATUSES.PROCESSING, PAYMENT_STATUSES.SUCCEEDED].includes(status as any)
      )
        if (ninetyDaysAgo.isAfter(date)) {
          ninetyPlusBalance -= amount
        } else if (sixtyDaysAgo.isAfter(date)) {
          sixtyToNinetyBalance -= amount
        } else if (thirtyDaysAgo.isAfter(date)) {
          thirtyToSixtyBalance -= amount
        } else if (zeroDaysAgo.isAfter(date)) {
          zeroToThirtyBalance -= amount
        }
    }
  )

  rentalCredits.forEach(({ amount, date }) => {
    if (ninetyDaysAgo.isAfter(date)) {
      ninetyPlusBalance -= amount
    } else if (sixtyDaysAgo.isAfter(date)) {
      sixtyToNinetyBalance -= amount
    } else if (thirtyDaysAgo.isAfter(date)) {
      thirtyToSixtyBalance -= amount
    } else if (zeroDaysAgo.isAfter(date)) {
      zeroToThirtyBalance -= amount
    }
  })

  rentalLiabilities.forEach(({ amount, date }) => {
    if (ninetyDaysAgo.isAfter(date)) {
      ninetyPlusBalance += amount
    } else if (sixtyDaysAgo.isAfter(date)) {
      sixtyToNinetyBalance += amount
    } else if (thirtyDaysAgo.isAfter(date)) {
      thirtyToSixtyBalance += amount
    } else if (zeroDaysAgo.isAfter(date)) {
      zeroToThirtyBalance += amount
    }
  })

  csvData.push([
    rental.id,
    propertyId,
    address,
    `${user.first_name} ${user.last_name}`,
    getStatusForLeaseEndDateAndLeaseEndReason(
      rental.move_out_date,
      rental.lease_end_reason || '',
      rental.status
    ),
    getContactInfo('email', user?.user_contact_details) || '',
    getContactInfo('phone', user?.user_contact_details) || '',
    llcName,
    rental.currentBalance.toFixed(2),
    zeroToThirtyBalance.toFixed(2),
    thirtyToSixtyBalance.toFixed(2),
    sixtyToNinetyBalance.toFixed(2),
    ninetyPlusBalance.toFixed(2)
  ])

  return csvData
}

export const getStatusForLeaseEndDateAndLeaseEndReason = (
  leaseEndDate: string,
  leaseEndReason: string,
  defaultStatus: string
) =>
  leaseEndDate && moment(leaseEndDate).isBefore(moment().startOf('day'))
    ? [
        LEASE_END_REASONS.PURCHASED_HOME,
        LEASE_END_REASONS.SWITCHED_HOMES,
        LEASE_END_REASONS.LEFT_PROGRAM,
        LEASE_END_REASONS.DO_NOT_RENEW
      ].some((r) => r === leaseEndReason)
      ? RENTAL_STATUS.COMPLETE
      : RENTAL_STATUS.CANCELED
    : defaultStatus

export const getRentalLeaseSigners = (
  rental: WithRentalAgreementInfoFragment & TenantsFragment
) => {
  const agreementHistoryWithAgreement = rental.rental_agreement_histories.find(
    (rah) => !!rah.agreement
  )

  // TEMP: Quick fix to unblock Ricky and allow him to send out a primary
  // transfer renewal that needs to go out today
  const updatedAgreementSigners = updateSignersFromRentalUsers(
    agreementHistoryWithAgreement?.agreement?.agreement_signers || [],
    rental.rental_users
  )

  return updatedAgreementSigners.sort((a: any, b: any) =>
    a.role === SIGNER_ROLES.PRIMARY_TENANT ? -1 : b.role === SIGNER_ROLES.PRIMARY_TENANT ? 1 : 0
  )
}

// TEMP: Quick fix to unblock Ricky and allow him to send out a primary
// transfer renewal that needs to go out today. Longer term, we should make the distinction
// between rental_users and agreement_signers clearer to avoid this weird "role merge" logic
const updateSignersFromRentalUsers = (
  agreementSigners: NonNullable<
    WithRentalAgreementInfoFragment['rental_agreement_histories'][number]['agreement']
  >['agreement_signers'],
  rentalUsers: TenantsFragment['rental_users']
) => {
  const activeRentalUsers = rentalUsers.filter((ru) => !ru.deactivated_at)

  let currentTenantNumber = 2
  const signers = activeRentalUsers.map((rentalUser) => {
    if (rentalUser.role === RENTAL_USER_ROLES.PRIMARY) {
      return {
        role: SIGNER_ROLES.PRIMARY_TENANT,
        user: { ...rentalUser.user }
      }
    } else if (rentalUser.role === RENTAL_USER_ROLES.TENANT) {
      const signer = {
        role: `${TENANT_PREFIX}${currentTenantNumber}`,
        user: { ...rentalUser.user }
      }
      currentTenantNumber++

      return signer
    } else {
      throw new Error(`Unrecognized rental user role: ${rentalUser.role}`)
    }
  })

  const cosignerSigner = agreementSigners.find((signer) => signer.role === SIGNER_ROLES.COSIGNER)
  if (cosignerSigner) {
    signers.push({
      user: { ...cosignerSigner.user! },
      role: SIGNER_ROLES.COSIGNER
    })
  }

  return signers
}

export const getProgramLeaseTypeForRental = (
  rental:
    | {
        rental_program_type?: Rental_Program_Types_Enum_Enum
      }
    | null
    | undefined
) => {
  if (!rental || !rental.rental_program_type) {
    return
  }

  return RENTAL_PROGRAM_LEASE_TYPES[rental.rental_program_type]
}
