import { Button, FormControl, FormLabel, HStack, Input, Select, VStack } from '@chakra-ui/react'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { MoveOutInfoFragment, useUpup_SetMoveOutDatesMutation } from 'graphql/generated'
import { SmallLoading } from 'ui/Loading'
import { parseDate, formatDate } from 'lib/date-time'
import { getStatusForLeaseEndDateAndLeaseEndReason } from 'lib/rentals'
import React, { useEffect, useState } from 'react'
import { rentals as RENTALS } from '@homevest/utils'
import startCase from 'lodash/startCase'
const { LEASE_END_REASONS } = RENTALS

interface MoveOutProps {
  rental: MoveOutInfoFragment
}

const leaseEndReasonOptions = Object.values(LEASE_END_REASONS).map((reason) => ({
  label: startCase(reason),
  value: reason
}))

const MoveOut: React.FC<React.PropsWithChildren<MoveOutProps>> = ({ rental }) => {
  const { final_liability_date, move_out_date, lease_end_reason, tenant_notice_given_on_date } =
    rental

  const [{ fetching }, updateDates] = useUpup_SetMoveOutDatesMutation()
  const [moveOutDate, setMoveOutDate] = useState<Date | null>(null)
  const [tenantNoticeDate, setTenantNoticeDate] = useState<Date | null>(null)
  const [finalLiabilityDate, setFinalLiabilityDate] = useState<Date | null>(null)
  const [leaseEndReason, setLeaseEndReason] = useState<string | null>()

  const [isEditing, setIsEditing] = useState(false)
  useEffect(() => {
    setMoveOutDate(move_out_date ? parseDate(move_out_date) : null)
    setFinalLiabilityDate(final_liability_date ? parseDate(final_liability_date) : null)
    setTenantNoticeDate(tenant_notice_given_on_date ? parseDate(tenant_notice_given_on_date) : null)
    setLeaseEndReason(lease_end_reason)
  }, [final_liability_date, move_out_date, lease_end_reason, tenant_notice_given_on_date])

  const submitForm = async () => {
    if (
      !moveOutDate ||
      !finalLiabilityDate ||
      !leaseEndReason ||
      (tenant_notice_given_on_date && !tenantNoticeDate)
    ) {
      alert('Please fill out all fields')
      return
    }

    const status = getStatusForLeaseEndDateAndLeaseEndReason(
      formatDate(moveOutDate),
      leaseEndReason,
      rental.status
    )

    await updateDates({
      lease_end_reason: leaseEndReason,
      move_out_date: formatDate(moveOutDate),
      final_liability_date: formatDate(finalLiabilityDate),
      tenant_notice_given_on_date: tenantNoticeDate ? formatDate(tenantNoticeDate) : null,
      status,
      id: rental.id
    })
    setIsEditing(false)
  }

  const handleCancelMoveOut = async () => {
    const result = await updateDates({
      lease_end_reason: null,
      move_out_date: null,
      final_liability_date: null,
      tenant_notice_given_on_date: null,
      status: rental.status,
      id: rental.id
    })
    if (result.data) {
      setMoveOutDate(null)
      setTenantNoticeDate(null)
      setFinalLiabilityDate(null)
      setLeaseEndReason(null)
    }
  }

  const ActionButton = () => {
    if (isEditing) {
      return (
        <HStack>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              setIsEditing(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              submitForm()
            }}
            isDisabled={!moveOutDate || !finalLiabilityDate || !leaseEndReason}
          >
            Save
          </Button>
        </HStack>
      )
    }

    return (
      <HStack>
        {moveOutDate && (
          <Button
            onClick={(e) => {
              e.stopPropagation()
              handleCancelMoveOut()
            }}
          >
            Cancel Move Out
          </Button>
        )}
        <Button
          onClick={(e) => {
            e.stopPropagation()
            setIsEditing(true)
          }}
        >
          {moveOutDate ? 'Update Move Out' : 'Schedule Move Out'}
        </Button>
      </HStack>
    )
  }

  const hasMoveOutBeenScheduled = !!moveOutDate
  const showForm = isEditing || hasMoveOutBeenScheduled
  return (
    <ContentSectionCard title='Move Out' action={ActionButton()}>
      {fetching ? (
        <SmallLoading />
      ) : showForm ? (
        <VStack width='50%' py='4' pl='8'>
          <FormControl isDisabled={!isEditing} isRequired>
            <FormLabel>Notice Given On</FormLabel>
            <Input
              _disabled={{ opacity: 1 }}
              type='date'
              value={tenantNoticeDate?.toISOString().split('T')[0]}
              onChange={(e) => setTenantNoticeDate(parseDate(e.target.value))}
            />
          </FormControl>
          <FormControl isDisabled={!isEditing} isRequired>
            <FormLabel>Move Out Date</FormLabel>
            <Input
              _disabled={{ opacity: 1 }}
              type='date'
              value={moveOutDate?.toISOString().split('T')[0]}
              onChange={(e) => setMoveOutDate(parseDate(e.target.value))}
            />
          </FormControl>
          <FormControl isDisabled={!isEditing} isRequired>
            <FormLabel>Final Liability Date</FormLabel>
            <Input
              _disabled={{ opacity: 1 }}
              type='date'
              value={finalLiabilityDate?.toISOString().split('T')[0]}
              onChange={(e) => setFinalLiabilityDate(parseDate(e.target.value))}
            />
          </FormControl>
          <FormControl isDisabled={!isEditing} isRequired>
            <FormLabel>Lease End Reason</FormLabel>
            <Select
              _disabled={{ opacity: 1 }}
              onChange={(e) => {
                setLeaseEndReason(e.target.value)
              }}
              placeholder='-'
              value={leaseEndReason || ''}
            >
              {leaseEndReasonOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </VStack>
      ) : (
        <div />
      )}
    </ContentSectionCard>
  )
}

export default MoveOut
