// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!

import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import PropTypes from 'prop-types'

import { Button, ErrorText, Input } from 'components/Toolkit'
import axios from 'lib/axios'

EditModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const isBlank = (s: string) => {
  if (!s) {
    return true
  }

  if (s.trim() === '') {
    return true
  }

  return false
}

const isPhone = (s: string) => {
  return /^\+?1\d{10}$/.test(s)
}

const isEmail = (s: string) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    s
  )
}

export default function EditModal({
  close,
  isOpen,
  onSuccess,
  user
}: {
  close: () => void
  isOpen: boolean
  onSuccess: () => void
  user: object
}) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [email, setEmail] = useState(user.email2)
  const [error, setError] = useState('')
  const [firstName, setFirstName] = useState(user.first_name)
  const [lastName, setLastName] = useState(user.last_name)
  const [phone, setPhone] = useState(user.phone2)

  const updateUser = async () => {
    try {
      setIsDisabled(true)
      await axios.put(`/admin/users/${user.id}`, {
        email,
        phone,
        first_name: firstName,
        last_name: lastName
      })

      // Need to refresh page to reload latest user state :/
      await onSuccess()
      return close()
    } catch (err) {
      if (err.isAxios) {
        setError(err.response.data)
      } else {
        setError(err.message)
      }
    } finally {
      setIsDisabled(false)
    }
  }

  return (
    <>
      <Modal size='lg' toggle={close} isOpen={isOpen}>
        <ModalHeader toggle={close}>
          Update {firstName} {lastName}
        </ModalHeader>
        <ModalBody>
          {error && <ErrorText>{error}</ErrorText>}
          <Input
            label='Email'
            type='email'
            onChange={setEmail}
            value={email}
            placeholder='Enter the new email'
            required
            disabled={isDisabled}
            hasError={isBlank(email) || !isEmail(email)}
          />
          <Input
            label='Phone'
            type='tel'
            pattern='^\+?1\d{10}$'
            onChange={setPhone}
            value={phone}
            placeholder='Enter the new phone'
            required
            disabled={isDisabled}
            hasError={isBlank(phone) || !isPhone(phone)}
          />
          <Input
            label='First Name'
            type='string'
            onChange={setFirstName}
            value={firstName}
            placeholder='Enter the first name'
            required
            disabled={isDisabled}
            hasError={isBlank(firstName)}
          />
          <Input
            label='Last Name'
            type='string'
            onChange={setLastName}
            value={lastName}
            placeholder='Enter the last name'
            required
            disabled={isDisabled}
            hasError={isBlank(lastName)}
          />
          <Button
            size='vs'
            disabled={isDisabled}
            isSecondary={false}
            onClick={() => {
              return updateUser()
            }}
          >
            Update {firstName} {lastName}
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}
