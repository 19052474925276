import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Text, Link } from '@chakra-ui/react'

import PendingRenewalsTable from './RenewalsTable'
import DoNotRenewSetPanel from './DoNotRenewSetPanel'
import { PendingRenewalInfoFragment } from 'graphql/generated'

const RenewalsPanelContent: React.FC<
  React.PropsWithChildren<{ rental: PendingRenewalInfoFragment }>
> = ({ rental }) => {
  const renewals = (rental?.rahWithRenewal ?? []).map((rah) => rah.renewals).flat()
  const isDoNotRenewSet = !!rental?.do_not_renew_selected_at

  if (isDoNotRenewSet) {
    return <DoNotRenewSetPanel />
  }

  if (!renewals.length) {
    return (
      <Text as='i'>
        This rental has no pending renewals to show, you can issue a custom renewal here or enter a
        renewal offer in the{'  '}
        <Link as={RouterLink} color='teal' to='/properties/renewal-manager'>
          Renewal Manager.
        </Link>
      </Text>
    )
  }

  return <PendingRenewalsTable renewals={renewals} />
}

export default RenewalsPanelContent
