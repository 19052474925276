import React, { Suspense, useEffect, useState } from 'react'
import axios from 'lib/axios'
import { useLocation } from 'react-router-dom'
import { documents } from '@homevest/utils'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import {
  UpupDocumentRealEstateAcquisitionsQuery,
  UpupDocumentsByResourceQuery,
  useUpupDocumentsByResourceQuery,
  useUpupInsertDocumentMutation
} from '../graphql/generated'
import DocumentsTable from 'components/DocumentManager/DocumentsTable'
import ResourceSelector from 'components/DocumentManager/ResourceSelector'
import ManageModal from 'components/DocumentManager/ManageModal'
import UploadModal from 'components/DocumentManager/UploadModal'
import ResourceInformation from 'components/DocumentManager/ResourceInformation'
import BulkUploadSidepeek from 'components/DocumentManager/BulkUploadSidepeek'
import NoteModal from 'components/DocumentManager/NoteModal'
import { Stack, Button, Box } from '@chakra-ui/react'
import { ACQUISITIONS_DOCUMENT_TYPE_MAP } from 'lib/document-manager'

type Document = UpupDocumentsByResourceQuery['documents'][number]

type RealEstateAcquisition =
  UpupDocumentRealEstateAcquisitionsQuery['real_estate_acquisitions'][number]

const DocumentsManager = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Body />
  </Suspense>
)

const Body = () => {
  const admin = useSelector((state: StoreState) => state.admin)
  const [resourceType, setResourceType] = useState<string>()
  const [resourceId, setResourceId] = useState<string>()
  const [resource, setResource] = useState<RealEstateAcquisition>()
  const [manageDoc, setManageDoc] = useState<Document>()
  const [showNote, setShowNote] = useState<Document>()
  const [showUpload, setShowUpload] = useState(false)
  const [showBulkUpload, setShowBulkUpload] = useState(false)
  const [docUrls, setDocUrls] = useState<{ [id: string]: string }>({})
  const { search } = useLocation()

  useEffect(() => {
    // todo v2: modularize resource, set from dropdown
    setResourceType('real_estate_acquisitions')
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(search)
    const defaultId = params.get('resource_id')
    if (defaultId) {
      setResourceId(defaultId)
    }
  }, [search])

  const [{ data, error }, refetchDocuments] = useUpupDocumentsByResourceQuery({
    variables: {
      resourceId: resource?.id,
      resourceType: resourceType!
    },
    pause: !(resourceType && resource?.id)
  })

  const [_, createDocument] = useUpupInsertDocumentMutation()

  const handleGetUrl = async (document: Document) => {
    if (!docUrls[document.id]) {
      const { data } = await axios.get(`/admin/documents/${document.id}/url`)
      setDocUrls({ ...docUrls, [document.id]: data.url })
      window.open(data.url, '_blank')
    } else {
      window.open(docUrls[document.id], '_blank')
    }
  }

  if (error) {
    return <div>Nope</div>
  }

  return (
    <Stack direction='column' spacing={6} p={4}>
      <Stack direction='row' alignItems='center' className='flex flex-row items-center'>
        <Box flex={1}>
          <ResourceSelector onSelect={setResource} resourceId={resourceId} />
        </Box>
        <Stack direction='column'>
          <Button
            type='button'
            bg='green.200'
            _hover={{ bg: 'green.300' }}
            justifyContent='left'
            onClick={() => setShowUpload(true)}
          >
            + Add new document
          </Button>
          <Button
            type='button'
            bg='green.200'
            _hover={{ bg: 'green.300' }}
            justifyContent='left'
            onClick={() => setShowBulkUpload(true)}
          >
            + Bulk Upload
          </Button>
        </Stack>
      </Stack>
      {Boolean(resource) && <ResourceInformation resource={resource!} />}
      <DocumentsTable
        documents={data?.documents ?? []}
        onManage={setManageDoc}
        onView={handleGetUrl}
        onNote={setShowNote}
      />
      <NoteModal
        isOpen={Boolean(showNote)}
        document={showNote}
        onClose={() => setShowNote(undefined)}
      />
      <ManageModal
        isOpen={Boolean(manageDoc)}
        resource={resource}
        document={manageDoc}
        onUpdate={async () => {
          refetchDocuments({ requestPolicy: 'network-only' })
        }}
        onClose={() => setManageDoc(undefined)}
      />
      <UploadModal
        isOpen={Boolean(showUpload)}
        defaultResource={resource}
        onUpload={async (data) => {
          await createDocument({
            external_id: data.docPath,
            external_source: documents.DOCUMENT_EXTERNAL_SOURCES.GOOGLE_CLOUD,
            friendly_name: data.docName,
            resource_id: data.resourceId,
            resource_type: data.resourceType,
            status: data.docStatus,
            type: data.docType,
            uploaded_by_admin_id: admin.id,
            ...(data.docNote ? { resource_notes: data.docNote } : {})
          })
          if (resourceType && resource?.id) {
            refetchDocuments({ requestPolicy: 'network-only' })
          }
        }}
        onClose={() => setShowUpload(false)}
      />
      <BulkUploadSidepeek
        isOpen={showBulkUpload}
        onClose={() => setShowBulkUpload(false)}
        resourceId={resource?.id}
        resourceType='real_estate_acquisitions'
        typeMap={ACQUISITIONS_DOCUMENT_TYPE_MAP}
      />
    </Stack>
  )
}

export default DocumentsManager
