import React, { useState } from 'react'
import { Input } from 'reactstrap'
import { useMutation } from 'urql'
import moment from 'moment'
import { Button, Tr, Td } from '@chakra-ui/react'

import { Upup_SetRentalAddOnEndDateDocument as SetEndDate } from 'graphql/generated'

export default function AddOnRow({ addOn }: { addOn: any }) {
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [newEndDate, setNewEndDate] = useState('')

  const [, setRentalAddOnEndDate] = useMutation(SetEndDate)

  const saveNewEndDate = async () => {
    setIsSaving(true)

    try {
      await setRentalAddOnEndDate({
        id: addOn.id,
        end_date: moment(newEndDate).format('YYYY-MM-DD')
      })
      setIsEditing(false)
      setNewEndDate('')
    } catch (err) {
      const error = err as any
      window.alert(error.message)
    }

    setIsSaving(false)
  }

  const {
    end_date: endDate,
    liability_type: { name },
    price,
    recurring,
    start_date: startDate
  } = addOn

  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{`$${price}${recurring ? ' / month' : ''}`}</Td>
      <Td>{startDate}</Td>
      <Td>
        {!endDate && isEditing ? (
          <Input
            type='date'
            value={newEndDate || endDate}
            onChange={(e) => setNewEndDate(e.target.value)}
          />
        ) : (
          endDate
        )}
      </Td>
      <Td>
        {!endDate && !isEditing && !isSaving ? (
          <Button
            size='sm'
            colorScheme={'teal'}
            disabled={isSaving || isEditing}
            onClick={() => setIsEditing(true)}
          >
            Update
          </Button>
        ) : null}
        {!endDate && isEditing ? (
          <>
            <Button
              size='sm'
              colorScheme={'teal'}
              disabled={isSaving || !isEditing}
              onClick={() => {
                setIsEditing(false)
                setNewEndDate('')
              }}
            >
              Cancel
            </Button>
            <Button
              size='sm'
              colorScheme={'teal'}
              disabled={isSaving || !isEditing}
              onClick={saveNewEndDate}
            >
              Save
            </Button>
          </>
        ) : null}
      </Td>
    </Tr>
  )
}
