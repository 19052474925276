import React, { FunctionComponent, useState } from 'react'
import { List, Spinner, Stack, Text } from '@chakra-ui/react'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import TimelineEvent from './TimelineEvent'
import SortButton from './SortButton'
import FilterButton from './FilterButton'
import { types } from '@homevest/timelines'
import { Customer } from './types'

type TimelinePropTypes = {
  events?: types.TimelineEvent[]
  homes?: types.HomeDetails[]
  customer?: Customer
  loading: boolean
  eventTypesToDisplay: types.EventTypeString[]
}

const Timeline: FunctionComponent<
  React.PropsWithChildren<TimelinePropTypes & { reverseTimeline: boolean }>
> = ({ events, homes, customer, loading, eventTypesToDisplay, reverseTimeline }) => {
  if (loading) {
    return <Spinner />
  }

  if (!events?.length) {
    return <Text>No events found for entity</Text>
  }

  const filteredTimeline = Array.from(events).filter((e) =>
    eventTypesToDisplay.includes(e.eventType)
  )

  if (reverseTimeline) {
    filteredTimeline.reverse()
  }

  return (
    <List>
      {filteredTimeline.map((e, i) => {
        return (
          <TimelineEvent
            event={e}
            eventIdx={i}
            timelineLength={filteredTimeline.length}
            key={e.eventId}
            homes={homes}
            customer={customer}
          />
        )
      })}
    </List>
  )
}

const TimelineWrapper: FunctionComponent<
  React.PropsWithChildren<TimelinePropTypes & { hideFilter?: boolean }>
> = (props) => {
  const [reverseTimeline, setReverseTimeline] = useState<boolean>(false)
  const [activeFilterEventTypes, setActiveFilterEventTypes] = useState(props.eventTypesToDisplay)

  return (
    <ContentSectionCard
      padding
      collapsable={false}
      title={'Timeline'}
      action={
        <Stack direction='row' gap='2'>
          {props.hideFilter || (
            <FilterButton
              eventTypesToDisplay={props.eventTypesToDisplay}
              activeEventTypes={activeFilterEventTypes}
              setActiveEventTypes={setActiveFilterEventTypes}
            />
          )}
          <SortButton
            reverse={reverseTimeline}
            onClick={() => setReverseTimeline(!reverseTimeline)}
          />
        </Stack>
      }
    >
      <Timeline
        {...props}
        eventTypesToDisplay={activeFilterEventTypes}
        reverseTimeline={reverseTimeline}
      />
    </ContentSectionCard>
  )
}

export default TimelineWrapper
