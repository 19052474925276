import { daysBetween, first, latest } from './helpers'
import { BillDates, Liability, Prorateable } from './utility_chargeback.types'

export const endedLiabilityBeforeBillStart = (
  lastDatePartyIsLiable: Date,
  serviceStartDate: Date
) => {
  return lastDatePartyIsLiable < serviceStartDate
}

export const startedLiabilityAfterBillEnd = (
  firstDatePartyIsLiable: Date,
  serviceEndDate: Date
) => {
  return firstDatePartyIsLiable > serviceEndDate
}

export const removeNonliableParties = ({
  potentiallyLiableParties,
  bill: { serviceStart, serviceEnd }
}: {
  potentiallyLiableParties: Prorateable[]
  bill: { serviceStart: Date; serviceEnd: Date }
}) => {
  return potentiallyLiableParties.filter((liability) => {
    if (endedLiabilityBeforeBillStart(liability.endDate, serviceStart)) {
      return false
    }
    if (startedLiabilityAfterBillEnd(liability.startDate, serviceEnd)) {
      return false
    }
    return true
  })
}

export const getLiabilityForEntity = ({
  serviceStart,
  serviceEnd,
  entityLiabilityStart: liabilityStart,
  entityLiabilityEnd: liabilityEnd
}: {
  serviceStart: Date
  serviceEnd: Date
  entityLiabilityStart: Date
  entityLiabilityEnd: Date
}) => {
  // first of both bill and entity liability
  // const firstDate: Date = first([serviceStart, liabilityStart]);
  // const finalDate = latest([serviceEnd, liabilityEnd]);
  // Chop off part of ownership before billing period
  const correctedStart = latest([serviceStart, liabilityStart])
  // Chop off part of ownership after billing period
  const correctedEnd = first([serviceEnd, liabilityEnd])

  // const serviceBeforeOwnership = daysBetween(serviceStart, liabilityStart);
  // const concurrent = daysBetween(correctedStart, correctedEnd);
  // const ownershipAfterService = daysBetween(serviceEnd, liabilityEnd);
  // print({
  //   liabilityStart,
  //   serviceStart,
  //   correctedStart,

  //   liabilityEnd,
  //   serviceEnd,
  //   correctedEnd,

  //   span: daysBetween(firstDate, finalDate),
  //   serviceDays: daysBetween(serviceStart, serviceEnd),
  //   ownershipDays: daysBetween(liabilityStart, liabilityEnd),
  //   daysInBillingPeriod: {
  //     serviceBeforeOwnership,
  //     concurrent,
  //     ownershipAfterService,
  //   },
  // });
  // print(
  //   'sum of before, during, after',
  //   serviceBeforeOwnership + concurrent + ownershipAfterService,
  //   '\n\n',
  // );

  return { start: correctedStart, end: correctedEnd }
}

export const correctLiabilityTimes = ({
  liableParties,
  bill
}: {
  liableParties: Prorateable[]
  bill: BillDates
}) => {
  const { serviceStart, serviceEnd } = bill
  return liableParties.map(({ startDate, endDate, id, id_origin }) => {
    const { start, end } = getLiabilityForEntity({
      serviceStart,
      serviceEnd,
      entityLiabilityStart: startDate,
      entityLiabilityEnd: endDate
    })
    return {
      id,
      id_origin,
      startDate: start,
      endDate: end
    } as Prorateable
  })
}

export const calculateCostPerParty = ({
  arr,
  costPerDay,
  liabilityBase
}: {
  arr: Prorateable[]
  costPerDay: number
  liabilityBase: Liability
}) => {
  return arr.map((liableParty) => {
    const daysLiable = daysBetween(liableParty.startDate, liableParty.endDate)

    return {
      ...liabilityBase,
      ...liableParty,
      daysLiable,
      amountDueInDollars: daysLiable * costPerDay
    } as Liability
  })
}
