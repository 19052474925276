import React, { FunctionComponent } from 'react'
import AcquisitionCard from '../Cards/AcquisitionDetails'
import Entity from '../Cards/EntityDetails'
import Marketing from '../Cards/MarketingDetails'
import RentalHistory from '../Cards/RentalHistory'
import ListingPhotos from '../Cards/ListingPhotos'
import Notes from '../Cards/Notes'

interface OverviewPanelProps {
  portfolioHomeId: string
  acquisitionId: string
}
export const OverviewPanel: FunctionComponent<React.PropsWithChildren<OverviewPanelProps>> = ({
  portfolioHomeId,
  acquisitionId
}) => (
  <>
    <Notes portfolioHomeId={portfolioHomeId} />
    <RentalHistory portfolioHomeId={portfolioHomeId} />
    <Marketing portfolioHomeId={portfolioHomeId} />
    <AcquisitionCard portfolioHomeId={portfolioHomeId} acquisitionId={acquisitionId} />
    <Entity portfolioHomeId={portfolioHomeId} />
    <ListingPhotos portfolioHomeId={portfolioHomeId} />
  </>
)
