import React, { FunctionComponent, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import axios from 'lib/axios'
import Impersonate from './Impersonate'
import DeleteUser from './DeleteUser'
import ChargeUser from './ChargeUser'
import CreateAccount from './CreateAccount'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import ResetMicrodeposit from './ResetMicrodeposit'
import ArchiveUser from './ArchiveUser'

const DropDown: FunctionComponent<
  React.PropsWithChildren<{ isArchived: boolean; reexecuteQuery: () => void }>
> = ({ isArchived, reexecuteQuery }) => {
  const { userId } = useParams<{ userId: string }>()
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [hasFirebaseAccount, setHasFirebaseAccount] = useState(false)

  const checkFirebaseAccount = async () => {
    const result = await axios.get(`/admin/users/${userId}/check_firebase_account`)
    const hasAccount = result.data.has_firebase_account
    setHasFirebaseAccount(hasAccount)
  }

  useEffect(() => {
    checkFirebaseAccount()
  }, [userId])

  return (
    <>
      <SC.DropDownContainer>
        <SC.DropDownIcon
          onClick={() => {
            setIsDropDownOpen(!isDropDownOpen)
          }}
        />
        <SC.DropDownMenu isOpen={isDropDownOpen}>
          <ChargeUser />
          <ResetMicrodeposit />
          {hasFirebaseAccount ? (
            <Impersonate />
          ) : (
            <CreateAccount refreshFirebaseAccount={checkFirebaseAccount} />
          )}
          {/* <div>Merge</div> */}
          {!isArchived && <ArchiveUser reexecuteQuery={reexecuteQuery} />}
          <DeleteUser />
        </SC.DropDownMenu>
      </SC.DropDownContainer>
    </>
  )
}

export default DropDown
