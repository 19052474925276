import React, { useState } from 'react'
import {
  Tr,
  Td,
  IconButton,
  Input,
  HStack,
  Spinner,
  FormControl,
  FormErrorMessage,
  InputProps,
  Center
} from '@chakra-ui/react'
import { PencilIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'

type EmploymentVerificationItemProps = {
  icon: React.ReactNode
  value: string | null | undefined
  formatDisplayValue?: (value: string | null | undefined) => string
  formatInputValue?: (value: string | null | undefined) => string
  validate?: (value: string | null | undefined) => string
  isLoading: boolean
  onSubmit: (value: string | null | undefined) => Promise<void>
  inputProps?: InputProps
}

const EmploymentVerificationItem = ({
  icon,
  value,
  isLoading,
  onSubmit,
  formatDisplayValue,
  formatInputValue,
  validate,
  inputProps
}: EmploymentVerificationItemProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedValue, setEditedValue] = useState(formatInputValue ? formatInputValue(value) : value)

  const formattedValue = formatDisplayValue ? formatDisplayValue(value) : value
  const errorMessage = validate ? validate(editedValue) : ''

  const handleSubmit = async () => {
    if (errorMessage) {
      return
    }
    await onSubmit(editedValue)
    setIsEditing(false)
  }

  if (isLoading) {
    return (
      <Tr>
        <Td paddingY={2}>{icon}</Td>
        <Td paddingY={2}>
          <Center>
            <Spinner size='sm' />
          </Center>
        </Td>
        <Td paddingY={2}></Td>
      </Tr>
    )
  }

  if (!isEditing) {
    return (
      <Tr>
        <Td paddingY={0}>{icon}</Td>
        <Td paddingY={0}>{formattedValue || '-'}</Td>
        <Td paddingY={0}>
          <IconButton
            aria-label='Edit'
            icon={<PencilIcon className={'h-4 w-4'} />}
            colorScheme='blackAlpha'
            variant='ghost'
            onClick={() => setIsEditing(true)}
          />
        </Td>
      </Tr>
    )
  }

  return (
    <Tr>
      <Td paddingY={1}>{icon}</Td>
      <Td paddingY={1}>
        <FormControl isInvalid={!!errorMessage}>
          <Input
            size='sm'
            backgroundColor={'gray.50'}
            borderColor={'gray.300'}
            value={editedValue ?? ''}
            onChange={(e) =>
              setEditedValue(
                formatInputValue ? formatInputValue(e.target.value || null) : e.target.value || null
              )
            }
            {...(inputProps || {})}
          />
          <FormErrorMessage> {errorMessage}</FormErrorMessage>
        </FormControl>
      </Td>
      <Td paddingY={1}>
        <HStack>
          <IconButton
            aria-label='Save'
            colorScheme={'teal'}
            variant='outline'
            icon={<CheckIcon className={'h-4 w-4'} />}
            onClick={handleSubmit}
          />
          <IconButton
            aria-label='Cancel'
            colorScheme={'red'}
            variant='outline'
            icon={<XMarkIcon className={'h-4 w-4'} />}
            onClick={() => setIsEditing(false)}
          />
        </HStack>
      </Td>
    </Tr>
  )
}

export default EmploymentVerificationItem
