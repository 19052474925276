import axios from 'lib/axios'

export const SET_REEXECUTE_HEADER = 'SET_REEXECUTE_HEADER'
export const SET_REEXECUTE_PERSONAL_DETAILS = 'SET_REEXECUTE_PERSONAL_DETAILS'

export const setReexecuteHeader = (doReexecute) => ({
  type: SET_REEXECUTE_HEADER,
  doReexecute
})

export const setReexecutePersonalDetails = (doReexecute) => ({
  type: SET_REEXECUTE_PERSONAL_DETAILS,
  doReexecute
})
