import React from 'react'
import { startCase } from 'lodash'
import { TableContainer, Table, Thead, Tr, Th, Td, Tbody } from '@chakra-ui/react'
import { OccupantWithId } from '@homevest/types/rental-applications'

const OccupantsDisplay: React.FC<React.PropsWithChildren<{ occupants: OccupantWithId[] }>> = ({
  occupants
}) => {
  return (
    <TableContainer>
      <Table variant='striped' size='sm'>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Age</Th>
            <Th>Relation</Th>
          </Tr>
        </Thead>
        <Tbody>
          {occupants.map((occupant) => (
            <Tr key={occupant.id}>
              <Td>{occupant.name}</Td>
              <Td>{occupant.age}</Td>
              <Td>{startCase(occupant.relation)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default OccupantsDisplay
