import React, { Suspense } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import BackfillForm from './Form'
import { Loading } from 'ui'

export default function BackfillModal({
  close,
  isOpen,
  rentalId
}: {
  close: () => void
  isOpen: boolean
  rentalId: string
}) {
  return (
    <Modal size='6xl' isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manual Payment Backfill</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Suspense fallback={<Loading />}>
            <BackfillForm rentalId={rentalId} close={close} />
          </Suspense>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blackAlpha' mr={3} onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
