import React from 'react'
import { get } from 'lodash'
import { Divider, Text } from '@chakra-ui/react'
import { BodyText, Label } from 'components/Toolkit'
import ReportLink from '../ReportLink'

export default function EvictionReportDetails({ evictionReport }) {
  const evictionReportDocumentId = get(evictionReport, 'document.id', null)

  if (!evictionReport) {
    return (
      <Text marginTop='0.5' fontWeight={'light'}>
        No report run
      </Text>
    )
  }

  if (evictionReportDocumentId) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <ReportLink
          documentId={evictionReportDocumentId}
          reportName='Eviction Report'
          style={{ flex: 1 }}
        />
      </div>
    )
  }

  const matches = get(evictionReport, 'matches', null)

  if (!matches || !matches.length) {
    return (
      <Text marginTop='0.5' fontWeight={'light'}>
        No matches found ✅
      </Text>
    )
  }

  return matches.map((match, idx) => {
    const {
      activity: { filingDate, judgement, judgementAmount, judgementDate, noticeType }
    } = match

    return (
      <>
        <div key={idx} className='flex flex-wrap justify-between'>
          <div style={{ flex: '1 0 33%' }}>
            <Text fontSize='xs'>Judgement Date</Text>
            <Text marginTop={0}>{judgementDate || 'Not reported'}</Text>
          </div>
          <div style={{ flex: '1 0 33%' }}>
            <Text fontSize='xs'>Judgement</Text>
            <Text marginTop={0}>{judgement || 'Not reported'}</Text>
          </div>
          <div style={{ flex: '1 0 33%' }}>
            <Text fontSize='xs'>Judgement Amount</Text>
            <Text marginTop={0}>{judgementAmount || 'Not reported'}</Text>
          </div>
          <div style={{ flex: '1 0 33%' }}>
            <Text fontSize='xs'>Notice Type</Text>
            <Text marginTop={0}>{noticeType || 'Not reported'}</Text>
          </div>
          <div style={{ flex: '1 0 33%' }}>
            <Text fontSize='xs'>Filing Date</Text>
            <Text marginTop={0}>{filingDate || 'Not reported'}</Text>
          </div>
          <div style={{ flex: '1 0 33%' }} />
        </div>
        {idx !== matches.length - 1 && <Divider marginY={'1'} />}
      </>
    )
  })
}
