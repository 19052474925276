import axios from 'lib/axios'
import Cookies from 'universal-cookie'
import { webUrl, cookieOptions } from 'lib/config'

export const impersonateAndOpenWeb = async (userId: string, adminId: string) => {
  const { data: token } = await axios.get(`/admin/users/${userId}/sudo_token`)
  const cookies = new Cookies()

  // Set this cookie for a short time.
  // In production, security and domain are additionally set.
  cookies.set('hudsonCustomToken', token, {
    maxAge: 5,
    path: '/',
    ...cookieOptions
  })

  // Session cookies to track who is doing what for future web work.
  cookies.set('impersonatingAdminId', adminId, {
    expires: undefined,
    path: '/',
    ...cookieOptions
  })

  cookies.set('isImpersonating', true, {
    expires: undefined,
    path: '/',
    ...cookieOptions
  })

  const url = new URL(webUrl)
  const host = url.host
  const prefix = host.includes('localhost') ? '' : 'https://'
  const impersonateActionUrl = prefix + host + '/actions?mode=impersonate&oobCode=' + token
  console.log('impersonateActionUrl', impersonateActionUrl)

  window.open(impersonateActionUrl, '_blank')
}
