import React, { FunctionComponent, useState } from 'react'
import { Spinner } from 'reactstrap'
import { documents } from '@homevest/utils'
import { startCase } from 'lodash'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { useDocumentResourceIdsByPropertyIdQuery } from 'graphql/generated'
import Grid from 'components/TailwindUIToolkit/Grid/DocumentsGrid'
import { recursiveObjectKeySearch } from 'lib/utils'
import { ActionButton } from 'components/TailwindUIToolkit'
import { Sidepeek } from 'ui'
import { UploadDocumentPanel } from 'components/Documents/UploadDocumentPanel'

const { DOCUMENT_TYPES } = documents

type DocumentsPropTypes = {
  portfolioHomeId: string
}

export const Documents: FunctionComponent<React.PropsWithChildren<DocumentsPropTypes>> = ({
  portfolioHomeId
}) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  const [{ data }] = useDocumentResourceIdsByPropertyIdQuery({
    variables: { portfolioHomeId }
  })

  const ids = data && recursiveObjectKeySearch(data, 'id')

  const documentTypes = Object.values(DOCUMENT_TYPES).reduce(
    (acc: Record<string, string>, docType) => {
      acc[docType] = startCase(docType)

      return acc
    },
    {}
  )

  const uploadButton = (
    <ActionButton onClick={() => setDrawerOpen(true)}>
      + Upload
      <Sidepeek isOpen={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <UploadDocumentPanel
          resourceId={portfolioHomeId}
          resourceType='portfolio_home'
          typeMap={documentTypes}
        />
      </Sidepeek>
    </ActionButton>
  )

  return (
    <ContentSectionCard title='Documents' staticSize={true} action={uploadButton}>
      <React.Suspense fallback={<Spinner />}>
        <Grid resourceIds={ids || []} />
      </React.Suspense>
    </ContentSectionCard>
  )
}
