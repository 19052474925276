import { SET_AGENTS } from './actions'

export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_AGENTS:
      return action.payload
    default:
      return state
  }
}
