import React, { FunctionComponent, useState } from 'react'
import { ImageCarouselModal } from './ImageCarouselModal'

export const ImageGridList: FunctionComponent<React.PropsWithChildren<{ files: string[] }>> = ({
  files
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [pictureToOpen, setPictureToOpen] = useState<number>(0)

  return (
    <>
      <ul className='m-3 grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4'>
        {files.map((file, idx) => (
          <li key={file} className='relative' id={`img-${idx}`}>
            <div
              onClick={() => {
                setModalOpen(true)
                setPictureToOpen(idx)
              }}
              className='group block w-full cursor-pointer overflow-hidden rounded-lg bg-gray-100'
            >
              <img
                className='pointer-events-none aspect-square object-cover group-hover:opacity-75'
                src={file}
                alt={file}
              />
            </div>
          </li>
        ))}
      </ul>
      <ImageCarouselModal
        open={modalOpen}
        files={files}
        setClosed={() => setModalOpen(false)}
        firstImage={pictureToOpen}
      />
    </>
  )
}
