import React, { FunctionComponent } from 'react'
import { Box, Button, Menu, MenuButton } from '@chakra-ui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const TabMenu: FunctionComponent<React.PropsWithChildren<{ label: string; active?: boolean }>> = ({
  label,
  active,
  children
}) => (
  <Menu>
    <MenuButton
      as={Button}
      variant='ghost'
      px={2}
      h='full'
      rounded='none'
      borderBottomWidth={2}
      borderBottomColor={active ? 'brand.600' : 'transparent'}
      fontSize='sm'
      color={active ? 'grey.900' : 'gray.500'}
      rightIcon={
        <Box h={4} w={4}>
          <ChevronDownIcon />
        </Box>
      }
    >
      {label}
    </MenuButton>
    {children}
  </Menu>
)

export default TabMenu
