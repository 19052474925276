import styled from 'styled-components'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'
import first from 'lodash/first'
import { getBathsString } from '../../lib/property'
import { formatMoney } from '../../lib/numbers'
import MLSAgentDetails from './MLSAgentDetail'

const Group = styled.div`
  background-color: #ececec;
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  border-radius: 10px;
  margin-bottom: 15px;
`

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
`

const KVRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`

const Value = styled.span`
  font-size: 16px;
`

const Key = styled(Value)`
  font-weight: bold;
  margin-right: 5px;
`
export default class PropertyInsights extends Component {
  static propTypes = {
    mlsListing: PropTypes.object.isRequired,
    sellerAgents: PropTypes.array
  }

  render() {
    const { mlsListing } = this.props
    const internalAttributes = first(mlsListing.internal_attributes) || {}
    const listedRent = mlsListing.opportunity?.current_rent[0]?.listed_rent
    const isListed = ['internally_listed', 'listed'].includes(internalAttributes.internal_status)

    return (
      <>
        <Group>
          <Header>Property Info</Header>
          <KVRow>
            <Key>Up&Up Url:</Key>
          </KVRow>
          <KVRow>
            <Value>
              <a href={internalAttributes.url} rel='noopener noreferrer' target='_blank'>
                {internalAttributes.url}
              </a>
            </Value>
          </KVRow>
          <KVRow>
            <Key>Manage Photos:</Key>
          </KVRow>
          <KVRow>
            <Value>
              <a
                href={`/properties/mls-photo-manager/${mlsListing.id}`}
                rel='noopener noreferrer'
                target='_blank'
              >
                /properties/mls-photo-manager/${mlsListing.id}
              </a>
            </Value>
          </KVRow>
          {isListed && (
            <>
              <KVRow>
                <Key>Application Link:</Key>
              </KVRow>
              <KVRow>
                <Value>
                  <a
                    href={`https://app.upandup.co/user/apply/${mlsListing.id}`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    {`https://app.upandup.co/user/apply/${mlsListing.id}`}
                  </a>
                </Value>
              </KVRow>
            </>
          )}
          <KVRow>
            <Key>Source:</Key>
            <Value>
              {mlsListing.provider === 'homejunction' ? mlsListing.mls : mlsListing.provider}
            </Value>
          </KVRow>
          <KVRow>
            <Key>MLS Status:</Key>
            <Value>{mlsListing.status}</Value>
          </KVRow>
          <KVRow>
            <Key>Up&Up Status:</Key>
            <Value>{internalAttributes.internal_status}</Value>
          </KVRow>
          <KVRow>
            <Key>Should call agent:</Key>
            <Value>
              {Boolean(
                mlsListing.status === 'Active' &&
                  internalAttributes.internal_status !== 'reviewer_rejected'
              ).toString()}
            </Value>
          </KVRow>
          <KVRow>
            <Key>In Real Estate Buy Box:</Key>
            <Value>{Boolean(internalAttributes.is_in_real_estate_buy_box).toString()}</Value>
          </KVRow>
          <KVRow>
            <Key>In Customer Buy Box:</Key>
            <Value>{Boolean(internalAttributes.is_in_customer_buy_box).toString()}</Value>
          </KVRow>
          <KVRow>
            <Key>Rent Percent Over Comp:</Key>
            <Value>
              {typeof internalAttributes.percent_over_comp === 'number'
                ? `${formatMoney(internalAttributes.percent_over_comp * 100)}%`
                : 'N/A'}
            </Value>
          </KVRow>
          <KVRow>
            <Key>Price:</Key>
            <Value>${formatMoney(mlsListing.list_price, 0)}</Value>
          </KVRow>
          <KVRow>
            <Key>Listed Rent:</Key>
            <Value>{listedRent ? `$${formatMoney(listedRent, 0)}` : 'N/A'}</Value>
          </KVRow>
          <KVRow>
            <Key>Disclosures:</Key>
            <Value>{(mlsListing.xf_attributes.xf_disc || []).join(', ')}</Value>
          </KVRow>
          <KVRow>
            <Key>Agent Remarks:</Key>
            <Value>{mlsListing.xf_attributes.xf_agent_only_remarks}</Value>
          </KVRow>
          <KVRow>
            <Key>Beds:</Key>
            <Value>{mlsListing.beds}</Value>
          </KVRow>
          <KVRow>
            <Key>Baths:</Key>
            <Value>{getBathsString(mlsListing)}</Value>
          </KVRow>
          <KVRow>
            <Key>Square feet:</Key>
            <Value>{Number(mlsListing.size).toLocaleString()}</Value>
          </KVRow>
          <KVRow>
            <Key>Lot square feet:</Key>
            <Value>
              {mlsListing.lotsize_sqft
                ? Number(mlsListing.lotsize_sqft).toLocaleString()
                : 'Unknown'}
            </Value>
          </KVRow>
          <KVRow>
            <Key>Area:</Key>
            <Value>{mlsListing.area}</Value>
          </KVRow>
          <KVRow>
            <Key>Municipality:</Key>
            <Value>{mlsListing.xf_attributes.xf_municipalitytownship}</Value>
          </KVRow>
          <KVRow>
            <Key>County:</Key>
            <Value>{mlsListing.county}</Value>
          </KVRow>
          <KVRow>
            <Key>Listing date:</Key>
            <Value>{moment(new Date(mlsListing.listing_date)).format('YYYY-MM-DD')}</Value>
          </KVRow>
          <KVRow>
            <Key>Days on market:</Key>
            <Value>{moment().diff(new Date(mlsListing.listing_date), 'd')}</Value>
          </KVRow>
          <KVRow>
            <Key>Year built:</Key>
            <Value>{mlsListing.year_built}</Value>
          </KVRow>
          <KVRow>
            <Key>Property type:</Key>
            <Value>{mlsListing.property_type}</Value>
          </KVRow>
          <MLSAgentDetails mlsListing={mlsListing} />
        </Group>
      </>
    )
  }
}
