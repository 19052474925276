import { UPDATE_DOCUMENTS_MODAL_ATTRIBUTES } from './actions'

export default function reducer(
  state = {
    isOpen: false,
    documentsApproved: false,
    documents: [],
    showDocument: new Set(),
    approvedDocuments: new Set(),
    missingReasons: [],
    showMissingReason: new Set(),
    approvedMissingReasons: new Set()
  },
  action
) {
  switch (action.type) {
    case UPDATE_DOCUMENTS_MODAL_ATTRIBUTES: {
      const newState = {
        ...state,
        ...action.update
      }

      // If everything has been approved, update state and close the modal
      if (
        newState.approvedDocuments.size === newState.documents.length &&
        newState.approvedMissingReasons.size === newState.missingReasons.length
      ) {
        newState.documentsApproved = true
        newState.isOpen = false
      }

      return newState
    }
    default:
      return state
  }
}
