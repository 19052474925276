import React from 'react'
import { userIncomeSources, documents } from '@homevest/utils'

import { useDocuments } from '../hooks/documents'
import { useSourceDetails } from '../context'
import DocumentUploadWithAmount from '../common/utils/DocumentUploadWithAmount'
import { Button, Heading } from '@chakra-ui/react'

const { REQUIRED_PAY_STUBS_BY_PAY_FREQUENCY, USER_INCOME_SOURCE_TYPES: SOURCE_TYPES } =
  userIncomeSources
const { DOCUMENT_TYPES } = documents

const PayStubUploadSection = () => {
  const context = useSourceDetails()
  const {
    documents,
    addDocumentAtIndex,
    removeDocumentAtIndex,
    updateAmountAtIndex,
    clearDocumentAtIndex,
    getErrorAtIndex
  } = useDocuments('pay_stubs', DOCUMENT_TYPES.PAY_STUB)

  if (!context || context.data.type !== SOURCE_TYPES.EMPLOYED || context.data.is_newly_employed) {
    return null
  }

  const { data } = context

  const payFrequency = data.pay_frequency
  const requiredDocumentCount = payFrequency ? REQUIRED_PAY_STUBS_BY_PAY_FREQUENCY[payFrequency] : 1

  const additionalDocumentIndices = data.pay_stubs
    ? Object.keys(data.pay_stubs)
        .map(Number)
        .filter((index) => index >= requiredDocumentCount)
    : []

  const maxDocumentIndex = Math.max(...additionalDocumentIndices, requiredDocumentCount - 1)

  return (
    <div className='flex flex-col gap-5'>
      <Heading as='h3' size='sm'>
        Upload Pay Stubs
      </Heading>
      {[...Array(requiredDocumentCount)].map((_, index) => (
        <DocumentUploadWithAmount
          key={index}
          uploadPrefix={DOCUMENT_TYPES.PAY_STUB}
          title={`Pay Stub #${index + 1} (Required)`}
          inputLabel='Gross Pay from this Paystub'
          amount={documents?.[index]?.amount || ''}
          setAmount={(value) => updateAmountAtIndex(index, value)}
          amountError={getErrorAtIndex(index, 'amount')}
          fileError={getErrorAtIndex(index, 'file')}
          onClear={() => clearDocumentAtIndex(index)}
          onUpload={({ uploadPath }) => addDocumentAtIndex(index, { upload_path: uploadPath })}
        />
      ))}

      {additionalDocumentIndices.map((docIndex, index) => (
        <DocumentUploadWithAmount
          key={index}
          uploadPrefix={DOCUMENT_TYPES.PAY_STUB}
          title={`Additional Pay Stub #${index + 1}`}
          inputLabel='Gross Pay from this Paystub'
          amount={documents?.[docIndex]?.amount || ''}
          setAmount={(value) => updateAmountAtIndex(docIndex, value)}
          amountError={getErrorAtIndex(docIndex, 'amount')}
          fileError={getErrorAtIndex(docIndex, 'file')}
          onClear={() => clearDocumentAtIndex(docIndex)}
          onUpload={({ uploadPath }) => addDocumentAtIndex(docIndex, { upload_path: uploadPath })}
          isOptional
          onRemove={() => removeDocumentAtIndex(docIndex)}
        />
      ))}
      <Button onClick={() => addDocumentAtIndex(maxDocumentIndex + 1, {})}>
        Add Another Pay Stub
      </Button>
    </div>
  )
}

export default PayStubUploadSection
