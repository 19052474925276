import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  type MenuProps,
  type ButtonProps
} from '@chakra-ui/react'

export const ConfirmButton: React.FC<
  React.PropsWithChildren<
    {
      confirmText?: string
      confirmPlacement?: MenuProps['placement']
      confirmButtonProps?: ButtonProps
    } & ButtonProps
  >
> = ({
  children,
  confirmPlacement = 'auto',
  confirmText = 'Confirm',
  confirmButtonProps,
  onClick,
  colorScheme,
  ...menuButtonProps
}) => {
  return (
    <Menu matchWidth placement={confirmPlacement}>
      <MenuButton as={Button} {...{ ...menuButtonProps, colorScheme }}>
        {children}
      </MenuButton>
      <MenuList>
        <MenuItem>
          <Button w='full' variant='outline' {...{ ...confirmButtonProps, onClick, colorScheme }}>
            {confirmText}
          </Button>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
