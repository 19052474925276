import React, { useEffect, useState } from 'react'
import { dates } from '@homevest/utils'
import { useMutation } from '@tanstack/react-query'
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  Spacer,
  Table,
  Tr,
  Thead,
  Td,
  Th,
  Tbody,
  Divider,
  IconButton
} from '@chakra-ui/react'
import { addDays } from 'date-fns'
import {
  CreatePaymentPlanForUtilityChargebacksPayload,
  PaymentPlanDryRun,
  UnfinalizedChargeback
} from '@homevest/types/utilities'
import axios from 'lib/axios'
import { AxiosResponse } from 'axios'
import { startCase } from 'lodash'
import { formatMoney } from 'lib/numbers'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { ConfirmButton } from 'ui/ConfirmButton'

const { formatISODate } = dates

type CreatePaymentPlanFormProps = {
  chargebackDetails: UnfinalizedChargeback
  onSuccess: () => Promise<unknown>
  allChargebacks: UnfinalizedChargeback[]
}
export const CreatePaymentPlanForm: React.FC<
  React.PropsWithChildren<CreatePaymentPlanFormProps>
> = ({ chargebackDetails, onSuccess, allChargebacks }) => {
  const [startDate, setStartDate] = useState<string>(formatISODate(new Date()))
  const [numberOfInstallments, setNumberOfInstallments] = useState<number>(4)
  const [note, setNote] = useState<string>()
  const [previewPaymentPlanData, setPreviewPaymentPlanData] = useState<PaymentPlanDryRun | null>(
    null
  )
  const [additionalChargebackIdsForPP, setAdditionalChargebackIdsForPP] = useState<Set<string>>(
    new Set()
  )
  const [isAddingChargebacks, setIsAddingChargebacks] = useState(false)

  useEffect(() => {
    setPreviewPaymentPlanData(null)
  }, [startDate, numberOfInstallments, additionalChargebackIdsForPP])

  const { mutate: createPaymentPlan, isLoading } = useMutation<
    AxiosResponse,
    unknown,
    {
      chargebackIds: string[]
      startDate: Date
      numberOfInstallments: number
      note?: string
      dry?: boolean
    }
  >({
    mutationFn: async ({ chargebackIds, numberOfInstallments, startDate, note, dry }) => {
      return await axios.post(
        '/admin/utility_chargebacks/payment_plan' + (dry ? '?dry=true' : ''),
        {
          utility_chargeback_ids: chargebackIds,
          start_date: startDate.valueOf(),
          number_of_installments: numberOfInstallments,
          note
        } as CreatePaymentPlanForUtilityChargebacksPayload
      )
    },
    onSuccess: async (result, { dry }) => {
      if (dry) {
        setPreviewPaymentPlanData((result as AxiosResponse<PaymentPlanDryRun>).data)
      } else {
        await onSuccess()
      }
    }
  })

  return (
    <Stack>
      <Heading size='md'>Chargeback Bill Details</Heading>
      <Stack p={4} bgColor='gray.200' rounded='md'>
        <BillDetails chargebackDetails={chargebackDetails} />
      </Stack>
      <Spacer />
      <Heading size='md'>Payment Plan Settings</Heading>
      {[
        {
          label: 'Start Date',
          element: (
            <Input
              type='date'
              value={startDate}
              onChange={(e) => setStartDate(e.currentTarget.value)}
            />
          )
        },
        {
          label: 'Number of Installments',
          element: (
            <Input
              type='number'
              step='1.0'
              value={numberOfInstallments}
              onChange={(e) => setNumberOfInstallments(Number(e.target.value))}
            />
          )
        },
        {
          label: 'Note?',
          element: <Input type='text' value={note} onChange={(e) => setNote(e.target.value)} />
        },
        {
          label: 'Additional Chargebacks',
          element: (
            <Stack gap={2} border='1px' borderColor='gray.200' rounded='md' p={2}>
              {Array.from(additionalChargebackIdsForPP).map((id) => {
                const chargeback = allChargebacks.find((chb) => chb.id === id)
                if (!chargeback) return null
                return (
                  <HStack gap={2} justifyContent='space-between'>
                    <Text>{chargebackSummary(chargeback)}</Text>
                    <IconButton
                      icon={<XMarkIcon height={24} />}
                      aria-label='delete'
                      size='xm'
                      colorScheme='red'
                      variant='outline'
                      onClick={() =>
                        setAdditionalChargebackIdsForPP((prev) => {
                          prev.delete(chargeback.id)
                          return new Set(prev)
                        })
                      }
                    />
                  </HStack>
                )
              })}
            </Stack>
          ),
          hide: additionalChargebackIdsForPP.size === 0
        }
      ].map(({ label, element, hide }) =>
        hide ? null : (
          <FormControl key={label} isDisabled={isLoading}>
            <FormLabel>{label}</FormLabel>
            {element}
          </FormControl>
        )
      )}
      {isAddingChargebacks && (
        <AddChargebacks
          selectedChargebackIds={new Set(additionalChargebackIdsForPP)}
          chargebacks={allChargebacks.filter(
            (chb) =>
              chb.rental.id === chargebackDetails.rental.id && chb.id !== chargebackDetails.id
          )}
          addChargeback={(chargebackId) =>
            setAdditionalChargebackIdsForPP((prev) => new Set(prev).add(chargebackId))
          }
          removeChargeback={(chargebackId) =>
            setAdditionalChargebackIdsForPP((prev) => {
              prev.delete(chargebackId)
              return new Set(prev)
            })
          }
        />
      )}
      <Button
        colorScheme='orange'
        variant={isAddingChargebacks ? 'solid' : 'outline'}
        onClick={() => setIsAddingChargebacks((prev) => !prev)}
      >
        Add Chargebacks
      </Button>
      {previewPaymentPlanData && <PaymentPlanPreview previewData={previewPaymentPlanData} />}
      <Button
        colorScheme='yellow'
        variant={previewPaymentPlanData ? 'solid' : 'outline'}
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={() => {
          if (previewPaymentPlanData) {
            setPreviewPaymentPlanData(null)
            return
          }

          createPaymentPlan({
            chargebackIds: [chargebackDetails.id, ...additionalChargebackIdsForPP],
            numberOfInstallments,
            startDate: new Date(startDate),
            note,
            dry: true
          })
        }}
      >
        Preview
      </Button>
      <ConfirmButton
        size='lg'
        colorScheme='green'
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={() =>
          createPaymentPlan({
            chargebackIds: [chargebackDetails.id, ...additionalChargebackIdsForPP],
            numberOfInstallments,
            startDate: new Date(startDate),
            note
          })
        }
      >
        Create
      </ConfirmButton>
    </Stack>
  )
}

const PaymentPlanPreview: React.FC<React.PropsWithChildren<{ previewData: PaymentPlanDryRun }>> = ({
  previewData
}) => (
  <Stack>
    <Heading size='md'>Payment Plan Preview</Heading>
    <Text>Based on the selected settings, the following payment plan would be created:</Text>
    <Text>
      <span className='font-semibold'>Total Amount</span>: ${previewData.total_amount}
    </Text>
    <Table border='1px' borderColor='gray.200'>
      <Thead bgColor='gray.100'>
        <Tr>
          <Th>Date</Th>
          <Th>Amount</Th>
        </Tr>
      </Thead>
      <Tbody>
        {previewData.charges_to_create.map(({ amount, date }) => (
          <Tr key={date}>
            <Td>{date}</Td>
            <Td>${amount}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Stack>
)

const BillDetails: React.FC<
  React.PropsWithChildren<{ chargebackDetails: UnfinalizedChargeback; onClick?: () => void }>
> = ({
  chargebackDetails: {
    rental: { home_address, home_city_state },
    current_prorated_amount,
    flags,
    utility_bill: {
      amount,
      bill_date,
      service_end_date,
      service_start_date,
      utility_type: { name: liabilityTypeName }
    }
  },
  onClick
}) => (
  <Stack onClick={onClick} cursor={onClick && 'pointer'}>
    {[
      {
        label: 'Address',
        element: `${home_address}, ${home_city_state}`
      },
      { label: 'Utility Type', element: startCase(liabilityTypeName) },
      {
        label: 'Service Period',
        element: `${addDays(new Date(service_start_date), 1).toLocaleDateString()} - ${addDays(
          new Date(service_end_date),
          1
        ).toLocaleDateString()}`
      },
      { label: 'Bill Date', element: addDays(new Date(bill_date), 1).toLocaleDateString() },
      { label: 'Prorated Amount', element: formatMoney(current_prorated_amount, 2, '$') },
      { label: 'Bill Original Amount', element: formatMoney(amount, 2, '$') },
      {
        label: 'Flags',
        element: flags.length > 0 && <div className='text-red-600'>{flags.join(', ')}</div>,
        hide: flags.length === 0
      }
    ].map(({ label, element, hide }) => {
      if (hide) return null
      return (
        <Text key={label}>
          <span className='font-semibold'>{label}</span>: {element}
        </Text>
      )
    })}
  </Stack>
)

const AddChargebacks: React.FC<
  React.PropsWithChildren<{
    chargebacks: UnfinalizedChargeback[]
    selectedChargebackIds: Set<string>
    addChargeback: (id: string) => void
    removeChargeback: (id: string) => void
  }>
> = ({ chargebacks, selectedChargebackIds, addChargeback, removeChargeback }) => {
  return (
    <Stack>
      <Heading size='md'>Add Chargebacks</Heading>
      <Stack rounded='md' border='1px' borderColor='gray.200' divider={<Divider />}>
        {chargebacks.length === 0 && <Text p={4}>No additional chargebacks available</Text>}
        {chargebacks.map((chargeback) => (
          <AdditionalChargebackRow
            key={chargeback.id}
            chargeback={chargeback}
            selectedChargebackIds={selectedChargebackIds}
            removeChargeback={removeChargeback}
            addChargeback={addChargeback}
          />
        ))}
      </Stack>
    </Stack>
  )
}

const displayDate = (date: number) => addDays(new Date(date), 1).toLocaleDateString()

const AdditionalChargebackRow = ({
  selectedChargebackIds,
  removeChargeback,
  addChargeback,
  chargeback
}: {
  chargeback: UnfinalizedChargeback
  selectedChargebackIds: Set<string>
  removeChargeback: (id: string) => void
  addChargeback: (id: string) => void
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Stack key={chargeback.id} p='2'>
      {!isOpen && (
        <div className='cursor-pointer p-2' onClick={() => setIsOpen(true)}>
          <HStack justify='space-between'>
            <Text>{chargebackSummary(chargeback)}</Text>
            <ChevronDownIcon height={24} />
          </HStack>
        </div>
      )}
      {isOpen && <BillDetails onClick={() => setIsOpen(false)} chargebackDetails={chargeback} />}
      {selectedChargebackIds.has(chargeback.id) ? (
        <Button colorScheme='red' variant='outline' onClick={() => removeChargeback(chargeback.id)}>
          Remove
        </Button>
      ) : (
        <Button colorScheme='green' variant='outline' onClick={() => addChargeback(chargeback.id)}>
          Add
        </Button>
      )}
    </Stack>
  )
}

const chargebackSummary = (chargeback: UnfinalizedChargeback) => {
  return `${startCase(chargeback.utility_bill.utility_type.name)} | $${
    chargeback.current_prorated_amount
  } | ${displayDate(chargeback.utility_bill.service_start_date)} - ${displayDate(
    chargeback.utility_bill.service_end_date
  )}`
}
