import React, { useState } from 'react'
import {
  Tr,
  Td,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'
import { requiredLeaseDetails } from '@homevest/utils'
import { OccupantWithId } from '@homevest/types/rental-applications'

import SelectDropdown from '../../Common/SelectDropdown'
import AddButton from '../../Common/AddButton'
import { isOccupantAgeValid } from 'lib/required-lease-details'

const { OCCUPANT_RELATIONS, MAX_OCCUPANT_AGE } = requiredLeaseDetails

const AddOccupantRow: React.FC<
  React.PropsWithChildren<{
    addOccupant: (occupant: OccupantWithId) => void
    canAddOccupant: boolean
    skipValidation: boolean
  }>
> = ({ addOccupant, canAddOccupant, skipValidation }) => {
  const [id, setId] = useState(uuidv4())
  const [name, setName] = useState('')
  const [age, setAge] = useState<number | null>(null)
  const [relation, setRelation] = useState('')

  // Don't show error initially when age is null, but disable submission until this is filled out
  const isAgeValid = skipValidation || age === null || isOccupantAgeValid(age)
  const canSubmit = Boolean(name && age !== null && isAgeValid && relation)

  const handleSubmit = () => {
    if (canSubmit) {
      addOccupant({ id, name, age: age!, relation })
      setId(uuidv4())
      setName('')
      setAge(null)
      setRelation('')
    }
  }

  if (!canAddOccupant) {
    return null
  }

  return (
    <Tr>
      <Td>
        <FormControl isRequired>
          <FormLabel htmlFor='name'>Name</FormLabel>
          <Input
            id='name'
            placeholder='Name...'
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired isInvalid={!isAgeValid}>
          <FormLabel htmlFor='age'>Age</FormLabel>
          <NumberInput
            id='age'
            value={age ?? ''}
            onChange={(value) => setAge(value ? parseInt(value) : null)}
            min={0}
            max={120}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>Please enter an age between 0 and {MAX_OCCUPANT_AGE}</FormErrorMessage>
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired>
          <FormLabel htmlFor='relation'>Relation</FormLabel>
          <SelectDropdown
            id='relation'
            options={Object.values(OCCUPANT_RELATIONS)}
            value={relation}
            onChange={(evt) => setRelation(evt.target.value)}
          />
        </FormControl>
      </Td>
      <Td valign='bottom'>
        <AddButton disabled={!canSubmit} onClick={handleSubmit} />
      </Td>
    </Tr>
  )
}

export default AddOccupantRow
