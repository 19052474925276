import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import { capabilities } from '@homevest/utils'
import { useSelector } from 'react-redux'

import {
  BodyText,
  Button,
  Checkbox,
  ErrorText,
  HeaderText,
  Input,
  SuccessText
} from 'components/Toolkit'

import { hasCapability } from 'lib/admin-perms'
import axios from 'lib/axios'

const { DAGGER } = capabilities.CAPABILITY_TYPES

const StyledContent = styled.div`
  width: 500px;
  margin: 0 auto;
`

export default function SentTestStatements() {
  const isDagger = useSelector((store) => hasCapability(store.admin, DAGGER))

  if (!isDagger) {
    return <ErrorText>You do not have the capability of DAGGER.</ErrorText>
  }

  return (
    <StyledContent>
      <HeaderText size='h3' style={{ textAlign: 'center' }}>
        Send Test Statements
      </HeaderText>
      <React.Suspense fallback={<Spinner color='primary' />}>
        <SentTestStatementsViewer />
      </React.Suspense>
    </StyledContent>
  )
}

function SentTestStatementsViewer() {
  const [backedUp, setBackedUp] = useState(false)
  const [debugRentalId, setDebugRentalId] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [hpaCalculated, setHpaCalculated] = useState(false)
  const [glTransactionsUploaded, setGlTransactionsUploaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [skipEmail, setSkipEmail] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)

  const handleSend = async () => {
    try {
      setIsLoading(true)

      await axios.post('/admin/system/topic_publish', {
        topic: 'calculator-send-monthly-statements',
        message: {
          debugRentalId,
          skipEmail
        }
      })

      setSuccessMessage('Test statements sent. Check your email!')
    } catch (err) {
      setErrorMessage(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Spinner color='primary' style={{ textAlign: 'center' }} />
  }

  if (errorMessage) {
    return <ErrorText>{errorMessage}</ErrorText>
  }

  if (successMessage) {
    return (
      <div>
        <SuccessText>{successMessage}</SuccessText>
      </div>
    )
  }

  return (
    <div>
      <BodyText style={{ marginBottom: '20px' }}>
        This tool sends test statements. Before you send, did you do the following:
      </BodyText>
      <Checkbox
        label='Run the latest HPA calculations.'
        value={hpaCalculated}
        onChange={setHpaCalculated}
      />
      <Checkbox
        label='Upload the latest GL transactions.'
        value={glTransactionsUploaded}
        onChange={setGlTransactionsUploaded}
      />
      <Checkbox label='Backup and rebuild statements.' value={backedUp} onChange={setBackedUp} />
      {hpaCalculated && glTransactionsUploaded && backedUp && (
        <div>
          <Button onClick={handleSend}>Send Test!</Button>
          <Checkbox label='Skip Email Send' value={skipEmail} onChange={setSkipEmail} />
          <Input
            label='Debug Rental ID'
            value={debugRentalId}
            onChange={(val) => setDebugRentalId(val)}
          />
        </div>
      )}
    </div>
  )
}
