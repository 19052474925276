import React from 'react'
import { ColDef, ICellRendererParams } from 'ag-grid-community'

import { PendingRenewalDataRow } from '../types'
import { format } from 'date-fns'
import { startCase } from 'lodash'

const ADDRESS: ColDef<PendingRenewalDataRow> = {
  headerName: 'Address',
  field: 'home_details.address',
  valueFormatter: (params) => params.value + ' | ' + params.data?.market_name
}

const TENANT: ColDef<PendingRenewalDataRow> = {
  headerName: 'Tenant',
  field: 'primary_tenant_name'
}

const LEASE_END_DATE: ColDef<PendingRenewalDataRow> = {
  headerName: 'Lease End Date',
  field: 'lease_ends_at',
  valueFormatter: (params) => {
    try {
      return format(new Date(params.value), 'MMM d, Y')
    } catch {
      return params.value
    }
  }
}

const STATUS: ColDef<PendingRenewalDataRow> = {
  headerName: 'Status',
  field: 'status',
  valueFormatter: (params) => startCase(params.data?.status)
}

const MOST_RECENT_NOTE: ColDef<PendingRenewalDataRow> = {
  headerName: 'Most Recent Note',
  field: 'most_recent_note',
  cellRenderer: (params: ICellRendererParams<PendingRenewalDataRow, string | undefined>) => {
    const { data, value } = params
    if (value && data?.most_recent_note_created_at) {
      const createdDate = format(new Date(data.most_recent_note_created_at), 'yyyy-MM-dd')
      return (
        <div>
          {'['}
          <span className='font-semibold'>{createdDate}</span>
          {'] '}
          {value}
        </div>
      )
    }
    return ''
  },
  autoHeight: true
}

export const COMPLETED_COLUMNS = [ADDRESS, TENANT, LEASE_END_DATE, STATUS, MOST_RECENT_NOTE]
