import { rentalApplications, regions, agentLeadGroupOwners, rentals } from '@homevest/utils'
import { Order_By, UpupRentalApplicationsQuery } from 'graphql/generated'
import capitalize from 'lodash/capitalize'

const { AGENT_TYPES } = agentLeadGroupOwners

const STATUS = rentalApplications.RENTAL_APPLICATION_STATUSES

export enum ColumnKey {
  address = 'address',
  market = 'market',
  lead_group = 'lead_group',
  status = 'status',
  projected_occupancy_date = 'projected_occupancy_date',
  assigned_team = 'assigned_team',
  created_at = 'created_at',
  escalation_note = 'escalation_note',
  escalated_by = 'escalated_by',
  escalated_to = 'escalated_to',
  escalation_date = 'escalation_date',
  priority_score = 'priority_score',
  snooze = 'snooze',
  submitted_at = 'submitted_at',
  metadata = 'metadata',
  rental_program_type_applied_for = 'rental_program_type_applied_for'
}

export type Application = UpupRentalApplicationsQuery['rental_applications'][number]

export type PageState = { size: number; index: number }

export type SortColumnKey =
  | ColumnKey.address
  | ColumnKey.market
  | ColumnKey.status
  | ColumnKey.projected_occupancy_date
  | ColumnKey.created_at
  | ColumnKey.escalation_date
  | ColumnKey.priority_score
  | ColumnKey.rental_program_type_applied_for

export type FilterColumnKey =
  | ColumnKey.address
  | ColumnKey.assigned_team
  | ColumnKey.lead_group
  | ColumnKey.escalated_by
  | ColumnKey.escalated_to
  | ColumnKey.escalation_note

export type SelectColumnKey =
  | ColumnKey.status
  | ColumnKey.market
  | ColumnKey.snooze
  | ColumnKey.rental_program_type_applied_for

export type SortState = {
  column: SortColumnKey
  type: Order_By.Asc | Order_By.Desc
}

export type FilterState = Partial<{ [key in FilterColumnKey]: string }>

export type SelectState = Partial<{
  [key in SelectColumnKey]: string[]
}>

export type TableState = {
  sortState: SortState
  filterState: FilterState
  selectState: SelectState
  pageState: PageState
  tabState: string
}

export const DEFAULT_SORT = {
  column: ColumnKey.created_at,
  type: Order_By.Desc
} as const

export const DEFAULT_PAGE = { index: 0, size: 50 } as const

export const STATUS_MAP = {
  [STATUS.PENDING_CUSTOMER_INFORMATION]: 'Customer Info',
  [STATUS.PENDING_APPLICATION_REVIEW]: 'App Review',
  [STATUS.PENDING_ADDITIONAL_UNDERWRITING_INFORMATION]: 'Additional UW Info',
  [STATUS.PENDING_UNDERWRITING]: 'Underwriting',
  [STATUS.PENDING_ADDITIONAL_LEASE_INFORMATION]: 'Additional Lease Info',
  [STATUS.PENDING_RENTAL_AGREEMENT_CREATION]: 'Lease Creation',
  [STATUS.PENDING_CUSTOMER_RENTAL_AGREEMENT_COMPLETION]: 'Lease Signing',
  [STATUS.PENDING_CUSTOMER_PAYMENT]: 'Payment',
  [STATUS.HOLD_FOR_NEW_LEASE]: 'Hold',
  [STATUS.PENDING_UP_AND_UP_RENTAL_AGREEMENT_COMPLETION]: 'Countersigning',
  [STATUS.PENDING_PROPERTY_PURCHASE]: 'Property Purchase',
  [STATUS.COMPLETE]: 'Complete',
  [STATUS.CANCELED]: 'Canceled'
}

export const MARKET_MAP = {
  [regions.REGIONS.ATLANTA]: 'Atlanta',
  [regions.REGIONS.CHARLOTTE]: 'Charlotte',
  [regions.REGIONS.DALLAS]: 'Dallas',
  [regions.REGIONS.HUNTSVILLE]: 'Huntsville',
  [regions.REGIONS.INDIANAPOLIS]: 'Indianapolis',
  [regions.REGIONS.LOS_ANGELES]: 'Los Angeles',
  [regions.REGIONS.PHOENIX]: 'Phoenix',
  [regions.REGIONS.RALEIGH]: 'Raleigh',
  [regions.REGIONS.ST_LOUIS]: 'St. Louis',
  [regions.REGIONS.TAMPA]: 'Tampa'
}

export const RENTAL_PROGRAM_TYPE_MAP = {
  [rentals.RENTAL_PROGRAM_TYPES.UPANDUP]: 'Up&Up',
  [rentals.RENTAL_PROGRAM_TYPES.VANILLA]: 'Vanilla'
}

export const mapAgentTypeToLabel = (agentType: string) => {
  switch (agentType) {
    case AGENT_TYPES.LEASING_COORDINATOR:
      return 'Coord.'
    case AGENT_TYPES.LEASING_AGENT:
      return 'Agent'
    case AGENT_TYPES.LEASING_REPRESENTATIVE:
      return 'Rep'
    default:
      return capitalize(agentType)
  }
}
