import React, { memo } from 'react'
import styled from 'styled-components'
import hexRgb from 'hex-rgb'

import COLORS from 'components/Toolkit/colors'

const rbgaSalmon = hexRgb(COLORS.ACCENTS.SALMON, { format: 'array' })
rbgaSalmon[3] = 0.05
const lightSalmon = `rgba(${rbgaSalmon.join(',')})`

const StyledContainer = styled.div`
  color: ${COLORS.ACCENTS.SALMON};
  background: ${lightSalmon};
  padding: 9px 17px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  margin: 25px 0;
`

function ErrorText(props) {
  return <StyledContainer {...props} />
}

export default memo(ErrorText)
