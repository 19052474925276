import React from 'react'
import { Divider, Heading, Flex, Text, Box, Button, Spacer } from '@chakra-ui/react'
import { PencilIcon } from '@heroicons/react/20/solid'
import { format as formatDate } from 'date-fns'

const DetailsSection: React.FC<
  React.PropsWithChildren<{
    title: string
    submittedAt?: string | null
    isEditable: boolean
    isEditing: boolean
    setIsEditing: (isEditing: boolean) => void
    children: JSX.Element | JSX.Element[] | null | false
  }>
> = ({ title, submittedAt, isEditable, isEditing, setIsEditing, children }) => {
  const formattedSubmissionDate = submittedAt
    ? formatDate(new Date(submittedAt), "M/d/yy 'at' h:mm a")
    : null

  const shouldShowEditButton = isEditable && !isEditing
  return (
    <div className='mb-4'>
      <Flex marginBottom={3} direction={'row'}>
        <Box>
          <Heading size='md' as='h4'>
            {title}
          </Heading>
          {!isEditing && (
            <Text fontSize='sm' size='sm' as='i'>
              {submittedAt ? `Submitted ${formattedSubmissionDate}` : 'Not Yet Submitted'}
            </Text>
          )}
        </Box>
        <Spacer />

        {shouldShowEditButton && (
          <Box>
            <Button
              size='sm'
              rightIcon={<PencilIcon className='h-4 w-4' />}
              onClick={() => setIsEditing(!isEditing)}
            >
              Edit
            </Button>
          </Box>
        )}
      </Flex>
      <Divider />
      {children}
      <Divider marginY={2} />
    </div>
  )
}

export default DetailsSection
