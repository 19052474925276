"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RENTAL_PROGRAM_LATE_FEES = exports.RENTAL_PROGRAM_LEASE_TYPES = exports.RENTAL_PROGRAM_TYPES = exports.DO_NOT_RENEW_DAYS_BEFORE_LEASE_END = exports.RENTAL_STATUS = exports.LEASE_END_REASONS = void 0;
exports.LEASE_END_REASONS = Object.freeze({
    ABANDONED_LEASE: 'abandoned_lease',
    DO_NOT_RENEW: 'do_not_renew',
    EARLY_TERMINATION: 'early_termination',
    EARLY_TERMINATION_CASUALTY_LOSS: 'early_termination_casualty_loss',
    EARLY_TERMINATION_MILITARY: 'early_termination_military',
    EVICTED: 'evicted',
    LEFT_PROGRAM: 'left_program',
    NEVER_MOVED_IN: 'never_moved_in',
    PURCHASED_HOME: 'purchased_home',
    SWITCHED_HOMES: 'switched_homes',
    PRIMARY_TENANT_TRANSFER: 'primary_tenant_transfer',
    VACATED_IN_EVICTIONS: 'vacated_in_evictions'
});
exports.RENTAL_STATUS = Object.freeze({
    ACTIVE: 'active',
    CANCELED: 'canceled', // move-out before the end of the lease
    COMPLETE: 'complete', // move-out at end of lease
    PENDING: 'pending'
});
// Number of days before lease end when we send a scheduled do not renew notice to the tenant
exports.DO_NOT_RENEW_DAYS_BEFORE_LEASE_END = 60;
exports.RENTAL_PROGRAM_TYPES = {
    UPANDUP: 'upandup',
    VANILLA: 'vanilla'
};
exports.RENTAL_PROGRAM_LEASE_TYPES = {
    [exports.RENTAL_PROGRAM_TYPES.UPANDUP]: 'upandup_lease',
    [exports.RENTAL_PROGRAM_TYPES.VANILLA]: 'vanilla_lease'
};
// TODO: Move this to the llcs table to allow more flexibility
exports.RENTAL_PROGRAM_LATE_FEES = {
    [exports.RENTAL_PROGRAM_TYPES.UPANDUP]: [
        { day: 6, amount: 25, threshold: 300 },
        { day: 11, amount: 50, threshold: 300 }
    ],
    [exports.RENTAL_PROGRAM_TYPES.VANILLA]: [{ day: 4, percentage: 0.1, threshold: 300 }]
};
