import React, { FunctionComponent } from 'react'

import ErrorModal from './ErrorModal'
import SuccessToast from './SuccessToast'

const Modals: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <ErrorModal />
      <SuccessToast />
    </>
  )
}

export default Modals
