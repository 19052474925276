import moment from 'moment'

import axios from '../../lib/axios'

export const RECEIVED_RENTAL = 'RECEIVED_RENTAL'
export const RECEIVED_RENTALS = 'RECEIVED_RENTALS'
export const RECEIVED_RENTALS_PENDING_PAYMENT = 'RECEIVED_RENTALS_PENDING_PAYMENT'

export const receivedRental = (rental) => ({
  type: RECEIVED_RENTAL,
  rental
})

export const receivedRentals = (rentals) => ({
  type: RECEIVED_RENTALS,
  rentals
})

export const receivedRentalsPendingPayment = (rentals) => ({
  type: RECEIVED_RENTALS_PENDING_PAYMENT,
  rentals
})

export const fetchRentals = () => {
  return async (dispatch) => {
    const rentals = await axios.get('/rentals')
    dispatch(receivedRentals(rentals.data))
  }
}

export const fetchRentalsPendingPayment = () => {
  return async (dispatch) => {
    const occupancyDate = moment().add(30, 'd').format('YYYY-MM-DD')
    const rentals = await axios.get(`/rentals?occupancy_date=~lt~${occupancyDate}&status=pending`)
    dispatch(receivedRentalsPendingPayment(rentals.data))
  }
}
