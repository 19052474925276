import React, { useState, useMemo } from 'react'
import { GridApi, GridReadyEvent } from 'ag-grid-community'
import { sub } from 'date-fns'
import { useSelector } from 'react-redux'
import { useMutation } from 'urql'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  MoonIcon
} from '@heroicons/react/24/solid'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Divider,
  Heading,
  Spinner,
  Stack,
  Text,
  HStack,
  Icon,
  TabList,
  Tabs,
  TabPanels,
  TabPanel,
  useToast
} from '@chakra-ui/react'

import LabelledTab from 'components/RentalSiteChecker/LabelledTab'
import ChecksTable from 'components/RentalSiteChecker/ChecksTable'
import { Loading } from 'ui'
import { CHECK_RANGE_IN_DAYS } from 'constants/rental-site-checker'
import {
  MuteRentalSiteOpportunityCheckDocument,
  useUpupRentalSiteOpportunityCheckListQuery
} from 'graphql/generated'

export default function RentalSiteChecker() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_refreshAt, setRefreshAt] = useState<Date>(new Date())
  return (
    <Box w='100%' p={1} padding={5}>
      <Stack spacing={4}>
        <Heading as='h3' size='xl' width='100%'>
          Rental Site Checker
        </Heading>
        <Divider />
        <React.Suspense fallback={<Spinner />}>
          <RentalSiteCheckerViewer setRefreshAt={setRefreshAt} />
        </React.Suspense>
      </Stack>
    </Box>
  )
}

function RentalSiteCheckerViewer({ setRefreshAt }: { setRefreshAt: any }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, muteRentalSiteOpportunityCheck] = useMutation(MuteRentalSiteOpportunityCheckDocument)
  const [errorMessage, setErrorMessage] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastRunAt, _setLastRunAt] = useState<Date>(sub(new Date(), { days: CHECK_RANGE_IN_DAYS }))
  const toast = useToast()
  const adminId = useSelector((state: any) => state.admin.id)

  const context = useMemo(
    () => ({
      suspense: false
    }),
    []
  )

  const [_failedGridApi, setFailedGridApi] = useState<GridApi>()
  const [_successGridApi, setSuccessGridApi] = useState<GridApi>()
  const [_mutedGridApi, setMutedGridApi] = useState<GridApi>()

  const onFailedGridReady = (evt: GridReadyEvent) => {
    setFailedGridApi(evt.api)
  }

  const onSuccessGridReady = (evt: GridReadyEvent) => {
    setSuccessGridApi(evt.api)
  }

  const onMutedGridReady = (evt: GridReadyEvent) => {
    setMutedGridApi(evt.api)
  }

  const [{ data }, reexecuteOpportunityChecksQuery] = useUpupRentalSiteOpportunityCheckListQuery({
    context,
    variables: {
      lastRunAt
    }
  })

  if (!data) {
    return <Loading />
  }

  const rentalSiteOpportunityChecks = data.rental_site_opportunity_checks

  const failedChecks = rentalSiteOpportunityChecks.filter((c) => !c.status && !c.muted_at)

  const mutedChecks = rentalSiteOpportunityChecks.filter((c) => c.muted_at)

  const okChecks = rentalSiteOpportunityChecks.filter((c) => c.status)

  const handleMute = async (id: string) => {
    const result = await muteRentalSiteOpportunityCheck({
      pk_columns: { id },
      muted_at: new Date(),
      muted_by_admin_id: adminId
    })

    reexecuteOpportunityChecksQuery({ requestPolicy: 'network-only' })

    setRefreshAt(new Date())
    if (result.error) {
      setErrorMessage(result.error.message)
      return
    }

    toast({
      title: 'Check muted!',
      description: 'Successfuly muted rental site check.',
      status: 'success',
      position: 'top-right',
      isClosable: true
    })
  }

  const handleTabChange = (index: number) => {
    // Need to resize on tab change, otherwise column sizes will be off
    // since the tables on other tabs are not yet visible when the corresponding
    // grid first renders
    switch (index) {
      case 0:
        _failedGridApi?.sizeColumnsToFit()
        break
      case 1:
        _successGridApi?.sizeColumnsToFit()
        break
      case 2:
        _mutedGridApi?.sizeColumnsToFit()
        break
    }
  }

  const handleUnmute = async (id: string) => {
    const result = await muteRentalSiteOpportunityCheck({
      pk_columns: { id },
      muted_at: null,
      muted_by_admin_id: null
    })

    setRefreshAt(new Date())
    if (result.error) {
      setErrorMessage(result.error.message)
      return
    }

    toast({
      title: 'Check unmuted!',
      description: 'Successfuly unmuted rental site check.',
      status: 'success',
      position: 'top-right',
      isClosable: true
    })
  }

  return (
    <>
      <Stack spacing={3}>
        <Text as='b' fontSize='lg'>
          Instructions
        </Text>
        <HStack>
          <Icon
            as={failedChecks.length ? ExclamationCircleIcon : CheckCircleIcon}
            color={failedChecks.length ? 'red.500' : 'green.500'}
          />
          <Text>
            Review all failed checks by checking the appropriate rental site. Once you've verified
            that the listing is live, mark mute. You have {failedChecks.length} more to go.
          </Text>
        </HStack>
        {errorMessage && (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>Whoops!</AlertTitle>
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <Tabs onChange={handleTabChange} isFitted colorScheme='teal'>
          <TabList>
            <LabelledTab
              label={'Failed'}
              icon={ExclamationTriangleIcon}
              checkCount={failedChecks.length}
            />
            <LabelledTab label={'Successful'} icon={CheckCircleIcon} checkCount={okChecks.length} />
            <LabelledTab label={'Muted'} icon={MoonIcon} checkCount={mutedChecks.length} />
          </TabList>
          <TabPanels>
            <TabPanel>
              <ChecksTable
                handleMuteAction={handleMute}
                tableType={'failed'}
                onGridReady={onFailedGridReady}
                checksToDisplay={failedChecks || []}
              />
            </TabPanel>
            <TabPanel>
              <ChecksTable
                tableType={'successful'}
                onGridReady={onSuccessGridReady}
                checksToDisplay={okChecks || []}
              />
            </TabPanel>
            <TabPanel>
              <ChecksTable
                handleMuteAction={handleUnmute}
                tableType={'muted'}
                onGridReady={onMutedGridReady}
                checksToDisplay={mutedChecks || []}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </>
  )
}
