import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useQuery } from 'urql'

import Tabs from '../Tabs'
import Preferences from './Preferences'
import Info from './Info'

import { REQUEST_POLICY } from 'constants/urql'
import Notes from 'components/Notes'
import { ErrorText } from 'components/Toolkit'

import { Upup_UserCrmDocument as QueryDoc } from 'graphql/generated'
import { combineFirstNameLastName } from 'lib/utils'

const StyledContainer = styled.div`
  height: calc(100vh - 248px);
  width: 100%;
`

const TABS = {
  PREFERENCES: 'Preferences',
  NOTES: 'Notes'
}

const TAB_OPTIONS = Object.values(TABS).map((value) => ({
  label: value,
  value
}))

const User = ({ userId }) => (
  <React.Suspense fallback={<span>Loading...</span>}>
    <UserRender userId={userId} />
  </React.Suspense>
)

function UserRender({ userId }) {
  const [tab, setTab] = useState(TABS.PREFERENCES)

  const [{ data, error }] = useQuery({
    query: QueryDoc,
    variables: { id: userId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK
  })

  if (error) {
    return <ErrorText>{error}</ErrorText>
  }

  const user = data.users[0]

  // For a user want to render user notes, lead group notes, rental app notes, and rental notes.
  const resourceNoteWidgetOptions = []
  const userOption = {
    id: user.id,
    type: 'users',
    displayName: combineFirstNameLastName(user),
    writable: true
  }
  resourceNoteWidgetOptions.push(userOption)

  for (const r of user.rentals) {
    const option = {
      id: r.id,
      type: 'rentals',
      displayName: 'Rental ' + r.rental_application.mls_listing.display_line_1,
      linkTo: '/rent-roll/' + r.id
    }
    resourceNoteWidgetOptions.push(option)
  }

  for (const lgu of user.lead_group_users) {
    const leadGroup = lgu.lead_group
    const lgOption = {
      id: leadGroup.id,
      type: 'lead_groups',
      displayName:
        'Lead group for ' +
        leadGroup.rental_applications.map((ra) => ra.mls_listing.display_line_1).join(', ')
    }
    resourceNoteWidgetOptions.push(lgOption)

    for (const ra of leadGroup.rental_applications) {
      const raOption = {
        id: ra.id,
        type: 'rental_applications',
        displayName: 'Application for ' + ra.mls_listing.display_line_1,
        linkTo: '/applications/' + ra.id
      }
      resourceNoteWidgetOptions.push(raOption)
    }
  }

  return (
    <StyledContainer>
      <div>
        <div style={{ padding: '20px' }}>
          <Info userId={userId} />
        </div>
        <div
          style={{
            borderBottom: '1px solid #dbd9d8',
            padding: '0 20px',
            marginTop: '20px'
          }}
        >
          <Tabs userId={userId} onChange={setTab} value={tab} options={TAB_OPTIONS} />
        </div>
        <div style={{ padding: '20px' }}>
          {tab.toLowerCase() === TABS.PREFERENCES.toLowerCase() ? (
            <Preferences userId={userId} />
          ) : null}
          {tab.toLowerCase() === TABS.NOTES.toLowerCase() ? (
            <Notes headerless={true} resourceNoteWidgetOptions={resourceNoteWidgetOptions} />
          ) : null}
        </div>
      </div>
    </StyledContainer>
  )
}

User.propTypes = {
  userId: PropTypes.string.isRequired
}

UserRender.propTypes = {
  userId: PropTypes.string.isRequired
}

export default User
