import { format, parseISO } from 'date-fns'
import { startCase } from 'lodash'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import {
  RentRollListRentalFragment,
  UtilityResponsibilitiesFragment,
  PortfolioHomeUtilitiesFragment,
  useUpup_CreateUtilityResponsibilityMutation
} from 'graphql/generated'
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  VStack
} from '@chakra-ui/react'
import { BlueBadge, GreenBadge } from 'components/TailwindUIToolkit/StyledComponents'
import { utilities } from '@homevest/utils'
import { Link } from 'react-router-dom'

const { UTILITIES_RESPONSIBLE_PARTIES } = utilities

const UTIL_RESP_INPUT_ERROR_TEXT = 'text-center text-wrap text-xs text-red-500'

type PortfolioHomeUtility = PortfolioHomeUtilitiesFragment['portfolio_home_utilities'][0]

type UtilityResponsibilitiesProps = {
  rental: RentRollListRentalFragment
}

type UtilityResponsbility = UtilityResponsibilitiesFragment['utility_responsibilities'][0]

type ComponentData = {
  isEditingAnyRow: boolean
  setIsEditingAnyRow: React.Dispatch<React.SetStateAction<boolean>>
  isAddUtilRespModalOpen: boolean
  setIsAddUtilRespModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalPortfolioHomeUtilityInput: string
  setModalPortfolioHomeUtilityInput: React.Dispatch<React.SetStateAction<string>>
  modalRespPartyInput: string
  setModalRespPartyInput: React.Dispatch<React.SetStateAction<string>>
  modalStartDateInput: Date
  setModalStartDateInput: React.Dispatch<React.SetStateAction<Date>>
  createUtilityResponsibility: ReturnType<typeof useUpup_CreateUtilityResponsibilityMutation>[1]
  adminId: string
  rentalId: string
  portfolioHomeId: string
  portfolioHomeUtilities: Array<PortfolioHomeUtility>
}

type UtilityResponsibilityData = ComponentData & { utilResp: UtilityResponsbility }

type UtilityResponsibilityStates = UtilityResponsibilityData & {
  adminName: string
  createdAt: string
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  respParty: string
  setRespParty: React.Dispatch<React.SetStateAction<string>>
  startDate: Date
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  isRespPartyValid: boolean
  isStartDateValid: boolean
}

const StandardComponents = (states: UtilityResponsibilityStates) => {
  const respPartyBadge =
    states.respParty === UTILITIES_RESPONSIBLE_PARTIES.UPANDUP ? (
      <BlueBadge>Up&Up</BlueBadge>
    ) : (
      <GreenBadge>Tenant</GreenBadge>
    )
  return (
    <>
      <Td>{startCase(states.utilResp.portfolio_home_utility.utility_type.name)}</Td>
      <Td>{respPartyBadge}</Td>
      <Td>{format(states.startDate, 'MM/dd/yyyy')}</Td>
      <Td>{states.adminName}</Td>
      <Td>{states.createdAt}</Td>
      <Td>
        <Button size='xs' colorScheme='teal' onClick={() => states.setIsEditing((x) => !x)}>
          Edit
        </Button>
      </Td>
    </>
  )
}

const submitUtilResp = async (states: UtilityResponsibilityStates, isValid: boolean) => {
  if (!isValid) {
    return
  }

  states.setIsEditing((x) => !x)
  await states.createUtilityResponsibility({
    rentalId: states.rentalId,
    adminId: states.adminId,
    portfolioHomeUtilityId: states.utilResp.portfolio_home_utility.id,
    responsibleParty: states.respParty,
    startDate: states.startDate
  })

  states.setRespParty(states.utilResp.responsible_party)
  states.setStartDate(parseISO(states.utilResp.start_date))
}

const EditModeButtons = (states: UtilityResponsibilityStates) => {
  const isValid = states.isRespPartyValid && states.isStartDateValid
  return (
    <HStack>
      <Button
        size='xs'
        colorScheme='teal'
        onClick={async (e) => {
          e.stopPropagation()
          await submitUtilResp(states, isValid)
        }}
        disabled={!isValid}
        sx={{ '&:disabled': { cursor: 'not-allowed' } }}
      >
        Submit
      </Button>
      <Button
        size='xs'
        onClick={(e) => {
          e.stopPropagation()
          states.setIsEditing((x) => !x)
          states.setRespParty(states.utilResp.responsible_party)
          states.setStartDate(parseISO(states.utilResp.start_date))
        }}
      >
        Cancel
      </Button>
    </HStack>
  )
}

const EditModeComponents = (states: UtilityResponsibilityStates) => {
  return (
    <>
      <Td>{startCase(states.utilResp.portfolio_home_utility.utility_type.name)}</Td>
      <Td>
        <Select
          size='xs'
          value={states.respParty}
          onChange={(e) => states.setRespParty(e.target.value)}
        >
          <option value={UTILITIES_RESPONSIBLE_PARTIES.UPANDUP}>Up&Up</option>
          <option value={UTILITIES_RESPONSIBLE_PARTIES.TENANT}>Tenant</option>
        </Select>
        {!states.isRespPartyValid && (
          <div className={UTIL_RESP_INPUT_ERROR_TEXT}>
            {`Responsibility is already set to ${states.utilResp.responsible_party}.`}
          </div>
        )}
      </Td>
      <Td>
        <Input
          size='xs'
          type='date'
          value={format(states.startDate, 'yyyy-MM-dd')}
          onChange={(e) => states.setStartDate(parseISO(e.target.value))}
        />
        {!states.isStartDateValid && (
          <div className={UTIL_RESP_INPUT_ERROR_TEXT}>
            {`New date must be after most recent start date ${format(
              parseISO(states.utilResp.start_date),
              'MM/dd/yyyy'
            )}.`}
          </div>
        )}
      </Td>
      <Td>{states.adminName}</Td>
      <Td>{states.createdAt}</Td>
      <Td>{EditModeButtons(states)}</Td>
    </>
  )
}

const UtilityResponsibility: FC<React.PropsWithChildren<{ data: UtilityResponsibilityData }>> = ({
  data
}) => {
  const originalRespParty = data.utilResp.responsible_party
  const originalStartDate = parseISO(data.utilResp.start_date)

  const [isEditing, setIsEditing] = useState(false)
  const [respParty, setRespParty] = useState(originalRespParty)
  const [startDate, setStartDate] = useState(originalStartDate)

  let isRespPartyValid = respParty !== originalRespParty
  let isStartDateValid = startDate > originalStartDate

  const adminName = [data.utilResp.admin?.first_name, data.utilResp.admin?.last_name].join(' ')
  const createdAt = format(parseISO(data.utilResp.created_at), 'MMM d yyyy, h:mm a')

  const states = {
    ...data,
    adminName,
    createdAt,
    isEditing,
    setIsEditing,
    respParty,
    setRespParty,
    startDate,
    setStartDate,
    isRespPartyValid,
    isStartDateValid
  }

  return <Tr>{isEditing ? EditModeComponents(states) : StandardComponents(states)}</Tr>
}

const AddUtilRespModal = (data: ComponentData, existingUtilTypeIds: string[]) => {
  const clearModal = () => {
    data.setIsAddUtilRespModalOpen(false)
    data.setModalPortfolioHomeUtilityInput('')
    data.setModalRespPartyInput('')
    data.setModalStartDateInput(new Date())
  }
  const areAllInputsSet =
    data.modalPortfolioHomeUtilityInput && data.modalRespPartyInput && data.modalStartDateInput

  const sortedAndFilteredPhus = data.portfolioHomeUtilities
    .filter((phu) => !phu.deactivated_at)
    .filter((phu) => !existingUtilTypeIds.includes(phu.utility_type.id))
    .sort((a, b) => a.utility_type.name.localeCompare(b.utility_type.name))

  const isDisabled = sortedAndFilteredPhus.length === 0

  const addUtilRespForm = (
    <FormControl isRequired>
      <VStack align='normal'>
        {sortedAndFilteredPhus.length === 0 && (
          <Box textAlign='center' p={2} rounded='md' bg='red.50' color='red.500'>
            No new utilities to add. This modal can only be used to add new utilities to the rental
            that are already configured in the Utilities panel of{' '}
            <Link className='underline' to={'/home/' + data.portfolioHomeId}>
              Homes360
            </Link>
            .
          </Box>
        )}

        <Box>
          <FormLabel>Utility Type</FormLabel>
          <Select
            isDisabled={isDisabled}
            placeholder='Select new utility type'
            value={data.modalPortfolioHomeUtilityInput}
            onChange={(e) => data.setModalPortfolioHomeUtilityInput(e.target.value)}
          >
            {sortedAndFilteredPhus.map((phu) => (
              <option key={phu.id} value={phu.id} label={startCase(phu.utility_type.name)} />
            ))}
          </Select>
        </Box>
        <Box>
          <FormLabel>Responsible Party</FormLabel>
          <Select
            isDisabled={isDisabled}
            placeholder='Select responsible party'
            value={data.modalRespPartyInput}
            onChange={(e) => data.setModalRespPartyInput(e.target.value)}
          >
            <option value={UTILITIES_RESPONSIBLE_PARTIES.UPANDUP}>Up&Up</option>
            <option value={UTILITIES_RESPONSIBLE_PARTIES.TENANT}>Tenant</option>
          </Select>
        </Box>
        <Box>
          <FormLabel>Start Date</FormLabel>
          <Input
            isDisabled={isDisabled}
            type='date'
            value={format(data.modalStartDateInput, 'yyyy-MM-dd')}
            onChange={(e) => data.setModalStartDateInput(parseISO(e.target.value))}
          />
        </Box>
      </VStack>
    </FormControl>
  )

  const addUtilRespSubmitButton = (
    <Button
      onClick={async (e) => {
        e.stopPropagation()
        await data.createUtilityResponsibility({
          rentalId: data.rentalId,
          adminId: data.adminId,
          portfolioHomeUtilityId: data.modalPortfolioHomeUtilityInput,
          responsibleParty: data.modalRespPartyInput,
          startDate: data.modalStartDateInput
        })
        data.setIsAddUtilRespModalOpen(false)
      }}
      colorScheme='teal'
      disabled={!areAllInputsSet}
    >
      Submit
    </Button>
  )

  return (
    <Modal isOpen={data.isAddUtilRespModalOpen} onClose={clearModal}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Add a Utility Responsibility</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{addUtilRespForm}</ModalBody>
          <ModalFooter>{addUtilRespSubmitButton}</ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

const AddUtilRespButton = (data: ComponentData) => {
  return (
    <Button
      size='sm'
      colorScheme='teal'
      onClick={(e) => {
        e.stopPropagation()
        data.setIsAddUtilRespModalOpen(true)
      }}
    >
      + Add
    </Button>
  )
}

const UtilityResponsibilitiesWrapper: FC<React.PropsWithChildren<UtilityResponsibilitiesProps>> = ({
  rental
}) => {
  const [isEditingAnyRow, setIsEditingAnyRow] = useState(false)
  const [isAddUtilRespModalOpen, setIsAddUtilRespModalOpen] = useState(false)
  const [modalPortfolioHomeUtilityInput, setModalPortfolioHomeUtilityInput] = useState('')
  const [modalRespPartyInput, setModalRespPartyInput] = useState('')
  const [modalStartDateInput, setModalStartDateInput] = useState(new Date())
  const [{ error }, createUtilityResponsibility] = useUpup_CreateUtilityResponsibilityMutation()
  const admin = useSelector((store: StoreState) => store.admin)

  const data = {
    isEditingAnyRow,
    setIsEditingAnyRow,
    isAddUtilRespModalOpen,
    setIsAddUtilRespModalOpen,
    createUtilityResponsibility,
    modalPortfolioHomeUtilityInput,
    setModalPortfolioHomeUtilityInput,
    modalRespPartyInput,
    setModalRespPartyInput,
    modalStartDateInput,
    setModalStartDateInput,
    adminId: admin.id,
    rentalId: rental.id,
    portfolioHomeId: rental.portfolio_home!.id,
    portfolioHomeUtilities: rental.portfolio_home!.portfolio_home_utilities
  }

  return (
    <ContentSectionCard title='Utility Responsibilities' action={AddUtilRespButton(data)}>
      <>{error && <Alert status='error'>{error.message}</Alert>}</>
      <TableContainer>
        <Table size='sm'>
          <Thead bg='gray.100'>
            <Tr>
              <Th>Utility Type</Th>
              <Th>Responsibility</Th>
              <Th>Start Date</Th>
              <Th>Set By</Th>
              <Th>Set At</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rental.current_utility_responsibilities.map(
              // Since current_utility_responsibilities is a view, all columns are nullable
              // to avoid having to write ! everywhere, use UtilityResponsibilityFragment type
              //@ts-ignore
              (ur: UtilityResponsbility) => {
                const utilRespData = { ...data, utilResp: ur }
                return <UtilityResponsibility key={ur.id} data={utilRespData} />
              }
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {isAddUtilRespModalOpen ? (
        AddUtilRespModal(
          data,
          rental.current_utility_responsibilities.map(
            (ur) => ur.portfolio_home_utility?.utility_type.id
          )
        )
      ) : (
        <></>
      )}
    </ContentSectionCard>
  )
}

export default UtilityResponsibilitiesWrapper
