import React from 'react'
import { Text, Link, HStack, Flex } from '@chakra-ui/react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'

import { UtilityDetailsByPortfolioHomeIdQuery } from 'graphql/generated'
import UtilitiesGrid from '../../Grids/UtilitiesGrid'

type UtilityDetailsViewPropTypes = {
  utilityDetails: UtilityDetailsByPortfolioHomeIdQuery['portfolio_homes_by_pk']
}

const UtilityDetailsView: React.FC<React.PropsWithChildren<UtilityDetailsViewPropTypes>> = ({
  utilityDetails
}) => {
  const conservicePropertyId = utilityDetails?.conservice_property_id
  const portfolioHomeUtilities = utilityDetails?.portfolio_home_utilities ?? []

  return (
    <div>
      <UtilitiesGrid portfolioHomeUtilities={portfolioHomeUtilities} />
      <div>
        <HStack padding={5}>
          <Text>
            <b>Conservice Property ID:</b>
          </Text>
          <Link href={'https://reomanager.conservice.com/Workspace.aspx'} isExternal>
            <Flex direction='row' align='center'>
              {conservicePropertyId} <ArrowTopRightOnSquareIcon className='ml-1 h-3 w-3' />
            </Flex>
          </Link>
        </HStack>
      </div>
    </div>
  )
}

export default UtilityDetailsView
