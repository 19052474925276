import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { v4 as uuidv4 } from 'uuid'
import { UpupDocumentRealEstateAcquisitionsQuery } from '../../graphql/generated'
import { STATUS_MAP, ACQUISITIONS_DOCUMENT_TYPE_MAP } from 'lib/document-manager'
import { UploadContainer } from 'components/Toolkit'
import ResourceSelector from './ResourceSelector'

type RealEstateAcquisition =
  UpupDocumentRealEstateAcquisitionsQuery['real_estate_acquisitions'][number]

type OnUploadVariables = {
  resourceId: string
  resourceType: string
  docPath: string
  docName: string
  docStatus: string
  docType: string
  docNote?: string
}

type Props = {
  defaultResource?: RealEstateAcquisition
  isOpen: boolean
  onUpload: (data: OnUploadVariables) => Promise<void>
  onClose: () => void
}

const UploadModal: FunctionComponent<React.PropsWithChildren<Props>> = ({
  defaultResource,
  isOpen,
  onUpload,
  onClose
}) => {
  const [resource, setResource] = useState<RealEstateAcquisition | undefined>(defaultResource)
  const [uploadId, setUploadId] = useState<string>(uuidv4())
  const [uploadState, setUploadState] = useState<any>()
  const [docName, setDocName] = useState<string>()
  const [docType, setDocType] = useState<string>()
  const [docStatus, setDocStatus] = useState<string>()
  const [docNote, setDocNote] = useState<string>()
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    if (defaultResource?.id) {
      setResource(defaultResource)
    }
  }, [defaultResource])

  const docPath = useMemo(
    () => `real_estate_acquisitions/${resource?.id}/documents/${docType}/${uploadId}`,
    [resource?.id, docType, uploadId]
  )

  const handleClose = () => {
    setResource(defaultResource)
    setUploadId(uuidv4())
    setUploadState(undefined)
    setDocName(undefined)
    setDocType(undefined)
    setDocStatus(undefined)
    setDocNote(undefined)
    onClose()
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={handleClose}>
        <div className='fixed inset-0 bg-black bg-opacity-25' />

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all '>
              <div className='flex flex-col gap-6'>
                <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  Upload document
                </Dialog.Title>
                <ResourceSelector onSelect={setResource} resourceId={defaultResource?.id} />
                <div className='flex flex-col gap-2'>
                  {resource && (
                    <>
                      <div>
                        <div className='text-gray-500'>Resource id</div>
                        <div className='text-gray-900'>{resource?.id}</div>
                      </div>
                      <div>
                        <div className='text-gray-500'>Address</div>
                        <div className='text-gray-900'>
                          {resource?.mls_listing.delivery_line}, {resource?.mls_listing.city},{' '}
                          {resource?.mls_listing.state}
                        </div>
                      </div>
                    </>
                  )}
                  <div>
                    <div className='text-gray-500'>Document type</div>
                    <select
                      className='rounded-md border border-gray-300 px-1 py-1'
                      onChange={(event) => setDocType(event.target.value)}
                      defaultValue=''
                    >
                      <option value='' disabled>
                        Choose...
                      </option>
                      {Object.keys(ACQUISITIONS_DOCUMENT_TYPE_MAP).map((key) => (
                        <option key={key} value={key}>
                          {
                            ACQUISITIONS_DOCUMENT_TYPE_MAP[
                              key as keyof typeof ACQUISITIONS_DOCUMENT_TYPE_MAP
                            ]
                          }
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <div className='text-gray-500'>Document name</div>
                    <input
                      type='text'
                      className='rounded-md border border-gray-300 px-1 py-1'
                      onChange={(event) => setDocName(event.target.value)}
                    />
                  </div>
                  <div>
                    <div className='text-gray-500'>Document status</div>
                    <div className='text-gray-900'>
                      <select
                        className='rounded-md border border-gray-300 px-1 py-1'
                        onChange={(event) => setDocStatus(event.target.value)}
                        defaultValue=''
                      >
                        <option value='' disabled>
                          Choose...
                        </option>
                        {Object.keys(STATUS_MAP).map((key) => (
                          <option key={key} value={key}>
                            {STATUS_MAP[key as keyof typeof STATUS_MAP]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className='text-gray-500'>Document note</div>
                    <div className='text-gray-900'>
                      <textarea
                        className='rounded-md border border-gray-300 px-1 py-1'
                        onChange={(event) => setDocNote(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className='pt-2'>
                    {resource?.id && docType && docName && docStatus && (
                      <UploadContainer
                        onChange={
                          (async (data: any) => {
                            if (data.isLoading) {
                              setProcessing(true)
                            }
                            if (data.downloadUrl) {
                              await onUpload({
                                resourceId: resource?.id,
                                resourceType: 'real_estate_acquisitions',
                                docPath,
                                docName,
                                docStatus,
                                docType,
                                docNote
                              })
                              setProcessing(false)
                              handleClose()
                            }
                            setUploadState(data)
                          }) as any
                        }
                        uploadPath={`/${docPath}`}
                      >
                        <div className='flex flex-col items-center justify-center rounded-md border border-gray-300 px-2 py-2'>
                          {processing && `Uploading... ${uploadState?.file?.name}`}
                          {!processing && (
                            <>
                              <div>Drag and drop, or choose a file.</div>
                              <div>Document will upload when file is selected.</div>
                            </>
                          )}
                        </div>
                      </UploadContainer>
                    )}
                  </div>
                </div>
                <div className='flex flex-row gap-4'>
                  <button
                    type='button'
                    className='whitespace-nowrap rounded bg-gray-200 px-2 py-1 font-medium'
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default UploadModal
