import React from 'react'
import { ChatBubbleBottomCenterText } from 'styled-icons/heroicons-solid'
import format from 'date-fns/format'
import { types } from '@homevest/timelines'
import { TimelineEventContentRenderer, TimelineEventDisplayTemplate } from '../types'
import { formatPersonalDetails } from 'components/CustomerProfile/formats'
import { SC } from 'components/TailwindUIToolkit'

const PreviewContent: TimelineEventContentRenderer<types.SmsTimelineEvent> = ({
  event,
  customer
}) => {
  let from, to, customerNumber, ourNumber
  if (event.direction === 'out') {
    customerNumber = formatPersonalDetails(event.toPhone ?? '')
    ourNumber = formatPersonalDetails(event.fromPhone ?? '')
    from = event.sentByAdminName ?? (ourNumber !== '' ? ourNumber : 'Automated text')
    to = customerNumber
  } else {
    customerNumber = formatPersonalDetails(event.fromPhone ?? '')
    ourNumber = formatPersonalDetails(event.toPhone ?? '')
    from = customer?.firstName + `(${customerNumber})`
    to = event.sentByAdminName ?? ourNumber
  }

  return (
    <div>
      <SC.MessageText>
        <SC.InlineBold>{from}</SC.InlineBold> sent a text to
        <SC.InlineBold> {to}</SC.InlineBold> from {}
        <SC.InlineBoldClickable
          href={event.messageLink}
          onClick={(e) => e.stopPropagation()}
          rel='noopener noreferrer'
          target='_blank'
        >
          {event.provider}
        </SC.InlineBoldClickable>
      </SC.MessageText>
    </div>
  )
}

const ExpandedContent: TimelineEventContentRenderer<types.SmsTimelineEvent> = ({ event }) => {
  // TODO: fill this in with all of the formatted data
  let customerNumber, ourNumber
  if (event.direction === 'out') {
    customerNumber = formatPersonalDetails(event.toPhone ?? '')
    ourNumber = formatPersonalDetails(event.fromPhone ?? '')
  } else {
    customerNumber = formatPersonalDetails(event.fromPhone ?? '')
    ourNumber = formatPersonalDetails(event.toPhone ?? '')
  }

  return (
    <>
      <SC.EventSubheading>Sms Message</SC.EventSubheading>
      <SC.MessageText>{event.content}</SC.MessageText>
      <SC.Subsection>
        <SC.DescriptionText>
          Customer number <SC.InlineBold>{customerNumber}</SC.InlineBold>
        </SC.DescriptionText>
        <SC.DescriptionText>
          Up&Up number <SC.InlineBold>{ourNumber}</SC.InlineBold>
        </SC.DescriptionText>
        <SC.DescriptionText>
          Sent {}
          <SC.InlineBold>{format(new Date(event.eventTimeAt), 'PPppp')}</SC.InlineBold>
        </SC.DescriptionText>
      </SC.Subsection>
    </>
  )
}

export const SmsEventDisplayTemplate: TimelineEventDisplayTemplate<types.SmsTimelineEvent> = {
  icon: ChatBubbleBottomCenterText,
  iconColor: '#6366F1',
  previewContent: PreviewContent,
  expandedContent: ExpandedContent
}
