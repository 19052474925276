import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Text } from '@chakra-ui/react'

export default function CriminalReportOffense({ hasBottomBorder, offense }) {
  const { caseType, description, offenseDate } = offense

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 1.5rem'
        }}
      >
        <div style={{ flex: 1 }}>
          <Text fontSize='xs'>Offense Date</Text>
          <Text marginTop={0}>{offenseDate || 'Not reported'}</Text>
        </div>
        <div style={{ flex: 1 }}>
          <Text fontSize='xs'>Case Type</Text>
          <Text marginTop={0}>{caseType || 'Not reported'}</Text>
        </div>
        <div style={{ flex: 1 }}>
          <Text fontSize='xs'>Description</Text>
          <Text marginTop={0}>{description || 'Not reported'}</Text>
        </div>
      </div>
      {hasBottomBorder && <Divider marginY={'1'} />}
    </>
  )
}

CriminalReportOffense.propTypes = {
  hasBottomBorder: PropTypes.bool,
  offense: PropTypes.object.isRequired
}
