// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React from 'react'
import { Input, InputGroup, InputGroupAddon, Button as StrapButton } from 'reactstrap'
import Select from 'react-select'
import { debounce, startCase } from 'lodash'
import { rentalApplications } from '@homevest/utils'
import DropDownHeader from './DropDownHeader'
import Paginator from './Paginator'
import {
  Order_By as OrderBy,
  ApplicationListDocument,
  UpupApplicationListQueryVariables
} from 'graphql/generated'

import { useQuery } from 'urql'
import { REQUEST_POLICY } from 'constants/urql'

const { RENTAL_APPLICATION_STATUSES } = rentalApplications

type Option = { value: any; label: string }

type OptionState = {
  [id: string]: OptionsType<Option> | Option | string | undefined | null
}

type Props = {
  pages: number
  pageSize: number
  itemCount: number
  pageOffset: number
  onHeaderChange: (key: string, value: any) => void
  onPageChange: (offset: any) => void
  variables: UpupApplicationListQueryVariables
}

const ListHeaders: React.FC<React.PropsWithChildren<Props>> = ({
  pages,
  pageSize,
  itemCount,
  pageOffset,
  onHeaderChange,
  onPageChange,
  variables
}) => {
  const [totalItems, setTotalItems] = React.useState(0)
  const [{ data }, reexecuteQuery] = useQuery({
    query: ApplicationListDocument,
    variables: { where: variables.where },
    requestPolicy: REQUEST_POLICY.NETWORK_ONLY,
    context: React.useMemo(() => ({ suspense: false }), [])
  })

  React.useEffect(() => {
    if (data) {
      setTotalItems(data.rental_applications.length)
    }
  }, [data])

  const [optionState, setOptionState] = React.useState<OptionState>({
    size: { value: 50, label: '50' },
    created: { value: OrderBy.Desc, label: 'Desc' },
    address: '',
    name: ''
  })
  const setAddressSlow = React.useMemo(
    () =>
      debounce((val) => onHeaderChange('address', val), 250, {
        trailing: true
      }),
    [onHeaderChange]
  )
  const setNameSlow = React.useMemo(
    () => debounce((val) => onHeaderChange('name', val), 250, { trailing: true }),
    [onHeaderChange]
  )

  const dropdownSelector = React.useRef('Created')

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingBottom: '0.25rem',
          gridColumnStart: 1,
          gridColumnEnd: 'end'
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: '1rem'
          }}
        >
          <Paginator
            pages={pages}
            pageSize={pageSize}
            itemCount={itemCount}
            pageOffset={pageOffset}
            onPageChange={onPageChange}
          />
          <div>
            {totalItems
              ? `Showing ${pageOffset * pageSize + 1}-${Math.min(
                  (pageOffset + 1) * pageSize,
                  totalItems
                )} of ${totalItems}`
              : `Showing 0 Applications`}
          </div>
        </div>
        <b style={{ marginTop: '0.75rem' }}>Items:</b>
        <div
          style={{
            marginTop: '0.75rem',
            marginLeft: '1rem',
            minWidth: '6rem '
          }}
        >
          <Select
            defaultValue={(optionState.size as Option) ?? null}
            options={[
              { value: 10, label: '10' },
              { value: 25, label: '25' },
              { value: 50, label: '50' },
              { value: 100, label: '100' }
            ]}
            onChange={(selected) => {
              setOptionState({ ...optionState, size: selected })
              onHeaderChange('pageSize', selected?.value ?? null)
            }}
            isSearchable={false}
            isClearable={false}
            autoFocus
          />
        </div>
      </div>
      <DropDownHeader
        header={<b>Address</b>}
        hasFilter={(optionState.address as string).length > 0}
      >
        <InputGroup>
          <Input
            autoFocus
            placeholder='Search...'
            value={optionState.address as string}
            onChange={({ target }) => {
              setOptionState({ ...optionState, address: target.value })
              setAddressSlow(target.value)
            }}
          />
          {(optionState.address as string).length > 0 && (
            <InputGroupAddon addonType='append'>
              <StrapButton
                outline
                onClick={() => {
                  setOptionState({ ...optionState, address: '' })
                  onHeaderChange('address', undefined)
                }}
              >
                &#10005;
              </StrapButton>
            </InputGroupAddon>
          )}
        </InputGroup>
      </DropDownHeader>
      <DropDownHeader header={<b>Region</b>} hasFilter={Boolean(optionState.region)}>
        <Select
          defaultValue={(optionState.region as Option) ?? null}
          options={[
            { value: 'atlanta-ga', label: 'ATL' },
            { value: 'indianapolis-in', label: 'IND' },
            { value: 'los-angeles-ca', label: 'LA' },
            { value: 'st-louis-mo', label: 'STL' }
          ]}
          onChange={(selected) => {
            setOptionState({ ...optionState, region: selected! })
            onHeaderChange('region', selected?.value ?? null)
          }}
          autoFocus
          isClearable
        />
      </DropDownHeader>
      <DropDownHeader
        header={<b>Lead Group</b>}
        hasFilter={(optionState.name as string).length > 0}
      >
        <InputGroup>
          <Input
            autoFocus
            placeholder='Search...'
            value={optionState.name as string}
            onChange={({ target }) => {
              setOptionState({ ...optionState, name: target.value })
              setNameSlow(target.value)
            }}
          />
          {(optionState.name as string).length > 0 && (
            <InputGroupAddon addonType='append'>
              <StrapButton
                outline
                onClick={() => {
                  setOptionState({ ...optionState, name: '' })
                  onHeaderChange('name', undefined)
                }}
              >
                &#10005;
              </StrapButton>
            </InputGroupAddon>
          )}
        </InputGroup>
      </DropDownHeader>

      <DropDownHeader
        header={<b>Occupancy</b>}
        hasFilter={(optionState.status as OptionsType<Option>)?.length > 0}
        minimize={dropdownSelector}
      >
        <Select
          defaultValue={(optionState.occupancy as OptionsType<Option>) ?? null}
          options={[
            { label: 'Desc ', value: OrderBy.DescNullsLast },
            { label: 'Asc ', value: OrderBy.Asc }
          ]}
          onChange={(selected) => {
            setOptionState({
              ...optionState,
              occupancy: selected
            })
            onHeaderChange('occupancy', selected?.value ?? null)
          }}
          autoFocus
          isClearable
        />
      </DropDownHeader>
      <DropDownHeader header={<b>Priority?</b>} hasFilter={Boolean(optionState.priority)}>
        <Select
          defaultValue={(optionState.priority as Option) ?? null}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]}
          onChange={(selected) => {
            setOptionState({ ...optionState, priority: selected })
            onHeaderChange('priority', selected?.value ?? null)
          }}
          autoFocus
          isClearable
        />
      </DropDownHeader>
      <DropDownHeader
        header={<b>Status</b>}
        hasFilter={(optionState.status as OptionsType<Option>)?.length > 0}
      >
        <Select
          defaultValue={(optionState.status as OptionsType<Option>) ?? null}
          options={STATUS_OPTIONS}
          onChange={(selected) => {
            setOptionState({ ...optionState, status: selected })
            const statusList = (selected ?? []).map(({ value }) => value)
            onHeaderChange('status', statusList)
          }}
          autoFocus
          isClearable
          isMulti
        />
      </DropDownHeader>
      <DropDownHeader
        header={<b>Created</b>}
        hasFilter={(optionState.status as OptionsType<Option>)?.length > 0}
        minimize={dropdownSelector}
      >
        <Select
          defaultValue={(optionState.created as OptionsType<Option>) ?? null}
          options={[
            { label: 'Desc ', value: OrderBy.Desc },
            { label: 'Asc ', value: OrderBy.Asc }
          ]}
          onChange={(selected) => {
            setOptionState({
              ...optionState,
              created: selected
            })
            onHeaderChange('created', selected?.value ?? null)
          }}
          autoFocus
          isClearable
        />
      </DropDownHeader>
      <div style={{ textAlign: 'center', padding: '6px 12px' }}>
        <b style={{ whiteSpace: 'nowrap' }}>Open</b>
      </div>
      <div
        style={{
          paddingTop: '0.25rem',
          gridColumnStart: 1,
          gridColumnEnd: 'end',
          borderBottom: '1px solid #eee'
        }}
      />
    </>
  )
}

export default ListHeaders

const STATUS_OPTIONS = Object.values<string>(RENTAL_APPLICATION_STATUSES).map((status) => ({
  value: status,
  label: startCase(status)
}))
