import React, { FunctionComponent, useState } from 'react'
import { startCase } from 'lodash'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { WorkOrdersHistoryFragment, useWorkOrdersFromPortfolioHomeIdQuery } from 'graphql/generated'
import { BaseGrid, DisplayDataType, LeftAlignedDataList } from 'components/TailwindUIToolkit'
import { Sidepeek } from 'ui'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import Error from 'components/Toolkit/Text/Error'
import { formatMoney } from 'lib/numbers'
import { formatNullableDateString } from 'lib/date-time'
import { DocumentsViewAndUploadTool } from 'components/Documents/DocumentsViewAndUploadTool'
import { CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP } from 'lib/document-manager'

export type WorkOrder = WorkOrdersHistoryFragment

const columns: ColDef<WorkOrder>[] = [
  {
    headerName: 'CREATED',
    colId: 'created',
    field: 'created_at',
    sort: 'desc',
    valueFormatter: (v) => formatNullableDateString(v.value),
    flex: 2
  },
  {
    headerName: 'STATUS',
    field: 'status',
    flex: 2,
    valueFormatter: (v) => startCase(v.value),
    cellStyle: { padding: 0 },
    cellRenderer: (params: ICellRendererParams<WorkOrder, string>) =>
      mapContentToBadge(params.value)
  },
  {
    headerName: 'DESCRIPTION',
    field: 'description',
    colId: 'description',
    flex: 3,
    cellStyle: {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      alignItems: 'start',
      paddingTop: '16px',
      paddingBottom: '16px'
    }
  },
  {
    headerName: 'VENDOR',
    field: 'vendor.name',
    colId: 'vendor',
    flex: 2
  }
]

interface GridProps {
  portfolioHomeId: string
}

const Grid: FunctionComponent<React.PropsWithChildren<GridProps>> = ({ portfolioHomeId }) => {
  const [{ data, error }] = useWorkOrdersFromPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  const [drawerTarget, setDrawerTarget] = useState<WorkOrder>()

  if (error) {
    return <Error>{error.message}</Error>
  }

  const workOrders = data?.portfolio_homes_by_pk?.work_orders

  if (!workOrders) {
    return <Error>could not find data</Error>
  }

  return (
    <>
      <BaseGrid<WorkOrder>
        columns={columns}
        rowData={workOrders}
        onRowDoubleClicked={(evt) => {
          setDrawerTarget(evt.data)
        }}
      />
      {drawerTarget && (
        <WorkOrderDrawer
          workOrder={drawerTarget}
          isOpen={!!drawerTarget}
          onClose={() => setDrawerTarget(undefined)}
        />
      )}
    </>
  )
}

const WorkOrderDrawer: React.FC<
  React.PropsWithChildren<{
    workOrder: WorkOrder
    isOpen: boolean
    onClose: () => void
  }>
> = ({ workOrder, isOpen, onClose }) => {
  const displayInfo: DisplayDataType = [
    {
      label: 'created at',
      value: formatNullableDateString(workOrder.created_at)
    },
    { label: 'status', value: mapContentToBadge(workOrder.status) },
    { label: 'vendor', value: workOrder.vendor?.name },
    { label: 'price', value: formatMoney(workOrder.bid_price, 2, '$') },
    { label: 'description', value: workOrder.description },
    {
      label: 'actual completion date',
      value: formatNullableDateString(workOrder.actual_completion_date)
    }
  ]

  return (
    <Sidepeek isOpen={isOpen} onClose={onClose} title='Work Order Details'>
      <LeftAlignedDataList data={displayInfo} />
      <DocumentsViewAndUploadTool
        resourceId={workOrder.id}
        resourceType={'work_orders'}
        resourceTypeMap={CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP}
      />
    </Sidepeek>
  )
}

export default Grid
