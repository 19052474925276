import React, { FunctionComponent, ReactNode, useState } from 'react'
import { Tr } from '@chakra-ui/react'

const RowHover: FunctionComponent<{
  children: (hover: boolean) => ReactNode
}> = ({ children }) => {
  const [hover, setHover] = useState<boolean>(false)
  return (
    <Tr onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {children(hover)}
    </Tr>
  )
}

export default RowHover
