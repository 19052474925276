import { rentalApplications } from '@homevest/utils'
import { Application, STATUS_MAP } from './applications-table-utils'
import React, { FunctionComponent } from 'react'
import { Tag } from 'ui'

const STATUS = rentalApplications.RENTAL_APPLICATION_STATUSES

const StatusTag: FunctionComponent<React.PropsWithChildren<{ status: Application['status'] }>> = ({
  status
}) => {
  let bg = 'grey.100'
  let color = 'grey.800'
  let dotColor = 'grey.500'
  switch (status) {
    case STATUS.COMPLETE: {
      bg = 'green.100'
      color = 'green.800'
      dotColor = 'green.400'
      break
    }
    case STATUS.CANCELED: {
      bg = 'red.100'
      color = 'red.800'
      dotColor = 'red.400'
      break
    }
    case STATUS.PENDING_APPLICATION_REVIEW:
    case STATUS.PENDING_UNDERWRITING:
    case STATUS.PENDING_RENTAL_AGREEMENT_CREATION:
    case STATUS.PENDING_UP_AND_UP_RENTAL_AGREEMENT_COMPLETION: {
      bg = 'blue.100'
      color = 'blue.800'
      dotColor = 'blue.400'
      break
    }
    case STATUS.PENDING_ADDITIONAL_LEASE_INFORMATION:
    case STATUS.PENDING_ADDITIONAL_UNDERWRITING_INFORMATION:
    case STATUS.PENDING_CUSTOMER_RENTAL_AGREEMENT_COMPLETION:
    case STATUS.PENDING_CUSTOMER_PAYMENT:
    case STATUS.PENDING_CUSTOMER_INFORMATION: {
      bg = 'yellow.100'
      color = 'yellow.800'
      dotColor = 'yellow.400'
      break
    }
    case STATUS.PENDING_PROPERTY_PURCHASE:
    case STATUS.HOLD_FOR_NEW_LEASE:
    default:
      break
  }
  return (
    <Tag dot bg={bg} color={color} dotColor={dotColor}>
      {STATUS_MAP[status as keyof typeof STATUS_MAP]}
    </Tag>
  )
}

export default StatusTag
