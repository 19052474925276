import React, { useState } from 'react'
import startCase from 'lodash/startCase'

import {
  Button,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuList,
  Select,
  Stack,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import {
  UserIncomeSource,
  UserIncomeSourceCreateDocumentRequestPayload
} from '@homevest/types/user-income-sources'
import { documents, userIncomeSources } from '@homevest/utils'

import { formatMoney } from 'lib/numbers'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import UserIncomeSourceDetailsDocumentsTable from './DocumentsTable'
import MenuFilePicker from './MenuFilePicker'
import axios from 'lib/axios'
import { StoreState } from 'store'
import { useOverrideIncomeSourceAnnualIncomeMutation } from 'graphql/generated'
import { DotsMenuButton } from 'components/TailwindUIToolkit/Display/DotsMenuButton'
import EmploymentVerification from './EmploymentVerificationDisplay'

const {
  USER_INCOME_SOURCE_TYPES: { BENEFITS, EMPLOYED, SELF_EMPLOYED }
} = userIncomeSources

const { USER_INCOME_SOURCE_DOCUMENT_TYPES, DOCUMENT_TYPES } = documents

const DOCUMENT_TYPES_WITH_AMOUNT = [DOCUMENT_TYPES.PAY_STUB, DOCUMENT_TYPES.TAX_RETURN]

const Source: React.FC<
  React.PropsWithChildren<{
    userIncomeSource: UserIncomeSource
    userId: string
    idx: number
    onUpdate: () => Promise<void>
  }>
> = ({ userIncomeSource, idx, userId, onUpdate }) => {
  let sourceInfo = <></>
  let sourceTitle = `Income Source #${idx + 1} - `
  let sourceSubtitle = `(${startCase(userIncomeSource.type)})`

  switch (userIncomeSource.type) {
    case BENEFITS:
      sourceTitle += ` ${startCase(userIncomeSource.benefits_type)}`
      sourceInfo = (
        <Stack>
          <div>
            Paid <u>{startCase(userIncomeSource.pay_frequency)}</u> at a rate of{' '}
            <u>{formatMoney(userIncomeSource.income_per_period, 2, '$')}</u> per period
          </div>
        </Stack>
      )
      break
    case EMPLOYED:
      sourceTitle += ` ${startCase(userIncomeSource.company_name)}`
      sourceSubtitle = `(Employed - ${startCase(userIncomeSource.wage_type)})`
      sourceInfo = (
        <Stack spacing={5}>
          <Stack>
            {userIncomeSource.is_newly_employed && (
              <>
                <Heading size='sm'>Newly Employed</Heading>
                <div>
                  Offered Rate: {formatMoney(userIncomeSource.offered_rate, 2, '$')} /{' '}
                  {userIncomeSource.wage_type === 'salary' ? 'year' : 'hour'}
                </div>
              </>
            )}
            <div>Pay Frequency: {startCase(userIncomeSource.pay_frequency)}</div>
          </Stack>
          <EmploymentVerification onUpdate={onUpdate} userIncomeSource={userIncomeSource} />
        </Stack>
      )
      break
    case SELF_EMPLOYED:
      sourceTitle += ` ${startCase(userIncomeSource.business_name)}`
      sourceInfo = (
        <Stack>
          <div>Business Name: {userIncomeSource.business_name}</div>
        </Stack>
      )
      break
  }

  // Track if invalidate button just pressed
  const [isInvalidating, setIsInvalidating] = useState(false)

  const hasUnfulfilledEditRequest =
    userIncomeSource.user_edit_requested_at && !userIncomeSource.user_edit_submitted_at

  return (
    <Stack my='3' p='4' bgColor='gray.100' borderRadius='md'>
      <HStack justifyContent='space-between'>
        <Stack>
          <HStack>
            <div>
              <span className='font-semibold'>{sourceTitle}</span>{' '}
              {!!sourceSubtitle && sourceSubtitle}
            </div>
            {hasUnfulfilledEditRequest && <div>{mapContentToBadge('edit_requested')}</div>}
          </HStack>
          <SourceGrossAnnualIncome incomeSource={userIncomeSource} onUpdate={onUpdate} />
        </Stack>
        <DotsMenuButton>
          <Stack>
            <OverrideSourceGrossAnnualIncome
              incomeSourceId={userIncomeSource.id}
              onUpdate={onUpdate}
            />
            {!hasUnfulfilledEditRequest && (
              <RequestSourceEditButton
                userIncomeSourceId={userIncomeSource.id}
                onAction={onUpdate}
              />
            )}
            <Menu>
              <MenuButton as={Button} size='sm' variant='outline' colorScheme='red'>
                Invalidate
              </MenuButton>
              <MenuList p='2'>
                <Button
                  w='full'
                  size='xs'
                  colorScheme='red'
                  isDisabled={isInvalidating}
                  isLoading={isInvalidating}
                  onClick={async () => {
                    setIsInvalidating(true)
                    await axios.put(`/admin/user_income_sources/${userIncomeSource.id}/invalidate`)
                    await onUpdate()
                    setIsInvalidating(false)
                  }}
                >
                  Confirm Invalidation
                </Button>
              </MenuList>
            </Menu>
          </Stack>
        </DotsMenuButton>
      </HStack>
      {sourceInfo}
      <Stack pt={4}>
        <HStack w='full' justify='space-between'>
          <Heading size='sm'>Documents</Heading>
          <HStack>
            <AddDocumentForSource
              userIncomeSourceId={userIncomeSource.id}
              onAction={onUpdate}
              userId={userId}
            />
            <RequestSupplementalDocsForSource
              userId={userId}
              userIncomeSourceId={userIncomeSource.id}
              onAction={onUpdate}
            />
          </HStack>
        </HStack>
        {userIncomeSource.documents.length > 0 && (
          <UserIncomeSourceDetailsDocumentsTable
            documents={userIncomeSource.documents}
            onUpdate={onUpdate}
            unfulfilledUserDocRequests={userIncomeSource.unfulfilled_user_document_requests}
          />
        )}
      </Stack>
    </Stack>
  )
}

const SourceGrossAnnualIncome = ({
  incomeSource: { source_annual_income, annual_income_overridden_at }
}: {
  incomeSource: UserIncomeSource
  onUpdate: () => Promise<void>
}) => {
  const sourceAnnualIncome = formatMoney(source_annual_income, 2, '$')

  return (
    <div>
      Gross Annual Income: <b>{sourceAnnualIncome}</b>{' '}
      {annual_income_overridden_at && (
        <span className='text-sm text-neutral-600 underline'>
          set by admin on {new Date(annual_income_overridden_at).toLocaleDateString()}
        </span>
      )}
    </div>
  )
}

const OverrideSourceGrossAnnualIncome = ({
  incomeSourceId,
  onUpdate
}: {
  incomeSourceId: string
  onUpdate: () => Promise<void>
}) => {
  const [overrideAmount, setOverrideAmount] = useState<number | ''>('')

  const adminId = useSelector((state: StoreState) => state.admin.id)

  const [{ fetching }, mutate] = useOverrideIncomeSourceAnnualIncomeMutation()

  return (
    <Menu placement='right'>
      <MenuButton as={Button} variant='outline' size='sm' colorScheme='yellow'>
        Override Amount
      </MenuButton>
      <MenuList>
        <HStack px={2}>
          <InputGroup size='sm'>
            <InputLeftAddon>$</InputLeftAddon>
            <Input
              type='number'
              value={overrideAmount}
              onChange={(e) =>
                setOverrideAmount(e.target.valueAsNumber === 0 ? '' : e.target.valueAsNumber)
              }
              placeholder='Override Amount'
            />
          </InputGroup>
          <Button
            size='sm'
            colorScheme='yellow'
            isLoading={fetching}
            onClick={async () => {
              await mutate({
                adminId,
                annualIncome: overrideAmount,
                userIncomeSourceId: incomeSourceId,
                now: new Date()
              })
              await onUpdate()
            }}
            isDisabled={!overrideAmount || fetching}
          >
            Submit
          </Button>
        </HStack>
      </MenuList>
    </Menu>
  )
}

const RequestSupplementalDocsForSource = ({
  userIncomeSourceId,
  userId,
  onAction
}: {
  userIncomeSourceId: string
  userId: string
  onAction?: () => Promise<void>
}) => {
  const handleRequest = async () => {
    await axios
      .post(`/admin/user_income_sources/${userIncomeSourceId}/request_supplemental_documents`, {
        user_document_requests: [
          {
            document_type: requestedDocType,
            instructions: requestNote,
            created_by_admin_id: id,
            user_id: userId
          }
        ]
      } as UserIncomeSourceCreateDocumentRequestPayload)
      .catch((err: any) =>
        toast({
          title: 'Error requesting document',
          description: `An error occurred while requesting the document, please contact Engineering. ${
            err?.message ? err.message : ''
          }`,
          status: 'error',
          position: 'bottom-right'
        })
      )

    if (onAction) {
      await onAction()
    }
  }

  const [requestNote, setRequestNote] = useState('')
  const [requestedDocType, setRequestedDocType] = useState<string>()
  const { mutate, isLoading } = useMutation({ mutationFn: handleRequest })
  const { id } = useSelector((state: StoreState) => state.admin)
  const toast = useToast()

  return (
    <Menu>
      <MenuButton as={Button} variant='outline' size='xs' colorScheme='yellow'>
        Request Doc
      </MenuButton>
      <MenuList>
        <Stack px={2}>
          <Textarea
            size='sm'
            value={requestNote}
            onChange={(e) => setRequestNote(e.target.value)}
            placeholder='Note...'
          />
          <Select
            size='sm'
            value={requestedDocType}
            onChange={(e) => setRequestedDocType(e.target.value)}
          >
            <option value=''>Select Document Type</option>
            {Object.values(USER_INCOME_SOURCE_DOCUMENT_TYPES).map((type) => (
              <option key={type} value={type}>
                {startCase(type)}
              </option>
            ))}
          </Select>
          <Button
            size='sm'
            colorScheme='yellow'
            isLoading={isLoading}
            onClick={() => {
              mutate()
            }}
            isDisabled={!requestedDocType || !requestNote || isLoading}
          >
            Submit
          </Button>
        </Stack>
      </MenuList>
    </Menu>
  )
}

const AddDocumentForSource = ({
  userIncomeSourceId,
  onAction,
  userId
}: {
  userIncomeSourceId: string
  onAction?: () => Promise<void>
  userId: string
}) => {
  const [docType, setDocType] = useState('')
  const [amount, setAmount] = useState<number | ''>()
  const [fileState, setFileState] = useState<any>({})

  const toast = useToast()
  const { id: adminId } = useSelector((state: StoreState) => state.admin)

  const createDocument = async () => {
    await axios
      .post(`/admin/user_income_sources/${userIncomeSourceId}/documents`, {
        type: docType,
        name: docType,
        upload_path: fileState.uploadPath,
        uploaded_by_admin_id: adminId,
        amount: amount === '' ? undefined : amount
      })
      .catch((err: any) =>
        toast({
          title: 'Error adding document',
          description: `An error occurred while adding the document, please contact Engineering. ${
            err?.message ? err.message : ''
          }`,
          status: 'error',
          position: 'bottom-right'
        })
      )

    if (onAction) {
      await onAction()
    }
  }

  const { mutate, isLoading } = useMutation({ mutationFn: createDocument })

  const needsAmount = (DOCUMENT_TYPES_WITH_AMOUNT as string[]).includes(docType)
  const canSubmit = docType && (!needsAmount || amount) && fileState.downloadUrl

  return (
    <Menu>
      <MenuButton as={Button} size='xs' colorScheme='teal' variant='outline'>
        + Add Doc
      </MenuButton>
      <MenuList>
        <Stack px={2}>
          <Select size='sm' value={docType} onChange={(e) => setDocType(e.target.value)}>
            <option value=''>Select Document Type</option>
            {Object.values(USER_INCOME_SOURCE_DOCUMENT_TYPES).map((type) => (
              <option key={type} value={type}>
                {startCase(type)}
              </option>
            ))}
          </Select>
          {needsAmount && (
            <InputGroup size='sm'>
              <InputLeftAddon>$</InputLeftAddon>
              <Input
                type='number'
                value={amount}
                onChange={(e) =>
                  setAmount(e.target.valueAsNumber === 0 ? '' : e.target.valueAsNumber)
                }
                placeholder='Amount'
              />
            </InputGroup>
          )}
          <MenuFilePicker
            uploadPrefix={`users/${userId}/documents/${docType}`}
            onClear={() => setFileState({})}
            onUpload={(data) => {
              setFileState(data)
            }}
          />
          <Button
            size='sm'
            colorScheme='teal'
            isLoading={isLoading}
            onClick={() => {
              mutate()
            }}
            isDisabled={!canSubmit || isLoading}
          >
            Submit
          </Button>
        </Stack>
      </MenuList>
    </Menu>
  )
}

const RequestSourceEditButton = ({
  userIncomeSourceId,
  onAction
}: {
  userIncomeSourceId: string
  onAction: () => Promise<void>
}) => {
  const [isRequesting, setIsRequesting] = useState(false)
  const [editInstructions, setEditInstructions] = useState('')

  const canRequest = editInstructions.length > 0

  return (
    <Menu placement='left'>
      <MenuButton as={Button} size='sm' variant='outline' colorScheme='orange'>
        Request User Edit
      </MenuButton>
      <MenuList p='2'>
        <Stack spacing={3}>
          <Textarea
            size='sm'
            value={editInstructions}
            onChange={(e) => setEditInstructions(e.target.value)}
            placeholder='Enter edit instructions for the user...'
          />
          <Button
            w='full'
            size='xs'
            colorScheme='orange'
            isDisabled={!canRequest || isRequesting}
            isLoading={isRequesting}
            onClick={async () => {
              setIsRequesting(true)
              await axios.put(
                `/admin/user_income_sources/${userIncomeSourceId}/request_user_edit`,
                {
                  user_edit_instructions: editInstructions
                }
              )
              await onAction()
              setIsRequesting(false)
            }}
          >
            Confirm Request
          </Button>
        </Stack>
      </MenuList>
    </Menu>
  )
}

export default Source
