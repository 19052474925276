import React from 'react'
import { Container } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import UsersCreateForm from '../../components/Users/CreateForm'

export default function UsersCreateScreen() {
  const history = useHistory()

  return (
    <Container className='tasks-container'>
      <h3>Create a new customer</h3>

      <UsersCreateForm history={history} />
    </Container>
  )
}
