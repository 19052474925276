import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Button
} from '@chakra-ui/react'
import { startCase } from 'lodash'
import { useSelector } from 'react-redux'

import { StoreState } from 'store'
import axios from 'lib/axios'
import {
  useUpupInsertUserDocumentRequestsMutation,
  useUpupLeadGroupUsersWithDocsByRentalAppIdQuery,
  UpupLeadGroupUsersWithDocsByRentalAppIdQuery
} from '../../graphql/generated'
import DocumentList from './DocumentList'
import RequestMenu from './RequestMenu'

type Props = {
  rentalAppId?: string
  filterUserId?: string
  isOpen: boolean
  onClose: () => void | Promise<void>
  onConfirm: () => void | Promise<void>
}

type LeadGroupUser = UpupLeadGroupUsersWithDocsByRentalAppIdQuery['lead_group_users'][number]
type NewRequest = { userId: string; type: string; instructions: string }

const SupplementalModal: FunctionComponent<React.PropsWithChildren<Props>> = ({
  rentalAppId,
  filterUserId,
  isOpen,
  onClose,
  onConfirm
}) => {
  const admin = useSelector((store: StoreState) => store.admin)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [lguForCreate, setLguForCreate] = useState<LeadGroupUser>()
  const [newRequests, setNewRequests] = useState<{
    [id: string]: NewRequest[]
  }>({})

  const [_, createNewRequests] = useUpupInsertUserDocumentRequestsMutation()

  const [{ data, error }, refetchDocuments] = useUpupLeadGroupUsersWithDocsByRentalAppIdQuery({
    variables: { rentalAppId },
    pause: !rentalAppId
  })

  useEffect(() => {
    if (isOpen) {
      refetchDocuments({ requestPolicy: 'network-only' })
    }
  }, [isOpen, refetchDocuments])

  if (error) {
    return <div>Error</div>
  }

  const valid = Object.keys(newRequests).length > 0

  const handleCreateRequest = (type: string, instructions: string) => {
    const newRequest = { userId: lguForCreate?.user.id, type, instructions }
    setNewRequests((requests) => ({
      ...requests,
      [lguForCreate?.id]: [...(requests[lguForCreate?.id] ?? []), newRequest]
    }))
    setLguForCreate(undefined)
  }

  const handleClose = async () => {
    setLguForCreate(undefined)
    setNewRequests({})
    await onClose()
  }

  const handleConfirmNewRequests = async () => {
    setIsSubmitting(true)
    const flattened = Object.values(newRequests).reduce((acc, bucket) => [...acc, ...bucket], [])
    try {
      const documentRequests = flattened.map((request) => ({
        created_by_admin_id: admin.id,
        document_type: request.type,
        instructions: request.instructions,
        user_id: request.userId
      }))

      await createNewRequests({
        requests: documentRequests
      })

      await axios.post(
        `/admin/rental_applications/${rentalAppId}/handle_supplemental_document_requests`,
        {
          document_requests: documentRequests
        }
      )
    } catch (err) {
      alert('Something went wrong, please try again')
    }
    setNewRequests({})
    refetchDocuments({ requestPolicy: 'network-only' })
    setIsSubmitting(false)
    await onConfirm()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent minWidth={'fit-content'}>
          <ModalHeader>Request Supplemental Documents</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className='flex flex-col gap-4'>
              {(data?.lead_group_users ?? [])
                .filter((lgu) => (filterUserId ? lgu.user.id === filterUserId : true))
                .map((lgu) => {
                  const documents = getDocumentsToDisplay(
                    lgu.user.documents,
                    lgu.user.user_document_requests,
                    newRequests[lgu.id] ?? []
                  )
                  return (
                    <div key={lgu.id} className='flex flex-col gap-2'>
                      <div className='flex flex-row items-center gap-2'>
                        <div className='flex flex-1 flex-row items-center gap-2'>
                          <div className='font-semibold'>
                            {lgu.user.first_name} {lgu.user.last_name}
                          </div>
                          <div className='rounded-full bg-gray-100 px-2 py-1 text-sm font-medium'>
                            {startCase(lgu.type)}
                          </div>
                        </div>
                        <RequestMenu
                          onCreate={handleCreateRequest}
                          onOpen={() => setLguForCreate(lgu)}
                          onClose={() => setLguForCreate(undefined)}
                        />
                      </div>
                      <DocumentList documents={documents} hideSize={filterUserId ? 25 : 10} />
                    </div>
                  )
                })}
            </div>
            <div className='mt-4 flex flex-row gap-4'>
              <Button
                colorScheme='green'
                isDisabled={!valid || isSubmitting}
                onClick={handleConfirmNewRequests}
              >
                Confirm
              </Button>
              <Button colorScheme='gray' onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SupplementalModal

const getDocumentsToDisplay = (
  documents: LeadGroupUser['user']['documents'],
  requests: LeadGroupUser['user']['user_document_requests'],
  newRequests: NewRequest[]
) => {
  const requestsFulfilledMap: { [id: string]: true } = requests
    .filter((req) => req.document_id && req.created_by_admin_id)
    .reduce((acc, req) => ({ ...acc, [req.document_id]: true }), {})

  return [
    ...documents.map((doc) => ({
      ...doc,
      is_supplemental: requestsFulfilledMap[doc.id]
    })),
    ...requests
      .filter((req) => !req.document_id && req.created_by_admin_id)
      .map(({ id, document_type, created_at, instructions }) => ({
        id,
        type: document_type,
        status: 'requested',
        instructions,
        created_at,
        is_supplemental: true
      })),
    ...newRequests.map(({ userId, type, instructions }) => ({
      id: `${userId}-${type}-${instructions}`,
      type,
      status: 'to_be_requested',
      is_supplemental: true
    }))
  ]
}
