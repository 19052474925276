import React, { FunctionComponent } from 'react'

import * as SC from 'components/TailwindUIToolkit/StyledComponents'

import axios from 'lib/axios'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'

import { hasCapability } from 'lib/admin-perms'
import { capabilities } from '@homevest/utils'
import { useToast } from '@chakra-ui/react'
const { APPLICATION_UNDERWRITER } = capabilities.CAPABILITY_TYPES

const resetDepositForUser = async (userId: string) => {
  const response = await axios.post(
    `/admin/users/${userId}/reset_pending_microdeposit_verification`
  )
  return response.data.success as boolean
}

const ResetMicrodeposit: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { userId } = useParams<{ userId: string }>()

  const toast = useToast()
  const admin = useSelector((store: StoreState) => store.admin)

  const canResetMicrodeposit = hasCapability(admin, APPLICATION_UNDERWRITER)

  return canResetMicrodeposit ? (
    <>
      <SC.DropDownMenuItem
        onClick={() =>
          resetDepositForUser(userId)
            .then((success) => {
              toast({
                title: success ? 'Microdeposits reset!' : 'User not pending microdeposits.',
                status: success ? 'success' : 'warning',
                duration: 5000,
                isClosable: true,
                position: 'top'
              })
            })
            .catch((err) => {
              toast({
                title: `An error occurred while resetting microdeposits. ${err.message}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
              })
            })
        }
      >
        Reset Microdeposits
      </SC.DropDownMenuItem>
    </>
  ) : null
}

export default ResetMicrodeposit
