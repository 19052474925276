import React, { FunctionComponent } from 'react'
import { UpUpUserTimelineByUserIdQuery } from 'graphql/generated'
import { buildTimeline } from 'lib/build-timeline'
import TimelineItem from './TimelineItem'
import { TimelineItemsContainer } from './StyledComponents'

type UserTimelineProps = {
  queryData: UpUpUserTimelineByUserIdQuery | undefined
  ascending?: boolean
}

// a react component for displaying in sorted chronological order
// all timeline events in the given query data

const UserTimeline: FunctionComponent<React.PropsWithChildren<UserTimelineProps>> = ({
  queryData,
  ascending
}) => {
  if (!queryData) {
    return <div>No id given...</div>
  }

  const timelineItems = buildTimeline(queryData, ascending)

  return (
    <TimelineItemsContainer>
      {timelineItems.map((item, i) => (
        <TimelineItem interaction={item} key={i} />
      ))}
    </TimelineItemsContainer>
  )
}

export default UserTimeline
