import React, { useState } from 'react'

import { Sidepeek } from 'ui'
import { ContentSectionCard, ActionButton } from 'components/TailwindUIToolkit'
import Grid from 'components/TailwindUIToolkit/Grid/DocumentsGrid'
import { UploadDocumentPanel } from 'components/Documents/UploadDocumentPanel'
import { CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP } from 'lib/document-manager'

const ProjectDocuments = ({ constructionProjectId }: { constructionProjectId: string }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const onClose = () => setDrawerOpen(false)
  const uploadButton = (
    <ActionButton onClick={() => setDrawerOpen(true)}>
      + Upload
      <Sidepeek isOpen={drawerOpen} onClose={onClose}>
        <UploadDocumentPanel
          resourceId={constructionProjectId}
          resourceType='construction_project'
          typeMap={CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP}
          setClosed={onClose}
        />
      </Sidepeek>
    </ActionButton>
  )
  return (
    <ContentSectionCard title='Documents' staticSize action={uploadButton}>
      <Grid resourceIds={[constructionProjectId]} columnIdsToExclude={['resource_type']} />
    </ContentSectionCard>
  )
}

export default ProjectDocuments
