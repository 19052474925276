import React, { FunctionComponent, useState } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Checkbox,
  Stack,
  Text
} from '@chakra-ui/react'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'

import axios from 'lib/axios'

type PowerUserToolsComponentPropTypes = {
  isInitialPaymentsCharger: boolean
  userId: string
}

const PowerUserToolsWrapper: FunctionComponent<
  React.PropsWithChildren<PowerUserToolsComponentPropTypes>
> = (props) => (
  <ContentSectionCard title={'Power User Tools'} padding>
    <PowerUserTools {...props} />
  </ContentSectionCard>
)

const PowerUserTools: FunctionComponent<
  React.PropsWithChildren<PowerUserToolsComponentPropTypes>
> = ({ isInitialPaymentsCharger, userId }) => {
  const [isCharging, setIsCharging] = useState<boolean>(false)
  const [isChargingConfirmed, setIsChargingConfirmed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState<string>('')

  async function doCharge() {
    try {
      setIsCharging(true)
      const { data } = await axios.post('/admin/payments/initial_payment', {
        userId
      })
      setSuccessMessage('Charge complete! ' + JSON.stringify(data))
    } catch (err: any) {
      setErrorMessage(err.message)
    } finally {
      setIsCharging(false)
      setIsChargingConfirmed(false)
    }
  }
  return (
    <SC.SmallBoxContainer>
      {isInitialPaymentsCharger && (
        <Stack spacing={3}>
          {errorMessage && (
            <Alert status='error'>
              <AlertIcon />
              <AlertTitle>Whoops!</AlertTitle>
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}
          {successMessage && (
            <Alert status='success'>
              <AlertIcon />
              <AlertTitle>Hurrah!</AlertTitle>
              <AlertDescription>{successMessage}</AlertDescription>
            </Alert>
          )}
          <Text as='b'>Initial Payments</Text>
          <Text>
            You can charge an initial payment here. Please be sure the user has a rental application
            and has not been charged yet.
          </Text>
          <Checkbox
            isChecked={isChargingConfirmed}
            isDisabled={isCharging}
            onChange={(e: any) => {
              setIsChargingConfirmed(e.target.checked)
            }}
          >
            Are you sure you want to charge an initial payment?
          </Checkbox>
          <Button color='red' isDisabled={isCharging || !isChargingConfirmed} onClick={doCharge}>
            Charge Initial Payment!
          </Button>
        </Stack>
      )}
    </SC.SmallBoxContainer>
  )
}

export default PowerUserToolsWrapper
