const ALL_SDRS = [
  'Brendan Effinger',
  'Brett Green',
  'Ben Wong',
  'Ryan Brown',
  'Ricky Davis',
  'Kitty Kan',
  'Mina Hughes',
  'Emily Bleiberg',
  'Alessandra Huamani',
  'Caroline Lee',
  'Sergio Perkovic'
]

export default function reducer(state = ALL_SDRS, action) {
  switch (action.type) {
    default:
      return state
  }
}
