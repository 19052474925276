import React from 'react'
import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import { utilities } from '@homevest/utils'

const { UTILITIES_RESPONSIBLE_PARTIES } = utilities

type UtilityResponsibilitySelectPropTypes = {
  id: string
  value: string
  setValue: (value: string) => void
  showLabel: boolean
}

const UtilityResponsibilitySelect: React.FC<
  React.PropsWithChildren<UtilityResponsibilitySelectPropTypes>
> = ({ id, value, setValue, showLabel }) => {
  return (
    <FormControl isRequired>
      {showLabel && <FormLabel htmlFor={id}>Default Responsible Party</FormLabel>}
      <Select
        id={id}
        placeholder='Select responsibility...'
        value={value}
        onChange={(event) => setValue(event.target.value)}
      >
        <option value={UTILITIES_RESPONSIBLE_PARTIES.UPANDUP}>Up&Up</option>
        <option value={UTILITIES_RESPONSIBLE_PARTIES.TENANT}>Tenant</option>
      </Select>
    </FormControl>
  )
}

export default UtilityResponsibilitySelect
