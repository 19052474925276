import React, { useState } from 'react'
import { LeftAlignedDataList } from 'components/TailwindUIToolkit'
import { startCase } from 'lodash'
import { format } from 'date-fns'
import { FindDocumentsByResourceIdsQuery } from 'graphql/generated'
import { DocumentDownloadButton } from 'components/Documents/DownloadDocumentButton'
import { Button, Input, Select, Spinner, Stack } from '@chakra-ui/react'
import { STATUS_MAP } from 'lib/document-manager'
import { useUpupUpdateDocumentMutation } from 'graphql/generated'

type Document = FindDocumentsByResourceIdsQuery['documents'][number]

export const DocumentDisplayData: React.FC<React.PropsWithChildren<{ doc: Document }>> = ({
  doc
}) => {
  const [manageState, setManageState] = useState(false)
  const [docName, setDocName] = useState<string>(doc.friendly_name)
  const [docStatus, setDocStatus] = useState<string>(doc.status)

  const docNameInput = (
    <Input
      size='sm'
      type='text'
      value={docName}
      onChange={(event) => setDocName(event.target.value)}
    />
  )
  const docStatusInput = (
    <Select
      size='sm'
      defaultValue={docStatus}
      onChange={(event) => setDocStatus(event.target.value)}
    >
      {Object.keys(STATUS_MAP).map((key) => (
        <option key={key} value={key}>
          {STATUS_MAP[key as keyof typeof STATUS_MAP]}
        </option>
      ))}
    </Select>
  )

  const [{ fetching }, updateDocument] = useUpupUpdateDocumentMutation()

  const handleSave = () => {
    updateDocument({
      documentId: doc.id,
      friendlyName: docName,
      status: docStatus
    }).then(() => setManageState(false))
  }

  const clearInputStates = () => {
    setDocName(doc.friendly_name)
    setDocStatus(doc.status)
  }

  const toggleManageState = () => {
    clearInputStates()
    setManageState(!manageState)
  }

  return (
    <Stack>
      <LeftAlignedDataList
        data={[
          {
            label: 'resource type',
            value: startCase(doc.resource_type)
          },
          {
            label: 'document name',
            value: manageState ? docNameInput : docName
          },
          {
            label: 'uploaded by',
            value: `${doc.uploaded_by_admin?.first_name} ${doc.uploaded_by_admin?.last_name}`
          },
          {
            label: 'upload date',
            value: format(new Date(doc.created_at), 'PP @ p')
          },
          {
            label: 'doc type',
            value: startCase(doc.type)
          },
          {
            label: 'status',
            value: manageState ? docStatusInput : startCase(docStatus)
          },
          {
            label: 'storage',
            value: startCase(doc.external_source)
          },
          {
            label: 'reviewed by',
            value:
              doc.reviewed_by_admin &&
              `${doc.reviewed_by_admin.first_name} ${doc.reviewed_by_admin.last_name}`
          },
          {
            label: 'review date',
            value: doc.reviewed_at && format(new Date(doc.reviewed_at), 'PP @ p')
          },
          {
            label: 'review notes',
            value: doc.review_notes
          }
        ]}
      />
      <Stack direction='row'>
        <DocumentDownloadButton documentId={doc.id} />
        <Button onClick={toggleManageState}>{manageState ? 'Cancel' : 'Manage'}</Button>
        {manageState ? (
          <Button colorScheme='blue' onClick={handleSave}>
            {fetching ? <Spinner /> : 'Save'}
          </Button>
        ) : null}
      </Stack>
    </Stack>
  )
}
