import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import CreateRentalAgreementHistoryForm from './Form'

export default function CreateRentalAgreementHistoryModal({
  close,
  isOpen,
  rentalId
}: {
  close: () => void
  isOpen: boolean
  rentalId: string
}) {
  return (
    <>
      <Modal toggle={close} isOpen={isOpen}>
        <ModalHeader toggle={close}>Create a Rental Agreement History</ModalHeader>
        <ModalBody>
          <CreateRentalAgreementHistoryForm onSubmit={close} rentalId={rentalId} />
        </ModalBody>
      </Modal>
    </>
  )
}
