import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react'
import { formatMoney } from 'lib/numbers'
import React, { FC } from 'react'
import { RentalLedger } from './Ledger'

interface CashoutValidAlertProps {
  ledger: RentalLedger
}

const CashoutValidAlert: FC<React.PropsWithChildren<CashoutValidAlertProps>> = ({ ledger }) => {
  const outstandingWalletContributionBalance = Object.entries(ledger.summaries).find(
    ([_, ledgerSummary]) => ledgerSummary.liabilityTypeName === 'Wallet Contribution'
  )?.[1]?.balance

  if (!outstandingWalletContributionBalance) {
    return null
  }

  return (
    <Alert status='error' mb={4}>
      <AlertIcon />
      <AlertTitle>Cannot cashout tenant!</AlertTitle>
      <AlertDescription>
        Please reverse the {formatMoney(outstandingWalletContributionBalance, 2, '$')} outstanding
        wallet balance{' '}
      </AlertDescription>
    </Alert>
  )
}

export default CashoutValidAlert
