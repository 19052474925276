import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { startCase } from 'lodash'
import {
  Select,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  FormHelperText,
  FormErrorMessage
} from '@chakra-ui/react'
import {
  OccupancyInspectionStatus,
  OccupancyInspectionResult,
  OccupancyInspectionBulkApiResponse
} from '@homevest/types/occupancy-inspections'
import {
  OCCUPANCY_INSPECTION_STATUSES,
  OCCUPANCY_INSPECTION_RESULTS
} from '@homevest/utils/occupancy-inspections'

import axios from 'lib/axios'
import { formatForDatetimeInput, formatUTCDate, formatDateWithTime } from 'lib/date-time'
import { useContactsByProjectIdQuery } from 'graphql/generated'
import {
  OccupancyInspectionFormDataInput,
  OccupancyInspectionFormData,
  ValidationResponse
} from 'lib/occupancy-inspections'

const OccupancyInspectionForm = ({
  initialData,
  validator,
  onSubmit
}: {
  initialData: OccupancyInspectionFormDataInput
  validator: (data: OccupancyInspectionFormData) => ValidationResponse
  onSubmit: (data: OccupancyInspectionFormData) => void
}) => {
  const projectId = initialData.construction_project_id
  const formData: OccupancyInspectionFormData = {
    construction_project_id: initialData.construction_project_id,
    previous_occupancy_inspection_id: initialData.previous_occupancy_inspection_id ?? null,
    primary_municipality_contact_id: initialData.primary_municipality_contact_id ?? null,
    status: initialData.status ?? null,
    result: initialData.result ?? null,
    inspection_at: initialData.inspection_at ?? null,
    certificate_effective_date: initialData.certificate_effective_date ?? null,
    certificate_expiration_date: initialData.certificate_expiration_date ?? null,
    municipality_contacted_at: initialData.municipality_contacted_at ?? null,
    application_submitted_at: initialData.application_submitted_at ?? null,
    payment_submitted_at: initialData.payment_submitted_at ?? null,
    payment_confirmed_at: initialData.payment_confirmed_at ?? null,
    occupant_signature_deadline: initialData.occupant_signature_deadline ?? null,
    occupant_signature_at: initialData.occupant_signature_at ?? null
  }

  const [inspectionData, setInspectionData] = useState<OccupancyInspectionFormData>(formData)
  const [errors, setErrors] = useState<ValidationResponse['errors']>(
    {} as ValidationResponse['errors']
  )

  const [{ data: contactsData }] = useContactsByProjectIdQuery({
    variables: {
      projectId: projectId
    }
  })

  const handleSubmit = () => {
    const { isValid, errors: currErrors } = validator(inspectionData)
    if (!isValid) {
      setErrors(currErrors)
      return
    }
    onSubmit(inspectionData)
  }

  const { data: previousOccupancyInspections } = useQuery({
    queryKey: ['getPreviousOccupancyInspections', projectId],
    queryFn: async () => {
      const response = await axios.get<OccupancyInspectionBulkApiResponse>(
        `/admin/construction_projects/${projectId}/previous_occupancy_inspections`
      )
      return response.data?.data
    }
  })

  const BaseFormField = ({
    field,
    label,
    isRequired,
    isDisabled,
    children
  }: {
    field: keyof OccupancyInspectionFormData
    label: string
    isRequired: boolean
    isDisabled?: boolean
    children: React.ReactNode
  }) => (
    <FormControl isRequired={isRequired} isInvalid={!!errors[field]} isDisabled={isDisabled}>
      <FormLabel>{label}</FormLabel>
      {children}
      <FormErrorMessage>{errors[field]}</FormErrorMessage>
    </FormControl>
  )

  const DateTimeFormField = ({
    field,
    label,
    isRequired
  }: {
    field: keyof OccupancyInspectionFormData
    label: string
    isRequired: boolean
  }) => (
    <BaseFormField field={field} label={label} isRequired={isRequired}>
      <Input
        value={formatForDatetimeInput(inspectionData[field])}
        onChange={(e) =>
          setInspectionData({
            ...inspectionData,
            [field]: e.target.value ? new Date(e.target.value) : null
          })
        }
        type='datetime-local'
      />
    </BaseFormField>
  )

  return (
    <Stack spacing={4}>
      <BaseFormField field='status' label='Status' isRequired>
        <Select
          value={inspectionData.status ?? ''}
          onChange={(e) =>
            setInspectionData({
              ...inspectionData,
              status: (e.target.value || null) as OccupancyInspectionStatus | null
            })
          }
        >
          <option value=''>Select a status</option>
          {Object.values(OCCUPANCY_INSPECTION_STATUSES).map((status) => (
            <option key={status} value={status}>
              {startCase(status)}
            </option>
          ))}
        </Select>
      </BaseFormField>
      <BaseFormField field='result' label='Result' isRequired={false}>
        <Select
          value={inspectionData.result ?? ''}
          onChange={(e) =>
            setInspectionData({
              ...inspectionData,
              result: (e.target.value || null) as OccupancyInspectionResult | null
            })
          }
        >
          <option value=''>Select a status</option>
          {Object.values(OCCUPANCY_INSPECTION_RESULTS).map((result) => (
            <option key={result} value={result}>
              {startCase(result)}
            </option>
          ))}
        </Select>
      </BaseFormField>
      <BaseFormField
        field='primary_municipality_contact_id'
        label='Primary Municipality Contact'
        isRequired={false}
      >
        <Select
          value={inspectionData.primary_municipality_contact_id ?? ''}
          onChange={(e) =>
            setInspectionData({
              ...inspectionData,
              primary_municipality_contact_id: e.target.value || null
            })
          }
        >
          <option value=''>Select a contact</option>
          {contactsData?.municipality_contacts.map((contact) => (
            <option key={contact.id} value={contact.id}>
              {contact.name}
            </option>
          ))}
        </Select>
        <FormHelperText>
          Not seeing the right contact? We are working on a way to add more.
        </FormHelperText>
      </BaseFormField>
      <DateTimeFormField field='inspection_at' label='Inspection At' isRequired={false} />
      <DateTimeFormField
        field='certificate_effective_date'
        label='Certificate Effective Date'
        isRequired={false}
      />
      <DateTimeFormField
        field='certificate_expiration_date'
        label='Certificate Expiration Date'
        isRequired={false}
      />
      <DateTimeFormField
        field='municipality_contacted_at'
        label='Municipality Contacted Att'
        isRequired={false}
      />
      <DateTimeFormField
        field='application_submitted_at'
        label='Application Submitted At'
        isRequired={false}
      />
      <DateTimeFormField
        field='payment_submitted_at'
        label='Payment Submitted At'
        isRequired={false}
      />
      <DateTimeFormField
        field='payment_confirmed_at'
        label='Payment Confirmed At'
        isRequired={false}
      />
      <DateTimeFormField
        field='occupant_signature_deadline'
        label='Occupant Signature Deadline'
        isRequired={false}
      />
      <DateTimeFormField
        field='occupant_signature_at'
        label='Occupant Signature At'
        isRequired={false}
      />
      <BaseFormField
        field='previous_occupancy_inspection_id'
        label='Previous Occupancy Inspection'
        isRequired={false}
        isDisabled={previousOccupancyInspections?.length === 0}
      >
        {previousOccupancyInspections?.length === 0 ? (
          <Select>
            <option value=''>No previous occupancy inspections</option>
          </Select>
        ) : (
          <>
            <FormHelperText>
              PROJECT TYPE - Project Start Date - OI Status - OI Inspection At
            </FormHelperText>
            <Select
              value={inspectionData.previous_occupancy_inspection_id ?? ''}
              onChange={(e) =>
                setInspectionData({
                  ...inspectionData,
                  previous_occupancy_inspection_id: e.target.value || null
                })
              }
              className='mt-2'
            >
              <option value=''>Select an occupancy inspection</option>
              {previousOccupancyInspections?.map((oi) => (
                <option key={oi.id} value={oi.id}>
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {oi.project.type.toUpperCase()} -{' '}
                    {formatUTCDate(oi.project.start_date?.toString(), 'MM/dd/yy')} -{' '}
                    {startCase(oi.status)}
                    {oi.inspection_at
                      ? ` - ${formatDateWithTime(oi.inspection_at?.toString())}`
                      : ''}
                  </span>
                </option>
              ))}
            </Select>
          </>
        )}
      </BaseFormField>
      <Button onClick={() => handleSubmit()} colorScheme='teal'>
        Submit
      </Button>
    </Stack>
  )
}

export default OccupancyInspectionForm
