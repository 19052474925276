import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { reStandardizeAddress, standardizeAddress } from './addressStandardizationFunctions'
import { csvToJsonArr } from './csv_helpers'
import { daysBetween, removeBackSlashR, UPUP, combineTranformations, arrayToCSV } from './helpers'
import {
  configureLlcData,
  configureLlcToFunds,
  configureProperties,
  configureRealEsateAcquisitions,
  memo_to_gl_account_id
} from './mappings'
import { calculateCostPerParty, correctLiabilityTimes, removeNonliableParties } from './proration'
import {
  BillDates,
  Liability,
  Prorateable,
  ProratableLineItem,
  TypedUtilityCharge,
  BuildiumUtilityOutput,
  rental_liabilities_utility_chargeback,
  TypedProperties,
  TypedRealEstatAcquisitions
} from './utility_chargeback.types'

async function writeUiltityOutputToHasura(
  {
    buildium: buildium_csv,
    rental_liabilities,
    transformations,
    original_upload_url,
    original_upload,
    rental_liabilities_csv,
    potential_duplicates_csv,
    known_liabilities_do_not_cover_full_bill_csv,
    unknown_memos
  }: any,
  saveProcessedUtilityBill: Function
) {
  const handleCreate = async (): Promise<void> => {
    const result = await saveProcessedUtilityBill({
      buildium_csv,
      rental_liabilities,
      rental_liabilities_csv,
      potential_duplicates_csv,
      original_upload_url,
      original_upload,
      known_liabilities_do_not_cover_full_bill_csv,
      unknown_memos,
      transformations
    })
    if (result.error !== undefined) {
      alert(`Please Slack James Philhower that ${result.error}`)
    }
  }

  handleCreate()
}

export const parseUtilityBillData = async (
  utilityBillData: string,
  propertyData: any,
  saveProcessedUtilityBill: any
) => {
  let utilityBill: Object[] = []
  let utilityBillHeaders: any[] = []

  try {
    const { headers, result } = csvToJsonArr(removeBackSlashR(utilityBillData))
    utilityBill = result
    utilityBillHeaders = headers
  } catch (err) {
    alert(`Please Slack James Philhower that ${err}`)
    return
  }

  // Buildable Outputs
  const amountsDue: {
    [liabilityPartyType: string]: {
      [liableParty: string]: ProratableLineItem[]
    }
  } = {}

  const lineItemsWithParseErrors: any[] = []
  const transformationDict: { [reference_number: string]: {} } = {}
  const unmappedCharges = {}

  let llcToFund: any
  let llcData: any
  let ownedProperties: TypedProperties
  let realEstateAcquisitionData: TypedRealEstatAcquisitions

  try {
    const { llcs, llc_properties, funds, fund_llcs, real_estate_acquisitions } = propertyData
    llcToFund = configureLlcToFunds(funds, fund_llcs)
    llcData = configureLlcData(llcs)
    ownedProperties = configureProperties(llc_properties)
    realEstateAcquisitionData = configureRealEsateAcquisitions(real_estate_acquisitions)
  } catch (err) {
    console.error('unable to parse db data')
  }

  const typedLineItems = utilityBill.map(typeLineItem)

  const billCountDict: { [compositeKey: string]: TypedUtilityCharge[] } = typedLineItems.reduce(
    (acc, lineItem) => {
      if (isNaN(lineItem.total_amount_in_dollars)) {
        console.log('LINE', lineItem)
        if (lineItemsWithParseErrors.length === 0) {
          lineItemsWithParseErrors.push(Object.keys(lineItem.original))
        }

        lineItemsWithParseErrors.push(Object.values(lineItem.original))

        return acc
      }
      const id = lineItem.referenceId

      if (!_.get(acc, id)) {
        _.set(acc, id, [])
      }

      _.set(acc, id, [..._.get(acc, id), lineItem])

      return acc
    },
    {}
  )

  const duplicateLineItems = Object.values(billCountDict)
    .filter((lineItems) => {
      return lineItems.length > 1
    })
    .flat()
    .map((lineItem) => Object.values(lineItem.original))

  if (duplicateLineItems.length) {
    duplicateLineItems.unshift(utilityBillHeaders)
  }

  console.log(duplicateLineItems.join('\n'))

  const dedupedLineItems = Object.values(billCountDict)
    .filter((lineItems) => {
      return lineItems.length === 1
    })
    .flat()

  const configuredUtilityBillLineItems = dedupedLineItems.map((lineItem) => {
    const {
      street_address,
      serviceStart,
      serviceEnd,
      reference_number,
      amount_of_allocation_in_dollars,
      memo,
      lineItemDueDate,
      total_amount_in_dollars,
      number_of_allocations_in_connected_line_items
    } = lineItem

    const address = getStandardizedAddress(street_address)
    const billDates: BillDates = {
      serviceStart,
      serviceEnd
    }
    const daysInBillingPeriod = daysBetween(billDates.serviceStart, billDates.serviceEnd)
    const costPerDay = amount_of_allocation_in_dollars / daysInBillingPeriod
    const raw_line_item = lineItem.original

    let transformations = combineTranformations({
      changes: {
        standardizedAddress: address,
        billDates,
        daysInBillingPeriod,
        costPerDay
      },
      previousTransforms: { raw_line_item }
    })

    const proratableLineItemBase: ProratableLineItem = {
      memo,
      amount: amount_of_allocation_in_dollars,
      daysPaidFor: daysInBillingPeriod,
      start: serviceStart,
      end: serviceEnd,
      reference_number,
      buildium: {
        lineItemDueDate,
        reference_number,
        total_amount_in_dollars,
        number_of_allocations_in_connected_line_items
      },
      address
    }
    const liabilityBase: Liability = {
      reference_number,
      startDate: billDates.serviceStart,
      endDate: billDates.serviceEnd,
      id: 'id for error llc',
      id_origin: 'errors',
      daysLiable: daysInBillingPeriod,
      amountDueInDollars: amount_of_allocation_in_dollars
    }

    return {
      liabilityBase,
      proratableLineItemBase,
      address,
      lineItem,
      transformations,
      billDates,
      daysInBillingPeriod,
      costPerDay
    }
  })

  const lineItemsWithownedPropertiesFound = configuredUtilityBillLineItems.filter(({ address }) => {
    return ownedProperties[address]
  })

  const lineItemsAndPropertyData = lineItemsWithownedPropertiesFound.map((vals) => {
    let { address, transformations } = vals

    const { propertyOwnership, propertyOccupancy } = ownedProperties[address]

    const ownership: Prorateable[] = propertyOwnership.map((owner) => {
      return {
        startDate: owner.start_date,
        endDate: owner.end_date,
        id: owner.owner_id,
        id_origin: 'llc_id'
      }
    })

    const occupancies: Prorateable[] = propertyOccupancy.map((occupant) => {
      return {
        startDate: occupant.occupancy_date,
        endDate: occupant.final_liability_date,
        id: occupant.rental_id,
        id_origin: 'rental_id'
      }
    })

    transformations = combineTranformations({
      previousTransforms: transformations,
      changes: { occupancies, ownership }
    })

    return { ...vals, ownership, occupancies, transformations }
  })

  const relevantPropertyOwnership = lineItemsAndPropertyData.map((vals) => {
    let { ownership, occupancies, billDates, costPerDay, transformations, liabilityBase } = vals

    const [ownerLiabilities, occupantLiabilities] = [ownership, occupancies].map((liableGroup) => {
      const liableParties: Prorateable[] = removeNonliableParties({
        potentiallyLiableParties: liableGroup,
        bill: billDates
      })
      const liablePartiesWithRelevantDates: Prorateable[] = correctLiabilityTimes({
        liableParties,
        bill: billDates
      })
      const charges: Liability[] = calculateCostPerParty({
        arr: liablePartiesWithRelevantDates,
        liabilityBase,
        costPerDay
      })
      return { liableParties, liablePartiesWithRelevantDates, charges }
    })

    transformations = combineTranformations({
      previousTransforms: transformations,
      changes: { ownerLiabilities, occupantLiabilities }
    })

    return { ...vals, ownerLiabilities, occupantLiabilities, transformations }
  })

  relevantPropertyOwnership.forEach(
    ({
      lineItem: { amount_of_allocation_in_dollars, referenceId },
      proratableLineItemBase,
      ownerLiabilities,
      occupantLiabilities,
      transformations,
      daysInBillingPeriod,
      liabilityBase
    }) => {
      ;[ownerLiabilities, occupantLiabilities].forEach(({ charges }) => {
        charges.forEach((payer) => {
          const liablePartyType = payer.id_origin
          const ProratableLineItem: ProratableLineItem = {
            ...proratableLineItemBase,
            amount: payer.amountDueInDollars,
            daysPaidFor: payer.daysLiable,
            start: payer.startDate,
            end: payer.endDate
          }
          addToAmountsDue({
            liablePartyType,
            payer,
            lineItem: ProratableLineItem
          })
        })
      })

      const { amountStillDueInBill, daysStillDueInBill } = calculateLiabilityOutsideKnownOwnership(
        ownerLiabilities,
        amount_of_allocation_in_dollars,
        daysInBillingPeriod
      )

      if (amountStillDueInBill > 0) {
        handleLineItemForDatesOutsideOwnership()
      }

      transformationDict[referenceId] = transformations

      function calculateLiabilityOutsideKnownOwnership(
        ownerLiabilities: any,
        amount_of_allocation_in_dollars: any,
        daysInBillingPeriod: any
      ) {
        return ownerLiabilities.charges.reduce(
          ({ daysStillDueInBill, amountStillDueInBill }: any, currentCharge: any) => {
            daysStillDueInBill -= currentCharge.daysLiable
            amountStillDueInBill -= currentCharge.amountDueInDollars

            return { daysStillDueInBill, amountStillDueInBill }
          },
          {
            amountStillDueInBill: amount_of_allocation_in_dollars,
            daysStillDueInBill: daysInBillingPeriod
          }
        )
      }

      function handleLineItemForDatesOutsideOwnership() {
        const errorLineItem: ProratableLineItem = {
          ...proratableLineItemBase,
          amount: amountStillDueInBill,
          daysPaidFor: daysStillDueInBill
        }

        const errorLiableParty: Liability = {
          ...liabilityBase,
          amountDueInDollars: amountStillDueInBill,
          daysLiable: daysStillDueInBill
        }

        transformations = combineTranformations({
          previousTransforms: transformations,
          changes: {
            knownLiabilitiesDoNotCoverBill: {
              errorLiableParty,
              errorLineItem
            }
          }
        })
        addToAmountsDue({
          liablePartyType: 'errors',
          payer: errorLiableParty,
          lineItem: errorLineItem
        })
      }
    }
  )

  const almostOwnedProperties = configuredUtilityBillLineItems
    .filter(({ address }) => {
      return !ownedProperties[address] && realEstateAcquisitionData[address]
    })
    .map((vals) => {
      const { address } = vals
      return {
        llc_id: realEstateAcquisitionData[address].llc_id,
        ...vals
      }
    })

  almostOwnedProperties.forEach(({ llc_id, proratableLineItemBase, liabilityBase }) => {
    const formattedAcquisition: Liability = {
      ...liabilityBase,
      id: llc_id ?? 'No llc assigned',
      id_origin: 'llc_id'
    }

    const lineItem: ProratableLineItem = {
      ...proratableLineItemBase,
      memo: 'DeadDealCost',
      address: 'Canceled Acquisition'
    }

    addToAmountsDue({
      liablePartyType: 'llc_id',
      lineItem,
      payer: formattedAcquisition
    })
  })

  const propertiesNotFound = configuredUtilityBillLineItems
    .filter(({ address }) => {
      return !ownedProperties[address] && !realEstateAcquisitionData[address]
    })
    .reduce((acc, { address, lineItem }) => {
      _.set(acc, address, lineItem)
      return acc
    }, {})

  const buildium: BuildiumUtilityOutput[] = Object.entries(amountsDue.llc_id)
    .map(([llc_id, charges]) => {
      return charges.map((charge) => {
        if (!_.get(memo_to_gl_account_id, charge.memo)) {
          if (!_.get(unmappedCharges, charge.memo)) {
            _.set(unmappedCharges, charge.memo, 0)
          }
          _.set(unmappedCharges, charge.memo, _.get(unmappedCharges, charge.memo) + 1)
        }

        const street =
          ownedProperties[charge.address]?.street ||
          realEstateAcquisitionData[charge.address]?.display_line_1

        const fund = llcToFund[llc_id]

        const llc = llcData[llc_id]
        const buildiumPropertyName = `${fund} - ${llc} - ${street}`
        return {
          'Bill Date': moment(charge.buildium.lineItemDate).format('MM/DD/YYYY'),
          'Bill Due Date': moment(charge.buildium.lineItemDueDate).format('MM/DD/YYYY'),
          Vendor: '"Homevest, Inc - Utilities"',
          'Reference Number': charge.reference_number,
          Memo: charge.memo,
          'Total Bill Amount': +charge.buildium.total_amount_in_dollars.toFixed(2),
          '# of Itemized allocations':
            charge.buildium.number_of_allocations_in_connected_line_items,
          'Itemized Allocation Property Name': buildiumPropertyName,
          'Itemized Allocation Amount': +charge.amount.toFixed(2),
          'Itemized Allocation GL Expense Account': _.get(memo_to_gl_account_id, charge.memo)
        } as BuildiumUtilityOutput
      })
    })
    .flat()
  const buildiumCSV = arrayToCSV(buildium)

  const rental_liabilities: rental_liabilities_utility_chargeback[] = Object.entries(
    amountsDue.rental_id ?? {}
  )
    .map(([rental_id, charges]) => {
      return charges.map((charge) => {
        return {
          Rental_UUID: rental_id,
          Liability_Type: `Utilities - ${charge.memo}`,
          Price: +charge.amount.toFixed(2),
          Service_Start: moment(+charge.start).format('MM/DD/YYYY'),
          Service_End: moment(+charge.end).format('MM/DD/YYYY'),
          Reference_Number: charge.reference_number
        } as rental_liabilities_utility_chargeback
      })
    })
    .flat()

  const known_liabilities_do_not_cover_full_bill_csv = amountsDue.error
    ? arrayToCSV(
        Object.entries(amountsDue.error)
          .map(([rental_id, charges]) => {
            return charges.map((charge) => {
              return {
                Rental_UUID: rental_id,
                Liability_Type: `Utilities - ${charge.memo}`,
                Price: +charge.amount.toFixed(2),
                Service_Start: moment(+charge.start).format('MM/DD/YYYY'),
                Service_End: moment(+charge.end).format('MM/DD/YYYY')
              }
            })
          })
          .flat()
      )
    : ''

  writeUiltityOutputToHasura(
    {
      buildium: buildiumCSV,
      rental_liabilities: JSON.stringify(rental_liabilities),
      transformations: JSON.stringify(transformationDict),
      original_upload_url: `wait for v2 ${Math.random()}`,
      original_upload: utilityBillData,
      potential_duplicates_csv: duplicateLineItems.length ? duplicateLineItems.join('\n') : '',
      rental_liabilities_csv: arrayToCSV(rental_liabilities),
      known_liabilities_do_not_cover_full_bill_csv,
      unknown_memos: 'no unknowns'
    },
    saveProcessedUtilityBill
  )
  logResults()
  return (
    <>
      {[
        duplicateLineItems.length ||
        lineItemsWithParseErrors.length ||
        Object.keys(unmappedCharges).length
          ? [
              <h1>Errors Processing Last Upload</h1>,
              <h3>These line items are NOT included in Buildium or Rental Liabilities CSVs</h3>
            ]
          : null,

        duplicateLineItems.length
          ? [
              <h2>Potential Duplicates</h2>,

              duplicateLineItems.map((item) => {
                return <p>{`${JSON.stringify(item, null, 2)}`}</p>
              })
            ]
          : null,

        lineItemsWithParseErrors.length
          ? [
              <h2>
                The below line items are not parsable, maybe due to the amount not being a number
              </h2>,

              lineItemsWithParseErrors.map((item) => {
                return <p>{`${JSON.stringify(item, null, 2)}`}</p>
              })
            ]
          : null,

        Object.keys(unmappedCharges).length
          ? [<h2>These Line</h2>, <p>{JSON.stringify(unmappedCharges, null, 2)}</p>]
          : null
      ]}
    </>
  )

  function logResults() {
    ;[
      ['Buildium Data: ', buildium],
      ['Rental Liabilities: ', rental_liabilities]
    ].forEach((output) => {
      console.log('\n\n', output[0])
      const numberEntries = output[1].length
      console.log(`Writing ${numberEntries} rows`)
      console.table(output[1].slice(0, 5))
    })

    console.log('Property could not be matched: ')
    console.log(JSON.stringify(propertiesNotFound, null, 2))

    console.log('Potential Duplicate Bills: ')
    console.log(JSON.stringify(duplicateLineItems, null, 2))
    console.log('unmappedCharges', unmappedCharges)
  }

  function typeLineItem(line_item: any) {
    const [serviceStart, serviceEnd] = line_item['Service Period']
      .split('-')
      .map((date: string) => moment(date, 'MM/DD/YYYY'))

    const data = {
      lineItemDate: UPUP.date(line_item['Bill Date'], { addDay: false }),
      lineItemDueDate: UPUP.date(line_item['Bill Due Date'], {
        addDay: false
      }),
      reference_number: line_item['Reference Number'],
      memo: line_item['Memo'],
      total_amount_in_dollars: parseFloat(line_item['Total Bill Amount']),
      number_of_allocations_in_connected_line_items: parseInt(
        line_item['# of Itemized Allocations']
      ),
      street_address: line_item['Itemized Allocation Property Name'],
      amount_of_allocation_in_dollars: parseFloat(line_item['Itemized Allocation Amount']),
      gl_account_id: line_item['Itemized Allocation GL Expense Account'],
      description: line_item['Itemized Allocation Description'],
      external_property_id: line_item['Property ID'],
      serviceStart: UPUP.date(serviceStart, { addDay: false }),
      serviceEnd: UPUP.date(serviceEnd, { addDay: false })
    }

    const referenceId = `${data.external_property_id}_${data.reference_number}_${data.memo}`

    return { ...data, referenceId, original: line_item } as TypedUtilityCharge
  }

  function addToAmountsDue({
    liablePartyType,
    payer,
    lineItem
  }: {
    liablePartyType: string
    payer: Liability
    lineItem: ProratableLineItem
  }) {
    if (!amountsDue[liablePartyType]) {
      amountsDue[liablePartyType] = {}
    }
    if (!amountsDue[liablePartyType][payer.id]) {
      amountsDue[liablePartyType][payer.id] = []
    }
    amountsDue[liablePartyType][payer.id].push(lineItem)
  }

  function getStandardizedAddress(address: string) {
    let standardizedAddress = standardizeAddress(address)

    if (!ownedProperties[standardizedAddress] && !realEstateAcquisitionData[standardizedAddress]) {
      standardizedAddress = reStandardizeAddress(standardizedAddress)
    }
    return standardizedAddress
  }
}
