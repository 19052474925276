import React, { useState } from 'react'

import {
  Stack,
  Heading,
  Divider,
  Text,
  HStack,
  Link,
  IconButton,
  Input,
  Center,
  Spinner,
  useToast
} from '@chakra-ui/react'
import {
  PhoneIcon,
  EnvelopeIcon,
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  CheckIcon,
  XMarkIcon
} from '@heroicons/react/20/solid'

import { useUpdateVendorLatchelIdMutation } from 'graphql/generated'
import { ContentSectionCard, LeftAlignedDataList } from 'components/TailwindUIToolkit'
import { formatPhoneFromDatabase } from 'lib/formatting'
import { Vendor } from 'lib/vendors'
import VendorDetails from './VendorDetails'

type LatchelVendorUser = Vendor['latchel_vendor_users'][number]

const VendorInformation = ({ vendor, onUpdate }: { vendor: Vendor; onUpdate: () => void }) => {
  const latchelCategories = (vendor.latchel_vendor_categories || [])
    .map((category) => category.latchel_category.name)
    .join(', ')

  // If there are already vendor users associated with this Latchel ID,
  // updating the Latchel ID would break the FK constraint on latchel_vendor_users
  // and possibly mess up data that was synced previously. We should really
  // only edit the ID if there is no Latchel data associated with the vendor yet.
  const canUpdateLatchelId = vendor.latchel_vendor_users.length === 0

  return (
    <Stack spacing={5}>
      <VendorDetails vendor={vendor} onUpdate={onUpdate} />
      <ContentSectionCard title='Latchel Info'>
        <div className='px-6 py-3'>
          {vendor.latchel_id && (
            <div className='mb-3'>
              <Link
                isExternal
                href={`https://app.latchel.com/admin/vendor-company/${vendor.latchel_id}`}
              >
                <HStack>
                  <Text>View in Latchel</Text>
                  <ArrowTopRightOnSquareIcon className='h-4 w-4' />
                </HStack>
              </Link>
            </div>
          )}
          <LeftAlignedDataList
            data={[
              {
                label: 'Vendor Company ID',
                value: (
                  <EditableLatchelId
                    vendorId={vendor.id}
                    latchelId={vendor.latchel_id}
                    canEdit={canUpdateLatchelId}
                    onEditSubmit={onUpdate}
                  />
                ),
                labelFormatter: (label) => label
              },

              { label: 'Categories', value: latchelCategories || '-' }
            ]}
          />
          <Divider />
          <Heading size='sm' fontWeight={'semibold'} py='3'>
            Users
          </Heading>
          <Stack spacing={3}>
            {!vendor.latchel_vendor_users.length && <Text>No users found</Text>}
            {vendor.latchel_vendor_users.map((latchelVendorUser) => (
              <LatchelUserDisplay key={latchelVendorUser.id} {...latchelVendorUser} />
            ))}
          </Stack>
        </div>
      </ContentSectionCard>
    </Stack>
  )
}

const EditableLatchelId = ({
  vendorId,
  latchelId,
  canEdit,
  onEditSubmit
}: {
  vendorId: string
  latchelId?: number | null
  canEdit?: boolean
  onEditSubmit: () => void
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedLatchelId, setEditedLatchelId] = useState(String(latchelId ?? ''))

  const toast = useToast()
  const [{ fetching }, updateLatchelId] = useUpdateVendorLatchelIdMutation()

  const handleSubmit = async () => {
    const result = await updateLatchelId({
      latchelId: Number(editedLatchelId),
      vendorId
    })

    if (result.error) {
      const errorMessage = result.error.message.includes('Uniqueness violation')
        ? 'This Latchel ID is already associated with another vendor'
        : result.error.message

      toast({
        title: 'Error updating Latchel ID',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })
      return
    }

    onEditSubmit()
    toast({
      title: 'Latchel ID Updated!',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'bottom-right'
    })
    setIsEditing(false)
  }

  const handleCancel = () => {
    setEditedLatchelId(String(latchelId))
    setIsEditing(false)
  }

  if (fetching) {
    return (
      <Center>
        <Spinner size='sm' color='teal' />
      </Center>
    )
  }

  if (!isEditing) {
    return (
      <HStack>
        <Text>{latchelId || '-'}</Text>
        {canEdit && (
          <IconButton
            size='sm'
            variant='ghost'
            aria-label='Edit Latchel ID'
            icon={<PencilIcon className='h-4 w-4' />}
            onClick={() => setIsEditing(true)}
          />
        )}
      </HStack>
    )
  }

  return (
    <HStack>
      <Input
        size='sm'
        value={editedLatchelId}
        type='number'
        onChange={(evt) => setEditedLatchelId(evt.target.value)}
      />
      <IconButton
        size='sm'
        aria-label='Save'
        colorScheme={'teal'}
        variant='outline'
        icon={<CheckIcon className={'h-4 w-4'} />}
        onClick={handleSubmit}
      />
      <IconButton
        size='sm'
        aria-label='Cancel'
        colorScheme={'red'}
        variant='outline'
        icon={<XMarkIcon className={'h-4 w-4'} />}
        onClick={handleCancel}
      />
    </HStack>
  )
}

const LatchelUserDisplay = (latchelVendorUser: LatchelVendorUser) => {
  const contactPhone = latchelVendorUser.contact_phone
  const contactEmail = latchelVendorUser.contact_email

  return (
    <div className='rounded-md border bg-slate-100 p-2'>
      <div className='flex flex-col gap-1'>
        <Link
          mb={1}
          href={`https://app.latchel.com/admin/contractor/${latchelVendorUser.latchel_id}`}
          isExternal
        >
          <Text fontWeight={'semibold'}>{latchelVendorUser.name}</Text>
        </Link>
        <HStack>
          <PhoneIcon className='h-4 w-4' />
          {contactPhone ? (
            <Link href={`tel:${contactPhone}`}>
              <Text>{formatPhoneFromDatabase(contactPhone)}</Text>
            </Link>
          ) : (
            <Text>-</Text>
          )}
        </HStack>
        <HStack>
          <EnvelopeIcon className='h-4 w-4' />
          {contactEmail ? (
            <Link href={`mailto:${contactEmail}`}>
              <Text>{contactEmail}</Text>
            </Link>
          ) : (
            <Text>-</Text>
          )}
        </HStack>
      </div>
    </div>
  )
}

export default VendorInformation
