import React, { useState } from 'react'
import _, { omit } from 'lodash'
import {
  Table,
  Thead,
  Tr,
  Th,
  TableContainer,
  Button,
  Spinner,
  Center,
  VStack,
  HStack,
  Tbody,
  Tfoot,
  Text
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { requiredLeaseDetails } from '@homevest/utils'
import { VehicleWithId } from '@homevest/types/rental-applications'

import axios from 'lib/axios'
import EditVehiclesRow from './EditVehiclesRow'
import AddVehicleRow from './AddVehicleRow'
import SkipValidationSwitch from '../../Common/SkipValidationSwitch'
import { isVehicleValid } from 'lib/required-lease-details'

const { MAX_COUNTS, REQUIRED_LEASE_DETAIL_TYPES } = requiredLeaseDetails

type IndexedVehicles = Record<string, VehicleWithId>

type EditVehiclesProps = {
  rentalApplicationId: string
  currentVehicles: VehicleWithId[]
  exitEditMode: () => void
}

const EditVehicles: React.FC<React.PropsWithChildren<EditVehiclesProps>> = ({
  currentVehicles,
  rentalApplicationId,
  exitEditMode
}) => {
  const indexedVehicles = currentVehicles.reduce((acc, vehicle) => {
    acc[vehicle.id] = vehicle
    return acc
  }, {} as IndexedVehicles)

  const [vehicles, setVehicles] = useState<IndexedVehicles>(indexedVehicles)
  const [skipValidation, setSkipValidation] = useState(false)

  const canSubmit =
    skipValidation || Object.values(vehicles).every((vehicle) => isVehicleValid(vehicle))

  const vehicleCount = Object.keys(vehicles).length
  const maxVehicleCount = MAX_COUNTS[REQUIRED_LEASE_DETAIL_TYPES.VEHICLES]
  const canAddVehicle = vehicleCount < maxVehicleCount

  const queryClient = useQueryClient()

  const { isLoading, mutate: submitVehicleDetails } = useMutation({
    mutationFn: async (vehicles: IndexedVehicles) => {
      await axios.post(`/admin/rental_applications/${rentalApplicationId}/required_lease_details`, {
        type: 'vehicles',
        data: Object.values(vehicles),
        skipValidation
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchRequiredLeaseDetails'] })
      exitEditMode()
    }
  })

  const editVehicle = (vehicleId: string, updatedVehicle: VehicleWithId) => {
    setVehicles({
      ...vehicles,
      [vehicleId]: updatedVehicle
    })
  }

  const removeVehicle = (vehicleId: string) => {
    setVehicles(omit(vehicles, [vehicleId]))
  }

  const addVehicle = (vehicle: VehicleWithId) => {
    setVehicles({
      ...vehicles,
      [vehicle.id]: vehicle
    })
  }

  if (isLoading) {
    return (
      <Center marginY={3}>
        <Spinner colorScheme='teal' />
      </Center>
    )
  }

  return (
    <>
      <VStack>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Make</Th>
                <Th>Model</Th>
                <Th>Year</Th>
                <Th>License</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.values(vehicles).map((vehicle) => (
                <EditVehiclesRow
                  key={vehicle.id}
                  vehicle={vehicle}
                  removeVehicle={removeVehicle}
                  editVehicle={editVehicle}
                  skipValidation={skipValidation}
                />
              ))}
            </Tbody>
            <Tfoot>
              <AddVehicleRow
                addVehicle={addVehicle}
                canAddVehicle={canAddVehicle}
                skipValidation={skipValidation}
              />
            </Tfoot>
          </Table>
        </TableContainer>
        {!canAddVehicle && (
          <Text color={'red.500'}>
            A maximum of {maxVehicleCount} vehicles can be listed on the lease!
          </Text>
        )}
        <SkipValidationSwitch
          skipValidation={skipValidation}
          setSkipValidation={setSkipValidation}
        />
        <HStack width='100%' paddingX={3} spacing={3}>
          <Button
            width='100%'
            onClick={() => submitVehicleDetails(vehicles)}
            colorScheme='teal'
            disabled={!canSubmit}
          >
            Save
          </Button>
          <Button width='100%' variant='outline' onClick={exitEditMode} colorScheme='red'>
            Cancel
          </Button>
        </HStack>
      </VStack>
    </>
  )
}

export default EditVehicles
