import React from 'react'
import { Stack, Skeleton, Heading } from '@chakra-ui/react'
import { CreditCardIcon, FlagIcon, HomeIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline'
import startCase from 'lodash/startCase'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { rentalApplications } from '@homevest/utils'

import {
  computeVirtualRecommendationSummary,
  getMaxRentAtDepositMultiple,
  getAdditionalIncomeNeededAtDepositMultiple,
  UNDERWRITING_RECOMMENDATION_SUMMARIES
} from 'lib/rental-application-underwriting'
import { formatMoney } from 'lib/numbers'
import { useRentalAppQualification } from './useRentalAppQualification'

const { getDepositMultipleForRentalProgramType } = rentalApplications

const recommendationBadgeFormatFn = (recommendation: string) => {
  if (recommendation === UNDERWRITING_RECOMMENDATION_SUMMARIES.APPROVE_WITH_3X_DEPOSIT) {
    return 'Approve With 3x Deposit'
  } else if (recommendation === UNDERWRITING_RECOMMENDATION_SUMMARIES.APPROVE_WITH_2X_DEPOSIT) {
    return 'Approve With 2x Deposit'
  }

  return startCase(recommendation)
}

const RecommendationPanel = ({ rentalApplicationId }: { rentalApplicationId: string }) => {
  const { data: recommendation, error } = useRentalAppQualification(rentalApplicationId)

  if (error) {
    return null
  }

  if (!recommendation) {
    return <Skeleton height='100px' />
  }

  const virtualRecommendationSummary = computeVirtualRecommendationSummary(recommendation)
  const { standardDepositMultiple, additionalDepositMultiple } =
    getDepositMultipleForRentalProgramType(recommendation.rental_program_type)
  const RENT_MULTIPLES = [standardDepositMultiple, additionalDepositMultiple]

  const [maxRentAtStandardDeposit, maxRentAtAdditionalDeposit] = RENT_MULTIPLES.map((rm) =>
    getMaxRentAtDepositMultiple(recommendation, rm)
  )
  const [additionalIncomeNeededAtStandardDeposit, additionalIncomeNeededAtAdditionalDeposit] =
    RENT_MULTIPLES.map((rm) => getAdditionalIncomeNeededAtDepositMultiple(recommendation, rm))

  const showFlags =
    recommendation.flags.length > 0 || recommendation.disqualification_reasons.length > 0

  const flagBadges = (
    <Stack flexWrap='wrap'>
      {[
        ...recommendation.disqualification_reasons.map((reason) => (
          <div key={reason}>
            {mapContentToBadge(reason, { colorOverride: 'Red', size: 'sm', dot: true })}
          </div>
        )),
        ...recommendation.flags.map((flag) => (
          <div key={flag}>
            {mapContentToBadge(flag, { colorOverride: 'Yellow', size: 'sm', dot: true })}
          </div>
        ))
      ]}
    </Stack>
  )

  return (
    <div>
      <Heading size='md' mb={4}>
        Recommendation
      </Heading>
      <div className='flex flex-col gap-4 md:flex-row'>
        <div className='flex w-full flex-col items-center justify-center gap-4 md:w-1/2'>
          <div className='w-fit'>
            {mapContentToBadge(virtualRecommendationSummary, {
              dot: false,
              size: 'lg',
              formatFn: recommendationBadgeFormatFn
            })}
          </div>
          <div className='flex flex-col items-center'>
            <Heading size='2xl'>
              {!!maxRentAtStandardDeposit ? formatMoney(maxRentAtStandardDeposit, 0, '$') : 'N/A'}
            </Heading>
            <div className='text-xl font-bold'>Max Approved Rent</div>
            {!!maxRentAtAdditionalDeposit &&
              maxRentAtAdditionalDeposit !== maxRentAtStandardDeposit && (
                <div className='text-base text-neutral-700'>
                  <span className='font-semibold'>
                    {formatMoney(maxRentAtAdditionalDeposit, 0, '$')}
                  </span>{' '}
                  w/ {additionalDepositMultiple}x Deposit
                </div>
              )}
          </div>
        </div>
        <div className='flex-grow justify-center gap-2'>
          <IconRow
            Icon={HomeIcon}
            title={formatMoney(recommendation.application_rent, 0, '$')}
            subtitle='Application Rent'
          />
          <IconRow
            Icon={CreditCardIcon}
            title={`Credit Score: ${recommendation.weighted_average_credit_score.toFixed(0)}`}
            subtitle={`Weighted Average`}
          />
          {!!additionalIncomeNeededAtStandardDeposit && (
            <IconRow
              Icon={CurrencyDollarIcon}
              title={'+' + formatMoney(additionalIncomeNeededAtStandardDeposit, 0, '$')}
              subtitle={
                additionalIncomeNeededAtAdditionalDeposit ? (
                  <>
                    <div>Additional Income Needed To Qualify </div>
                    {additionalIncomeNeededAtStandardDeposit !==
                      additionalIncomeNeededAtAdditionalDeposit && (
                      <div>
                        {'(+'}
                        <span className='font-medium'>
                          {formatMoney(additionalIncomeNeededAtAdditionalDeposit, 0, '$')}
                        </span>{' '}
                        w/ {additionalDepositMultiple}x Deposit{')'}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div>Additional Income Needed</div>
                    <div>
                      {' ('}To Qualify w/ {standardDepositMultiple}x Deposit{')'}
                    </div>
                  </>
                )
              }
            />
          )}
          {showFlags && <IconRow Icon={FlagIcon} subtitle={flagBadges} />}
        </div>
      </div>
    </div>
  )
}

const IconRow = ({
  Icon,
  title,
  subtitle
}: {
  Icon: React.FunctionComponent<{ className: any }>
  title?: string
  subtitle?: React.ReactNode
}) => (
  <div className='flex w-full flex-grow flex-row items-center gap-4 rounded p-2'>
    <div className='w-8'>
      <Icon className='h-8 w-8' />
    </div>
    <div className='flex flex-col'>
      {title && <div className='text-xl'>{title}</div>}
      {subtitle && <div className='text-sm text-neutral-700'>{subtitle}</div>}
    </div>
  </div>
)

export default RecommendationPanel
