import React, { createContext } from 'react'

import { useUtilityTypesQuery, UtilityTypesQuery } from 'graphql/generated'

export type UtilityTypesContextType = {
  utilityTypes: UtilityTypesQuery['utility_types']
}

export const UtilityTypesContext = createContext<UtilityTypesContextType>({
  utilityTypes: []
})

export const UtilityTypesContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const [{ data }] = useUtilityTypesQuery()

  const utilityTypes = data?.utility_types ?? []

  return (
    <UtilityTypesContext.Provider value={{ utilityTypes }}>{children}</UtilityTypesContext.Provider>
  )
}
