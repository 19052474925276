import { TableContainer, Table, TableCaption, Thead, Tr, Th, Tbody } from '@chakra-ui/react'
import React from 'react'
import { FC } from 'react'
import { FmvEstimateRow } from '../types'
import ManualFmvTableRow from './Row'

interface ManualFmvTableProps {
  variant: 'invalid' | 'valid'
  rows: FmvEstimateRow[]
  showErrors: boolean
}

const getStyle = (variant: 'invalid' | 'valid') => {
  switch (variant) {
    case 'invalid':
      return {
        title: 'Rows with errors',
        colorScheme: 'pink'
      }
    case 'valid':
      return {
        title: 'Valid rows',
        colorScheme: 'teal'
      }
  }
}

const ManualFmvTable: FC<React.PropsWithChildren<ManualFmvTableProps>> = ({
  rows,
  showErrors,
  variant
}) => {
  const style = getStyle(variant)
  return (
    <TableContainer>
      <Table variant='striped' colorScheme={style.colorScheme}>
        <TableCaption>{style.title}</TableCaption>
        <Thead>
          <Tr>
            {/* <Th>Property ID</Th> */}
            <Th>Address</Th>
            <Th>FMV</Th>
            <Th>Date</Th>
            <Th>Source</Th>
            <Th>FMV Change</Th>
            {showErrors && <Th>Errors</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {rows && rows.map((row) => <ManualFmvTableRow row={row} showError={showErrors} />)}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default ManualFmvTable
