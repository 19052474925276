import {
  UPDATED_ALL_PROPERTIES_SEARCH_STATE,
  UPDATED_AUTOMATED_REVIEW_SEARCH_STATE
} from './actions'

export default function reducer(
  state = {
    allProperties: {
      searchState: {}
    },
    automatedReview: {
      searchState: {}
    }
  },
  action
) {
  switch (action.type) {
    case UPDATED_ALL_PROPERTIES_SEARCH_STATE:
      return {
        ...state,
        allProperties: {
          ...state.allProperties,
          searchState: action.searchState
        }
      }
    case UPDATED_AUTOMATED_REVIEW_SEARCH_STATE:
      return {
        ...state,
        automatedReview: {
          ...state.automatedReview,
          searchState: action.searchState
        }
      }
    default:
      return state
  }
}
