import { SET_REEXECUTE_HEADER, SET_REEXECUTE_PERSONAL_DETAILS } from './actions'

export const initialState = {
  reexecuteHeader: false,
  reexecutePersonalDetails: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_REEXECUTE_HEADER: {
      return {
        ...state,
        reexecuteHeader: action.doReexecute
      }
    }
    case SET_REEXECUTE_PERSONAL_DETAILS: {
      return {
        ...state,
        reexecutePersonalDetails: action.doReexecute
      }
    }
    default:
      return state
  }
}
