import React, { useState } from 'react'

import { capabilities } from '@homevest/utils'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { StoreState } from 'store'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  Heading,
  Spinner,
  Stack,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast
} from '@chakra-ui/react'

import Dropzone from 'components/Dropzone'

import { isAxiosError, uninterceptedClient } from 'lib/axios'
import { fmvEstimateUploadUrl, fmvMarkPropertiesUrl } from 'lib/config'
import { hasCapability } from 'lib/admin-perms'

import { generateAuthorizationHeader } from 'lib/servicing-api'
import ManualFmvTable from './ManualFmvTable'
import { FmvEstimateRow } from './types'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const { DAGGER } = capabilities.CAPABILITY_TYPES

const triggerAndDownloadMarks = (authorization: string, commit = 0) =>
  uninterceptedClient
    .post(
      fmvMarkPropertiesUrl,
      {
        commit
      },
      {
        headers: {
          Authorization: authorization
        }
      }
    )
    .then((response) => response.data)
    .then((data) => {
      // Create a Blob from the CSV data
      const blob = new Blob([data], { type: 'text/csv' })

      // Create a link element
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = 'data.csv'

      // Append the link to the body and trigger the download
      document.body.appendChild(downloadLink)
      downloadLink.click()

      // Clean up by removing the link
      document.body.removeChild(downloadLink)
    })

export default function ManualFMVUploader() {
  const isDagger = useSelector((store: any) => hasCapability(store.admin, DAGGER))
  const admin: any = useSelector((store: StoreState) => store.admin)
  const [hasPreviewed, setHasPreviewed] = useState<boolean>(false)
  const toast = useToast()

  const handleDownload = async (finalizeMarks: boolean) => {
    const credential = admin.credentials.dagger.api
    const authorization = generateAuthorizationHeader(credential.username, credential.password)

    const downloadingToast = toast({
      title: 'Downloading...',
      description: 'Your file should download shortly.',
      status: 'info',
      position: 'top',
      isClosable: true,
      duration: null
    })
    try {
      await triggerAndDownloadMarks(authorization, finalizeMarks ? 1 : 0)
      downloadingToast &&
        toast.update(downloadingToast, {
          title: 'Success',
          description: 'Downloaded successfully.',
          status: 'success'
        })
    } catch (err: any) {
      downloadingToast &&
        toast.update(downloadingToast, {
          title: 'Error',
          description: err.isAxiosError ? err.response.data : err.message,
          status: 'error'
        })
    }
  }
  if (!isDagger) {
    return (
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>Access Denied!</AlertTitle>
        <AlertDescription>You must be a DAGGER to use this tool.</AlertDescription>
      </Alert>
    )
  }

  return (
    <Box w='100%' p={1}>
      <HStack justify='space-between'>
        <Heading as='h3' size='xl' width='100%'>
          FMV Upload Tool
        </Heading>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />}>
                Finalize Marks
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    handleDownload(false)
                    setHasPreviewed(true)
                  }}
                >
                  Preview
                </MenuItem>
                <MenuItem isDisabled={!hasPreviewed} onClick={() => handleDownload(true)}>
                  Finalize
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </HStack>
      <Text>
        This tool allows you to upload FMV estimates for properties. You can upload a CSV file
        containing AT LEAST the following columns:{' '}
        <strong>propertyId, address, fmv, date, source</strong>
      </Text>
      <React.Suspense fallback={<Spinner />}>
        <ManualFMVUploadTool onSubmit={() => setHasPreviewed(false)} />
      </React.Suspense>
    </Box>
  )
}

interface ManualFMVUploadToolProps {
  onSubmit: () => void
}
function ManualFMVUploadTool({ onSubmit }: ManualFMVUploadToolProps) {
  const [fmvEstimateRows, setFmvEstimateRows] = useState<FmvEstimateRow[]>([])
  const [storageUrl, setStorageUrl] = useState<string>('')
  const [filePath, setFilePath] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isUploadConfirmed, setIsUploadConfirmed] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')

  const admin: any = useSelector((store: StoreState) => store.admin)
  const adminId: string = admin.id

  const handleUpload = async () => {
    const credential = admin.credentials.dagger.api
    const authorization = generateAuthorizationHeader(credential.username, credential.password)

    try {
      setSuccessMessage('')
      setErrorMessage('')
      setIsLoading(true)
      await uninterceptedClient.post(
        fmvEstimateUploadUrl,
        {
          adminId,
          commit: 1,
          url: storageUrl,
          filePath
        },
        {
          headers: {
            Authorization: authorization
          }
        }
      )
      setSuccessMessage(`Your FMV Estimates are being processed.`)
      setIsUploadConfirmed(false)
      setFmvEstimateRows([])
      setStorageUrl('')
      setFilePath('')
      onSubmit()
    } catch (err: any) {
      if (err.isAxiosError) {
        setErrorMessage(err.response.data)
      } else {
        setErrorMessage(err.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleValidation = async (url: string, gcsFilePath: string) => {
    const credential = admin.credentials.dagger.api
    const authorization = generateAuthorizationHeader(credential.username, credential.password)

    try {
      setSuccessMessage('')
      setErrorMessage('')
      setIsUploadConfirmed(false)
      setIsLoading(true)
      const { data } = await uninterceptedClient.post<FmvEstimateRow[]>(
        fmvEstimateUploadUrl,
        {
          adminId,
          commit: 0,
          url
        },
        {
          headers: {
            Authorization: authorization
          }
        }
      )
      setFilePath(gcsFilePath)
      setStorageUrl(url)
      setFmvEstimateRows(data)
    } catch (err: any) {
      if (isAxiosError<FmvEstimateRow[]>(err)) {
        if (err.response?.status === 400) {
          setFmvEstimateRows(err.response.data)
        } else {
          // TODO: fix the typing here where we just return a string from GCF
          // @ts-ignore
          setErrorMessage(err.response?.data ?? 'Unknown error')
        }
      } else {
        setErrorMessage(err.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  let invalidRows: Array<FmvEstimateRow> = []
  let validRows: Array<FmvEstimateRow> = []
  if (fmvEstimateRows) {
    invalidRows = fmvEstimateRows.filter((d) => !d.valid)
    validRows = fmvEstimateRows.filter((d) => d.valid)
  }

  const showUpload = invalidRows.length === 0 && validRows.length > 0
  const showDropzone = !showUpload

  return (
    <div>
      {errorMessage && (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
      )}
      {successMessage && (
        <Alert status='success'>
          <AlertIcon />
          <AlertTitle>Success</AlertTitle>
          <AlertDescription>{successMessage}</AlertDescription>
        </Alert>
      )}
      <ManualFmvTable rows={invalidRows} showErrors={true} variant='invalid' />
      <Heading as='h1' size='md'>
        Valid Rows ({(validRows || []).length})
      </Heading>
      <ManualFmvTable rows={validRows} showErrors={false} variant='valid' />
      {showUpload && (
        <Stack>
          <Checkbox
            isChecked={isUploadConfirmed}
            isDisabled={isLoading}
            onChange={(e: any) => {
              setIsUploadConfirmed(e.target.checked)
            }}
          >
            You sure you want to upload {validRows.length} FMVs?
          </Checkbox>
          <Button
            colorScheme='green'
            isDisabled={!isUploadConfirmed || isLoading}
            isLoading={isLoading}
            onClick={handleUpload}
          >
            Upload
          </Button>
        </Stack>
      )}
      {showDropzone && (
        <Stack spacing={5}>
          <Box borderWidth='1px' borderRadius='lg'>
            <Dropzone onSuccess={handleValidation} uploadPath={'/fmv/manual-uploads/' + uuidv4()} />
          </Box>
        </Stack>
      )}
    </div>
  )
}
