import React from 'react'
import { InformationCircle } from 'styled-icons/heroicons-solid'
import { TableContainer, Table, Thead, Th, Tr, Tbody, Tooltip } from '@chakra-ui/react'

import EditUtilityDetailsRow from './EditUtilityDetailsRow'

type EditUtilityDetailsTablePropTypes = {
  utilityTypeConfig: Record<string, { defaultResponsibleParty: string }>
  removeUtilityType: (utilityTypeId: string) => void
  setResponsibleParty: (utilityTypeId: string, newResponsibleParty: string) => void
}

const EditUtilityDetailsTable: React.FC<
  React.PropsWithChildren<EditUtilityDetailsTablePropTypes>
> = ({ utilityTypeConfig, removeUtilityType, setResponsibleParty }) => {
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Utility Type</Th>
            <Th>
              Default Responsible Party
              <Tooltip label='The "default responsible party" will be responsible for making payments for this utility type for any rentals associated with this home, unless this is reconfigured manually in rent roll. If this is set to "Up&Up," then we will pay utility bills of this type and charge them back to the tenant. If set to "Tenant," then the tenant is expected to register utilities in their name and make payments directly to the provider.'>
                <InformationCircle className='h-4 w-4' />
              </Tooltip>
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(utilityTypeConfig).map(([utilityTypeId, { defaultResponsibleParty }]) => (
            <EditUtilityDetailsRow
              utilityTypeConfig={{ utilityTypeId, defaultResponsibleParty }}
              setResponsibleParty={(newResponsibleParty: string) =>
                setResponsibleParty(utilityTypeId, newResponsibleParty)
              }
              removeUtilityType={() => removeUtilityType(utilityTypeId)}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default EditUtilityDetailsTable
