import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import PropertyCarousel from './Carousel'

const StyledHeader = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
`

export default function PropertyInfo({ property }) {
  return (
    <div>
      <StyledHeader>
        {property.display_line_1}
        <br />
        {property.display_line_2}
      </StyledHeader>

      {property.photos && <PropertyCarousel images={property.photos} />}
    </div>
  )
}

PropertyInfo.propTypes = {
  property: PropTypes.object.isRequired
}
