"use strict";
exports.PRE_PAYMENT_BALANCE_CHECK_STATUSES = Object.freeze({
    FAILED: 'failed',
    SUCCEEDED: 'succeeded'
});
exports.PRE_PAYMENT_BALANCE_CHECK_EXTERNAL_SOURCES = Object.freeze({
    STRIPE: 'stripe'
});
exports.PRE_PAYMENT_BALANCE_CHECK_EXTERNAL_TYPES = Object.freeze({
    CHARGE: 'charge',
    PAYMENT_INTENT: 'payment_intent'
});
