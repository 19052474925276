import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import pluralize from 'pluralize'

import { Label } from 'components/Toolkit'
import LeadGroupReminders from './LeadGroupReminders'

const DOCUMENT_OPTIONS = [
  {
    label: 'Identity Document',
    value: 'Identity Document'
  },
  {
    label: 'Pay Stub(s)',
    value: 'Pay Stub(s)'
  },
  {
    label: 'Bank Statement',
    value: 'Bank Statement'
  },
  {
    label: 'Tax Return',
    value: 'Tax Return'
  },
  {
    label: 'Offer Letter',
    value: 'Offer Letter'
  }
]

const INITIAL_DOCUMENTS = DOCUMENT_OPTIONS.slice(0, 3)

export default function DocumentReminders({ canSend, disabled, onSend, rentalApplication }) {
  const [documents, setDocuments] = useState(INITIAL_DOCUMENTS)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (documents.length) {
      setMessage(
        `Before your application can move forward, we still need you to upload the following ${pluralize(
          'document',
          documents.length
        )}:

${documents.map(({ value }) => value).join('\n')}

Warmly,
Your Up&Up Leasing Concierge`
      )
    } else {
      setMessage('')
    }
  }, [documents])

  return (
    <div>
      <Label>Documents</Label>
      <Select
        onChange={setDocuments}
        value={documents}
        options={[{ label: 'Select documents', options: DOCUMENT_OPTIONS }]}
        isMulti
        disabled={disabled}
      />
      <LeadGroupReminders
        canSend={canSend}
        disabled={disabled || !documents.length}
        messageTemplate={message}
        onSend={onSend}
        rentalApplication={rentalApplication}
      />
    </div>
  )
}

DocumentReminders.propTypes = {
  canSend: PropTypes.bool,
  disabled: PropTypes.bool,
  onSend: PropTypes.func.isRequired,
  rentalApplication: PropTypes.object.isRequired
}
