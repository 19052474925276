import React, { useContext } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  VStack,
  Divider,
  Button,
  Spacer,
  HStack,
  useToast
} from '@chakra-ui/react'

import { useUpup_SetRentalDoNotRenewMutation } from 'graphql/generated'
import { RentalDetailsContext } from '../../RentalDetailsContext'
import { ErrorText } from 'components/Toolkit'

interface CancelDoNotRenewModalProps {
  isOpen: boolean
  close: () => void
}

const CancelDoNotRenewModal: React.FC<React.PropsWithChildren<CancelDoNotRenewModalProps>> = ({
  isOpen,
  close
}) => {
  const [{ fetching, error }, setRentalDoNotRenew] = useUpup_SetRentalDoNotRenewMutation()
  const toast = useToast()
  let { rentalData } = useContext(RentalDetailsContext)

  const handleSubmit = async () => {
    await setRentalDoNotRenew({
      id: rentalData?.id,
      do_not_renew_selected_at: null,
      do_not_renew_selected_by_admin_id: null,
      do_not_renew_reason: null,
      lease_end_reason: null
    })

    if (error !== undefined) {
      return
    }

    toast({
      title: '"Do Not Renew" notice cancelled!',
      description: 'The "Do Not Renew" notice for this rental has been successfully cancelled.',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right'
    })

    close()
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel Do Not Renew</ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody>
          {error !== undefined && <ErrorText>{error.message}</ErrorText>}
          <VStack spacing={5}>
            <p>Are you sure you want to cancel the "do not renew" status for this rental?</p>
            <p>This will allow us to issue a renewal offer for this tenant.</p>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button colorScheme='red' onClick={close}>
              Go Back
            </Button>
            <Spacer />
            <Button colorScheme='teal' isLoading={fetching} onClick={handleSubmit}>
              Cancel Do Not Renew
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CancelDoNotRenewModal
