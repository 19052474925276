import React, { useState, useEffect } from 'react'
import { Stack, Heading, Divider, Button, useToast } from '@chakra-ui/react'
import { EnvelopeIcon } from '@heroicons/react/24/solid'

import RenewalOfferRow from './RenewalOfferRow'
import AddRenewalOfferForm from './AddRenewalOfferForm'
import { buildDefaultRenewalOffers, RenewalOfferItem, RenewalOffersByTerm } from 'lib/renewals'
import SubmitConfirmationModal from './SubmitConfirmationModal'
import axios from 'lib/axios'

interface RenewalOfferFormProps {
  currentRent: number
  marketRent?: number
  portfolioHomeId: string
  rentalId: string
  onSubmit?: () => void
}

const RenewalOfferForm: React.FC<React.PropsWithChildren<RenewalOfferFormProps>> = ({
  currentRent,
  marketRent,
  portfolioHomeId,
  rentalId,
  onSubmit
}) => {
  const [renewalOffers, setRenewalOffers] = useState<RenewalOffersByTerm>({})
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    const newRenewalOffers = marketRent ? buildDefaultRenewalOffers(currentRent, marketRent) : []
    setRenewalOffers(newRenewalOffers)
  }, [currentRent, marketRent])

  const handleAddRenewalOffer = (renewalOffer: RenewalOfferItem) => {
    const { term } = renewalOffer

    setRenewalOffers({
      ...renewalOffers,
      [term]: { ...renewalOffer }
    })
  }

  const handlePriceChangeForTerm = (term: number, price: number) => {
    const newRenewalOffers = {
      ...renewalOffers,
      [term]: {
        ...renewalOffers[term],
        price
      }
    }
    setRenewalOffers(newRenewalOffers)
  }

  const submitRenewalOffers = async () => {
    setLoading(true)
    try {
      const body = {
        send_email: true,
        portfolio_home_underwrite: {
          portfolio_home_id: portfolioHomeId,
          type: 'rent',
          value: marketRent
        },
        rental_id: rentalId,
        offers: Object.values(renewalOffers).map((renewalOffer) => ({
          term: renewalOffer.term,
          price: renewalOffer.price,
          month_to_month: renewalOffer.isMonthToMonth
        }))
      }
      const reply = await axios.post('/admin/renewals', body)

      if (reply.data.renewal || reply.status > 299) {
        toast({
          title: 'Renewal Offers Submitted!',
          position: 'top-right',
          description: 'Your renewal offers have been successfully sent to the tenant.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } else {
        toast({
          title: 'Error',
          position: 'top-right',
          description: 'There was an error submitting your renewal offer: ' + reply.statusText,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
      onSubmit?.()
    } catch (e: any) {
      toast({
        title: 'Error',
        position: 'top-right',
        description: 'There was an error submitting your renewal offer: ' + e.message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    } finally {
      setLoading(false)
    }
  }

  const removeRenewalOfferForTerm = (term: number) => {
    const { [term]: _, ...newRenewalOffers } = renewalOffers
    setRenewalOffers(newRenewalOffers)
  }

  return (
    <Stack spacing={5}>
      <Heading size='sm'>Adjust Renewal Offers</Heading>
      <Divider />
      {!!marketRent &&
        Object.values(renewalOffers)
          .sort((a, b) => b.term - a.term)
          .map((renewalOffer) => (
            <RenewalOfferRow
              renewalOffer={renewalOffer}
              handlePriceChangeForTerm={handlePriceChangeForTerm}
              removeRenewalOfferForTerm={removeRenewalOfferForTerm}
              key={renewalOffer.term}
            />
          ))}
      {!marketRent && <div className='text-red-400'>Enter market rent to see renewal offers.</div>}
      <Divider />
      <AddRenewalOfferForm addRenewalOffer={handleAddRenewalOffer} />
      <Button
        colorScheme='teal'
        onClick={() => setIsModalOpen(true)}
        rightIcon={<EnvelopeIcon className='h-5 w-5' />}
        isLoading={loading}
        isDisabled={loading || !marketRent}
      >
        Submit and Send
      </Button>
      <SubmitConfirmationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        renewalOffers={renewalOffers}
        submitRenewalOffers={submitRenewalOffers}
      />
    </Stack>
  )
}

export default RenewalOfferForm
