import React, { createContext } from 'react'
import {
  RentRollRentalSubscription,
  Upup_LiabilityTypesQuery,
  useRentRollRentalSubscription,
  useUpup_LiabilityTypesQuery
} from 'graphql/generated'
import { Loading } from 'ui'

type RentalDetailsContextType = {
  rentalData: RentRollRentalSubscription['rentals_by_pk']
  liabilityTypes: Upup_LiabilityTypesQuery['liability_types']
}

export const RentalDetailsContext = createContext<RentalDetailsContextType>({
  rentalData: null,
  liabilityTypes: []
})

export const RentalDetailsContextProvider: React.FC<
  React.PropsWithChildren<{ rentalId: string }>
> = ({ rentalId, children }) => {
  const [{ data: liabilityTypesData }] = useUpup_LiabilityTypesQuery()
  const [{ data: rentalData }] = useRentRollRentalSubscription({ variables: { rentalId } })

  if (!liabilityTypesData || !rentalData) {
    return <Loading />
  }

  return (
    <RentalDetailsContext.Provider
      value={{
        liabilityTypes: liabilityTypesData.liability_types,
        rentalData: rentalData.rentals_by_pk
      }}
    >
      {children}
    </RentalDetailsContext.Provider>
  )
}
