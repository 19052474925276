import React, { useState } from 'react'
import {
  Tr,
  Td,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'
import { requiredLeaseDetails } from '@homevest/utils'
import { PetWithId } from '@homevest/types/rental-applications'

import SelectDropdown from '../../Common/SelectDropdown'
import AddButton from '../../Common/AddButton'
import { isPetWeightValid } from 'lib/required-lease-details'

const { PET_TYPES, MAX_PET_WEIGHT } = requiredLeaseDetails

const AddPetRow: React.FC<
  React.PropsWithChildren<{
    addPet: (pet: PetWithId) => void
    canAddPet: boolean
    skipValidation: boolean
  }>
> = ({ addPet, canAddPet, skipValidation }) => {
  const [id, setId] = useState(uuidv4())
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [color, setColor] = useState('')
  const [weight, setWeight] = useState<number | null>(null)

  // Don't show error initially when weight is null, but disable submission until this is filled out
  const isWeightValid = weight === null || isPetWeightValid(weight) || skipValidation
  const canSubmit = Boolean(canAddPet && name && type && color && weight !== null && isWeightValid)

  const handleSubmit = () => {
    if (canSubmit) {
      addPet({ id, name, type, color, weight_in_pounds: weight! })
      setId(uuidv4())
      setName('')
      setType('')
      setColor('')
      setWeight(null)
    }
  }

  if (!canAddPet) {
    return null
  }

  return (
    <Tr>
      <Td>
        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input placeholder='Name...' value={name} onChange={(evt) => setName(evt.target.value)} />
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired>
          <FormLabel htmlFor='pet-type'>Type</FormLabel>
          <SelectDropdown
            id='pet-type'
            options={Object.values(PET_TYPES)}
            value={type}
            onChange={(evt) => setType(evt.target.value)}
          />
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired>
          <FormLabel>Color</FormLabel>
          <Input
            placeholder='Color...'
            value={color}
            onChange={(evt) => setColor(evt.target.value)}
          />
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired isInvalid={!isWeightValid}>
          <FormLabel>Weight (lbs)</FormLabel>
          <NumberInput
            max={2000}
            min={0}
            // placeholder='Weight...'
            value={weight ?? ''}
            onChange={(value) => setWeight(value ? parseInt(value) : null)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>Please enter a weight between 0 and {MAX_PET_WEIGHT}</FormErrorMessage>
        </FormControl>
      </Td>

      <Td valign='bottom'>
        <AddButton disabled={!canSubmit} onClick={handleSubmit} />
      </Td>
    </Tr>
  )
}

export default AddPetRow
