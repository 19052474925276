import { userIncomeSources } from '@homevest/utils'

import {
  SelfEmployedSourceDetails,
  SelfEmployedSourceErrors,
  BaseValidatorReturnType,
  BenefitsSourceDetails,
  BenefitsSourceErrors,
  StandardEmployedSourceDetails,
  StandardEmployedSourceErrors,
  NewlyEmployedSourceDetails,
  NewlyEmployedSourceErrors
} from './types'
import { isValidPhoneNumber, isValidEmail } from 'lib/form-validation'

const { REQUIRED_PAY_STUBS_BY_PAY_FREQUENCY } = userIncomeSources

export const validateEmployedIncomeSource = (
  values: StandardEmployedSourceDetails | NewlyEmployedSourceDetails
): BaseValidatorReturnType<StandardEmployedSourceErrors | NewlyEmployedSourceErrors> => {
  if (values.is_newly_employed) {
    return validateNewlyEmployedIncomeSource(values)
  } else {
    return validateStandardEmployedIncomeSource(values)
  }
}

const validateNewlyEmployedIncomeSource = (
  values: NewlyEmployedSourceDetails
): BaseValidatorReturnType<NewlyEmployedSourceErrors> => {
  let isValid = true
  const errors: NewlyEmployedSourceErrors = {}

  if (!values.company_name) {
    errors.company_name = 'Please enter an employer name!'
    isValid = false
  }

  if (values.contact_email && !isValidEmail(values.contact_email)) {
    errors.contact_email = 'Please enter a valid email!'
    isValid = false
  }

  if (values.contact_phone && !isValidPhoneNumber(values.contact_phone)) {
    errors.contact_phone = 'Please enter a valid phone number!'
    isValid = false
  }

  if (!values.pay_frequency) {
    errors.pay_frequency = 'Please select a pay frequency!'
    isValid = false
  }

  if (!values.wage_type) {
    errors.wage_type = 'Please select a wage type!'
    isValid = false
  }

  if (!values.offered_rate) {
    errors.offered_rate =
      values.wage_type === 'hourly'
        ? 'Please enter an hourly rate!'
        : 'Please enter an annual salary!'
    isValid = false
  }

  if (!values.offer_letter?.upload_path) {
    errors.offer_letter = {
      file: 'Please upload an offer letter!'
    }
    isValid = false
  }

  return { errors, isValid }
}

const validateStandardEmployedIncomeSource = (
  values: StandardEmployedSourceDetails
): BaseValidatorReturnType<StandardEmployedSourceErrors> => {
  let isValid = true
  const errors: StandardEmployedSourceErrors = {}

  if (!values.company_name) {
    errors.company_name = 'Please enter an employer name!'
    isValid = false
  }

  if (!values.contact_email) {
    errors.contact_email = 'Please enter a contact email!'
    isValid = false
  } else if (!isValidEmail(values.contact_email)) {
    errors.contact_email = 'Please enter a valid email!'
    isValid = false
  }

  if (!values.contact_phone) {
    errors.contact_phone = 'Please enter a contact phone number!'
    isValid = false
  } else if (!isValidPhoneNumber(values.contact_phone)) {
    errors.contact_phone = 'Please enter a valid phone number!'
    isValid = false
  }

  if (!values.contact_name) {
    errors.contact_name = 'Please enter a contact name!'
    isValid = false
  }

  if (!values.contact_title) {
    errors.contact_title = 'Please enter a contact title!'
    isValid = false
  }

  if (!values.pay_frequency) {
    errors.pay_frequency = 'Please select a pay frequency!'
    isValid = false
  }

  if (!values.wage_type) {
    errors.wage_type = 'Please select a wage type!'
    isValid = false
  }

  const requiredPaystubCount = REQUIRED_PAY_STUBS_BY_PAY_FREQUENCY[values.pay_frequency] ?? 1

  if (!values.pay_stubs || Object.keys(values.pay_stubs).length < requiredPaystubCount) {
    ;[...Array(REQUIRED_PAY_STUBS_BY_PAY_FREQUENCY[values.pay_frequency])].forEach((_, index) => {
      if (!values.pay_stubs?.[index]) {
        errors.pay_stubs = {
          ...(errors.pay_stubs || {}),
          [index]: {
            amount: 'Please enter the income from this pay stub!',
            file: 'Please upload a pay stub!'
          }
        }
      }
    })
    isValid = false
  }

  Object.entries(values.pay_stubs || {}).forEach(([rawIndex, payStub]) => {
    const index = parseInt(rawIndex)

    if (!payStub.amount) {
      errors.pay_stubs = {
        ...(errors.pay_stubs || {}),
        [index]: {
          ...(errors.pay_stubs?.[index] || {}),
          amount: 'Please enter the income from this pay stub!'
        }
      }
      isValid = false
    }

    if (!payStub.upload_path) {
      errors.pay_stubs = {
        ...(errors.pay_stubs || {}),
        [index]: {
          ...(errors.pay_stubs?.[index] || {}),
          file: 'Please upload a pay stub!'
        }
      }
      isValid = false
    }
  })

  return { errors, isValid }
}

export const validateSelfEmployedIncomeSource = (
  values: SelfEmployedSourceDetails
): BaseValidatorReturnType<SelfEmployedSourceErrors> => {
  let isValid = true
  const errors: SelfEmployedSourceErrors = {}

  if (!values.business_name) {
    errors.business_name = 'Please enter a company name!'
    isValid = false
  }

  if (!values.tax_return?.amount) {
    errors.tax_return = {
      ...(errors.tax_return || {}),
      amount: 'Please enter an amount!'
    }
    isValid = false
  }

  if (!values.tax_return?.upload_path) {
    errors.tax_return = {
      ...(errors.tax_return || {}),
      file: 'Please upload a tax return!'
    }
    isValid = false
  }

  if (!values.articles_of_organization?.upload_path) {
    errors.articles_of_organization = {
      file: 'Please upload articles of organization!'
    }
    isValid = false
  }

  return { errors, isValid }
}

export const validateBenefitsIncomeSource = (
  values: BenefitsSourceDetails
): BaseValidatorReturnType<BenefitsSourceErrors> => {
  let isValid = true
  const errors: BenefitsSourceErrors = {}

  if (!values.benefits_type) {
    errors.benefits_type = 'Please select a benefit type!'
    isValid = false
  }

  if (!values.pay_frequency) {
    errors.pay_frequency = 'Please select a pay frequency!'
    isValid = false
  }

  if (!values.income_per_period) {
    errors.income_per_period = 'Please enter the benefit amount!'
    isValid = false
  }

  if (!values.award_letter?.upload_path) {
    errors.award_letter = {
      file: 'Please upload an award letter!'
    }
    isValid = false
  }

  return { errors, isValid }
}
