"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AGENT_ADMINS_BY_TYPE = exports.AGENT_ADMINS = exports.AGENT_TYPES = void 0;
exports.AGENT_TYPES = Object.freeze({
    LEASING_REPRESENTATIVE: 'leasing_representative',
    LEASING_COORDINATOR: 'leasing_coordinator',
    LEASING_AGENT: 'leasing_agent'
});
// TODO - move this to the DB
exports.AGENT_ADMINS = Object.freeze({
    ALLISON: '723a6586-080b-43c9-b86e-43869b3805e0',
    ISSA: '5456decd-e8cf-45f7-9380-b440b1ab1e8d',
    AIKO: '053bba59-3ef0-4776-9d03-c2c76a8091dd',
    ELLA: 'a5e00187-78a4-45d0-aa8c-ff62191aefc4'
});
exports.AGENT_ADMINS_BY_TYPE = Object.freeze({
    [exports.AGENT_TYPES.LEASING_REPRESENTATIVE]: [exports.AGENT_ADMINS.AIKO, exports.AGENT_ADMINS.ELLA],
    [exports.AGENT_TYPES.LEASING_COORDINATOR]: [exports.AGENT_ADMINS.ISSA],
    [exports.AGENT_TYPES.LEASING_AGENT]: [exports.AGENT_ADMINS.ALLISON]
});
