import React from 'react'
import { useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { BuyersInspection, BuyersInspectionApiResponse } from '@homevest/types/buyers-inspections'

import axios from 'lib/axios'
import { Sidepeek } from 'ui'
import { BuyersInspectionFormData, validateBuyersInspectionForm } from 'lib/buyers-inspections'
import BuyersInspectionForm from './BuyersInspectionForm'

const EditBuyersInspectionSidepeek = ({
  inspection,
  portfolioHomeId,
  isOpen,
  onSubmit,
  onClose
}: {
  inspection?: BuyersInspectionApiResponse | null
  portfolioHomeId: string
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}) => {
  const toast = useToast()
  const { mutate: editBuyersInspection } = useMutation({
    mutationFn: async (data: BuyersInspectionFormData) => {
      return axios.put<BuyersInspection>(`/admin/buyers_inspections/${inspection?.id}`, data)
    },
    onSuccess: () => {
      toast({
        title: "Buyer's Inspection Edited",
        status: 'success',
        duration: 5000,
        position: 'bottom-right'
      })
      onSubmit()
      onClose()
    },
    onError: (error: any) => {
      toast({
        title: "Error Editing Buyer's Inspection",
        description: error?.message || 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        position: 'bottom-right'
      })
    }
  })

  if (!inspection) {
    return null
  }

  return (
    <Sidepeek isOpen={isOpen} onClose={onClose} title={"Edit Buyer's Inspection"}>
      <BuyersInspectionForm
        initialData={{
          portfolio_home_disposition_bid_id: inspection.portfolio_home_disposition_bid_id,
          status: inspection.status,
          submitted_by_buyer_at: inspection.submitted_by_buyer_at,
          counter_deadline_date: inspection.counter_deadline_date,
          vendor_bids_sent_at: inspection.vendor_bids_sent_at,
          counter_submitted_at: inspection.counter_submitted_at,
          counter_response_type: inspection.counter_response_type,
          counter_response_received_at: inspection.counter_response_received_at
        }}
        portfolioHomeId={portfolioHomeId}
        onSubmit={(data) => editBuyersInspection(data)}
        validator={validateBuyersInspectionForm}
      />
    </Sidepeek>
  )
}

export default EditBuyersInspectionSidepeek
