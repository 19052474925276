"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOCUMENT_TYPES_WITH_AMOUNT = exports.BENEFIT_TYPES = exports.USER_INCOME_SOURCE_WAGE_TYPES = exports.EMPLOYMENT_VERIFICATION_STATUSES = exports.REQUIRED_PAY_STUBS_BY_PAY_FREQUENCY = exports.USER_INCOME_SOURCE_PAY_FREQUENCIES = exports.USER_INCOME_SOURCE_STATUSES = exports.USER_INCOME_SOURCE_TYPES = void 0;
const documents_1 = require("./documents");
exports.USER_INCOME_SOURCE_TYPES = Object.freeze({
    EMPLOYED: 'employed',
    SELF_EMPLOYED: 'self_employed',
    BENEFITS: 'benefits'
});
exports.USER_INCOME_SOURCE_STATUSES = Object.freeze({
    PENDING: 'pending',
    VERIFIED: 'verified',
    INVALID: 'invalid'
});
exports.USER_INCOME_SOURCE_PAY_FREQUENCIES = Object.freeze({
    WEEKLY: 'weekly',
    BIWEEKLY: 'biweekly',
    SEMIMONTHLY: 'semimonthly',
    MONTHLY: 'monthly',
    BIMONTHLY: 'bimonthly',
    ANNUALLY: 'annually',
    OTHER: 'other'
});
exports.REQUIRED_PAY_STUBS_BY_PAY_FREQUENCY = Object.freeze({
    [exports.USER_INCOME_SOURCE_PAY_FREQUENCIES.WEEKLY]: 4,
    [exports.USER_INCOME_SOURCE_PAY_FREQUENCIES.BIWEEKLY]: 2,
    [exports.USER_INCOME_SOURCE_PAY_FREQUENCIES.SEMIMONTHLY]: 2,
    [exports.USER_INCOME_SOURCE_PAY_FREQUENCIES.MONTHLY]: 1,
    [exports.USER_INCOME_SOURCE_PAY_FREQUENCIES.BIMONTHLY]: 1,
    [exports.USER_INCOME_SOURCE_PAY_FREQUENCIES.ANNUALLY]: 1,
    [exports.USER_INCOME_SOURCE_PAY_FREQUENCIES.OTHER]: 1
});
exports.EMPLOYMENT_VERIFICATION_STATUSES = Object.freeze({
    PENDING: 'pending',
    VERIFIED: 'verified',
    FAILED: 'failed'
});
exports.USER_INCOME_SOURCE_WAGE_TYPES = Object.freeze({
    HOURLY: 'hourly',
    SALARY: 'salary'
});
exports.BENEFIT_TYPES = Object.freeze({
    UNEMPLOYMENT: 'unemployment',
    DISABILITY: 'disability',
    SOCIAL_SECURITY: 'social_security',
    RETIREMENT: 'retirement',
    CHILD_SUPPORT: 'child_support',
    ALIMONY: 'alimony',
    OTHER: 'other'
});
exports.DOCUMENT_TYPES_WITH_AMOUNT = Object.freeze([
    documents_1.DOCUMENT_TYPES.PAY_STUB,
    documents_1.DOCUMENT_TYPES.TAX_RETURN
]);
