import React from 'react'
import { Tab, Icon, As as AsComponent } from '@chakra-ui/react'

const LabelledTab: React.FC<
  React.PropsWithChildren<{
    icon: AsComponent
    label: string
    checkCount: number
  }>
> = ({ icon, label, checkCount }) => {
  return (
    <Tab>
      <Icon as={icon} w={5} h={5} marginRight={'5px'} />
      {label} ({checkCount})
    </Tab>
  )
}

export default LabelledTab
