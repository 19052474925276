import React from 'react'
import {
  Stack,
  Text,
  NumberInput,
  NumberInputField,
  Heading,
  Box,
  // Link,
  // HStack,
  Divider,
  FormControl,
  FormLabel
} from '@chakra-ui/react'

// import { ArrowTopRightOnSquareIcon as ExternalLinkIcon } from '@heroicons/react/20/solid'
import { formatMoney } from 'lib/numbers'

interface MarketRentFormProps {
  currentRent: number
  marketRent: number | undefined
  // zillowLink: string
  setMarketRent: (marketRent: number) => void
}

const MarketRentForm: React.FC<React.PropsWithChildren<MarketRentFormProps>> = ({
  currentRent,
  marketRent,
  // zillowLink,
  setMarketRent
}) => {
  return (
    <Stack spacing={5}>
      <Box height='40%'>
        <Heading size='sm'>Enter Market Rent</Heading>
      </Box>
      <Divider />

      {/* <Link isExternal href={zillowLink} color='teal.500' target='_blank'>
        <HStack>
          <Text>Browse on Zillow </Text>
          <ExternalLinkIcon className='h-4 w-4' />
        </HStack>
      </Link> */}

      <Box height='20%'>
        <Text>
          <b>Current Rent:</b> {formatMoney(currentRent, 0, '$')}
        </Text>
      </Box>
      <Box height='20%'>
        <FormControl>
          <FormLabel htmlFor='market-rent'>Enter Market Rent</FormLabel>
          <NumberInput
            value={marketRent ?? ''}
            format={(value: string | number | undefined) =>
              value ? formatMoney(Number(value), 0, '$') : ''
            }
            onChange={(_valueAsString: string, valueAsNumber: number) =>
              setMarketRent(valueAsNumber)
            }
            min={0}
            max={10000}
          >
            <NumberInputField id='market-rent' />
          </NumberInput>
        </FormControl>
      </Box>
    </Stack>
  )
}

export default MarketRentForm
