import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

import { HeaderText, Modal, Button, Input } from 'components/Toolkit'

function ApproveDocumentModal({ approve, close }) {
  const [notes, setNotes] = useState('')

  return (
    <Modal close={close} hasCloseButton>
      <HeaderText style={{ marginTop: '40px', marginBottom: '20px' }} size='h4'>
        Approval note
      </HeaderText>
      <Input lable='Notes' value={notes} onChange={setNotes} />
      <Button onClick={() => approve(notes)} style={{ width: '100%', marginTop: '40px' }}>
        Approve
      </Button>
    </Modal>
  )
}

ApproveDocumentModal.propTypes = {
  approve: PropTypes.func.isRequired,
  close: PropTypes.func
}

export default memo(ApproveDocumentModal)
