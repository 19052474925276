import React from 'react'

import { Spinner } from '@chakra-ui/react'
import {
  ContentSectionCard,
  NotesGrid,
  CreateNoteButtonWithModal
} from 'components/TailwindUIToolkit'

const ProjectNotes = ({ constructionProjectId }: { constructionProjectId: string }) => {
  return (
    <ContentSectionCard
      title='Notes'
      staticSize={true}
      action={
        <CreateNoteButtonWithModal
          resourceId={constructionProjectId}
          resourceType='construction_project'
        />
      }
    >
      <React.Suspense fallback={<Spinner />}>
        <NotesGrid resourceIds={[constructionProjectId]} columnIdsToExclude={['type']} />
      </React.Suspense>
    </ContentSectionCard>
  )
}

export default ProjectNotes
