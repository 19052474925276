import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import { capabilities } from '@homevest/utils'
import { useSelector } from 'react-redux'

import { BodyText, Button, HeaderText, ErrorText, SuccessText } from 'components/Toolkit'

import { hasCapability } from 'lib/admin-perms'
import axios from 'lib/axios'

const { DAGGER } = capabilities.CAPABILITY_TYPES

const StyledContent = styled.div`
  width: 500px;
  margin: 0 auto;
`

export default function BackupAndRebuild() {
  const isDagger = useSelector((store) => hasCapability(store.admin, DAGGER))

  if (!isDagger) {
    return <ErrorText>You do not have the capability of DAGGER.</ErrorText>
  }

  return (
    <StyledContent>
      <HeaderText size='h3' style={{ textAlign: 'center' }}>
        Backup Statements and Rebuild
      </HeaderText>
      <React.Suspense fallback={<Spinner color='primary' />}>
        <BackupAndRebuildViewer />
      </React.Suspense>
    </StyledContent>
  )
}

function BackupAndRebuildViewer() {
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)

  const handleBackup = async () => {
    try {
      setIsLoading(true)

      await axios.post('/admin/system/topic_publish', {
        topic: 'statements-initiate-backup-and-rebuild',
        message: 'EOM'
      })

      setSuccessMessage(
        'Statements snapshot and rebuild initiated. Please review the snapshots in homevest-data.property_financials_final_snapshots and the statements in homevest-data.property_financials_final.'
      )
    } catch (err) {
      setErrorMessage(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Spinner color='primary' style={{ textAlign: 'center' }} />
  }

  if (errorMessage) {
    return <ErrorText>{errorMessage}</ErrorText>
  }

  if (successMessage) {
    return (
      <div>
        <SuccessText>{successMessage}</SuccessText>
      </div>
    )
  }

  return (
    <div>
      <BodyText style={{ marginBottom: '20px' }}>
        This tool takes a snapshot of all _final statement tables and rebuilds the calculations from
        the latest from Airflow.
      </BodyText>
      <Button onClick={handleBackup}>Snapshot and Rebuild!</Button>
    </div>
  )
}
