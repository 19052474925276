import React from 'react'
import { Badge } from '@chakra-ui/react'
import { CheckType } from 'types/RentalSiteChecker'
import { startCase } from 'lodash'

export const StatusBadge: React.FC<React.PropsWithChildren<{ status: boolean }>> = ({ status }) => {
  return <Badge colorScheme={status ? 'green' : 'red'}>{status ? 'Found' : 'Missing'}</Badge>
}

export const CheckTypeBadge: React.FC<React.PropsWithChildren<{ checkType: CheckType }>> = ({
  checkType
}) => {
  return (
    <Badge colorScheme={mapCheckTypeToColorScheme(checkType)}>
      {checkType === 'upandup_feeds' ? 'Up&Up Feeds' : startCase(checkType)}
    </Badge>
  )
}

const mapCheckTypeToColorScheme = (checkType: CheckType) => {
  switch (checkType) {
    case 'rently':
      return 'orange'
    case 'zillow':
      return 'blue'
    case 'upandup_feeds':
      return 'teal'
    default:
      throw new Error(`Unrecognized check type: ${checkType}`)
  }
}
