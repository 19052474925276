import React from 'react'

import { Text, Link, Icon } from '@chakra-ui/react'
import { ArrowTopRightOnSquareIcon as ExternalLinkIcon } from '@heroicons/react/20/solid'

import { CheckType } from 'types/RentalSiteChecker'
import { startCase } from 'lodash'

const CheckSearchLink: React.FC<
  React.PropsWithChildren<{ zipCode: string; checkType: CheckType }>
> = ({ zipCode, checkType }) => {
  if (checkType === 'upandup_feeds') {
    return <Text>-</Text>
  }

  const checkUrl = buildUrlForCheckType(zipCode, checkType)
  return (
    <Link textDecoration='underline' href={checkUrl} color={'blue'} isExternal>
      Check on {startCase(checkType)}
      <Icon as={ExternalLinkIcon} mx='2px' />
    </Link>
  )
}

const buildUrlForCheckType = (zipCode: string, checkType: CheckType): string => {
  switch (checkType) {
    case 'zillow':
      return `https://www.zillow.com/homes/for_rent/${zipCode}_rb/`
    case 'rently':
      return `https://homes.rently.com/homes-for-rent/${zipCode}`
    case 'upandup_feeds':
      return ''
    default:
      throw new Error(`Unrecognized check type: ${checkType}!`)
  }
}

export default CheckSearchLink
