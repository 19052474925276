import {
  EmployedUserIncomeSourceSubmitPayload,
  SelfEmployedUserIncomeSourceSubmitPayload,
  BenefitsUserIncomeSourceSubmitPayload,
  UploadDocument
} from '@homevest/types/user-income-sources'

import {
  EmployedSourceDetails,
  StandardEmployedSourceDetails,
  NewlyEmployedSourceDetails,
  SelfEmployedSourceDetails,
  BenefitsSourceDetails
} from './types'
import { parsePhoneNumber } from 'lib/formatting'

export const formatEmployedUserIncomeSource = (userIncomeSource: EmployedSourceDetails) => {
  if (userIncomeSource.is_newly_employed) {
    return formatNewlyEmployedUserIncomeSource(userIncomeSource)
  } else {
    return formatStandardEmployedUserIncomeSource(userIncomeSource)
  }
}

const formatStandardEmployedUserIncomeSource = (
  userIncomeSource: StandardEmployedSourceDetails
): EmployedUserIncomeSourceSubmitPayload => {
  const documents = Object.values(userIncomeSource.pay_stubs) as UploadDocument[]

  return {
    type: userIncomeSource.type,
    is_newly_employed: userIncomeSource.is_newly_employed,
    company_name: userIncomeSource.company_name,
    contact_name: userIncomeSource.contact_name,
    contact_email: userIncomeSource.contact_email,
    contact_phone: parsePhoneNumber(userIncomeSource.contact_phone),
    contact_title: userIncomeSource.contact_title,
    pay_frequency: userIncomeSource.pay_frequency,
    wage_type: userIncomeSource.wage_type,
    offered_rate: userIncomeSource.offered_rate,
    documents
  }
}

const formatNewlyEmployedUserIncomeSource = (
  userIncomeSource: NewlyEmployedSourceDetails
): EmployedUserIncomeSourceSubmitPayload => {
  return {
    type: userIncomeSource.type,
    is_newly_employed: userIncomeSource.is_newly_employed,
    company_name: userIncomeSource.company_name,
    contact_name: userIncomeSource.contact_name,
    contact_email: userIncomeSource.contact_email,
    contact_phone: parsePhoneNumber(userIncomeSource.contact_phone),
    contact_title: userIncomeSource.contact_title,
    pay_frequency: userIncomeSource.pay_frequency,
    wage_type: userIncomeSource.wage_type,
    offered_rate: userIncomeSource.offered_rate,
    documents: [userIncomeSource.offer_letter]
  }
}

export const formatSelfEmployedUserIncomeSource = (
  userIncomeSource: SelfEmployedSourceDetails
): SelfEmployedUserIncomeSourceSubmitPayload => {
  return {
    type: userIncomeSource.type,
    business_name: userIncomeSource.business_name,
    documents: [
      userIncomeSource.articles_of_organization,
      userIncomeSource.tax_return as UploadDocument
    ]
  }
}

export const formatBenefitsUserIncomeSource = (
  userIncomeSource: BenefitsSourceDetails
): BenefitsUserIncomeSourceSubmitPayload => {
  return {
    type: userIncomeSource.type,
    pay_frequency: userIncomeSource.pay_frequency,
    benefits_type: userIncomeSource.benefits_type,
    income_per_period: userIncomeSource.income_per_period,
    documents: [userIncomeSource.award_letter]
  }
}
