import React from 'react'
import { useMutation } from 'urql'
import { UpupInsertCapabilityDocument } from 'graphql/generated'
import { Input, Button, Modal, ErrorText } from 'components/Toolkit'

type Props = {
  isOpen: boolean
  onCreate: () => void
  onClose: () => void
}

const CreateCapabilityModal: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCreate,
  onClose
}) => {
  const [{ fetching, error }, createCapability] = useMutation(UpupInsertCapabilityDocument)
  const [type, setType] = React.useState<string>('')
  const [name, setName] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('')

  const handleCreate = async (): Promise<void> => {
    const result = await createCapability({ type, name, description })
    if (result.error === undefined) {
      onCreate()
      setType('')
      setName('')
      setDescription('')
    }
  }

  return isOpen ? (
    <Modal hasCloseButton close={onClose}>
      <Input value={type} type='text' onChange={setType} hasError={false} label='type' />
      <Input value={name} type='text' onChange={setName} hasError={false} label='name' />
      <Input
        value={description}
        type='text'
        onChange={setDescription}
        hasError={false}
        label='description'
      />
      <Button
        disabled={type.length < 2 || name.length < 2 || description.length < 2 || fetching}
        onClick={handleCreate}
        isSecondary={false}
      >
        Save
      </Button>
      {error !== undefined && <ErrorText>{error.message}</ErrorText>}
    </Modal>
  ) : null
}

export default CreateCapabilityModal
