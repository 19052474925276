import React, { useState } from 'react'
import { Box, HStack } from '@chakra-ui/react'

import PropertyDisplay from './PropertyDisplay'
import MarketRentForm from './MarketRentForm'
import RenewalOfferForm from './RenewalOfferForm'
import { UpcomingRenewalRentals } from '@homevest/types/renewals'
interface SelectedRentalViewProps {
  selectedRental?: UpcomingRenewalRentals[number]
  onRenewalSubmit?: () => void
}

const SelectedRentalView: React.FC<React.PropsWithChildren<SelectedRentalViewProps>> = ({
  selectedRental,
  onRenewalSubmit
}) => {
  const [marketRent, setMarketRent] = useState<number>()

  if (!selectedRental) {
    return null
  }

  const { current_rent: currentRent } = selectedRental

  return (
    <HStack spacing={10}>
      <Box width='25%'>
        <PropertyDisplay {...selectedRental} />
      </Box>
      <Box width='25%'>
        <MarketRentForm
          currentRent={currentRent}
          marketRent={marketRent}
          setMarketRent={setMarketRent}
        />
      </Box>
      <Box width='50%'>
        <RenewalOfferForm
          onSubmit={onRenewalSubmit}
          marketRent={marketRent}
          currentRent={currentRent}
          portfolioHomeId={selectedRental.portfolio_home_id}
          rentalId={selectedRental.rental_id}
        />
      </Box>
    </HStack>
  )
}

export default SelectedRentalView
