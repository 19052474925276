import React, { FunctionComponent } from 'react'
import { Tag as ChakraTag, TagProps as ChakraTagProps, TagLabel, Box } from '@chakra-ui/react'

export type TagProps = ChakraTagProps & { dot?: boolean; dotColor?: string }

const Tag: FunctionComponent<React.PropsWithChildren<TagProps>> = ({
  dot,
  dotColor = 'gray.500',
  children,
  ...props
}) => (
  <ChakraTag
    variant='subtle'
    size='sm'
    borderRadius='full'
    bg='gray.100'
    color='gray.800'
    {...props}
  >
    {dot && <Box h={1} w={1} borderRadius='full' mr={1} bg={dotColor} />}
    <TagLabel>{children}</TagLabel>
  </ChakraTag>
)

export default Tag
