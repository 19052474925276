import { Nav } from 'components/TailwindUIToolkit'
import React, { FunctionComponent } from 'react'

const { BreadcrumbBar } = Nav

export const DynamicWidthPageContainer: FunctionComponent<
  React.PropsWithChildren<{
    pathToPage?: Nav.HudsonPathPart[]
    breadcrumbBar?: boolean
    maxWidth?: string
  }>
> = ({ children, pathToPage = [], breadcrumbBar = true, maxWidth = 'max-w-wide' }) => {
  return (
    <div className={`m-auto px-5 py-0 ${maxWidth} min-w-narrow`}>
      {breadcrumbBar && <BreadcrumbBar path={pathToPage} />}
      <div className='w-full px-2 py-8 sm:px-0'>{children}</div>
    </div>
  )
}
